import { TFunction } from 'i18next';
import moment from 'moment';
import { SearchDropDown } from 'types/CommonTypes';
import {
  LoadFundsRequestParamsType,
  OrderNewCardsType,
  OrderNewCardsValuesType,
  PaymentDetailsType,
  RegisterPaycardRequestParamsType,
  UnloadFundsRequestParamsType,
} from 'types/PaycardManagementTypes';

export const fetchOrganizationPaycardsFilterMapper = (
  value: string,
  searchOption: SearchDropDown,
): string => {
  switch (searchOption.id) {
    case 0:
      return `name=${value}`;
    case 1:
      return `payrollNumber=${value}`;
    case 2:
      return `proxyNumber=${value}`;
    default:
      return '';
  }
};

export const registerPaycardMapper = (
  employeeDetails: any,
  paymentDetails: PaymentDetailsType,
): RegisterPaycardRequestParamsType => ({
  proxyNumber: paymentDetails.proxyNumber,
  firstName: employeeDetails.firstName,
  lastName: employeeDetails.lastName,
  payrollNumber: employeeDetails.payrollId,
  dateOfBirth: moment(employeeDetails?.birthDate[0]).format('YYYY-MM-DD'),
  ssn: employeeDetails.ssn,
  phone: employeeDetails.mobileNumber.replaceAll('-', ''),
  address: {
    country: 'US',
    state: employeeDetails.state[0].value,
    city: employeeDetails.city,
    postalCode: employeeDetails.zip,
    addressLine1: employeeDetails.address,
    addressLine2: employeeDetails.unit || undefined,
  },
});

export const loadFundsMapper = (
  {
    funds,
    paymentDescription,
    internalNotes,
  }: PaymentDetailsType,
  t: TFunction,
  organizationName?: string,
): any => ({
  amount: funds,
  currencyCode: 'USD',
  description: paymentDescription
  || t('paycardManagement:paycardManagement.registerCard.pairCard.paymentDescription.placeholder', { orgName: `${organizationName || ''}` }),
  internalNotes,
});

export const orderNewCardsMapper = (
  data: OrderNewCardsValuesType,
): OrderNewCardsType => ({
  cardsCount: Number(data.cardsCount),
  shipping: {
    addressee: data.addressee,
    attention: data.attention,
    phone: data.phone,
  },
});

export const unloadFundsMapper = (
  {
    transactionId,
    description,
    internalNotes,
  }: UnloadFundsRequestParamsType,
  t: TFunction,
  organizationName?: string,
): UnloadFundsRequestParamsType => ({
  transactionId,
  description: description
  || t('paycardManagement:paycardManagement.paycards.manageFunds.paymentDescription.placeholderUnloadFunds', { orgName: `${organizationName || ''}` }),
  internalNotes,
});

export const loadFundsMapperWizard = (
  {
    amount,
    description,
    internalNotes,
    currencyCode,
  }: LoadFundsRequestParamsType,
  t: TFunction,
  organizationName?: string,
): any => ({
  amount,
  currencyCode: currencyCode || 'USD',
  description: description
  || t('paycardManagement:paycardManagement.registerCard.pairCard.paymentDescription.placeholder', { orgName: `${organizationName || ''}` }),
  internalNotes,
});
