import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  fetchFinancialSummaryReportRequest,
  fetchOutstandingBatchesReportRequest,
  fetchOutstandingRepaymentsReportRequest,
  fetchTreasurySummaryReportRequest,
  fetchTreasurySummaryRequest,
} from 'api/reportsAPI';
import { RootState } from 'store';
import {
  FetchFinancialSummaryReportParamsType,
  FetchOutstandingBatchesReportParamsType,
  FetchOutstandingRepaymentsReportParamsType,
  FetchTreasurySummaryParamsType,
  FetchTreasurySummaryReportParamsType,
} from 'types/ReportsTypes';

export interface ReportFileData {
  id?: string
  name?: string
  csv?: string
}

export const initialState = {
  treasurySummary: {
    pending: false,
    reportData: [] as any,
    reportTotals: {} as any,
  },
  treasurySummaryData: {
    pending: false,
    file: null as ReportFileData | null,
  },
  outstandingBatchesData: {
    pending: false,
    file: null as ReportFileData | null,
  },
  outstandingRepaymentsData: {
    pending: false,
    file: null as ReportFileData | null,
  },
  financialReportData: {
    pending: false,
    file: null as ReportFileData | null,
  },
};

export const fetchTreasurySummary = createAsyncThunk(
  'reports/treasury-summary',
  async (params: FetchTreasurySummaryParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
    } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchTreasurySummaryRequest(storeState.user.accessToken, organizationID);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchTreasurySummaryReport = createAsyncThunk(
  'reports/treasury-summary-report',
  async (params: FetchTreasurySummaryReportParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID, organizationName,
    } = params;
    const storeState = getState() as RootState;

    try {
      return {
        id: organizationID ? `Organization_${organizationName || organizationID}-treasury-summary-report-file` : 'Outstanding-treasury-summary-report-file',
        csv: await fetchTreasurySummaryReportRequest(storeState.user.accessToken, organizationID),
      };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOutstandingBatchesReport = createAsyncThunk(
  'reports/outstanding-batches-report',
  async (params: FetchOutstandingBatchesReportParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID, organizationName, types, statuses,
    } = params;
    const storeState = getState() as RootState;

    try {
      return {
        id: organizationID ? `Organization_${organizationName || organizationID}-outstanding-batches-report-file` : 'Outstanding-batches-report-file',
        csv: await fetchOutstandingBatchesReportRequest(storeState.user.accessToken, organizationID, types, statuses),
      };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOutstandingRepaymentsReport = createAsyncThunk(
  'reports/outstanding-repayments-report',
  async (params: FetchOutstandingRepaymentsReportParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID, organizationName, autogenerated, type, status,
    } = params;
    const storeState = getState() as RootState;

    try {
      return {
        id: organizationID ? `Organization_${organizationName || organizationID}-outstanding-repayments-report-file` : 'Outstanding-repayments-report-file',
        csv: await fetchOutstandingRepaymentsReportRequest(storeState.user.accessToken, organizationID, autogenerated?.toString(), type, status),
      };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchFinancialSummaryReport = createAsyncThunk(
  'reports/financial-summary-report',
  async (params: FetchFinancialSummaryReportParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      year,
    } = params;
    const storeState = getState() as RootState;

    try {
      return {
        id: 'Financial-summary-report-file',
        csv: await fetchFinancialSummaryReportRequest(storeState.user.accessToken, year),
      };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    resetTreasuryReport: (state) => {
      state.treasurySummary = initialState.treasurySummary;
    },
    resetTreasurySummaryData: (state) => {
      state.treasurySummaryData.file = initialState.treasurySummaryData.file;
    },
    resetOutstandingBatchesData: (state) => {
      state.outstandingBatchesData.file = initialState.outstandingBatchesData.file;
    },
    resetOutstandingRepaymentsData: (state) => {
      state.outstandingRepaymentsData.file = initialState.outstandingRepaymentsData.file;
    },
    resetFinancialReportData: (state) => {
      state.financialReportData.file = initialState.financialReportData.file;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTreasurySummary.pending, (state) => {
      state.treasurySummary.reportData = initialState.treasurySummary.reportData;
      state.treasurySummary.reportTotals = initialState.treasurySummary.reportTotals;
      state.treasurySummary.pending = true;
    });

    builder.addCase(fetchTreasurySummary.fulfilled, (state, action) => {
      state.treasurySummary.reportData = action.payload.values;
      state.treasurySummary.reportTotals = action.payload.totals;
      state.treasurySummary.pending = false;
    });

    builder.addCase(fetchTreasurySummary.rejected, (state) => {
      state.treasurySummary.pending = false;
    });

    builder.addCase(fetchTreasurySummaryReport.pending, (state) => {
      state.treasurySummaryData.pending = true;
    });

    builder.addCase(fetchTreasurySummaryReport.fulfilled, (state, action) => {
      state.treasurySummaryData.file = {
        id: action.payload.id,
        csv: action.payload.csv,
      };
      state.treasurySummaryData.pending = false;
    });

    builder.addCase(fetchTreasurySummaryReport.rejected, (state) => {
      state.outstandingBatchesData.file = initialState.outstandingBatchesData.file;
      state.outstandingBatchesData.pending = false;
    });

    builder.addCase(fetchOutstandingBatchesReport.pending, (state) => {
      state.outstandingBatchesData.pending = true;
    });

    builder.addCase(fetchOutstandingBatchesReport.fulfilled, (state, action) => {
      state.outstandingBatchesData.file = {
        id: action.payload.id,
        csv: action.payload.csv,
      };
      state.outstandingBatchesData.pending = false;
    });

    builder.addCase(fetchOutstandingBatchesReport.rejected, (state) => {
      state.outstandingBatchesData.file = initialState.outstandingBatchesData.file;
      state.outstandingBatchesData.pending = false;
    });

    builder.addCase(fetchOutstandingRepaymentsReport.pending, (state) => {
      state.outstandingRepaymentsData.pending = true;
    });

    builder.addCase(fetchOutstandingRepaymentsReport.fulfilled, (state, action) => {
      state.outstandingRepaymentsData.file = {
        id: action.payload.id,
        csv: action.payload.csv,
      };
      state.outstandingRepaymentsData.pending = false;
    });

    builder.addCase(fetchOutstandingRepaymentsReport.rejected, (state) => {
      state.outstandingRepaymentsData.file = initialState.outstandingRepaymentsData.file;
      state.outstandingRepaymentsData.pending = false;
    });

    builder.addCase(fetchFinancialSummaryReport.pending, (state) => {
      state.financialReportData.pending = true;
    });

    builder.addCase(fetchFinancialSummaryReport.fulfilled, (state, action) => {
      state.financialReportData.file = {
        id: action.payload.id,
        csv: action.payload.csv,
      };
      state.financialReportData.pending = false;
    });

    builder.addCase(fetchFinancialSummaryReport.rejected, (state) => {
      state.financialReportData.file = initialState.financialReportData.file;
      state.financialReportData.pending = false;
    });
  },
});

export const {
  resetTreasuryReport,
  resetOutstandingBatchesData,
  resetOutstandingRepaymentsData,
  resetTreasurySummaryData,
  resetFinancialReportData,
} = reportsSlice.actions;

export const treasurySummaryReportSelector = (state: RootState) => state.reports.treasurySummary.reportData;
export const treasurySummaryReportTotalsSelector = (state: RootState) => state.reports.treasurySummary.reportTotals;
export const treasurySummaryReportPendingSelector = (state: RootState): boolean => state.reports.treasurySummary.pending;
export const treasurySummaryReportDataSelector = (state: RootState): ReportFileData | null | undefined => state.reports.treasurySummaryData.file;
export const treasurySummaryReportDataPendingSelector = (state: RootState): boolean => state.reports.treasurySummaryData.pending;
export const treasurySummaryOutstandingBatchesDataSelector = (state: RootState): ReportFileData | null | undefined => state.reports.outstandingBatchesData.file;
export const treasurySummaryOutstandingBatchesDataPendingSelector = (state: RootState): boolean => state.reports.outstandingBatchesData.pending;
// eslint-disable-next-line max-len
export const treasurySummaryOutstandingRepaymentsDataSelector = (state: RootState): ReportFileData | null | undefined => state.reports.outstandingRepaymentsData.file;
export const treasurySummaryOutstandingRepaymentsDataPendingSelector = (state: RootState): boolean => state.reports.outstandingRepaymentsData.pending;
export const treasurySummaryFinancialReportDataSelector = (state: RootState): ReportFileData | null | undefined => state.reports.financialReportData.file;
export const treasurySummaryFinancialReportDataPendingSelector = (state: RootState): boolean => state.reports.financialReportData.pending;

export default reportsSlice.reducer;
