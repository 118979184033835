import { configureStore } from '@reduxjs/toolkit';
import application from './slices/application';
import batches from './slices/batches';
import employees from './slices/employees';
import error from './slices/error';
import loader from './slices/loader';
import locations from './slices/locations';
import loggedOrganization from './slices/loggedOrganization';
import masterAccount from './slices/masterAccount';
import modals from './slices/modals';
import notification from './slices/notification';
import offers from './slices/offers';
import organizations from './slices/organizations';
import payGroups from './slices/payGroups';
import paymentAllocation from './slices/paymentAllocation';
import repayments from './slices/repayments';
import transactions from './slices/transactions';
import user from './slices/user';
import workers from './slices/workers';
import workerAccount from './slices/workerAccount';
import tna from './slices/tna';
import administrators from './slices/administrators';
import sections from './slices/sections';
import tpo from './slices/tpo';
import universalCalendar from './slices/universalCalendar';
import reports from './slices/reports';
import paycards from './slices/paycards';
import repaymentsBankDetails from './slices/repaymentsBankDetails';
import dashboard from './slices/dashboard';

export const store = configureStore({
  reducer: {
    application,
    notification,
    batches,
    employees,
    error,
    loader,
    locations,
    loggedOrganization,
    masterAccount,
    modals,
    offers,
    organizations,
    paymentAllocation,
    payGroups,
    repayments,
    transactions,
    user,
    workers,
    workerAccount,
    tna,
    administrators,
    sections,
    tpo,
    universalCalendar,
    reports,
    paycards,
    repaymentsBankDetails,
    dashboard,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
