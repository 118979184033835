import { memo } from 'react';
import AccordionSection from 'components/AccordionSection/AccordionSection';
import Access, { AccessUnit } from 'components/Access/Access';
import { Button, KIND } from 'baseui/button';
import { Cell, ALIGNMENT, Grid } from 'baseui/layout-grid';
import { LabelMedium, ParagraphSmall, LabelSmall } from 'baseui/typography';
import AppGridTable from 'components/AppGridTable/AppGridTable';
import { emptyPlaceholder, colors } from 'theme';
import { EmployeeResponseType } from 'types/EmployeeTypes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setModal, ModalNames } from 'store/slices/modals';
import { workerAccountExternalSelector } from 'store/slices/workerAccount';
import { setWorkerProfileAttributesTrusted, workerProfileAttributesTrustedPendingSelector, workerSelector } from 'store/slices/workers';
import { setPrevPage } from 'store/slices/application';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'baseui';
import { containerStyles } from 'screens/Employees/EmployeesHelpers';
import Loader from 'components/Loader';
import { employeeDetailsPendingSelector } from 'store/slices/employees';

interface Props {
  selectedEmployee?: EmployeeResponseType;
  expanded?: boolean;
  show?: boolean;
}

/**
 * EmployeeDetailsFuegoProfile component
 *
 * @param selectedEmployee selected employee
 * @param expanded         expanded
 * @param show             show section for clear testing purposes
 */
const EmployeeDetailsFuegoProfile = ({
  selectedEmployee,
  expanded,
  show,
}: Props) => {
  const history = useHistory();
  const { employeeID } = useParams<{ organizationID: string, employeeID: string }>();
  const workerAccountExternal = useAppSelector(workerAccountExternalSelector);
  const setTrustUserPending = useAppSelector(workerProfileAttributesTrustedPendingSelector);
  const workerDetails = useAppSelector(workerSelector);
  const pending = useAppSelector(employeeDetailsPendingSelector);
  const hideSection = show ? false : (
    !pending
    && workerAccountExternal?.length === 0
    && !selectedEmployee?.workerId
  );
  const { t } = useTranslation(['employees', 'workers']);
  const [css] = useStyletron();
  const theme = useStyletron()[1];

  const dispatch = useAppDispatch();

  const handleShiftsModalOpen = () => {
    dispatch(setModal({
      name: ModalNames.WORKER_UNIVERSAL_CALENDAR_SHIFTS_MODAL,
      isOpen: true,
    }));
  };

  const visitTransactionsHistory = () => {
    dispatch(setPrevPage(`/loggedOrganization/employees/${employeeID}`));
    history.push(`/treasury/transaction-history/${workerDetails?.id}`);
  };

  const handleTrustUserAction = () => {
    if (workerDetails?.id) {
      dispatch(setWorkerProfileAttributesTrusted({
        workerID: workerDetails?.id,
        data: {
          trusted: true,
        },
      }));
    }
  };

  return (hideSection) ? null : (
    <AccordionSection
      title={t('employees:fuegoProfile')}
      showStatus={false}
      expanded={expanded}
    >
      <div className={
          css({
            ...containerStyles,
            minHeight: hideSection ? '' : '120px',
          })
        }
      >
        <Loader active={pending} />

        <Access oneOf={[AccessUnit.FBOManager, AccessUnit.FBOReader, AccessUnit.TreasuryManager, AccessUnit.TreasuryReader]}>
          {workerAccountExternal?.length > 0 && (
          <Grid
            gridColumns={10}
            align={ALIGNMENT.center}
          >
            <Cell
              span={[12, 12, 10]}
              align={ALIGNMENT.center}
            >
              <LabelMedium>{t('employees:linkedAccounts')}</LabelMedium>
              <br />

              <AppGridTable
                tableStyle={{
                  '@media (max-width: 1200px)': {
                    gridTemplateColumns: '200px 100px 100px 130px 250px',
                  },
                }}
                // eslint-disable-next-line max-len
                tableGridTemplateColumns="minmax(20%, max-content) minmax(15%, max-content) minmax(15%, max-content) minmax(20%, max-content) minmax(30%, max-content)"
                headingCells={[
                  <LabelMedium>{t('workers:accountNickname')}</LabelMedium>,
                  <LabelMedium>{t('workers:accountLastFour')}</LabelMedium>,
                  <LabelMedium>{t('workers:accountStatus')}</LabelMedium>,
                  <LabelMedium>{t('workers:accountVerificationStatus')}</LabelMedium>,
                  <LabelMedium>{t('workers:accountType')}</LabelMedium>,
                ]}
                rows={workerAccountExternal?.map(({
                  nickname, lastFour, status, verificationStatus, type,
                }) => ({
                  id: `${nickname}-${lastFour}-${status}`,
                  cells: [
                    <ParagraphSmall>{nickname || emptyPlaceholder}</ParagraphSmall>,
                    <ParagraphSmall>{`****${lastFour}` || emptyPlaceholder}</ParagraphSmall>,
                    <ParagraphSmall>{status || emptyPlaceholder}</ParagraphSmall>,
                    <ParagraphSmall>{verificationStatus || emptyPlaceholder}</ParagraphSmall>,
                    <ParagraphSmall>{type || emptyPlaceholder}</ParagraphSmall>,
                  ],
                })) || []}
              />
            </Cell>
          </Grid>
          )}
        </Access>
        {selectedEmployee?.workerId && (
          <Access oneOf={[AccessUnit.EWAManager]}>
            <div className={css({
              marginTop: workerAccountExternal?.length > 0 ? '32px' : '0',
            })}
            >
              <Grid
                gridColumns={12}
                align={ALIGNMENT.center}
              >
                <Cell
                  span={12}
                  align={ALIGNMENT.start}
                >
                  <LabelMedium>{t('employees:workerDetails')}</LabelMedium>
                </Cell>
                <Cell
                  span={[12, 6, 6]}
                >
                  <LabelSmall
                    display="inline-flex"
                    color={theme.colors.contentSecondary}
                    overrides={{
                      Block: {
                        style: {
                          '@media screen and (max-width: 1700px)': {
                            marginTop: '12px',
                          },

                        },
                      },
                    }}
                  >
                    {t('employees:fuegoWorkerID.label')}
                    : &nbsp;
                  </LabelSmall>
                  <ParagraphSmall display="inline-flex">
                    {selectedEmployee?.workerId || emptyPlaceholder}
                  </ParagraphSmall>
                </Cell>
                <Cell
                  span={[12, 6, 6]}
                >
                  <LabelSmall display="inline-flex" color={theme.colors.contentSecondary}>
                    {t('employees:workerUsername.label')}
                    : &nbsp;
                  </LabelSmall>
                  <ParagraphSmall display="inline-flex">
                    {workerDetails?.username || emptyPlaceholder}
                  </ParagraphSmall>
                </Cell>
                <Cell
                  span={[12, 6, 6]}
                >
                  <LabelSmall display="inline-flex" color={theme.colors.contentSecondary}>
                    {t('employees:workerEmail.label')}
                    : &nbsp;
                  </LabelSmall>
                  <ParagraphSmall display="inline-flex" marginTop="8px">
                    {workerDetails?.email || emptyPlaceholder}
                  </ParagraphSmall>
                </Cell>
                <Cell
                  span={[12, 6, 6]}
                >
                  <LabelSmall display="inline-flex" color={theme.colors.contentSecondary}>
                    {t('employees:workerMobileNumber.label')}
                    : &nbsp;
                  </LabelSmall>
                  <ParagraphSmall display="inline-flex" marginTop="8px">
                    {workerDetails?.mobilePhone || emptyPlaceholder}
                  </ParagraphSmall>
                </Cell>
                <Cell
                  span={[12, 6, 12]}
                >
                  <div className={css({
                    display: 'grid',
                    gridTemplateColumns: 'repeat(7, 1fr)',
                    gap: '0 10px',
                    placeContent: 'center',
                  })}
                  >
                    <Button
                      type="button"
                      kind={KIND.secondary}
                      onClick={handleShiftsModalOpen}
                      overrides={{
                        Root: {
                          props: {
                            id: 'EmployeeDetails-Worker-Shifts-button',
                          },
                          style: {
                            marginBottom: '8px',
                          },
                        },
                      }}
                    >
                      {t('workers:shiftsButton')}
                    </Button>
                    <Access oneOf={[AccessUnit.FBOManager, AccessUnit.FBOReader]}>
                      <Button
                        type="button"
                        kind={KIND.secondary}
                        onClick={visitTransactionsHistory}
                        overrides={{
                          Root: {
                            props: {
                              id: 'EmployeeDetails-Worker-ViewTransactions-button',
                            },
                            style: {
                              marginBottom: '8px',
                            },
                          },
                        }}
                      >
                        {t('workers:viewTransactions')}
                      </Button>
                    </Access>
                    <Button
                      type="button"
                      kind={KIND.secondary}
                      onClick={handleTrustUserAction}
                      isLoading={setTrustUserPending}
                      overrides={{
                        Root: {
                          props: {
                            id: 'EmployeeDetails-Worker-TrustUser-button',
                          },
                          style: {
                            marginBottom: '8px',
                          },
                        },
                        LoadingSpinner: {
                          style: {
                            borderRightColor: colors.primary,
                            borderTopColor: colors.primary,
                            borderLeftColor: colors.primary,
                          },
                        },
                      }}
                    >
                      {t('workers:trustUserButton')}
                    </Button>
                  </div>
                </Cell>
              </Grid>
            </div>
          </Access>
        )}
      </div>
    </AccordionSection>
  );
};

export default memo(EmployeeDetailsFuegoProfile);
