import { createContext, memo, useEffect } from 'react';
import {
  FormikState, FormikHelpers, FormikHandlers, useFormik,
} from 'formik';
import { useStyletron } from 'baseui';
import { Cell, Grid } from 'baseui/layout-grid';
import { useTranslation } from 'react-i18next';
import { loadFundsValidationSchema as validationSchema } from 'validation/paycardManagementSchema';
import { LoadFundsValuesType } from 'types/PaycardManagementTypes';
import AppInput from 'components/Form/AppInput';
import AppTextarea from 'components/Form/AppTextarea';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { useAppSelector } from 'store/hooks';
import { ParagraphSmall } from 'baseui/typography';
import { paycardManagementLoadFundsInitialValues } from 'initialValues/PaycardsInitialValues';
import { colors } from 'theme';

export const LoadFundsFormContext = createContext(
  {} as FormikState<LoadFundsValuesType> & FormikHelpers<LoadFundsValuesType> & FormikHandlers,
);

export type Props = {
  formValues: LoadFundsValuesType | undefined,
  setFormValues: (values: LoadFundsValuesType) => void;
  setIsFormValid: (value: boolean) => void;
  isHighLoadAmount: boolean
}

const EnterFunds = ({
  formValues, setFormValues, setIsFormValid, isHighLoadAmount,
}: Props) => {
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const [, theme] = useStyletron();
  const organization = useAppSelector(loggedOrganizationSelector);
  const { name: organizationName } = organization || {};

  const initialValues = {
    ...paycardManagementLoadFundsInitialValues,
    ...formValues,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema,
  });

  const {
    values,
    isValid,
    validateForm,
  } = formik;

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid]);

  useEffect(() => {
    validateForm(initialValues);
  }, []);

  return (
    <form>
      <LoadFundsFormContext.Provider value={formik}>
        <Grid
          gridColumns={12}
          gridMargins={24}
          gridGaps={[0, isHighLoadAmount ? 0 : 24]}
          overrides={{
            Grid: {
              style: {
                flexDirection: 'column',
              },
            },
          }}
        >
          <AppInput
            showStar
            name="amount"
            label={t('paycardManagement:paycardManagement.paycards.manageFunds.loadFunds.label')}
            cellSpan={[12, 6, 2.5]}
            context={LoadFundsFormContext}
            formControlProps={{
              htmlFor: 'amount',
            }}
            inputProps={{
              autoComplete: 'off',
              startEnhancer: '$',
              min: 0.1,
              max: 1000000.00,
              type: 'number',
              step: 'any' as any,
            }}
            borderColor={isHighLoadAmount && isValid ? colors.warning : theme.colors.inputBorder}
          />
          {
            (isHighLoadAmount && isValid) && (
              <Cell span={[12, 6, 2.5]}>
                <ParagraphSmall color={colors.warning} marginTop={0} marginBottom="24px">
                  {t('paycardManagement:paycardManagement.paycards.manageFunds.loadFunds.highLoadAmount')}
                </ParagraphSmall>
              </Cell>
            )
          }
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
          gridGaps={[0, 24]}
        >
          <AppTextarea
            name="paymentDescription"
            label={t('paycardManagement:paycardManagement.paycards.manageFunds.paymentDescription.label')}
            cellSpan={[12, 6, 6]}
            context={LoadFundsFormContext}
            textareaProps={{
              id: 'paymentDescription-unloadFunds',
              autoComplete: 'off',
            }}
            placeholder={
              t(
                'paycardManagement:paycardManagement.paycards.manageFunds.paymentDescription.placeholderLoadFunds',
                { orgName: `${organizationName || ''}` },
              )
            }
          />
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
          gridGaps={[0, 24]}
        >
          <AppTextarea
            name="internalNotes"
            label={t('paycardManagement:paycardManagement.paycards.manageFunds.internalNotes.label')}
            cellSpan={[12, 6, 6]}
            context={LoadFundsFormContext}
            textareaProps={{
              id: 'internalNotes-unloadFunds',
              autoComplete: 'off',
            }}
            placeholder={t('paycardManagement:paycardManagement.paycards.manageFunds.internalNotes.placeholderLoadFunds')}
          />
        </Grid>
      </LoadFundsFormContext.Provider>
    </form>
  );
};

export default memo(EnterFunds);
