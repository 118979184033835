import { rolePropToRoleValue } from 'screens/Administrators/AdministratorsHelpers';
import {
  CreateAdministratorFormType,
  AdministratorType,
  EditAdminRolesType,
  EditAdministratorFormType,
} from 'types/AdministratorsTypes';

const pickerFunction = ({
  ewaClientManager,
  ewaManager,
  treasuryManager,
  fboManager,
  tipsReader,
  treasuryReader,
  fboReader,
  ewaClientLocationManager,
  tipsClientManager,
  fddClientManager,
  ewaReport,
  paycardClientManager,
}: any) => ({
  ewaClientManager,
  ewaManager,
  treasuryManager,
  fboManager,
  tipsReader,
  treasuryReader,
  fboReader,
  ewaClientLocationManager,
  tipsClientManager,
  fddClientManager,
  ewaReport,
  paycardClientManager,
});

export const saveAdministratorMapper = (
  values: CreateAdministratorFormType,
): AdministratorType => ({
  id: values.id,
  firstName: values.firstName,
  lastName: values.lastName,
  password: values.password,
  email: values.email,
  organizations: (values.organizationID?.length !== 0
    ? values.organizationID?.map((org) => ({ id: org.value }))
    : undefined),
  displayName: `${values.firstName} ${values.lastName}`,
  userSource: values.userSource,
  roles: Object.entries(pickerFunction(values))
    .map(([key, value]) => (value ? rolePropToRoleValue[key] : null))
    .filter((role) => role),
  locations: (values.locationID?.length !== 0
    ? values.locationID?.map((loc) => ({ name: loc.label, id: loc.value }))
    : undefined),
  mobileCountryCode: values.mobilePhone ? values.mobileCountry?.dialCode : undefined,
  mobilePhone: values.mobilePhone ? values.mobilePhone : undefined,
});

export const updateAdminRolesMapper = (
  values: EditAdministratorFormType,
): EditAdminRolesType => ({
  organizations: (values.organizationID?.length !== 0
    ? values.organizationID?.map((org) => ({ id: org.value }))
    : undefined),
  roles: Object.entries(pickerFunction(values))
    .map(([key, value]) => (value ? rolePropToRoleValue[key] : null))
    .filter((role) => role),
  locations: (values.locationID?.length !== 0
    ? values.locationID?.map((loc) => ({ name: loc.label, id: loc.value }))
    : undefined),
});
