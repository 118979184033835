import * as Yup from 'yup';
import {
  EmployeeTypePayRatesType,
  SubElementEWATypeEnum,
} from 'types/EmployeeTypes';
import { commonAmountFormattedValidationSchema } from './commonSchema';
import messages from './messages';

export const employmentPayRatesSchema = (employeeType: EmployeeTypePayRatesType) => Yup.object().shape({
  YEARLY: Yup.string().when({
    is: () => employeeType?.[0]?.value === SubElementEWATypeEnum.SALARIED_NOT_CLOCKING,
    then: commonAmountFormattedValidationSchema(),
    otherwise: Yup.string().nullable(),
  }),
  HOURLY: commonAmountFormattedValidationSchema(),
});

export const resendMoneyMfaSchema = Yup.object().shape({
  paymentMethod: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(messages?.required),
        label: Yup.string().required(messages?.required),
      }),
    ),
});
