import {
  Key,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import { Accordion } from 'baseui/accordion';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  accordionAllocationListItemOverrides,
  allocationsListContainerStyles,
  tableHeaderStyles,
  tableStyles,
} from 'screens/Batches/BatchesHelpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  repaymentSelector,
  repaymentsPaymentAllocationSearchPayGroupSelector,
  repaymentsPaymentAllocationSearchPayPeriodSelector,
} from 'store/slices/repayments';
import {
  allocationBatchesSelector,
  batchesPendingListSelector,
  expandedBatchesSelector,
  selectedBatchEmploymentsSelector,
  selectedBatchesSelector,
  setExpandedBatches,
  fetchBatchEmployments,
  selectedEmploymentDrawsSelector,
  createBatchAllocations,
  totalSizeEmploymentsList,
  pageNumberEmploymentsList,
  allocationBatchesPageNumberSelector,
  allocationBatchesTotalSizeSelector,
  fetchBatchAllocations,
  numPagesEmploymentsList,
  allocationBatchesNumPagesSelector,
} from 'store/slices/paymentAllocation';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { Pagination, SIZE } from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import Loader from 'components/Loader';
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import { unScalePrice } from 'utils/priceScale';
import {
  AllocationBatchType,
  PaymentAllocationTablePropsType,
} from 'types/RepaymentTypes';
import { BatchIDType } from 'types/BatchTypes';
import PaymentAllocationListItem from './PaymentAllocationListItem';
import PaymentAllocationFooter from './PaymentAllocationFooter';

const PaymentAllocationTable = ({
  filter,
}: PaymentAllocationTablePropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['batches']);
  const dispatch = useAppDispatch();
  const allocationBatches = useAppSelector(allocationBatchesSelector);
  const pending = useAppSelector(batchesPendingListSelector);
  const selectedBatches = useAppSelector(selectedBatchesSelector);
  const expandedBatches = useAppSelector(expandedBatchesSelector);
  const repayment = useAppSelector(repaymentSelector);
  const selectedBatchEmployments = useAppSelector(selectedBatchEmploymentsSelector);
  const selectedEmploymentDraws = useAppSelector(selectedEmploymentDrawsSelector);
  const numPages = useAppSelector(numPagesEmploymentsList);
  const pageNumber = useAppSelector(pageNumberEmploymentsList);
  const totalSize = useAppSelector(totalSizeEmploymentsList);
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const { id: organizationID } = loggedOrganization || {};
  const batchesPageNumber = useAppSelector(allocationBatchesPageNumberSelector);
  const batchesNumPages = useAppSelector(allocationBatchesNumPagesSelector);
  const batchesTotalSize = useAppSelector(allocationBatchesTotalSizeSelector);
  const selectedAllocationBatches = allocationBatches.filter((item) => selectedBatches?.includes(item?.id?.toString()));
  const hasSelectedBatches = selectedBatches?.length > 0;
  const hasExpandedBatches = expandedBatches?.length > 0;
  const hasSelectedDraws = selectedEmploymentDraws?.length > 0;
  const hasSelectedBatchEmployments = selectedBatchEmployments?.length > 0;

  const batchPayGroup = useAppSelector(repaymentsPaymentAllocationSearchPayGroupSelector);
  const batchPayrollPeriod = useAppSelector(repaymentsPaymentAllocationSearchPayPeriodSelector);

  const {
    balance: repaymentBalance,
    id: repaymentID,
  } = repayment || {};

  const paymentBalanceUnscaled = unScalePrice(repaymentBalance?.value, repaymentBalance?.scale);

  const handlePageChange = ({ nextPage, batchID }: { nextPage: number, batchID: BatchIDType }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();

    dispatch(fetchBatchEmployments({ batchID, pageNumber: page }));
  };
  const handleBatchesPageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), batchesTotalSize).toString();

    dispatch(fetchBatchAllocations({
      organizationID,
      pageNumber: page,
      payGroupId: batchPayGroup[0]?.id,
      payrollPeriodId: batchPayrollPeriod[0]?.id,
    }));
  };

  const handleAccordionChange = ({ expanded }: { expanded: Key[] }) => {
    dispatch(
      setExpandedBatches(expanded),
    );

    if (expanded[0]) {
      dispatch(fetchBatchEmployments({ batchID: expanded[0]?.toString(), pageNumber: '1' }));
    }
  };

  const handleClickSave = () => {
    dispatch(createBatchAllocations({
      repaymentID: repaymentID.toString(),
      data: {
        allocations: selectedAllocationBatches?.map((item) => ({
          id: item?.id,
          amount: item?.amountDue,
        })),
      },
    }));
  };

  const hasSearchWordInBatches = (item: AllocationBatchType) => {
    const searchWord = filter?.search?.trim().toLowerCase();

    if (searchWord) {
      const {
        payGroup,
        payrollPeriod,
        reference,
        amount: itemAmount,
        amountDue: itemAmountDue,
      } = item || {};

      const normalizeString = (text: string | number) => text?.toString().trim().toLowerCase();
      const unscaledAmount = itemAmount ? unScalePrice(itemAmount?.value, itemAmount?.scale) : 0;
      const unscaledAmountDue = itemAmountDue ? unScalePrice(itemAmountDue?.value, itemAmountDue?.scale) : 0;

      return searchWord
        && (
          normalizeString(payGroup?.name)?.includes(searchWord)
          || normalizeString(payrollPeriod?.name)?.includes(searchWord)
          || normalizeString(reference)?.includes(searchWord)
          || normalizeString(unscaledAmount)?.includes(searchWord)
          || normalizeString(unscaledAmountDue)?.includes(searchWord)
        );
    }

    return true;
  };

  const filteredAllocationBatches = allocationBatches?.filter(hasSearchWordInBatches);

  const drawsAmountSum = selectedEmploymentDraws
    ?.reduce((acc, draw) => {
      acc.sum += unScalePrice(draw?.amount?.value, draw?.amount?.scale);

      return acc;
    }, { sum: 0 });

  const employmentsAmountSum = selectedBatchEmployments
    ?.filter(({ batchID }) => filteredAllocationBatches?.find((item) => item.id?.toString() === batchID?.toString()))
    ?.reduce((acc, { totalDrawAmount }) => {
      acc.sum += unScalePrice(totalDrawAmount?.value, totalDrawAmount?.scale);

      return acc;
    }, { sum: 0 });

  const batchesAmount = filteredAllocationBatches
    ?.filter((item) => !expandedBatches?.includes(item?.id?.toString()))
    ?.reduce((acc, allocationBatch) => {
      if (selectedBatches?.includes(allocationBatch?.id?.toString())) {
        acc.sum += unScalePrice(allocationBatch?.amountDue?.value, allocationBatch?.amountDue?.scale);
      }
      return acc;
    }, { sum: 0 });

  const calcAllocatedAmount = () => {
    if (hasSelectedDraws) {
      return drawsAmountSum?.sum;
    }

    if (hasSelectedBatchEmployments) {
      return employmentsAmountSum?.sum;
    }

    if (hasSelectedBatches) {
      return batchesAmount?.sum;
    }
    return 0;
  };

  const balance = paymentBalanceUnscaled - calcAllocatedAmount();
  const isBalanceNegative = balance < 0;

  const renderFooter = () => (
    <PaymentAllocationFooter
      isBalanceNegative={isBalanceNegative}
      allocatedAmount={calcAllocatedAmount()}
      paymentAmount={paymentBalanceUnscaled}
      balance={balance}
    />
  );

  return (
    <div className={css(tableStyles)}>
      <div className={css(tableHeaderStyles)}>
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
          gridMargins={30}
        >
          <Cell
            align={ALIGNMENT.center}
            span={[2, 3]}
          >
            <Block display="flex" justifyContent="start">
              <strong>{t('batches:paymentAllocations.batchReference')}</strong>
            </Block>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={[2, 3]}
          >
            <strong>{t('batches:paymentAllocations.payGroup')}</strong>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <strong>{t('batches:paymentAllocations.payPeriod')}</strong>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <strong>{t('batches:paymentAllocations.amount')}</strong>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={[2, 1]}
          >
            <strong>{t('batches:paymentAllocations.amountDue')}</strong>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <strong>{t('batches:paymentAllocations.allocation')}</strong>
          </Cell>

          {!hasExpandedBatches && (
            <Cell
              align={ALIGNMENT.center}
              span={[2, 1]}
            >
              <Block
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  disabled={hasExpandedBatches || !hasSelectedBatches || isBalanceNegative || calcAllocatedAmount() <= 0}
                  type="button"
                  kind={KIND.primary}
                  onClick={handleClickSave}
                  overrides={{
                    Root: {
                      props: {
                        id: 'payment-allocation-table-save-button',
                      },
                    },
                  }}
                >
                  {t('common:save')}
                </Button>
              </Block>
            </Cell>
          )}
        </Grid>
      </div>

      <div className={css(allocationsListContainerStyles)}>
        <Accordion
          onChange={handleAccordionChange}
          overrides={accordionAllocationListItemOverrides}
        >
          <Loader active={pending} />

          {filteredAllocationBatches
            ?.map((allocationBatch) => (
              <PaymentAllocationListItem
                pageNumber={pageNumber}
                numPages={numPages}
                handlePageChange={handlePageChange}
                key={allocationBatch?.id}
                allocationBatch={allocationBatch}
                search={filter?.search}
                disableSave={isBalanceNegative || calcAllocatedAmount() === 0}
                footer={renderFooter()}
              />
            ))}
        </Accordion>
      </div>
      {allocationBatches?.length > 0 && (
        <Block
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
          marginBottom="16px"
          marginTop="16px"
        >
          <Pagination
            size={SIZE.compact}
            numPages={batchesNumPages}
            currentPage={batchesPageNumber}
            overrides={paginationTransparentOverrides}
            onPageChange={handleBatchesPageChange}
          />
        </Block>
      )}
      {renderFooter()}
    </div>
  );
};

export default memo(PaymentAllocationTable);
