import {
  OrganizationIDType,
} from 'types/OrganizationTypes';
import {
  SaveLocationRequestType,
  LocationIDType,
  SaveLocationConfigRequestType,
  PatchLocationRequestType,
} from 'types/LocationTypes';
import {
  PayGroupIDType,
  AssignLocationsValuesType,
} from 'types/PayGroupTypes';
import request from './request';
import { organizationsBaseUrl } from './organizationsAPI';

export const fetchLocationsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  onlyTcoEnabled?: string,
  pageSize: string = '1000',
) => {
  const queryParams = new URLSearchParams({
    pageSize,
  });
  if (onlyTcoEnabled) {
    queryParams.set('onlyTcoEnabled', onlyTcoEnabled);
  }

  return request({
    token,
    url: `${organizationsBaseUrl}/${organizationID}/locations?${queryParams.toString()}`,
  });
};
/**
 * @returns {values: []} but with potential to return pages as well
 */
export const fetchLocationConfigsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
) => request({
  token,
  url: `/organizations/${organizationID}/locations/${locationID}/configs`,
});

export const fetchLocationByIdRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
) => request({
  token,
  url: `organizations/${organizationID}/locations/${locationID}`,
});

export const createLocationRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: SaveLocationRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/locations`,
});

export const importLocationsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  file: FormData,
) => request({
  token,
  method: 'POST',
  payload: file,
  url: `${organizationsBaseUrl}/${organizationID}/locations/bulk`,
});

export const saveLocationConfigRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
  data: SaveLocationConfigRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/locations/${locationID}/configs/bulk`,
});

export const editLocationRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
  data: SaveLocationRequestType,
) => request({
  token,
  method: 'PUT',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/locations/${locationID}`,
});

export const patchLocationRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
  data: PatchLocationRequestType,
) => request({
  token,
  method: 'PATCH',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/locations/${locationID}`,
});

export const assignLocationsToPayGroupRequest = (
  token: string,
  organizationID: OrganizationIDType,
  payGroupID: PayGroupIDType,
  data: AssignLocationsValuesType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organizations/${organizationID}/location-paygroup-relations/bulk`,
});

export const generateLocationsConfigReportRequest = (
  token: string,
  organizationID: OrganizationIDType,
) => request({
  token,
  method: 'GET',
  url: `${organizationsBaseUrl}/${organizationID}/locations/bulk`,
});
