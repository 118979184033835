import { BatchIDType } from 'types/BatchTypes';
import {
  OrganizationIDType,
  DeductionBatchesValuesType,
} from 'types/OrganizationTypes';
import request from './request';

export const fetchAllBatchesRequest = (
  token: string,
  organizationID: OrganizationIDType,
  pageNumber: string,
  payGroupId: string,
  payrollPeriodId: string,
  status?: string,
  creationTrigger?: string,
  deductionCount?: number,
  creationDate?: string,
  types?: string,
) => {
  const queryParams = new URLSearchParams({
    pageNumber,
    payGroupId,
    payrollPeriodId,
  });
  if (creationTrigger) {
    queryParams.set('creationTrigger', creationTrigger);
  }
  if (status) {
    queryParams.set('status', status);
  }
  if (deductionCount === 0) {
    queryParams.set('deductionCount', '0');
  }
  if (creationDate) {
    queryParams.set('creationDate', creationDate);
  }
  if (types) {
    queryParams.set('types', types);
  }
  return request({
    token,
    url: `organizations/${organizationID}/batches?${queryParams.toString()}&sort=-payrollPeriodStartDate`,
  });
};

export const fetchBatchRequest = (
  token: string,
  batchID: BatchIDType,
) => request({
  token,
  url: `batches/${batchID}`,
});

export const createDeductionBatchesRequest = (
  token: string,
  organizationID: OrganizationIDType,
  payrollPeriodId: number,
  data: DeductionBatchesValuesType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organizations/${organizationID}/payroll-periods/${payrollPeriodId}/deduction-batches`,
});
