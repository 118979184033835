import Access, { AccessUnit } from 'components/Access/Access';
import Loader from 'components/Loader';
import { pendingBalanceSelector, pendingDashBalanceSelector } from 'store/slices/masterAccount';
import { useAppSelector } from 'store/hooks';
import { workersPendingListSelector } from 'store/slices/workers';
import { Block } from 'baseui/block';
import TreasuryMasterAccount from './TreasuryMasterAccount/TreasuryMasterAccount';
import TreasuryWorkerAccounts from './TreasuryWorkerAccounts/TreasuryWorkerAccounts';
import { blockOverridesRelativeWhite } from '../CommonHelpers';

const TreasuryWorkerAndMasterAccount = () => {
  const pendingBalance = useAppSelector(pendingBalanceSelector);
  const pendingDashBalance = useAppSelector(pendingDashBalanceSelector);
  const pendingWorkers = useAppSelector(workersPendingListSelector);
  const pending = pendingBalance || pendingDashBalance || pendingWorkers;

  return (
    <Block overrides={blockOverridesRelativeWhite}>
      <Loader active={pending} />
      <Access oneOf={[AccessUnit.TreasuryManager, AccessUnit.TreasuryReader]}>
        <TreasuryMasterAccount />
      </Access>
      <Access oneOf={[AccessUnit.FBOManager, AccessUnit.FBOReader]}>
        <TreasuryWorkerAccounts />
      </Access>
    </Block>
  );
};

export default TreasuryWorkerAndMasterAccount;
