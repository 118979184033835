import { OrganizationIDType } from 'types/OrganizationTypes';
import { LocationIDType } from 'types/LocationTypes';
import { SetTnALocationSettingsType } from 'types/TnaTypes';
import request from './request';

export const fetchTnALocationSettingsRequest = (
  token: string,
  organizationId: OrganizationIDType,
  locationId: LocationIDType,
  businessDate?: string,
) => request({
  token,
  url: `organizations/${organizationId}/locations/${locationId}/settings?businessDate=${businessDate}`,
});
export const setTnALocationSettingsRequest = (
  token: string,
  organizationId: OrganizationIDType,
  locationId: LocationIDType,
  data?: SetTnALocationSettingsType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organizations/${organizationId}/locations/${locationId}/settings`,
});
