import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createBatchAllocationsRequest,
  createDrawAllocationsRequest,
  createEmploymentAllocationsRequest,
  fetchAllocatedBatchEmploymentDrawAllocationsRequest,
  fetchAllocatedBatchEmploymentRequest,
  fetchAllocatedTPOBatchEmploymentPaymentAllocationsRequest,
  fetchAllocatedTPOBatchEmploymentRequest,
  fetchBatchEmploymentDrawAllocationsRequest,
  fetchRepaymentRequest,
} from 'api/repaymentsAPI';
import {
  AllocationBatchType,
  CreateBatchAllocationsParamsType,
  CreateDrawAllocationsParamsType,
  CreateEmploymentAllocationsParamsType,
  FetchAllocatedBatchAllocationsParamsType,
  FetchAllocatedBatchEmploymentDrawAllocationsParamsType,
  FetchAllocatedBatchEmploymentsParamsType,
  FetchAllocatedTPOBatchEmploymentsParamsType,
  FetchBatchAllocationsParamsType,
  FetchBatchEmploymentDrawAllocationsParamsType,
  FetchBatchEmploymentsParamsType,
  BatchEmploymentType,
  AllocatedBatchEmploymentType,
  AllocatedTPOBatchEmploymentType,
  DrawAllocationType,
  TPOPaymentAllocationType,
} from 'types/RepaymentTypes';
import { BatchIDType } from 'types/BatchTypes';
import {
  DrawIDType,
  EmploymentIDType,
} from 'types/EmployeeTypes';
import { fetchAllocatedBatchAllocationsRequest, fetchBatchAllocationsRequest } from 'api/paymentAllocationAPI';
import { fetchBatchEmploymentsRequest } from 'api/employmentsAPI';
import { MoneyDtoType } from 'types/CommonTypes';
import { RootState } from '..';
import { setRepayment } from './repayments';

export type BatchEmploymentIDsType = {
  batchID: BatchIDType
  employmentID: EmploymentIDType,
  totalDrawAmount: MoneyDtoType,
}

export type SelectedEmploymentDraw = {
  drawID: DrawIDType
  amount: MoneyDtoType
}

export type PaymentAllocationType = {
  pendingList: boolean
  pendingAllocatedList: boolean
  pendingEmploymentsList: boolean
  pendingAllocatedEmploymentsList: boolean
  pendingEmploymentDrawsList: boolean
  pendingAllocatedEmploymentDrawsList: boolean,
  allocationBatches: AllocationBatchType[]
  allocatedBatches: AllocationBatchType[]
  batchEmployments: BatchEmploymentType[]
  batchAllocatedEmployments: AllocatedBatchEmploymentType[]
  employmentDraws: DrawAllocationType[]
  allocatedEmploymentDraws: DrawAllocationType[]
  expandedBatches: BatchIDType[]
  expandedBatchEmployments: BatchEmploymentIDsType[],
  selectedBatches: BatchIDType[]
  selectedBatchEmployments: BatchEmploymentIDsType[]
  selectedEmploymentDraws: SelectedEmploymentDraw[]
  selectedAllocatedEmploymentDraws: SelectedEmploymentDraw[]
  pageNumberEmploymentsList: number,
  pageSizeEmploymentsList: number,
  numPagesEmploymentsList: number,
  totalSizeEmploymentsList: number,
  pageNumberAllocatedEmploymentsList: number,
  pageSizeAllocatedEmploymentsList: number,
  totalSizeAllocatedEmploymentsList: number,
  numPagesAllocatedEmploymentsList: number,
  allocationBatchesPageNumber: number,
  allocationBatchesPageSize: number,
  allocationBatchesTotalSize: number,
  allocationBatchesNumPages: number,
  allocatedBatchesPageNumber: number,
  allocatedBatchesPageSize: number,
  allocatedBatchesTotalSize: number,
  allocatedBatchesNumPages: number,
  allocatedTPOBatchEmployments: {
    pendingEmploymentsList: boolean,
    employmentsList: AllocatedTPOBatchEmploymentType[]
  }
  allocatedTPOBatchEmploymentPayments: {
    pendingEmploymentPaymentsList: boolean,
    employmentPaymentsList: TPOPaymentAllocationType[],
  },
  batchAllocationsCreated: boolean,
}

export const initialState = {
  pendingList: false,
  pendingAllocatedList: false,
  pendingAllocatedEmploymentsList: false,
  pendingEmploymentsList: false,
  pageNumberEmploymentsList: 1 as number,
  pageSizeEmploymentsList: 50 as number,
  totalSizeEmploymentsList: 0 as number,
  numPagesEmploymentsList: 1 as number,
  pageNumberAllocatedEmploymentsList: 1 as number,
  pageSizeAllocatedEmploymentsList: 50 as number,
  totalSizeAllocatedEmploymentsList: 0 as number,
  numPagesAllocatedEmploymentsList: 1 as number,
  allocationBatchesPageNumber: 1 as number,
  allocationBatchesPageSize: 50 as number,
  allocationBatchesTotalSize: 0 as number,
  allocationBatchesNumPages: 1 as number,
  allocatedBatchesPageNumber: 1 as number,
  allocatedBatchesPageSize: 50 as number,
  allocatedBatchesTotalSize: 0 as number,
  allocatedBatchesNumPages: 1 as number,
  pendingEmploymentDrawsList: false,
  pendingAllocatedEmploymentDrawsList: false,
  allocationBatches: [],
  allocatedBatches: [],
  batchEmployments: [],
  batchAllocatedEmployments: [],
  employmentDraws: [],
  allocatedEmploymentDraws: [],
  expandedBatches: [],
  expandedBatchEmployments: [],
  selectedBatches: [],
  selectedBatchEmployments: [],
  selectedEmploymentDraws: [],
  selectedAllocatedEmploymentDraws: [],
  allocatedTPOBatchEmployments: {
    pendingEmploymentsList: false,
    employmentsList: [],
  },
  allocatedTPOBatchEmploymentPayments: {
    pendingEmploymentPaymentsList: false,
    employmentPaymentsList: [],
  },
  batchAllocationsCreated: false,
} as PaymentAllocationType;

export const fetchBatchAllocations = createAsyncThunk(
  'repayments/allocations',
  async (params: FetchBatchAllocationsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID, pageNumber, payGroupId, payrollPeriodId,
    } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchBatchAllocationsRequest(storeState.user.accessToken, organizationID, pageNumber, payGroupId, payrollPeriodId);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchAllocatedBatchAllocations = createAsyncThunk(
  'repayments/allocated/allocations',
  async (params: FetchAllocatedBatchAllocationsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, pageNumber, repaymentId } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchAllocatedBatchAllocationsRequest(storeState.user.accessToken, organizationID, repaymentId, pageNumber);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchBatchEmployments = createAsyncThunk(
  'batch/employments',
  async (params: FetchBatchEmploymentsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { batchID, pageNumber } = params;
    const storeState = getState() as RootState;
    try {
      return await fetchBatchEmploymentsRequest(storeState.user.accessToken, batchID, pageNumber);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const fetchAllocatedBatchEmployments = createAsyncThunk(
  'batch/allocated/employments',
  async (params: FetchAllocatedBatchEmploymentsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { batchID, pageNumber, repaymentId } = params;
    const storeState = getState() as RootState;
    try {
      return await fetchAllocatedBatchEmploymentRequest(storeState.user.accessToken, batchID, repaymentId, pageNumber);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchAllocatedTPOBatchEmployments = createAsyncThunk(
  'tpo-batch/allocated/employments',
  async (params: FetchAllocatedTPOBatchEmploymentsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { batchID } = params;
    const storeState = getState() as RootState;
    try {
      return await fetchAllocatedTPOBatchEmploymentRequest(storeState.user.accessToken, batchID);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchAllocatedTPOBatchEmploymentPaymentAllocations = createAsyncThunk(
  'tpo-batch/batch-employment/payment-allocations',
  async (params: FetchBatchEmploymentDrawAllocationsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { batchID, employmentID } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchAllocatedTPOBatchEmploymentPaymentAllocationsRequest(storeState.user.accessToken, batchID, employmentID);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchBatchEmploymentDrawAllocations = createAsyncThunk(
  'repayments/batch-employment/draw-allocations',
  async (params: FetchBatchEmploymentDrawAllocationsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { batchID, employmentID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchBatchEmploymentDrawAllocationsRequest(storeState.user.accessToken, batchID, employmentID);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const fetchAllocatedBatchEmploymentDrawAllocations = createAsyncThunk(
  'repayments/batch-employment/paid/draw-allocations',
  async (params: FetchAllocatedBatchEmploymentDrawAllocationsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { batchID, employmentID, repaymentID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchAllocatedBatchEmploymentDrawAllocationsRequest(storeState.user.accessToken, batchID, repaymentID, employmentID);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createDrawAllocations = createAsyncThunk(
  'repayments/draw-allocations/create',
  async (params: CreateDrawAllocationsParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const {
      batchID,
      repaymentID,
      employmentID,
      data,
    } = params;

    const storeState = getState() as RootState;
    const { user, loggedOrganization } = storeState;

    try {
      const result = await createDrawAllocationsRequest(user.accessToken, batchID, repaymentID, employmentID, data);

      dispatch(fetchBatchAllocations({
        organizationID: loggedOrganization?.organization?.id,
        pageNumber: '1',
      }));

      dispatch(fetchBatchEmployments({
        batchID, pageNumber: '1',
      }));

      dispatch(fetchBatchEmploymentDrawAllocations({
        batchID,
        employmentID,
      }));

      dispatch(fetchAllocatedBatchAllocations({
        organizationID: loggedOrganization?.organization?.id,
        pageNumber: '1',
        repaymentId: repaymentID,
      }));
      dispatch(fetchAllocatedBatchEmployments({
        pageNumber: '1',
        batchID,
        repaymentId: repaymentID,
      }));

      dispatch(fetchAllocatedBatchEmploymentDrawAllocations({
        batchID,
        employmentID,
        repaymentID,
      }));

      const repayment = await fetchRepaymentRequest(user.accessToken, loggedOrganization?.organization?.id, repaymentID);
      dispatch(setRepayment(repayment));
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createBatchAllocations = createAsyncThunk(
  'repayments/batch-allocations/create',
  async (params: CreateBatchAllocationsParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const {
      repaymentID,
      data,
    } = params;

    const storeState = getState() as RootState;
    const { user, loggedOrganization } = storeState;

    try {
      const result = await createBatchAllocationsRequest(user.accessToken, repaymentID, data);

      dispatch(fetchBatchAllocations({
        organizationID: loggedOrganization?.organization?.id,
        pageNumber: '1',
      }));
      dispatch(fetchAllocatedBatchAllocations({
        organizationID: loggedOrganization?.organization?.id,
        pageNumber: '1',
        repaymentId: repaymentID,
      }));
      const repayment = await fetchRepaymentRequest(user.accessToken, loggedOrganization?.organization?.id, repaymentID);
      dispatch(setRepayment(repayment));
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createEmploymentAllocations = createAsyncThunk(
  'repayments/employment-allocations/create',
  async (params: CreateEmploymentAllocationsParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const {
      repaymentID,
      batchID,
      data,
    } = params;

    const storeState = getState() as RootState;
    const { user, loggedOrganization } = storeState;

    try {
      const result = await createEmploymentAllocationsRequest(user.accessToken, repaymentID, batchID, data);

      dispatch(fetchBatchAllocations({
        organizationID: loggedOrganization?.organization?.id,
        pageNumber: '1',
      }));

      dispatch(fetchBatchEmployments({
        batchID, pageNumber: '1',
      }));
      dispatch(fetchAllocatedBatchAllocations({
        organizationID: loggedOrganization?.organization?.id,
        pageNumber: '1',
        repaymentId: repaymentID,
      }));
      dispatch(fetchAllocatedBatchEmployments({
        pageNumber: '1',
        batchID,
        repaymentId: repaymentID,
      }));
      const repayment = await fetchRepaymentRequest(user.accessToken, loggedOrganization?.organization?.id, repaymentID);
      dispatch(setRepayment(repayment));
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const paymentAllocationSlice = createSlice({
  name: 'paymentAllocation',
  initialState,
  reducers: {
    setExpandedBatches: (state, action) => {
      state.expandedBatches = action.payload;
      state.selectedBatches = initialState.selectedBatches;
      state.expandedBatchEmployments = initialState.expandedBatchEmployments;
      state.selectedBatchEmployments = initialState.selectedBatchEmployments;
      state.selectedEmploymentDraws = initialState.selectedEmploymentDraws;
      state.batchEmployments = initialState.batchEmployments;
    },
    setExpandedEmployees: (state, action) => {
      state.expandedBatchEmployments = action.payload;
      state.selectedBatchEmployments = initialState.selectedBatchEmployments;

      if (action.payload?.length === 0) {
        state.selectedEmploymentDraws = initialState.selectedEmploymentDraws;
      }
    },
    setSelectedBatches: (state, action) => {
      state.selectedBatches = action.payload;
    },
    setSelectedEmployees: (state, action) => {
      state.selectedBatchEmployments = action.payload;
    },
    setSelectedEmploymentDraws: (state, action) => {
      state.selectedEmploymentDraws = action.payload;
    },
    resetPaymentAllocation: () => initialState,
    resetBatchAllocationsCreated: (state) => {
      state.batchAllocationsCreated = initialState.batchAllocationsCreated;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBatchAllocations.pending, (state) => {
      state.pendingList = true;
    });

    builder.addCase(fetchBatchAllocations.fulfilled, (state, { payload }) => {
      state.pendingList = false;
      state.allocationBatches = payload.values;
      state.allocationBatchesPageNumber = payload.pageNumber;
      state.allocationBatchesPageSize = payload.pageSize;
      state.allocationBatchesTotalSize = payload.totalSize;
      state.allocationBatchesNumPages = (Math.ceil(payload.totalSize / initialState.allocationBatchesPageSize));
    });

    builder.addCase(fetchBatchAllocations.rejected, (state) => {
      state.pendingList = false;
      state.allocationBatches = initialState.allocationBatches;
    });

    builder.addCase(fetchAllocatedBatchAllocations.pending, (state) => {
      state.pendingAllocatedList = true;
    });

    builder.addCase(fetchAllocatedBatchAllocations.fulfilled, (state, { payload }) => {
      state.pendingAllocatedList = false;
      state.allocatedBatches = payload.values;
      state.allocatedBatchesPageNumber = payload.pageNumber;
      state.allocatedBatchesPageSize = payload.pageSize;
      state.allocatedBatchesTotalSize = payload.totalSize;
      state.allocatedBatchesNumPages = (Math.ceil(payload.totalSize / initialState.allocatedBatchesPageSize));
    });

    builder.addCase(fetchAllocatedBatchAllocations.rejected, (state) => {
      state.pendingAllocatedList = false;
      state.allocatedBatches = initialState.allocatedBatches;
    });

    builder.addCase(fetchBatchEmployments.pending, (state) => {
      state.pendingEmploymentsList = true;
      state.batchEmployments = initialState.batchEmployments;
    });

    builder.addCase(fetchBatchEmployments.fulfilled, (state, { payload }) => {
      state.pendingEmploymentsList = false;
      state.batchEmployments = payload.values;
      state.pageNumberEmploymentsList = payload.pageNumber;
      state.pageSizeEmploymentsList = payload.pageSize;
      state.totalSizeEmploymentsList = payload.totalSize;
      state.numPagesEmploymentsList = (Math.ceil(payload.totalSize / initialState.pageSizeEmploymentsList));
    });

    builder.addCase(fetchBatchEmployments.rejected, (state) => {
      state.pendingEmploymentsList = false;
      state.batchEmployments = initialState.batchEmployments;
    });
    builder.addCase(fetchAllocatedBatchEmployments.pending, (state) => {
      state.pendingAllocatedEmploymentsList = true;
      state.batchAllocatedEmployments = initialState.batchAllocatedEmployments;
    });

    builder.addCase(fetchAllocatedBatchEmployments.fulfilled, (state, { payload }) => {
      state.pendingAllocatedEmploymentsList = false;
      state.batchAllocatedEmployments = payload.values;
      state.pageNumberAllocatedEmploymentsList = payload.pageNumber;
      state.pageSizeAllocatedEmploymentsList = payload.pageSize;
      state.totalSizeAllocatedEmploymentsList = payload.totalSize;
      state.numPagesAllocatedEmploymentsList = (Math.ceil(payload.totalSize / initialState.pageSizeAllocatedEmploymentsList));
    });

    builder.addCase(fetchAllocatedBatchEmployments.rejected, (state) => {
      state.pendingAllocatedEmploymentsList = false;
      state.batchAllocatedEmployments = initialState.batchAllocatedEmployments;
    });

    builder.addCase(fetchAllocatedTPOBatchEmployments.pending, (state) => {
      state.allocatedTPOBatchEmployments.pendingEmploymentsList = true;
      state.allocatedTPOBatchEmployments.employmentsList = initialState.allocatedTPOBatchEmployments.employmentsList;
    });

    builder.addCase(fetchAllocatedTPOBatchEmployments.fulfilled, (state, { payload }) => {
      state.allocatedTPOBatchEmployments.pendingEmploymentsList = false;
      state.allocatedTPOBatchEmployments.employmentsList = payload;
    });

    builder.addCase(fetchAllocatedTPOBatchEmployments.rejected, (state) => {
      state.allocatedTPOBatchEmployments.pendingEmploymentsList = false;
      state.allocatedTPOBatchEmployments.employmentsList = initialState.allocatedTPOBatchEmployments.employmentsList;
    });

    builder.addCase(fetchAllocatedTPOBatchEmploymentPaymentAllocations.pending, (state) => {
      state.allocatedTPOBatchEmploymentPayments.pendingEmploymentPaymentsList = true;
    });

    builder.addCase(fetchAllocatedTPOBatchEmploymentPaymentAllocations.fulfilled, (state, action) => {
      state.allocatedTPOBatchEmploymentPayments.pendingEmploymentPaymentsList = false;
      state.allocatedTPOBatchEmploymentPayments.employmentPaymentsList = action.payload;
    });

    builder.addCase(fetchAllocatedTPOBatchEmploymentPaymentAllocations.rejected, (state) => {
      state.allocatedTPOBatchEmploymentPayments.pendingEmploymentPaymentsList = false;
    });

    builder.addCase(createDrawAllocations.pending, (state) => {
      state.pendingEmploymentDrawsList = true;
    });

    builder.addCase(createDrawAllocations.fulfilled, (state) => {
      state.pendingEmploymentDrawsList = false;
    });

    builder.addCase(createDrawAllocations.rejected, (state) => {
      state.pendingEmploymentDrawsList = false;
    });

    builder.addCase(createBatchAllocations.pending, (state) => {
      state.pendingList = true;
    });

    builder.addCase(createBatchAllocations.fulfilled, (state) => {
      state.pendingList = false;
      state.batchAllocationsCreated = true;
    });

    builder.addCase(createBatchAllocations.rejected, (state) => {
      state.pendingList = false;
    });

    builder.addCase(createEmploymentAllocations.pending, (state) => {
      state.pendingEmploymentsList = true;
    });

    builder.addCase(createEmploymentAllocations.fulfilled, (state) => {
      state.pendingEmploymentsList = false;
    });

    builder.addCase(createEmploymentAllocations.rejected, (state) => {
      state.pendingEmploymentsList = false;
    });

    builder.addCase(fetchBatchEmploymentDrawAllocations.pending, (state) => {
      state.pendingEmploymentDrawsList = true;
    });

    builder.addCase(fetchBatchEmploymentDrawAllocations.fulfilled, (state, action) => {
      state.employmentDraws = action.payload;
      state.pendingEmploymentDrawsList = false;
      state.selectedEmploymentDraws = action.payload?.map((item: DrawAllocationType) => ({
        drawID: item?.id,
        amount: item?.amountDue,
      }));
    });

    builder.addCase(fetchBatchEmploymentDrawAllocations.rejected, (state) => {
      state.pendingEmploymentDrawsList = false;
    });

    builder.addCase(fetchAllocatedBatchEmploymentDrawAllocations.pending, (state) => {
      state.pendingAllocatedEmploymentDrawsList = true;
    });

    builder.addCase(fetchAllocatedBatchEmploymentDrawAllocations.fulfilled, (state, action) => {
      state.allocatedEmploymentDraws = action.payload;
      state.pendingAllocatedEmploymentDrawsList = false;
      state.selectedAllocatedEmploymentDraws = action.payload?.map((item: DrawAllocationType) => ({
        drawID: item?.id,
        amount: item?.amountDue,
      }));
    });

    builder.addCase(fetchAllocatedBatchEmploymentDrawAllocations.rejected, (state) => {
      state.pendingAllocatedEmploymentDrawsList = false;
    });
  },
});

export const {
  resetPaymentAllocation,
  setExpandedBatches,
  setExpandedEmployees,
  setSelectedBatches,
  setSelectedEmployees,
  setSelectedEmploymentDraws,
  resetBatchAllocationsCreated,
} = paymentAllocationSlice.actions;
export const pendingEmploymentDrawsSelector = (state: RootState) => state.paymentAllocation.pendingEmploymentDrawsList;
export const pendingAllocatedEmploymentDrawsSelector = (state: RootState) => state.paymentAllocation.pendingAllocatedEmploymentDrawsList;
export const employmentDrawsSelector = (state: RootState) => state.paymentAllocation.employmentDraws;
export const allocatedEmploymentDrawsSelector = (state: RootState) => state.paymentAllocation.allocatedEmploymentDraws;
export const allocationBatchesSelector = (state: RootState) => state.paymentAllocation.allocationBatches;
export const allocatedBatchesSelector = (state: RootState) => state.paymentAllocation.allocatedBatches;
export const allocationBatchesPageNumberSelector = (state: RootState) => state.paymentAllocation.allocationBatchesPageNumber;
export const allocationBatchesPageSizeSelector = (state: RootState) => state.paymentAllocation.allocationBatchesPageSize;
export const allocationBatchesNumPagesSelector = (state: RootState) => state.paymentAllocation.allocationBatchesNumPages;
export const allocationBatchesTotalSizeSelector = (state: RootState) => state.paymentAllocation.allocationBatchesTotalSize;
export const allocatedBatchesPageNumberSelector = (state: RootState) => state.paymentAllocation.allocatedBatchesPageNumber;
export const allocatedBatchesPageSizeSelector = (state: RootState) => state.paymentAllocation.allocatedBatchesPageSize;
export const allocatedBatchesTotalSizeSelector = (state: RootState) => state.paymentAllocation.allocatedBatchesTotalSize;
export const allocatedBatchesNumPagesSelector = (state: RootState) => state.paymentAllocation.allocatedBatchesNumPages;
export const expandedBatchesSelector = (state: RootState) => state.paymentAllocation.expandedBatches;
export const expandedBatchEmploymentsSelector = (state: RootState) => state.paymentAllocation.expandedBatchEmployments;
export const selectedBatchesSelector = (state: RootState) => state.paymentAllocation.selectedBatches;
export const selectedBatchEmploymentsSelector = (state: RootState) => state.paymentAllocation.selectedBatchEmployments;
export const selectedEmploymentDrawsSelector = (state: RootState): SelectedEmploymentDraw[] => state.paymentAllocation.selectedEmploymentDraws;
export const selectedAllocatedEmploymentDrawsSelector = (state: RootState) => state.paymentAllocation.selectedAllocatedEmploymentDraws;
export const batchEmploymentsSelector = (state: RootState) => state.paymentAllocation.batchEmployments;
export const batchAllocatedEmploymentsSelector = (state: RootState) => state.paymentAllocation.batchAllocatedEmployments;
export const batchEmploymentsPendingListSelector = (state: RootState) => state.paymentAllocation.pendingEmploymentsList;
export const batchAllocatedEmploymentsPendingListSelector = (state: RootState) => state.paymentAllocation.pendingAllocatedEmploymentsList;
export const batchesPendingListSelector = (state: RootState) => state.paymentAllocation.pendingList;
export const batchesPendingAllocatedListSelector = (state: RootState) => state.paymentAllocation.pendingAllocatedList;
export const batchAllocationsCreatedSelector = (state: RootState): boolean => state.paymentAllocation.batchAllocationsCreated;
export const totalSizeEmploymentsList = (state: RootState) => state.paymentAllocation.totalSizeEmploymentsList;
export const pageSizeEmploymentsList = (state: RootState) => state.paymentAllocation.pageSizeEmploymentsList;
export const numPagesEmploymentsList = (state: RootState) => state.paymentAllocation.numPagesEmploymentsList;
export const pageNumberEmploymentsList = (state: RootState) => state.paymentAllocation.pageNumberEmploymentsList;
export const totalSizeAllocatedEmploymentsList = (state: RootState) => state.paymentAllocation.totalSizeAllocatedEmploymentsList;
export const pageSizeAllocatedEmploymentsList = (state: RootState) => state.paymentAllocation.pageSizeAllocatedEmploymentsList;
export const pageNumberAllocatedEmploymentsList = (state: RootState) => state.paymentAllocation.pageNumberAllocatedEmploymentsList;
export const numPagesAllocatedEmploymentsList = (state: RootState) => state.paymentAllocation.numPagesAllocatedEmploymentsList;

// eslint-disable-next-line max-len
export const allocatedTPOBatchEmploymentsPendingListSelector = (state: RootState) => state.paymentAllocation.allocatedTPOBatchEmployments.pendingEmploymentsList;
export const allocatedTPOBatchEmploymentsList = (state: RootState) => state.paymentAllocation.allocatedTPOBatchEmployments.employmentsList;
// eslint-disable-next-line max-len
export const allocatedTPOBatchEmploymentPaymentAllocationsPendingSelector = (state: RootState) => state.paymentAllocation.allocatedTPOBatchEmploymentPayments.pendingEmploymentPaymentsList;
// eslint-disable-next-line max-len
export const allocatedTPOBatchEmploymentPaymentAllocationsListSelector = (state: RootState) => state.paymentAllocation.allocatedTPOBatchEmploymentPayments.employmentPaymentsList;

export default paymentAllocationSlice.reducer;
