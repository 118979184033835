import {
  createContext,
  useEffect,
} from 'react';
import { Layer } from 'baseui/layer';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { zendeskTicketValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  ALIGNMENT,
} from 'baseui/layout-grid';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import { employeeZendeskInitialValues as initialValues } from 'initialValues/EmployeeInitialValues';
import AppModal from 'components/AppModal/AppModal';
import {
  EmployeeZendeskValuesType,
  ZendeskTicketModalPropsType,
  ZendeskTicketValuesType,
} from 'types/EmployeeTypes';
import AppInput from 'components/Form/AppInput';
import AppSelect from 'components/Form/AppSelect';
import {
  saveZendeskTicketMapper,
  zendeskTicketMapper,
} from 'dataMappers/organizationsDataMappers';
import {
  employmentZendeskTicketFormPendingSelector,
  employmentZendeskTicketSavedSelector,
  saveZendeskTicket,
} from 'store/slices/employees';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import Loader from 'components/Loader';

export const EmployeeZendeskFormContext = createContext(
  {} as FormikState<EmployeeZendeskValuesType> & FormikHelpers<EmployeeZendeskValuesType> & FormikHandlers,
);

const ZendeskModal = ({
  drawID,
  zendeskTicket,
}: ZendeskTicketModalPropsType) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['employees', 'organizations', 'errors', 'common', 'locations']);
  const zendeskTicketSaved = useAppSelector(employmentZendeskTicketSavedSelector);
  const modals = useAppSelector(modalsSelector);
  const pending = useAppSelector(employmentZendeskTicketFormPendingSelector);
  const modalName = ModalNames.ZENDESK_ADD_MODAL;
  const isModalOpen = checkIsModalOpen(modals, modalName);
  const ticketStatuses = [
    { value: 'OPEN', label: t('employees:draws.zendeskStatuses.OPEN') },
    { value: 'RESOLVED', label: t('employees:draws.zendeskStatuses.RESOLVED') },
  ];

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const onSubmit = (
    values: ZendeskTicketValuesType,
  ) => {
    dispatch(saveZendeskTicket({
      drawID,
      data: saveZendeskTicketMapper(values),
    }))
      .then(() => {
        setIsModalOpen(false);
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    resetForm,
    isSubmitting,
    setSubmitting,
    setValues,
    validateForm,
  } = formik;

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    resetForm();
    setSubmitting(false);
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      setSubmitting(false);
      resetForm();
    }
  }, [isModalOpen]);

  useEffect(() => {
    zendeskTicket && setValues({
      ...zendeskTicketMapper(zendeskTicket),
    });
  }, [zendeskTicket]);

  useEffect(() => {
    zendeskTicketSaved && setSubmitting(false);
  }, [zendeskTicketSaved]);

  return (
    <Layer index={300}>
      <EmployeeZendeskFormContext.Provider value={formik}>
        <form>
          <AppModal
            modal={modalName}
            title="Zendesk"
            cancelBtnText={t('common:cancel')}
            onClose={handleModalClose}
            actionBtnText={t('common:save')}
            onAction={handleSubmitExtended}
            isCloseDisabled={isSubmitting}
          >
            <Loader active={pending} />

            <Grid
              align={ALIGNMENT.start}
            >
              <AppInput
                name="ticketNumber"
                label={t('employees:ticketNumber')}
                cellSpan={6}
                context={EmployeeZendeskFormContext}
                inputProps={{
                  autoComplete: 'off',
                }}
              />

              <AppSelect
                name="ticketStatuses"
                label={t('employees:ticketStatuses')}
                cellSpan={6}
                clearable={false}
                options={ticketStatuses}
                context={EmployeeZendeskFormContext}
                selectProps={{
                  overrides: {
                    ControlContainer: {
                      props: {
                        'data-testid': 'control-container',
                      },
                    },
                    Input: {
                      props: {
                        id: 'ZendeskModal-select-organization',
                        autoComplete: 'off',
                      },
                    },
                  },
                }}
              />
            </Grid>
          </AppModal>
        </form>
      </EmployeeZendeskFormContext.Provider>
    </Layer>
  );
};

export default ZendeskModal;
