import { useStyletron } from 'styletron-react';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import PriceFormatter from 'utils/priceFormatter';
import {
  outerContainerStyles,
} from 'screens/Batches/BatchesHelpers';
import { unScalePrice } from 'utils/priceScale';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TPOPaymentAllocationType } from 'types/RepaymentTypes';

export type TPOAllocatedPaymentsPaymentListItemPropsType = {
  payment: TPOPaymentAllocationType
}

const TPOAllocatedPaymentsPaymentListItem = ({
  payment,
}: TPOAllocatedPaymentsPaymentListItemPropsType) => {
  const {
    allocatedAmount,
    totalAmount,
    createdDate,
    location,
  } = payment || {};

  const [css] = useStyletron();
  const { t } = useTranslation(['dateFormats']);

  const dateFormat = t('dateFormats:standard-with-time');
  const unscaledTotalAmount = unScalePrice(totalAmount?.value, totalAmount?.scale);
  const unscaledAllocatedAmount = unScalePrice(allocatedAmount?.value, allocatedAmount?.scale);

  return (
    <div className={css(outerContainerStyles)}>
      <Grid
        gridColumns={12}
        gridMargins={0}
      >
        <Cell
          align={ALIGNMENT.center}
          span={3}
        >
          {moment(createdDate).format(dateFormat)}
        </Cell>

        <Cell
          span={3}
          align={ALIGNMENT.center}
        >
          {location.name}
        </Cell>

        <Cell
          span={2}
          align={ALIGNMENT.center}
        >
          {PriceFormatter().format(unscaledTotalAmount)}
        </Cell>

        <Cell
          span={2}
          align={ALIGNMENT.center}
        >
          {PriceFormatter().format(unscaledAllocatedAmount)}
        </Cell>

      </Grid>
    </div>
  );
};

export default TPOAllocatedPaymentsPaymentListItem;
