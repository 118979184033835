import { createTheme } from 'baseui';

export const pageSize = 10;

export const colors = {
  primary: '#BB002F',
  primaryHover: '#a1032a',
  secondary: '#EEEEEE',
  secondaryHover: '#ccc',
  tertiary: '#fff',
  tertiaryHover: '#fff',
  header: '#fff',
  primaryGradient: 'linear-gradient(#CC0033 44.16%, #DC3131 93.05%);',
  info: '#757575',
  warning: '#F5A623',
};

export const borderRadius = '4px';

export const emptyPlaceholder = 'N/A';

const primitives = {
  accent: colors.primary,
  accentHover: colors.primary,
  secondary: colors.secondary,
  secondaryHover: colors.secondaryHover,
  tertiary: colors.tertiary,
  tertiaryHover: colors.tertiaryHover,
  borderRadius,
};

const overrides = {
  colors: {
    checkBoxPrimaryFill: primitives.accent,

    buttonPrimaryFill: primitives.accent,
    buttonPrimaryText: '#fff',
    buttonPrimaryHover: primitives.accentHover,
    buttonPrimaryActive: primitives.accent,
    buttonPrimarySelectedFill: primitives.accent,
    buttonPrimarySelectedText: primitives.accent,
    buttonPrimarySpinnerForeground: primitives.accent,
    buttonPrimarySpinnerBackground: primitives.accent,

    buttonSecondaryFill: primitives.secondary,
    buttonSecondaryText: primitives.accent,
    buttonSecondaryHover: primitives.secondaryHover,
    buttonSecondaryActive: primitives.secondary,
    buttonSecondarySelectedFill: primitives.secondary,
    buttonSecondarySelectedText: primitives.accent,
    buttonSecondarySpinnerForeground: primitives.secondary,
    buttonSecondarySpinnerBackground: primitives.secondary,

    buttonTertiaryFill: primitives.tertiary,
    buttonTertiaryText: primitives.accent,
    buttonTertiaryHover: primitives.tertiaryHover,
    buttonTertiaryActive: primitives.tertiary,
    buttonTertiarySelectedFill: primitives.tertiary,
    buttonTertiarySelectedText: primitives.accent,
    buttonTertiarySpinnerForeground: primitives.tertiary,
    buttonTertiarySpinnerBackground: primitives.tertiary,

    tickBorder: primitives.accent,
    tickFillActive: primitives.accent,
    tickFillSelected: primitives.accent,
    tickFillSelectedHover: primitives.accent,

    inputBorder: '#757575',
    inputFill: '#fff',
    inputFillError: '#fff',
    inputFillDisabled: '#eee',
    inputFillActive: '#fff',
    inputFillPositive: '#fff',
    inputTextDisabled: 'rgb(110 110 110)',
    inputBorderError: '#E11900',
    inputBorderPositive: '#048848',
    inputEnhancerFill: '#fff',
    inputEnhancerFillDisabled: '#1F1F1F',
    inputEnhancerTextDisabled: 'rgb(110 110 110)',
    inputPlaceholder: '#6B6B6B',
    inputPlaceholderDisabled: 'rgb(110 110 110)',

    tagPrimarySolidBackground: primitives.accent,
    tagPrimaryOutlinedFont: primitives.accent,
  },
  borders: {
    buttonBorderRadius: borderRadius,
    inputBorderRadius: borderRadius,
  },
  grid: {
    maxWidth: 1840,
  },
  block: {
    maxWidth: 1840,
  },
  typography: {
    HeadingXSmall: {
      fontWeight: 500,
    },
  },
};

export const AppTheme = createTheme(primitives, overrides);
