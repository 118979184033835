import { memo, useEffect } from 'react';
import {
  useMsal, useIsAuthenticated,
} from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLoader } from 'store/slices/loader';
import { userSelector, login } from 'store/slices/user';
import { AuthenticationResult } from '@azure/msal-browser';
import { AccessUnit } from 'components/Access/Access';
import { fetchAllOrganizations, maintenanceSelector } from 'store/slices/organizations';

const Login = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const shouldGoIntoMaintenance = useAppSelector(maintenanceSelector);
  const user = useAppSelector(userSelector);
  const {
    accessToken,
    accessUnits,
    organizationUnits,
  } = user;

  const redirectToPage = (pageEndpoint: string) => {
    setTimeout(() => history.push(shouldGoIntoMaintenance ? '/maintenance' : pageEndpoint), 1000);
  };

  const startLogin = () => {
    dispatch(login(instance))
      .then(() => {
        if (inProgress === 'none') {
          dispatch(setLoader({ active: false }));
        }
      }).catch(() => {
        dispatch(setLoader({ active: false }));
      });
  };

  useEffect(() => {
    dispatch(setLoader({ active: true }));
    instance.handleRedirectPromise().then(
      (info: AuthenticationResult | null) => {
        if (!info) startLogin();
      },
    ).catch(() => {
      startLogin();
    });
    return () => {
      dispatch(setLoader({ active: false }));
    };
  }, []);

  useEffect(() => {
    if (inProgress === 'none' && isAuthenticated && accessToken) {
      if (accessUnits.some(
        (unit) => [AccessUnit.EWAClientManager].includes(unit),
      )) {
        if (organizationUnits.length === 1) {
          // TODO: refactor the approach as this could prove wrong if the call for fetchOrgs is slow
          dispatch(fetchAllOrganizations({ pageSize: '1000' }));
          redirectToPage('/loggedOrganization/employees');
        } else {
          redirectToPage('/choose-organization');
        }
      } else if (accessUnits.includes(AccessUnit.EWAManager)) {
        redirectToPage('/choose-organization');
      } else if (accessUnits.some(
        (unit) => [AccessUnit.TreasuryManager, AccessUnit.FBOManager, AccessUnit.TreasuryReader, AccessUnit.FBOReader].includes(unit),
      )) {
        redirectToPage('/treasury');
      } else if (accessUnits.length === 1 && accessUnits[0] === AccessUnit.TipsReader) {
        redirectToPage('/integrationData');
      } else if (accessUnits.includes(AccessUnit.EWAClientLocationManager)) {
        // TODO: refactor the approach as this could prove wrong if the call for fetchOrgs is slow
        dispatch(fetchAllOrganizations({ pageSize: '1000' }));
        redirectToPage('/tips');
      }
    }
  }, [inProgress, accessToken, isAuthenticated]);

  return null;
};

export default memo(Login);
