import {
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Block } from 'baseui/block';
import { Grid, ALIGNMENT, Cell } from 'baseui/layout-grid';
import { HeadingXSmall } from 'baseui/typography';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  fetchDestinationAccounts,
  repaymentsOrganizationDestinationAccountsFetchFailedSelector,
  repaymentsOrganizationDestinationAccountsSelector,
  fetchOrganizationSettings,
  organizationSettingsPendingSelector,
  organizationSettingsFailedSelector,
  saveOrganizationSettings,
  organizationSettingsDataSelector,
} from 'store/slices/repaymentsBankDetails';
import { organizationSelector } from 'store/slices/organizations';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import { ModalNames, setModal } from 'store/slices/modals';
import { OnChangeParams, Select, SIZE } from 'baseui/select';
import { FormControl } from 'baseui/form-control';

const OrganizationFormOrganizationMapping = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['organizations', 'errors', 'common']);
  const organizationSettingsFetchFailed = useAppSelector(organizationSettingsFailedSelector);
  const organizationSettingsData = useAppSelector(organizationSettingsDataSelector);
  const pending = useAppSelector(organizationSettingsPendingSelector);
  const destinationAccounts = useAppSelector(repaymentsOrganizationDestinationAccountsSelector);
  const destinationAccountsFetchFailed = useAppSelector(repaymentsOrganizationDestinationAccountsFetchFailedSelector);
  const organization = useAppSelector(organizationSelector);
  const { id: organizationID, name: organizationName } = organization || {};
  const [destinationSelected, setDestinationSelected] = useState<OnChangeParams>();
  const isClearAction = destinationSelected?.type === 'clear';

  const mappedDestinationAccounts = destinationAccounts.map(
    (item: { id: number; displayName: string }) => ({
      value: item.id,
      label: item.displayName,
    }),
  );

  const chooseOgraizationDestinationAccountConfirm = () => {
    organizationName && dispatch(saveOrganizationSettings({
      organizationID,
      data: {
        organizationName,
        destinationAccountId: destinationSelected?.option?.value,
      },
    })).then(() => {
      dispatch(fetchOrganizationSettings({ organizationID }));
      dispatch(fetchDestinationAccounts());
    });
  };

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: ModalNames.ORGANIZATION_DESTINATION_ACCOUNT_MODAL,
      isOpen,
    }));
  };

  return (
    <Block>
      <Grid
        gridColumns={12}
        gridGutters={0}
        align={ALIGNMENT.start}
        gridMargins={0}
        gridGaps={16}
      >
        <Cell
          span={12}
          align={ALIGNMENT.start}
        >
          <HeadingXSmall margin={0}>{t('organizations:bankDetails.organizationMapping.header')}</HeadingXSmall>
        </Cell>

        <Cell
          span={[12, 6, 3]}
        >
          <FormControl
            label={t('organizations:bankDetails.organizationMapping:destinationAccount')}
            error={organizationSettingsFetchFailed ? t('errors:unableToLoadData') : ''}
            overrides={{
              Label: {
                style: {
                  lineHeight: '20px',
                },
              },
              ControlContainer: {
                props: {
                  'data-testid': 'FormControl-organization-destination-account',
                  id: 'FormControl-organization-destination-account',
                },
              },
            }}
          >
            <Select
              id="destinationAccountId"
              data-testid="destinationAccountId"
              size={SIZE.compact}
              type="select"
              clearable={!!organizationSettingsData?.destinationAccountId}
              noResultsMsg={destinationAccountsFetchFailed ? t('errors:errorOnLoadingData') : t('common:noResults')}
              options={mappedDestinationAccounts}
              isLoading={pending}
              onChange={(params) => {
                setIsModalOpen(true);
                setDestinationSelected(params);
              }}
              labelKey="label"
              valueKey="value"
              value={[
                {
                  value: organizationSettingsData?.destinationAccountId,
                  label: organizationSettingsData?.destinationAccountDisplayName,
                },
              ]}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'OrganizationFormBankingSection-organizationMapping-select',
                    id: `OrganizationFormBankingSection-organizationMapping-select-${organizationID}`,
                  },
                },
              }}
            />
          </FormControl>
        </Cell>
      </Grid>
      <ConfirmModal
        onAction={chooseOgraizationDestinationAccountConfirm}
        title={isClearAction
          ? t(
            'organizations:bankDetails.organizationMapping.confirmationTitleDelete',
            { organizationName },
          )
          : t(
            'organizations:bankDetails.organizationMapping.confirmationTitle',
            { destinationName: destinationSelected?.option?.label, organizationName },
          )}
        actionBtnText={t('common:confirm.ok')}
        modalNameSpecified="ORGANIZATION_DESTINATION_ACCOUNT_MODAL"
      />
    </Block>
  );
};

export default OrganizationFormOrganizationMapping;
