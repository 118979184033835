import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface Sections {
  activeSection: number,
  wizardActiveSection: number,
  wizardSectionsList: string[]
}

export const initialState: Sections = {
  activeSection: 0 as number,
  wizardActiveSection: 0 as number,
  wizardSectionsList: [],
};

const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    setActiveSections: (state: Sections, { payload }): void => {
      state.activeSection = payload;
    },
    resetActiveSections: (state) => {
      state.activeSection = initialState.activeSection;
    },
    setWizardActiveSections: (state: Sections, { payload }): void => {
      state.wizardActiveSection = payload;
    },
    resetWizardActiveSections: (state) => {
      state.wizardActiveSection = initialState.activeSection;
    },
    setWizardSections: (state: Sections, { payload }): void => {
      state.wizardSectionsList = payload;
    },
  },
});

export const {
  setActiveSections,
  resetActiveSections,
  setWizardActiveSections,
  resetWizardActiveSections,
  setWizardSections,
} = sectionsSlice.actions;

export const sectionSelector = (state: RootState): number => state.sections.activeSection;
export const wizardSectionSelector = (state: RootState): number => state.sections.wizardActiveSection;
export const wizardSectionsSelector = (state: RootState): string[] => state.sections.wizardSectionsList;

export default sectionsSlice.reducer;
