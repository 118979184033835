import {
  LocationPaycardConfigTypeInitialValues,
  locationConfigInitialValues,
  locationInitialValues,
} from 'initialValues/LocationsInitialValues';
import {
  ConfigType,
  OrganizationIDType,
} from './OrganizationTypes';
import {
  PayGroupDTOType,
  PayGroupIDType,
} from './PayGroupTypes';
import { WorkerIDType } from './WorkerTypes';

export type LocationValuesType = typeof locationInitialValues;
export type LocationConfigValuesType = typeof locationConfigInitialValues;

export type LocationIDType = string | null | undefined;

export type SaveLocationRequestType = {
  id: number
  externalLocationId: string
  externalIntegrationId?: string | null
  posId: string | null
  timezone: string
  businessDayStartTime: string
  name: string
  payGroup?: PayGroupDTOType
  status: string
  tipsProviderId?: string | null
  storeNumber?: string
  alphaNumericStoreNumber?: string
  conceptId?: string
  bankAccount?: {
    bankAccountId: string | null
    bankAccountAlias: string | null
  }
}

export type PatchLocationRequestType = {
  status?: LocationStatus,
  bankAccount?: {
    bankAccountId: string | null,
    bankAccountAlias: string | null
  }
}

export type LocationResponseType = {
  id: number
  externalLocationId: string
  externalIntegrationId: string | undefined
  posId: string
  name: string
  timezone: string
  businessDayStartTime: string
  payGroup: PayGroupDTOType | null
  status: LocationStatus
  tipsProviderId?: string
  storeNumber?: string
  alphaNumericStoreNumber?: string
  conceptId?: string
  bankAccount?: {
    bankAccountId: string | null
    bankAccountAlias: string | null
  }
}

export type FetchWorkerOffersParamsType = {
  employeeID: WorkerIDType
}

export type FetchLocationsParamsType = {
  organizationID: OrganizationIDType
  pageSize?: string
  onlyTcoEnabled?: boolean
}

export type FetchLocationParamsType = {
  organizationID: OrganizationIDType
  locationID?: LocationIDType
  payGroupID?: PayGroupIDType
}

export type FetchLocationPaycardConfigParamsType = {
  organizationId: OrganizationIDType
  locationId: LocationIDType
}

export type EditLocationPaycardConfigType = {
  organizationId: OrganizationIDType
  locationId: LocationIDType
  data: SaveLocationPaycardConfigType
}

export type CreateLocationParamsType = {
  organizationID: OrganizationIDType
  data: SaveLocationRequestType
  configs: SaveLocationConfigRequestType
}

export type ImportLocationsPropsType = {
  organizationID: OrganizationIDType
  formData: FormData
}

export type EditLocationParamsType = {
  organizationID: OrganizationIDType
  locationID: LocationIDType
  data: SaveLocationRequestType
  configs: SaveLocationConfigRequestType
}

export type EditLocationConfigsParamsType = {
  organizationID: OrganizationIDType
  locationID: LocationIDType
  configs: SaveLocationConfigRequestType
}

export type UpdateLocationStatusPropsType = {
  organizationID: OrganizationIDType
  locationID: LocationIDType
  data: {
    status?: LocationStatus
    bankAccount?: {
      bankAccountId: string | null,
      bankAccountAlias: string | null
    }
  }
}

export type GenerateLocationsConfigsReportParamsType = {
  organizationID: OrganizationIDType
}

export type OrganizationFormLocationModalPropsType = {
  locationID: LocationIDType
}

export type OrganizationPreviewLocationModalPropsType = {
  locationID: LocationIDType
}

export enum LocationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type LocationConfig = {
  id?: number
  locationId?: LocationIDType
  configKeyName: ConfigType
  configValue: string | null
}

export type SaveLocationConfigRequestType = LocationConfigResponseType

export type LocationConfigResponseType = LocationConfig[]

export type LocationPaycardConfigType = typeof LocationPaycardConfigTypeInitialValues;

export type SaveLocationPaycardConfigType = {
  locationName: string
  locationExternalId: number
  nonPersonalizedCardsProgram: {
    programId: number
    enabled: boolean
    deliveryAddress: {
      state: string
      city: string
      postalCode: string
      addressLine1: string
      addressLine2: string
      country: string
    };
  };
};
