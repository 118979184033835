import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { DailyShifts, FetchUniversalCalendarDailyShiftsPropsType, Shift } from 'types/WorkerTypes';
import { fetchDailyShiftsRequest, fetchOpenShiftsRequest } from 'universalCalendarApi/shifts';

export const initialState = {
  selectedWorkerID: '',
  dailyShifts: {
    isUniversalCalendarSupported: true,
    list: {} as DailyShifts[],
    pendingList: false,
  },
  openShifts: {
    isUniversalCalendarSupported: true,
    list: [] as Shift[],
    pendingList: false,
  },
  shiftsSearch: {
    organization: [] as any,
    location: [] as any,
    date: undefined as any,
  },
};

export const fetchUniversalCalendarDailyShifts = createAsyncThunk(
  'universalCalendar/dailyShifts',
  async (params: FetchUniversalCalendarDailyShiftsPropsType, { getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken } } = getState() as RootState;
    const {
      workerID,
      fromDate,
      organizationID,
      locationID,
    } = params;

    try {
      return await fetchDailyShiftsRequest(
        accessToken,
        workerID,
        fromDate,
        organizationID,
        locationID,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchUniversalCalendarOpenShifts = createAsyncThunk(
  'universalCalendar/openShifts',
  async (params: FetchUniversalCalendarDailyShiftsPropsType, { getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken } } = getState() as RootState;
    const {
      workerID,
      fromDate,
      organizationID,
      locationID,
    } = params;

    try {
      return await fetchOpenShiftsRequest(
        accessToken,
        workerID,
        fromDate,
        organizationID,
        locationID,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const universalCalendarSlice = createSlice({
  name: 'universalCalendar',
  initialState,
  reducers: {
    setSelectedWorkerID: (state, action) => {
      state.selectedWorkerID = action.payload;
    },
    resetUniversalCalendarOpenShiftsList: (state) => {
      state.openShifts.list = initialState.openShifts.list;
    },
    resetUniversalCalendarDailyShiftsList: (state) => {
      state.dailyShifts.list = initialState.dailyShifts.list;
    },
    setSearchUniversalCalendarShiftsOrganization: (state, action) => {
      state.shiftsSearch.organization = action.payload;
    },
    setSearchUniversalCalendarShiftsLocation: (state, action) => {
      state.shiftsSearch.location = action.payload;
    },
    setSearchUniversalCalendarShiftsDate: (state, action) => {
      state.shiftsSearch.date = action.payload;
    },
    resetSearchUniversalCalendarShifts: (state) => {
      state.shiftsSearch = initialState.shiftsSearch;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUniversalCalendarDailyShifts.pending, (state) => {
      state.dailyShifts.pendingList = true;
      state.dailyShifts.list = initialState.dailyShifts.list;
    });

    builder.addCase(fetchUniversalCalendarDailyShifts.fulfilled, (state, action) => {
      state.dailyShifts.list = action.payload.values;
      state.dailyShifts.pendingList = false;
      state.dailyShifts.isUniversalCalendarSupported = action.payload.universalCalendarSupported;
    });

    builder.addCase(fetchUniversalCalendarDailyShifts.rejected, (state) => {
      state.dailyShifts.list = initialState.dailyShifts.list;
      state.dailyShifts.pendingList = false;
    });

    builder.addCase(fetchUniversalCalendarOpenShifts.pending, (state) => {
      state.openShifts.pendingList = true;
    });

    builder.addCase(fetchUniversalCalendarOpenShifts.fulfilled, (state, action) => {
      if (state.openShifts.list.length === 0) {
        action.payload.values.forEach((shift: Shift) => {
          shift.date = action.meta.arg.fromDate;
          state.openShifts.list.push(shift);
        });
      } else {
        action.payload.values.forEach((shift: Shift) => {
          const index = state.openShifts.list.findIndex((item: Shift) => item.id === shift.id);
          if (index === -1) {
            shift.date = action.meta.arg.fromDate;
            state.openShifts.list.push(shift);
          }
        });
      }

      state.openShifts.isUniversalCalendarSupported = action.payload.universalCalendarSupported;
      state.openShifts.pendingList = false;
    });

    builder.addCase(fetchUniversalCalendarOpenShifts.rejected, (state) => {
      state.openShifts.list = initialState.openShifts.list;
      state.openShifts.pendingList = false;
    });
  },
});

export const {
  setSelectedWorkerID,
  resetUniversalCalendarOpenShiftsList,
  resetUniversalCalendarDailyShiftsList,
  setSearchUniversalCalendarShiftsOrganization,
  setSearchUniversalCalendarShiftsLocation,
  setSearchUniversalCalendarShiftsDate,
  resetSearchUniversalCalendarShifts,
} = universalCalendarSlice.actions;

export const universalCalendarDailyShiftsPendingSelector = (state: RootState) => state.universalCalendar.dailyShifts.pendingList;
export const universalCalendarDailyShiftsIsSupportedSelector = (state: RootState) => state.universalCalendar.dailyShifts.isUniversalCalendarSupported;
export const universalCalendarDailyShiftsListSelector = (state: RootState) => state.universalCalendar.dailyShifts.list;
export const universalCalendarOpenShiftsPendingSelector = (state: RootState) => state.universalCalendar.openShifts.pendingList;
export const universalCalendarOpenShiftsIsSupportedSelector = (state: RootState) => state.universalCalendar.openShifts.isUniversalCalendarSupported;
export const universalCalendarOpenShiftsListSelector = (state: RootState) => state.universalCalendar.openShifts.list;
export const universalCalendarSelectedWorkerIDSelector = (state: RootState) => state.universalCalendar.selectedWorkerID;
export const universalCalendarSearchOrganizationSelector = (state: RootState) => state.universalCalendar.shiftsSearch.organization;
export const universalCalendarSearchLocationSelector = (state: RootState) => state.universalCalendar.shiftsSearch.location;
export const universalCalendarSearchDateSelector = (state: RootState) => state.universalCalendar.shiftsSearch.date;

export default universalCalendarSlice.reducer;
