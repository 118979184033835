import { useStyletron } from 'baseui';
import { WorkerListItemPropsType } from 'types/WorkerTypes';
import {
  emptyPlaceholder,
} from 'theme';
import { useTranslation } from 'react-i18next';
import {
  KIND,
} from 'baseui/button';
import Access,
{ AccessUnit } from 'components/Access/Access';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  HeadingXSmall,
  LabelMedium,
  ParagraphMedium,
} from 'baseui/typography';
import {
  Tag,
  VARIANT,
} from 'baseui/tag';
import WorkerActions from './WorkerActions';

const listStyles = {
  padding: '0px',
};

const listItemStyles = {
  background: '#FFFFFF',
  'list-style': 'none',
  padding: '8px',
};

const listItemNameAndStatusesWithMenu = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  margin: '8px 8px 0px 0px',
};

const listItemNameAndStatuses = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  margin: '8px 8px 0px 0px',
  '@media screen and (max-width: 520px)': {
    'flex-direction': 'column',
    alignItems: 'flex-start',
  },
};

/**
 * WorkerListItem component
 *
 * @param worker worker data
 * @param setIsEmploymentsModalOpen if WORKER_EMPLOYMENTS_MODAL is open
 * @param setIsLinkedAccountsModalOpen if WORKER_LINKED_ACCOUNTS_MODAL is open
 * @param setIsShiftsModalOpen if WORKER_UNIVERSAL_CALENDAR_SHIFTS_MODAL is open
 */

const WorkerListItem = ({
  worker,
  setIsEmploymentsModalOpen,
  setIsLinkedAccountsModalOpen,
  setIsShiftsModalOpen,
}: WorkerListItemPropsType) => {
  const [css] = useStyletron();
  const theme = useStyletron()[1];
  const { t } = useTranslation(['common', 'workers', 'employees']);

  return (
    <ul className={css(listStyles)}>
      <li className={css(listItemStyles)}>
        <div className={css(listItemNameAndStatusesWithMenu)}>
          <div className={css(listItemNameAndStatuses)}>
            <HeadingXSmall
              overrides={{
                Block: {
                  style: {
                    marginTop: '4px',
                    marginLeft: '16px',
                    marginRight: '8px',
                    marginBottom: '0px',
                  },
                },
              }}
            >
              {`${worker?.firstName} ${worker?.lastName}`}
            </HeadingXSmall>
            <Tag
              closeable={false}
              kind={worker.knownSource ? 'positive' : 'warning'}
              variant={VARIANT.solid}
              overrides={{
                Root: {
                  style: {
                    marginLeft: '16px',
                    maxWidth: '100%',
                  },
                },
                Text: {
                  style: {
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              {worker.knownSource ? t('workers:workers.trusted') : t('workers:workers.untrusted') }
            </Tag>
          </div>
          <Access oneOf={[AccessUnit.EWAManager]}>
            <WorkerActions
              worker={worker}
              setIsEmploymentsModalOpen={setIsEmploymentsModalOpen}
              setIsLinkedAccountsModalOpen={setIsLinkedAccountsModalOpen}
              setIsShiftsModalOpen={setIsShiftsModalOpen}
            />
          </Access>
        </div>
        <Grid
          gridColumns={12}
          gridMargins={18}
        >
          <Cell
            span={[12, 6, 4]}
            align={ALIGNMENT.end}
          >
            <LabelMedium display="inline-flex" color={theme.colors.contentSecondary}>
              {t('workers:id')}
              : &nbsp;
            </LabelMedium>
            <ParagraphMedium display="inline-flex">
              {worker.id || emptyPlaceholder}
            </ParagraphMedium>
          </Cell>

          <Cell
            span={[12, 4, 2]}
            align={ALIGNMENT.end}
          >
            <LabelMedium display="inline-flex" color={theme.colors.contentSecondary}>
              {t('workers:username')}
              : &nbsp;
            </LabelMedium>
            <ParagraphMedium display="inline-flex">
              {worker.username || emptyPlaceholder}
            </ParagraphMedium>
          </Cell>

          <Cell
            span={[12, 4, 2.5]}
            align={ALIGNMENT.end}
          >
            <LabelMedium display="inline-flex" color={theme.colors.contentSecondary}>
              {t('employees:mobileNumber')}
              : &nbsp;
            </LabelMedium>
            <ParagraphMedium display="inline-flex">
              {worker.mobilePhone || emptyPlaceholder}
            </ParagraphMedium>
          </Cell>

          <Cell
            span={[12, 4, 3]}
            align={ALIGNMENT.end}
          >
            <LabelMedium display="inline-flex" color={theme.colors.contentSecondary}>
              {t('workers:email')}
              : &nbsp;
            </LabelMedium>
            <ParagraphMedium display="inline-flex">
              {worker.email || emptyPlaceholder}
            </ParagraphMedium>
          </Cell>
          {worker.fuegoCardIssued && (
          <Cell
            span={[12, 4, 1.5]}
          >
            <Tag
              closeable={false}
              variant={VARIANT.solid}
              kind={KIND.primary}
              overrides={{
                Text: {
                  style: {
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              {t('workers:workers.fuegoCardHolder.visa')}
            </Tag>
          </Cell>
          )}
          {worker.fuegoPaycardIssued && (
          <Cell
            span={[12, 4, 1.5]}
          >
            <Tag
              closeable={false}
              variant={VARIANT.outlined}
              kind={KIND.primary}
              overrides={{
                Text: {
                  style: {
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              {t('workers:workers.fuegoCardHolder.paycard')}
            </Tag>
          </Cell>
          )}

        </Grid>
      </li>
    </ul>
  );
};

export default WorkerListItem;
