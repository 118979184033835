import { useParams } from 'react-router-dom';
import {
  ChangeEvent,
  memo,
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import { StatefulTooltip } from 'baseui/tooltip';
import {
  Button,
  KIND,
} from 'baseui/button';
import {
  Grid,
  Cell,
  ALIGNMENT,
} from 'baseui/layout-grid';
import { SIZE } from 'baseui/table-semantic';
import { Pagination } from 'baseui/pagination';
import { Block } from 'baseui/block';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  locationsSelector,
  locationPendingListSelector,
  generateLocationsConfigReport,
  locationsConfigCsvSelector,
  resetLocationConfigCsv,
  patchLocation,
  locationPendingPatchSelector,
  importLocations,
  Location,
} from 'store/slices/locations';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import {
  LocationIDType,
  LocationStatus,
} from 'types/LocationTypes';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import Loader from 'components/Loader';
import DownloadFile from 'utils/downloadFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCirclePlus,
  faFileExport,
  faFileImport,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import {
  colors,
  pageSize,
} from 'theme';
import {
  OnChangeParams,
  Select,
} from 'baseui/select';
import { SourceAccountType } from 'types/RepaymentsBankDetails';
import AppFileUploadButton from 'components/Form/AppFileUploadButton';
import AppStyledTable from 'components/AppStyledTable/AppStyledTable';
import { organizationsSelector } from 'store/slices/organizations';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  paginationTransparentOverrides,
  tooltipOverrides,
} from 'screens/CommonHelpers';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import removeUnderlinesFromText from 'utils/locationHelpers';
import { ParagraphSmall } from 'baseui/typography';
import {
  repaymentsOrganizationSourceAccountsListSelector,
  repaymentsOrganizationSourceAccountsPendingSelector,
} from 'store/slices/repaymentsBankDetails';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import OrganizationFormLocationModal from './OrganizationFormLocationModal/OrganizationFormLocationModal';
import OrganizationPreviewLocationModal from './OrganizationPreviewLocationModal/OrganizationPreviewLocationModal';

const containerStyles = {
  width: '100%',
  minHeight: '240px',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 10,
} as StyleObject;

const listItemStyles = {
  display: 'inline-block',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
} as StyleObject;

const OrganizationFormLocationsSection = () => {
  const dispatch = useAppDispatch();
  const organizationLocations = useAppSelector(locationsSelector);
  const pendingList = useAppSelector(locationPendingListSelector);
  const locationsConfigCsv = useAppSelector(locationsConfigCsvSelector);
  const modals = useAppSelector(modalsSelector);
  const pendingPatch = useAppSelector(locationPendingPatchSelector);
  const { t } = useTranslation(['organizations', 'errors', 'common', 'locations']);
  const [css] = useStyletron();
  const [filteredLocations, setFilteredLocations] = useState(organizationLocations);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [locationID, setLocationID] = useState<LocationIDType>(null);
  const [search, setSearch] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const { organizationID } = useParams<{ organizationID: string }>();
  const loggedOrg = useAppSelector(loggedOrganizationSelector);
  const organizations = useAppSelector(organizationsSelector);
  const totalSize = filteredLocations?.length || 0;
  const [numPages, setNumPages] = useState(1);
  const sourceAccounts = useAppSelector(repaymentsOrganizationSourceAccountsListSelector);
  const pending = useAppSelector(repaymentsOrganizationSourceAccountsPendingSelector);
  const [isStatusChanged, setIsStatusChanged] = useState<boolean>(false);
  const [isBankAccountChanged, setIsBankAccountChanged] = useState<boolean>(false);
  const [bankAccountChangedData, setBankAccountChangedData] = useState<OnChangeParams>();

  const isConfirmModalOpen = !!modals?.find((item) => item.name === ModalNames.CONFIRM_MODAL_LOCATION_EDIT_BANK_ACCOUNT)?.isOpen;

  const bankAccountOptions = sourceAccounts?.map((account: SourceAccountType) => ({
    value: account.accountId,
    label: account.nickname || account.accountNumber,
  }));

  const statusOptions = [
    { value: LocationStatus.ACTIVE, label: t('locations:locationsStatuses.ACTIVE') },
    { value: LocationStatus.INACTIVE, label: t('locations:locationsStatuses.INACTIVE') },
  ];

  const setIsConfirmModalOpen = (
    isOpen: boolean,
  ) => {
    isConfirmModalOpen !== isOpen && dispatch(setModal({
      name: ModalNames.CONFIRM_MODAL_LOCATION_EDIT_BANK_ACCOUNT,
      isOpen,
    }));
  };

  const updateLocations = () => {
    setFilteredLocations(
      organizationLocations
        ?.filter((location) => location?.name?.toString().toLowerCase().includes(search?.toLowerCase())),
    );
  };

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    setSearch(e.target.value);
  };

  const handleAddLocationBtnClick = () => {
    setLocationID(null);
    dispatch(setModal({
      name: ModalNames.LOCATION_FORM_MODAL,
      isOpen: true,
    }));
  };

  const handleEditLocationBtnClick = (
    id: LocationIDType,
  ) => {
    setLocationID(id);
    dispatch(setModal({
      name: ModalNames.LOCATION_FORM_MODAL,
      isOpen: true,
    }));
  };

  const handleLocationNameClick = (id: LocationIDType) => {
    setLocationID(id);
    dispatch(setModal({
      name: ModalNames.LOCATION_PREVIEW_MODAL,
      isOpen: true,
    }));
  };

  const handleExportLocationBtnClick = () => {
    dispatch(generateLocationsConfigReport({ organizationID: organizationID || loggedOrg?.id }));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize);
    setCurrentPage(page);
  };

  const handleStatusChange = (locID: LocationIDType, params: OnChangeParams) => {
    setIsStatusChanged(true);
    locID && setSelectedLocation(locID);

    params?.option?.value && dispatch(patchLocation({
      organizationID,
      locationID: locID,
      data: {
        status: params?.option?.value,
      },
    }))
      .then(() => setIsStatusChanged(false))
      .catch(() => setIsStatusChanged(false));
  };

  const handleBankAccountChange = () => {
    setIsBankAccountChanged(true);

    dispatch(patchLocation({
      organizationID,
      locationID: selectedLocation,
      data: {
        bankAccount: {
          bankAccountId: bankAccountChangedData?.option?.value || null,
          bankAccountAlias: bankAccountChangedData?.option?.label && bankAccountChangedData?.option?.value !== null
            ? bankAccountChangedData?.option?.label?.toString()
            : null,
        },
      },
    }))
      .then(() => {
        setIsBankAccountChanged(false);
        setBankAccountChangedData(undefined);
      })
      .catch(() => {
        setIsBankAccountChanged(false);
        setBankAccountChangedData(undefined);
      });
  };

  const handleBankAccountChangeClose = () => {
    setIsConfirmModalOpen(false);
    setIsBankAccountChanged(false);
    setBankAccountChangedData(undefined);
  };

  const handleFileSelected = (file: File) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      dispatch(importLocations({
        organizationID: organizationID || loggedOrg?.id,
        formData,
      }));
    }
  };

  useEffect(() => {
    updateLocations();
    search && setCurrentPage(1);
  }, [organizationLocations, search]);

  useEffect(() => {
    !pendingPatch && setSelectedLocation('');
  }, [pendingPatch]);

  useEffect(() => {
    if (locationsConfigCsv) {
      const activeOrganization = organizations?.find((item) => item?.id?.toString() === organizationID?.toString());
      const fileName = activeOrganization?.name || loggedOrg?.name;
      DownloadFile(
        `${fileName?.toLowerCase().replace(/\s/g, '-')}-locations-config.csv`,
        `data:text/csv;charset=UTF-8,${encodeURIComponent(locationsConfigCsv)}`,
      );
      dispatch(resetLocationConfigCsv());
    }
  }, [locationsConfigCsv]);

  const rows = filteredLocations?.length > 0
    ? (
      filteredLocations
        ?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        ?.sort((a: Location, b: Location) => a.name.localeCompare(b.name)).map(({
          id,
          externalLocationId,
          name,
          timezone,
          status,
          payGroup,
          bankAccount,
        }, index) => ({
          id,
          cells: [
            <StatefulTooltip
              accessibilityType="tooltip"
              content={name}
              overrides={tooltipOverrides(!name?.trim().includes(' '))}
            >
              <Button
                onClick={() => handleLocationNameClick(id)}
                kind={KIND.tertiary}
                overrides={{
                  Root: {
                    props: {
                      id: `OrganizationFormLocationsSection-location-${id}-name`,
                      'data-testid': 'location-section-name-button',
                    },
                    style: {
                      color: colors.primary,
                      backgroundColor: 'inherit',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      textAlign: 'left',
                      ':hover': {
                        backgroundColor: 'inherit',
                      },
                    },
                  },
                }}
              >
                <div>
                  {name}
                </div>
              </Button>
            </StatefulTooltip>,
            <StatefulTooltip
              accessibilityType="tooltip"
              content={externalLocationId}
              overrides={tooltipOverrides(false)}
            >
              <span className={css(listItemStyles)}>
                {externalLocationId}
              </span>
            </StatefulTooltip>,
            <StatefulTooltip
              accessibilityType="tooltip"
              content={removeUnderlinesFromText(timezone)}
              overrides={tooltipOverrides(false)}
            >
              <span className={css(listItemStyles)}>
                {removeUnderlinesFromText(timezone)}
              </span>
            </StatefulTooltip>,
            <Select
              size={SIZE.compact}
              isLoading={selectedLocation === id && pendingPatch && isStatusChanged}
              disabled={selectedLocation === id && pendingPatch && isStatusChanged}
              type="select"
              clearable={false}
              options={statusOptions}
              onChange={(params) => handleStatusChange(id, params)}
              labelKey="label"
              valueKey="value"
              value={[{ value: status }]}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': `LocationsSection-status-select-${index}`,
                    id: `LocationsSection-status-select-${index}`,
                  },
                  style: {
                    width: '130px',
                  },
                },
              }}
            />,
            <Select
              size={SIZE.compact}
              isLoading={(selectedLocation === id && pendingPatch && isBankAccountChanged) || pending}
              disabled={(selectedLocation === id && pendingPatch && isBankAccountChanged) || pending}
              type="select"
              clearable={!!bankAccount?.bankAccountAlias}
              options={bankAccountOptions}
              onChange={(params) => {
                setIsConfirmModalOpen(true);
                setBankAccountChangedData(params);
                setSelectedLocation(id);
              }}
              labelKey="label"
              valueKey="value"
              value={[{ value: bankAccount?.bankAccountId, label: bankAccount?.bankAccountAlias }]}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': `LocationsSection-bankAccount-select-${index}`,
                    id: `LocationsSection-bankAccount-select-${index}`,
                  },
                  style: {
                    width: '230px',
                  },
                },
              }}
            />,
            <StatefulTooltip
              accessibilityType="tooltip"
              content={payGroup?.name}
              overrides={tooltipOverrides(!payGroup?.name?.trim().includes(' '))}
            >
              <span className={css(listItemStyles)}>
                {payGroup?.name}
              </span>
            </StatefulTooltip>,
            <Button
              kind={KIND.tertiary}
              onClick={() => handleEditLocationBtnClick(id)}
              overrides={{
                BaseButton: {
                  props: {
                    id: 'OrganizationFormLocationsSection-list-item-edit',
                  },
                  style: {
                    color: colors.primary,
                    backgroundColor: 'inherit',
                    ':hover': {
                      backgroundColor: 'inherit',
                    },
                  },
                },
              }}
            >
              <FontAwesomeIcon
                icon={faPen}
              />
            </Button>,
          ],
        }))
    )
    : (
      [{
        id: 'no-locations-available-message',
        cells: [
          <ParagraphSmall
            overrides={{
              Block: {
                style: {
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                },
              },
            }}
          >
            {t('locations:noLocations')}
          </ParagraphSmall>, '', '', '', '', '', ''],
      }]
    );

  useEffect(() => {
    setNumPages(Math.ceil(totalSize / pageSize));
  }, [totalSize]);

  return (
    <div className={css(containerStyles)}>
      <Loader active={pendingList} />

      <Block
        marginBottom="16px"
      >
        <Grid
          gridColumns={12}
          gridGutters={0}
          align={ALIGNMENT.center}
          gridMargins={36}
          gridGaps={8}
        >
          <Cell
            span={[12, 4]}
            align={ALIGNMENT.start}
          >
            <Block
              alignItems="start"
              justifyContent="start"
              display="flex"
              width="auto"
            >
              <Input
                value={search}
                startEnhancer={<Search />}
                type="text"
                name="search"
                onChange={handleChangeSearch}
                clearOnEscape
                placeholder={t('common:searchPlaceholder')}
                overrides={{
                  Input: {
                    props: {
                      id: 'OrganizationFormLocationsSection-search',
                      autoComplete: 'off',
                    },
                  },
                }}
              />
            </Block>
          </Cell>

          <Cell
            span={[12, 8]}
          >

            <Grid
              gridColumns={12}
              gridMargins={0}
            >
              <Cell
                skip={[0, 2, 3]}
                span={[12, 3]}
              >
                <Block
                  display="flex"
                  width="100%"
                  justifyContent="end"
                >
                  <AppFileUploadButton
                    handleFileSelected={handleFileSelected}
                  >
                    <Block
                      marginRight="8px"
                    >
                      <FontAwesomeIcon icon={faFileImport} />
                    </Block>
                    {t('locations:importLocations')}
                  </AppFileUploadButton>
                </Block>
              </Cell>

              <Cell
                span={[12, 3]}
              >
                <Block
                  display="flex"
                  width="100%"
                  justifyContent="end"
                >
                  <Button
                    kind={KIND.tertiary}
                    onClick={handleExportLocationBtnClick}
                    overrides={{
                      Root: {
                        style: {
                          marginRight: '10px',
                        },
                        props: {
                          id: 'OrganizationFormLocationsSection-export-btn',
                        },
                      },
                    }}
                  >
                    <Block
                      marginRight="8px"
                    >
                      <FontAwesomeIcon icon={faFileExport} />
                    </Block>
                    {t('locations:exportLocations')}
                  </Button>
                </Block>
              </Cell>

              <Cell
                span={[12, 4, 3]}
              >
                <Block
                  display="flex"
                  width="100%"
                  justifyContent="end"
                >
                  <Button
                    kind={KIND.tertiary}
                    onClick={handleAddLocationBtnClick}
                    overrides={{
                      Root: {
                        props: {
                          id: 'OrganizationFormLocationsSection-search-btn',
                        },
                      },
                    }}
                  >
                    <Block
                      marginRight="8px"
                    >
                      <FontAwesomeIcon icon={faCirclePlus} />
                    </Block>
                    {t('locations:newLocation')}
                  </Button>
                </Block>
              </Cell>
            </Grid>

          </Cell>

        </Grid>
      </Block>

      <Block
        paddingLeft="36px"
        paddingRight="36px"
      >
        <AppStyledTable
          tableGridTemplateColumns="minmax(26%, max-content)
          minmax(12%, max-content)
          minmax(12%, max-content)
          minmax(12%, max-content)
          minmax(20%, max-content)
          minmax(12%, max-content)
          minmax(6%, max-content)"
          tableStyle={{
            // eslint-disable-next-line max-len
            gridTemplateColumns: 'minmax(26%, max-content) minmax(12%, max-content) minmax(12%, max-content) minmax(12%, max-content) minmax(20%, max-content) minmax(12%, max-content) minmax(6%, max-content)',
            '@media (max-width: 1200px)': {
              gridTemplateColumns: '400px 200px 160px 160px 250px 180px 60px',
            },
            '@media (max-width: 780px)': {
              gridTemplateColumns: '250px 200px 160px 160px 250px 180px 60px',
            },
          }}
          headingCells={[
            <Block paddingLeft="16px">
              {t('locations:locationName')}
            </Block>,
            t('locations:locationExternalID.label'),
            t('locations:locationsTimeZone'),
            t('locations:locationsStatus'),
            t('locations:bankAccount'),
            t('locations:payGroup.label'),
            '',
          ]}
          rows={rows}
        />

        <ConfirmModal
          onAction={() => handleBankAccountChange()}
          title={bankAccountChangedData?.type === 'clear'
            ? t('locations:bankAccount.remove.confirmation.title')
            : t('locations:bankAccount.change.confirmation.title')}
          actionBtnText={t('common:confirm.ok')}
          onClose={handleBankAccountChangeClose}
          modalNameSpecified="CONFIRM_MODAL_LOCATION_EDIT_BANK_ACCOUNT"
        />
      </Block>

      {
        filteredLocations?.length > 0 && (
          <Block
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            marginBottom="16px"
          >
            <Pagination
              size={SIZE.compact}
              numPages={numPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              overrides={paginationTransparentOverrides}
            />
          </Block>
        )
      }

      {checkIsModalOpen(modals, ModalNames.LOCATION_FORM_MODAL) && <OrganizationFormLocationModal locationID={locationID} />}
      {checkIsModalOpen(modals, ModalNames.LOCATION_PREVIEW_MODAL) && <OrganizationPreviewLocationModal locationID={locationID} />}
    </div>
  );
};

export default memo(OrganizationFormLocationsSection);
