import {
  createContext,
  memo,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { LocationPaycardConfigType } from 'types/LocationTypes';
import { LabelLarge, LabelMedium } from 'baseui/typography';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { states } from 'types/CommonTypes';
import {
  LocationPaycardConfigTypeInitialValues as locationPaycardConfigTypeInitialValues,
} from 'initialValues/LocationsInitialValues';
import { useParams } from 'react-router-dom';
import {
  fetchLocationPaycardConfigSettings,
  fetchOrganizationsPaycardProviderWithParentId,
  fetchPaycardConfigProviderPrograms,
  paycardLocationConfigSettingsRejectedSelector,
  paycardLocationConfigSettingsSelector,
  paycardNonPersonalizedProgramsConfigListSelector,
  paycardProgramsConfigListPendingSelector,
  paycardProgramsConfigListRejectedSelector,
  paycardProviderOrganizationsWithParentIdListPendingSelector,
  paycardProviderOrganizationsWithParentIdListRejectedSelector,
  paycardProviderOrganizationsWithParentIdListSelector,
} from 'store/slices/paycards';
import AppCheckbox from 'components/Form/AppCheckbox';
import AppInput from 'components/Form/AppInput';
import AppSelect from 'components/Form/AppSelect';
import { Block } from 'baseui/block';
import { ModalButton } from 'baseui/modal';
import { KIND, SIZE } from 'baseui/button';
import { locationPaycardConfigMapper, saveLocationPaycardConfigMapper } from 'dataMappers/locationsDataMapper';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import {
  locationPaycardSettingsFailedSelector,
  locationPaycardSettingsFailedStatusCodeSelector,
  locationPaycardSettingsPendingSelector,
  locationPaycardSettingsSelector,
  resetLocationPaycardSettings,
  resetSelectedLocation,
  saveLocationPaycardConfig,
  selectedLocationSelector,
  locationCreatedSelector,
  locationEditedSuccessfully,
  fetchLocations,
  resetLocationSuccessfulOperation,
} from 'store/slices/locations';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { errorSelector } from 'store/slices/error';
import Loader from 'components/Loader';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import {
  LocationPaycardConfigValidationSchema as validationSchema,
} from 'validation/addOrganizationSchema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'theme';
import { notificationSelector } from 'store/slices/notification';
import { containerStyles } from '../../OrganizationFormHelpers';
import { warningIconStyle } from '../OrganizationFormLocationModalEWA/OrganizationFormLocationModalEWA';

export const LocationPaycardConfigurationContext = createContext(
  {} as FormikState<LocationPaycardConfigType>
  & FormikHelpers<LocationPaycardConfigType>
  & FormikHandlers,
);

const OrganizationFormLocationModalPaycardConfiguration = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['locations', 'errors', 'common']);
  const dispatch = useAppDispatch();
  const location = useAppSelector(selectedLocationSelector);
  const { organizationID: organizationId } = useParams<{ organizationID: string }>();
  const paycardConfig = useAppSelector(locationPaycardSettingsSelector);
  const paycardConfigPending = useAppSelector(locationPaycardSettingsPendingSelector);
  const paycardConfigFailed = useAppSelector(locationPaycardSettingsFailedSelector);
  const paycardConfigFailedStatusCode = useAppSelector(locationPaycardSettingsFailedStatusCodeSelector);
  const paycardLocationConfigSettingsRejected = useAppSelector(paycardLocationConfigSettingsRejectedSelector);
  const { organizationExternalId: externalOrganizationId } = useAppSelector(paycardLocationConfigSettingsSelector);
  const externalCustomerIdOptions = useAppSelector(paycardProviderOrganizationsWithParentIdListSelector);
  const externalCustomerIdLoading = useAppSelector(paycardProviderOrganizationsWithParentIdListPendingSelector);
  const externalCustomerIdError = useAppSelector(paycardProviderOrganizationsWithParentIdListRejectedSelector);
  const customerProgramIdOptions = useAppSelector(paycardNonPersonalizedProgramsConfigListSelector);
  const customerProgramIdLoading = useAppSelector(paycardProgramsConfigListPendingSelector);
  const customerProgramIdError = useAppSelector(paycardProgramsConfigListRejectedSelector);
  const notificationToast = useAppSelector(notificationSelector);
  const locationCreated = useAppSelector(locationCreatedSelector);
  const locationEdited = useAppSelector(locationEditedSuccessfully);
  const modals = useAppSelector(modalsSelector);
  const modalName = ModalNames.LOCATION_FORM_MODAL;
  const isModalOpen = checkIsModalOpen(modals, modalName);
  const error = useAppSelector(errorSelector);
  const hasFetchedRef = useRef(false);

  const errorMessage = useMemo(() => {
    if (paycardConfigFailedStatusCode === 400) {
      return t('locations:paycardConfiguration.errors.paycardProgramDisabled');
    }
    if (paycardLocationConfigSettingsRejected) {
      return t('locations:paycardConfiguration.errors.paycardLocationSettingsNotFound');
    }
    return t('common:unsavedChanges');
  }, [paycardConfigFailedStatusCode, error, paycardLocationConfigSettingsRejected]);

  const stateOptions = Object.values(states).map((state) => ({
    label: state,
    value: state,
  }));

  const mappedExternalCustomerIdOptions = useMemo(() => (
    externalCustomerIdOptions.map(
      (item: { id: number; name: string }) => ({
        value: item.id,
        label: item.name,
      }),
    )
  ), [externalCustomerIdOptions]);

  const mappedCustomerProgramIdOptions = useMemo(() => (
    customerProgramIdOptions.map(
      (item: { id: number; name: string }) => ({
        value: item.id,
        label: item.name,
      }),
    )
  ), [customerProgramIdOptions]);

  const initialValues = {
    ...locationPaycardConfigTypeInitialValues,
    locationName: location?.name || '',
    ...(paycardConfig && locationPaycardConfigMapper(paycardConfig)),
  };

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetSelectedLocation());
    dispatch(resetLocationPaycardSettings());
    (locationCreated || locationEdited) && dispatch(fetchLocations({ organizationID: organizationId })).then(() => {
      dispatch(resetLocationSuccessfulOperation());
    });
  };

  const onSubmit = (values: LocationPaycardConfigType) => {
    dispatch(saveLocationPaycardConfig({
      organizationId,
      locationId: location?.id.toString(),
      data: saveLocationPaycardConfigMapper(values),
    }));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    setValues,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    resetForm,
    isValid,
  } = formik;

  const isPaycardDisabled = paycardConfigFailed || paycardLocationConfigSettingsRejected;
  const isFormFieldDisabled = isPaycardDisabled || !values.enabled;

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  useEffect(() => {
    !paycardConfigFailed && dispatch(fetchLocationPaycardConfigSettings({ organizationId }));
  }, [organizationId, paycardConfigFailed]);

  useEffect(() => {
    if (paycardConfig) {
      setDefaultValues({
        ...locationPaycardConfigMapper(paycardConfig),
      });
      setValues({
        ...locationPaycardConfigMapper(paycardConfig),
      });
    }
  }, [paycardConfig]);

  useEffect(() => {
    if (externalOrganizationId && !hasFetchedRef.current) {
      dispatch(fetchOrganizationsPaycardProviderWithParentId({
        parentId: externalOrganizationId,
      }));
      hasFetchedRef.current = true;
    }
  }, [externalOrganizationId]);

  useEffect(() => {
    if (paycardConfig?.locationExternalId) {
      dispatch(fetchPaycardConfigProviderPrograms({
        providerOrganizationId: paycardConfig.locationExternalId.toString(),
      }));
    } else if (values.locationExternalId.length) {
      dispatch(fetchPaycardConfigProviderPrograms({
        providerOrganizationId: values.locationExternalId[0].value,
      }));
    }
  }, [paycardConfig?.locationExternalId, values.locationExternalId]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    if (isModalOpen) {
      setSubmitting(false);
      resetForm();
      setDefaultValues({
        ...initialValues,
        ...values,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    const hasOpenNotification = notificationToast?.some((notification) => notification.isOpen === true);

    if (hasOpenNotification) {
      setSubmitting(false);
    }
  }, [notificationToast]);

  return (
    <LocationPaycardConfigurationContext.Provider value={formik}>
      <div className={css(containerStyles)}>
        <Loader active={paycardConfigPending} />
        <form>
          {(isFormChanged || paycardConfigFailedStatusCode || paycardLocationConfigSettingsRejected) && (
            <Block
              overrides={{
                Block: {
                  style: {
                    marginLeft: '14px',
                    marginRight: '0px',
                    marginBottom: '14px',
                    marginTop: '0px',
                    display: 'flex',
                    justifyContent: 'center',
                  },
                },
              }}
            >
              <FontAwesomeIcon
                icon={faWarning}
                className={css(warningIconStyle)}
                size="lg"
              />
              <LabelMedium color={colors.primary}>
                {errorMessage}
              </LabelMedium>
            </Block>
          )}
          <LabelLarge marginBottom="8px">
            {t('locations:paycardConfiguration.paycardIntegrationDetails')}
          </LabelLarge>
          <Grid
            gridColumns={12}
            gridMargins={0}
            align={ALIGNMENT.start}
          >
            <AppCheckbox
              name="enabled"
              label={t('locations:paycardConfiguration.details.cardManagement')}
              labelDescription={t('locations:paycardConfiguration.details.cardManagementDescription')}
              cellSpan={[12, 6, 6]}
              context={LocationPaycardConfigurationContext}
              cellAlign={ALIGNMENT.center}
              paragraphProps={{
                width: '400px',
                wordWrap: 'break-word',
                whiteSpace: 'normal',
                '@media screen and (max-width: 720px)': {
                  width: '250px',
                },
              }}
              disabled={isPaycardDisabled}
            />
          </Grid>
          <Grid
            gridColumns={12}
            gridMargins={0}
            align={ALIGNMENT.start}
          >
            <AppSelect
              showStar
              name="locationExternalId"
              label={t('locations:paycardConfiguration.details.externalCustomerId')}
              placeholder={t('common:select')}
              options={mappedExternalCustomerIdOptions}
              noResultsText={externalCustomerIdError ? t('errors:errorOnLoadingData') : t('errors:noDataAvailable')}
              context={LocationPaycardConfigurationContext}
              cellSpan={[12, 6, 6]}
              selectLoading={externalCustomerIdLoading}
              formControlProps={{
                disabled: isFormFieldDisabled,
              }}
            />
          </Grid>
          <Grid
            gridColumns={12}
            gridMargins={0}
            align={ALIGNMENT.start}
          >
            <AppSelect
              showStar
              name="programId"
              label={t('locations:paycardConfiguration.details.customerProgramId')}
              placeholder={t('common:select')}
              options={mappedCustomerProgramIdOptions}
              noResultsText={customerProgramIdError ? t('errors:errorOnLoadingData') : t('errors:noDataAvailable')}
              context={LocationPaycardConfigurationContext}
              cellSpan={[12, 6, 6]}
              selectLoading={customerProgramIdLoading}
              formControlProps={{
                disabled: isFormFieldDisabled,
              }}
            />
          </Grid>
          <LabelLarge marginBottom="16px">
            {t('locations:paycardConfiguration.locationAddress')}
          </LabelLarge>
          <Grid
            gridColumns={12}
            gridMargins={0}
            align={ALIGNMENT.start}
          >
            <AppInput
              name="addressLine1"
              inputProps={{
                id: 'addressLine1',
                autoComplete: 'off',
              }}
              formControlProps={{
                htmlFor: 'addressLine1',
                disabled: isFormFieldDisabled,
              }}
              label={t('locations:paycardConfiguration.location.address')}
              cellSpan={[12, 6]}
              context={LocationPaycardConfigurationContext}
            />
            <AppInput
              name="addressLine2"
              inputProps={{
                id: 'addressLine2',
                autoComplete: 'off',
              }}
              formControlProps={{
                htmlFor: 'addressLine2',
                disabled: isFormFieldDisabled,
              }}
              label={t('locations:paycardConfiguration.location.apartment')}
              cellSpan={[12, 6]}
              context={LocationPaycardConfigurationContext}
            />
          </Grid>
          <Grid
            gridColumns={12}
            gridMargins={0}
            align={ALIGNMENT.start}
          >
            <AppInput
              name="city"
              inputProps={{
                id: 'city',
                autoComplete: 'off',
              }}
              formControlProps={{
                htmlFor: 'city',
                disabled: isFormFieldDisabled,
              }}
              label={t('locations:paycardConfiguration.location.city')}
              cellSpan={[12, 6]}
              context={LocationPaycardConfigurationContext}
            />
            <AppSelect
              name="state"
              selectProps={{
                clearable: false,
              }}
              formControlProps={{
                htmlFor: 'state',
                disabled: isFormFieldDisabled,
              }}
              options={stateOptions}
              label={t('locations:paycardConfiguration.location.state')}
              cellSpan={[12, 6]}
              context={LocationPaycardConfigurationContext}
            />
          </Grid>
          <Grid
            gridColumns={12}
            gridMargins={0}
            align={ALIGNMENT.start}
          >
            <AppInput
              name="postalCode"
              inputProps={{
                id: 'postalCode',
                autoComplete: 'off',
                disabled: isFormFieldDisabled,
              }}
              formControlProps={{
                htmlFor: 'postalCode',
              }}
              label={t('locations:paycardConfiguration.location.zip')}
              cellSpan={[12, 6]}
              context={LocationPaycardConfigurationContext}
            />
          </Grid>
          <Grid
            gridColumns={12}
            gridMargins={0}
          >
            <Cell span={12}>
              <Block
                display="flex"
                justifyContent="flex-end"
              >
                <ModalButton
                  size={SIZE.compact}
                  type="button"
                  onClick={handleModalClose}
                  kind={KIND.secondary}
                  overrides={{
                    BaseButton: {
                      props: {
                        id: 'Modal-footer-close',
                      },
                    },
                  }}
                >
                  {t('common:cancel')}
                </ModalButton>
                <ModalButton
                  size={SIZE.compact}
                  type="submit"
                  onClick={handleSubmit}
                  kind={KIND.primary}
                  disabled={
                    isSubmitting
                    || !isFormChanged
                    || !isValid
                  }
                  overrides={{
                    BaseButton: {
                      props: {
                        id: 'Modal-footer-action',
                      },
                    },
                  }}
                >
                  {t('common:save')}
                </ModalButton>
              </Block>
            </Cell>
          </Grid>
        </form>
      </div>
    </LocationPaycardConfigurationContext.Provider>
  );
};

export default memo(OrganizationFormLocationModalPaycardConfiguration);
