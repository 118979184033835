import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchDashAccountBalanceRequest,
  fetchMasterAccountBalanceRequest,
} from 'bankingApi/masterAccountAPI';
import {
  BalanceType,
} from 'types/MasterAccountTypes';
import { RootState } from '..';

type NotNeededParam = undefined | null

export const initialState = {
  pending: false,
  balance: {} as BalanceType,
  dashBalance: {
    pending: false,
    balance: {} as BalanceType,
  },
};

export const fetchBalance = createAsyncThunk(
  'balance',
  async (params: NotNeededParam, { getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken } } = getState() as RootState;

    try {
      return await fetchMasterAccountBalanceRequest(accessToken);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchDashBalance = createAsyncThunk(
  'dashBalance',
  async (params: NotNeededParam, { getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken } } = getState() as RootState;

    try {
      return await fetchDashAccountBalanceRequest(accessToken);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const masterAccountSlice = createSlice({
  name: 'masterAccount',
  initialState,
  reducers: {
    resetBalance: (state) => {
      state.balance = initialState.balance;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBalance.pending, (state) => {
      state.pending = true;
      state.balance = initialState.balance;
    });

    builder.addCase(fetchBalance.fulfilled, (state, { payload }) => {
      state.balance = payload;
      state.pending = false;
    });

    builder.addCase(fetchBalance.rejected, (state) => {
      state.balance = initialState.balance;
      state.pending = false;
    });

    builder.addCase(fetchDashBalance.pending, (state) => {
      state.dashBalance.pending = true;
      state.dashBalance.balance = initialState.dashBalance.balance;
    });

    builder.addCase(fetchDashBalance.fulfilled, (state, { payload }) => {
      state.dashBalance.balance = payload;
      state.dashBalance.pending = false;
    });

    builder.addCase(fetchDashBalance.rejected, (state) => {
      state.dashBalance.balance = initialState.dashBalance.balance;
      state.dashBalance.pending = false;
    });
  },
});

export const {
  resetBalance,
} = masterAccountSlice.actions;
export const balanceSelector = (state: RootState): BalanceType => state.masterAccount.balance;
export const pendingBalanceSelector = (state: RootState): boolean => state.masterAccount.pending;
export const dashBalanceSelector = (state: RootState): BalanceType => state.masterAccount.dashBalance.balance;
export const pendingDashBalanceSelector = (state: RootState): boolean => state.masterAccount.dashBalance.pending;

export default masterAccountSlice.reducer;
