import {
  ChangeEvent,
  createContext, memo, useEffect, useState,
} from 'react';
import { Button, KIND } from 'baseui/button';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  ALIGNMENT,
} from 'baseui/layout-grid';
import { SIZE } from 'baseui/select';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import { employeeLinkToEmployerInitialValues as initialValues } from 'initialValues/EmployeeInitialValues';
import AppModal from 'components/AppModal/AppModal';
import validationSchema from 'validation/linkEmployeeSchema';
import {
  organizationsSelector,
} from 'store/slices/organizations';
import {
  assignEmployeesToEmployment,
  employeesSearchResultsSelector,
  searchEmployees,
  employeesPendingSearchListSelector,
  employeesSearchTotalSizeSelector,
  employeesSearchPageNumberSelector,
  employeesSearchNumPagesSelector,
} from 'store/slices/employees';
import {
  loggedOrganizationSelector,
} from 'store/slices/loggedOrganization';
import { resetLinkEmploymentsFormEvent } from 'store/events';
import {
  Employee,
  EmploymentType,
  AssignEmployeesToWorkerValuesType,
  EmployeeLinkToEmployerInitialValuesType,
  OperationMode,
} from 'types/EmployeeTypes';
import CellFormControl from 'components/CellFormControl';
import AppInput from 'components/Form/AppInput';
import AppSelect from 'components/Form/AppSelect';
import Loader from 'components/Loader';
import TitleSeparator from 'components/TitleSeparator';
import { WorkerEmployments } from 'types/WorkerTypes';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { Block } from 'baseui/block';
import { Pagination } from 'baseui/pagination';
import { workaroundPaginationTransparentOverrides } from 'screens/CommonHelpers';
import EmploymentsList from './EmploymentsList';
import WorkerResult from './WorkerResult';

const searchEmploymentButtonOverrides = {
  Root: {
    props: {
      id: 'LinkToEmployerModal-search-employment-btn',
    },
    style: {
      width: '100%',
    },
  },
};

export const LinkToEmployerFormContext = createContext(
  {} as FormikState<EmployeeLinkToEmployerInitialValuesType> & FormikHelpers<EmployeeLinkToEmployerInitialValuesType> & FormikHandlers,
);

export type LinkToEmployerPropTypes = {
  worker: WorkerEmployments | undefined,
  refreshOnSave: () => void,
};

const LinkToEmployerModal = ({ worker, refreshOnSave }: LinkToEmployerPropTypes) => {
  if (!worker) return null;
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['employees', 'organizations', 'errors', 'common', 'locations']);
  const organizations = useAppSelector(organizationsSelector);
  const organization = useAppSelector(loggedOrganizationSelector);
  const { id: organizationID } = organization || {};
  const employeesSearchResults = useAppSelector<Employee[]>(employeesSearchResultsSelector);
  const numPages = useAppSelector(employeesSearchNumPagesSelector);
  const pageNumber = useAppSelector(employeesSearchPageNumberSelector);
  const totalSize = useAppSelector(employeesSearchTotalSizeSelector);
  const pendingEmployeesSearch = useAppSelector(employeesPendingSearchListSelector);
  const modals = useAppSelector(modalsSelector);
  const [employments, setEmployments] = useState<(Employee & EmploymentType)[]>([]);
  const [employmentsSearched, setEmploymentsSearched] = useState<boolean>(false);
  const modalName = ModalNames.LINK_TO_EMPLOYER_MODAL;
  const selectedEmployments = employments?.filter((item) => item.selected);
  const isModalOpen = checkIsModalOpen(modals, modalName);
  const hasSelectedEmployments = selectedEmployments?.length > 0;

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const onSubmit = () => {
    const selectedEmployeesFormatted: AssignEmployeesToWorkerValuesType = {
      workerId: worker.workerId,
      operation: OperationMode.WORKER_UPDATE,
      employments: selectedEmployments.map(({ id, assignments }) => ({
        id, assignments: assignments?.map((i) => ({ id: i.id, name: i.name, primaryLocation: i.primaryLocation })),
      })),
    };

    dispatch(
      assignEmployeesToEmployment({
        organizationID,
        data: selectedEmployeesFormatted,
      }),
    ).then(() => {
      setIsModalOpen(false);
      refreshOnSave();
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    values,
    resetForm,
    validateForm,
    isSubmitting,
    setSubmitting,
    setValues,
  } = formik;

  useEffect(() => {
    resetForm();
    setSubmitting(false);
  }, []);

  const resetFormAndStore = () => {
    setSubmitting(false);
    resetForm();
    dispatch(resetLinkEmploymentsFormEvent());
  };

  const toggle = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = event.currentTarget;

    setEmployments((data) => data.map((row) => ({
      ...row,
      selected: String(row.id) === name ? checked : row.selected,
    })));
  };

  const onAssignmentsChange = (employmentId: number, assignmentId: number) => {
    setEmployments(employments?.map((row) => ({
      ...row,
      assignments: row.id.toString() === employmentId?.toString()
        ? row.assignments?.map((assignment) => ({
          ...assignment,
          primaryLocation: Number(assignment?.id) === Number(assignmentId),
        }))
        : row.assignments,
    })));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();
    dispatch(searchEmployees({
      organizationID: values.organization[0]?.value,
      filter: {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.mobile,
        email: values.email,
        pageNumber: page,
        workerId: '',
      },
    }));
  };
  const handleSearchEmploymentClick = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          dispatch(searchEmployees({
            organizationID: values.organization[0]?.value,
            filter: {
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.mobile,
              email: values.email,
              workerId: '',
            },
          }))
            .then(() => setEmploymentsSearched(true));
        }
      });
  };

  useEffect(() => {
    resetFormAndStore();
  }, [isModalOpen]);

  useEffect(() => {
    setValues({
      ...initialValues,
      organization: [{
        value: organization?.id,
        label: organization?.name,
      }],
    });
  }, [organization]);

  useEffect(() => {
    setEmployments(
      employeesSearchResults?.map((item): Employee & EmploymentType => ({
        id: item?.id?.toString(),
        firstName: item.firstName || '',
        lastName: item.lastName || '',
        email: item.email || '',
        phone: item?.phone || '',
        workerId: worker?.workerId || '',
        organization: item.organization,
        externalUserId: item.externalUserId,
        status: item?.status,
        statusEffectiveDate: item.statusEffectiveDate,
        payrollId: item.payrollId,
        assignments: item.assignments?.find((i) => i.primaryLocation) ? item.assignments : item.assignments?.map((i, index) => ({
          ...i,
          primaryLocation: index === 0 ? true : i.primaryLocation,
        })),
      })),
    );
  }, [employeesSearchResults]);

  return (
    <AppModal
      modal={modalName}
      title={t('employees:workerLinking')} // TODO: change me
      cancelBtnText={t('common:cancel')}
      onClose={handleModalClose}
      actionBtnText={t('employees:link')}
      onAction={onSubmit}
      isCloseDisabled={isSubmitting}
      isActionDisabled={isSubmitting || !hasSelectedEmployments || !worker}
    >
      <Loader active={pendingEmployeesSearch} />

      <LinkToEmployerFormContext.Provider value={formik}>
        <TitleSeparator>
          {t('employees:workerLinkingPageHeader')}
        </TitleSeparator>
        <WorkerResult worker={worker} />
        <form>
          <TitleSeparator>
            {t('employees:workerLinkingOrganizationHeader')}
          </TitleSeparator>
          <Grid
            gridColumns={12}
            gridMargins={16}
            align={ALIGNMENT.center}
          >
            <AppInput
              name="email"
              cellSpan={[12, 6, 4]}
              label={t('employees:email.label')}
              context={LinkToEmployerFormContext}
              inputProps={{
                autoComplete: 'off',
              }}
            />

            <AppInput
              name="mobile"
              cellSpan={[12, 6, 4]}
              label={t('employees:mobileNumber')}
              inputProps={{
                autoComplete: 'off',
              }}
              context={LinkToEmployerFormContext}
            />

            <AppSelect
              name="organization"
              cellSpan={[12, 12, 4]}
              label={t('employees:organization.label')}
              searchable
              options={
                organizations
                  ?.map((org) => ({
                    label: org?.name,
                    value: org?.id,
                  }))
                  ?.sort((a, b) => a.label.localeCompare(b.label))
              }
              context={LinkToEmployerFormContext}
            />
          </Grid>

          <Grid
            gridColumns={12}
            gridMargins={16}
            align={ALIGNMENT.center}
          >
            <AppInput
              name="firstName"
              label={t('employees:firstName.label')}
              cellSpan={[12, 4]}
              inputProps={{
                autoComplete: 'off',
              }}
              context={LinkToEmployerFormContext}
            />

            <AppInput
              name="lastName"
              cellSpan={[12, 4]}
              label={t('employees:lastName.label')}
              inputProps={{
                autoComplete: 'off',
              }}
              context={LinkToEmployerFormContext}
            />

            <CellFormControl
              cellSpan={[12, 4]}
              label="&nbsp;"
              cellAlign={ALIGNMENT.end}
            >
              <Button
                size={SIZE.compact}
                type="button"
                kind={KIND.primary}
                overrides={searchEmploymentButtonOverrides}
                onClick={() => handleSearchEmploymentClick()}
              >
                {t('common:searchButton')}
              </Button>
            </CellFormControl>
          </Grid>

          {employmentsSearched && (
            <EmploymentsList
              toggle={toggle as () => void}
              onAssignmentsChange={onAssignmentsChange as () => void}
              employments={employments}
            />
          )}
          {employmentsSearched && employments?.length > 0 && (
            <Block
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
              marginBottom="16px"
            >
              <Pagination
                size={SIZE.compact}
                numPages={numPages}
                currentPage={pageNumber}
                overrides={workaroundPaginationTransparentOverrides}
                onPageChange={handlePageChange}
              />
            </Block>
          )}
        </form>
      </LinkToEmployerFormContext.Provider>
    </AppModal>
  );
};

export default memo(LinkToEmployerModal);
