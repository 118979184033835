import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Block } from 'baseui/block';
import {
  LabelMedium,
  LabelSmall,
} from 'baseui/typography';
import moment from 'moment';
import {
  Key,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'baseui';
import {
  DailyShifts,
  Shift,
} from 'types/WorkerTypes';
import WorkerShiftsShiftListItem from './WorkerShiftsShiftListItem';
import WorkerShiftsAvailableShiftsItem from './WorkerShiftsAvailableShiftsItem';

const WorkerShiftsDateListItem = ({
  dailyShifts,
}: {
  dailyShifts: DailyShifts,
}) => {
  const { t } = useTranslation(['workers', 'dateFormats']);
  const [css] = useStyletron();
  const dateFormat = t('dateFormats:standard-day-of-week');
  const theme = useStyletron()[1];

  return (
    <div>
      <Block
        display="flex"
        alignItems="center"
        padding="16px 0px"
      >
        <FontAwesomeIcon
          icon={faCalendar}
          className={css({
            marginRight: '12px',
          })}
        />
        <LabelMedium>{moment(dailyShifts.date).format(dateFormat)}</LabelMedium>
      </Block>
      {dailyShifts.shifts.length > 0 ? (
        dailyShifts.shifts.map((dailyShift: Shift) => (
          <WorkerShiftsShiftListItem
            shiftsList={dailyShift}
            isOpenShifts={false}
            key={`${dailyShifts.date}-${Math.random()}-shift-shiftListItem` as Key}
          />
        ))
      ) : (
        <LabelSmall
          paddingBottom="16px"
          color={theme.colors.contentStateDisabled}
        >
          {t('workers:noShiftsSchedules')}
        </LabelSmall>
      )}
      {dailyShifts.openShiftsCount && dailyShifts.openShiftsCount > 0 ? (
        <WorkerShiftsAvailableShiftsItem
          availableShiftsCount={dailyShifts.openShiftsCount}
          date={dailyShifts.date}
        />
      ) : (
        <Block />
      )}
    </div>
  );
};

export default memo(WorkerShiftsDateListItem);
