import { ReactNode, memo } from 'react';
import { useStyletron } from 'styletron-react';

const containerStyles = {
  background: '#dfffcc',
};

export type HighLightSearchPropsType = { search: string | undefined, children: ReactNode }

const HighLightSearch = ({
  search,
  children,
}: HighLightSearchPropsType) => {
  const [css] = useStyletron();

  return (
    <span
      className={search && children?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
        ? css(containerStyles)
        : ''}
    >
      {children}
    </span>
  );
};

export default memo(HighLightSearch);
