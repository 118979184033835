import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toaster, ToasterContainer } from 'baseui/toast';
import { errorSelector, resetError } from 'store/slices/error';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { ErrorFallbackPropsType } from 'types/GeneralTypes';
import { useMsal } from '@azure/msal-react';
import { logout } from 'store/slices/user';
import { useStyletron } from 'styletron-react';

const titleClass = {
  'line-height': 'calc(100% + 16px)',
};

const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: ErrorFallbackPropsType) => {
  const dispatch = useAppDispatch();
  const appError = useAppSelector(errorSelector);
  const { t } = useTranslation(['errors', 'common']);
  const { instance } = useMsal();
  const [css] = useStyletron();

  const onClose = () => {
    dispatch(resetError());
    resetErrorBoundary && resetErrorBoundary();
  };

  useEffect(() => {
    (error?.message || appError?.message || appError?.messageKey) && toaster.negative(
      <>
        <h2 className={css(titleClass)}>
          {(appError?.errorTitleKey && t(appError?.errorTitleKey as any)) || appError?.errorTitle || t('errors:error')}
        </h2>
        <div>{error?.message || (appError?.messageKey && t(appError?.messageKey as any)) || appError?.message || t('errors:somethingWentWrong')}</div>
      </>,
      { onClose },
    );
  }, [error, appError]);

  useEffect(() => {
    if (appError?.errorTitleKey === 'errors:errorCode.AUTHENTICATION_ERROR') {
      dispatch(logout(instance));
    }
  }, [appError?.errorTitleKey]);

  return (
    <ToasterContainer
      autoHideDuration={3000}
      overrides={{
        Root: {
          style: {
            zIndex: 1000,
          },
        },
        ToastBody: {
          style: {
            backgroundColor: '#c57272',
            minWidth: '50%',
          },
        },
        ToastInnerContainer: {
          style: {
            width: '100%',
            overflow: 'auto',
            maxWidth: '100%',
            height: '100%',
          },
        },
      }}
    />
  );
};

export default ErrorFallback;
