import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectProps, SIZE } from 'baseui/select';
import {
  Cell,
  ALIGNMENT,
  Responsive,
  CellProps,
  Alignment,
} from 'baseui/layout-grid';
import {
  FormControlProps,
} from 'baseui/form-control';
import {
  FormikHandlers, FormikHelpers, FormikState,
} from 'formik';
import AppFormControl from './AppFormControl';

export type AppSelectPropsType = {
  name: string
  context: any
  options: { value: string | number | null, label: string }[]
  label?: any
  maxDropdownHeight?: string
  cellSpan?: Responsive<number> | undefined
  cellAlign?: Alignment| undefined
  cellProps?: CellProps
  selectProps?: SelectProps
  formControlProps?: Pick<FormControlProps, Exclude<keyof FormControlProps, 'children'>>
  placeholder?: string
  clearable?: boolean
  searchable?: boolean
  showStar?: boolean
  tooltipTitle?: ReactNode
  tooltipContent?: ReactNode
  selectDisabled?: boolean
  selectLoading?: boolean
  noResultsText?: string
  children?: ReactNode
}

const AppSelect = ({
  label,
  name,
  cellSpan = 3,
  maxDropdownHeight = '280px',
  cellAlign = ALIGNMENT.start,
  cellProps,
  selectProps,
  formControlProps,
  context,
  options,
  placeholder,
  showStar,
  clearable = false,
  searchable = false,
  tooltipTitle,
  tooltipContent,
  selectDisabled = false,
  selectLoading = false,
  noResultsText,
  children = null,
}: AppSelectPropsType) => {
  const formik = useContext<FormikState<any> & FormikHelpers<any> & FormikHandlers>(context);
  const { t } = useTranslation();
  const {
    errors,
    values,
    touched,
    setFieldValue,
    handleBlur,
  } = formik;

  return (
    <Cell
      span={cellSpan}
      align={cellAlign}
      {...cellProps}
    >
      <AppFormControl
        tooltipTitle={tooltipTitle}
        tooltipContent={tooltipContent}
        showStar={showStar}
        label={label}
        error={!!(errors[name] && touched[name]) && errors[name]}
        {...formControlProps}
      >
        <Select
          size={SIZE.compact}
          id={name}
          type="select"
          clearable={clearable}
          searchable={searchable}
          options={options}
          labelKey="label"
          valueKey="value"
          maxDropdownHeight={maxDropdownHeight}
          onBlur={handleBlur}
          placeholder={placeholder || t('select')}
          value={values[name]}
          onChange={(option) => setFieldValue(name, [option.option])}
          error={!!(errors[name] && touched[name] && errors[name])}
          disabled={selectDisabled}
          isLoading={selectLoading}
          noResultsMsg={noResultsText}
          overrides={{
            ValueContainer: {
              props: {
                'data-testid': `Form-select-${name}`,
              },
            },
            ControlContainer: {
              props: {
                id: `Form-select-${name}`,
              },
            },
            Input: {
              props: { id: `Form-select-input-${name}`, name: `Form-select-input-name-${name}` },
            },
          }}
          {...selectProps}
        />
      </AppFormControl>
      {children}
    </Cell>
  );
};

export default AppSelect;
