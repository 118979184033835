import {
  ChangeEvent,
  memo,
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import { Search } from 'baseui/icon';
import {
  Input,
  SIZE,
} from 'baseui/input';
import { Block } from 'baseui/block';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { OnChangeParams, Select, Value } from 'baseui/select';
import CellFormControl from 'components/CellFormControl';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  fetchPayrollPeriods,
  payGroupPayrollPeriodsSelector,
  payGroupsSelector,
} from 'store/slices/payGroups';
import { repaymentSelector } from 'store/slices/repayments';
import {
  PaymentAllocationsFilterType,
  RepaymentTypesType,
} from 'types/RepaymentTypes';
import { borderRadius } from 'theme';
import { fetchAllocatedBatchAllocations } from 'store/slices/paymentAllocation';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import AllocatedPaymentsTable from './AllocatedPaymentsTable';
import TPOAllocatedPaymentsTable from './TPOAllocatedPayments/TPOAllocatedPaymentsTable';

const contentContainerStyles = {
  padding: '30px 24px',
  paddingTop: '30px',
  zIndex: 50,
};

const containerAllocatedSectionStyles = {
  background: '#fff',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  'box-sizing': 'border-box',
  padding: '24px',
  maxWidth: '100%',
  position: 'relative',
} as StyleObject;

const AllocatedPaymentsSection = () => {
  const [css] = useStyletron();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['batches', 'errors', 'common', 'dateFormats']);
  const payGroups = useAppSelector(payGroupsSelector);
  const payGroupPayrollPeriods = useAppSelector(payGroupPayrollPeriodsSelector);
  const organization = useAppSelector(loggedOrganizationSelector);
  const repayment = useAppSelector(repaymentSelector);
  const [payGroup, setPayGroup] = useState<Value>();
  const [payrollPeriod, setPayrollPeriod] = useState<Value>();
  const [search, setSearch] = useState('');

  const dateFormat = t('dateFormats:standard');

  const {
    id: organizationID,
  } = organization || {};

  const {
    dateReceived,
  } = repayment;

  const handlePayGroupChange = ({
    value,
  }: OnChangeParams) => {
    setPayGroup(value);
  };

  const handlePayrollPeriodChange = ({
    value,
  }: OnChangeParams) => {
    setPayrollPeriod(value);
  };

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    setSearch(e.target.value);
  };

  const filter: PaymentAllocationsFilterType = {
    payGroupID: payGroup && payGroup[0]?.id?.toString(),
    search: search?.trim(),
    payrollPeriodID: payrollPeriod && payrollPeriod[0]?.id?.toString(),
  };

  useEffect(() => {
    if (organizationID && payGroup && payGroup[0]?.id) {
      dispatch(fetchPayrollPeriods({ organizationID, payGroupID: payGroup[0]?.id?.toString() }));
    } else {
      setPayrollPeriod([]);
    }
  }, [organizationID, payGroup]);

  useEffect(() => {
    dispatch(fetchAllocatedBatchAllocations({ organizationID, pageNumber: '1', repaymentId: String(repayment.id) }));
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={css(contentContainerStyles)}>
      <Grid
        gridColumns={12}
        gridMargins={0}
      >
        <Cell span={12}>
          <div
            className={css(containerAllocatedSectionStyles)}
          >
            <Grid
              gridColumns={12}
              gridMargins={0}
            >
              <Cell
                align={ALIGNMENT.center}
                span={[12, 6, 9]}
              >
                <h3>{t('batches:paymentAllocations.allocatedPayments')}</h3>
              </Cell>

              <Cell
                align={ALIGNMENT.center}
                span={[12, 6, 3]}
              >
                <Block
                  display="flex"
                  justifyContent="end"
                >
                  {t('batches:paymentAllocations.paymentReceived')}
                  :&nbsp;
                  <strong>
                    {moment(dateReceived).format(t('dateFormats:standard'))}
                  </strong>
                </Block>
              </Cell>
            </Grid>

            <hr />

            <Grid
              gridColumns={12}
              gridMargins={0}
            >
              <Cell span={12}>
                <b>
                  {t('common:filterBy')}
                </b>
              </Cell>

              <CellFormControl
                label="&nbsp;"
                cellSpan={[12, 6]}
              >
                <Input
                  size={SIZE.compact}
                  startEnhancer={<Search />}
                  type="text"
                  name="search"
                  autoComplete="off"
                  onChange={handleChangeSearch}
                  value={search}
                  id="allocated-payments-section-search"
                  clearOnEscape
                  placeholder={t('common:searchPlaceholder')}
                />
              </CellFormControl>

              <CellFormControl
                cellSpan={[12, 6, 3]}
                label={`${t('batches:payGroup.label')}`}
              >
                <Select
                  size={SIZE.compact}
                  maxDropdownHeight="300px"
                  placeholder={t('common:select')}
                  type="select"
                  clearable
                  options={payGroups
                    ?.map(({ name, id }) => ({
                      name,
                      id,
                    }))
                    ?.sort((a, b) => a.name.localeCompare(b.name))}
                  labelKey="name"
                  valueKey="id"
                  onChange={handlePayGroupChange}
                  value={payGroup as Value}
                  overrides={{
                    ControlContainer: {
                      props: {
                        id: 'allocated-payments-section-payGroup-select',
                      },
                    },
                  }}
                />
              </CellFormControl>

              <CellFormControl
                cellSpan={[12, 6, 3]}
                label={`${t('batches:payPeriod.label')}`}
              >
                <Select
                  size={SIZE.compact}
                  maxDropdownHeight="300px"
                  disabled={!payGroup || payGroup?.length === 0}
                  type="select"
                  clearable
                  options={payGroupPayrollPeriods?.map(({ startDate, endDate, id }) => ({
                    name: `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`,
                    id,
                  }))}
                  labelKey="name"
                  valueKey="id"
                  onChange={handlePayrollPeriodChange}
                  value={payrollPeriod as Value}
                  placeholder={t('common:select')}
                  overrides={{
                    ControlContainer: {
                      props: {
                        id: 'allocated-payments-section-payPeriod-select',
                      },
                    },
                  }}
                />
              </CellFormControl>
            </Grid>
            {repayment.type === RepaymentTypesType.EWA && <AllocatedPaymentsTable filter={filter} />}
            {repayment.type === RepaymentTypesType.TPO && <TPOAllocatedPaymentsTable filter={filter} />}
          </div>
        </Cell>
      </Grid>
    </div>
  );
};

export default memo(AllocatedPaymentsSection);
