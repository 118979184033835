import {
  ChangeEvent,
  memo,
  useEffect,
  useState,
} from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { Grid, Cell } from 'baseui/layout-grid';
import { Layer } from 'baseui/layer';
import { Search } from 'baseui/icon';
import { Block } from 'baseui/block';
import { Input, SIZE } from 'baseui/input';
import { Checkbox } from 'baseui/checkbox';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import {
  organizationSelector,
} from 'store/slices/organizations';
import {
  locationsSelector,
  Location,
} from 'store/slices/locations';
import { errorSelector } from 'store/slices/error';
import {
  AssignLocationsFormattedArray,
  AssignLocationsValuesType,
  OrganizationFormAssignLocationsModalPropsType,
} from 'types/PayGroupTypes';
import AppModal from 'components/AppModal/AppModal';
import { assignLocationsValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import { assignLocationsInitialValues as initialValues } from 'initialValues/PayGroupsInitialValues';
import CellFormControl from 'components/CellFormControl';
import {
  assignLocationsToPayGroup,
  fetchPayGroupWithLocations,
  payGroupAssignedLocationsSelector,
  payGroupPendingAssignLocationsSelector,
  selectedPayGroupSelector,
} from 'store/slices/payGroups';
import Loader from 'components/Loader';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import { LocationRowAssignLocationsType } from 'types/OrganizationTypes';

export type ISelectedLocation = {
  selected?: boolean
}

const OrganizationFormAssignLocationsModal = ({
  payGroupID,
}: OrganizationFormAssignLocationsModalPropsType) => {
  const { t } = useTranslation(['organizations', 'errors', 'common', 'locations', 'payGroups', 'batches', 'employees']);
  const dispatch = useAppDispatch();
  const organizationLocations = useAppSelector(locationsSelector);
  const locationsAssigned = useAppSelector(payGroupAssignedLocationsSelector);
  const organization = useAppSelector(organizationSelector);
  const modals = useAppSelector(modalsSelector);
  const pendingAssignLocations = useAppSelector(payGroupPendingAssignLocationsSelector);
  const payGroup = useAppSelector(selectedPayGroupSelector);
  const error = useAppSelector(errorSelector);
  const [search, setSearch] = useState('');
  const [locations, setLocations] = useState<(Location & ISelectedLocation)[]>([]);
  const { id: organizationID } = organization || {};
  const modalName = ModalNames.ASSIGN_LOCATIONS_MODAL;
  const isModalOpen = checkIsModalOpen(modals, modalName);
  const hasAny = Boolean(locations.length);
  const hasAll = hasAny && locations?.every((x) => x.selected);
  const hasSome = hasAny && locations.some((x) => x.selected);
  const selectedLocations: AssignLocationsFormattedArray[] = locations
    .map((x) => {
      const hasPayGroupCondition = x?.payGroup?.id === undefined
        ? null
        : x.payGroup?.id;
      return {
        locationId: Number(x.id),
        payGroupId: x.selected === false
          ? hasPayGroupCondition
          : Number(payGroupID),
      };
    });

  const hasOrganizationLocations = organizationLocations?.length > 0;

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const toggleAll = () => {
    setLocations((data) => data.map((row) => ({
      ...row,
      selected: !hasAll,
    })));
  };

  const toggle = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = event.currentTarget;

    setLocations(locations.map((row) => {
      const hasPayGroupCondition = row?.payGroup?.id === Number(payGroupID)
        ? null
        : row.payGroup;
      return {
        ...row,
        payGroup: (String(row.id) === name && checked === false)
          ? hasPayGroupCondition
          : null,
        selected: String(row.id) === name ? checked : row.selected,
      };
    }));
  };

  const handleChangeSearch = (
    e: ChangeEvent<InputCustomHTMLElement>,
  ) => {
    setSearch(e.target.value);
  };

  const onSubmit = () => {
    const selectedLocationsFormatted: AssignLocationsValuesType = { relations: selectedLocations };
    dispatch(
      assignLocationsToPayGroup({
        organizationID,
        payGroupID,
        data: selectedLocationsFormatted,
      }),
    )
      .then(() => { setIsModalOpen(false); });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    handleSubmit,
    validateForm,
    setSubmitting,
    resetForm,
  } = formik;

  const handleSubmitExtended = () => {
    const selectedLocationsFormatted: AssignLocationsValuesType = { relations: selectedLocations };

    validateForm(selectedLocationsFormatted)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  const handleModalClose = () => {
    setLocations(() => []);
    setIsModalOpen(false);
  };

  useEffect(() => {
    resetForm();
    setSubmitting(false);
  }, []);

  useEffect(() => {
    isModalOpen && organizationID && payGroupID && dispatch(fetchPayGroupWithLocations({ organizationID, payGroupID }));
  }, [isModalOpen]);

  useEffect(() => {
    organizationLocations && setLocations(organizationLocations.map((ol) => ({
      ...ol,
      selected: String(ol.payGroup?.id) === String(payGroupID),
    })));
  }, [organizationLocations]);

  useEffect(() => {
    if (locationsAssigned) {
      resetForm();
      setSubmitting(false);
    }
  }, [locationsAssigned]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  return (
    <Layer index={400}>
      <AppModal
        confirm
        minWidth="360px"
        modal={modalName}
        title={t('locations:assignLocations')}
        cancelBtnText={t('common:cancel')}
        onClose={handleModalClose}
        actionBtnText={t('common:assign')}
        onAction={handleSubmitExtended}
        isActionDisabled={!hasOrganizationLocations}
        isCloseDisabled={!hasOrganizationLocations}
      >
        <Loader active={pendingAssignLocations} />

        {locations?.length > 0 && (
          <>
            <Block
              marginTop="24px"
              marginBottom="8px"
            >
              <Grid
                gridColumns={12}
                gridMargins={16}
              >
                <Cell
                  span={[12, 6, 3]}
                >
                  <strong>
                    {t('common:selected')}
                  </strong>
                  :&nbsp;
                  {selectedLocations.length}
                </Cell>

                <Cell
                  span={[12, 6]}
                >
                  <strong>
                    {t('payGroups:payGroup')}
                  </strong>
                  :&nbsp;
                  {payGroup?.payFrequencyRule}
                </Cell>
              </Grid>
            </Block>

            <Grid
              gridColumns={12}
              gridMargins={16}
            >
              <CellFormControl cellSpan={12}>
                <Block marginTop="16px">
                  <Input
                    size={SIZE.compact}
                    clearOnEscape
                    clearable
                    startEnhancer={<Search />}
                    autoComplete="off"
                    type="text"
                    name="search"
                    onChange={handleChangeSearch}
                    value={search}
                    placeholder={t('payGroups:assignLocation.search.placeholder')}
                    overrides={{
                      Input: {
                        props: {
                          id: 'OrganizationFormAssignLocationsModal-search',
                        },
                      },
                    }}
                  />
                </Block>
              </CellFormControl>
            </Grid>
            <Grid
              gridColumns={12}
              gridMargins={16}
            >
              <Cell
                span={12}
              >
                <TableBuilder
                  data={locations?.filter((item) => item?.name.toLowerCase().includes(search.toLowerCase()))?.sort((a, b) => a.name.localeCompare(b.name))}
                  overrides={{
                    Root: {
                      style: {
                        maxHeight: '360px',
                        maxWidth: '100%',
                        minWidth: '380px',
                        overflow: 'auto',
                      },
                    },
                  }}
                >
                  <TableBuilderColumn
                    header={(
                      <Checkbox
                        checked={hasAll}
                        isIndeterminate={!hasAll && hasSome}
                        onChange={toggleAll}
                        overrides={{
                          Input: {
                            props: {
                              id: 'OrganizationFormAssignLocationsModal-select-all',
                              'aria-checked': `${hasAll}`,
                            },
                          },
                        }}
                      />
                    )}
                  >
                    {(row: LocationRowAssignLocationsType) => (
                      <Checkbox
                        name={row.id}
                        checked={row.selected}
                        onChange={toggle}
                        overrides={{
                          Input: {
                            props: {
                              id: `OrganizationFormAssignLocationsModal-checkbox-${row.id}`,
                              'aria-checked': `${row.selected}`,
                            },
                          },
                        }}
                      />
                    )}
                  </TableBuilderColumn>

                  <TableBuilderColumn
                    header={t('payGroups:assignLocation.header.location')}
                  >
                    {(row: LocationRowAssignLocationsType) => row.name}
                  </TableBuilderColumn>

                </TableBuilder>
              </Cell>
            </Grid>
          </>
        )}

        {locations?.length === 0 && (
          <Block
            marginTop="48px"
            marginBottom="32px"
          >
            <Grid
              gridColumns={12}
              gridMargins={16}
            >
              <Cell
                span={12}
              >
                <h3>{t('locations:assignLocations.noResult')}</h3>
              </Cell>
            </Grid>
          </Block>
        )}
      </AppModal>
    </Layer>
  );
};

export default memo(OrganizationFormAssignLocationsModal);
