export const repaymentsInitialValues = {
  referenceID: '' as string,
  paymentAmount: 1 as number,
  dateReceived: [new Date()],
};

export const tpoRepaymentsInitialValues = {
  referenceID: '' as string,
  dateReceived: [new Date()],
};
