import {
  ChangeEvent,
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import CellFormControl from 'components/CellFormControl';
import {
  OnChangeParams,
  SIZE, Select, Value,
} from 'baseui/select';
import {
  locationConfigSelector,
} from 'store/slices/locations';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  fetchTippedEmployeesByPayPeriod,
  tpoSearchByPayrollPeriodEmployeeStatusSelector,
  tpoSearchPayrollPeriodSelector,
  tippedEmployeesByPayPeriodListSelector,
  tippedEmployeesByPayPeriodPendingListSelector,
  tippedEmployeesSelectedLocationSelector,
  tippedEmployeesByPayPeriodTotalSizeSelector,
  selectedTippedEmployeesSelector,
  resetSelectedTippedEmployees,
  setIsSingleTipPayoutOptionChosen,
  isSingleTipPayoutOptionChosenSelector,
  tpoSearchByPayrollPeriodNameSelector,
  setTPOSearchNameInPayPeriod,
  setTPOSearchPaymentStatusInPayPeriod,
  setTPOSearchEmployeeStatusInPayPeriod,
  tpoSearchByPayrollPeriodPaymentStatusSelector,
  setTPOSearchNameInPayPeriodChanged,
  setTPOSearchEmployeeStatusInPayPeriodChanged,
  setTPOSearchPaymentStatusInPayPeriodChanged,
  tpoSearchByPayrollPeriodNameChangedSelector,
  tpoSearchByPayrollPeriodEmployeeStatusChangedSelector,
  tpoSearchByPayrollPeriodPaymentStatusChangedSelector,
} from 'store/slices/tpo';
import moment from 'moment-timezone';
import {
  Button,
  KIND,
} from 'baseui/button';
import Loader from 'components/Loader';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import hasAccess from 'utils/hasAccess';
import { organizationConfigSelector } from 'store/slices/organizations';
import { ConfigType } from 'types/OrganizationTypes';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';
import { TPOEmploymentStatuses } from 'screens/Employees/EmployeesHelpers';
import {
  FetchTippedEmployeesByPayPeriodParamsType,
  TPOEmployeeStatuses,
} from 'types/TPOTypes';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import {
  LOADER_STYLE,
  contentLocationContainerStyles,
  listContainerStyles,
} from './TipManagementHelper';
import TippedEmployeesPerPayPeriodTable from './TippedEmployeesPerPayPeriodTable';

let timer: any;

export type TipManagementPayPeriodSectionPropsType = {
  isRunTPOPaymentsButtonEnabled: boolean,
}

const TipManagementPayPeriodSection = ({
  isRunTPOPaymentsButtonEnabled,
}: TipManagementPayPeriodSectionPropsType) => {
  const { t } = useTranslation(['common', 'tipsManagement', 'dateFormats']);
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const loggedOrganizationID = useAppSelector(loggedOrganizationSelector)?.id;
  const payrollPeriod = useAppSelector(tpoSearchPayrollPeriodSelector);
  const employeeStatus = useAppSelector(tpoSearchByPayrollPeriodEmployeeStatusSelector);
  const paymentStatus = useAppSelector(tpoSearchByPayrollPeriodPaymentStatusSelector);
  const totalSize = useAppSelector(tippedEmployeesByPayPeriodTotalSizeSelector);
  const isSingleTPOChecked = useAppSelector(isSingleTipPayoutOptionChosenSelector);
  const location = useAppSelector(tippedEmployeesSelectedLocationSelector);
  const selectedTippedEmployees = useAppSelector(selectedTippedEmployeesSelector);
  const pendingList = useAppSelector(tippedEmployeesByPayPeriodPendingListSelector);
  const tippedEmployees = useAppSelector(tippedEmployeesByPayPeriodListSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const locationConfig = useAppSelector(locationConfigSelector);
  const search = useAppSelector(tpoSearchByPayrollPeriodNameSelector);
  const modals = useAppSelector(modalsSelector);
  const [searchDebounce, setSearchDebounce] = useState('');
  const searchNameChanged = useAppSelector(tpoSearchByPayrollPeriodNameChangedSelector);
  const searchEmployeeStatusChanged = useAppSelector(tpoSearchByPayrollPeriodEmployeeStatusChangedSelector);
  const searchPaymentStatusChanged = useAppSelector(tpoSearchByPayrollPeriodPaymentStatusChangedSelector);

  const isConfirmModalOpen = !!modals?.find((item) => item.name === ModalNames.CONFIRM_MODAL)?.isOpen;

  const organizationTPODailyPayoutCondition = organizationConfig
    ?.find((item) => item.configKeyName === ConfigType.TPO_DAILY_PAYOUT_SCHEDULE)?.configValue === 'API'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_DAILY_PAYOUT_SCHEDULE)?.configValue === 'API_OR_SCHEDULED';
  const organizationTPOEnabledOrgAndLocation = organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_ENABLED)?.configValue === 'true'
    && locationConfig?.find((i) => i.configKeyName === ConfigType.TPO_ENABLED)?.configValue === 'true';

  const orgTimezone = organizationConfig?.find((item) => item.configKeyName === ConfigType.CORPORATE_TIMEZONE)?.configValue;
  const locationTimezone = location[0]?.timezone;

  const currentTimeIsBeforeOrgTPOCutoffDate = orgTimezone
    && moment(moment.tz(new Date(), orgTimezone).format('YYYY-MM-DDTHH:mm:ss')).isBefore(payrollPeriod[0]?.tcoCutoffTime);
  const currentTimeIsAfterLocationStartDate = moment(moment.tz(new Date(), locationTimezone).format('YYYY-MM-DDTHH:mm:ss'))
    .isAfter(payrollPeriod[0]?.startDate);

  const runTipPaymentsCondition = payrollPeriod[0]?.tcoStatus === 'OPEN'
    || (payrollPeriod[0]?.tcoStatus === 'FUTURE'
      && currentTimeIsAfterLocationStartDate
      && currentTimeIsBeforeOrgTPOCutoffDate);

  const tpoPaymentStatuses = [
    {
      id: 1,
      value: TPOEmployeeStatuses.EMPLOYEE_NOT_REGISTERED,
      label: t(`tipsManagement:statuses.${TPOEmployeeStatuses.EMPLOYEE_NOT_REGISTERED}`),
    },
    {
      id: 2,
      value: TPOEmployeeStatuses.PAYMENT_FAILED,
      label: t(`tipsManagement:statuses.${TPOEmployeeStatuses.PAYMENT_FAILED}`),
    },
    {
      id: 3,
      value: TPOEmployeeStatuses.OK,
      label: t(`tipsManagement:statuses.${TPOEmployeeStatuses.OK}`),
    },
    {
      id: 4,
      value: TPOEmployeeStatuses.TCO_DEFAULT_ACCOUNT_NOT_SELECTED,
      label: t(`tipsManagement:statuses.${TPOEmployeeStatuses.TCO_DEFAULT_ACCOUNT_NOT_SELECTED}`),
    },
    {
      id: 5,
      value: TPOEmployeeStatuses.NON_TCO_EMPLOYMENT,
      label: t(`tipsManagement:statuses.${TPOEmployeeStatuses.NON_TCO_EMPLOYMENT}`),
    },
    {
      id: 6,
      value: TPOEmployeeStatuses.PAYMENT_PROCESSING,
      label: t(`tipsManagement:statuses.${TPOEmployeeStatuses.PAYMENT_PROCESSING}`),
    },
  ];

  const setIsConfirmModalOpen = (
    isOpen: boolean,
  ) => {
    isConfirmModalOpen !== isOpen && dispatch(setModal({
      name: ModalNames.CONFIRM_MODAL,
      isOpen,
    }));
  };

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    dispatch(setTPOSearchNameInPayPeriod(e.target.value));
    dispatch(setTPOSearchNameInPayPeriodChanged(true));
  };

  const handleEmployeeStatusesChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(setTPOSearchEmployeeStatusInPayPeriod(value));
    dispatch(setTPOSearchEmployeeStatusInPayPeriodChanged(true));
  };

  const handlePaymentStatusChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(setTPOSearchPaymentStatusInPayPeriod(value));
    dispatch(setTPOSearchPaymentStatusInPayPeriodChanged(true));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();
    if (location && location.length > 0) {
      dispatch(fetchTippedEmployeesByPayPeriod({
        organizationID: loggedOrganizationID,
        locationID: location[0].id?.toString(),
        payrollPeriodID: payrollPeriod[0].id?.toString(),
        pageNumber: page,
        statuses: paymentStatus?.[0]?.value || [],
        employmentStatuses: employeeStatus?.[0]?.name || [],
        name: searchDebounce,
      }));
    }
  };

  useEffect(() => {
    dispatch(resetSelectedTippedEmployees());
  }, [isSingleTPOChecked]);

  useEffect(() => {
    if (!organizationTPOEnabledOrgAndLocation
      || !organizationTPODailyPayoutCondition
      || !runTipPaymentsCondition) {
      dispatch(setIsSingleTipPayoutOptionChosen(false));
    }
  }, [runTipPaymentsCondition, organizationTPODailyPayoutCondition, organizationTPOEnabledOrgAndLocation]);

  useEffect(() => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      setSearchDebounce(search);
    }, 1000);
  }, [search]);

  useEffect(() => {
    if ((location && location[0]?.id)
      && (payrollPeriod && Object.keys(payrollPeriod).length !== 0)
      && (((paymentStatus || paymentStatus.length === 0) && searchPaymentStatusChanged)
        || ((employeeStatus || employeeStatus.length === 0) && searchEmployeeStatusChanged)
        || ((search || search === '') && searchNameChanged && search === searchDebounce))) {
      const filter = new Map([
        ['organizationID', loggedOrganizationID],
        ['locationID', location?.[0]?.id?.toString()],
        ['payrollPeriodID', payrollPeriod?.[0].id?.toString()],
        ['pageNumber', '1'],
        ['employmentStatuses', (employeeStatus && employeeStatus?.[0]?.name) || ''],
        ['statuses', (paymentStatus && paymentStatus?.[0]?.value) || ''],
        ['name', search || ''],
      ]);
      dispatch(fetchTippedEmployeesByPayPeriod(Object.fromEntries(filter) as FetchTippedEmployeesByPayPeriodParamsType));
    }
  }, [
    employeeStatus,
    paymentStatus,
    searchDebounce,
  ]);

  return (
    <Grid gridColumns={12}>
      <div className={css(contentLocationContainerStyles)}>
        <Grid gridColumns={12} gridMargins={20}>
          <CellFormControl
            cellSpan={[12, 4, 2]}
            label={t('common:searchButton')}
          >
            <Input
              disabled={!location || !location?.length || pendingList}
              clearable
              clearOnEscape
              size={SIZE.compact}
              startEnhancer={<Search />}
              type="text"
              name="search"
              onChange={handleChangeSearch}
              value={search}
              placeholder={t('tipsManagement:searchByName.placeholder')}
              overrides={{
                Input: {
                  props: {
                    id: 'Tip-Management-PayPeriod-view-Search-by-NamePattern',
                    'data-testid': 'Tip-Management-PayPeriod-view-Search-by-NamePattern',
                    autoComplete: 'off',
                  },
                },
              }}
            />
          </CellFormControl>
          <CellFormControl
            cellSpan={[12, 4, 2]}
            label={t('tipsManagement:status.label')}
          >
            <Select
              disabled={!location || !location?.length || pendingList}
              size={SIZE.compact}
              id="employee-status-select"
              clearable
              placeholder={t('tipsManagement:searchByEmployeeStatus.placeholder')}
              type="select"
              options={TPOEmploymentStatuses.map(({ name, id }) => ({
                name,
                id,
              }))}
              labelKey="name"
              valueKey="id"
              onChange={handleEmployeeStatusesChange}
              value={employeeStatus as Value}
              maxDropdownHeight="300px"
              overrides={{
                ControlContainer: {
                  props: {
                    id: 'Tip-Management-PayPeriod-view-Search-by-EmployeeStatus-select',
                    'data-testid': 'Tip-Management-PayPeriod-view-Search-by-EmployeeStatus-select',
                  },
                },
              }}
            />
          </CellFormControl>
          <CellFormControl
            cellSpan={[12, 4, 2]}
            label={t('tipsManagement:paymentStatus.label')}
          >
            <Select
              disabled={!location || !location?.length || pendingList}
              size={SIZE.compact}
              id="payment-status-select"
              clearable
              placeholder={t('tipsManagement:searchByPaymentStatus.placeholder')}
              type="select"
              options={tpoPaymentStatuses}
              labelKey="label"
              valueKey="value"
              onChange={handlePaymentStatusChange}
              value={paymentStatus as Value}
              maxDropdownHeight="300px"
              overrides={{
                ControlContainer: {
                  props: {
                    id: 'Tip-Management-PayPeriod-view-Search-by-PaymentStatus-select',
                    'data-testid': 'Tip-Management-PayPeriod-view-Search-by-PaymentStatus-select',
                  },
                },
              }}
            />
          </CellFormControl>
          <Cell skip={[0, 0, 2]} />
          <CellFormControl
            cellSpan={[12, 3, 3]}
            cellAlign={ALIGNMENT.end}
            formControlProps={{
              overrides: {
                ControlContainer: {
                  style: {
                    '@media screen and (min-width: 1136px)': {
                      display: 'inline-flex',
                      justifyItems: 'end',
                      justifyContent: 'flex-end',
                    },
                  },
                },
              },
            }}
          >
            <Button
              kind={KIND.primary}
              disabled={(!isRunTPOPaymentsButtonEnabled && isSingleTPOChecked === false)
                || !organizationTPOEnabledOrgAndLocation
                || !organizationTPODailyPayoutCondition
                || !runTipPaymentsCondition
                || !hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.EWAClientManager, AccessUnit.EWAClientLocationManager])
                || (tippedEmployees && tippedEmployees.length === 0)
                || (isSingleTPOChecked && selectedTippedEmployees.length === 0)}
              onClick={() => { setIsConfirmModalOpen(true); }}
              overrides={{
                Root: {
                  style: {
                    height: '36px',
                  },
                  props: {
                    'data-testid': 'TipManagement-run-tips-payments-button',
                    id: 'TipManagement-run-tips-payments-button',
                  },
                },
              }}
            >
              {t('tipsManagement:runTipPayments')}
            </Button>
          </CellFormControl>
        </Grid>
        <Grid gridColumns={12} gridMargins={20}>
          {runTipPaymentsCondition && (
            <Cell
              span={[12, 9, 9]}
              align={ALIGNMENT.start}

            >
              <Block
                marginTop="12px"
                marginBottom="12px"
              >
                <Button
                  type="button"
                  disabled={pendingList}
                  kind={isSingleTPOChecked ? KIND.secondary : KIND.primary}
                  onClick={() => dispatch(setIsSingleTipPayoutOptionChosen(false))}
                  size={SIZE.compact}
                  overrides={{
                    BaseButton: {
                      props: {
                        id: 'TipManagement-multiple-TPO',
                        name: 'TipManagement-multiple-TPO',
                      },
                    },
                  }}
                >
                  {t('tipsManagement:tpo.allEmployees.button.label')}
                </Button>
                <Button
                  type="button"
                  kind={isSingleTPOChecked ? KIND.primary : KIND.secondary}
                  size={SIZE.compact}
                  onClick={() => dispatch(setIsSingleTipPayoutOptionChosen(true))}
                  disabled={!organizationTPOEnabledOrgAndLocation
                    || !organizationTPODailyPayoutCondition
                    || !hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.EWAClientManager, AccessUnit.EWAClientLocationManager])
                    || (tippedEmployees && tippedEmployees.length === 0)}
                  overrides={{
                    BaseButton: {
                      props: {
                        id: 'TipManagement-single-TPO',
                        name: 'TipManagement-single-TPO',
                      },
                    },
                  }}
                >
                  {t('tipsManagement:tpo.selectedEmployees.button.label')}
                </Button>
              </Block>
            </Cell>
          )}
        </Grid>
        {pendingList && (
          <div className={css(listContainerStyles)}>
            <Loader active={pendingList} containerStyles={{ LOADER_STYLE }} />
          </div>
        )}
        {!pendingList && (
          <Cell span={12}>
            <TippedEmployeesPerPayPeriodTable
              handlePageChange={handlePageChange}
            />

          </Cell>
        )}
      </div>
    </Grid>
  );
};

export default memo(TipManagementPayPeriodSection);
