import { OrganizationIDType } from 'types/OrganizationTypes';
import { LocationIDType } from 'types/LocationTypes';
import { WorkerIDType } from 'types/WorkerTypes';
import request from './request';

export const fetchDailyShiftsRequest = (
  token: string,
  workerID: WorkerIDType,
  fromDate?: string,
  organizationID?: OrganizationIDType,
  locationID?: LocationIDType,
) => {
  const queryParams = new URLSearchParams({
  });
  if (fromDate) {
    queryParams.set('fromDate', fromDate);
  }
  if (organizationID) {
    queryParams.set('organizationId', organizationID);
  }
  if (locationID) {
    queryParams.set('locationId', locationID);
  }
  return request({
    token,
    url: `workers/${workerID}/daily-shifts?${queryParams}`,
  });
};

export const fetchOpenShiftsRequest = (
  token: string,
  workerID: WorkerIDType,
  fromDate?: string,
  organizationID?: OrganizationIDType,
  locationID?: LocationIDType,
) => {
  const queryParams = new URLSearchParams({
  });
  if (fromDate) {
    queryParams.set('fromDate', fromDate);
  }
  if (organizationID) {
    queryParams.set('organizationId', organizationID);
  }
  if (locationID) {
    queryParams.set('locationId', locationID);
  }
  return request({
    token,
    url: `workers/${workerID}/open-shifts?${queryParams}`,
  });
};
