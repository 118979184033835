import { LocationIDType } from 'types/LocationTypes';
import { OrganizationIDType } from 'types/OrganizationTypes';
import { PayrollPeriodIDType } from 'types/PayGroupTypes';
import request from './request';

export const fetchEmployeesOffersRequest = (
  token: string,
  organizationID: OrganizationIDType,
  employeeID: string,
) => request({
  token,
  method: 'POST',
  payload: {},
  url: `organizations/${organizationID}/employments/${employeeID}/offer/generate`,
});

export const fetchEmployeesOfferByIdRequest = (
  token: string,
  offerID: string | undefined,
) => request({
  token,
  url: `offers/${offerID}`,
});

export const fetchEmployeesTPOOffersPerLocationRequest = (
  token: string,
  locationID: LocationIDType,
  employeeID: string | undefined,
  payrollPeriodID: PayrollPeriodIDType,
) => request({
  token,
  method: 'POST',
  payload: {},
  url: `employments/${employeeID}/locations/${locationID}/payroll-periods/${payrollPeriodID}/tco-offers/generate`,
});

export const fetchEmployeesTPOOfferByIdRequest = (
  token: string,
  offerID: string | undefined,
) => request({
  token,
  url: `tco-offers/${offerID}`,
});

export const fetchEmployeesTPOOffersRequest = (
  token: string,
  organizationID: OrganizationIDType,
  employeeID: string,
) => request({
  token,
  method: 'POST',
  payload: {},
  url: `organizations/${organizationID}/employments/${employeeID}/tco-offers/generate`,
});
