import {
  memo,
  useEffect,
  useState,
} from 'react';
import { useStyletron } from 'baseui';
import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { contentLocationContainerStyles } from 'screens/TipManagement/TipManagementHelper';
import { HeadingXSmall } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import CellFormControl from 'components/CellFormControl';
import {
  OnChangeParams,
  SIZE,
  Select,
  Value,
} from 'baseui/select';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  fetchOrganizationPaycardEligibleLocations,
  fetchOrganizationPaycardsInventory,
  paycardOrganizationEligibleLocationsFetchFailedSelector,
  paycardOrganizationEligibleLocationsListSelector,
  paycardOrganizationEligibleLocationsPendingSelector,
  paycardPaycardManagementCardStockSearchLocationSelector,
  resetSearchLocation,
  setSearchLocation,
} from 'store/slices/paycards';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import PaycardManagementCardStockTable from './CardStockTable/PaycardManagementCardStockTable';

const PaycardManagementCardStockSection = () => {
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const locations = useAppSelector(paycardOrganizationEligibleLocationsListSelector);
  const pendingLocations = useAppSelector(paycardOrganizationEligibleLocationsPendingSelector);
  const fetchLocationsFailed = useAppSelector(paycardOrganizationEligibleLocationsFetchFailedSelector);
  const location = useAppSelector(paycardPaycardManagementCardStockSearchLocationSelector);
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const [locationChanged, setLocationChanged] = useState(false);

  const handleLocationChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(setSearchLocation(value));
    setLocationChanged(true);
  };

  const fetchInventory = ({ pageNum, locationID }: {pageNum: number, locationID: string}) => {
    dispatch(fetchOrganizationPaycardsInventory({
      organizationId: loggedOrganization?.id,
      locationId: locationID,
      pageSize: 5,
      page: pageNum,
    }));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    fetchInventory({ pageNum: nextPage, locationID: location?.[0]?.id.toString() || '' });
  };

  useEffect(() => {
    locationChanged && fetchInventory({ pageNum: 1, locationID: location?.[0]?.id.toString() || '' });
  }, [location]);

  useEffect(() => {
    dispatch(resetSearchLocation());
    dispatch(fetchOrganizationPaycardEligibleLocations({ organizationId: loggedOrganization?.id }));
    fetchInventory({ pageNum: 1, locationID: '' });
  }, []);

  return (
    <div className={css(contentLocationContainerStyles)}>
      <Grid gridColumns={12} gridMargins={24}>
        <Cell span={12}>
          <HeadingXSmall marginTop="24px" marginBottom="24px">
            {t('paycardManagement:paycardManagement.cardStock.sectionHeading')}
          </HeadingXSmall>
        </Cell>
        <CellFormControl
          cellSpan={[12, 4, 2.5]}
          label={t('paycardManagement:paycardManagement.cardStock.searchLocation')}
        >
          <Select
            size={SIZE.compact}
            id="cardStock-locations-search-select"
            clearable
            placeholder={t('common:all')}
            type="select"
            isLoading={pendingLocations}
            options={locations
              ?.map(({ name, id }) => ({
                name,
                id,
              }))
              ?.sort((a, b) => a.name.localeCompare(b.name))}
            labelKey="name"
            valueKey="id"
            onChange={handleLocationChange}
            value={location as Value}
            maxDropdownHeight="300px"
            noResultsMsg={fetchLocationsFailed ? t('errors:unableToLoadData') : ''}
            overrides={{
              ControlContainer: {
                props: {
                  id: 'Paycard-Management-cardStock-locations-search-select',
                  'data-testid': 'Paycard-Management-cardStock-locations-search-select',
                },
              },
            }}
          />
        </CellFormControl>
      </Grid>
      <PaycardManagementCardStockTable handlePageChange={handlePageChange} />
    </div>
  );
};

export default memo(PaycardManagementCardStockSection);
