import {
  ChangeEvent,
  memo,
  useEffect,
  useState,
} from 'react';
import {
  useAppSelector,
  useAppDispatch,
} from 'store/hooks';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import CellFormControl from 'components/CellFormControl';
import {
  SIZE,
  Select,
  OnChangeParams,
} from 'baseui/select';
import { useTranslation } from 'react-i18next';
import {
  paycardOrganizationRegisterCardChooseEmployeeSearchByCriteriaSelector,
  paycardOrganizationRegisterCardChooseEmployeeSearchSelector,
  paycardOrganizationRegisterCardSelectedEmployeeSelector,
  paycardOrganizationRegisterCardSelectedLocationSelector,
  resetOrganizationsPaycardRegisterCardEmployeeDetails,
  resetOrganizationsPaycardRegisterCardSelectedEmployee,
  setEmployeeSearch,
  setEmployeeSearchByCriteria,
} from 'store/slices/paycards';
import {
  EmployeeDetailsValuesType,
  EmployeeSearchKeyType,
} from 'types/PaycardManagementTypes';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import {
  Button,
  KIND,
} from 'baseui/button';
import { Block } from 'baseui/block';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  employeesPageNumberSelector,
  employeesPendingListSelector,
  employeesTotalSizeSelector,
  fetchFilteredEmployees,
  setEmployeesPage,
  setEmployeesPageSize,
} from 'store/slices/employees';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  setWizardActiveSections,
  wizardSectionSelector,
} from 'store/slices/sections';
import PaycardManagementRegisterCardChooseEmployeeTable from './Table/PaycardManagementRegisterCardChooseEmployeeTable';

export type PaycardManagementRegisterCardChooseEmployeePropsType = {
  setFormValues: (values: EmployeeDetailsValuesType | {}) => void;
}

const PaycardManagementRegisterCardChooseEmployee = (
  {
    setFormValues,
  }: PaycardManagementRegisterCardChooseEmployeePropsType,
) => {
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const dispatch = useAppDispatch();
  const searchByOption = useAppSelector(paycardOrganizationRegisterCardChooseEmployeeSearchByCriteriaSelector);
  const search = useAppSelector(paycardOrganizationRegisterCardChooseEmployeeSearchSelector);
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const location = useAppSelector(paycardOrganizationRegisterCardSelectedLocationSelector);
  const totalSize = useAppSelector(employeesTotalSizeSelector);
  const pageNumber = useAppSelector(employeesPageNumberSelector);
  const pendingList = useAppSelector(employeesPendingListSelector);
  const activeSection = useAppSelector(wizardSectionSelector);
  const selectedEmployee = useAppSelector(paycardOrganizationRegisterCardSelectedEmployeeSelector);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [searchDebounce, setSearchDebounce] = useState<string>('');

  const searchByOptions: { value: EmployeeSearchKeyType, label: string, disabled?: boolean }[] = [
    { value: EmployeeSearchKeyType.firstName, label: t('paycardManagement:paycardManagement.registerCard.chooseEmployee.searchByOptions.firstName') },
    { value: EmployeeSearchKeyType.lastName, label: t('paycardManagement:paycardManagement.registerCard.chooseEmployee.searchByOptions.lastName') },
    { value: EmployeeSearchKeyType.payrollNumber, label: t('paycardManagement:paycardManagement.registerCard.chooseEmployee.searchByOptions.payrollId') },
  ];

  const handleAddEmployeeClick = () => {
    dispatch(setWizardActiveSections(2));
    dispatch(resetOrganizationsPaycardRegisterCardSelectedEmployee());
    dispatch(resetOrganizationsPaycardRegisterCardEmployeeDetails());
    setFormValues({});
  };

  const handleSearchByChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(setEmployeeSearchByCriteria(value));
    dispatch(setEmployeeSearch(''));
    setHasSearched(true);
  };

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    dispatch(setEmployeeSearch(e.target.value));
    setHasSearched(true);
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();

    dispatch(setEmployeesPage(pageNumber));
    const filter: any = {
      pageNumber: page,
      locationId: location[0].id.toString(),
      pageSize: '10',
    };

    dispatch(fetchFilteredEmployees({
      organizationID: loggedOrganization?.id,
      filter,
    }));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchDebounce(search);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (pendingList) return;

    const filter: any = {
      pageNumber: '1',
      locationId: location[0].id.toString(),
      pageSize: '10',
    };

    if (searchByOption?.[0]?.value && search) {
      filter[searchByOption[0].value] = search;
    }

    if ((search || search === '') && hasSearched && activeSection === 1) {
      dispatch(fetchFilteredEmployees({
        organizationID: loggedOrganization?.id,
        filter,
      }));
    }
  }, [
    searchDebounce,
  ]);

  useEffect(() => {
    dispatch(setEmployeesPageSize('10'));
    !selectedEmployee && !search && activeSection === 1
    && dispatch(fetchFilteredEmployees({
      organizationID: loggedOrganization?.id,
      filter: {
        pageNumber: '1',
        locationId: location[0].id.toString(),
        pageSize: '10',
      },
    }));
  }, []);

  return (
    <>
      <Grid gridColumns={12} gridMargins={24}>
        <CellFormControl
          cellSpan={[12, 4, 2.5]}
          label={`${t('common:searchBy')}:`}
          cellAlign={ALIGNMENT.end}
        >
          <Select
            size={SIZE.compact}
            id="paycard-management-register-card-chooseEmployee-searchBy-select"
            clearable
            placeholder={t('common:select')}
            type="select"
            labelKey="label"
            valueKey="value"
            options={searchByOptions}
            onChange={handleSearchByChange}
            value={searchByOption}
            maxDropdownHeight="300px"
            overrides={{
              ControlContainer: {
                props: {
                  id: 'paycard-management-register-card-chooseEmployee-searchBy-select',
                  'data-testid': 'paycard-management-register-card-chooseEmployee-searchBy-selector',
                },
              },
            }}
          />
        </CellFormControl>
        <CellFormControl
          cellSpan={[12, 4, 2.5]}
          label={t('common:searchLabel')}
          cellAlign={ALIGNMENT.end}
        >
          <Input
            disabled={!searchByOption || searchByOption?.length === 0}
            size={SIZE.compact}
            startEnhancer={<Search />}
            type="text"
            name="search"
            autoComplete="off"
            onChange={handleChangeSearch}
            value={search}
            id="paycard-management-register-card-chooseEmployee-search-select"
            clearOnEscape
            placeholder={t('common:searchPlaceholder')}
            clearable
            overrides={{
              Root: {
                props: {
                  id: 'paycard-management-register-card-chooseEmployee-search-select',
                  'data-testid': 'paycard-management-register-card-chooseEmployee-search-selector',
                },
              },
            }}
          />
        </CellFormControl>
        <Cell skip={[0, 0, 3]} />
        <CellFormControl
          cellSpan={[12, 4, 3]}
          cellAlign={ALIGNMENT.center}
          formControlProps={{
            overrides: {
              ControlContainer: {
                style: {
                  '@media screen and (min-width: 600px)': {
                    display: 'inline-flex',
                    justifyItems: 'end',
                    justifyContent: 'flex-end',
                  },
                },
              },
            },
          }}
        >
          <Button
            kind={KIND.primary}
            onClick={handleAddEmployeeClick}
            size={SIZE.compact}
          >
            <Block
              marginRight="8px"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Block>
            {t('paycardManagement:paycardManagement.registerCard.chooseEmployee.addEmployee')}
          </Button>
        </CellFormControl>
      </Grid>
      <PaycardManagementRegisterCardChooseEmployeeTable handlePageChange={handlePageChange} setFormValues={setFormValues} />
    </>
  );
};

export default memo(PaycardManagementRegisterCardChooseEmployee);
