/* eslint-disable react/jsx-no-useless-fragment */
import {
  memo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  ModalNames,
  modalsSelector, setModal,
} from 'store/slices/modals';
import {
  defaultConfigSelector,
  organizationConfigSelector,
  organizationSelector,
} from 'store/slices/organizations';
import {
  fetchLocation,
  locationPendingSelector,
  resetSelectedLocation,
  selectedLocationSelector,
  locationConfigSelector,
} from 'store/slices/locations';
import {
  OrganizationPreviewLocationModalPropsType,
} from 'types/LocationTypes';
import AppModal from 'components/AppModal/AppModal';
import Loader from 'components/Loader';
import { Block } from 'baseui/block';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { useStyletron } from 'styletron-react';
import { emptyPlaceholder } from 'theme';
import { FormControl } from 'baseui/form-control';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, KIND } from 'baseui/button';
import { ConfigType } from 'types/OrganizationTypes';
import moment from 'moment';
import removeUnderlinesFromText from 'utils/locationHelpers';

const titleStyles = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  'white-space': 'nowrap',
  width: 'calc(100% - 40px)',
  display: 'inline-block',
  marginRight: '8px',
};

const OrganizationPreviewLocationModal = ({
  locationID,
}: OrganizationPreviewLocationModalPropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'locations', 'organizations']);
  const dispatch = useAppDispatch();
  const modals = useAppSelector(modalsSelector);
  const organization = useAppSelector(organizationSelector);
  const locationConfig = useAppSelector(locationConfigSelector);
  const { id: organizationID } = organization || {};
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const defaultConfig = useAppSelector(defaultConfigSelector);
  const location = useAppSelector(selectedLocationSelector);

  const pending = useAppSelector(locationPendingSelector);
  const modalName = ModalNames.LOCATION_PREVIEW_MODAL;
  const isModalOpen = checkIsModalOpen(modals, modalName);
  const jobCodesExclusions = locationConfig?.find((i) => i.configKeyName === ConfigType.JOB_CODE_EXCLUSIONS)?.configValue;
  const organizationOfferPercent = Number(organizationConfig?.find((item) => item.configKeyName === ConfigType.OFFER_PERCENT)?.configValue)
    ?? defaultConfig?.OFFER_PERCENT;
  const hasTPOConfigChanges = locationConfig?.find((i) => i.configKeyName === ConfigType.TPO_ENABLED);
  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetSelectedLocation());
  };

  const handleClickEdit = () => {
    dispatch(setModal({
      name: ModalNames.LOCATION_FORM_MODAL,
      isOpen: true,
    }));
    dispatch(setModal({
      name: ModalNames.LOCATION_PREVIEW_MODAL,
      isOpen: false,
    }));
  };

  useEffect(() => {
    dispatch(resetSelectedLocation());
  }, []);

  useEffect(() => {
    isModalOpen && locationID && dispatch(fetchLocation({ organizationID, locationID }));
  }, [locationID, isModalOpen]);

  return (
    <AppModal
      modal={modalName}
      title={(
        <Block
          marginTop="-4px"
          display="flex"
          alignItems="center"
        >
          <div className={css(titleStyles)}>
            {t('locations:location.details.title')}
          </div>
          <Block
            marginRight="16px"
            display="flex"
          >
            <Button
              onClick={handleClickEdit}
              size="compact"
              kind={KIND.tertiary}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Block>
        </Block>
      )}
      onClose={handleModalClose}
    >
      <Loader active={pending} />

      <Block
        marginTop="12px"
      >
        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:fuegoId.label')}
            >
              <>
                {location?.id || emptyPlaceholder}
              </>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:location.details.status.label')}
            >
              <>
                {(location?.status && t(`locations:statuses.${location?.status}`)) || emptyPlaceholder}
              </>
            </FormControl>
          </Cell>
        </Grid>

        <Block
          marginTop="12px"
        >
          <Grid
            gridColumns={12}
            gridMargins={16}
          >
            <Cell
              span={12}
            >
              <strong>{t('locations:basicSettings.title')}</strong>
            </Cell>
          </Grid>
        </Block>

        <hr />

        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:location.details.name.label')}
            >
              <div className="ellipsis" title={location?.name || emptyPlaceholder}>
                {location?.name || emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:location.details.payGroup.label')}
            >
              <div className="ellipsis" title={location?.payGroup?.name || emptyPlaceholder}>
                {location?.payGroup?.name || emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:location.details.businessDayStartTime.label')}
            >
              <div className="ellipsis" title={location?.businessDayStartTime || emptyPlaceholder}>
                {location?.businessDayStartTime ? moment(location?.businessDayStartTime, 'hh:mm').format('hh:mm A') : emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:location.details.timezone.label')}
            >
              <div className="ellipsis" title={location?.timezone || emptyPlaceholder}>
                {removeUnderlinesFromText(location?.timezone || emptyPlaceholder)}
              </div>
            </FormControl>
          </Cell>
        </Grid>

        <Block
          marginTop="12px"
        >
          <Grid
            gridColumns={12}
            gridMargins={16}
          >
            <Cell
              span={12}
            >
              <strong>{t('locations:integrationSettings.title')}</strong>
            </Cell>
          </Grid>
        </Block>

        <hr />

        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:location.details.externalLocationId.label')}
            >
              <div className="ellipsis" title={location?.externalLocationId || emptyPlaceholder}>
                {location?.externalLocationId || emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>
          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:externalIntID.label')}
            >
              <div className="ellipsis" title={location?.externalIntegrationId || emptyPlaceholder}>
                {location?.externalIntegrationId || emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>
          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:location.details.tipsProviderId.label')}
            >
              <div className="ellipsis" title={location?.tipsProviderId || emptyPlaceholder}>
                {location?.tipsProviderId || emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>
          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:location.details.posId.label')}
            >
              <div className="ellipsis" title={location?.posId || emptyPlaceholder}>
                {location?.posId || emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:location.details.storeNumber.label')}
            >
              <div className="ellipsis" title={location?.storeNumber || emptyPlaceholder}>
                {location?.storeNumber || emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:location.details.alphaNumericStoreNumber.label')}
            >
              <div className="ellipsis" title={location?.alphaNumericStoreNumber || emptyPlaceholder}>
                {location?.alphaNumericStoreNumber || emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:location.details.conceptID.label')}
            >
              <div className="ellipsis" title={location?.conceptId || emptyPlaceholder}>
                {location?.conceptId || emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>
        </Grid>

        <Block
          marginTop="12px"
        >
          <Grid
            gridColumns={12}
            gridMargins={16}
          >
            <Cell
              span={12}
            >
              <strong>{t('organizations:EWASettings')}</strong>
            </Cell>
          </Grid>
        </Block>

        <hr />

        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <Cell span={[12, 6]}>
            <FormControl
              label={t('organizations:program.enabled')}
            >
              <>
                {`${locationConfig?.find((i) => i.configKeyName === ConfigType.EWA_ENABLED)?.configValue === 'true' ? 'yes' : 'no'}`.toUpperCase()}
              </>
            </FormControl>
          </Cell>
        </Grid>

        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <Cell
            span={12}
            align={ALIGNMENT.start}
          >
            <h3>{t('organizations:offer')}</h3>
          </Cell>
        </Grid>

        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <Cell span={[12, 6]}>
            <FormControl
              label={t('locations:location.details.offerPercent.label')}
            >
              <>
                {organizationOfferPercent ?? emptyPlaceholder}
              </>
            </FormControl>
          </Cell>
        </Grid>

        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <Cell span={[12, 6]}>
            <Block
              marginLeft="-20px"
            >
              <Cell
                span={12}
                align={ALIGNMENT.start}
              >
                <h3>{t('organizations:shifts')}</h3>
              </Cell>
            </Block>

            <FormControl
              label={t('common:configs.INCLUDE_TIPPED_SHIFTS')}
            >
              <>
                {`${locationConfig?.find((i) => i.configKeyName === ConfigType.INCLUDE_TIPPED_SHIFTS)?.configValue === 'true' ? 'no' : 'yes'}`.toUpperCase()}
              </>
            </FormControl>

            <FormControl
              label={t('common:configs.JOB_CODE_EXCLUSIONS')}
            >
              <div
                className={css({
                  'overflow-wrap': 'break-word',
                })}
                title={jobCodesExclusions?.toString() || emptyPlaceholder}
              >
                {jobCodesExclusions ? jobCodesExclusions.toString().replace(/,/g, ', ') : emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <Block
              marginLeft="-24px"
            >
              <Cell
                span={[12, 6]}
                align={ALIGNMENT.start}
              >
                <h3>{t('organizations:tips')}</h3>
              </Cell>
            </Block>

            <FormControl
              label={t('organizations:labelInclude3rdPartyTips')}
            >
              <>
                {`${locationConfig?.find((i) => i.configKeyName === ConfigType.INCLUDE_3RD_PARTY_TIPS)
                  ?.configValue === 'true'
                  ? 'yes' : 'no'}`.toUpperCase()}
              </>
            </FormControl>

            <FormControl
              label={t('organizations:labelIncludeCCTips')}
            >
              <>
                {`${locationConfig?.find((i) => i.configKeyName === ConfigType.INCLUDE_CC_TIPS)
                  ?.configValue === 'true'
                  ? 'yes' : 'no'}`.toUpperCase()}
              </>
            </FormControl>

            <FormControl
              label={t('organizations:labelIncludeServiceCharges')}
            >
              <>
                {`${locationConfig?.find((i) => i.configKeyName === ConfigType.INCLUDE_SERVICE_CHARGES)
                  ?.configValue === 'true'
                  ? 'yes' : 'no'}`.toUpperCase()}
              </>
            </FormControl>

            <FormControl
              label={t('organizations:labelIncludeDeclaredTips')}
            >
              <>
                {`${locationConfig?.find((i) => i.configKeyName === ConfigType.INCLUDE_DECLARED_TIPS)
                  ?.configValue === 'true'
                  ? 'yes' : 'no'}`.toUpperCase()}
              </>
            </FormControl>

            <FormControl
              label={t('organizations:labelIncludeCashTips')}
            >
              <>
                {`${locationConfig?.find((i) => i.configKeyName === ConfigType.INCLUDE_CASH_TIPS)
                  ?.configValue === 'true'
                  ? 'yes' : 'no'}`.toUpperCase()}
              </>
            </FormControl>

            <FormControl
              label={t('organizations:labelIncludeTipsPool')}
            >
              <>
                {`${locationConfig?.find((i) => i.configKeyName === ConfigType.INCLUDE_TIPS_POOL)
                  ?.configValue === 'true'
                  ? 'yes' : 'no'}`.toUpperCase()}
              </>
            </FormControl>
          </Cell>

        </Grid>
        <>
          <Block
            marginTop="12px"
          >
            <Grid
              gridColumns={12}
              gridMargins={16}
            >
              <Cell
                span={12}
              >
                <strong>{t('organizations:TPOSettings')}</strong>
              </Cell>
            </Grid>
          </Block>

          <hr />
          <Grid
            gridColumns={12}
            gridMargins={16}
          >
            <Cell span={[12, 6]}>
              <FormControl
                label={t('organizations:program.enabled')}
              >
                <>
                  {`${locationConfig?.find((i) => i.configKeyName === ConfigType.TPO_ENABLED)?.configValue === 'true' ? 'yes' : 'no'}`.toUpperCase()}
                </>
              </FormControl>
            </Cell>
          </Grid>
          <Grid
            gridColumns={12}
            gridMargins={16}
          >
            <Cell span={[12, 6]}>
              <Block
                marginLeft="-20px"
              >
                <Cell
                  span={[12, 6]}
                  align={ALIGNMENT.start}
                >
                  <h3>{t('organizations:tpo.tips')}</h3>
                </Cell>
              </Block>

              <FormControl
                label={t('organizations:labelInclude3rdPartyTips')}
              >
                <>
                  {hasTPOConfigChanges
                    ? `${locationConfig?.find((i) => i.configKeyName === ConfigType.TPO_INCLUDE_3RD_PARTY_TIPS)
                      ?.configValue === 'true' ? 'yes' : 'no'}`.toUpperCase()
                    : 'YES'}
                </>
              </FormControl>

              <FormControl
                label={t('organizations:labelIncludeServiceCharges')}
              >
                <>
                  {hasTPOConfigChanges
                    ? `${locationConfig?.find((i) => i.configKeyName === ConfigType.TPO_INCLUDE_SERVICE_CHARGES)
                      ?.configValue === 'true'
                      ? 'yes' : 'no'}`.toUpperCase()
                    : 'YES'}
                </>
              </FormControl>
              <FormControl
                label={t('organizations:labelIncludeCCTips')}
              >
                <>
                  {hasTPOConfigChanges
                    ? `${locationConfig?.find((i) => i.configKeyName === ConfigType.TPO_INCLUDE_CC_TIPS)
                      ?.configValue === 'true' ? 'yes' : 'no'}`.toUpperCase()
                    : 'YES'}
                </>
              </FormControl>

              <FormControl
                label={t('organizations:labelIncludeDeclaredTips')}
              >
                <>
                  {hasTPOConfigChanges
                    ? `${locationConfig?.find((i) => i.configKeyName === ConfigType.TPO_INCLUDE_DECLARED_TIPS)
                      ?.configValue === 'true' ? 'yes' : 'no'}`.toUpperCase()
                    : 'YES'}
                </>
              </FormControl>

              <FormControl
                label={t('organizations:labelIncludeCashTips')}
              >
                <>
                  {hasTPOConfigChanges
                    ? `${locationConfig?.find((i) => i.configKeyName === ConfigType.TPO_INCLUDE_CASH_TIPS)
                      ?.configValue === 'true' ? 'yes' : 'no'}`.toUpperCase()
                    : 'YES'}
                </>
              </FormControl>

              <FormControl
                label={t('organizations:labelIncludeTipsPool')}
              >
                <>
                  {hasTPOConfigChanges
                    ? `${locationConfig?.find((i) => i.configKeyName === ConfigType.TPO_INCLUDE_TIPS_POOL)
                      ?.configValue === 'true' ? 'yes' : 'no'}`.toUpperCase()
                    : 'YES'}
                </>
              </FormControl>
            </Cell>
          </Grid>
        </>
      </Block>
    </AppModal>
  );
};

export default memo(OrganizationPreviewLocationModal);
