import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, KIND } from 'baseui/button';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStyletron } from 'styletron-react';
import { Block } from 'baseui/block';
import { useAppDispatch } from 'store/hooks';
import {
  OrganizationListItemPropsType,
  OrganizationSectionNameType,
  OrganizationSectionProgressStatusType,
} from 'types/OrganizationTypes';
import { setLoggedOrganization } from 'store/slices/loggedOrganization';
import {
  NotificationType,
  setNotification,
} from 'store/slices/notification';
import AppChip from 'components/AppChip/AppChip';
import findSectionStatusByName from 'utils/findSectionStatusByName';
import hasAccess from 'utils/hasAccess';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import {
  list,
  listItem,
  listItemNameAndStatuses,
  listItemInfoStyles,
  details,
} from './OrganizationListItemHelpers';

const replaceSpacesWithDashes = (str: string) => (typeof str === 'string' ? str.replace(/\s+/g, '-') : str);

const OrganizationListItem = ({
  organization: { name, id, sectionStatuses = [] },
}: OrganizationListItemPropsType) => {
  const { t } = useTranslation(['organizations']);
  const [css] = useStyletron();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const profileSectionStatus = findSectionStatusByName(OrganizationSectionNameType.PROFILE, sectionStatuses);
  const payGroupsSectionStatus = findSectionStatusByName(OrganizationSectionNameType.PAY_GROUP, sectionStatuses);
  const locationsSectionStatus = findSectionStatusByName(OrganizationSectionNameType.PAY_PERIODS, sectionStatuses);
  const EWASettingsSectionStatus = findSectionStatusByName(OrganizationSectionNameType.ORG_DEFAULT_SETTINGS, sectionStatuses);
  const TPOSettingsSectionStatus = findSectionStatusByName(OrganizationSectionNameType.TIP_CASH_OUT, sectionStatuses);
  const DirectDepositSectionStatus = findSectionStatusByName(OrganizationSectionNameType.DIRECT_DEPOSIT, sectionStatuses);
  const bankDetailsSectionStatus = findSectionStatusByName(OrganizationSectionNameType.BANK_DETAILS, sectionStatuses);
  const orgNameButtonOverrides = {
    BaseButton: {
      props: {
        id: `OrganizationListItem-organization-${id}-title`,
      },
      style: {
        maxWidth: 'calc(100% - 48px)', // 48px is the width of the stateful popup button
      },
    },
  };

  const handleMenuItemClick = () => {
    dispatch(setLoggedOrganization({
      name,
      id,
    }));
    dispatch(setNotification({
      type: NotificationType.SUCCESS,
      isOpen: true,
      title: t('organizations:notifications.successLoggedToOrganization', { organizationName: name }),
      autoHideDuration: 3000,
    }));
  };

  const handleOrganizationClick = () => {
    id && history.push(`/organizations/${id}`);
  };

  return (
    <ul className={css(list)}>
      <li className={css(listItem)}>
        <div className={css(listItemNameAndStatuses)}>
          <Button
            kind={KIND.tertiary}
            overrides={orgNameButtonOverrides}
            onClick={handleOrganizationClick}
            disabled={!hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager])}
          >
            <span className="ellipsis">{name}</span>
          </Button>

          <Block padding="14px 16px">
            <FontAwesomeIcon
              title={t('organizations:loginToOrganization')}
              size="lg"
              className={css({ cursor: 'pointer', color: 'rgb(187, 0, 47)' })}
              icon={faRightToBracket}
              onClick={handleMenuItemClick}
            />
          </Block>
        </div>

        <div className={css(listItemInfoStyles)}>
          <div>
            <span
              id={`${replaceSpacesWithDashes(name)}-organization-id-label`}
              className={css(details)}
            >
              {t('organizations:organizationId')}
              :
              <AppChip
                id={`${replaceSpacesWithDashes(name)}-organization-id-status`}
                items={id}
              />
            </span>

            <span
              id={`${replaceSpacesWithDashes(name)}-profile-label`}
              className={css(details)}
            >
              {t('organizations:profile')}
              :
              <AppChip
                id={`${replaceSpacesWithDashes(name)}-profile-status`}
                items={t(`organizations:sectionStatuses.${profileSectionStatus.name
                  || OrganizationSectionProgressStatusType.IN_PROGRESS}`)?.toUpperCase()}
              />
            </span>

            <span
              id={`${replaceSpacesWithDashes(name)}-pay-groups-label`}
              className={css(details)}
            >
              {t('organizations:payGroups')}
              :
              <AppChip
                id={`${name.replace(' ', '-')}-pay-groups-status`}
                items={t(`organizations:sectionStatuses.${payGroupsSectionStatus.name
                  || OrganizationSectionProgressStatusType.IN_PROGRESS}`)?.toUpperCase()}
              />
            </span>

            <span
              id={`${replaceSpacesWithDashes(name)}-locations-label`}
              className={css(details)}
            >
              {t('organizations:locations')}
              :
              <AppChip
                id={`${replaceSpacesWithDashes(name)}-locations-status`}
                items={t(`organizations:sectionStatuses.${locationsSectionStatus.name
                  || OrganizationSectionProgressStatusType.IN_PROGRESS}`)?.toUpperCase()}
              />
            </span>

            <span
              id={`${replaceSpacesWithDashes(name)}-ewa-settings-label`}
              className={css(details)}
            >
              {t('organizations:EWASettings')}
              :
              <AppChip
                id={`${replaceSpacesWithDashes(name)}-ewa-settings-status`}
                items={t(`organizations:sectionStatuses.${EWASettingsSectionStatus.name
                  || OrganizationSectionProgressStatusType.IN_PROGRESS}`)?.toUpperCase()}
              />
            </span>

            <span
              id={`${replaceSpacesWithDashes(name)}-tpo-settings-label`}
              className={css(details)}
            >
              {t('organizations:TPOSettings')}
              :
              <AppChip
                id={`${replaceSpacesWithDashes(name)}-tpo-settings-status`}
                items={t(`organizations:sectionStatuses.${TPOSettingsSectionStatus.name
                  || OrganizationSectionProgressStatusType.IN_PROGRESS}`)?.toUpperCase()}
              />
            </span>

            <span
              id={`${replaceSpacesWithDashes(name)}-direct-deposit-settings-label`}
              className={css(details)}
            >
              {t('organizations:DirectDepositSettings')}
              :
              <AppChip
                id={`${replaceSpacesWithDashes(name)}-direct-deposit-settings-status`}
                items={t(`organizations:sectionStatuses.${DirectDepositSectionStatus.name
                  || OrganizationSectionProgressStatusType.IN_PROGRESS}`)?.toUpperCase()}
              />
            </span>
            <span
              id={`${replaceSpacesWithDashes(name)}-bank-details-label`}
              className={css(details)}
            >
              {t('organizations:bankDetails.header')}
              :
              <AppChip
                id={`${replaceSpacesWithDashes(name)}-bank-details-status`}
                items={t(`organizations:sectionStatuses.${bankDetailsSectionStatus.name
                  || OrganizationSectionProgressStatusType.IN_PROGRESS}`)?.toUpperCase()}
              />
            </span>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default OrganizationListItem;
