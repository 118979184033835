import { memo } from 'react';
import { useStyletron } from 'styletron-react';
import { useTranslation } from 'react-i18next';
import SectionsTabulation from 'components/SectionsTabulation';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { Block } from 'baseui/block';
import Access, { AccessUnit } from 'components/Access/Access';
import { Button, KIND } from 'baseui/button';
import { useHistory, useParams } from 'react-router-dom';
import { ModalNames, setModal } from 'store/slices/modals';
import { batchesPendingListSelector } from 'store/slices/batches';
import { Grid } from 'baseui/layout-grid';
import EWABatchesSection from './BatchAdministration/EWABatchesSection/EWABatchesSection';
import BatchRepaymentSection from './BatchAdministration/BatchRepaymentSection/BatchRepaymentSection';
import TPOBatchesSection from './BatchAdministration/TPOBatchesSection/TPOBatchesSection';

const containerStyles = {
  maxWidth: '100%',
  overflow: 'hidden',
};

const buttonOverrides = {
  Root: {
    style: {
      '@media screen and (min-width: 880px)': {
        minWidth: '200px',
      },
    },
  },
};

const BatchManagement = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'employees', 'batches', 'repayments']);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const pendingEWABatches = useAppSelector(batchesPendingListSelector);

  const handleCreateNewRepaymentClick = () => {
    dispatch(setModal({
      name: ModalNames.REPAYMENT_FORM_MODAL,
      isOpen: true,
    }));
  };

  const handleCreateNewBatchClick = () => {
    history.push('/batches/create');
  };

  const subMenuHeadings = [t('batches:ewaBatches.section'), t('batches:tpoBatches.section'), t('batches:repaymentRecords.section')];

  const { sectionIndex } = useParams<{sectionIndex: string}>();
  const selectedSection = Number(sectionIndex);

  const handleShownSection = (i: number) => {
    history.push(`/batches/section/${i}`);
  };

  return (
    <div className={css(containerStyles)}>
      <CommonHeader title={t('batches:batchManagement')}>
        <Block
          alignItems="center"
          display="inline-flex"
          justifyContent="flex-end"
          width="50%"
          minWidth="160px"
          height="72px"
        >
          {selectedSection === 0 && (
          <Button
            overrides={{
              Root: {
                ...buttonOverrides.Root,
                props: {
                  id: 'batches-record-new-batch',
                },
              },
            }}
            kind={KIND.primary}
            onClick={handleCreateNewBatchClick}
            disabled={pendingEWABatches}
          >
            {t('batches:createNewBatch')}
          </Button>
          )}
          {selectedSection === 2 && (
          <Access
            not
            oneOf={[AccessUnit.EWAClientManager]}
          >
            <Button
              kind={KIND.primary}
              onClick={handleCreateNewRepaymentClick}
              overrides={{
                Root: {
                  ...buttonOverrides.Root,
                  props: {
                    id: 'batches-record-new-payment',
                  },
                },
              }}
            >
              {t('repayments:recordNewPayment')}
            </Button>
          </Access>
          )}

        </Block>
      </CommonHeader>
      <Grid>
        <SectionsTabulation
          subMenuHeadings={subMenuHeadings}
          buttonDisabledRole={false}
          sectionName="batch"
          activeSection={selectedSection}
          setShownSection={handleShownSection}
        />
      </Grid>
      <Block marginTop="24px" />
      {selectedSection === 0 && <EWABatchesSection />}
      {selectedSection === 1 && <TPOBatchesSection />}
      {selectedSection === 2 && <BatchRepaymentSection />}
    </div>

  );
};

export default memo(BatchManagement);
