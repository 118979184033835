import {
  ChangeEvent,
  memo,
  useEffect,
  useState,
} from 'react';
import { StyleObject, useStyletron } from 'styletron-react';
import { useTranslation } from 'react-i18next';
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import Loader from 'components/Loader';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  employmentWorkerLinkingPendingListSelector,
  fetchWorkerEmploymentLinks,
  employmentWorkerLinkingPageNumberSelector,
  employmentWorkerLinkingTotalSizeSelector,
  employmentWorkerLinkingListSelector,
  employmentWorkerLinkingNumPagesSelector,
} from 'store/slices/employees';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { Pagination, SIZE } from 'baseui/pagination';
import {
  paginationTransparentOverrides, blockOverrides,
  emptyTabButtonStyles, activeTabContainerStyles, activeTabButtonStyles, tabButtonStyles,
} from 'screens/CommonHelpers';
import { WorkerEmployments, WorkerEmploymentStatusTypes } from 'types/WorkerTypes';
import { Card } from 'baseui/card';
import { Button, KIND } from 'baseui/button';
import { Input } from 'baseui/input';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import WorkerLinkingListItem from './WorkerLinkingListItem';
import WorkerLinkingModal from './WorkerLinkingModal/WorkerLinkingModal';

const tabsButtonStyles = (id: string) => ({
  BaseButton: {
    props: {
      id: `WorkerLinking-tabs-${id}`,
    },
    style: {
      color: '#111111',
      'font-size': '15px',
      'font-weight': '700',
      'white-space': 'nowrap',
    },
  },
});

const containerStyles = {
  width: '100%',
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 100,
} as StyleObject;

const WorkerLinking = () => {
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [selectedWorker, setSelectedWorker] = useState<WorkerEmployments | undefined>(undefined);
  const organizationID = useAppSelector(loggedOrganizationSelector)?.id;
  const list = useAppSelector(employmentWorkerLinkingListSelector);
  const pendingList = useAppSelector(employmentWorkerLinkingPendingListSelector);
  const { t } = useTranslation(['common', 'employees']);
  const [css] = useStyletron();
  const modals = useAppSelector(modalsSelector);
  const modalName = ModalNames.LINK_TO_EMPLOYER_MODAL;
  const modal = modals.find((item) => item.name === modalName);

  const numPages = useAppSelector(employmentWorkerLinkingNumPagesSelector);
  const pageNumber = useAppSelector(employmentWorkerLinkingPageNumberSelector);
  const totalSize = useAppSelector(employmentWorkerLinkingTotalSizeSelector);

  const [selectedTab, setSelectedTab] = useState(WorkerEmploymentStatusTypes.PENDING);
  const tabs = [
    WorkerEmploymentStatusTypes.PENDING,
    WorkerEmploymentStatusTypes.AUTO_LINKED,
  ];

  const fetchByStatus = (loadedStatus: WorkerEmploymentStatusTypes, pageNum: string) => {
    dispatch(fetchWorkerEmploymentLinks({
      organizationId: String(organizationID),
      pageNumber: pageNum,
      status: loadedStatus,
    }));
  };

  const handleStatusTabClick = (tab: WorkerEmploymentStatusTypes) => {
    setSelectedTab(tab);
  };

  const handleLinkToEmployerClicked = (worker: WorkerEmployments) => {
    setSelectedWorker(worker);
    dispatch(setModal({
      name: modalName,
      isOpen: true,
    }));
  };

  const refreshOnSave = () => {
    setSelectedWorker(undefined);
    fetchByStatus(WorkerEmploymentStatusTypes.PENDING, '1');
  };

  const handleSearch = () => {
    let payload = {
      organizationId: String(organizationID),
      pageNumber: '1',
      status: selectedTab,
    } as any;
    if (firstName) payload = { ...payload, firstName };
    if (lastName) payload = { ...payload, lastName };
    dispatch(fetchWorkerEmploymentLinks(payload));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();

    fetchByStatus(selectedTab, page);
  };

  const handleFirstNameChange = (e: ChangeEvent<InputCustomHTMLElement>) => {
    setFirstName(e.target.value);
    if (e.target.value === '' && !lastName) fetchByStatus(selectedTab, '1');
  };

  const handleLastNameChange = (e: ChangeEvent<InputCustomHTMLElement>) => {
    setLastName(e.target.value);
    if (e.target.value === '' && !firstName) fetchByStatus(selectedTab, '1');
  };

  const renderTabs = () => tabs.map((tab) => (
    <div
      key={tab}
      className={tab === selectedTab
        ? css(activeTabButtonStyles)
        : css(tabButtonStyles)}
    >
      <Button
        kind={KIND.tertiary}
        overrides={tabsButtonStyles(tab)}
        onClick={() => handleStatusTabClick(tab)}
      >
        {t(`employees:workerLinkingStatuses.${tab}`)}
      </Button>
    </div>
  ));

  useEffect(() => {
    setLastName('');
    setFirstName('');
    dispatch(fetchWorkerEmploymentLinks({
      organizationId: String(organizationID),
      pageNumber: '1',
      status: selectedTab,
    }));
  }, [selectedTab]);

  return (
    <div className={css(containerStyles)}>
      <CommonHeader title={t('employees:workerLinkingHeader')} />
      <Block>
        <Grid
          align={ALIGNMENT.center}
        >
          <Cell span={12} />
          <div className={css(activeTabContainerStyles)}>
            {renderTabs()}
            <div className={css(emptyTabButtonStyles)} />
          </div>
        </Grid>
      </Block>
      <Loader active={pendingList} />
      <Block overrides={blockOverrides}>
        <Grid
          align={ALIGNMENT.center}
        >
          <Cell span={12}>
            <Block
              marginTop="24px"
            >
              <b>
                {t('common:searchBy')}
              </b>
            </Block>
            <Block marginTop="16px">
              <Grid
                align={ALIGNMENT.center}
                gridMargins={0}
                gridGaps={10}
              >
                <Cell span={4}>
                  <Input
                    clearable
                    clearOnEscape
                    type="text"
                    name="firstName"
                    onChange={handleFirstNameChange}
                    value={firstName}
                    placeholder={t('employees:firstName')}
                    overrides={{
                      Input: {
                        props: {
                          id: 'WorkerLinking-firstName-input',
                          autoComplete: 'off',
                        },
                      },
                    }}
                  />
                </Cell>
                <Cell span={4}>
                  <Input
                    clearable
                    clearOnEscape
                    type="text"
                    name="lastName"
                    onChange={handleLastNameChange}
                    value={lastName}
                    placeholder={t('employees:lastName')}
                    overrides={{
                      Input: {
                        props: {
                          id: 'WorkerLinking-lastName-input',
                          autoComplete: 'off',
                        },
                      },
                    }}
                  />
                </Cell>
                <Cell span={4} align={ALIGNMENT.end}>
                  <div className={css({ display: 'flex' })}>
                    <Button
                      kind={KIND.primary}
                      onClick={handleSearch}
                      overrides={{
                        Root: {
                          style: {
                            marginLeft: 'auto',
                          },
                        },
                      }}
                    >
                      {t('common:searchButton')}
                    </Button>
                  </div>
                </Cell>
              </Grid>
            </Block>
          </Cell>
          <Cell
            span={12}
          >
            {list?.length > 0 && (
              <>
                {list
                  ?.map((worker) => <WorkerLinkingListItem key={worker.id} worker={worker} handleLinkToEmployerClick={handleLinkToEmployerClicked} />)}

                <Block
                  display="flex"
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                  justifyItems="center"
                  marginBottom="16px"
                >
                  <Pagination
                    size={SIZE.compact}
                    numPages={numPages}
                    currentPage={pageNumber}
                    overrides={paginationTransparentOverrides}
                    onPageChange={handlePageChange}
                  />
                </Block>
              </>
            )}

            {list?.length === 0 && (
              <Block
                marginTop="24px"
                marginBottom="24px"
              >
                <Card>
                  <p>{t('employees:notFound')}</p>
                </Card>
              </Block>
            )}

          </Cell>
        </Grid>
      </Block>
      {modal?.isOpen && <WorkerLinkingModal worker={selectedWorker} refreshOnSave={refreshOnSave} />}
    </div>
  );
};

export default memo(WorkerLinking);
