import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, HIERARCHY, KIND } from 'baseui/banner';
import { ParagraphSmall } from 'baseui/typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { payPeriodBannerStyles } from '../../../DashboardHelper';

const PayPeriodBanner = () => {
  const [isClosed, setIsClosed] = useState(false);
  const { t } = useTranslation(['dashboard']);

  return !isClosed ? (
    <Banner
      action={{
        label: t('dashboard:dashboard.banner.close'),
        icon: () => (
          <FontAwesomeIcon icon={faXmark} size="lg" />
        ),
        onClick: () => { setIsClosed(true); },
      }}
      overrides={payPeriodBannerStyles}
      kind={KIND.warning}
      hierarchy={HIERARCHY.low}
    >
      <ParagraphSmall
        margin={0}
        overrides={{
          Block: {
            style: {
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            },
          },
        }}
      >
        {t('dashboard:dashboard.payPeriodContent.partiallySettledBanner.header')}
        <br />
        <br />
        {t('dashboard:dashboard.payPeriodContent.partiallySettledBanner.body')}
        <br />
        <br />
        {t('dashboard:dashboard.payPeriodContent.partiallySettledBanner.footer')}
      </ParagraphSmall>
    </Banner>
  ) : null;
};

export default PayPeriodBanner;
