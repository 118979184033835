import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { editOrganization, Organization } from './organizations';

export const initialState = {
  organization: null as Organization | null,
};

const loggedOrganizationSlice = createSlice({
  name: 'loggedOrganization',
  initialState,
  reducers: {
    setLoggedOrganization: (state, { payload }): void => {
      state.organization = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editOrganization.fulfilled, (state, { payload }) => {
      const [organization] = payload || [];

      if (organization?.id?.toString() === state.organization?.id?.toString() && state.organization?.name) {
        state.organization.name = organization?.name;
      }
    });
  },
});

export const { setLoggedOrganization } = loggedOrganizationSlice.actions;
export default loggedOrganizationSlice.reducer;

// Selectors
export const loggedOrganizationSelector = (state: RootState): Organization | null => state.loggedOrganization.organization;
