import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'baseui';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useParams } from 'react-router-dom';
import {
  HeadingXSmall,
  ParagraphSmall,
} from 'baseui/typography';
import Loader from 'components/Loader';
import { StyleObject } from 'styletron-react';
import {
  deleteSourceAccount,
  fetchRepaymentsSourceAccounts,
  repaymentsOrganizationSourceAccountsIsAccountDeletedSelector,
  repaymentsOrganizationSourceAccountsListSelector,
  repaymentsOrganizationSourceAccountsPendingSelector,
  resetSourceAccountsCreationStatus,
} from 'store/slices/repaymentsBankDetails';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { colors } from 'theme';
import { Block } from 'baseui/block';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  KIND,
} from 'baseui/button';
import { SourceAccountType } from 'types/RepaymentsBankDetails';
import AppStyledTable from 'components/AppStyledTable/AppStyledTable';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import { maskBankAccountNumber } from 'dataMappers/organizationsDataMappers';
import OrganizationFormBankAccountModal from './OrganizationFormBankAccountModal/OrganizationFormBankAccountModal';
import OrganizationFormOrganizationMapping from './OrganizationFormOranizationMapping/OrganizationFormOrganizationMapping';

const containerStyles = {
  position: 'relative',
  paddingLeft: '36px',
  paddingRight: '36px',
  minHeight: '240px',
  overflow: 'hidden',
  zIndex: 10,
  whiteSpace: 'normal',
  wordWrap: 'break-word',
} as StyleObject;

const blockOverrides = {
  style: {
    display: 'flex',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
};

const OrganizationFormBankDetailsSection = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['organizations', 'errors', 'common']);
  const pending = useAppSelector(repaymentsOrganizationSourceAccountsPendingSelector);
  const sourceAccounts = useAppSelector(repaymentsOrganizationSourceAccountsListSelector);
  const { organizationID } = useParams<{ organizationID: string }>();
  const modals = useAppSelector(modalsSelector);
  const [selectedBankAccount, setSelectedBankAccount] = useState<SourceAccountType | null>(null);
  const isAccountDeleted = useAppSelector(repaymentsOrganizationSourceAccountsIsAccountDeletedSelector);
  const dispatch = useAppDispatch();

  const isConfirmModalOpen = !!modals?.find((item) => item.name === ModalNames.CONFIRM_MODAL_ORGANIZATION_DELETE_BANK_ACCOUNT)?.isOpen;

  const setIsConfirmModalOpen = (
    isOpen: boolean,
  ) => {
    isConfirmModalOpen !== isOpen && dispatch(setModal({
      name: ModalNames.CONFIRM_MODAL_ORGANIZATION_DELETE_BANK_ACCOUNT,
      isOpen,
    }));
  };

  useEffect(() => {
    if (isAccountDeleted) {
      setIsConfirmModalOpen(false);
      dispatch(fetchRepaymentsSourceAccounts({ organizationID }));
    }
  }, [isAccountDeleted]);

  const handleAddBankAccountClick = () => {
    dispatch(setModal({
      name: ModalNames.BANK_ACCOUNT_MODAL,
      isOpen: true,
    }));
    dispatch(resetSourceAccountsCreationStatus());
  };

  const handleBankAccountDeleteConfirm = () => {
    dispatch(deleteSourceAccount({
      accountID: selectedBankAccount?.accountId.toString() || '',
      organizationID,
    }));
  };

  const handleBankAccountDeleteClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleBankAccountDeleteButtonClick = (account: SourceAccountType) => {
    setIsConfirmModalOpen(true);
    setSelectedBankAccount(account);
  };

  const handleBankAccountEditButtonClick = (account: SourceAccountType) => {
    setSelectedBankAccount(account);
    dispatch(setModal({
      name: ModalNames.BANK_ACCOUNT_EDIT_MODAL,
      isOpen: true,
    }));
    dispatch(resetSourceAccountsCreationStatus());
  };

  const rows = sourceAccounts.length > 0 ? (
    [...sourceAccounts]?.map((account: SourceAccountType) => ({
      id: account.accountId.toString(),
      cells: [
        <Block
          paddingLeft="16px"
          overrides={{ Block: blockOverrides }}
        >
          {account?.accountId}
        </Block>,
        <Block overrides={{ Block: blockOverrides }}>
          {maskBankAccountNumber(account?.accountNumber)}
        </Block>,
        <Block overrides={{ Block: blockOverrides }}>
          {account?.routingNumber}
        </Block>,
        <Block overrides={{ Block: blockOverrides }}>
          {account?.legalName}
        </Block>,
        <Block overrides={{ Block: blockOverrides }}>
          {account?.nickname}
        </Block>,
        <Block overrides={{ Block: blockOverrides }}>
          {account?.repaymentStatement}
        </Block>,
        <Block overrides={{ Block: blockOverrides }}>
          <Button
            kind={KIND.tertiary}
            overrides={{
              Root: {
                props: {
                  id: 'OrganizationFormBankDetailsSection-delete-btn',
                },
                style: {
                  color: colors.primary,
                  backgroundColor: 'inherit',
                  ':hover': {
                    backgroundColor: 'inherit',
                  },
                  padding: '8px',
                },
              },
            }}
            onClick={() => handleBankAccountDeleteButtonClick(account)}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
            />
          </Button>
          <Button
            kind={KIND.tertiary}
            overrides={{
              Root: {
                props: {
                  id: 'OrganizationFormBankDetailsSection-edit-btn',
                },
                style: {
                  color: colors.primary,
                  backgroundColor: 'inherit',
                  ':hover': {
                    backgroundColor: 'inherit',
                  },
                  padding: '8px',
                },
              },
            }}
            onClick={() => handleBankAccountEditButtonClick(account)}
          >
            <FontAwesomeIcon
              icon={faPen}
            />
          </Button>
        </Block>,
      ],
    }))) : ([{
    id: 'no-bankAccounts-available-message',
    cells: [
      <ParagraphSmall
        overrides={{
          Block: {
            style: {
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              minWidth: '180px',
            },
          },
        }}
      >
        {t('organizations:bankDetails.sourceAccounts.table.noResults')}
      </ParagraphSmall>, '', '', '', '', '', ''],
  }]);

  return (
    <div className={css(containerStyles)}>
      <Loader active={pending} />
      <Block>
        <OrganizationFormOrganizationMapping />
      </Block>
      <Block>
        <Loader active={pending} />

        <Grid
          gridColumns={12}
          gridGutters={0}
          align={ALIGNMENT.start}
          gridMargins={0}
        >
          <Cell
            span={[12, 6]}
            align={ALIGNMENT.center}
          >
            <Block
              alignItems="start"
              justifyContent="flex-start"
              display="flex"
            >
              <HeadingXSmall margin={0}>{t('organizations:bankDetails.bankAccounts.header')}</HeadingXSmall>
            </Block>
          </Cell>

          <Cell
            span={[12, 6]}
          >
            <Block
              alignItems="end"
              justifyContent="flex-end"
              display="flex"
              width="100%"
            >
              <Button
                type="button"
                kind={KIND.tertiary}
                onClick={handleAddBankAccountClick}
                overrides={{
                  BaseButton: {
                    props: {
                      id: 'OrganizationFormBankDetailsSection-add-bank-account-btn',
                    },
                  },
                }}
              >
                <Block
                  marginRight="8px"
                >
                  <FontAwesomeIcon icon={faCirclePlus} />
                </Block>
                {t('organizations:bankDetails.addButton')}
              </Button>
            </Block>
          </Cell>
        </Grid>
      </Block>

      <Block
        marginTop="16px"
      >
        <AppStyledTable
          tableGridTemplateColumns="minmax(10%, max-content)
          minmax(15%, max-content)
          minmax(12%, max-content)
          minmax(13%, max-content)
          minmax(13%, max-content)
          minmax(24%, max-content)
          minmax(10%, max-content)"
          tableStyle={{
            // eslint-disable-next-line max-len
            gridTemplateColumns: `
            minmax(12%, max-content)
            minmax(15%, max-content)
            minmax(12%, max-content)
            minmax(13%, max-content)
            minmax(13%, max-content)
            minmax(24%, max-content)
            minmax(10%, max-content)`,
            '@media (max-width: 1200px)': {
              gridTemplateColumns: sourceAccounts.length > 0
                ? '125px 225px 175px 175px 175px 425px 115px'
                : '200px 225px 175px 175px 175px 425px 115px',
            },
            '@media (max-width: 780px)': {
              gridTemplateColumns: sourceAccounts.length > 0
                ? '125px 225px 175px 175px 175px 425px 115px'
                : '200px 225px 175px 175px 175px 425px 115px',
            },
          }}
          headingCells={[
            <Block paddingLeft="16px">
              {t('organizations:bankDetails.sourceAccounts.table.accountId')}
            </Block>,
            t('organizations:bankDetails.sourceAccounts.table.accountNumber'),
            t('organizations:bankDetails.sourceAccounts.table.routingNumber'),
            t('organizations:bankDetails.sourceAccounts.table.legalName'),
            t('organizations:bankDetails.sourceAccounts.table.accountDisplayName'),
            t('organizations:bankDetails.sourceAccounts.table.repaymentStatement'),
            t('organizations:bankDetails.sourceAccounts.table.actions.label'),
          ]}
          rows={rows}
        />

        <ConfirmModal
          onAction={handleBankAccountDeleteConfirm}
          title={t('organizations:bankDetails.sourceAccounts.confirmationTitle')}
          actionBtnText={t('common:confirm.ok')}
          onClose={handleBankAccountDeleteClose}
          modalNameSpecified="CONFIRM_MODAL_ORGANIZATION_DELETE_BANK_ACCOUNT"
        />
      </Block>

      {checkIsModalOpen(modals, ModalNames.BANK_ACCOUNT_MODAL) && <OrganizationFormBankAccountModal selectedAccount={null} />}
      {checkIsModalOpen(modals, ModalNames.BANK_ACCOUNT_EDIT_MODAL) && <OrganizationFormBankAccountModal selectedAccount={selectedBankAccount} />}
    </div>
  );
};

export default memo(OrganizationFormBankDetailsSection);
