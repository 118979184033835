import {
  useState,
} from 'react';
import {
  ChevronDown,
  ChevronUp,
} from 'baseui/icon';
import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import { Block } from 'baseui/block';
import useOuterClick from '../../hooks/useOuterClick';
import AppChipItem, { AppChipItemType } from './AppChipItem';

export type AppChipListItemsPropsType = {
  items: AppChipItemType[]
  isExpanded?: boolean
}

const listItemsStyles = {
  display: 'block',
  position: 'relative',
  minWidth: '100%',
  minHeight: '100%',
  cursor: 'pointer',
  background: '#eee',
} as StyleObject;

const listItemStyles = {
  padding: '4px',
};

const innerContainerStyles = {
  display: 'inline-block',
  position: 'absolute',
  padding: '8px',
  minWidth: '100%',
  top: '100%',
  left: '0px',
  marginLeft: '-6px',
  marginTop: '8px',
  boxShadow: '0px 0px 4px #ccc',
  cursor: 'default',
  background: '#eee',
  zIndex: 200,
} as StyleObject;

const AppChipListItems = ({ items, isExpanded }: AppChipListItemsPropsType) => {
  const [css] = useStyletron();
  const [expanded, setExpanded] = useState(!!isExpanded);

  const { ref } = useOuterClick(() => {
    setExpanded(false);
  });

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      id="AppChip-list-container"
      data-testid="AppChip-list-container"
      ref={ref}
      role="button"
      className={css(listItemsStyles)}
    >
      <Block
        id="AppChipListItems-toggle"
        data-testid="AppChipListItems-toggle"
        display="inline-flex"
        onClick={handleToggleExpand}
      >
        <Block
          display="inline-flex"
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
        >
          <AppChipItem key={items[0]?.toString()} item={items[0]} />
        </Block>

        <Block
          display="inline-flex"
        >
          {expanded
            ? <ChevronUp />
            : <ChevronDown />}
        </Block>
      </Block>

      {expanded && (
        <div
          className={css(innerContainerStyles)}
        >
          {items.slice(1).map((item) => (
            <div key={item?.toString()} className={css(listItemStyles)}>
              <AppChipItem item={item} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AppChipListItems;
