import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, TETHER_PLACEMENT } from 'baseui/layer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ModalNames } from 'store/slices/modals';
import AppModal from 'components/AppModal/AppModal';
import {
  employeeDetailsOffersSelector,
  employeeDetailsGeneratedOffersSelector,
  resetEmployeeOffersById,
  employeesOffersPendingListSelector,
} from 'store/slices/employees';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import { useStyletron } from 'styletron-react';
import Loader from 'components/Loader';
import moment from 'moment';
import { emptyPlaceholder } from 'theme';
import priceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';
import Access, { AccessUnit } from 'components/Access/Access';
import AppTooltip from 'components/Form/AppTooltip';
import AppStyledTable from 'components/AppStyledTable/AppStyledTable';
import PrintJsObjectAsJson from 'components/PrintJsObjectAsJson';
import { headerStyle, rowStyles } from 'screens/CommonHelpers';

export type OfferCalculationListItemPropsType = {
  title: string | number | ReactNode,
  additional?: string | number | ReactNode,
  titleWidth?: string,
  additionalWidth?: string,
}
const TABLE_STYLES = {
  // eslint-disable-next-line max-len
  gridTemplateColumns: 'minmax(12%, max-content) minmax(15%, max-content) minmax(15%, max-content) minmax(12%, max-content) minmax(12%, max-content) minmax(8%, max-content) minmax(10%, max-content) minmax(10%, max-content) minmax(15%, max-content) minmax(15%, max-content) minmax(15%, max-content) minmax(15%, max-content) minmax(15%, max-content)',
  '@media (max-width: 1650px)': {
    gridTemplateColumns: '130px 150px 150px 100px 100px 80px 100px 100px 130px 130px 130px 130px 130px',
  },
};
const firstColumnWidth = '50%';
const secondColumnWidth = '50%';
const headerLabelStyles = {
  fontWeight: 600,
};

const EmployeeDetailsOfferDetailsModal = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['organizations', 'errors', 'common', 'locations', 'payGroups', 'dateFormats']);
  const offer = useAppSelector(employeeDetailsOffersSelector);
  const generatedOffer = useAppSelector(employeeDetailsGeneratedOffersSelector);
  const pending = useAppSelector(employeesOffersPendingListSelector);
  const dispatch = useAppDispatch();
  const modalName = ModalNames.EMPLOYEE_OFFER_DETAILS_MODAL;
  const dateFormat = t('dateFormats:standard');
  const dateFormatWithTime = t('dateFormats:standard-with-time');
  const timeFormat = t('dateFormats:standard-time');

  const {
    id,
    created,
    expirationTime,
    employmentId,
    payrollPeriodId,
    valueAmount,
    ewaAvailableAmount,
    earningsToDateAmount,
    lowerEarningsThreshold,
    data,
    previousDrawsFeesTotalAmount,
    previousDrawsTotalAmount,
  } = offer || generatedOffer || {};

  const {
    tips,
    timeCardDetails,
    timeCards,
  } = data || {};

  const sortedTips = tips && tips.length > 0
    ? [...tips].sort((a, b) => moment(b.businessDate).diff(moment(a.businessDate)))
    : [];

  const onClose = () => {
    dispatch(resetEmployeeOffersById());
  };

  const unscaledValueAmount = valueAmount ? unScalePrice(valueAmount?.value, valueAmount?.scale) : 0;
  const unscaledLowerEarningsThreshold = lowerEarningsThreshold ? unScalePrice(lowerEarningsThreshold?.value, lowerEarningsThreshold?.scale) : 0;
  const unscaledEWAAvailableAmount = ewaAvailableAmount ? unScalePrice(ewaAvailableAmount?.value, ewaAvailableAmount?.scale) : 0;
  const unscaledEarningsToDateAmount = earningsToDateAmount ? unScalePrice(earningsToDateAmount?.value, earningsToDateAmount?.scale) : 0;
  const unscaledPreviousDrawsTotalAmount = previousDrawsTotalAmount
    ? unScalePrice(previousDrawsTotalAmount?.value, previousDrawsTotalAmount?.scale) : 0;
  const unscaledPreviousDrawsFeesTotalAmount = previousDrawsFeesTotalAmount
    ? unScalePrice(previousDrawsFeesTotalAmount?.value, previousDrawsFeesTotalAmount?.scale) : 0;

  const OfferCalculationListItem = ({
    title,
    additional,
    titleWidth,
    additionalWidth,
  }: OfferCalculationListItemPropsType) => (
    <Cell
      align={ALIGNMENT.center}
      span={12}
    >
      <Block
        height="24px"
        overrides={{
          Block: {
            style: { borderBottom: '1px solid #B0B0B0' },
          },
        }}
      >
        <Block
          width={titleWidth || '70%'}
          display="inline-flex"
          justifyContent="start"
          margin={0}
        >
          {title}
        </Block>

        <Block
          width={additionalWidth || '30%'}
          display="inline-flex"
          justifyContent="end"
          margin={0}
        >
          {additional}
        </Block>
      </Block>
    </Cell>
  );

  const headingCells = [
    <span style={headerStyle}>{t('offers:details.timeCard.businessDay.header')}</span>,
    <span style={headerStyle}>{t('offers:details.timeCard.locationName.header')}</span>,
    <span style={headerStyle}>{t('offers:details.timeCard.jobName.header')}</span>,
    <span style={headerStyle}>{t('offers:details.timeCard.start.header')}</span>,
    <span style={headerStyle}>{t('offers:details.timeCard.end.header')}</span>,
    <span style={headerStyle}>{t('offers:details.timeCard.hours.header')}</span>,
    <span style={headerStyle}>{t('offers:details.timeCard.payRate.header')}</span>,
    <span style={headerStyle}>{t('offers:details.timeCard.wage.header')}</span>,
    <span style={headerStyle}>{t('offers:details.timeCard.tips.header')}</span>,
    <span style={headerStyle}>{t('offers:details.timeCard.serviceCharge.header')}</span>,
    <span style={headerStyle}>{t('offers:details.timeCard.declaredTips.header')}</span>,
    <span style={headerStyle}>{t('offers:details.timeCard.cashTips.header')}</span>,
    <span style={headerStyle}>{t('offers:details.timeCard.tipsPool.header')}</span>,
  ];
  return (
    <Layer index={400}>
      <AppModal
        minWidth="360px"
        maxWidth="100%"
        onClose={onClose}
        modal={modalName}
        title={`${t('offers:details.title')} - ${priceFormatter().format(unscaledValueAmount) || emptyPlaceholder}`}
      >
        <Loader active={pending} />

        <Block
          marginTop="40px"
        >
          <Grid
            gridColumns={12}
            gridMargins={16}
            gridGaps={16}
          >

            {id && (
              <Cell span={12}>
                <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
                  {t('offers:details.offerID')}
                  :
                </Block>
                <Block width={secondColumnWidth} display="inline-flex">
                  {id || emptyPlaceholder}
                </Block>
              </Cell>
            )}

            <Cell span={12}>
              <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
                {t('offers:details.employmentID')}
                :
              </Block>
              <Block width={secondColumnWidth} display="inline-flex">
                {employmentId || emptyPlaceholder}
              </Block>
            </Cell>

            <Cell span={12}>
              <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
                {t('offers:details.payrollPeriodID')}
                :
              </Block>
              <Block width={secondColumnWidth} display="inline-flex">
                {payrollPeriodId || emptyPlaceholder}
              </Block>
            </Cell>

            {(id && created) && (
              <Cell span={12}>
                <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
                  {t('offers:details.created')}
                  :
                </Block>
                <Block width={secondColumnWidth} display="inline-flex">
                  {created ? moment(created).format(dateFormatWithTime) : emptyPlaceholder}
                </Block>
              </Cell>
            )}
            <Cell span={12}>
              <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
                {t('offers:details.expirationTime')}
                :
              </Block>
              <Block width={secondColumnWidth} display="inline-flex">
                {expirationTime ? moment(expirationTime).format(dateFormatWithTime) : emptyPlaceholder}
              </Block>
            </Cell>

            <Block
              marginTop="16px"
            >
              <Cell span={12}>
                <b>
                  {t('offers:details.offerCalculation')}
                </b>
              </Cell>
            </Block>

            <OfferCalculationListItem
              title={t('offers:details.earningsToDate')}
              additional={priceFormatter().format(unscaledEarningsToDateAmount)}
            />

            <OfferCalculationListItem
              title={t('offers:details.let')}
              additional={priceFormatter().format(unscaledLowerEarningsThreshold)}
            />
            <OfferCalculationListItem
              title={(
                <>
                  {t('offers:details.EWAAvailable')}
                  <AppTooltip
                    title={t('offers:details.EWAAvailable.tooltip.title')}
                    content={t('offers:details.EWAAvailable.tooltip.content')}
                    placement={TETHER_PLACEMENT.right}
                  />
                </>
              )}
              additional={priceFormatter().format(unscaledEWAAvailableAmount)}
            />

            <OfferCalculationListItem
              title={t('offers:details.previousDrawsTotal')}
              additional={priceFormatter().format(unscaledPreviousDrawsTotalAmount)}
            />

            <OfferCalculationListItem
              title={t('offers:details.previousDrawsFeesTotal')}
              additional={priceFormatter().format(unscaledPreviousDrawsFeesTotalAmount)}
            />

            <OfferCalculationListItem
              title={t('offers:details.offerAmount')}
              additional={priceFormatter().format(unscaledValueAmount)}
            />
            <Access
              not
              oneOf={[AccessUnit.EWAClientManager]}
            >
              <Block
                marginTop="16px"
              >
                <Cell span={12}>
                  <b>
                    {t('offers:details.earningDetails.title')}
                  </b>
                </Cell>
              </Block>

              {timeCardDetails && timeCardDetails.length > 0 && (
                <>
                  <Cell span={12}>
                    <Block className={css(headerLabelStyles)} width="100%" display="inline-flex">
                      {t('offers:details.timeCardDetails.title')}
                    </Block>
                  </Cell>
                  <Cell span={12}>
                    <AppStyledTable
                      tableGridTemplateColumns={TABLE_STYLES.gridTemplateColumns}
                      tableStyle={TABLE_STYLES}
                      headingCells={headingCells}
                      rows={timeCardDetails?.map((timeCard, index) => {
                        const start = moment(timeCard?.clockIn);
                        const end = moment(timeCard?.clockOut);
                        const duration = moment.duration(end.diff(start));
                        const hours = duration.asHours();
                        const unscaledPayRate = timeCard?.jobPayRate?.value
                          ? unScalePrice(timeCard.jobPayRate.value, timeCard.jobPayRate.scale)
                          : 0;
                        const unscaledRegularPay = timeCard?.regularPay?.value
                          ? unScalePrice(timeCard.regularPay.value, timeCard.regularPay.scale)
                          : 0;
                        const unscaledServiceChargePay = timeCard?.serviceChargePay?.value
                          ? unScalePrice(timeCard.serviceChargePay.value, timeCard.serviceChargePay.scale)
                          : 0;
                        const unscaledCreditedTips = timeCard?.creditTipsPay?.value
                          ? unScalePrice(timeCard.creditTipsPay.value, timeCard.creditTipsPay.scale)
                          : 0;
                        const unscaledDeclaredTips = timeCard?.declaredTipsPay?.value
                          ? unScalePrice(timeCard.declaredTipsPay.value, timeCard.declaredTipsPay.scale)
                          : 0;
                        const unscaledCashTips = timeCard?.cashTipsPay?.value
                          ? unScalePrice(timeCard.cashTipsPay.value, timeCard.cashTipsPay.scale)
                          : 0;
                        const unscaledTipsPool = timeCard?.tipsPoolPay?.value
                          ? unScalePrice(timeCard.tipsPoolPay.value, timeCard.tipsPoolPay.scale)
                          : 0;

                        return {
                          id: `${timeCard?.clientId?.toString()}-${index}`,
                          cells: [
                            <span style={rowStyles}>
                              {
                              timeCard?.clockIn
                                ? moment(timeCard.clockIn).format(dateFormat)
                                : emptyPlaceholder
                            }
                            </span>,
                            <span style={rowStyles}>
                              {
                              timeCard?.location?.name || emptyPlaceholder
                            }
                            </span>,
                            <span style={rowStyles}>
                              {
                              timeCard?.jobName || emptyPlaceholder
                            }
                            </span>,
                            <span style={rowStyles}>
                              {
                              timeCard?.clockIn
                                ? moment(timeCard.clockIn).format(timeFormat)
                                : emptyPlaceholder
                            }
                            </span>,
                            <span style={rowStyles}>
                              {
                              timeCard?.clockOut
                                ? moment(timeCard.clockOut).format(timeFormat)
                                : emptyPlaceholder
                            }
                            </span>,
                            <span style={rowStyles}>{hours}</span>,
                            <span style={rowStyles}>{priceFormatter().format(unscaledPayRate)}</span>,
                            <span style={rowStyles}>{priceFormatter().format(unscaledRegularPay)}</span>,
                            <span style={rowStyles}>{priceFormatter().format(unscaledCreditedTips)}</span>,
                            <span style={rowStyles}>{priceFormatter().format(unscaledServiceChargePay)}</span>,
                            <span style={rowStyles}>{priceFormatter().format(unscaledDeclaredTips)}</span>,
                            <span style={rowStyles}>{priceFormatter().format(unscaledCashTips)}</span>,
                            <span style={rowStyles}>{priceFormatter().format(unscaledTipsPool)}</span>,
                          ],
                        };
                      }) || []}
                    />
                  </Cell>
                </>
              )}

              {timeCards && timeCards.length > 0 && (
              <>
                <Cell span={12}>
                  <Block className={css(headerLabelStyles)} width="100%" display="inline-flex">
                    {t('offers:details.timeCards.title')}
                  </Block>
                </Cell>
                <Cell span={12}>
                  <AppStyledTable
                    tableGridTemplateColumns={TABLE_STYLES.gridTemplateColumns}
                    tableStyle={TABLE_STYLES}
                    headingCells={headingCells}
                    rows={timeCards?.map((timeCard, index) => {
                      const start = moment(timeCard.startedAt);
                      const end = moment(timeCard.endedAt);
                      const duration = moment.duration(end.diff(start));
                      const hours = duration.asHours();
                      const unscaledPayRate = timeCard?.jobPayRate?.value
                        ? unScalePrice(timeCard.jobPayRate.value, timeCard.jobPayRate.scale)
                        : 0;
                      const unscaledServiceChargePay = timeCard?.serviceChargePay?.value
                        ? unScalePrice(timeCard.serviceChargePay.value, timeCard.serviceChargePay.scale)
                        : 0;
                      const unscaledCreditedTips = timeCard?.creditTipsPay?.value
                        ? unScalePrice(timeCard.creditTipsPay.value, timeCard.creditTipsPay.scale)
                        : 0;
                      const unscaledDeclaredTips = timeCard?.declaredTipsPay?.value
                        ? unScalePrice(timeCard.declaredTipsPay.value, timeCard.declaredTipsPay.scale)
                        : 0;
                      const unscaledCashTips = timeCard?.cashTipsPay?.value
                        ? unScalePrice(timeCard.cashTipsPay.value, timeCard.cashTipsPay.scale)
                        : 0;
                      const unscaledTipsPool = timeCard?.tipsPoolPay?.value
                        ? unScalePrice(timeCard.tipsPoolPay.value, timeCard.tipsPoolPay.scale)
                        : 0;
                      const wage = hours * unscaledPayRate;

                      return {
                        id: `${timeCard?.id?.toString()}-${index}`,
                        cells: [
                          <span style={rowStyles}>
                            {
                              timeCard?.startedAt
                                ? moment.parseZone(timeCard?.startedAt).format(dateFormat)
                                : emptyPlaceholder
                            }
                          </span>,
                          <span style={rowStyles}>
                            {
                              timeCard?.location?.name || emptyPlaceholder
                            }
                          </span>,
                          <span style={rowStyles}>
                            {
                              timeCard?.jobName || emptyPlaceholder
                            }
                          </span>,
                          <span style={rowStyles}>
                            {
                           timeCard?.startedAt
                             ? moment.parseZone(timeCard?.startedAt).format(timeFormat)
                             : emptyPlaceholder
                         }
                          </span>,
                          <span style={rowStyles}>
                            {
                           timeCard?.endedAt
                             ? moment.parseZone(timeCard?.endedAt).format(timeFormat)
                             : emptyPlaceholder
                         }
                          </span>,
                          <span style={rowStyles}>
                            {duration.minutes() ? `${duration.hours()}:${duration.minutes()}` : duration.hours()}
                          </span>,
                          <span style={rowStyles}>
                            {priceFormatter().format(unscaledPayRate)}
                          </span>,
                          <span style={rowStyles}>
                            {priceFormatter().format(wage)}
                          </span>,
                          <span style={rowStyles}>
                            {priceFormatter().format(unscaledCreditedTips)}
                          </span>,
                          <span style={rowStyles}>
                            {priceFormatter().format(unscaledServiceChargePay)}
                          </span>,
                          <span style={rowStyles}>
                            {priceFormatter().format(unscaledDeclaredTips)}
                          </span>,
                          <span style={rowStyles}>
                            {priceFormatter().format(unscaledCashTips)}
                          </span>,
                          <span style={rowStyles}>
                            {priceFormatter().format(unscaledTipsPool)}
                          </span>,
                        ],
                      };
                    }) || []}
                  />
                </Cell>
              </>
              )}
              {sortedTips && sortedTips.length > 0 && (
                <>
                  <Cell span={12}>
                    <Block className={css(headerLabelStyles)} width="100%" display="inline-flex">
                      {t('offers:details.thirdPartyTips.title')}
                    </Block>
                  </Cell>

                  <Cell span={12}>
                    <AppStyledTable
                      tableGridTemplateColumns="minmax(50%, max-content) minmax(50%, max-content)"
                      headingCells={[
                        <span style={headerStyle}>{t('offers:details.tips.businessDay.header')}</span>,
                        <span style={headerStyle}>{t('offers:details.tips.tips.header')}</span>,
                      ]}
                      rows={sortedTips.map((tip, index) => {
                        const unscaledTotalTips = tip?.totalTipsAmount?.value ? unScalePrice(tip.totalTipsAmount.value, tip.totalTipsAmount.scale) : 0;

                        return {
                          id: `${tip.businessDate.toString()}-${index}`,
                          cells: [
                            <span style={rowStyles}>
                              {tip?.businessDate ? moment(tip?.businessDate).format(dateFormat) : emptyPlaceholder}
                            </span>,
                            <span style={rowStyles}>{priceFormatter().format(unscaledTotalTips)}</span>,
                          ],
                        };
                      }) || []}
                    />
                  </Cell>
                </>
              )}

              {
                (!timeCardDetails || timeCardDetails.length === 0)
                && (!timeCards || timeCards.length === 0) && (
                  <Cell span={12}>
                    <Block className={css(headerLabelStyles)} width="100%" display="inline-flex">
                      {t('offers:details.data')}
                      :
                    </Block>
                    <Block height="220px" marginTop="16px" width="100%" display="inline-flex">
                      {data ? <PrintJsObjectAsJson {...data} /> : emptyPlaceholder}
                    </Block>
                  </Cell>
                )
              }
            </Access>
          </Grid>
        </Block>
      </AppModal>
    </Layer>
  );
};

export default memo(EmployeeDetailsOfferDetailsModal);
