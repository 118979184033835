import { borderRadius, colors } from 'theme';

export const list = {
  padding: '0px',
};

export const listItem = {
  background: '#FFFFFF',
  'list-style': 'none',
  padding: '0px',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
};

export const listItemNameAndStatuses = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  padding: '14px 4px',
};

export const listItemInfoStyles = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '0px 4px 16px 20px',
};

export const nameButton = {
  Root: {
    style: {
      maxWidth: 'calc(100% - 48px)', // 48px is the width of the stateful popup button
      paddingLeft: '0px',
    },
  },
};

export const details = {
  display: 'inline-block',
  marginRight: '8px',
  marginTop: '4px',
};

export const containerStyles = {
  display: 'inherit',
  width: '100%',
  background: '#FFF',
  marginBottom: '24px',
};

export const blockOverrides = {
  Block: {
    style: {
      background: '#F3F3F3',
    },
  },
};

export const blockOverridesRelative = {
  Block: {
    style: {
      background: '#F3F3F3',
      position: 'relative',
    },
  },
};

export const blockOverridesRelativeWhite = {
  Block: {
    style: {
      background: '#FFF',
      position: 'relative',
    },
  },
};

export const dateStyle = {
  fontSize: '12px',
};

export const headerStyle = {
  fontSize: '14px',
  lineHeight: '20px',
};

export const rowStyles = {
  fontSize: '14px',
  lineHeight: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const activeTabContainerStyles = {
  display: 'inherit',
  margin: '8px 0px 0px 0px',
  width: '100%',
  overflow: 'auto',
  padding: '0 20px 0px 20px',
};

export const tabsButtonStyles = {
  Root: {
    style: {
      color: '#111111',
      'font-size': '15px',
      'font-weight': '700',
      'white-space': 'nowrap',
    },
  },
};

export const activeTabButtonStyles = {
  borderBottom: `4px solid ${colors.primary}`,
};

export const tabButtonStyles = {
  borderBottom: '4px solid #E3E3E3',
};

export const emptyTabButtonStyles = {
  borderBottom: '4px solid #E3E3E3',
  width: '100%',
};

export const paginationTransparentOverrides = {
  PrevButton: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  Select: {
    props: {
      overrides: {
        ControlContainer: {
          style: () => ({
            borderLeftColor: 'transparent',
            borderRightColor: 'transparent',
            borderTopColor: 'transparent',
            borderBottomColor: 'transparent',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            ':hover': {
              backgroundColor: 'transparent',
            },
            ':focus': {
              backgroundColor: 'transparent',
            },
          }),
        },
        InputContainer: {
          style: {
            marginLeft: '0px',
          },
        },
        ValueContainer: {
          style: {
            flexBasis: 'auto',
          },
        },
        SingleValue: {
          style: ({ $theme }: any) => ({
            position: 'relative',
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: $theme.sizing.scale200,
            paddingRight: $theme.sizing.scale500,
            color: $theme.colors.buttonTertiaryText,
            ...$theme.typography.LabelMedium,
            lineHeight: 'unset',
          }),
        },
        SelectArrow: {
          props: {
            overrides: {
              Svg: {
                style: ({ $theme }: any) => ({
                  width: '24px',
                  height: '24px',
                  color: $theme.colors.buttonTertiaryText,
                }),
              },
            },
          },
        },
      },
    },
  },
  NextButton: {
    style: {
      backgroundColor: 'transparent',
    },
  },
};

export const workaroundPaginationTransparentOverrides = {
  PrevButton: {
    style: {
      backgroundColor: 'transparent',
    },
    props: {
      disabled: true,
    },
  },
  Select: {
    props: {
      overrides: {
        ControlContainer: {
          style: () => ({
            borderLeftColor: 'transparent',
            borderRightColor: 'transparent',
            borderTopColor: 'transparent',
            borderBottomColor: 'transparent',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            ':hover': {
              backgroundColor: 'transparent',
            },
            ':focus': {
              backgroundColor: 'transparent',
            },
          }),
        },
        InputContainer: {
          style: {
            marginLeft: '0px',
          },
        },
        ValueContainer: {
          style: {
            flexBasis: 'auto',
          },
        },
        SingleValue: {
          style: ({ $theme }: any) => ({
            position: 'relative',
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: $theme.sizing.scale200,
            paddingRight: $theme.sizing.scale500,
            color: $theme.colors.buttonTertiaryText,
            ...$theme.typography.LabelMedium,
            lineHeight: 'unset',
          }),
        },
        SelectArrow: {
          props: {
            overrides: {
              Svg: {
                style: ({ $theme }: any) => ({
                  width: '24px',
                  height: '24px',
                  color: $theme.colors.buttonTertiaryText,
                }),
              },
            },
          },
        },
      },
    },
  },
  NextButton: {
    style: {
      backgroundColor: 'transparent',
    },
    props: {
      disabled: true,
    },
  },
};

export const tooltipOverrides = (withEllipsis: boolean) => {
  const style = withEllipsis
    ? {
      'max-width': '500px',
      textOverflow: 'ellipsis',
      'white-space': 'nowrap',
      overflow: 'hidden',
    }
    : {
      'max-width': '500px',
      'overflow-wrap': 'break-word',
      'word-break': 'break-all',
    };

  return {
    Inner: {
      style,
    },
  };
};

export const skeletonStyles = ({ margin = '' } = {}) => ({
  Root: {
    style: {
      borderRadius,
      margin,
    },
  },
});
