import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  negativeStyles,
  footerStyles,
} from 'screens/Batches/BatchesHelpers';
import { Block } from 'baseui/block';
import PriceFormatter from 'utils/priceFormatter';

export type PaymentAllocationFooterPropsType = {
  isBalanceNegative: boolean
  allocatedAmount: number
  paymentAmount: number
  balance: number
}

const PaymentAllocationFooter = ({
  isBalanceNegative,
  allocatedAmount,
  paymentAmount,
  balance,
}: PaymentAllocationFooterPropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['batches']);

  return (
    <div className={css(footerStyles)}>
      <Grid
        gridColumns={12}
        align={ALIGNMENT.center}
        gridMargins={0}
      >
        <Cell
          align={ALIGNMENT.center}
          span={5}
        >
          <Block
            display="flex"
            justifyContent="end"
            marginRight="24px"
          >
            {t('batches:paymentAllocations.paymentAmount')}
            :&nbsp;
            <strong>
              {` ${PriceFormatter().format(paymentAmount)}`}
            </strong>
          </Block>
        </Cell>

        <Cell
          align={ALIGNMENT.center}
          span={2.9}
        >
          <Block
            display="flex"
            justifyContent="end"
            marginRight="4px"
          >
            {t('batches:paymentAllocations.allocationAmount')}
            :&nbsp;
            <strong>
              {` ${PriceFormatter().format(allocatedAmount)}`}
            </strong>
          </Block>
        </Cell>

        <Cell
          align={ALIGNMENT.center}
          span={2.9}
        >
          <Block
            display="flex"
            justifyContent="center"
          >
            {t('batches:paymentAllocations.balance')}
            :&nbsp;
            <strong className={css(isBalanceNegative ? negativeStyles : {})}>
              {` ${PriceFormatter().format(balance)}`}
            </strong>
          </Block>
        </Cell>
      </Grid>
    </div>
  );
};

export default PaymentAllocationFooter;
