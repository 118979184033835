import { ReactNode } from 'react';
import { useStyletron } from 'baseui';

export type TitleSeparatorPropsType = {
  children: ReactNode
}

const TitleSeparator = ({
  children,
}: TitleSeparatorPropsType) => {
  const [css] = useStyletron();
  return (
    <div className={css({
      margin: '16px',
    })}
    >
      <div className={css({
        fontSize: '16px',
        fontWeight: '500',
        color: '#000',
      })}
      >
        {children}
      </div>
      <hr />
    </div>
  );
};

export default TitleSeparator;
