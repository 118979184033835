export const employeeDetailsInitialValues = {
  payrollId: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  ssn: '',
  mobileNumber: '',
  address: '',
  unit: '',
  city: '',
  state: [{ value: '' }],
  zip: '',
};

export const employeePaymentDetailsInitialValues = {
  funds: '',
  proxyNumber: '',
  paymentDescription: '',
  internalNotes: '',
};

export const paycardManagementOrderNewCardsInitialValues = {
  cardsCount: '',
  addressee: '',
  attention: '',
  phone: '',
};

export const paycardManagementLoadFundsInitialValues = {
  amount: '',
  paymentDescription: '',
  internalNotes: '',
};
