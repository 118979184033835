import { OrganizationIDType } from 'types/OrganizationTypes';
import { SetOrganizationTPOSettingsRequest } from 'types/TPOTypes';
import request from './request';

export const setOrganizationTPOSettingsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: SetOrganizationTPOSettingsRequest,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organization/${organizationID}/tco/settings`,
});

export const other = null;
