import {
  ChangeEvent,
  memo,
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleObject, useStyletron } from 'styletron-react';
import { Search } from 'baseui/icon';
import {
  Input,
  SIZE,
} from 'baseui/input';
import { Block } from 'baseui/block';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  OnChangeParams,
  Select,
  Value,
} from 'baseui/select';
import CellFormControl from 'components/CellFormControl';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  fetchPayrollPeriods,
  payGroupPayrollPeriodsSelector,
  payGroupsSelector,
} from 'store/slices/payGroups';
import {
  repaymentSelector,
  repaymentsPaymentAllocationSearchPayGroupSelector,
  repaymentsPaymentAllocationSearchPayPeriodSelector,
  resetPaymentAllocationSearchPayGroup,
  resetPaymentAllocationSearchPayPeriod,
  setPaymentAllocationSearchPayGroup,
  setPaymentAllocationSearchPayPeriod,
} from 'store/slices/repayments';
import {
  PaymentAllocationsFilterType,
  PaymentStatusType,
} from 'types/RepaymentTypes';
import { borderRadius } from 'theme';
import { Card } from 'baseui/card';
import {
  fetchBatchAllocations,
  resetPaymentAllocation,
} from 'store/slices/paymentAllocation';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import PaymentAllocationTable from './PaymentAllocationTable';

const contentContainerStyles = {
  padding: '24px 24px 0px',
  background: '#E5E5E5',
  marginTop: '0px',
  zIndex: 50,
};

const contentPaymentAloocationStyles = {
  background: '#fff',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  'box-sizing': 'border-box',
  padding: '24px',
  maxWidth: '100%',
  position: 'relative',
} as StyleObject;

const PaymentAllocationSection = () => {
  const [css] = useStyletron();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['batches', 'errors', 'common', 'dateFormats']);
  const payGroups = useAppSelector(payGroupsSelector);
  const payGroupPayrollPeriods = useAppSelector(payGroupPayrollPeriodsSelector);
  const organization = useAppSelector(loggedOrganizationSelector);
  const repayment = useAppSelector(repaymentSelector);
  const payGroup = useAppSelector(repaymentsPaymentAllocationSearchPayGroupSelector);
  const payrollPeriod = useAppSelector(repaymentsPaymentAllocationSearchPayPeriodSelector);

  const [searchPayGroupChanged, setSearchPayGroupHasChanged] = useState<boolean>(false);
  const [searchPayPeriodChanged, setSearchPayPeriodHasChanged] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const dateFormat = t('dateFormats:standard');

  const {
    id: organizationID,
  } = organization || {};

  const {
    dateReceived,
    status,
  } = repayment;

  const handlePayGroupChange = ({
    value,
  }: OnChangeParams) => {
    setSearchPayGroupHasChanged(true);
    dispatch(setPaymentAllocationSearchPayGroup(value));
    dispatch(resetPaymentAllocationSearchPayPeriod());
  };

  const handlePayrollPeriodChange = ({
    value,
  }: OnChangeParams) => {
    setSearchPayPeriodHasChanged(true);
    dispatch(setPaymentAllocationSearchPayPeriod(value));
  };

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    setSearch(e.target.value);
  };

  const filter: PaymentAllocationsFilterType = {
    payGroupID: payGroup && payGroup[0]?.id?.toString(),
    search: search?.trim(),
    payrollPeriodID: payrollPeriod && payrollPeriod[0]?.id?.toString(),
  };

  useEffect(() => {
    if (payGroup && payGroup.length > 0) {
      dispatch(fetchPayrollPeriods({ organizationID, payGroupID: payGroup[0]?.id?.toString() }));
    } else {
      dispatch(resetPaymentAllocationSearchPayGroup());
    }
  }, [payGroup]);

  useEffect(() => {
    dispatch(resetPaymentAllocationSearchPayGroup());
    dispatch(resetPaymentAllocationSearchPayPeriod());
    dispatch(fetchBatchAllocations({
      organizationID, pageNumber: '1', payGroupId: '', payrollPeriodId: '',
    }));
  }, []);

  useEffect(() => {
    if (searchPayPeriodChanged || searchPayGroupChanged) {
      dispatch(fetchBatchAllocations({
        organizationID, pageNumber: '1', payGroupId: payGroup[0]?.id || '', payrollPeriodId: payrollPeriod[0]?.id || '',
      }));
    }
    window.scrollTo(0, 0);
    return () => {
      dispatch(resetPaymentAllocation());
    };
  }, [payGroup, payrollPeriod, searchPayGroupChanged, searchPayPeriodChanged]);

  return (
    <div className={css(contentContainerStyles)}>
      <Grid
        gridColumns={12}
        gridMargins={0}
      >
        <Cell span={12}>
          <div
            className={css(contentPaymentAloocationStyles)}
          >
            <Grid
              gridColumns={12}
              gridMargins={0}
            >
              <Cell
                align={ALIGNMENT.center}
                span={[12, 6, 9]}
              >
                <h3>{t('batches:paymentAllocations.paymentAllocation')}</h3>
              </Cell>

              <Cell
                align={ALIGNMENT.center}
                span={[12, 6, 3]}
              >
                <Block
                  display="flex"
                  justifyContent="end"
                >
                  {t('batches:paymentAllocations.paymentReceived')}
                  :&nbsp;
                  <strong>
                    {moment(dateReceived).format(t('dateFormats:standard'))}
                  </strong>
                </Block>
              </Cell>
            </Grid>

            <hr />
            {status !== PaymentStatusType.ALLOCATED ? (
              <>
                <Grid
                  gridColumns={12}
                  gridMargins={0}
                >
                  <Cell span={6}>
                    <b>
                      {t('common:filterBy')}
                    </b>
                  </Cell>
                  <Cell span={6}>
                    <b>
                      {t('common:searchBy')}
                    </b>
                  </Cell>

                  <CellFormControl
                    label="&nbsp;"
                    cellSpan={[12, 6]}
                  >
                    <Input
                      size={SIZE.compact}
                      startEnhancer={<Search />}
                      type="text"
                      name="search"
                      autoComplete="off"
                      onChange={handleChangeSearch}
                      value={search}
                      id="payment-allocation-section-search"
                      clearOnEscape
                      placeholder={t('common:searchPlaceholder')}
                    />
                  </CellFormControl>

                  <CellFormControl
                    cellSpan={[12, 6, 3]}
                    label={`${t('batches:payGroup.label')}`}
                  >
                    <Select
                      size={SIZE.compact}
                      maxDropdownHeight="300px"
                      placeholder={t('common:select')}
                      type="select"
                      clearable
                      options={payGroups
                        ?.map(({ name, id }) => ({
                          name,
                          id,
                        }))
                        ?.sort((a, b) => a.name.localeCompare(b.name))}
                      labelKey="name"
                      valueKey="id"
                      onChange={handlePayGroupChange}
                      value={payGroup as Value}
                      overrides={{
                        ControlContainer: {
                          props: {
                            id: 'payment-allocation-section-payGroup-select',
                          },
                        },
                      }}
                    />
                  </CellFormControl>

                  <CellFormControl
                    cellSpan={[12, 6, 3]}
                    label={`${t('batches:payPeriod.label')}`}
                  >
                    <Select
                      size={SIZE.compact}
                      maxDropdownHeight="300px"
                      disabled={!payGroup || payGroup?.length === 0}
                      type="select"
                      clearable={false}
                      options={payGroupPayrollPeriods?.map(({ startDate, endDate, id }) => ({
                        name: `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`,
                        id,
                      }))}
                      labelKey="name"
                      valueKey="id"
                      onChange={handlePayrollPeriodChange}
                      value={payrollPeriod as Value}
                      placeholder={t('common:select')}
                      overrides={{
                        ControlContainer: {
                          props: {
                            id: 'payment-allocation-section-payPeriod-select',
                          },
                        },
                      }}
                    />
                  </CellFormControl>
                </Grid>
                <PaymentAllocationTable filter={filter} />
              </>
            ) : (
              <Grid
                gridColumns={12}
                gridMargins={16}
              >
                <Cell span={12}>
                  <Card>
                    {t('batches:fullyAllocatedPayment')}
                  </Card>
                </Cell>
              </Grid>
            )}
          </div>
        </Cell>
      </Grid>
    </div>
  );
};

export default memo(PaymentAllocationSection);
