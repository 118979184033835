import { OrganizationIDType } from 'types/OrganizationTypes';
import { PayGroup } from 'store/slices/payGroups';
import { PayPeriodType, UpdatePayPeriodActionsType } from 'types/PayrollPeriodTypes';
import request from './request';

export const fetchPayPeriodsWithActionsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  pageNumber?: number,
  pageSize?: number,
  from?: string,
  to?: string,
  statuses?: string,
  actionRequired?: boolean,
) => {
  const queryParams = new URLSearchParams({});

  from && queryParams.set('from', from);
  to && queryParams.set('to', to);
  pageNumber && queryParams.set('pageNumber', pageNumber.toString());
  pageSize && queryParams.set('pageSize', pageSize.toString());
  statuses && queryParams.set('statuses', statuses);
  actionRequired !== undefined && queryParams.set('actionRequired', actionRequired.toString());

  return request({
    token,
    url: `/organizations/${organizationID}/payroll-periods-with-actions?${queryParams}`,
  });
};

export const updatePayPeriodActionsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  payPeriodID: PayPeriodType['id'],
  payGroupId: PayGroup['id'],
  payload: UpdatePayPeriodActionsType['payload'],
) => request({
  token,
  url: `/organizations/${organizationID}/pay-groups/${payGroupId}/payroll-periods/${payPeriodID}/actions`,
  method: 'PATCH',
  payload,
});

export const fetchUtilizationReportRequest = (
  token: string,
  organizationId: OrganizationIDType,
) => request({
  token,
  method: 'GET',
  url: `/organizations/${organizationId}/utilization-report`,
});

export const fetchEnrolmentReportRequest = (
  token: string,
  organizationId: OrganizationIDType,
) => request({
  token,
  method: 'GET',
  url: `/organizations/${organizationId}/enrollment-report`,

});
