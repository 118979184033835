import {
  ReactNode,
  useContext,
} from 'react';
import {
  TimezonePicker,
  TimezonePickerProps,
} from 'baseui/timezonepicker';
import {
  Cell,
  ALIGNMENT,
  Responsive,
  CellProps,
  Alignment,
} from 'baseui/layout-grid';
import {
  FormControlProps,
} from 'baseui/form-control';
import {
  FormikHandlers, FormikHelpers, FormikState,
} from 'formik';
import { SIZE } from 'baseui/input';
import AppFormControl from './AppFormControl';

export type AppTimezonePickerPropsType = {
  name: string
  context: any
  label?: string | undefined
  maxDropdownHeight?: string
  cellSpan?: Responsive<number> | undefined
  cellAlign?: Alignment | undefined
  cellProps?: CellProps
  timezonePickerProps?: TimezonePickerProps
  formControlProps?: Pick<FormControlProps, Exclude<keyof FormControlProps, 'children'>>
  showStar?: boolean
  tooltipTitle?: ReactNode,
  tooltipContent?: ReactNode,
}

const AppTimezonePicker = ({
  label,
  name,
  cellSpan = 3,
  cellAlign = ALIGNMENT.start,
  maxDropdownHeight = '280px',
  cellProps,
  timezonePickerProps,
  formControlProps,
  context,
  showStar,
  tooltipTitle,
  tooltipContent,
}: AppTimezonePickerPropsType) => {
  const formik = useContext<FormikState<any> & FormikHelpers<any> & FormikHandlers>(context);

  const {
    errors,
    values,
    touched,
    setFieldValue,
  } = formik;

  return (
    <Cell
      span={cellSpan}
      align={cellAlign}
      {...cellProps}
    >
      <AppFormControl
        tooltipTitle={tooltipTitle}
        tooltipContent={tooltipContent}
        showStar={showStar}
        label={label}
        error={errors[name]}
        {...formControlProps}
      >
        <TimezonePicker
          size={SIZE.compact}
          value={values[name]}
          onChange={(tz) => setFieldValue(name, tz?.id)}
          error={!!(errors[name] && touched[name] && errors[name])}
          overrides={{
            Select: {
              props: {
                id: `Form-timezone-picker-${name}`,
                overrides: {
                  Root: {
                    props: {
                      'data-testid': `Form-timezone-picker-${name}`,
                    },
                  },
                  Dropdown: {
                    style: {
                      height: maxDropdownHeight,
                    },
                  },
                  ValueContainer: {
                    props: {
                      'data-testid': `Form-timezone-picker-${name}`,
                    },
                  },
                  ControlContainer: {
                    props: {
                      id: `Form-timezone-picker-${name}`,
                    },
                  },
                  Input: {
                    props: { id: `Form-timezone-picker-input-${name}`, name: `Form-timezone-picker-input-name-${name}` },
                  },
                },
              },
            },
          }}
          {...timezonePickerProps}
        />
      </AppFormControl>
    </Cell>
  );
};

export default AppTimezonePicker;
