export const profileInitialValues = {
  firstName: '',
  lastName: '',
  password: '',
  repeatPassword: '',
  email: '',
  userSource: '',
  roles: [],
  mobileCountry: {
    id: 'US',
    label: 'United States',
    dialCode: '+1',
  },
  mobilePhone: '',
  adminProfile: '',
};

export const adminProfileInitialValues = {
  firstName: '',
  lastName: '',
  password: '',
  repeatPassword: '',
  email: '',
  userSource: '',
  roles: [],
  mobileCountry: {
    id: 'US',
    label: 'United States',
    dialCode: '+1',
  },
  mobilePhone: '',
};

export const other = null;
