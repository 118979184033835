import { useStyletron } from 'styletron-react';
import { contentContainerStyles } from 'screens/PaycardManagement/PaycardManagementHelper';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import WizardContainer from 'components/Wizard/WizardContainer/WizardContainer';
import WizardTabulation from 'components/Wizard/WizardTabulation/WizardTabulation';
import { useTranslation } from 'react-i18next';
import { wizardSectionSelector } from 'store/slices/sections';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { orderNewCards, paycardManagementIsOrderNewCardsFormValidSelector, paycardManagementSelectedCardStockSelector } from 'store/slices/paycards';
import { orderNewCardsMapper } from 'dataMappers/paycardManagementMapper';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { OrderNewCardsValuesType } from 'types/PaycardManagementTypes';
import ConfirmationSection from './ConfirmationSection/ConfirmationSection';
import OrderNewCardsOrderSection from './OrderNewCardsOrderSection/OrderNewCardsOrderSection';

const OrderNewCards = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'paycardManagement']);
  const history = useHistory();
  const activeSection = useAppSelector(wizardSectionSelector);
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const isOrderNewCardsFormValid = useAppSelector(paycardManagementIsOrderNewCardsFormValidSelector);
  const cardStock = useAppSelector(paycardManagementSelectedCardStockSelector);
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState<OrderNewCardsValuesType | {}>({});

  const subMenuHeadings = [
    t('paycardManagement:paycardManagement.orderNewCards.menuHeadings.orderCards'),
    t('paycardManagement:paycardManagement.orderNewCards.menuHeadings.orderConfirmation'),
  ];

  const onCancel = () => {
    history.push('/paycard');
  };

  const nextButtonDisabledCondition = useMemo(() => {
    switch (activeSection) {
      case 0:
        return !isOrderNewCardsFormValid;
      default:
        return false;
    }
  }, [activeSection, isOrderNewCardsFormValid]);

  const handleFinish = () => {
    dispatch(orderNewCards({
      organizationId: loggedOrganization?.id,
      locationId: cardStock?.locationId.toString(),
      data: orderNewCardsMapper(formValues as OrderNewCardsValuesType),
    })).then(() => {
      history.push('/paycard');
    });
  };

  return (
    <div className={css(contentContainerStyles)}>
      <CommonHeader
        title={t('paycardManagement:paycardManagement.orderNewCards.sectionHeading')}
        backTo="/paycard"
      />
      <WizardContainer
        nextButtonDisabled={nextButtonDisabledCondition}
        onAction={() => { }}
        onFinish={handleFinish}
        onCancel={onCancel}
        showCancelButton
      >
        <WizardTabulation tabs={subMenuHeadings} />
        {activeSection === 0 && (
          <OrderNewCardsOrderSection
            formValues={formValues}
            setFormValues={setFormValues}
          />
        )}
        {activeSection === 1 && (
          <ConfirmationSection
            values={formValues as OrderNewCardsValuesType}
          />
        )}
      </WizardContainer>
    </div>
  );
};

export default OrderNewCards;
