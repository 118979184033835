import { OrganizationIDType } from 'types/OrganizationTypes';
import { SetOrganizationDirectDepositSettingsRequest } from 'types/DirectDepositTypes';
import request from './request';

export const setOrganizationDirectDepositSettingsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: SetOrganizationDirectDepositSettingsRequest,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organization/${organizationID}`,
});

export const fetchOrganizationDirectDepositSettingsRequest = (
  token: string,
  organizationID: OrganizationIDType,
) => request({
  token,
  method: 'GET',
  url: `organization/${organizationID}`,
});
