import {
  Button,
  KIND,
} from 'baseui/button';
import AppChip from 'components/AppChip/AppChip';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { setAdministrator } from 'store/slices/administrators';
import {
  ModalNames,
  setModal,
} from 'store/slices/modals';
import { useStyletron } from 'styletron-react';
import {
  AdministratorRolesEnum,
  AdministratorType,
} from 'types/AdministratorsTypes';
import { userAccessUnitsSelector } from 'store/slices/user';
import { AccessUnit } from 'components/Access/Access';
import {
  detailsStyles,
  listItemInfoStyles,
  listItemNameAndStatuses,
  listItemStyles,
  listStyles,
} from './AdministratorsHelpers';

const AdministratorListItem = ({
  admin,
}: {
  admin: AdministratorType,
}) => {
  const { t } = useTranslation(['common', 'employees']);
  const [css] = useStyletron();
  const dispatch = useAppDispatch();
  const {
    firstName,
    lastName,
    email,
    roles,
    organizations,
    locations,
  } = admin;
  const editorRoles = useAppSelector(userAccessUnitsSelector);
  const ewaManagerWithNoNeededEditorRole = admin.roles.includes(AdministratorRolesEnum.EWA_MANAGER) && (
    (!editorRoles.includes(AccessUnit.FBOManager) && admin.roles.includes(AdministratorRolesEnum.FBO_MANAGER))
    || (!editorRoles.includes(AccessUnit.TreasuryReader) && admin.roles.includes(AdministratorRolesEnum.TREASURY_READER))
    || (!editorRoles.includes(AccessUnit.TreasuryManager) && admin.roles.includes(AdministratorRolesEnum.TREASURY_MANAGER))
    || (!editorRoles.includes(AccessUnit.PaycardClientManager) && admin.roles.includes(AdministratorRolesEnum.PAYCARD_CLIENT_MANAGER))
  );
  const ewaClientManagerWithNoNeededEditorRole = admin.roles.includes(AdministratorRolesEnum.EWA_CLIENT_MANAGER) && (
    !editorRoles.includes(AccessUnit.PaycardClientManager) && admin.roles.includes(AdministratorRolesEnum.PAYCARD_CLIENT_MANAGER)
  );

  const openEditModal = () => {
    dispatch(setModal({
      name: ModalNames.EDIT_ADMIN_MODAL,
      isOpen: true,
    }));
    dispatch(setAdministrator(admin));
  };

  return (
    <ul className={css(listStyles)}>
      <li className={css(listItemStyles)}>
        <div className={css(listItemNameAndStatuses)} key={`${admin?.id}-name-tag`}>
          <Button
            kind={KIND.tertiary}
            overrides={{
              Root: {
                props: {
                  id: `AdministratorListItem-admin-${admin?.id}-title`,
                },
                style: {
                  cursor: 'default',
                  color: '#000',
                },
              },
            }}
          >
            <span className="ellipsis">{`${firstName} ${lastName}`}</span>
          </Button>
          <Button
            kind={KIND.secondary}
            onClick={openEditModal}
            disabled={ewaManagerWithNoNeededEditorRole || ewaClientManagerWithNoNeededEditorRole}
            overrides={{
              Root: {
                props: {
                  id: `AdministratorListItem-edit-roles-${admin?.id}`,
                },
              },
            }}
          >
            {t('administrators:editRoles')}
          </Button>
        </div>
        <div className={css(listItemInfoStyles)} key={`${admin.id}-info-card`}>
          <div key={`${admin.id}-info-email`}>
            <span className={css(detailsStyles)}>
              {t('administrators:email')}
              :
              <AppChip items={email} />
            </span>
            <div key={`${admin.id}-info-orgs`}>
              {organizations && organizations.length !== 0
                ? organizations.map((org) => (
                  <div key={`${admin.id}-${org.id}-info-text`}>
                    <span className={css(detailsStyles)}>
                      {t('administrators:organizationId')}
                      :
                      <AppChip items={org?.id} />
                    </span>
                    <span className={css(detailsStyles)}>
                      {t('administrators:organizationName')}
                      :
                      <AppChip
                        blockProps={{
                          overrides: {
                            Block: {
                              style: {
                                '@media screen and (min-width: 1000px)': {
                                  maxWidth: '1000px',
                                },
                              },
                            },
                          },
                        }}
                        items={org?.name}
                      />
                    </span>
                  </div>
                ))
                : null}
            </div>
            <div key={`${admin.id}-info-location`}>
              {locations && locations.length !== 0
                ? (
                  <span className={css(detailsStyles)}>
                    {t('administrators:locationName')}
                    :
                    {locations.map((loc) => (
                      <AppChip
                        key={`${loc.id}-info-location-app-chip`}
                        blockProps={{
                          overrides: {
                            Block: {
                              style: {
                                '@media screen and (min-width: 1000px)': {
                                  maxWidth: '1000px',
                                },
                              },
                            },
                          },
                        }}
                        items={loc?.name}
                      />
                    ))}
                  </span>
                )
                : null}
            </div>
          </div>
        </div>
        {roles?.length ? (
          <div className={css(listItemInfoStyles)} key={`${admin.id}-info-roles`}>
            <span className={css(detailsStyles)}>
              {t('administrators:admin.item.role.label')}
              :
              {roles
                .filter((role) => role === AdministratorRolesEnum.EWA_MANAGER
                || role === AdministratorRolesEnum.EWA_CLIENT_MANAGER
                || role === AdministratorRolesEnum.EWA_CLIENT_LOCATION_MANAGER)
                .map((profile) => (
                  <AppChip
                    key={profile}
                    items={t(`administrators:admin.role.${profile}.label`)}
                    hoverItem={`Permissions: ${roles.map((role: string | null) => t(`administrators:${role}`)).toString().replace(/,/g, ', ')}`}
                  />
                ))}
            </span>
          </div>
        ) : null}
      </li>
    </ul>
  );
};

export default AdministratorListItem;
