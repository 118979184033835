import { AccountInfo } from '@azure/msal-browser';

const handleRedirectPromise = async (
  msalInstance: any,
  resp: { account: AccountInfo | null } | null,
) => {
  if (resp !== null) {
    msalInstance.setActiveAccount(resp.account);
  } else {
    const currentAccounts = msalInstance.getAllAccounts();

    if (currentAccounts.length > 1) {
      // Add choose account code here
    } else if (currentAccounts.length === 1) {
      const activeAccount = currentAccounts[0];

      msalInstance.setActiveAccount(activeAccount);
    }
  }
};

export default handleRedirectPromise;
