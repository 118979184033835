import * as Yup from 'yup';
import { commonAmountValidationSchema } from './commonSchema';
import messages from './messages';

export const repaymentValidationSchema = Yup.object().shape({
  referenceID: Yup.string().max(255, messages?.maxLength).required(messages?.required),
  paymentAmount: commonAmountValidationSchema(),
  dateReceived: Yup.date(),
});

export const tpoRepaymentValidationSchema = Yup.object().shape({
  referenceID: Yup.string().max(255, messages?.maxLength).required(messages?.required),
  dateReceived: Yup.date(),
});

export const other = undefined;
