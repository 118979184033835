import { ReactNode } from 'react';
import {
  Cell,
  ALIGNMENT,
  Responsive,
  CellProps,
  Alignment,
} from 'baseui/layout-grid';
import {
  FormControlProps,
} from 'baseui/form-control';
import AppFormControl from './Form/AppFormControl';

export type CellFormControlPropsType = {
  children: ReactNode
  error?: boolean | ReactNode;
  label?: any
  cellSpan?: Responsive<number> | undefined
  cellAlign?: Alignment | undefined
  cellProps?: CellProps
  formControlProps?: Pick<FormControlProps, Exclude<keyof FormControlProps, 'children'>>
  showStar?: boolean
  tooltipTitle?: ReactNode
  tooltipContent?: ReactNode
}

const CellFormControl = ({
  children,
  error,
  label,
  cellSpan = 3,
  cellAlign = ALIGNMENT.start,
  cellProps,
  formControlProps,
  showStar,
  tooltipContent,
  tooltipTitle,
}: CellFormControlPropsType) => (
  <Cell
    span={cellSpan}
    align={cellAlign}
    {...cellProps}
  >
    <AppFormControl
      showStar={showStar}
      label={label}
      error={error}
      tooltipContent={tooltipContent}
      tooltipTitle={tooltipTitle}
      {...formControlProps}
    >
      {children}
    </AppFormControl>
  </Cell>
);

export default CellFormControl;
