interface EnvironmentVariables {
  baseURL: string
  bankingBaseUrl: string
  tnaBaseUrl: string
  directDepositBaseUrl: string
  workerProfileBaseUrl: string
  universalCalendarUrl: string
  repaymentsBaseUrl: string
  namesSignUpSignIn: string
  authoritiesSignUpSignInAuthority: string
  authorityDomain: string
  authClientId: string
  authRedirectUrl: string
  adminAPIWriteScope: string
  appInsightsInstrumentationKey: string
  buildNumber: string,
  exposeSpecificFeatures: string,
}

const development: EnvironmentVariables = {
  baseURL: 'https://admin-dev.fuegosecure.com/api',
  bankingBaseUrl: 'https://admin-dev.fuegosecure.com/banking-api',
  tnaBaseUrl: 'https://admin-dev.fuegosecure.com/tna-api',
  directDepositBaseUrl: 'https://admin-dev.fuegosecure.com/direct-deposits-api',
  workerProfileBaseUrl: 'https://admin-dev.fuegosecure.com/worker-profile-api',
  universalCalendarUrl: 'https://admin-dev.fuegosecure.com/universal-calendar-api',
  repaymentsBaseUrl: 'https://admin-dev.fuegosecure.com/repayments-api',
  namesSignUpSignIn: 'B2C_1A_ADMINSITE_SIGNIN',
  authoritiesSignUpSignInAuthority: 'https://login-dev.fuegosecure.com/852a28c8-2888-4fc8-8a36-ed2b62028b57/B2C_1A_ADMINSITE_SIGNIN',
  authorityDomain: 'login-dev.fuegosecure.com',
  authClientId: '129ee265-a2a8-4232-a2b5-7ec4b34ad7b1',
  authRedirectUrl: 'http://localhost:3000',
  adminAPIWriteScope: 'https://squirrelnonprod.onmicrosoft.com/admin-api/admin.write',
  appInsightsInstrumentationKey: '1bd73182-f282-49d2-9459-18bd7be8146f',
  buildNumber: '',
  exposeSpecificFeatures: '',
};

const test: EnvironmentVariables = {
  baseURL: '',
  bankingBaseUrl: '',
  tnaBaseUrl: '',
  directDepositBaseUrl: '',
  workerProfileBaseUrl: '',
  universalCalendarUrl: '',
  repaymentsBaseUrl: '',
  namesSignUpSignIn: '',
  authoritiesSignUpSignInAuthority: '',
  authorityDomain: '',
  authClientId: '',
  authRedirectUrl: '',
  adminAPIWriteScope: '',
  appInsightsInstrumentationKey: '',
  buildNumber: '',
  exposeSpecificFeatures: '',
};

const production: EnvironmentVariables = {
  baseURL: '#{baseURL}',
  bankingBaseUrl: '#{bankingBaseUrl}',
  tnaBaseUrl: '#{tnaBaseUrl}',
  directDepositBaseUrl: '#{directDepositBaseUrl}',
  workerProfileBaseUrl: '#{workerProfileBaseUrl}',
  universalCalendarUrl: '#{universalCalendarUrl}',
  repaymentsBaseUrl: '#{repaymentsBaseUrl}',
  namesSignUpSignIn: '#{namesSignUpSignIn}',
  authoritiesSignUpSignInAuthority: '#{authoritiesSignUpSignInAuthority}',
  authorityDomain: '#{authorityDomain}',
  authClientId: '#{authClientId}',
  authRedirectUrl: '#{authRedirectUrl}',
  adminAPIWriteScope: '#{adminAPIWriteScope}',
  appInsightsInstrumentationKey: '#{appInsightsInstrumentationKey}',
  buildNumber: '#{buildNumber}',
  exposeSpecificFeatures: '#{exposeSpecificFeatures}',
};

const environment = {
  development,
  production,
  test,
};

export default environment[process.env.NODE_ENV];
