import { LocationIDType } from 'types/LocationTypes';
import { OrganizationIDType } from 'types/OrganizationTypes';
import request from './request';

export const importTipsFileRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
  formData: FormData,
) => request({
  token,
  method: 'POST',
  payload: formData,
  url: `tips/organizations/${organizationID}/locations/${locationID}`,
});

export const other = null;
