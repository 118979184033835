import { borderRadius } from 'theme';

export const listStyles = {
  padding: '0px',
};

export const listItemStyles = {
  background: '#FFFFFF',
  'list-style': 'none',
  padding: '8px',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
};

export const customerListItemNameAndStatusesStyles = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '4px',
  '@media screen and (max-width: 768px)': {
    'flex-direction': 'column',
  },
};

export const listItemInfoStyles = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '0px 4px 16px 20px',
  '@media screen and (max-width: 768px)': {
    'flex-direction': 'column',
    alignItems: 'start',
    gap: '8px',
  },
};

export const detailsStyles = {
  marginRight: '16px',
  display: 'inline-block',
  marginTop: '4px',
};

export const customerDetailsValue = {
  'margin-left': '5px',
  'font-weight': 'bold',
};

export const statusBadgeStyles = {
  background: '#E0F1E5',
  color: '#4A5456',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  padding: '7px',
  'margin-left': '10px',
  'font-weight': '700',
  'font-size': '14px',
  '@media screen and (max-width: 768px)': {
    alignSelf: 'flex-start',
    margin: '8px 0 8px 8px',
  },
};

export const warningStatusBadgeStyles = {
  background: '#FFFAF0',
  labelColor: '#674D1B',
};
