import {
  createContext,
  useEffect,
} from 'react';
import { Layer } from 'baseui/layer';
import * as Yup from 'yup';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, ALIGNMENT } from 'baseui/layout-grid';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import AppModal from 'components/AppModal/AppModal';
import AppInput from 'components/Form/AppInput';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import Loader from 'components/Loader';
import { Draw } from 'types/EmployeeTypes';
import { scalePrice } from 'utils/priceScale';
import messages from 'validation/messages';
import { createBadDebt } from 'store/slices/employees';

export const InitiateACFFormContext = createContext(
  {} as FormikState<InitiateACFValuesType> & FormikHelpers<InitiateACFValuesType> & FormikHandlers,
);
export const InitiateACFInitialValues = {
  amount: 0,
};
export type InitiateACFValuesType = typeof InitiateACFInitialValues;
export type InitiateACFModalPropsType = {
  draw: Draw | undefined,
  maxAmount: number,
};

const InitiateBadDebtModal = ({
  draw,
  maxAmount,
}: InitiateACFModalPropsType) => {
  if (!draw) return null;
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['employees', 'organizations', 'errors', 'common', 'locations']);
  const modals = useAppSelector(modalsSelector);
  const modalName = ModalNames.BAD_DEBT_MODAL;
  const isModalOpen = checkIsModalOpen(modals, modalName);

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const onSubmit = (
    values: InitiateACFValuesType,
  ) => {
    dispatch(createBadDebt({
      drawId: draw.id,
      employmentId: draw.employmentId,
      data: {
        amount: {
          ...draw.subElements[0].amount,
          value: scalePrice(values.amount, draw.subElements[0].amount.scale),
        },
      },
    }))
      .then(() => {
        setIsModalOpen(false);
      });
  };

  const formik = useFormik({
    initialValues: InitiateACFInitialValues,
    validationSchema: Yup.object().shape({
      amount: Yup.number().typeError(messages.mustBeANumber)
        .min(0, messages.positiveNumbersOnly)
        .required(messages?.numberRequired),
    }),
    onSubmit,
  });

  const {
    values,
    resetForm,
    setErrors,
    isSubmitting,
    setSubmitting,
    isValid,
    errors,
    handleSubmit,
  } = formik;

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      setSubmitting(false);
      resetForm();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (Number(maxAmount.toFixed(2)) < values.amount) {
      setErrors({
        ...errors,
        amount: t('errors:initAcfValueTooHigh', { amount: `${maxAmount.toFixed(2)} ${draw.subElements[0].amount.currencyCode}` }),
      });
    }
  }, [values, errors]);

  return (
    <Layer index={300}>
      <InitiateACFFormContext.Provider value={formik}>
        <form>
          <AppModal
            modal={modalName}
            title={t('employees:initBadDebtTitle')}
            cancelBtnText={t('common:cancel')}
            onClose={handleModalClose}
            actionBtnText={t('common:save')}
            onAction={handleSubmit}
            isActionDisabled={!values.amount || !isValid}
            isCloseDisabled={isSubmitting}
          >
            <Loader active={false} />

            <Grid
              align={ALIGNMENT.start}
              gridMargins={18}
            >
              <AppInput
                name="amount"
                label={t('employees:amount')}
                cellSpan={6}
                context={InitiateACFFormContext}
                inputProps={{
                  id: 'amount',
                  autoComplete: 'off',
                  type: 'number',
                  endEnhancer: draw?.subElements?.[0]?.amount.currencyCode,
                }}
              />

            </Grid>
          </AppModal>
        </form>
      </InitiateACFFormContext.Provider>
    </Layer>
  );
};

export default InitiateBadDebtModal;
