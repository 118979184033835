import {
  ChangeEvent,
  memo, useEffect, useState,
} from 'react';
import { useStyletron } from 'baseui';
import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { contentLocationContainerStyles } from 'screens/TipManagement/TipManagementHelper';
import { HeadingXSmall } from 'baseui/typography';
import {
  OnChangeParams,
  Option,
  SIZE,
  Select,
  Value,
} from 'baseui/select';
import CellFormControl from 'components/CellFormControl';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { fetchOrganizationPaycardsFilterMapper } from 'dataMappers/paycardManagementMapper';
import {
  fetchOrganizationPaycards,
  paycardsListSearchOptionTypeSelector,
  paycardsListSearchValueSelector,
  resetPaycardsSearchOption,
  resetPaycardsSearchValue,
  setPaycardsSearchOption,
  setPaycardsSearchValue,
} from 'store/slices/paycards';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';
import PaycardManagementPaycardsTable from './PaycardsTable/PaycardManagementPaycardsTable';

const PaycardManagementPaycardsSection = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'paycardManagement']);
  const dispatch = useAppDispatch();
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const searchOption = useAppSelector(paycardsListSearchOptionTypeSelector);
  const searchValue = useAppSelector(paycardsListSearchValueSelector);
  const [searchDebounce, setSearchDebounce] = useState<string>('');
  const searchOptionIsNotSelected = Array.isArray(searchOption)
    && searchOption.length === 0;

  const options: Option[] = [
    {
      id: 0,
      name: t('paycardManagement:paycardManagement.paycards.searchOptions.employeeName'),
    },
    {
      id: 1,
      name: t('paycardManagement:paycardManagement.paycards.searchOptions.payrollId'),
    },
    {
      id: 2,
      name: t('paycardManagement:paycardManagement.paycards.searchOptions.proxyNumber'),
    },
  ];

  const fetchPaycards = (
    pageNum: number = 1,
  ) => {
    dispatch(fetchOrganizationPaycards({
      organizationId: loggedOrganization?.id,
      pageSize: 5,
      pageNum,
      filter: searchOptionIsNotSelected
        ? ''
        : fetchOrganizationPaycardsFilterMapper(searchValue, searchOption[0]),
    }));
  };

  useEffect(() => {
    dispatch(resetPaycardsSearchOption());
    dispatch(resetPaycardsSearchValue());
  }, []);

  useEffect(() => {
    searchOptionIsNotSelected && fetchPaycards();
  }, [searchOptionIsNotSelected]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchDebounce(searchValue);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === searchDebounce && !searchOptionIsNotSelected) {
      fetchPaycards();
    }
  }, [searchDebounce]);

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    fetchPaycards(nextPage);
  };

  const handleFilterTypeChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(setPaycardsSearchOption(value));
    if (value.length === 0) {
      dispatch(setPaycardsSearchValue(''));
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<InputCustomHTMLElement>) => {
    dispatch(setPaycardsSearchValue(e.target.value));
  };

  return (
    <div className={css(contentLocationContainerStyles)}>
      <Grid gridColumns={12} gridMargins={24}>
        <Cell span={12}>
          <HeadingXSmall marginTop="24px" marginBottom="24px">
            {t('paycardManagement:paycardManagement.paycards.sectionHeading')}
          </HeadingXSmall>
        </Cell>
        <CellFormControl
          cellSpan={[12, 4, 2.5]}
          label={t('common:searchBy')}
        >
          <Select
            size={SIZE.compact}
            id="paycards-search-select"
            clearable
            placeholder={t('common:select')}
            type="select"
            options={options.map((option) => ({
              id: option.id,
              name: option.name,
            }))}
            labelKey="name"
            valueKey="id"
            onChange={handleFilterTypeChange}
            value={searchOption as Value}
            maxDropdownHeight="300px"
            overrides={{
              ControlContainer: {
                props: {
                  'data-testid': 'Paycards-search-select',
                },
              },
            }}
          />
        </CellFormControl>

        <CellFormControl
          cellSpan={[12, 4, 2.5]}
        >
          <Input
            size={SIZE.compact}
            startEnhancer={<Search />}
            type="text"
            name="search"
            value={searchValue}
            onChange={handleSearchInputChange}
            placeholder={t('common:searchPlaceholder')}
            clearable
            disabled={searchOptionIsNotSelected}
            overrides={{
              Root: {
                style: {
                  paddingLeft: '0',
                  marginTop: '28px',
                },
              },
              Input: {
                style: {
                  paddingLeft: '0',
                },
                props: {
                  'data-testid': 'Paycards-search-input',
                },
              },
            }}
          />
        </CellFormControl>
      </Grid>
      <PaycardManagementPaycardsTable handlePageChange={handlePageChange} />
    </div>
  );
};

export default memo(PaycardManagementPaycardsSection);
