import { FetchWorkersFilterType, WorkerIDType } from 'types/WorkerTypes';
import request from './request';

export const fetchWorkersRequest = (
  token: string,
  filter?: FetchWorkersFilterType,
) => {
  const queryParams = filter ? new URLSearchParams(filter)?.toString() : '';

  return request({
    token,
    url: `workers?${queryParams}`,
  });
};

export const fetchWorkerEmploymentsRequest = (
  token: string,
  workerID: WorkerIDType,
) => request({
  token,
  url: `workers/${workerID}/employments`,
});
