import { ChangeEvent, Key, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import { borderRadius } from 'theme';
import {
  Accordion,
  Panel,
} from 'baseui/accordion';
import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  OrganizationFormSectionPropsType,
  OrganizationSectionProgressStatusType,
} from 'types/OrganizationTypes';
import {
  Checkbox,
  LABEL_PLACEMENT,
  STYLE_TYPE,
} from 'baseui/checkbox';
import { toggleOverrides } from 'components/Form/AppCheckbox';

export const statefulPopoverOverrides = {
  Root: {
    style: {
      display: 'block',
      'text-align': 'right',
    },
  },
};

export const panelStyles = {
  display: 'flex',
  'justify-content': 'space-between',
  svg: {
    'margin-right': '15px',
  },
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
};

const AccordionSection = ({
  children,
  title,
  status = {
    sectionID: undefined,
    name: OrganizationSectionProgressStatusType.IN_PROGRESS,
  },
  saveStatus,
  disabled,
  showStatus = true,
  expanded = false,
  onExpand,
  disabledStatus,
}: OrganizationFormSectionPropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['organizations']);
  const panelKey = 'P1';
  const isStatusChecked = status?.name === OrganizationSectionProgressStatusType.COMPLETE;

  const accordionOverrides = {
    Root: {
      props: {
        id: `AccordionSection-${title?.replace(/[\s]/i, '-')}-header`,
      },
    },
    Header: {
      style: {
        position: 'relative',
        display: 'block',
        'text-align': 'right',
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        background: 'green !important',
      } as StyleObject,
    },
  };

  const handleToggleStatus = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.currentTarget;

    saveStatus && saveStatus({
      sectionID: status?.sectionID,
      sectionName: status?.sectionName,
      status: checked
        ? OrganizationSectionProgressStatusType.COMPLETE
        : OrganizationSectionProgressStatusType.IN_PROGRESS,
    });
  };

  const handleOnAccordionChange = ({
    expanded: expandedArr,
  }: {
    expanded: Key[]
  }) => {
    if (expandedArr?.includes(panelKey)) {
      onExpand && onExpand();
    }
  };

  return (
    <Grid>
      <Cell
        span={12}
      >
        <Accordion
          renderAll
          disabled={disabled}
          overrides={accordionOverrides}
          initialState={{
            expanded: expanded ? [panelKey] : [],
          }}
          onChange={handleOnAccordionChange}
        >
          <Panel
            overrides={{
              Header: {
                style: {
                  borderBottomLeftRadius: borderRadius,
                  borderBottomRightRadius: borderRadius,
                  borderTopLeftRadius: borderRadius,
                  borderTopRightRadius: borderRadius,
                  display: 'block',
                  position: 'relative',
                  textAlign: 'right',
                },
              },
              Content: {
                style: {
                  paddingTop: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              },
              PanelContainer: {
                style: {
                  marginLeft: 0,
                  marginRight: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              },
            }}
            key={panelKey}
            title={(
              <>
                <div
                  className={css(panelStyles)}
                >
                  {title}
                  {showStatus && (
                    <div
                      id="AccordionSection-status-btn"
                      data-testid="AccordionSection-status-btn"
                      role="button"
                      onClick={(e) => { e.stopPropagation(); }}
                      onKeyUp={(e) => { e.stopPropagation(); }}
                      tabIndex={0}
                    >
                      <Checkbox
                        checked={isStatusChecked}
                        labelPlacement={LABEL_PLACEMENT.right}
                        onChange={handleToggleStatus}
                        disabled={disabledStatus}
                        checkmarkType={STYLE_TYPE.toggle_round}
                        overrides={{
                          ...toggleOverrides({
                            checked: isStatusChecked,
                          }),
                          Input: {
                            props: {
                              id: 'AccordionSection-status-checkbox',
                              'data-testid': 'AccordionSection-status-checkbox',
                              'aria-checked': `${isStatusChecked}`,
                            },
                          },
                        }}
                      >
                        {t(`organizations:sectionStatuses.${OrganizationSectionProgressStatusType.COMPLETE}`)}
                      </Checkbox>
                    </div>
                  )}
                </div>
                <hr />
              </>
            )}
          >
            {children}
          </Panel>
        </Accordion>
      </Cell>
    </Grid>
  );
};

export default memo(AccordionSection);
