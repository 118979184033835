import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import { DatePicker } from 'baseui/datepicker';
import { SIZE } from 'baseui/input';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  OnChangeParams,
  Select,
  Value,
} from 'baseui/select';
import AppModal from 'components/AppModal/AppModal';
import CellFormControl from 'components/CellFormControl';
import { datePickerOverrides } from 'components/Form/AppDatePicker';
import Loader from 'components/Loader';
import {
  memo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  ModalNames,
  setModal,
} from 'store/slices/modals';
import {
  fetchUniversalCalendarDailyShifts,
  resetSearchUniversalCalendarShifts,
  resetUniversalCalendarDailyShiftsList,
  resetUniversalCalendarOpenShiftsList,
  setSearchUniversalCalendarShiftsDate,
  setSearchUniversalCalendarShiftsLocation,
  setSearchUniversalCalendarShiftsOrganization,
  setSelectedWorkerID,
  universalCalendarDailyShiftsIsSupportedSelector,
  universalCalendarDailyShiftsListSelector,
  universalCalendarDailyShiftsPendingSelector,
  universalCalendarSearchDateSelector,
  universalCalendarSearchLocationSelector,
  universalCalendarSearchOrganizationSelector,
} from 'store/slices/universalCalendar';
import { DailyShifts } from 'types/WorkerTypes';
import { Layer } from 'baseui/layer';
import {
  fetchAllOrganizations,
  organizationsSelector,
} from 'store/slices/organizations';
import {
  fetchLocations,
  locationPendingListSelector,
  locationsSelector,
} from 'store/slices/locations';
import moment from 'moment';
import { OptionalDateOrDateArrayType } from 'types/CommonTypes';
import WorkerShiftsDateListItem from './WorkerShiftsDateListItem';

const WorkerShiftsModal = ({
  workerId,
}: { workerId: string }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'workers']);
  const modalName = ModalNames.WORKER_UNIVERSAL_CALENDAR_SHIFTS_MODAL;
  const pending = useAppSelector(universalCalendarDailyShiftsPendingSelector);
  const shiftsList = useAppSelector(universalCalendarDailyShiftsListSelector);
  const isUniversalCalendarSupported = useAppSelector(universalCalendarDailyShiftsIsSupportedSelector);
  const organizations = useAppSelector(organizationsSelector);
  const locations = useAppSelector(locationsSelector);
  const pendingLocations = useAppSelector(locationPendingListSelector);
  const selectedDate = useAppSelector(universalCalendarSearchDateSelector);
  const selectedLocationValue = useAppSelector(universalCalendarSearchLocationSelector);
  const selectedOrganizationValue = useAppSelector(universalCalendarSearchOrganizationSelector);

  const datePickerDateFormat = t('dateFormats:date-picker.standard');
  const dateFormatAPI = t('dateFormats:standard-reverse');

  const dateIn30Days = new Date();
  dateIn30Days.setDate(dateIn30Days.getDate() + 30);

  const datePickerOverridesExtended = (id: string) => ({
    ...datePickerOverrides,
    Input: {
      ...datePickerOverrides.Input,
      props: {
        onKeyDown: (event: any) => event.preventDefault(),
        overrides: {
          ...datePickerOverrides.Input.props.overrides,
        },
        id,
      },
    },
  });

  const handleOrganizationSelectChange = ({ value }: OnChangeParams) => {
    dispatch(setSearchUniversalCalendarShiftsOrganization(value));
    dispatch(resetUniversalCalendarDailyShiftsList());
    dispatch(resetUniversalCalendarOpenShiftsList());
    dispatch(setSearchUniversalCalendarShiftsLocation([]));
    if (value && value[0]) {
      dispatch(fetchLocations({
        organizationID: value[0].id?.toString(),
      }));
    }
  };

  const handleLocationChange = ({ value }: OnChangeParams): void => {
    dispatch(setSearchUniversalCalendarShiftsLocation(value));
    dispatch(resetUniversalCalendarOpenShiftsList());
  };

  const handleDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    if (date && !Array.isArray(date)) {
      dispatch(setSearchUniversalCalendarShiftsDate(date as Date));
    } else if (!date) {
      dispatch(setSearchUniversalCalendarShiftsDate(undefined));
    }
    dispatch(resetUniversalCalendarOpenShiftsList());
  };

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetUniversalCalendarOpenShiftsList());
    dispatch(resetSearchUniversalCalendarShifts());
  };

  useEffect(() => {
    dispatch(fetchUniversalCalendarDailyShifts({
      workerID: workerId,
      organizationID: selectedOrganizationValue?.[0]?.id?.toString() || undefined,
      locationID: selectedLocationValue?.[0]?.id?.toString() || undefined,
      fromDate: selectedDate ? moment(selectedDate).format(dateFormatAPI) : undefined,
    }));
  }, [selectedDate,
    selectedOrganizationValue,
    selectedLocationValue,
  ]);

  useEffect(() => {
    dispatch(setSelectedWorkerID(workerId));
    if (organizations && organizations.length < 51) {
      dispatch(fetchAllOrganizations({ pageSize: '1000' }));
    }
  }, []);

  return (
    <Layer index={400}>
      <AppModal
        modalWidth="70vw"
        minWidth="360px"
        maxWidth="100%"
        modal={modalName}
        title={t('workers:shiftsModalTitle')}
        onClose={handleModalClose}
      >
        <Loader active={pending} />
        <Grid
          gridColumns={12}
          gridMargins={16}
          align={ALIGNMENT.start}
        >
          <CellFormControl
            cellSpan={[12, 6, 4]}
            cellAlign={ALIGNMENT.start}
          >
            <DatePicker
              size={SIZE.compact}
              disabled={pending}
              clearable
              placeholder={t('workers:shifts.dateRange.search.placeholder')}
              formatString={datePickerDateFormat}
              value={selectedDate}
              onChange={handleDateChange}
              overrides={datePickerOverridesExtended('worker-universal-calendar-shifts-dateRange')}
              minDate={new Date()}
              maxDate={dateIn30Days}
            />
          </CellFormControl>
          <CellFormControl
            cellSpan={[12, 6, 4]}
            cellAlign={ALIGNMENT.start}
          >
            <Select
              size={SIZE.compact}
              disabled={pending}
              clearable
              id="select"
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'worker-universal-calendar-shifts-organization-select',
                    id: 'worker-universal-calendar-shifts-organization-select',
                  },
                },
                Input: {
                  props: {
                    'data-testid': 'worker-universal-calendar-shifts-organization-select',
                    id: 'worker-universal-calendar-shifts-organization-select',
                  },
                },
              }}
              options={
                organizations
                  ?.map((org) => ({
                    name: org.name,
                    id: org.id,
                  }))
                  ?.sort((a, b) => a.name.localeCompare(b.name))
              }
              placeholder={t('workers:shifts.organization.search.placeholder')}
              type="select"
              labelKey="name"
              valueKey="name"
              onChange={handleOrganizationSelectChange}
              value={selectedOrganizationValue as Value}
              maxDropdownHeight="300px"
            />
          </CellFormControl>
          <CellFormControl
            cellSpan={[12, 6, 4]}
            cellAlign={ALIGNMENT.start}
          >
            <Select
              size={SIZE.compact}
              disabled={!selectedOrganizationValue?.[0]?.id || pendingLocations || pending || !isUniversalCalendarSupported}
              clearable
              id="select"
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'worker-universal-calendar-shifts-location-select',
                    id: 'worker-universal-calendar-shifts-location-select',
                  },
                },
                Input: {
                  props: {
                    'data-testid': 'worker-universal-calendar-shifts-location-select',
                    id: 'worker-universal-calendar-shifts-location-select',
                  },
                },
              }}
              options={locations?.map(({
                name, id, payGroup, timezone, externalLocationId, tipsProviderId,
              }) => ({
                name, id, payGroup, timezone, externalLocationId, tipsProviderId,
              }))}
              placeholder={t('workers:shifts.location.search.placeholder')}
              type="select"
              labelKey="name"
              valueKey="name"
              onChange={handleLocationChange}
              value={selectedLocationValue as Value}
              maxDropdownHeight="300px"
            />
          </CellFormControl>
        </Grid>

        <Cell
          span={12}
        >
          {isUniversalCalendarSupported === false && !pending && (
          <Block
            marginTop="8px"
            marginBottom="8px"
          >
            <Grid gridMargins={0}>
              <Cell span={12}>
                <Card>
                  <p>Universal Calendar is not enabled</p>
                </Card>
              </Cell>
            </Grid>
          </Block>
          )}
          {isUniversalCalendarSupported === true && !pending && shiftsList?.length > 0 && (
          <Block
            marginTop="8px"
            marginBottom="8px"
          >
            {shiftsList?.map((shift: DailyShifts) => <WorkerShiftsDateListItem key={shift.date} dailyShifts={shift} />)}
          </Block>
          )}
          {isUniversalCalendarSupported === true && !pending && shiftsList?.length === 0 && (
          <Block
            marginTop="8px"
            marginBottom="8px"
          >
            <Grid gridMargins={0}>
              <Cell span={12}>
                <Card>
                  <p>{t('workers:noShiftsAvailable')}</p>
                </Card>
              </Cell>
            </Grid>
          </Block>
          )}
        </Cell>
      </AppModal>
    </Layer>
  );
};

export default memo(WorkerShiftsModal);
