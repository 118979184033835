import { TipsFilesFilterType } from 'types/TnaTypes';
import request from './request';

export const fetchTipsFilesRequest = (
  token: string,
  filter: TipsFilesFilterType,
) => {
  const queryParams = new URLSearchParams(filter)?.toString();
  return request({
    token,
    url: `tips/files?${queryParams}`,
  });
};

export const other = null;
