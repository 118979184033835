import { Cell, Grid } from 'baseui/layout-grid';
import { LabelMedium, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import AppInput from 'components/Form/AppInput';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import {
  paycardManagementOrderNewCardsInitialValues,
} from 'initialValues/PaycardsInitialValues';
import { createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { paycardManagementSelectedCardStockSelector, setIsOrderNewCardsFormValid } from 'store/slices/paycards';
import { emptyPlaceholder } from 'theme';
import { OrderNewCardsValuesType } from 'types/PaycardManagementTypes';
import {
  orderNewCardsValidationSchema as validationSchema,
} from 'validation/paycardManagementSchema';

export const OrderNewCardsOrderFormContext = createContext(
  {} as FormikState<OrderNewCardsValuesType> & FormikHelpers<OrderNewCardsValuesType> & FormikHandlers,
);

type Props = {
  formValues: OrderNewCardsValuesType | {},
  setFormValues: (values: OrderNewCardsValuesType | {}) => void,
}

const OrderNewCardsOrderSection = (
  {
    formValues,
    setFormValues,
  }: Props,
) => {
  const { t } = useTranslation(['common', 'paycardManagement']);
  const dispatch = useAppDispatch();
  const cardStock = useAppSelector(paycardManagementSelectedCardStockSelector);

  const initialValues = {
    ...paycardManagementOrderNewCardsInitialValues,
    ...formValues,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => { },
    validationSchema,
  });

  const {
    values,
    isValid,
    validateForm,
    errors,
    touched,
  } = formik;

  useEffect(() => {
    dispatch(setIsOrderNewCardsFormValid(isValid));
  }, [isValid]);

  useEffect(() => {
    setFormValues(values);
  }, [values, setFormValues]);

  useEffect(() => {
    validateForm(initialValues);
  }, []);

  return (
    <form>
      <OrderNewCardsOrderFormContext.Provider value={formik}>
        <Grid
          gridColumns={12}
          gridMargins={[12, 24]}
        >
          <LabelMedium margin="18px 18px 0 18px">
            {t('paycardManagement:paycardManagement.orderNewCards.cardAmountHeader')}
          </LabelMedium>
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
          overrides={{
            Grid: {
              style: {
                flexDirection: 'column',
              },
            },
          }}
        >
          <AppInput
            name="cardsCount"
            inputProps={{
              id: 'cardsCount',
            }}
            formControlProps={{ htmlFor: 'cardsCount' }}
            cellSpan={[12, 6, 2.5]}
            context={OrderNewCardsOrderFormContext}
          />
          <Cell span={[12, 6, 2.5]}>
            <ParagraphSmall color="#757575" marginTop={0} hidden={!!errors.cardsCount && !!touched.cardsCount}>
              {`
                ${t('paycardManagement:paycardManagement.orderNewCards.currentStockLabel')}: ${cardStock?.availableCards}, 
                ${t('paycardManagement:paycardManagement.orderNewCards.thresholdLabel')}: ${cardStock?.threshold}
              `}
            </ParagraphSmall>
          </Cell>
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={[12, 24]}
        >
          <LabelMedium margin="18px">
            {t('paycardManagement:paycardManagement.orderNewCards.shippingInfoHeader')}
          </LabelMedium>
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <AppInput
            name="addressee"
            inputProps={{
              id: 'addressee',
            }}
            formControlProps={{ htmlFor: 'addressee' }}
            cellSpan={[12, 6, 2.5]}
            context={OrderNewCardsOrderFormContext}
            label={t('paycardManagement:paycardManagement.orderNewCards.addresseeLabel')}
          />
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <AppInput
            name="attention"
            inputProps={{
              id: 'attention',
            }}
            formControlProps={{ htmlFor: 'attention' }}
            cellSpan={[12, 6, 2.5]}
            context={OrderNewCardsOrderFormContext}
            label={t('paycardManagement:paycardManagement.orderNewCards.attentionLabel')}
          />
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <AppInput
            name="phone"
            inputProps={{
              id: 'phone',
            }}
            formControlProps={{ htmlFor: 'phone' }}
            cellSpan={[12, 6, 2.5]}
            context={OrderNewCardsOrderFormContext}
            label={t('paycardManagement:paycardManagement.orderNewCards.phoneLabel')}
          />
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <Cell span={[12, 6, 3]}>
            <LabelMedium marginTop="18px">
              {t('paycardManagement:paycardManagement.orderNewCards.addressHeader')}
            </LabelMedium>
            <ParagraphMedium
              marginTop="4px"
              marginBottom="0px"
            >
              {cardStock?.locationAddress.addressLine1 || emptyPlaceholder}
            </ParagraphMedium>
            <ParagraphMedium margin={0}>
              {cardStock?.locationAddress.city || emptyPlaceholder}
              {', '}
              {cardStock?.locationAddress.state || emptyPlaceholder}
            </ParagraphMedium>
            <ParagraphMedium margin={0}>
              {cardStock?.locationAddress.postalCode || emptyPlaceholder}
            </ParagraphMedium>
          </Cell>
        </Grid>
      </OrderNewCardsOrderFormContext.Provider>
    </form>
  );
};

export default OrderNewCardsOrderSection;
