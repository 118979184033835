import { OrganizationIDType } from 'types/OrganizationTypes';
import { RepaymentIDType } from 'types/RepaymentTypes';
import request from './request';

export const fetchBatchAllocationsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  pageNumber: string,
  payGroupId?: string,
  payrollPeriodId?: string,
) => {
  const queryParams = new URLSearchParams({
    pageNumber,
  });

  payGroupId && queryParams.set('payGroupId', payGroupId);
  payrollPeriodId && queryParams.set('payrollPeriodId', payrollPeriodId);

  return request({
    token,
    url: `organizations/${organizationID}/batch-allocations?${queryParams.toString()}`,
  });
};

export const fetchAllocatedBatchAllocationsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  repaymentId: RepaymentIDType,
  pageNumber: string,
) => request({
  token,
  url: `organizations/${organizationID}/repayments/${repaymentId}/batch-allocations?pageNumber=${pageNumber}`,
});
