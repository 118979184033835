import {
  BankFilesFilterType,
} from 'types/BankFilesTypes';
import request from './request';

export const fetchBankFilesRequest = (
  token: string,
  filter: BankFilesFilterType,
) => {
  const queryParams = new URLSearchParams(filter)?.toString();
  return request({
    token,
    url: `bank-files?${queryParams}`,
  });
};

export const other = null;
