import { emptyPlaceholder } from 'theme';
import { useTranslation } from 'react-i18next';
import { OrderNewCardsValuesType } from 'types/PaycardManagementTypes';
import { Cell, Grid } from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import { LabelMedium, ParagraphMedium } from 'baseui/typography';
import { useAppSelector } from 'store/hooks';
import { paycardManagementSelectedCardStockSelector, paycardOrderNewCardsPendingSelector } from 'store/slices/paycards';
import Loader from 'components/Loader';

type Props = {
  values: OrderNewCardsValuesType,
}

const ConfirmationSection = ({ values }: Props) => {
  const { t } = useTranslation(['common', 'paycardManagement']);
  const cardStock = useAppSelector(paycardManagementSelectedCardStockSelector);
  const orderPending = useAppSelector(paycardOrderNewCardsPendingSelector);

  return (
    <Grid
      gridColumns={12}
      gridMargins={24}
      overrides={{
        Grid: {
          style: {
            padding: '18px',
          },
        },
      }}
    >
      <Cell span={[12, 6, 4]}>
        <Loader active={orderPending} />
        <Block>
          <LabelMedium>{t('paycardManagement:paycardManagement.orderNewCards.cardsOrderedLabel')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{values.cardsCount || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.orderNewCards.addresseeLabel')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{values.addressee || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.orderNewCards.attentionLabel')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{values.attention || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.orderNewCards.phoneLabel')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{values.phone || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.orderNewCards.addressHeader')}</LabelMedium>
          <ParagraphMedium marginTop="4px" marginBottom="0px">{cardStock?.locationAddress.addressLine1 || emptyPlaceholder}</ParagraphMedium>
          <ParagraphMedium margin={0}>{`${cardStock?.locationAddress.city}, ${cardStock?.locationAddress.state}`}</ParagraphMedium>
          <ParagraphMedium margin={0}>{cardStock?.locationAddress.postalCode}</ParagraphMedium>
        </Block>
      </Cell>
    </Grid>
  );
};

export default ConfirmationSection;
