import { useTranslation } from 'react-i18next';
import { Layer } from 'baseui/layer';
import { ModalNames, setModal } from 'store/slices/modals';
import AppModal from 'components/AppModal/AppModal';
import { Cell, Grid } from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import { useAppDispatch } from 'store/hooks';
import { ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const PaycardManagementHelpAndSettingsModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['paycardManagement', 'errors', 'common']);

  const handleActionButtonClick = () => {
    window.open('https://help.hotschedules.com/hc/en-us/requests/new', '_blank');
    dispatch(setModal({
      name: ModalNames.PAYCARD_HELP_AND_SETTINGS,
      isOpen: false,
    }));
  };

  return (
    <Layer index={400}>
      <AppModal
        minWidth="360px"
        maxWidth="100%"
        cancelBtnText={t('common:cancel')}
        actionBtnText={t('paycardManagement:paycardManagement.helpAndSettingsModal.content.submitARequest')}
        modal={ModalNames.PAYCARD_HELP_AND_SETTINGS}
        title={t('paycardManagement:paycardManagement.helpAndSettingsModal.title')}
        onAction={handleActionButtonClick}
        actionButtonEndEnhancer={(
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
          />
        )}
      >
        <Block>
          <Grid
            gridColumns={12}
            gridMargins={16}
            gridGaps={16}
          >
            <Cell span={12}>
              <Block>
                <ParagraphSmall style={{ whiteSpace: 'normal' }}>
                  {t('paycardManagement:paycardManagement.helpAndSettingsModal.content.makeChanges')}
                </ParagraphSmall>
                <ParagraphMedium margin="24px 0 8px 0" style={{ fontWeight: '600', whiteSpace: 'normal' }}>
                  {t('paycardManagement:paycardManagement.helpAndSettingsModal.content.addOrRemoveLocations')}
                </ParagraphMedium>
                <ParagraphSmall margin="0" style={{ whiteSpace: 'normal' }}>
                  {t('paycardManagement:paycardManagement.helpAndSettingsModal.content.additionOrRemoval')}
                </ParagraphSmall>
                <ParagraphMedium margin="24px 0 8px 0" style={{ fontWeight: '600', whiteSpace: 'normal' }}>
                  {t('paycardManagement:paycardManagement.helpAndSettingsModal.content.stockLevelManagement')}
                </ParagraphMedium>
                <ParagraphSmall margin="0" style={{ whiteSpace: 'normal' }}>
                  {t('paycardManagement:paycardManagement.helpAndSettingsModal.content.managingStock')}
                </ParagraphSmall>
                <ol style={{ margin: 0, paddingLeft: 12, listStyleType: 'none' }}>
                  <li>
                    <ParagraphSmall margin="0" style={{ whiteSpace: 'normal' }}>
                      <b>{t('paycardManagement:paycardManagement.helpAndSettingsModal.content.autoReorderThreshold')}</b>
                      {t('paycardManagement:paycardManagement.helpAndSettingsModal.content.autoReorderThresholdDescription')}
                    </ParagraphSmall>
                  </li>
                  <li>
                    <ParagraphSmall margin="0" style={{ whiteSpace: 'normal' }}>
                      <b>{t('paycardManagement:paycardManagement.helpAndSettingsModal.content.autoReorderQuantity')}</b>
                      {t('paycardManagement:paycardManagement.helpAndSettingsModal.content.autoReorderQuantityDescription')}
                    </ParagraphSmall>
                  </li>
                </ol>
                <ParagraphSmall margin="24px 0 8px 0" style={{ whiteSpace: 'normal' }}>
                  {t('paycardManagement:paycardManagement.helpAndSettingsModal.content.autoReorderFrequency')}
                  <b>{t('paycardManagement:paycardManagement.helpAndSettingsModal.content.asItAppears')}</b>
                  {t('paycardManagement:paycardManagement.helpAndSettingsModal.content.settingsYouWishToAdjust')}
                </ParagraphSmall>
                <ParagraphMedium margin="8px 0" style={{ fontWeight: '600', whiteSpace: 'normal' }}>
                  {t('paycardManagement:paycardManagement.helpAndSettingsModal.content.expeditedShipping')}
                </ParagraphMedium>
                <ParagraphSmall margin="0" style={{ whiteSpace: 'normal' }}>
                  {t('paycardManagement:paycardManagement.helpAndSettingsModal.content.selectingExpeditedShipping')}
                  <b>{t('paycardManagement:paycardManagement.helpAndSettingsModal.content.phoneNumber')}</b>
                  {t('paycardManagement:paycardManagement.helpAndSettingsModal.content.requestDirectly')}
                </ParagraphSmall>
              </Block>
            </Cell>
          </Grid>
        </Block>
      </AppModal>
    </Layer>
  );
};

export default PaycardManagementHelpAndSettingsModal;
