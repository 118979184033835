import * as Yup from 'yup';
import messages from './messages';

const searchInputsValidationSchema = Yup.object().shape({
  mobile: Yup.string().matches(/^\+?[0-9()\- ]+$/, messages?.mobile),
  email: Yup.string().email(messages?.email),
  firstName: Yup.string().matches(/^[a-zA-Z ]+$/, messages?.name),
  lastName: Yup.string().matches(/^[a-zA-Z ]+$/, messages?.name),
});

// TODO: remove default export once we have more than one declaration to export
export default searchInputsValidationSchema;
