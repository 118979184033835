import { createSlice } from '@reduxjs/toolkit';
import { resetEmployeeDetailsWorkersDrawsAndOffers } from 'store/events';
import { Offer } from 'types/OfferTypes';
import { RootState } from '..';

export type SubElementsAmount = {
  value: number
  scale: number
  currencyCode: string
}

export const initialState = {
  pendingList: false,
  pendingOffer: false,
  list: [] as Offer[],
  offer: undefined as Offer | undefined,
  selectedOfferId: undefined as number | undefined,
};

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    resetOffers: (state) => {
      state.list = initialState.list;
      state.offer = initialState.offer;
    },
    resetOffer: (state) => {
      state.offer = initialState.offer;
      state.selectedOfferId = initialState.selectedOfferId;
    },
    setSelectedOfferId: (state, action) => {
      state.selectedOfferId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetEmployeeDetailsWorkersDrawsAndOffers, (state) => {
      state.selectedOfferId = initialState.selectedOfferId;
    });
  },
});

export const {
  resetOffers,
  resetOffer,
  setSelectedOfferId,
} = offersSlice.actions;

export const offersOfferSelector = (state: RootState): Offer | undefined => state.offers.offer;
export const offersPendingOfferSelector = (state: RootState): boolean => state.offers.pendingOffer;
export const offersPendingListSelector = (state: RootState): boolean => state.offers.pendingList;
export const offerSelectedOffersIdSelector = (state: RootState): number | undefined => state.offers.selectedOfferId;

export default offersSlice.reducer;
