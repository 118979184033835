import { Block } from 'baseui/block';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  ChangeEvent,
  memo,
  useEffect,
} from 'react';
import { blockOverridesRelative } from 'screens/CommonHelpers';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  fetchOutstandingBatchesReport,
  fetchOutstandingRepaymentsReport,
  fetchTreasurySummary,
  resetOutstandingBatchesData,
  resetOutstandingRepaymentsData,
  treasurySummaryOutstandingBatchesDataPendingSelector,
  treasurySummaryOutstandingBatchesDataSelector,
  treasurySummaryOutstandingRepaymentsDataPendingSelector,
  treasurySummaryOutstandingRepaymentsDataSelector,
  treasurySummaryReportSelector,
  treasurySummaryReportPendingSelector,
  treasurySummaryReportDataSelector,
  treasurySummaryReportDataPendingSelector,
  resetTreasurySummaryData,
  fetchTreasurySummaryReport,
  treasurySummaryReportTotalsSelector,
  fetchFinancialSummaryReport,
  treasurySummaryFinancialReportDataSelector,
  treasurySummaryFinancialReportDataPendingSelector,
  resetFinancialReportData,
} from 'store/slices/reports';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import { unScalePrice } from 'utils/priceScale';
import DownloadFile from 'utils/downloadFile';
import {
  Button,
  KIND,
  SIZE,
} from 'baseui/button';
import CellFormControl from 'components/CellFormControl';
import { HeadingXSmall } from 'baseui/typography';
import { OrganizationTreasurySummaryRecord } from 'types/ReportsTypes';
import { colors } from 'theme';
import TreasurySummaryListItem from './TreasurySummaryListItem';

const TreasurySummarySection = () => {
  const dispatch = useAppDispatch();
  const treasurySummary = useAppSelector(treasurySummaryReportSelector);
  const pending = useAppSelector(treasurySummaryReportPendingSelector);
  const { t } = useTranslation(['common', 'treasury']);
  const outstandingBatchesData = useAppSelector(treasurySummaryOutstandingBatchesDataSelector);
  const pendingOutstandingBatchesReport = useAppSelector(treasurySummaryOutstandingBatchesDataPendingSelector);
  const outstandingRepaymentsData = useAppSelector(treasurySummaryOutstandingRepaymentsDataSelector);
  const pendingOutstandingRepaymentsReport = useAppSelector(treasurySummaryOutstandingRepaymentsDataPendingSelector);
  const treasurySummaryData = useAppSelector(treasurySummaryReportDataSelector);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pendingTreasurySummaryReport = useAppSelector(treasurySummaryReportDataPendingSelector);
  const treasurySummaryTotals = useAppSelector(treasurySummaryReportTotalsSelector);
  const financialReportData = useAppSelector(treasurySummaryFinancialReportDataSelector);
  const pendingFinancialReport = useAppSelector(treasurySummaryFinancialReportDataPendingSelector);

  const batchesOutstanding = unScalePrice(
    treasurySummaryTotals?.outstandingBatchesSummary?.amountDue.value,
    treasurySummaryTotals?.outstandingBatchesSummary?.amountDue.scale,
  );
  const repaymentsOutstanding = unScalePrice(
    treasurySummaryTotals?.outstandingRepaymentsSummary?.amountDue.value,
    treasurySummaryTotals?.outstandingRepaymentsSummary?.amountDue.scale,
  );
  const repaymentsUnallocated = unScalePrice(
    treasurySummaryTotals?.unallocatedRepaymentsSummary?.amountDue.value,
    treasurySummaryTotals?.unallocatedRepaymentsSummary?.amountDue.scale,
  );
  const ewaLedgersMissing = unScalePrice(
    treasurySummaryTotals?.ewaLedgersOutsideBatchesSummary?.amountDue.value,
    treasurySummaryTotals?.ewaLedgersOutsideBatchesSummary?.amountDue.scale,
  );
  const tpoLedgersMissing = unScalePrice(
    treasurySummaryTotals?.tcoLedgersOutsideBatchesSummary?.amountDue.value,
    treasurySummaryTotals?.tcoLedgersOutsideBatchesSummary?.amountDue.scale,
  );

  const handleExportOutstandingBatchesClick = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    dispatch(fetchOutstandingBatchesReport({}));
  };

  const handleExportOutstandingRepaymentsClick = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    dispatch(fetchOutstandingRepaymentsReport({}));
  };

  const handleTreasurySummaryReportClick = () => {
    dispatch(fetchTreasurySummaryReport({}));
  };

  const handleFinancialSummaryReportClick = () => {
    dispatch(fetchFinancialSummaryReport({}));
  };

  useEffect(() => {
    dispatch(fetchTreasurySummary({
      organizationID: undefined,
    }));
  }, []);

  useEffect(() => {
    if (outstandingBatchesData?.csv) {
      DownloadFile(
        `${outstandingBatchesData?.id}.csv`,
        `data:text/csv;charset=UTF-8,${encodeURIComponent(outstandingBatchesData?.csv)}`,
      );
      dispatch(resetOutstandingBatchesData());
    }
  }, [outstandingBatchesData?.id]);

  useEffect(() => {
    if (outstandingRepaymentsData?.csv) {
      DownloadFile(
        `${outstandingRepaymentsData?.id}.csv`,
        `data:text/csv;charset=UTF-8,${encodeURIComponent(outstandingRepaymentsData?.csv)}`,
      );
      dispatch(resetOutstandingRepaymentsData());
    }
  }, [outstandingRepaymentsData?.id]);

  useEffect(() => {
    if (treasurySummaryData?.csv) {
      DownloadFile(
        `${treasurySummaryData?.id}.csv`,
        `data:text/csv;charset=UTF-8,${encodeURIComponent(treasurySummaryData?.csv)}`,
      );
      dispatch(resetTreasurySummaryData());
    }
  }, [treasurySummaryData?.id]);

  useEffect(() => {
    if (financialReportData?.csv) {
      DownloadFile(
        `${financialReportData?.id}.csv`,
        `data:text/csv;charset=UTF-8,${encodeURIComponent(financialReportData?.csv)}`,
      );
      dispatch(resetFinancialReportData());
    }
  }, [financialReportData?.id]);

  return (
    <Block
      overrides={blockOverridesRelative}
    >
      <Loader active={pending} />
      <Grid
        align={ALIGNMENT.center}
        gridColumns={12}
      >
        <CellFormControl
          cellSpan={12}
          cellAlign={ALIGNMENT.end}
          formControlProps={{
            overrides: {
              ControlContainer: {
                style: {
                  display: 'inline-flex',
                  justifyItems: 'end',
                  justifyContent: 'flex-end',
                },
              },
            },
          }}
        >
          <Button
            kind={KIND.tertiary}
            onClick={() => handleTreasurySummaryReportClick()}
            disabled={pending}
            isLoading={pendingTreasurySummaryReport}
            size={SIZE.compact}
            overrides={{
              Root: {
                props: {
                  'data-testid': 'TreasurySummary-export-all-button',
                  id: 'TreasurySummary-export-all-button',
                },
              },
              LoadingSpinner: {
                style: {
                  borderRightColor: colors.primary,
                  borderTopColor: colors.primary,
                  borderLeftColor: colors.primary,
                },
              },
            }}
          >
            {t('common:exportAll')}
          </Button>
        </CellFormControl>
        <Cell span={12}>
          <HeadingXSmall margin={0}>{t('treasury:treasurySummary.treasury.heading')}</HeadingXSmall>
          <br />
          <TreasurySummaryListItem
            sectionName={t('treasury:treasurySummary.batchesOutstanding.label')}
            totalValue={batchesOutstanding}
            onClick={handleExportOutstandingBatchesClick}
            disabledExportButton={treasurySummary.length === 0
              || batchesOutstanding === 0}
            info={treasurySummary?.map((item: OrganizationTreasurySummaryRecord) => ({
              organization: item.organization,
              summaryItem: item.outstandingBatchesSummary,
            }))}
            totalRecordsNumber={treasurySummaryTotals?.outstandingBatchesSummary?.totalRecordsInSummary}
            pending={pendingOutstandingBatchesReport}
          />
          <br />
          <TreasurySummaryListItem
            sectionName={t('treasury:treasurySummary.repaymentsOutstanding.label')}
            totalValue={repaymentsOutstanding}
            onClick={handleExportOutstandingRepaymentsClick}
            disabledExportButton={treasurySummary.length === 0
              || repaymentsOutstanding === 0}
            info={treasurySummary?.map((item: OrganizationTreasurySummaryRecord) => ({
              organization: item.organization,
              summaryItem: item.outstandingRepaymentsSummary,
            }))}
            totalRecordsNumber={treasurySummaryTotals?.outstandingRepaymentsSummary?.totalRecordsInSummary}
            pending={pendingOutstandingRepaymentsReport}
          />
          <br />
          <TreasurySummaryListItem
            sectionName={t('treasury:treasurySummary.repaymentsUnallocated.label')}
            totalValue={repaymentsUnallocated}
            info={treasurySummary?.map((item: OrganizationTreasurySummaryRecord) => ({
              organization: item.organization,
              summaryItem: item.unallocatedRepaymentsSummary,
            }))}
            totalRecordsNumber={treasurySummaryTotals?.unallocatedRepaymentsSummary?.totalRecordsInSummary}
          />
        </Cell>
      </Grid>

      <Grid
        align={ALIGNMENT.center}
        gridColumns={12}
      >
        <Cell span={12}>
          <br />
          <Grid
            align={ALIGNMENT.center}
            gridColumns={12}
            gridMargins={0}
          >
            <Cell span={3}>
              <HeadingXSmall margin={0}>{t('treasury:treasurySummary.ledgers.heading')}</HeadingXSmall>
            </Cell>
            <Cell skip={[3, 5, 5]} />
            <CellFormControl
              cellSpan={[5, 3, 3]}
              cellAlign={ALIGNMENT.end}
              formControlProps={{
                overrides: {
                  ControlContainer: {
                    style: {
                      display: 'inline-flex',
                      justifyItems: 'end',
                      justifyContent: 'flex-end',
                    },
                  },
                },
              }}
            >
              <Button
                kind={KIND.tertiary}
                onClick={() => handleFinancialSummaryReportClick()}
                disabled={pending}
                isLoading={pendingFinancialReport}
                size={SIZE.compact}
                overrides={{
                  Root: {
                    props: {
                      'data-testid': 'TreasurySummary-export-ledgers-button',
                      id: 'TreasurySummary-export-ledgers-button',
                    },
                  },
                  LoadingSpinner: {
                    style: {
                      borderRightColor: colors.primary,
                      borderTopColor: colors.primary,
                      borderLeftColor: colors.primary,
                    },
                  },
                }}
              >
                {t('treasury:exportLedgers')}
              </Button>
            </CellFormControl>
          </Grid>
          <TreasurySummaryListItem
            sectionName={t('treasury:treasurySummary.ewaMissing.label')}
            totalValue={ewaLedgersMissing}
            info={treasurySummary?.map((item: OrganizationTreasurySummaryRecord) => ({
              organization: item.organization,
              summaryItem: item.ewaLedgersOutsideBatchesSummary,
            }))}
            totalRecordsNumber={treasurySummaryTotals?.ewaLedgersOutsideBatchesSummary?.totalRecordsInSummary}
          />
          <br />
          <TreasurySummaryListItem
            sectionName={t('treasury:treasurySummary.tpoMissing.label')}
            totalValue={tpoLedgersMissing}
            totalRecordsNumber={treasurySummaryTotals?.tcoLedgersOutsideBatchesSummary?.totalRecordsInSummary}
            info={treasurySummary?.map((item: OrganizationTreasurySummaryRecord) => ({
              organization: item.organization,
              summaryItem: item.tcoLedgersOutsideBatchesSummary,
            }))}
          />
          <br />
        </Cell>
      </Grid>
    </Block>
  );
};

export default memo(TreasurySummarySection);
