import { TransactionsStatus } from 'types/MasterAccountTypes';
import request from './request';

export const fetchMasterAccountBalanceRequest = (
  token: string,
) => request({
  token,
  url: 'master-account/visa-card/balance',
});

export const fetchDashAccountBalanceRequest = (
  token: string,
) => request({
  token,
  url: 'master-account/paycard/balance',
});

export const fetchMasterAccountTransactionsRequest = (
  token: string,
  page: string,
  transactionStatus?: TransactionsStatus,
  transactionType?: string,
  transactionNumber?: string,
  startDate?: string,
  endDate?: string,
) => {
  const queryParams = new URLSearchParams({
    page,
  });
  transactionStatus && queryParams.set('transactionStatus', transactionStatus);
  transactionType && queryParams.set('transactionType', transactionType);
  transactionNumber && queryParams.set('transactionNumber', transactionNumber);
  startDate && queryParams.set('fromDate', startDate);
  endDate && queryParams.set('toDate', endDate);

  return request({
    token,
    url: `master-account/visa-card/transactions?${queryParams.toString()}`,
  });
};

export const fetchDashAccountTransactionsRequest = (
  token: string,
  proxyNumber?: string,
  transactionType?: string,
  startDate?: string,
  endDate?: string,
) => {
  const queryParams = new URLSearchParams();
  proxyNumber && queryParams.set('proxyNumber', proxyNumber);
  transactionType && queryParams.set('transactionType', transactionType);
  startDate && queryParams.set('fromDate', startDate);
  endDate && queryParams.set('toDate', endDate);

  return request({
    token,
    url: `master-account/paycard/transactions?${queryParams.toString()}`,
  });
};
