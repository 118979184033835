import AppModal from 'components/AppModal/AppModal';
import {
  memo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  ModalNames,
  setModal,
} from 'store/slices/modals';
import {
  fetchOrdersHistory,
} from 'store/slices/paycards';
import {
  CardStockLocationItem,
} from 'types/PaycardManagementTypes';
import PaycardManagementCardStockLocationHistoryModalTable from './CardHistoryTable/PaycardManagementCardStockLocationHistoryModalTable';

const PaycardMangementCardStockLocationStockHistoryModal = ({
  location,
}: { location: CardStockLocationItem | undefined }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'paycardManagement']);
  const modalName = ModalNames.CARD_STOCK_VIEW_HISTORY;
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const fetchCardHistory = ({ pageNum }: {pageNum: number}) => {
    dispatch(fetchOrdersHistory({
      organizationId: loggedOrganization?.id,
      locationId: location?.locationId.toString(),
      pageSize: 10,
      page: pageNum,
    }));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    fetchCardHistory({ pageNum: nextPage });
  };

  useEffect(() => {
    fetchCardHistory({ pageNum: 1 });
  }, []);

  return (
    <AppModal
      modalWidth={['90vw', '80vw', '70vw', '50vw']}
      minWidth="360px"
      maxWidth="100%"
      modal={modalName}
      title={t(
        'paycardManagement:paycardManagement.cardStock.orderCards.viewOrderHistory.title',
        { locationName: `${location?.locationName || ''}` },
      )}
      onClose={handleModalClose}
    >
      <br />
      <PaycardManagementCardStockLocationHistoryModalTable handlePageChange={handlePageChange} />
    </AppModal>
  );
};

export default memo(PaycardMangementCardStockLocationStockHistoryModal);
