import { memo } from 'react';
import { useAppSelector } from 'store/hooks';
import { Block } from 'baseui/block';
import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { Pagination } from 'baseui/pagination';
import { Skeleton } from 'baseui/skeleton';
import {
  SIZE,
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic';
import {
  HeadingXSmall,
  ParagraphSmall,
} from 'baseui/typography';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import {
  HistoryOrderItem,
} from 'types/PaycardManagementTypes';
import { imageContainerStyles } from 'screens/PaycardManagement/PaycardManagementHelper';
import { format } from 'date-fns';
import {
  paycardOrdersHistoryFetchFailedSelector,
  paycardOrdersHistoryListPendingSelector,
  paycardOrdersHistoryListSelector,
  paycardOrdersHistoryPageNumberSelector,
  paycardOrdersHistoryTotalPagesSelector,
} from 'store/slices/paycards';
import { useStyletron } from 'styletron-react';
import { useTranslation } from 'react-i18next';

export type PaycardManagementCardHistoryTablePropsType = {
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
}

const PaycardManagementCardStockLocationHistoryModalTable = ({
  handlePageChange,
}: PaycardManagementCardHistoryTablePropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const ordersList = useAppSelector(paycardOrdersHistoryListSelector);
  const pendingOrdersList = useAppSelector(paycardOrdersHistoryListPendingSelector);
  const page = useAppSelector(paycardOrdersHistoryPageNumberSelector);
  const numPages = useAppSelector(paycardOrdersHistoryTotalPagesSelector);
  const fetchFailed = useAppSelector(paycardOrdersHistoryFetchFailedSelector);
  const dateFormat = t('dateFormats:date-picker.standard');

  return (
    <Grid
      gridColumns={12}
      gridMargins={12}
    >
      <Cell
        span={12}
      >
        <TableBuilder
          data={ordersList}
          size={SIZE.compact}
          emptyMessage={(
            <div className={css(imageContainerStyles)}>
              {fetchFailed
                ? (<HeadingXSmall margin={0}>{t('paycardManagement:paycardManagement.errorOnLoading')}</HeadingXSmall>)
                : (
                  <HeadingXSmall margin={0}>{t('paycardManagement:paycardManagement.cardStock.orderCards.viewOrderHistory.table.noResults')}</HeadingXSmall>
                )}
            </div>
          )}
          isLoading={pendingOrdersList}
          loadingMessage={(
            <Skeleton
              height="72px"
              animation
            />
          )}
        >
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.cardStock.orderCards.viewOrderHistory.table.date')}
          >
            {(order: HistoryOrderItem) => (
              <ParagraphSmall>
                {order.date && format(new Date(order.date), dateFormat)}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.cardStock.orderCards.viewOrderHistory.table.type')}
          >
            {(order: HistoryOrderItem) => (
              <ParagraphSmall>
                {`${order.type.slice(0, 1)}${order.type.slice(1).toLowerCase()}`}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.cardStock.orderCards.viewOrderHistory.table.cards')}
          >
            {(order: HistoryOrderItem) => (
              <ParagraphSmall>
                {order.cardsCount}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.cardStock.orderCards.viewOrderHistory.table.status')}
          >
            {(order: HistoryOrderItem) => (
              <ParagraphSmall>
                {order.status}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
        </TableBuilder>

      </Cell>
      {!pendingOrdersList && (
        <Cell span={12}>
          <Block
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
          >
            <Pagination
              size={SIZE.compact}
              numPages={numPages}
              currentPage={page}
              overrides={paginationTransparentOverrides}
              onPageChange={handlePageChange}
            />
          </Block>
        </Cell>
      )}
    </Grid>
  );
};

export default memo(PaycardManagementCardStockLocationHistoryModalTable);
