import { faArrowDownShortWide, faArrowUpWideShort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, KIND, SIZE } from 'baseui/button';
import { StatefulTooltip } from 'baseui/tooltip';
import { tooltipOverrides } from 'screens/CommonHelpers';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

export type SortButtonProps = {
  sortType: string;
  content: string;
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  onClick: () => void;
  iconSize?: SizeProp;
};

const SortButton = ({
  content,
  placement = 'topLeft',
  sortType,
  onClick,
  iconSize = 'lg',
}: SortButtonProps) => (
  <StatefulTooltip
    accessibilityType="tooltip"
    content={content}
    placement={placement}
    overrides={tooltipOverrides(false)}
  >
    <Button
      kind={KIND.primary}
      size={SIZE.compact}
      onClick={onClick}
    >
      {sortType === 'desc' ? (
        <FontAwesomeIcon icon={faArrowDownShortWide} size={iconSize} />
      ) : (
        <FontAwesomeIcon icon={faArrowUpWideShort} size={iconSize} />
      )}
    </Button>
  </StatefulTooltip>
);

export default SortButton;
