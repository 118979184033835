import {
  memo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useStyletron } from 'baseui';
import { contentContainerStyles } from 'screens/PaycardManagement/PaycardManagementHelper';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { useTranslation } from 'react-i18next';
import {
  prevPageSelector,
} from 'store/slices/application';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  fetchOrganizationPaycardEligibleLocations,
  loadFunds,
  paycardOrganizationRegisterCardEmployeeDetailsSelector,
  paycardOrganizationRegisterCardIsValidAddEmployeeFormSelector,
  paycardOrganizationRegisterCardIsValidPairCardFormSelector,
  paycardOrganizationRegisterCardPaymentDetailsSelector,
  paycardOrganizationRegisterCardSelectedEmployeeSelector,
  paycardOrganizationRegisterCardSelectedLocationSelector,
  registerPaycard,
  setOrganizationsPaycardRegisterCardEmployeeDetails,
  setOrganizationsPaycardRegisterCardPaymentDetails,
} from 'store/slices/paycards';
import WizardContainer from 'components/Wizard/WizardContainer/WizardContainer';
import WizardTabulation from 'components/Wizard/WizardTabulation/WizardTabulation';
import {
  wizardSectionSelector,
} from 'store/slices/sections';
import {
  loadFundsMapper,
  registerPaycardMapper,
} from 'dataMappers/paycardManagementMapper';
import {
  NotificationType,
  notificationSelector,
  setNotification,
} from 'store/slices/notification';
import { useHistory } from 'react-router-dom';
import PaycardManagementRegisterCardSelectLocation from './SelectLocation/PaycardManagementRegisterCardSelectLocation';
import PaycardManagementRegisterCardChooseEmployee from './ChooseEmployee/PaycardManagementRegisterCardChooseEmployee';
import PaycardManagementRegisterCardAddEmployeeDetails from './AddEmployeeDetails/PaycardManagementRegisterCardAddEmployeeDetails';
import PaycardManagementRegisterCardPairCard from './PairCard/PaycardManagementRegisterCardPairCard';
import PaycardManagementRegisterCardReviewAndRegisterCard from './ReviewAndRegisteCard/PaycardManagementRegisterCardReviewAndRegisterCard';

const PaycardManagementRegisterCard = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const history = useHistory();
  const prevPage = useAppSelector(prevPageSelector);
  const dispatch = useAppDispatch();
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const location = useAppSelector(paycardOrganizationRegisterCardSelectedLocationSelector);
  const activeSection = useAppSelector(wizardSectionSelector);
  const selectedEmployee = useAppSelector(paycardOrganizationRegisterCardSelectedEmployeeSelector);
  const isAddEmployeeFormValid = useAppSelector(paycardOrganizationRegisterCardIsValidAddEmployeeFormSelector);
  const isPairCardFormValid = useAppSelector(paycardOrganizationRegisterCardIsValidPairCardFormSelector);
  const employeeDetails = useAppSelector(paycardOrganizationRegisterCardEmployeeDetailsSelector);
  const paymentDetails = useAppSelector(paycardOrganizationRegisterCardPaymentDetailsSelector);
  const notificationToast = useAppSelector(notificationSelector);

  const [formValuesEmployee, setFormValuesEmployee] = useState({});
  const [formValuesPayment, setFormValuesPayment] = useState({});

  const subMenuHeadings = [
    t('paycardManagement:paycardManagement.registerCard.navigation.selectLocation'),
    t('paycardManagement:paycardManagement.registerCard.navigation.chooseEmployee'),
    t('paycardManagement:paycardManagement.registerCard.navigation.addEmployeeDetails'),
    t('paycardManagement:paycardManagement.registerCard.navigation.pairCard'),
    t('paycardManagement:paycardManagement.registerCard.navigation.reviewAndRegisterCard'),
  ];

  const nextButtonDisabledCondition = () => {
    switch (activeSection) {
      case 0:
        return !location || location?.length === 0;
      case 1:
        return !selectedEmployee;
      case 2:
        return !isAddEmployeeFormValid;
      case 3:
        return !isPairCardFormValid;
      default:
        return false;
    }
  };
  const handleAction = useCallback(() => {
    switch (activeSection) {
      case 2:
        return dispatch(setOrganizationsPaycardRegisterCardEmployeeDetails(formValuesEmployee));
      case 3:
        return dispatch(setOrganizationsPaycardRegisterCardPaymentDetails(formValuesPayment));
      default:
        return false;
    }
  }, [formValuesEmployee,
    formValuesPayment]);

  const handleFinish = () => {
    dispatch(registerPaycard({
      organizationId: loggedOrganization?.id,
      locationId: location[0].id.toString(),
      data: registerPaycardMapper(employeeDetails, paymentDetails),
    }))
      .unwrap()
      .then(() => {
        paymentDetails.funds > 0
          && dispatch(loadFunds({
            organizationId: loggedOrganization?.id,
            proxyNumber: paymentDetails.proxyNumber,
            data: loadFundsMapper(paymentDetails, t, loggedOrganization?.name),
          }))
            .unwrap()
            .catch(() => {
              dispatch(setNotification({
                type: NotificationType.ERROR,
                isOpen: true,
                titleKey: t('errors:unableToLoadFunds'),
                autoHideDuration: 3000,
              }));
            });
      })
      .catch(() => {
        dispatch(setNotification({
          type: NotificationType.ERROR,
          isOpen: true,
          titleKey: t('errors:unableToRegisterPaycard'),
          autoHideDuration: 3000,
        }));
      });
  };

  useEffect(() => {
    activeSection === 1 && !employeeDetails && setFormValuesEmployee({});
    activeSection === 2 && selectedEmployee === null && setFormValuesPayment({});
  }, [activeSection,
    selectedEmployee,
    employeeDetails]);

  useEffect(() => {
    if (notificationToast?.[1]?.isOpen
      && notificationToast?.[1]?.type === NotificationType.SUCCESS) {
      history.push(`${prevPage || '/paycard'}`);
    }
  }, [notificationToast]);

  useEffect(() => {
    dispatch(fetchOrganizationPaycardEligibleLocations(
      { organizationId: loggedOrganization?.id },
    ));
  }, []);

  return (
    <div className={css(contentContainerStyles)}>
      <CommonHeader
        title={t('paycardManagement:paycardManagement.registerCard.button')}
        backTo={`${prevPage || '/paycard'}`}
      />
      <WizardContainer
        nextButtonDisabled={nextButtonDisabledCondition()}
        onAction={handleAction}
        onFinish={handleFinish}
      >
        <WizardTabulation tabs={subMenuHeadings} />
        {activeSection === 0 && <PaycardManagementRegisterCardSelectLocation /> }
        {activeSection === 1 && (
        <PaycardManagementRegisterCardChooseEmployee
          setFormValues={setFormValuesEmployee}
        />
        )}
        {activeSection === 2 && (
          <PaycardManagementRegisterCardAddEmployeeDetails
            formValues={formValuesEmployee}
            setFormValues={setFormValuesEmployee}
          />
        )}
        {activeSection === 3 && (
          <PaycardManagementRegisterCardPairCard
            formValues={formValuesPayment}
            setFormValues={setFormValuesPayment}
          />
        )}
        {activeSection === 4 && <PaycardManagementRegisterCardReviewAndRegisterCard />}
      </WizardContainer>
    </div>
  );
};

export default memo(PaycardManagementRegisterCard);
