import { useEffect } from 'react';
import { Button, KIND } from 'baseui/button';
import { Cell } from 'baseui/layout-grid';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { sectionSelector, setActiveSections, resetActiveSections } from 'store/slices/sections';
import { useStyletron } from 'styletron-react';
import { useTranslation } from 'react-i18next';
import {
  emptyTabButtonStyles, activeTabContainerStyles, activeTabButtonStyles, tabButtonStyles,
} from 'screens/CommonHelpers';

export const tabsButtonStyles = {
  Root: {
    style: {
      color: '#111111',
      'font-size': '15px',
      'font-weight': '700',
      'white-space': 'nowrap',
    },
  },
};

export type SectionTabulationPropsType = {
    subMenuHeadings: string[],
    buttonDisabledRole: boolean | undefined,
    sectionName: string,
    activeSection?: number
    setShownSection?: (activeSec: number) => void
};

const SectionsTabulation = ({
  subMenuHeadings, buttonDisabledRole, sectionName, activeSection, setShownSection,
}: SectionTabulationPropsType) => {
  const [css] = useStyletron();

  const dispatch = useAppDispatch();

  const storedSection = useAppSelector(sectionSelector);

  const selectedSection = activeSection || storedSection;

  const { t } = useTranslation(['treasury']);

  const onSelectTab = (i: number) => {
    if (setShownSection) {
      setShownSection(i);
    } else {
      dispatch(setActiveSections(i));
    }
  };

  useEffect(() => {
    dispatch(resetActiveSections());
  }, []);

  return (
    <>
      <Cell span={12} />
      <div className={css(activeTabContainerStyles)}>
        {subMenuHeadings.map((sH, i) => (
          <div key={sH} className={selectedSection === i ? css(activeTabButtonStyles) : css(tabButtonStyles)}>
            <Button
              kind={KIND.tertiary}
              disabled={((subMenuHeadings[i] === t('treasury:foa'))
              || (subMenuHeadings[i] === t('treasury:treasurySummary') && buttonDisabledRole)
              || (subMenuHeadings[i] === t('locations:location.section.profile') && buttonDisabledRole))
                ? false : buttonDisabledRole}
              overrides={{
                Root: {
                  ...tabsButtonStyles.Root,
                  props: {
                    id: `${sectionName}-admin-${sH}`,
                  },
                },
              }}
              onClick={() => onSelectTab(i)}
            >
              {sH}
            </Button>

          </div>
        ))}
        <div className={css(emptyTabButtonStyles)} />
      </div>
    </>
  );
};

export default SectionsTabulation;
