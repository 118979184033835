export const blockOverrides = {
  width: '100%',
};

export const tableRowStyle = {
  borderBottom: '1px solid #ccc',
  width: 'calc(100% - 128px)',
  margin: '0 auto !important',
  padding: '0 !important',
  '@media screen and (max-width: 1200px)': {
    width: 'calc(100% - 72px)',
  },
  '@media screen and (max-width: 767px)': {
    width: 'calc(100% - 32px)',
  },
};

export const tableCellStyle = {
  height: '45px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
