import moment from 'moment';
import { Layer } from 'baseui/layer';
import { useTranslation } from 'react-i18next';
import { Grid, Cell } from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import { useAppDispatch } from 'store/hooks';
import { ModalNames, setModal } from 'store/slices/modals';
import AppModal from 'components/AppModal/AppModal';
import { useStyletron } from 'styletron-react';
import PriceFormatter from 'utils/priceFormatter';
import { emptyPlaceholder } from 'theme';
import { WorkerAccountTransactionsDetailsModalPropsType } from 'types/WorkerAccountTypes';

const firstColumnWidth = '40%';
const secondColumnWidth = '60%';
const headerLabelStyles = {
  fontWeight: 600,
};

const WorkerAccountTransactionsDetailsModal = ({
  transaction,
}: WorkerAccountTransactionsDetailsModalPropsType) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['treasury', 'common']);
  const [css] = useStyletron();
  const modalName = ModalNames.TRANSACTION_HISTORY_DETAILS_MODAL;

  const dateFormat = t('dateFormats:standard-with-time');

  const handleModalClose = () => {
    dispatch(setModal({ name: modalName, isOpen: false }));
  };

  const Item = (heading: string, value: string | number | undefined | null) => (
    <Cell span={12}>
      <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
        {heading}
        :
      </Block>
      <Block width={secondColumnWidth} display="inline-flex">
        {value}
      </Block>
    </Cell>
  );

  return (
    <Layer index={300}>
      <AppModal
        modal={modalName}
        title={t('transactionDetailsHeader')}
        onClose={handleModalClose}
      >
        <Grid
          gridColumns={12}
          gridMargins={16}
          gridGaps={16}
        >
          {Item(t('treasury:transactionDetails.ID'), transaction?.id)}
          {Item(t('treasury:transactionDetails.transactionDate'), moment(transaction?.transactionDate).format(dateFormat))}
          {Item(t('treasury:transactionDetails.transactionNumber'), transaction?.transactionNumber)}
          {Item(t('treasury:transactionDetails.status'), transaction?.status)}
          {Item(t('treasury:transactionDetails.type'), transaction?.type)}
          {Item(t('treasury:transactionDetails.amount'), transaction?.amount ? PriceFormatter().format(transaction?.amount) : emptyPlaceholder)}
          {Item(t('treasury:transactionDetails.description'), transaction?.description || emptyPlaceholder)}
        </Grid>

        {transaction?.merchant && (
          <>
            <hr />

            <Block
              marginTop="24px"
            >
              <Grid
                gridColumns={12}
                gridMargins={16}
                gridGaps={16}
              >
                {Item(t('treasury:transactionDetails.merchant.name'), transaction?.merchant?.name)}
                {Item(t('treasury:transactionDetails.merchant.mcc'), transaction?.merchant?.mcc)}
                {Item(t('treasury:transactionDetails.merchant.mccName'), transaction?.merchant?.mccName)}
                {Item(t('treasury:transactionDetails.merchant.mccGroup'), transaction?.merchant?.mccGroup)}
                {Item(t('treasury:transactionDetails.merchant.city'), transaction?.merchant?.city)}
                {Item(t('treasury:transactionDetails.merchant.region'), transaction?.merchant?.region)}
                {Item(t('treasury:transactionDetails.merchant.country'), transaction?.merchant?.country)}
              </Grid>
            </Block>
          </>
        )}
      </AppModal>
    </Layer>
  );
};

export default WorkerAccountTransactionsDetailsModal;
