import {
  forwardRef,
  memo,
  ReactNode,
  RefObject,
  useContext,
} from 'react';
import { DatePicker, DatepickerProps } from 'baseui/datepicker';
import {
  Cell,
  ALIGNMENT,
  Responsive,
  CellProps,
  Alignment,
} from 'baseui/layout-grid';
import {
  FormControlProps,
} from 'baseui/form-control';
import {
  FormikHandlers, FormikHelpers, FormikState,
} from 'formik';
import { BaseInput, SIZE } from 'baseui/input';
import { useTranslation } from 'react-i18next';
import AppFormControl from './AppFormControl';

export type AppDatePickerPropsType = {
  name: string
  context: any
  disabled?: boolean
  label?: any
  cellSpan?: Responsive<number> | undefined
  cellAlign?: Alignment | undefined
  cellProps?: CellProps
  datePickerProps?: DatepickerProps
  formControlProps?: Pick<FormControlProps, Exclude<keyof FormControlProps, 'children'>>
  placeholder?: string
  showStar?: boolean
  tooltipTitle?: ReactNode,
  tooltipContent?: ReactNode,
}

export const ForwardedInput = forwardRef<HTMLInputElement>((
  props,
  ref,
) => <BaseInput {...props} inputRef={ref as RefObject<HTMLInputElement>} />);

export const datePickerOverrides = {
  Input: {
    props: {
      id: undefined as string | undefined,
      autoComplete: 'off',
      overrides: {
        Input: {
          component: ForwardedInput,
          props: {
            autoComplete: 'off',
            size: SIZE.compact,
          },
        },
      },
    },
  },
};

const AppDatePicker = ({
  label,
  name,
  context,
  cellSpan = 3,
  cellAlign = ALIGNMENT.start,
  cellProps,
  datePickerProps,
  formControlProps,
  placeholder,
  disabled,
  showStar,
  tooltipTitle,
  tooltipContent,
}: AppDatePickerPropsType) => {
  const formik = useContext<FormikState<any> & FormikHelpers<any> & FormikHandlers>(context);
  const { t } = useTranslation(['dateFormats']);

  const datePickerDateFormat = t('dateFormats:date-picker.standard');
  const datePickerPlaceholder = t('dateFormats:date-picker.placeholder');

  const {
    errors,
    values,
    touched,
    setFieldValue,
    setFieldTouched,
  } = formik;

  return (
    <Cell
      span={cellSpan}
      align={cellAlign}
      {...cellProps}
    >
      <AppFormControl
        tooltipTitle={tooltipTitle}
        tooltipContent={tooltipContent}
        showStar={showStar}
        label={label}
        error={!!(errors[name] && touched[name]) && errors[name]}
        {...formControlProps}
      >
        <DatePicker
          size={SIZE.compact}
          disabled={disabled}
          placeholder={placeholder || datePickerPlaceholder}
          formatString={datePickerDateFormat}
          value={values[name]}
          onClose={() => setFieldTouched(name, true)}
          onChange={({ date }) => setFieldValue(name, Array.isArray(date) ? date : [date])}
          error={!!(errors[name] && touched[name] && errors[name])}
          overrides={{
            ...datePickerOverrides,
            InputWrapper: {
              props: {
                'data-testid': `Form-date-picker-${name}`,
              },
            },
            Input: {
              ...datePickerOverrides.Input,
              props: {
                ...datePickerOverrides.Input.props,
                id: `Form-date-picker-${name}`,
                'data-testid': `Form-date-picker-${name}`,
              },
            },
          }}
          {...datePickerProps}
        />
      </AppFormControl>
    </Cell>
  );
};

export default memo(AppDatePicker);
