import { Layer } from 'baseui/layer';
import { useTranslation } from 'react-i18next';
import { Grid } from 'baseui/layout-grid';
import {
  createContext, memo, useEffect,
} from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { newRecoverFundsValidationSchema as validationSchema } from 'validation/transactionsSchema';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import AppInput from 'components/Form/AppInput';
import AppModal from 'components/AppModal/AppModal';
import {
  FormikHandlers, FormikHelpers, FormikState, useFormik,
} from 'formik';
import { recoverFunds } from 'store/slices/transactions';
import { currencyCodeSelector, priceScaleSelector } from 'store/slices/application';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { errorSelector } from 'store/slices/error';
import { recoverFundsMapper } from 'dataMappers/repaymentsDataMappers';
import { workerAccountBalanceSelector, workerAccountWorkerSelector } from 'store/slices/workerAccount';
import messages from 'validation/messages';
import AppTextarea from 'components/Form/AppTextarea';

const initialValues = {
  amount: 1,
  zendeskTicketReference: '',
  recoverFundsDetails: '',
};

export type RecoverFundsValuesType = typeof initialValues;

export const NewRecoverFundsFormContext = createContext(
  {} as FormikState<RecoverFundsValuesType> & FormikHelpers<RecoverFundsValuesType> & FormikHandlers,
);

export type WorkerAccountTransactionsRecoverFundsPropTypes = {
  refreshOnSave: () => void,
};

const WorkerAccountTransactionsRecoverFundsModal = ({ refreshOnSave }: WorkerAccountTransactionsRecoverFundsPropTypes) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['treasury', 'common']);
  const currencyCode = useAppSelector(currencyCodeSelector);
  const scale = useAppSelector(priceScaleSelector);
  const error = useAppSelector(errorSelector);
  const worker = useAppSelector(workerAccountWorkerSelector);
  const { id: workerID } = worker || {};
  const balance = useAppSelector(workerAccountBalanceSelector);
  const { availableBalance } = balance || {};
  const modals = useAppSelector(modalsSelector);
  const modalName = ModalNames.RECOVER_FUNDS_MODAL;
  const isModalOpen = checkIsModalOpen(modals, modalName);

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleClickSave = (values: RecoverFundsValuesType) => {
    if (values.amount <= availableBalance) {
      dispatch(recoverFunds({
        workerID,
        data: recoverFundsMapper(currencyCode, scale, values),
      })).then(() => {
        setIsModalOpen(false);
        refreshOnSave();
      });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleClickSave,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    validateForm,
    isSubmitting,
    setSubmitting,
    setValues,
    setErrors,
    errors,
    isValid,
  } = formik;

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  useEffect(() => {
    if (!errors.amount && (values.amount > availableBalance || !availableBalance)) {
      setErrors({
        ...errors,
        amount: messages.recoverFundsLessThanBalance,
      });
    }
  }, [values, errors]);

  useEffect(() => {
    if (isModalOpen) {
      setValues({
        ...values,
        ...initialValues,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  return (
    <Layer index={300}>

      <AppModal
        modal={modalName}
        title={t('treasury:recoverFundsTitle')}
        cancelBtnText={t('common:cancel')}
        onClose={handleModalClose}
        actionBtnText={t('treasury:recoverButton')}
        onAction={handleSubmitExtended}
        isCloseDisabled={isSubmitting}
        isActionDisabled={isSubmitting || !isValid}
      >
        <NewRecoverFundsFormContext.Provider value={formik}>
          <form>

            <Grid
              gridColumns={12}
              gridMargins={16}
              gridGaps={16}
            >
              <AppInput
                showStar
                name="amount"
                label={t('treasury:recoverFunds.amountLabel')}
                cellSpan={[12, 6, 6]}
                context={NewRecoverFundsFormContext}
                formControlProps={{
                  htmlFor: 'amount',
                }}
                inputProps={{
                  min: 0,
                  max: 999999.99,
                  type: 'number',
                  step: 'any' as any,
                  endEnhancer: currencyCode,
                  autoComplete: 'off',
                }}
              />
              <AppInput
                showStar
                name="zendeskTicketReference"
                inputProps={{
                  id: 'zendeskTicketReference',
                  autoComplete: 'off',
                }}
                formControlProps={{
                  htmlFor: 'zendeskTicketReference',
                }}
                label={t('treasury:recoverFunds.zendeskTicketReferenceLabel')}
                cellSpan={[12, 6, 6]}
                context={NewRecoverFundsFormContext}
              />
              <AppTextarea
                name="recoverFundsDetails"
                textareaProps={{
                  id: 'recoverFundsDetails',
                  autoComplete: 'off',
                }}
                formControlProps={{
                  htmlFor: 'recoverFundsDetails',
                }}
                label={t('treasury:recoverFunds.details')}
                cellSpan={[12, 6, 6]}
                context={NewRecoverFundsFormContext}
              />
            </Grid>
          </form>
        </NewRecoverFundsFormContext.Provider>
      </AppModal>

    </Layer>
  );
};

export default memo(WorkerAccountTransactionsRecoverFundsModal);
