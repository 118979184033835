import {
  memo,
  ReactNode,
} from 'react';
import { StyleObject, useStyletron } from 'styletron-react';

const itemStyles = {
  position: 'relative',
  fontSize: '14px',
  maxWidth: '100%',
  '@media screen and (min-width: 1200px)': {
    textOverflow: 'ellipsis',
    'white-space': 'nowrap',
    overflow: 'hidden',
  },
  '@media screen and (max-width: 1200px)': {
    'overflow-wrap': 'break-word',
    'word-break': 'break-all',
  },
} as StyleObject;

export type AppChipItemType = string | number | ReactNode | undefined;

type AppChipItemPropsType = {
  item: AppChipItemType
  hoverItem?: AppChipItemType
  id?: string
}

const AppChipItem = ({ item, hoverItem, id }: AppChipItemPropsType) => {
  const [css] = useStyletron();

  return (
    <div
      title={hoverItem?.toString() || (['string', 'number'].includes(typeof item) && item?.toString()) || ''}
      id={`AppChipItem-${id}`}
      key={item?.toString()}
      className={css(itemStyles)}
    >
      {item}
    </div>
  );
};

export default memo(AppChipItem);
