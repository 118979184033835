import * as Yup from 'yup';
import { commonAmountValidationSchema } from './commonSchema';
import messages from './messages';

export const newTransactionValidationSchema = Yup.object().shape({
  fromAccount: Yup.string().max(255, messages?.maxLength).required(messages?.required),
  toAccount: Yup.string().max(255, messages?.maxLength).required(messages?.required),
  reference: Yup.string().max(255, messages?.maxLength).required(messages?.required),
  amount: commonAmountValidationSchema(),
});

export const newRecoverFundsValidationSchema = Yup.object().shape({
  zendeskTicketReference: Yup
    .string()
    .max(19, messages?.maxLength)
    .matches(/^[0-9]*$/, messages.mustBeANumber)
    .required(messages?.required),
  amount: commonAmountValidationSchema(),
});

export const other = undefined;
