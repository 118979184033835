import {
  ChangeEvent,
  useState,
  useEffect,
  memo,
} from 'react';
import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Cell,
  ALIGNMENT,
} from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import {
  Input,
  SIZE,
} from 'baseui/input';
import { Search } from 'baseui/icon';
import {
  OnChangeParams,
  Select,
  Value,
} from 'baseui/select';
import { Card } from 'baseui/card';
import {
  directDepositReportDataPendingSelector,
  employeeDetailsConfigsAndDetailsSavedSelector,
  employeesNumPagesSelector,
  employeesPageNumberSelector,
  employeesPendingListSelector,
  employeesSearchEmploymentStatusSelector,
  employeesSearchEnrollmentStatusSelector,
  employeesSearchLocationSelector,
  employeesSearchPatternSelector,
  employeesSearchPayGroupSelector,
  employeesSelector,
  employeesTotalSizeSelector,
  fetchEmployeesWithConfigsLocationsAndGroups,
  fetchFilteredEmployees,
  resetEmployeeSearch,
  setEmployeesPage,
  setEmployeesPageSize,
  setSearchEmploymentStatus,
  setSearchEnrollmentStatus,
  setSearchLocation,
  setSearchPattern,
  setSearchPayGroup,
} from 'store/slices/employees';
import {
  locationsSelector,
} from 'store/slices/locations';
import {
  payGroupsSelector,
} from 'store/slices/payGroups';
import {
  useAppSelector,
  useAppDispatch,
} from 'store/hooks';
import Loader from 'components/Loader';
import CellFormControl from 'components/CellFormControl';
import EmployeeListItem from 'screens/Employees//EmployeeListItem';
import {
  employmentStatuses,
  enrollmentStatuses,
} from 'screens/Employees/EmployeesHelpers';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { Pagination } from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import Access, { AccessUnit } from 'components/Access/Access';
import {
  Button,
} from 'baseui/button';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import {
  prevPageSelector,
  setPrevPage,
} from 'store/slices/application';
import { ConfigType } from 'types/OrganizationTypes';
import {
  fetchOrganizationConfigs,
  organizationConfigSelector,
} from 'store/slices/organizations';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import GenerateDirectDepositModal from './GenerateDirectDepositModal/GenerateDirectDepositModal';

const blockOverrides = {
  Block: {
    style: {
      background: '#F3F3F3',
    },
  },
};

const containerStyles = {
  width: '100%',
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 100,
} as StyleObject;

let timer: any;

const Employees = () => {
  const dispatch = useAppDispatch();
  const organizationID = useAppSelector(loggedOrganizationSelector)?.id;
  const employees = useAppSelector(employeesSelector);
  const payGroups = useAppSelector(payGroupsSelector);
  const locations = useAppSelector(locationsSelector);
  const pendingList = useAppSelector(employeesPendingListSelector);
  const modals = useAppSelector(modalsSelector);
  const { t } = useTranslation(['common', 'employees']);
  const [css] = useStyletron();
  const search = useAppSelector(employeesSearchPatternSelector);
  const enrollmentStatus = useAppSelector(employeesSearchEnrollmentStatusSelector);
  const employmentStatus = useAppSelector(employeesSearchEmploymentStatusSelector);
  const payGroup = useAppSelector(employeesSearchPayGroupSelector);
  const location = useAppSelector(employeesSearchLocationSelector);
  const prevPage = useAppSelector(prevPageSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const successfullySaved = useAppSelector(employeeDetailsConfigsAndDetailsSavedSelector);

  const directDepositReportFilePending = useAppSelector(directDepositReportDataPendingSelector);
  const numPages = useAppSelector(employeesNumPagesSelector);
  const pageNumber = useAppSelector(employeesPageNumberSelector);
  const totalSize = useAppSelector(employeesTotalSizeSelector);
  const [hasPerformedSearch, setHasPerformedSearch] = useState<boolean>(false);
  const [searchEnrollmentStatusChanged, setSearchEnrollmentStatusHasChanged] = useState<boolean>(false);
  const [searchEmploymentStatusChanged, setSearchEmploymentStatusHasChanged] = useState<boolean>(false);
  const [searchPayGroupChanged, setSearchPayGroupHasChanged] = useState<boolean>(false);
  const [searchLocationChanged, setSearchLocationHasChanged] = useState<boolean>(false);

  const generateDirectDepositModalName = ModalNames.GENERATE_DIRECT_DEPOSIT_MODAL;
  const generateDirectDepositModal = modals.find((item) => item.name === generateDirectDepositModalName);

  // eslint-disable-next-line max-len
  const organizationDirectDepositEnabledOrg = organizationConfig?.find((item) => item.configKeyName === ConfigType.DIRECT_DEPOSIT)?.configValue === 'DIRECT_DEPOSIT_FUEGO';

  const handleGenerateDirectDepositReport = () => {
    dispatch(setModal({
      name: generateDirectDepositModalName,
      isOpen: true,
    }));
  };

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    dispatch(setSearchPattern(e.target.value));
    setHasPerformedSearch(true);
  };

  const handleEnrollmentStatusesChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(setSearchEnrollmentStatus(value));
    setSearchEnrollmentStatusHasChanged(true);
  };

  const handleEmploymentStatusesChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(setSearchEmploymentStatus(value));
    setSearchEmploymentStatusHasChanged(true);
  };

  const handlePayGroupChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(setSearchPayGroup(value));
    setSearchPayGroupHasChanged(true);
  };

  const handleLocationChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(setSearchLocation(value));
    setSearchLocationHasChanged(true);
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();

    dispatch(setEmployeesPage(pageNumber));
    let filter: any = {
      payGroupId: (payGroup && payGroup[0]?.id?.toString()) || '',
      locationId: (location && location[0]?.id?.toString()) || '',
      status: (employmentStatus && employmentStatus[0]?.id?.toString()) || '',
      pageNumber: page,
      namePattern: search?.trim() || '',
    };
    if (enrollmentStatus && enrollmentStatus[0]?.id?.toString()) {
      filter = {
        ...filter,
        workerId: enrollmentStatus[0]?.id?.toString() === 'null' ? '' : enrollmentStatus[0]?.id?.toString(),
      };
    }
    dispatch(fetchFilteredEmployees({
      organizationID,
      filter,
    }));
  };

  const [searchDebounce, setSearchDebounce] = useState<string | null>('');

  useEffect(() => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      setSearchDebounce(search);
    }, 1000);
  }, [search]);

  useEffect(() => {
    if (!prevPage.startsWith('/loggedOrganization/employees') || successfullySaved) {
      dispatch(resetEmployeeSearch());
      dispatch(fetchEmployeesWithConfigsLocationsAndGroups({ organizationID }));
      dispatch(fetchFilteredEmployees({
        organizationID,
        filter: {
          namePattern: '',
          pageNumber: '1',
          status: employmentStatuses.find((status) => status.id === 'ACTIVE')?.id?.toString() || '',
        },
      }));
    }
    dispatch(fetchOrganizationConfigs({ organizationID }));
    dispatch(setPrevPage(''));
    dispatch(setEmployeesPageSize('50'));
  }, []);
  // TODO: need to improve the design of how this component is implemented, this useEffect is too busy
  useEffect(() => {
    if (pendingList) return;

    const namePattern = search?.trim();
    const payGroupId = (payGroup && payGroup[0]?.id?.toString()) || '';
    const locationId = (location && location[0]?.id?.toString()) || '';
    const status = (employmentStatus && employmentStatus[0]?.id?.toString()) || '';
    const workerId = (enrollmentStatus && enrollmentStatus[0]?.id?.toString()) || '';

    if (((namePattern || namePattern === '') && hasPerformedSearch)
      || ((payGroupId || payGroupId === '') && searchPayGroupChanged)
      || ((locationId || locationId === '') && searchLocationChanged)
      || ((status || status === '') && searchEmploymentStatusChanged)
      || ((workerId || workerId === '') && searchEnrollmentStatusChanged)) {
      let filter: any = {
        payGroupId,
        locationId,
        status,
        pageNumber: '1',
      };
      if (namePattern || namePattern === '') {
        if (namePattern.trim().indexOf(' ') !== -1) {
          const [firstName, lastName] = namePattern.trim().split(' ');
          filter = { ...filter, firstName, lastName };
        } else {
          filter = { ...filter, namePattern: namePattern.trim() };
        }
      }
      if (enrollmentStatus && enrollmentStatus[0]?.id?.toString()) {
        filter = {
          ...filter,
          workerId: workerId === 'null' ? '' : workerId,
        };
      }
      dispatch(fetchFilteredEmployees({
        organizationID,
        filter,
      }));
    }
  }, [
    payGroup,
    location,
    enrollmentStatus,
    searchDebounce,
    employmentStatus,
  ]);

  return (
    <div className={css(containerStyles)}>
      <CommonHeader title={t('employees:employees')}>
        {organizationDirectDepositEnabledOrg && (
        <Access oneOf={[AccessUnit.EWAManager, AccessUnit.FDDClientManager]}>
          <Block
            alignItems="center"
            display="inline-flex"
            justifyContent="flex-end"
            minWidth="160px"
            height="72px"
          >
            <Button
              onClick={handleGenerateDirectDepositReport}
              disabled={directDepositReportFilePending}
              overrides={{
                Root: {
                  props: {
                    id: 'EmployeesHeader-generate-direct-deposit-report',
                  },
                },
              }}
            >
              {t('employees:generateDirectDepositReport.button')}
            </Button>
          </Block>
        </Access>
        ) }
        {generateDirectDepositModal?.isOpen && <GenerateDirectDepositModal />}
      </CommonHeader>

      <Loader active={pendingList} />

      <Block
        marginTop="24px"
        marginBottom="21px"
      >
        <Grid
          align={ALIGNMENT.center}
        >

          <Cell
            span={4}
            align={ALIGNMENT.center}
          >
            <Block
              marginTop="20px"
              display="flex"
              width="auto"
            >
              <Input
                clearable
                clearOnEscape
                startEnhancer={<Search />}
                type="text"
                name="search"
                onChange={handleChangeSearch}
                value={search || ''}
                placeholder={t('employees:search')}
                overrides={{
                  Input: {
                    props: {
                      id: 'Employees-search',
                      autoComplete: 'off',
                    },
                  },
                }}
              />
            </Block>
          </Cell>

          <CellFormControl
            cellSpan={2}
            label={`${t('employees:employmentStatus')}`}
            cellAlign={ALIGNMENT.center}
            formControlProps={{
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          >
            <Select
              size={SIZE.compact}
              clearable
              placeholder={t('common:all')}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'employees-employment-status-select',
                    id: 'employees-employment-status-select',
                  },
                },
              }}
              type="select"
              options={employmentStatuses.map(({ name, id }) => ({
                name,
                id,
              }))}
              labelKey="name"
              valueKey="id"
              onChange={handleEmploymentStatusesChange}
              value={employmentStatus as Value}
              maxDropdownHeight="300px"
            />
          </CellFormControl>

          <CellFormControl
            cellSpan={2}
            cellAlign={ALIGNMENT.center}
            label={`${t('employees:enrollmentStatus')}`}
            formControlProps={{
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          >
            <Select
              size={SIZE.compact}
              clearable
              placeholder={t('common:all')}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'employees-enrollment-status-select',
                    id: 'employees-enrollment-status-select',
                  },
                },
              }}
              type="select"
              options={enrollmentStatuses.map(({ name, id }) => ({
                name,
                id,
              }))}
              labelKey="name"
              valueKey="id"
              onChange={handleEnrollmentStatusesChange}
              value={enrollmentStatus as Value}
              maxDropdownHeight="300px"
            />
          </CellFormControl>

          <CellFormControl
            cellSpan={2}
            label={`${t('employees:payGroups')}`}
            cellAlign={ALIGNMENT.center}
            formControlProps={{
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          >
            <Select
              size={SIZE.compact}
              clearable
              placeholder={t('common:all')}
              type="select"
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'employees-pay-group-select',
                    id: 'employees-pay-group-select',
                  },
                },
              }}
              options={payGroups
                ?.map(({ name, id }) => ({
                  name,
                  id,
                }))
                ?.sort((a, b) => a.name.localeCompare(b.name))}
              labelKey="name"
              valueKey="id"
              onChange={handlePayGroupChange}
              value={payGroup as Value}
              maxDropdownHeight="300px"
            />
          </CellFormControl>

          <CellFormControl
            cellSpan={2}
            label={`${t('employees:locations')}`}
            cellAlign={ALIGNMENT.center}
            formControlProps={{
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          >
            <Select
              size={SIZE.compact}
              clearable
              placeholder={t('common:all')}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'employees-location-select',
                    id: 'employees-location-select',
                  },
                },
              }}
              options={locations
                ?.map(({ name, id }) => ({
                  name,
                  id,
                }))
                ?.sort((a, b) => a.name.localeCompare(b.name))}
              type="select"
              labelKey="name"
              valueKey="id"
              onChange={handleLocationChange}
              value={location as Value}
              maxDropdownHeight="300px"
            />
          </CellFormControl>
        </Grid>
      </Block>

      <Block overrides={blockOverrides}>
        <Grid
          align={ALIGNMENT.center}
        >
          <Cell
            span={12}
          >
            {employees?.length > 0 && (
              <>
                {employees
                  ?.map((employee) => <EmployeeListItem key={employee.id} employee={employee} />)}

                <Block
                  display="flex"
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                  justifyItems="center"
                  marginBottom="16px"
                >
                  <Pagination
                    size={SIZE.compact}
                    numPages={numPages}
                    currentPage={pageNumber}
                    overrides={paginationTransparentOverrides}
                    onPageChange={handlePageChange}
                  />
                </Block>
              </>
            )}

            {employees?.length === 0 && (
              <Block
                marginTop="24px"
                marginBottom="24px"
              >
                <Card>
                  <p>{t('employees:notFound')}</p>
                </Card>
              </Block>
            )}

          </Cell>
        </Grid>
      </Block>
    </div>
  );
};

export default memo(Employees);
