import { OrganizationSectionNameType, OrganizationSectionStatusesResponseType } from '../types/OrganizationTypes';

const findSectionStatusByName = (
  sectionName: OrganizationSectionNameType | undefined,
  sectionStatusesList: OrganizationSectionStatusesResponseType[],
) => {
  const sectionItem = sectionStatusesList?.find((item) => item.sectionName === sectionName);

  return { sectionName, sectionID: sectionItem?.id, name: sectionItem?.status };
};

export default findSectionStatusByName;
