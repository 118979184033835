import { useStyletron } from 'styletron-react';
import { borderRadius, emptyPlaceholder } from 'theme';
import { useTranslation } from 'react-i18next';
import AppChip from 'components/AppChip/AppChip';
import { Button, KIND } from 'baseui/button';
import { WorkerEmployments } from 'types/WorkerTypes';
import { OrganizationIDType } from 'types/OrganizationTypes';
import { EmployeeAssignment, EmploymentType } from 'types/EmployeeTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';

const listStyles = {
  padding: '0px',
};

const listItemStyles = {
  background: '#FFFFFF',
  'list-style': 'none',
  padding: '8px',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
};

const listItemNameAndStatuses = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  marginLeft: '4px',
  paddingTop: '14px',
  paddingBottom: '14px',
  paddingRight: '10px',
  '@media screen and (max-width: 768px)': {
    'flex-direction': 'column',
  },
};

const listItemInfoStyles = {
  display: 'flex',
  'flex-direction': 'row',
  alignItems: 'center',
  padding: '0px 4px 16px 20px',
  '@media screen and (max-width: 768px)': {
    'flex-direction': 'column',
    marginRight: '0px',
    alignItems: 'center',
  },
};

const detailsStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, max-content)',
  placeItems: 'center',
  marginRight: '16px',
  marginTop: '4px',
  '@media screen and (max-width: 768px)': {
    gridTemplateColumns: '1fr',
    gap: '4px 0',
    marginRight: '0px',
  },
};

const WorkerEmploymentsListItem = ({
  employment,
  visitEmployment,
}: {
  employment: Partial<WorkerEmployments> & Partial<EmploymentType>,
  visitEmployment: (organization: { name: string, id: OrganizationIDType } | undefined, employeeId: string) => void,
}) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'workers', 'employees']);

  return (
    <ul className={css(listStyles)}>
      <li className={css(listItemStyles)}>
        <div className={css(listItemNameAndStatuses)}>
          <Button
            kind={KIND.tertiary}
            onClick={() => visitEmployment(employment?.organization, String(employment.id))}
            overrides={{
              BaseButton: {
                style: {
                  ...listItemNameAndStatuses,
                  marginLeft: '0px',
                },
              },
            }}
          >
            <span className="ellipsis">{`${employment?.firstName} ${employment?.lastName}`}</span>
            &nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon
              title={t('workers:goToEmployment')}
              size="lg"
              className={css({ cursor: 'pointer', color: 'rgb(187, 0, 47)' })}
              icon={faRightToBracket}
            />
          </Button>
        </div>
        <div className={css(listItemInfoStyles)}>
          <span className={css(detailsStyles)}>
            {t('workers:organization')}
            :
            <AppChip
              items={employment?.organization?.name || emptyPlaceholder}
            />
          </span>

          <span className={css(detailsStyles)}>
            {t('workers:payrollNumber')}
            :
            <AppChip
              items={employment?.payrollId || emptyPlaceholder}
            />
          </span>
        </div>
        <div className={css(listItemInfoStyles)}>
          <span className={css(detailsStyles)}>
            {t('employees:mobileNumber')}
            :
            <AppChip
              items={employment?.phone || emptyPlaceholder}
            />
          </span>

          <span className={css(detailsStyles)}>
            {t('workers:email')}
            :
            <AppChip
              items={employment.email || emptyPlaceholder}
            />
          </span>
        </div>
        {employment?.assignments?.length && (
          <div className={css({ ...listItemInfoStyles, 'flex-direction': 'column', alignItems: 'start' })}>
            <span className={css({ fontWeight: 'bold' })}>
              {t('workers:assignments')}
            </span>
            {employment?.assignments?.map((assignment: EmployeeAssignment) => (
              <div key={assignment.id} className={css({ marginTop: '10px' })}>
                {`${assignment.name} (${assignment.location?.name})`}
                {assignment.primaryLocation ? t('workers:primaryLocation') : null}
              </div>
            ))}
          </div>
        )}
        <hr />
      </li>
    </ul>
  );
};

export default WorkerEmploymentsListItem;
