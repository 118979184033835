export type MoneyDtoType = {
  value: number
  scale: number
  currencyCode: string
}

export enum APIErrorCodeType {
  SERVER_ERROR = 'SERVER_ERROR',
  CONFIGURATION_ERROR = 'CONFIGURATION_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  INTEGRATION_ERROR = 'INTEGRATION_ERROR',
  OFFER_ERROR = 'OFFER_ERROR',
  DRAW_ERROR = 'DRAW_ERROR',
  DRAW_BATCH_ERROR = 'DRAW_BATCH_ERROR',
  REPAYMENT_ERROR = 'REPAYMENT_ERROR',
  DUPLICATE_USERNAME = 'DUPLICATE_USERNAME',
  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
  DUPLICATE_PHONE_NUMBER = 'DUPLICATE_PHONE_NUMBER',
  FUEGO_VERIFICATION_TOKEN_EXPIRED = 'FUEGO_VERIFICATION_TOKEN_EXPIRED',
  FUEGO_VERIFICATION_TOKEN_INVALID = 'FUEGO_VERIFICATION_TOKEN_INVALID',
  INACTIVE_ORGANIZATION = 'INACTIVE_ORGANIZATION',
  EMPLOYMENT_NOT_LINKED = 'EMPLOYMENT_NOT_LINKED',
  INVALID_DEVICE_ATTESTATION_CODE = 'INVALID_DEVICE_ATTESTATION_CODE',
  DEVICE_NOT_ATTESTED = 'DEVICE_NOT_ATTESTED',
}

export type APIError = {
  code: APIErrorCodeType
  details: string
  messages: string[]
  requestId?: string
};

export type SearchDropDown = {
  name: string,
  id: string | number,
};

export type InputCustomHTMLElement = HTMLTextAreaElement | HTMLInputElement

export type OptionalDateOrDateArrayType = Date | (Date | null | undefined)[] | null | undefined

export enum states {
  AE = 'AE',
  AL = 'AL',
  AK = 'AK',
  AP = 'AP',
  AS = 'AS',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FM = 'FM',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MH = 'MH',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MP = 'MP',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PW = 'PW',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VI = 'VI',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}
