import { Block } from 'baseui/block';
import { ALIGNMENT, Cell } from 'baseui/layout-grid';
import { memo, ReactNode } from 'react';
import {
  ParagraphMedium,
} from 'baseui/typography';

export type OfferCalculationListItemPropsType = {
  title: string | number | ReactNode,
  additional?: string | number | ReactNode,
  titleWidth?: string,
  additionalWidth?: string,
}

const OfferCalculationListItem = ({
  title,
  additional,
  titleWidth,
  additionalWidth,
}: OfferCalculationListItemPropsType) => (
  <Cell
    align={ALIGNMENT.center}
    span={12}
  >
    <Block
      height="24px"
      overrides={{
        Block: {
          style: { borderBottom: '1px solid #B0B0B0' },
        },
      }}
    >
      <ParagraphMedium
        display="inline-flex"
        justifyContent="start"
        margin={0}
        width={titleWidth || '70%'}
      >
        {title}
      </ParagraphMedium>

      <ParagraphMedium
        display="inline-flex"
        justifyContent="end"
        margin={0}
        width={additionalWidth || '30%'}
      >
        {additional}
      </ParagraphMedium>
    </Block>
  </Cell>
);

export default memo(OfferCalculationListItem);
