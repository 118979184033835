import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import {
  Block,
  BlockProps,
} from 'baseui/block';
import { colors } from 'theme';
import AppChipListItems from './AppChipListItems';
import AppChipItem, { AppChipItemType } from './AppChipItem';

export type AppChipPropsType = {
  items?: AppChipItemType | AppChipItemType[] | undefined
  hoverItem?: AppChipItemType | AppChipItemType[] | undefined
  blockProps?: BlockProps
  isExpanded?: boolean
  onClick?: ((e: Event) => any) | undefined
  id?: string
}

const AppChip = ({
  items,
  hoverItem,
  isExpanded,
  blockProps,
  onClick,
  id,
}: AppChipPropsType) => {
  const [css] = useStyletron();
  const isMoreThanOne = !!(Array.isArray(items) && items.length > 1);

  const containerStyles = {
    display: 'inline-flex',
    position: 'relative',
    width: 'auto',
    maxWidth: '100%',
    padding: '4px 8px',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    border: '1px solid #ccc',
    background: '#eee',
    maxHeight: '18px',
    overflow: 'visible',
    cursor: onClick ? 'pointer' : 'default',
    margin: '8px',
    ':hover': onClick && {
      background: colors.primaryHover,
      color: '#fff',
    },
    '@media screen and (max-width: 1200px)': {
      maxWidth: '600px',
      maxHeight: '200px',
    },
    '@media screen and (max-width: 768px)': {
      maxWidth: '84%',
      maxHeight: '200px',
      margin: '8px',
    },
  } as StyleObject;

  return (
    <Block
      id={`AppChip-container-${id}`}
      data-testid="AppChip-container"
      className={css(containerStyles)}
      display="inline-block"
      position="relative"
      onClick={!isMoreThanOne ? onClick : undefined}
      {...blockProps}
    >
      {isMoreThanOne
        ? <AppChipListItems items={items} isExpanded={isExpanded} />
        : <AppChipItem item={items} hoverItem={hoverItem} id={id} />}
    </Block>
  );
};

export default AppChip;
