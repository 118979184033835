import {
  Key,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useStyletron } from 'styletron-react';
import {
  Accordion,
  Panel,
} from 'baseui/accordion';
import { Block } from 'baseui/block';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import PriceFormatter from 'utils/priceFormatter';
import {
  accordionAllocationListItemOverrides,
  listContainerStyles,
  outerContainerStyles,
} from 'screens/Batches/BatchesHelpers';
import {
  allocatedTPOBatchEmploymentsPendingListSelector,
  allocatedTPOBatchEmploymentsList,
  fetchAllocatedTPOBatchEmploymentPaymentAllocations,
} from 'store/slices/paymentAllocation';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { unScalePrice } from 'utils/priceScale';
import Loader from 'components/Loader';
import { Card } from 'baseui/card';
import HighLightSearch from 'components/HighLightSearch';
import {
  AllocationBatchType,
} from 'types/RepaymentTypes';
import { StatefulTooltip } from 'baseui/tooltip';
import { EmploymentIDType } from 'types/EmployeeTypes';
import TPOAllocatedPaymentsEmploymentListItem from './TPOAllocatedPaymentsEmploymentListItem';

export type TPOAllocatedPaymentsListItemPropsType = {
  allocationBatch: AllocationBatchType
  search?: string
}

const TPOAllocatedPaymentsListItem = ({
  allocationBatch,
  search,
  ...rest
}: TPOAllocatedPaymentsListItemPropsType) => {
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const { t } = useTranslation(['batches', 'dateFormats']);
  const employmentsTPO = useAppSelector(allocatedTPOBatchEmploymentsList);

  const pending = useAppSelector(allocatedTPOBatchEmploymentsPendingListSelector);
  const {
    id,
    payGroup,
    payrollPeriod,
    amount,
    amountDue,
    reference,
    allocatedAmount,
  } = allocationBatch || {};
  const batchID = id?.toString();
  const hasTPOEmployments = employmentsTPO?.length > 0;
  const dateFormat = t('dateFormats:standard');

  const handleAccordionChange = ({ expanded }: { expanded: Key[] }) => {
    if (expanded[0]) {
      dispatch(fetchAllocatedTPOBatchEmploymentPaymentAllocations(
        {
          batchID,
          employmentID: expanded[0] as EmploymentIDType,
        },
      ));
    }
  };

  return (
    <>
      <div className={css(outerContainerStyles)}>
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
          gridMargins={30}
        >
          <Cell
            align={ALIGNMENT.center}
            span={[2, 3]}
          >
            <HighLightSearch
              search={search}
            >
              <StatefulTooltip
                accessibilityType="tooltip"
                content={reference}
                placement="topLeft"
              >
                <span
                  className="ellipsis"
                  style={{
                    marginTop: '4px',
                    width: '100%',
                    display: 'inline-block',
                  }}
                >
                  {reference}
                </span>
              </StatefulTooltip>
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={[2, 3]}
          >
            <HighLightSearch
              search={search}
            >
              <StatefulTooltip
                accessibilityType="tooltip"
                content={payGroup?.name}
                placement="topLeft"
              >
                <span
                  className="ellipsis"
                  style={{
                    marginTop: '4px',
                    width: '100%',
                    display: 'inline-block',
                  }}
                >
                  {payGroup?.name}
                </span>
              </StatefulTooltip>
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <HighLightSearch search={search}>
              {`${moment(payrollPeriod?.startDate).format(dateFormat)} - ${moment(payrollPeriod?.endDate).format(dateFormat)}`}
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <HighLightSearch search={search}>
              {PriceFormatter().format(unScalePrice(amount?.value, amount?.scale))}
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={[2, 1]}
          >
            <HighLightSearch search={search}>
              {PriceFormatter().format(unScalePrice(amountDue?.value, amountDue?.scale))}
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <HighLightSearch search={search}>
              {PriceFormatter().format(unScalePrice(allocatedAmount?.value, allocatedAmount?.scale))}
            </HighLightSearch>
          </Cell>
        </Grid>
      </div>

      <Panel
        key={batchID as Key}
        {...rest}
      >
        {hasTPOEmployments && (
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
          gridMargins={4}
        >
          <Cell
            align={ALIGNMENT.center}
            span={4}
          >
            <strong>{t('batches:paymentAllocations.employee')}</strong>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <strong>{t('batches:paymentAllocations.payrollNumber')}</strong>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <strong>{t('batches:paymentAllocations.totalAmount')}</strong>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <strong>{t('batches:paymentAllocations.allocatedAmount')}</strong>
          </Cell>
        </Grid>
        )}

        <div className={css(listContainerStyles)}>
          <Accordion
            onChange={handleAccordionChange}
            overrides={accordionAllocationListItemOverrides}
          >
            <Loader active={pending} />

            {[...employmentsTPO]
              ?.sort((a, b) => `${a.firstName} ${a.firstName}`.localeCompare(`${b.firstName} ${b.lastName}`))
              ?.map((employment) => (
                <TPOAllocatedPaymentsEmploymentListItem
                  key={employment?.id}
                  employment={employment}
                />
              ))}

            {!pending && !hasTPOEmployments && (
            <Block
              marginTop="24px"
            >
              <Grid
                gridColumns={12}
                gridMargins={0}
              >
                <Cell span={12}>
                  <Card>
                    {t('repayments:notFoundEmployments')}
                  </Card>
                </Cell>
              </Grid>
            </Block>
            )}
          </Accordion>
        </div>
      </Panel>
    </>
  );
};

export default TPOAllocatedPaymentsListItem;
