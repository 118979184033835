import { Block } from 'baseui/block';
import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  LabelMedium,
  ParagraphMedium,
} from 'baseui/typography';
import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  paycardOrganizationRegisterCardEmployeeDetailsSelector,
  paycardOrganizationRegisterCardPaymentDetailsSelector,
} from 'store/slices/paycards';
import { emptyPlaceholder } from 'theme';

const PaycardManagementRegisterCardReviewAndRegisterCard = () => {
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const employeeDetails = useAppSelector(paycardOrganizationRegisterCardEmployeeDetailsSelector);
  const paymentDetails = useAppSelector(paycardOrganizationRegisterCardPaymentDetailsSelector);
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);

  const dateFormat = t('dateFormats:standard');

  return (
    <Grid
      gridColumns={12}
      gridMargins={24}
      gridGutters={3}
      overrides={{
        Grid: {
          style: {
            padding: '18px',
          },
        },
      }}
    >
      <Cell span={[12, 6, 3]}>
        <Block>
          <LabelMedium>{t('paycardManagement:paycardManagement.registerCard.review.payrollId')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{employeeDetails?.payrollId || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.registerCard.review.employee')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{`${employeeDetails?.firstName} ${employeeDetails?.lastName}` || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.registerCard.review.birthDate')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{moment(employeeDetails?.birthDate.toString()).format(dateFormat) || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.registerCard.review.ssn')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{`***-**-${employeeDetails?.ssn.slice(-4)}`}</ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.registerCard.review.mobileNumber')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{employeeDetails?.mobileNumber.replaceAll('-', ' ') || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.registerCard.review.address')}</LabelMedium>
          <ParagraphMedium marginTop="4px" marginBottom="0px">{employeeDetails?.address || emptyPlaceholder}</ParagraphMedium>
          {employeeDetails?.unit && <ParagraphMedium margin={0}>{employeeDetails?.unit}</ParagraphMedium>}
          <ParagraphMedium margin={0}>{`${employeeDetails?.city}, ${employeeDetails?.state[0].value}`}</ParagraphMedium>
          <ParagraphMedium margin={0}>{employeeDetails?.zip}</ParagraphMedium>
        </Block>
      </Cell>
      <Cell span={[12, 6]}>
        <Block>
          <LabelMedium>{t('paycardManagement:paycardManagement.registerCard.review.proxyNumber')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{paymentDetails?.proxyNumber || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.registerCard.review.loadedFunds')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{`$ ${paymentDetails?.funds}` || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.registerCard.review.paymentDescription')}</LabelMedium>
          <ParagraphMedium marginTop="4px">
            {paymentDetails?.paymentDescription
              || `(${t('common:default')}) ${t(
                'paycardManagement:paycardManagement.registerCard.pairCard.paymentDescription.placeholder',
                { orgName: `${loggedOrganization?.name || ''}` },
              )}`}
          </ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.registerCard.review.internalNotes.label')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{paymentDetails?.internalNotes || emptyPlaceholder}</ParagraphMedium>
        </Block>
      </Cell>
    </Grid>
  );
};

export default memo(PaycardManagementRegisterCardReviewAndRegisterCard);
