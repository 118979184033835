import { AssignLocationsFormattedArray } from 'types/PayGroupTypes';

export const payGroupInitialValues = {
  id: null as number | null,
  payGroupName: '',
  payCycle: [{ value: 'WEEKLY' }],
  deductionCode: '',
  payGroupClientID: '',
  businessDayStartTime: new Date(new Date().setHours(9, 0, 0, 0)),
  ewaCutoffTime: new Date(new Date().setHours(11, 0, 0, 0)),
  tpoCutoffTime: new Date(new Date().setHours(11, 0, 0, 0)),
  payrollCutoffTime: new Date(new Date().setHours(11, 0, 0, 0)),
  organizationTimezone: '',
  payrollPeriodStartDay: [{ value: 'TUESDAY' }],
  paydayOffset: [{ value: 0 }],
  payrollCutoffDayOffset: [{ value: 0 }],
  batchDeliveryEmailAddresses: '',
  automaticPayrollPeriodGenerationEnabled: false,
  ewaCutoffDayOffset: [{ value: 0 }],
  tpoCutoffDayOffset: [{ value: 0 }],
  externalIntegrationCode: '',
  bankAccount: {
    bankAccountId: null as string | null,
    bankAccountAlias: null as string | null,
  },
};

export const assignLocationsInitialValues = {
  relations: [] as AssignLocationsFormattedArray[],
};

export const payrollInitialValues = {
  id: null as number | null,
  workerId: '',
  organizationId: null as number | null,
  externalUserId: '',
  statusType: '',
  statusEffectiveDate: new Date(),
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  payrollId: null as number | null,
  organizationName: '',
  drawsTotalAmount: {
    value: null as number | null,
    scale: null as number | null,
    currencyCode: '',
  },
};
