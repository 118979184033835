/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeEvent,
  useState,
  useEffect,
  memo,
} from 'react';
import { useStyletron } from 'styletron-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Cell,
} from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import {
  Input,
  SIZE,
} from 'baseui/input';
import {
  OnChangeParams,
  Select,
  Value,
} from 'baseui/select';
import { Search } from 'baseui/icon';
import { Card } from 'baseui/card';
import {
  payGroupsSelector,
  fetchPayrollPeriods,
  payGroupPayrollPeriodsSelector,
  fetchPayGroups,
} from 'store/slices/payGroups';
import {
  loggedOrganizationSelector,
} from 'store/slices/loggedOrganization';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  fetchAllBatches,
  batchesSelector,
  batchesPendingListSelector,
  batchesPageNumberSelector,
  batchesNumPagesSelector,
  batchesTotalSizeSelector,
  batchesSearchPatternSelector,
  batchesSearchPayGroupSelector,
  batchesSearchPayPeriodSelector,
  batchesSearchBatchStatusSelector,
  batchesSearchShowEmptyBatchesSelector,
  batchesSearchDateCreatedSelector,
  batchesSearchBatchCreationTriggerSelector,
  setEWASearchPattern,
  setEWASearchPayGroup,
  setEWASearchPayPeriod,
  setEWASearchBatchCreationTrigger,
  setEWASearchBatchStatus,
  setEWASearchShowEmptyBatches,
  setEWASearchDateCreated,
  resetEWABatchSearch,
} from 'store/slices/batches';
import Loader from 'components/Loader';
import {
  containerStyles,
} from 'screens/Batches/BatchesHelpers';
import {
  BatchCreationTriggers,
  BatchItem,
  BatchStatuses,
  FetchAllBatchesParamsType,
} from 'types/BatchTypes';
import { unScalePrice } from 'utils/priceScale';
import { Pagination } from 'baseui/pagination';
import { blockOverridesRelative, paginationTransparentOverrides } from 'screens/CommonHelpers';
import { DatePicker } from 'baseui/datepicker';
import {
  Checkbox,
  LABEL_PLACEMENT,
} from 'baseui/checkbox';
import { ForwardedInput } from 'components/Form/AppDatePicker';
import { prevPageSelector, setPrevPage } from 'store/slices/application';
import { LabelSmall } from 'baseui/typography';
import { InputCustomHTMLElement, OptionalDateOrDateArrayType } from 'types/CommonTypes';
import BatchesListItem from './BatchesListItem/BatchesListItem';

const EWABatchesSection = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'employees', 'batches', 'dateFormats']);
  const [css] = useStyletron();
  const organization = useAppSelector(loggedOrganizationSelector);
  const payGroups = useAppSelector(payGroupsSelector);
  const payGroupPayrollPeriods = useAppSelector(payGroupPayrollPeriodsSelector);
  const batches = useAppSelector(batchesSelector);
  const pendingList = useAppSelector(batchesPendingListSelector);
  const pageNumber = useAppSelector(batchesPageNumberSelector);
  const totalSize = useAppSelector(batchesTotalSizeSelector);
  const numPages = useAppSelector(batchesNumPagesSelector);

  const { id: organizationID } = organization || {};

  const search = useAppSelector(batchesSearchPatternSelector);
  const payGroup = useAppSelector(batchesSearchPayGroupSelector);
  const payrollPeriod = useAppSelector(batchesSearchPayPeriodSelector);
  const batchStatus = useAppSelector(batchesSearchBatchStatusSelector);
  const emptyChecked = useAppSelector(batchesSearchShowEmptyBatchesSelector);
  const batchCreationTrigger = useAppSelector(batchesSearchBatchCreationTriggerSelector);
  const creationDate = useAppSelector(batchesSearchDateCreatedSelector);
  const prevPage = useAppSelector(prevPageSelector);

  const [searchPayGroupChanged, setSearchPayGroupHasChanged] = useState<boolean>(false);
  const [searchPayPeriodChanged, setSearchPayPeriodHasChanged] = useState<boolean>(false);
  const [searchBatchStatusChanged, setSearchBatchStatusHasChanged] = useState<boolean>(false);
  const [searchCreationTriggerChanged, setSearchCreationTriggerHasChanged] = useState<boolean>(false);
  const [searchCreationDateChanged, setSearchCreationDateHasChanged] = useState<boolean>(false);
  const [searchEmptyCheckedChanged, setSearchEmptyCheckedHasChanged] = useState<boolean>(false);

  const dateFormat = t('dateFormats:standard');
  const datePickerDateFormat = t('dateFormats:date-picker.standard');
  const datePickerPlaceholder = t('dateFormats:date-picker.placeholder');
  const dateFormatAPI = t('dateFormats:standard-reverse');

  const datePickerOverridesExtended = (id: string) => ({
    Input: {
      props: {
        id,
        autoComplete: 'off',
        overrides: {
          Input: {
            component: ForwardedInput,
            props: {
              autoComplete: 'off',
            },
          },
        },
      },
    },
  });

  const batchStatuses = [
    {
      id: 1,
      value: BatchStatuses.NEW,
      label: t(`batches:statuses.${BatchStatuses.NEW}`),
    },
    {
      id: 2,
      value: BatchStatuses.PARTIALLY_PAID,
      label: t(`batches:statuses.${BatchStatuses.PARTIALLY_PAID}`),
    },
    {
      id: 3,
      value: BatchStatuses.PAID_IN_FULL,
      label: t(`batches:statuses.${BatchStatuses.PAID_IN_FULL}`),
    },
    {
      id: 4,
      value: BatchStatuses.SENT_TO_PAYROLL,
      label: t(`batches:statuses.${BatchStatuses.SENT_TO_PAYROLL}`),
    },
    {
      id: 5,
      value: BatchStatuses.CANCELED,
      label: t(`batches:statuses.${BatchStatuses.CANCELED}`),
    },
    {
      id: 6,
      value: BatchStatuses.FAILED,
      label: t(`batches:statuses.${BatchStatuses.FAILED}`),
    },
  ];
  const batchGenerationOptions = [
    {
      id: 1,
      value: BatchCreationTriggers.ADMIN_API,
      label: t(`batches:creationTrigger.${BatchCreationTriggers.ADMIN_API}`),
    },
    {
      id: 2,
      value: BatchCreationTriggers.SCHEDULER,
      label: t(`batches:creationTrigger.${BatchCreationTriggers.SCHEDULER}`),
    },
  ];
  const handleCreationDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    if (date !== null || date !== undefined) {
      setSearchCreationDateHasChanged(true);
      if (date && !Array.isArray(date)) {
        dispatch(setEWASearchDateCreated(date as Date));
      } else if (!date) {
        dispatch(setEWASearchDateCreated(undefined));
      }
    }
  };
  const hasSearchWordInBatches = (item: BatchItem) => {
    const searchWord = search?.trim().toLowerCase();

    if (searchWord) {
      const {
        batchReference,
        payrollPeriodStartDate,
        payrollPeriodEndDate,
        status,
        payGroupPayFrequencyRule,
        subElementsAmount,
      } = item || {};

      const normalizeString = (text: string | number) => text?.toString().trim().toLowerCase();

      const unscaledAmount = subElementsAmount ? unScalePrice(subElementsAmount?.value, subElementsAmount?.scale) : 0;

      return searchWord
        && (
          normalizeString(moment(payrollPeriodStartDate).format(dateFormat))?.includes(searchWord)
          || normalizeString(moment(payrollPeriodEndDate).format(dateFormat))?.includes(searchWord)
          || normalizeString(batchReference)?.includes(searchWord)
          || normalizeString(payGroupPayFrequencyRule)?.includes(searchWord)
          || normalizeString(status)?.includes(searchWord)
          || normalizeString(unscaledAmount)?.includes(searchWord)
        );
    }

    return true;
  };

  const filteredBatches = batches?.filter(hasSearchWordInBatches);

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    dispatch(setEWASearchPattern(e.target.value));
  };

  const handleEmptyBatchesChanged = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEWASearchShowEmptyBatches(e.currentTarget.checked));
    setSearchEmptyCheckedHasChanged(true);
  };

  const handlePayGroupChange = ({
    value,
  }: OnChangeParams) => {
    setSearchPayGroupHasChanged(true);
    dispatch(setEWASearchPayGroup(value));
    dispatch(setEWASearchPayPeriod([]));
  };

  const handlePayrollPeriodChange = ({
    value,
  }: OnChangeParams) => {
    setSearchPayPeriodHasChanged(true);
    dispatch(setEWASearchPayPeriod(value));
  };

  const handleStatusChange = ({
    value,
  }: OnChangeParams) => {
    setSearchBatchStatusHasChanged(true);
    dispatch(setEWASearchBatchStatus(value));
  };
  const handleTriggerChange = ({
    value,
  }: OnChangeParams) => {
    setSearchEmptyCheckedHasChanged(true);
    setSearchCreationTriggerHasChanged(true);
    dispatch(setEWASearchBatchCreationTrigger(value));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();

    dispatch(fetchAllBatches({
      organizationID,
      pageNumber: page,
      status: (batchStatus && batchStatus[0]?.value?.toString()) || '',
      creationTrigger: ((batchCreationTrigger && batchCreationTrigger[0]?.value?.toString()) || ''),
      payGroupId: (payGroup && Object.keys(payGroup).length !== 0 && payGroup[0].id?.toString()) || '',
      payrollPeriodId: (payrollPeriod && Object.keys(payrollPeriod).length !== 0 && payrollPeriod[0].id?.toString()) || '',
      types: 'EWA',
    }));
  };

  useEffect(() => {
    const filter = new Map([
      ['organizationID', organizationID],
      ['pageNumber', '1'],
      ['status', (batchStatus && batchStatus[0]?.value?.toString()) || ''],
      ['creationTrigger', ((batchCreationTrigger && batchCreationTrigger[0]?.value?.toString()) || '')],
      ['payGroupId', (payGroup && Object.keys(payGroup).length !== 0 && payGroup[0].id?.toString()) || ''],
      ['payrollPeriodId', (payrollPeriod && Object.keys(payrollPeriod).length !== 0 && payrollPeriod[0].id?.toString()) || ''],
      ['types', 'EWA'],
    ]);

    if (emptyChecked) {
      filter.set('deductionCount', 0);
    }
    if (creationDate) {
      filter.set('creationDate', moment(creationDate.toString()).format(dateFormatAPI));
    }

    if (searchPayGroupChanged || searchPayPeriodChanged || searchBatchStatusChanged
        || searchCreationTriggerChanged || searchEmptyCheckedChanged || searchCreationDateChanged) {
      dispatch(fetchAllBatches(Object.fromEntries(filter) as FetchAllBatchesParamsType));
    }
  }, [batchStatus,
    batchCreationTrigger,
    emptyChecked,
    creationDate,
    payGroup,
    payrollPeriod,
    searchPayGroupChanged,
    searchPayPeriodChanged,
    searchBatchStatusChanged,
    searchCreationTriggerChanged,
    searchEmptyCheckedChanged,
    searchCreationDateChanged,
  ]);

  useEffect(() => {
    const statuses = 'OPEN, LOCKED, CLOSED';

    if (organizationID && payGroup && payGroup[0]?.id && searchPayGroupChanged) {
      dispatch(fetchPayrollPeriods({ organizationID, payGroupID: payGroup[0]?.id?.toString(), statuses }));
    }
  }, [organizationID, payGroup, searchPayGroupChanged]);

  useEffect(() => {
    if (!prevPage.startsWith('/batches')) {
      dispatch(fetchAllBatches({
        organizationID,
        pageNumber: '1',
        status: '',
        creationTrigger: '',
        payGroupId: '',
        payrollPeriodId: '',
        creationDate: '',
        types: 'EWA',
      })).then(() => {
        dispatch(fetchPayGroups({ organizationID }));
      });
      dispatch(resetEWABatchSearch());
    }
    dispatch(setPrevPage(''));
  }, []);

  return (
    <div className={css(containerStyles)}>

      <Grid gridColumns={12}>
        <Cell span={12}>
          <b>
            {t('common:searchBy')}
          </b>
        </Cell>

        <Cell
          span={[12, 12, 3]}
        >
          <Block
            marginTop="32px"
            display="flex"
            width="auto"
            marginBottom="20px"
          >
            <Input
              clearable
              startEnhancer={<Search />}
              type="text"
              name="search"
              autoComplete="off"
              id="batch-management-search"
              onChange={handleChangeSearch}
              value={search}
              clearOnEscape
              placeholder={t('common:filterPlaceholder')}
            />
          </Block>
        </Cell>

        <Cell
          span={[12, 6, 3]}
        >
          <Block
            display="flex"
            flexDirection="column"
            width="auto"
            marginBottom="20px"
          >
            <LabelSmall padding="8px 0px">{t('common:payGroup')}</LabelSmall>
            <Select
              maxDropdownHeight="300px"
              placeholder={t('common:select')}
              type="select"
              clearable
              options={payGroups
                ?.map(({ name, id }) => ({
                  name,
                  id,
                }))
                ?.sort((a, b) => a.name.localeCompare(b.name))}
              labelKey="name"
              valueKey="id"
              onChange={handlePayGroupChange}
              value={payGroup as Value}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'batch-management-pay-groups',
                    id: 'batch-management-pay-group-select',
                  },
                },
              }}
            />
          </Block>
        </Cell>

        <Cell
          span={[12, 6, 3]}
        >
          <Block
            display="flex"
            flexDirection="column"
            width="auto"
            marginBottom="20px"
          >
            <LabelSmall padding="8px 0px">{t('common:payPeriod')}</LabelSmall>
            <Select
              maxDropdownHeight="300px"
              clearable
              disabled={!payGroup || payGroup?.length === 0}
              type="select"
              options={payGroupPayrollPeriods?.map(({ startDate, endDate, id }) => ({
                name: `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`,
                id,
              }))}
              labelKey="name"
              valueKey="id"
              onChange={handlePayrollPeriodChange}
              value={payrollPeriod as Value}
              placeholder={t('common:select')}
              overrides={{
                ControlContainer: {
                  props: {
                    id: 'batch-management-pay-period-select',
                  },
                },
              }}
            />
          </Block>
        </Cell>

        <Cell
          span={[12, 6, 3]}
        >
          <Block
            display="flex"
            flexDirection="column"
            width="auto"
            marginBottom="20px"
          >
            <LabelSmall padding="8px 0px">{t('batches:status.label')}</LabelSmall>
            <Select
              maxDropdownHeight="300px"
              placeholder={t('common:select')}
              type="select"
              clearable
              options={batchStatuses}
              onChange={handleStatusChange}
              value={batchStatus as Value}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'batch-management-status',
                    id: 'batch-management-status-select',
                  },
                },
              }}
            />
          </Block>
        </Cell>
      </Grid>

      <Grid gridColumns={12}>
        <Cell
          span={[12, 6, 3]}
        >
          <Block
            display="flex"
            width="auto"
            flexDirection="column"
            marginBottom="20px"
          >
            <LabelSmall padding="8px 0px">{t('batches:creationTrigger.label')}</LabelSmall>
            <Select
              maxDropdownHeight="300px"
              placeholder={t('common:select')}
              type="select"
              clearable
              options={batchGenerationOptions}
              onChange={handleTriggerChange}
              value={batchCreationTrigger as Value}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'batch-management-creation-trigger',
                    id: 'batch-management-creation-trigger-select',
                  },
                },
              }}
            />
          </Block>
        </Cell>
        <Cell
          span={[12, 6, 3]}
        >
          <Block
            display="flex"
            width="auto"
            flexDirection="column"
            marginBottom="20px"
          >
            <LabelSmall padding="8px 0px">{t('batches:creationDate.label')}</LabelSmall>
            <DatePicker
              clearable
              placeholder={datePickerPlaceholder}
              formatString={datePickerDateFormat}
              mask={dateFormat}
              value={creationDate}
              onChange={handleCreationDateChange}
              overrides={datePickerOverridesExtended('TPOBatchesManagementSection-creationDate')}
            />
          </Block>
        </Cell>
        <Cell
          span={[12, 6, 3]}
          overrides={{
            Cell: {
              style:
              {
                marginTop: 'auto',
                marginBottom: '20px !important',
                '@media screen and (min-width: 1135px)': {
                  marginBottom: 'auto !important',
                },
              },
            },
          }}
        >
          <Block
            marginTop="10px"
          >
            <Checkbox
              checked={emptyChecked}
              onChange={handleEmptyBatchesChanged}
              labelPlacement={LABEL_PLACEMENT.right}
              overrides={{
                Input: {
                  props: {
                    'aria-checked': `${emptyChecked}`,
                  },
                },
              }}
            >
              {t('batches:showEmptyBatches.label')}
            </Checkbox>
          </Block>
        </Cell>
      </Grid>
      <Block overrides={blockOverridesRelative}>
        <Loader active={pendingList} />
        <Grid>
          <Cell span={12}>
            {filteredBatches?.map((batch: BatchItem) => (
              <BatchesListItem key={batch?.id} batch={batch} search={search} />
            ))}

            {filteredBatches?.length > 0 && (
              <Block
                display="flex"
                width="100%"
                alignItems="center"
                justifyContent="center"
                justifyItems="center"
                marginBottom="16px"
              >
                <Pagination
                  size={SIZE.compact}
                  numPages={numPages}
                  currentPage={pageNumber}
                  overrides={paginationTransparentOverrides}
                  onPageChange={handlePageChange}
                />
              </Block>
            )}

            {filteredBatches?.length === 0 && (
              <Block
                marginTop="16px"
                marginBottom="16px"
              >
                <Card>
                  <p>{t('batches:notFound')}</p>
                </Card>
              </Block>
            )}
          </Cell>
        </Grid>
      </Block>
    </div>
  );
};

export default memo(EWABatchesSection);
