import { memo } from 'react';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { useAppSelector } from 'store/hooks';
import PaymentAllocationSection from 'screens/Batches/BatchAdministration/PaymentAllocation/PaymentAllocationSection';
import AllocatedPaymentsSection from 'screens/Batches/BatchAdministration/AllocatedPayments/AllocatedPaymentsSection';
import { repaymentSelector } from 'store/slices/repayments';
import { PaymentStatusType, RepaymentTypesType } from 'types/RepaymentTypes';
import { useStyletron } from 'baseui';
import FailedPaymentsSection from './FailedPaymentsSection';

const parentStyle = {
  background: '#E5E5E5',
  minHeight: '100vh',
};

const PaymentAllocation = () => {
  const [css] = useStyletron();
  const repayment = useAppSelector(repaymentSelector);
  const {
    transactionId,
    status,
  } = repayment;

  return (
    <div className={css(parentStyle)}>
      <CommonHeader
        title={transactionId}
        backTo="/batches/section/2"
      />
      { repayment.type === RepaymentTypesType.EWA && <PaymentAllocationSection /> }
      {(
        (repayment.type === RepaymentTypesType.EWA
          && [PaymentStatusType.PARTIALLY_ALLOCATED, PaymentStatusType.ALLOCATED].includes(status as PaymentStatusType))
        || repayment.type === RepaymentTypesType.TPO)
      && <AllocatedPaymentsSection />}
      { status === PaymentStatusType.FAILED && <FailedPaymentsSection />}
    </div>
  );
};

export default memo(PaymentAllocation);
