import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchTipsFilesRequest,
} from 'tnaApi/tipsAPI';
import {
  FetchTnALocationSettingsRequestType,
  SetTnALocationSettingsRequestType,
  TipsFileType,
  TipsFilesFilterType,
  TnALocationSetting,
} from 'types/TnaTypes';
import { fetchTnALocationSettingsRequest, setTnALocationSettingsRequest } from 'tnaApi/tnaSettings';
import { RootState } from '..';

export const initialState = {
  tipsFiles: [] as TipsFileType[],
  filesPending: false,
  tipsFileData: {
    pending: false,
    file: null as TipsFileType[] | null,
  },
  tnaLocationSettings: null as TnALocationSetting[] | null,
  tnaLocationSettingsPending: false,
  tnaLocationSettingsFetchFailed: false,
};

export const fetchTipsFiles = createAsyncThunk(
  'tips/fetchFiles',
  async (params: TipsFilesFilterType, { getState, rejectWithValue }): Promise<any> => {
    const storeState = getState() as RootState;

    try {
      return await fetchTipsFilesRequest(storeState.user.accessToken, params);
    } catch (error: any) {
      return error?.response?.status !== 404 && rejectWithValue(error);
    }
  },
);

export const downloadLatestTipFile = createAsyncThunk(
  'tips/fetchFiles/downloadDailyFile',
  async (params: TipsFilesFilterType, { getState, rejectWithValue }): Promise<any> => {
    const storeState = getState() as RootState;

    try {
      return await fetchTipsFilesRequest(storeState.user.accessToken, params);
    } catch (error: any) {
      return error?.response?.status !== 404 && rejectWithValue(error);
    }
  },
);
export const fetchTnALocationSettings = createAsyncThunk(
  'tna/fetchLocationSettings',
  async (params: FetchTnALocationSettingsRequestType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, locationID, businessDate } = params;
    const storeState = getState() as RootState;
    try {
      return await fetchTnALocationSettingsRequest(storeState.user.accessToken, organizationID, locationID, businessDate);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const setTnALocationSettings = createAsyncThunk(
  'tna/setLocationSettings',
  async (params: SetTnALocationSettingsRequestType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, locationID, data } = params;
    const storeState = getState() as RootState;
    try {
      return await setTnALocationSettingsRequest(storeState.user.accessToken, organizationID, locationID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const tnaSlice = createSlice({
  name: 'tna',
  initialState,
  reducers: {
    resetTipsFiles: (state) => {
      state.tipsFiles = initialState.tipsFiles;
    },
    resetDataTipsFiles: (state) => {
      state.tipsFileData.file = initialState.tipsFileData.file;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTipsFiles.pending, (state) => {
      state.tipsFiles = initialState.tipsFiles;
      state.filesPending = true;
    });

    builder.addCase(fetchTipsFiles.fulfilled, (state, action) => {
      state.tipsFiles = action.payload.values;
      state.filesPending = false;
    });

    builder.addCase(fetchTipsFiles.rejected, (state) => {
      state.filesPending = false;
    });

    builder.addCase(downloadLatestTipFile.pending, (state) => {
      state.tipsFileData.file = initialState.tipsFileData.file;
      state.tipsFileData.pending = true;
    });

    builder.addCase(downloadLatestTipFile.fulfilled, (state, action) => {
      state.tipsFileData.file = action.payload.values;
      state.tipsFileData.pending = false;
    });

    builder.addCase(downloadLatestTipFile.rejected, (state) => {
      state.tipsFileData.pending = false;
    });

    builder.addCase(fetchTnALocationSettings.pending, (state) => {
      state.tnaLocationSettings = initialState.tnaLocationSettings;
      state.tnaLocationSettingsFetchFailed = initialState.tnaLocationSettingsFetchFailed;
      state.tnaLocationSettingsPending = true;
    });
    builder.addCase(fetchTnALocationSettings.fulfilled, (state, action) => {
      state.tnaLocationSettings = action.payload.values;
      state.tnaLocationSettingsPending = false;
      state.tnaLocationSettingsFetchFailed = false;
    });
    builder.addCase(fetchTnALocationSettings.rejected, (state) => {
      state.tnaLocationSettingsPending = false;
      state.tnaLocationSettingsFetchFailed = true;
    });
    builder.addCase(setTnALocationSettings.fulfilled, (state, action) => {
      state.tnaLocationSettings = action.payload.values;
      state.tnaLocationSettingsPending = false;
    });
    builder.addCase(setTnALocationSettings.rejected, (state) => {
      state.tnaLocationSettingsPending = false;
    });
  },
});

export const {
  resetTipsFiles,
  resetDataTipsFiles,
} = tnaSlice.actions;

export const tipsFilesSelector = (state: RootState): TipsFileType[] => state.tna.tipsFiles;
export const filesPendingSelector = (state: RootState): boolean => state.tna.filesPending;
export const tipsFileDataSelector = (state: RootState): TipsFileType[] | null | undefined => state.tna.tipsFileData.file;
export const tipsFileDataPendingSelector = (state: RootState): boolean => state.tna.tipsFileData.pending;
export const tnaLocationSettingsSelector = (state: RootState): TnALocationSetting[] | null | undefined => state.tna.tnaLocationSettings;
export const tnaLocationSettingsPendingSelector = (state: RootState): boolean => state.tna.tnaLocationSettingsPending;
export const tnaLocationSettingsFetchFailedSelector = (state: RootState): boolean => state.tna.tnaLocationSettingsFetchFailed;

export default tnaSlice.reducer;
