import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  HeadingXSmall,
  ParagraphLarge,
  ParagraphSmall,
} from 'baseui/typography';
import { memo } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { emptyPlaceholder } from 'theme';
import {
  SIZE,
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic';
import { Skeleton } from 'baseui/skeleton';
import { ReactComponent as NotFound } from 'assets/not-found.svg';
import { imageContainerStyles } from 'screens/PaycardManagement/PaycardManagementHelper';
import { useStyletron } from 'baseui';
import {
  Button,
  KIND,
} from 'baseui/button';
import { Block } from 'baseui/block';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Pagination } from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import { Radio } from 'baseui/radio';
import {
  employeesFetchListFailedSelector,
  employeesNumPagesSelector,
  employeesPageNumberSelector,
  employeesPendingListSelector,
  employeesSelector,
} from 'store/slices/employees';
import {
  paycardOrganizationRegisterCardSelectedEmployeeSelector,
  resetOrganizationsPaycardRegisterCardEmployeeDetails,
  resetOrganizationsPaycardRegisterCardSelectedEmployee,
  setOrganizationsPaycardRegisterCardSelectedEmployee,
} from 'store/slices/paycards';
import { setWizardActiveSections } from 'store/slices/sections';
import { EmployeeDetailsValuesType } from 'types/PaycardManagementTypes';

export type PaycardManagementRegisterCardChooseEmployeeTablePropsType = {
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
    setFormValues: (values: EmployeeDetailsValuesType | {}) => void;
}

const PaycardManagementRegisterCardChooseEmployeeTable = ({
  handlePageChange,
  setFormValues,
}: PaycardManagementRegisterCardChooseEmployeeTablePropsType) => {
  const { t } = useTranslation(['paycardManagement, common']);
  const [css] = useStyletron();
  const dispatch = useAppDispatch();
  const fetchFailed = useAppSelector(employeesFetchListFailedSelector);
  const pendingList = useAppSelector(employeesPendingListSelector);
  const numPages = useAppSelector(employeesNumPagesSelector);
  const pageNumber = useAppSelector(employeesPageNumberSelector);
  const employees = useAppSelector(employeesSelector);
  const selectedEmployee = useAppSelector(paycardOrganizationRegisterCardSelectedEmployeeSelector);

  const handleAddEmployeeClick = () => {
    dispatch(setWizardActiveSections(2));
    dispatch(resetOrganizationsPaycardRegisterCardSelectedEmployee());
    dispatch(resetOrganizationsPaycardRegisterCardEmployeeDetails());
    setFormValues({});
  };

  return (
    <Grid
      gridColumns={12}
      gridMargins={24}
    >
      <Cell
        span={12}
      >
        <TableBuilder
          data={employees}
          size={SIZE.compact}
          emptyMessage={(
            <div className={css(imageContainerStyles)}>
              {fetchFailed
                ? (<HeadingXSmall margin={0}>{t('paycardManagement:paycardManagement.registerCard.chooseEmployee.table.errorOnLoading')}</HeadingXSmall>)
                : (
                  <>
                    <NotFound />
                    <HeadingXSmall margin={0}>{t('paycardManagement:paycardManagement.registerCard.chooseEmployee.table.noResults.message')}</HeadingXSmall>
                    <ParagraphLarge marginTop="0px">
                      {t('paycardManagement:paycardManagement.registerCard.chooseEmployee.table.noResults.description')}
                    </ParagraphLarge>
                    <Button
                      kind={KIND.secondary}
                      onClick={handleAddEmployeeClick}
                      size={SIZE.compact}
                    >
                      <Block marginRight="8px">
                        <FontAwesomeIcon icon={faPlus} />
                      </Block>
                      {t('paycardManagement:paycardManagement.registerCard.chooseEmployee.addEmployee')}
                    </Button>
                  </>
                )}
            </div>
          )}
          isLoading={pendingList}
          loadingMessage={(
            <Skeleton
              height="72px"
              animation
            />
          )}
        >
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.registerCard.chooseEmployee.table.select')}
            overrides={{
              TableHeadCell: { style: { width: '8%' } },
              TableBodyCell: { style: { width: '8%' } },
            }}
          >
            {(employment: any) => (
              <Radio
                value={employment}
                checked={selectedEmployee && selectedEmployee?.id === employment?.id}
                onChange={() => {
                  dispatch(setOrganizationsPaycardRegisterCardSelectedEmployee(employment));
                  dispatch(resetOrganizationsPaycardRegisterCardEmployeeDetails());
                }}
              />
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.registerCard.chooseEmployee.table.firstName')}
          >
            {(employment: any) => (
              <ParagraphSmall>
                { employment?.firstName || emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.registerCard.chooseEmployee.table.lastName')}
          >
            {(employment: any) => (
              <ParagraphSmall>
                { employment?.lastName || emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.registerCard.chooseEmployee.table.payrollId')}
          >
            {(employment: any) => (
              <ParagraphSmall>
                { employment?.payrollId || emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

        </TableBuilder>
        {!pendingList && employees && employees.length > 0 && (
        <Cell span={12}>
          <Block
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            marginBottom="16px"
          >
            <Pagination
              size={SIZE.compact}
              numPages={numPages}
              currentPage={pageNumber}
              overrides={paginationTransparentOverrides}
              onPageChange={handlePageChange}
            />
          </Block>
        </Cell>
        )}
      </Cell>
    </Grid>
  );
};

export default memo(PaycardManagementRegisterCardChooseEmployeeTable);
