import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  colors,
} from 'theme';
import { Button, KIND } from 'baseui/button';
import { StatefulMenu } from 'baseui/menu';
import { StatefulPopover } from 'baseui/popover';
import { PLACEMENT } from 'baseui/toast';
import { SIZE, Spinner } from 'baseui/spinner';
import { Block } from 'baseui/block';
import { CardStockLocationPropsType } from 'types/PaycardManagementTypes';
import { useAppDispatch } from 'store/hooks';
import { useHistory } from 'react-router-dom';
import { setSelectedCardStock } from 'store/slices/paycards';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IItem {
  label: string;
  'data-testid': string;
  id: string;
  disabled?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
}

const CardStockActions = ({
  location,
  setIsCardStockHistoryModalOpen,
}: CardStockLocationPropsType) => {
  const { t } = useTranslation(['common', 'paycardManagement']);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const listOfDropdownOptions: IItem[] = [
    {
      label: t('paycardManagement:paycardManagement.cardStock.table.orderCards'),
      'data-testid': `Paycard-Management-cardStock-actions-${location.locationId}-order-cards-btn`,
      id: `Paycard-Management-cardStock-actions-${location.locationId}-order-cards-btn`,
      onClick: () => {
        dispatch(setSelectedCardStock(location));
        history.push('/paycard/card-stock/order-cards');
      },
    },
    {
      label: t('paycardManagement:paycardManagement.cardStock.orderCards.viewOrderHistory.label'),
      'data-testid': `Paycard-Management-cardStock-actions-${location.locationId}-view-history-btn`,
      id: `Paycard-Management-cardStock-actions-${location.locationId}-view-history-btn`,
      onClick: () => setIsCardStockHistoryModalOpen(true, location),
    },
  ];

  return (
    <StatefulPopover
      initialState={{ isOpen: false }}
      content={({ close }) => (
        <StatefulMenu
          onItemSelect={({ item }) => {
            !item.isLoading && item.onClick(item);
            close();
          }}
          getRequiredItemProps={(item) => ({
            id: item.id,
            'data-testid': item['data-testid'],
          })}
          items={listOfDropdownOptions.map((item) => ({
            ...item,
            label: item.isLoading ? (
              <Block
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="20"
              >
                <Spinner
                  style={{
                    borderRightColor: colors.primary,
                    borderTopColor: colors.primary,
                    borderLeftColor: colors.primary,
                    borderBottomColor: 'transparent',
                    width: 12,
                    height: 12,
                  }}
                  $size={SIZE.small}
                />
              </Block>
            ) : (
              item.label
            ),
          }))}
        />
      )}
      accessibilityType="menu"
      placement={PLACEMENT.bottomRight}
    >
      <Button
        kind={KIND.secondary}
        overrides={{
          Root: {
            props: {
              id: `Paycard-Management-cardStock-${location.locationId}-actions-btn`,
              'data-testid': `Paycard-Management-cardStock-${location.locationId}-actions-btn`,
            },
            style: {
              color: colors.primary,
              backgroundColor: 'inherit',
              ':hover': {
                backgroundColor: 'inherit',
              },
            },
          },
        }}
      >
        <FontAwesomeIcon
          icon={faEllipsisVertical}
        />
      </Button>
    </StatefulPopover>
  );
};

export default memo(CardStockActions);
