import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { Pagination } from 'baseui/pagination';
import { Skeleton } from 'baseui/skeleton';
import {
  SIZE,
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic';
import { ParagraphSmall } from 'baseui/typography';
import {
  memo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  paycardsManageFundsHistoryListPendingSelector,
  paycardsManageFundsHistoryListSelector,
  paycardsManageFundsHistoryPageNumberSelector,
  paycardsManageFundsHistoryTotalPagesSelector,
} from 'store/slices/paycards';
import {
  FundsHistoryItem,
  FundType,
} from 'types/PaycardManagementTypes';
import PriceFormatter from 'utils/priceFormatter';
import { format } from 'date-fns';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import UnloadFundsModal from './UnloadFundsModal/UnloadFundsModal';

interface ManageFundsTableProps {
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
}

const PaycardManagementPaycardsEmployeeManageFundsTable = ({
  handlePageChange,
}: ManageFundsTableProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['paycardManagement']);
  const historyRecords = useAppSelector(paycardsManageFundsHistoryListSelector);
  const numPages = useAppSelector(paycardsManageFundsHistoryTotalPagesSelector);
  const page = useAppSelector(paycardsManageFundsHistoryPageNumberSelector);
  const pending = useAppSelector(paycardsManageFundsHistoryListPendingSelector);
  const dateFormat = t('dateFormats:date-picker.standard');
  const modals = useAppSelector(modalsSelector);
  const [selectedRecord, setSelectedRecord] = useState<FundsHistoryItem>();

  const unloadFunds = (item: FundsHistoryItem) => {
    setSelectedRecord(item);
    dispatch(setModal({
      name: ModalNames.UNLOAD_FUNDS_MODAL,
      isOpen: true,
    }));
  };

  return (
    <>
      <Grid
        align={ALIGNMENT.start}
        gridColumns={12}
        gridMargins={20}
      >
        <Cell span={12}>
          <TableBuilder
            data={historyRecords}
            size={SIZE.compact}
            isLoading={pending}
            loadingMessage={(
              <Skeleton
                height="72px"
                animation
              />
          )}
            emptyMessage={t('paycardManagement:paycardManagement.paycards.manageFunds.table.noResults')}
          >
            <TableBuilderColumn
              header={t('paycardManagement:paycardManagement.paycards.manageFunds.table.date')}
            >
              {(item: FundsHistoryItem) => (
                <ParagraphSmall>{item.date && format(new Date(item.date), dateFormat)}</ParagraphSmall>
              )}
            </TableBuilderColumn>
            <TableBuilderColumn
              header={t('paycardManagement:paycardManagement.paycards.manageFunds.table.amount')}
            >
              {(item: FundsHistoryItem) => (
                <ParagraphSmall>{PriceFormatter().format(item.amount)}</ParagraphSmall>
              )}
            </TableBuilderColumn>
            <TableBuilderColumn
              header={t('paycardManagement:paycardManagement.paycards.manageFunds.table.paymentDescription')}
            >
              {(item: FundsHistoryItem) => (
                <ParagraphSmall>{item.description}</ParagraphSmall>
              )}
            </TableBuilderColumn>
            <TableBuilderColumn
              header={t('paycardManagement:paycardManagement.paycards.manageFunds.table.internalNotes')}
            >
              {(item: FundsHistoryItem) => (
                <ParagraphSmall>{item.internalNotes}</ParagraphSmall>
              )}
            </TableBuilderColumn>
            <TableBuilderColumn
              header={t('paycardManagement:paycardManagement.paycards.manageFunds.table.actions.label')}
            >
              {(item: FundsHistoryItem) => {
                if (item.isReversed === false && item.type === FundType.LOAD) {
                  return (
                    <Button
                      kind={KIND.secondary}
                      onClick={() => unloadFunds(item)}
                    >
                      {t('paycardManagement:paycardManagement.paycards.manageFunds.table.actions.unload')}
                    </Button>
                  );
                }
                return null;
              }}
            </TableBuilderColumn>
          </TableBuilder>
        </Cell>
        {!pending && (
        <Cell span={12}>
          <Block
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            marginBottom="16px"
          >
            <Pagination
              size={SIZE.compact}
              numPages={Math.max(numPages, 1)}
              currentPage={page}
              overrides={paginationTransparentOverrides}
              onPageChange={handlePageChange}
            />
          </Block>
        </Cell>
        )}
      </Grid>
      {checkIsModalOpen(modals, ModalNames.UNLOAD_FUNDS_MODAL) && <UnloadFundsModal selectedRecord={selectedRecord} />}
    </>
  );
};

export default memo(PaycardManagementPaycardsEmployeeManageFundsTable);
