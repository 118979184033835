import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import WizardContainer from 'components/Wizard/WizardContainer/WizardContainer';
import WizardTabulation from 'components/Wizard/WizardTabulation/WizardTabulation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetWizardActiveSections, setWizardActiveSections, wizardSectionSelector } from 'store/slices/sections';
import { contentContainerStyles } from 'screens/Organizations/OrganizationForm/OrganizationFormHelpers';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { loadFunds, paycardsManageFundsSelectedEmployeeSelector } from 'store/slices/paycards';
import { LoadFundsValuesType } from 'types/PaycardManagementTypes';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import priceFormatter from 'utils/priceFormatter';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { loadFundsMapperWizard } from 'dataMappers/paycardManagementMapper';
import { NotificationType, setNotification } from 'store/slices/notification';
import { currencyCodeSelector } from 'store/slices/application';
import EnterFunds from './EnterFunds/EnterFunds';
import LoadFundsReview from './LoadFundsReview/LoadFundsReview';

const LoadFunds = () => {
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const [css] = useStyletron();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const activeSection = useAppSelector(wizardSectionSelector);
  const modals = useAppSelector(modalsSelector);
  const selectedEmployee = useAppSelector(paycardsManageFundsSelectedEmployeeSelector);
  const organization = useAppSelector(loggedOrganizationSelector);
  const { id: organizationId, name: organizationName } = organization || {};
  const [formValuesLoadFunds, setFormValuesLoadFunds] = useState<LoadFundsValuesType>();
  const currencyCode = useAppSelector(currencyCodeSelector);
  const [isLoadFundsFormValid, setIsLoadFundsFormValid] = useState<boolean>(false);
  const isConfirmModalOpen = !!modals?.find((item) => item.name === ModalNames.CONFIRM_MODAL)?.isOpen;

  const isHighLoadAmount = useMemo(() => !!(formValuesLoadFunds?.amount && Number(formValuesLoadFunds?.amount) >= 1000), [formValuesLoadFunds]);

  const subMenuHeadings = [
    t('paycardManagement:paycardManagement.paycards.manageFunds.loadFunds.enterFunds'),
    t('paycardManagement:paycardManagement.paycards.manageFunds.loadFunds.confirmation'),
  ];

  const setIsConfirmModalOpen = (
    isOpen: boolean,
  ) => {
    isConfirmModalOpen !== isOpen && dispatch(setModal({
      name: ModalNames.CONFIRM_MODAL,
      isOpen,
    }));
  };

  const nextButtonDisabledCondition = () => {
    switch (activeSection) {
      case 0:
        return !isLoadFundsFormValid;
      case 1:
        return !isLoadFundsFormValid;
      default:
        return false;
    }
  };

  const handleAction = useCallback(() => {
    switch (activeSection) {
      case 0:
        return isHighLoadAmount && setIsConfirmModalOpen(true);
      default:
        return false;
    }
  }, [formValuesLoadFunds]);

  const handleFinish = () => {
    if (formValuesLoadFunds && selectedEmployee) {
      dispatch(loadFunds({
        organizationId,
        proxyNumber: selectedEmployee.proxyNumber,
        data: loadFundsMapperWizard(
          {
            amount: Number(formValuesLoadFunds.amount),
            description: formValuesLoadFunds.paymentDescription,
            internalNotes: formValuesLoadFunds.internalNotes,
            currencyCode: currencyCode || 'USD',
          },
          t,
          organizationName,
        ),
      }))
        .unwrap()
        .then(() => {
          history.push('/paycard/manage-funds/history');
        })
        .catch(() => {
          dispatch(setNotification({
            type: NotificationType.ERROR,
            isOpen: true,
            titleKey: t('errors:unableToLoadFundsError'),
            autoHideDuration: 3000,
          }));
        });
    }
  };

  useEffect(() => {
    dispatch(resetWizardActiveSections());
  }, []);

  const onCancel = () => {
    history.push('/paycard/manage-funds/history');
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const confirmationModalAction = () => {
    dispatch(setWizardActiveSections(activeSection + 1));
  };

  return (
    <div className={css(contentContainerStyles)}>
      <CommonHeader
        title={`${t('paycardManagement:paycardManagement.paycards.manageFunds.header')} - ${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`}
        backTo="/paycard/manage-funds/history"
      />
      <WizardContainer
        nextButtonDisabled={nextButtonDisabledCondition()}
        onAction={handleAction}
        onFinish={handleFinish}
        showCancelButton
        onCancel={onCancel}
        allowGoingToNextSection={!isHighLoadAmount}
      >
        <WizardTabulation tabs={subMenuHeadings} />
        {activeSection === 0 && (
          <EnterFunds
            setIsFormValid={setIsLoadFundsFormValid}
            formValues={formValuesLoadFunds}
            setFormValues={setFormValuesLoadFunds}
            isHighLoadAmount={isHighLoadAmount}
          />
        )}
        {activeSection === 1 && (
          <LoadFundsReview
            formValues={formValuesLoadFunds}
          />
        )}
      </WizardContainer>
      <ConfirmModal
        onAction={confirmationModalAction}
        title={
          t(
            'paycardManagement:paycardManagement.paycards.manageFunds.loadFundsConfirmationModal.title',
            { amount: formValuesLoadFunds?.amount && priceFormatter().format(Number(formValuesLoadFunds?.amount)) },
          )
        }
        actionBtnText={t('common:confirm.ok')}
        onClose={handleConfirmModalClose}
      />
    </div>
  );
};
export default memo(LoadFunds);
