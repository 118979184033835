import {
  useIsAuthenticated,
} from '@azure/msal-react';
import { useStyletron } from 'baseui';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Block } from 'baseui/block';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { HeadingLarge, HeadingXSmall } from 'baseui/typography';

export const cellOverrides = {
  Cell: {
    style: { textAlign: 'center' as any },
  },
};
const Maintenance = () => {
  const isAuthenticated = useIsAuthenticated();
  const [css, theme] = useStyletron();
  const { t } = useTranslation(['common']);

  if (!isAuthenticated) return null;
  return (
    <div className={css({
      height: '100vh',
      width: '100vw',
      background: '#F3F3F3',
      marginTop: '-72px',
      display: 'flex',
    })}
    >
      <Block backgroundColor="white" maxWidth="790px" width="100%" maxHeight="420px" height="100%" margin="auto" paddingTop="88px">
        <Grid align={ALIGNMENT.center}>
          <Cell span={12} overrides={cellOverrides}>
            <img src="/logo.svg" alt="Fuego Logo" width="260px" height="95px" />
          </Cell>
          <Cell span={12} overrides={cellOverrides}>
            <HeadingLarge overrides={{ Block: { style: { marginTop: '58px', marginBottom: '0px', whiteSpace: 'break-spaces' } } }}>
              {t('common:maintenance.heading')}
            </HeadingLarge>
          </Cell>
          <Cell span={12} overrides={cellOverrides}>
            <HeadingXSmall
              color={theme.colors.contentSecondary}
              overrides={{ Block: { style: { marginTop: '24px', whiteSpace: 'break-spaces' } } }}
            >
              {t('common:maintenance.description')}
            </HeadingXSmall>
          </Cell>
        </Grid>
      </Block>
    </div>
  );
};

export default memo(Maintenance);
