import {
  memo,
} from 'react';
import { Button, KIND } from 'baseui/button';
import { useStyletron } from 'styletron-react';
import { emptyPlaceholder } from 'theme';
import PriceFormatter from 'utils/priceFormatter';
import {
  list,
  listItem,
  listItemNameAndStatuses,
  nameButton,
} from 'screens/CommonHelpers';

export type TreasuryWorkerAccountsListItemPropsType = {
  handleAccountClick: () => any
  data: {
    accountName: string | null
    balance: number | bigint | null
  } | null
};

const TreasuryAccountsListItem = ({
  handleAccountClick,
  data,
}: TreasuryWorkerAccountsListItemPropsType) => {
  const [css] = useStyletron();

  const { accountName, balance } = data || {};

  return (
    <ul className={css(list)}>
      <li className={css(listItem)}>
        <div className={css(listItemNameAndStatuses)} style={{ padding: 0 }}>
          <Button
            kind={KIND.tertiary}
            overrides={{
              Root: {
                ...nameButton.Root,
                props: {
                  id: 'treasury-account-list-button',
                },
              },
            }}
            onClick={handleAccountClick}
          >
            <span className="ellipsis">
              <b>
                {`${accountName || emptyPlaceholder} ${balance !== undefined && balance !== null
                  ? PriceFormatter().format(balance)
                  : emptyPlaceholder}`}
              </b>
            </span>
          </Button>
        </div>
      </li>
    </ul>
  );
};

export default memo(TreasuryAccountsListItem);
