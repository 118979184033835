import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import {
  LocationConfigValuesType,
  LocationValuesType,
} from 'types/LocationTypes';
import {
  createContext,
  memo,
  useEffect,
  useState,
} from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'baseui';
import {
  ModalNames,
  setModal,
} from 'store/slices/modals';
import { locationValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import { locationInitialValues } from 'initialValues/LocationsInitialValues';
import {
  defaultConfigSelector,
  organizationConfigSelector,
  organizationSelector,
} from 'store/slices/organizations';
import { organizationConfigMapper } from 'dataMappers/organizationsDataMappers';
import {
  editLocationConfigs,
  fetchLocations,
  locationConfigSelector,
  locationCreatedSelector,
  locationEditedSuccessfully,
  resetLocationSuccessfulOperation,
  resetSelectedLocation,
  selectedLocationSelector,
} from 'store/slices/locations';
import {
  locationConfigMapper,
  locationMapper,
  saveLocationConfigMapper,
} from 'dataMappers/locationsDataMapper';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { Block } from 'baseui/block';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'theme';
import {
  LabelLarge,
  LabelMedium,
} from 'baseui/typography';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { ModalButton } from 'baseui/modal';
import {
  Button,
  KIND,
  SIZE,
} from 'baseui/button';
import { Navigation } from 'baseui/side-navigation';
import AppCheckbox from 'components/Form/AppCheckbox';
import {
  LABEL_PLACEMENT,
  STYLE_TYPE,
} from 'baseui/checkbox';
import { errorSelector } from 'store/slices/error';
import { ConfigType } from 'types/OrganizationTypes';
import { SubElementEWATypeEnum } from 'types/EmployeeTypes';
import { notificationSelector } from 'store/slices/notification';
import { unScalePrice } from 'utils/priceScale';

export const LocationFormContext = createContext(
  {} as FormikState<LocationValuesType> & FormikHelpers<LocationValuesType> & FormikHandlers,
);

export const warningIconStyle = {
  padding: '0 10px',
  color: 'rgb(187, 0, 47)',
  '@media screen and (max-width: 1200px)': {
    marginTop: '16px',
  },
};

const OrganizationFormLocationModalTipConfiguration = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['organizations', 'errors', 'common', 'locations']);
  const defaultConfig = useAppSelector(defaultConfigSelector);
  const locationConfig = useAppSelector(locationConfigSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const organizationConfigValues = organizationConfig && organizationConfigMapper(organizationConfig);

  const [activeItemId, setActiveItemId] = useState('ewa');
  const location = useAppSelector(selectedLocationSelector);
  const organization = useAppSelector(organizationSelector);
  const { id: organizationID } = organization || {};
  const error = useAppSelector(errorSelector);
  const modalName = ModalNames.LOCATION_FORM_MODAL;
  const [css] = useStyletron();
  const locationCreated = useAppSelector(locationCreatedSelector);
  const locationEdited = useAppSelector(locationEditedSuccessfully);
  const notificationToast = useAppSelector(notificationSelector);

  const employeeTypesOrgLevel = (organizationConfig?.find((item) => item.configKeyName === ConfigType.SUPPORTED_EMPLOYMENT_EWA_TYPES)?.configValue)
    ?? defaultConfig?.SUPPORTED_EMPLOYMENT_EWA_TYPES;

  const initialValues = {
    ...locationInitialValues,
    offerPercentPerEmployee: Number(defaultConfig?.OFFER_PERCENT),
    tpoMaxEarningsPerBusinessDay: unScalePrice(Number(defaultConfig?.TPO_MAX_EARNINGS_PER_BUSINESS_DAY)),
    tpoMaxEarningsPerPeriod: unScalePrice(Number(defaultConfig?.TPO_MAX_EARNINGS_PER_PERIOD)),
    ...(organizationConfigValues),
    ...(organizationConfig && organizationConfigMapper(organizationConfig)),
  };

  const onSubmit = (
    values: LocationValuesType & LocationConfigValuesType,
  ) => {
    organizationID && location?.id
      && dispatch(editLocationConfigs({
        organizationID,
        locationID: location.id.toString(),
        configs: saveLocationConfigMapper(values),
      }));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    initialTouched: {
      storeNumber: true,
      alphaNumericStoreNumber: true,
      conceptID: true,
    },
  });

  const {
    values,
    handleSubmit,
    validateForm,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    setValues,
    isValid,
  } = formik;

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err: any) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetSelectedLocation());
    (locationCreated || locationEdited) && dispatch(fetchLocations({ organizationID })).then(() => {
      dispatch(resetLocationSuccessfulOperation());
    });
  };

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
      handleModalClose();
    }
  }, [error]);

  const orgConfigCheckPercent = typeof organizationConfigValues?.offerPercentPerEmployee === 'number'
    ? organizationConfigValues.offerPercentPerEmployee : defaultConfig?.OFFER_PERCENT;

  const locationTPOMaxEarningsBusinessDay = locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_MAX_EARNINGS_PER_BUSINESS_DAY)?.configValue
    ? locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_MAX_EARNINGS_PER_BUSINESS_DAY)?.configValue
    : defaultConfig?.TPO_MAX_EARNINGS_PER_BUSINESS_DAY;

  const locationTPOMaxEarningsPayPeriod = locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_MAX_EARNINGS_PER_PERIOD)?.configValue
    ? locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_MAX_EARNINGS_PER_PERIOD)?.configValue
    : defaultConfig?.TPO_MAX_EARNINGS_PER_PERIOD;

  useEffect(() => {
    if (location) {
      const combinedValues = {
        ...locationMapper(location),
        ...(locationConfig && locationConfigMapper(locationConfig)),
        offerPercentPerEmployee: Number(orgConfigCheckPercent),
        tpoMaxEarningsPerBusinessDay: unScalePrice(Number(locationTPOMaxEarningsBusinessDay)),
        tpoMaxEarningsPerPeriod: unScalePrice(Number(locationTPOMaxEarningsPayPeriod)),
        hourlyPaidEmployeesEnable: employeeTypesOrgLevel?.includes(SubElementEWATypeEnum.HOURLY_PAID) ? values.hourlyPaidEmployeesEnable : false,
        salariedEmployeesEnable: employeeTypesOrgLevel?.includes(SubElementEWATypeEnum.SALARIED_NOT_CLOCKING) ? values.salariedEmployeesEnable : false,
        regularlyPaidEmployeesEnable: employeeTypesOrgLevel?.includes(SubElementEWATypeEnum.REGULARLY_PAID_HOURLY)
          ? values.regularlyPaidEmployeesEnable : false,
        ewa3rdPartyTips: locationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_3RD_PARTY_TIPS)?.configValue === 'true'
          || locationConfig?.find((item) => item.configKeyName === ConfigType.SUBTRACT_INCLUDED_3RD_PARTY_TIPS)?.configValue === 'true',
        ewaServiceCharges: locationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_SERVICE_CHARGES)?.configValue === 'true'
          || locationConfig?.find((item) => item.configKeyName === ConfigType.SUBTRACT_INCLUDED_SERVICE_CHARGES)?.configValue === 'true',
        ewaCashTips: locationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_CASH_TIPS)?.configValue === 'true'
          || locationConfig?.find((item) => item.configKeyName === ConfigType.SUBTRACT_INCLUDED_CASH_TIPS)?.configValue === 'true',
        ewaCCTips: locationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_CC_TIPS)?.configValue === 'true'
          || locationConfig?.find((item) => item.configKeyName === ConfigType.SUBTRACT_INCLUDED_CC_TIPS)?.configValue === 'true',
        ewaDeclaredTips: locationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_DECLARED_TIPS)?.configValue === 'true'
          || locationConfig?.find((item) => item.configKeyName === ConfigType.SUBTRACT_INCLUDED_DECLARED_TIPS)?.configValue === 'true',
        ewaTipsPool: locationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_TIPS_POOL)?.configValue === 'true'
          || locationConfig?.find((item) => item.configKeyName === ConfigType.SUBTRACT_INCLUDED_TIPS_POOL)?.configValue === 'true',
        tpo3rdPartyTips: locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_3RD_PARTY_TIPS)?.configValue === 'true'
          || locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_SUBTRACT_INCLUDED_3RD_PARTY_TIPS)?.configValue === 'true',
        tpoServiceCharges: locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_SERVICE_CHARGES)?.configValue === 'true'
          || locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_SUBTRACT_INCLUDED_SERVICE_CHARGES)?.configValue === 'true',
        tpoCashTips: locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_CASH_TIPS)?.configValue === 'true'
          || locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_SUBTRACT_INCLUDED_CASH_TIPS)?.configValue === 'true',
        tpoCCTips: locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_CC_TIPS)?.configValue === 'true'
          || locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_SUBTRACT_INCLUDED_CC_TIPS)?.configValue === 'true',
        tpoDeclaredTips: locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_DECLARED_TIPS)?.configValue === 'true'
          || locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_SUBTRACT_INCLUDED_DECLARED_TIPS)?.configValue === 'true',
        tpoTipsPool: locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_TIPS_POOL)?.configValue === 'true'
          || locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_SUBTRACT_INCLUDED_TIPS_POOL)?.configValue === 'true',
        ...(locationConfig && locationConfigMapper(locationConfig)),
      };
      setValues(combinedValues);
      setDefaultValues(combinedValues);
    }
  }, [location, locationConfig]);

  useEffect(() => {
    const hasOpenNotification = notificationToast?.some((notification) => notification.isOpen === true);

    if (hasOpenNotification) {
      setSubmitting(false);
    }
  }, [notificationToast]);

  return (
    <form>
      <LocationFormContext.Provider value={formik}>
        {isFormChanged && (
          <Block
            overrides={{
              Block: {
                style: {
                  marginLeft: '14px',
                  marginRight: '0px',
                  marginBottom: '14px',
                  marginTop: '0px',
                  display: 'flex',
                  justifyContent: 'center',
                },
              },
            }}
          >
            <FontAwesomeIcon
              icon={faWarning}
              className={css(warningIconStyle)}
              size="lg"
            />
            <LabelMedium color={colors.primary}>
              {t('common:unsavedChanges')}
            </LabelMedium>
          </Block>
        )}
        <Grid
          gridColumns={12}
          align={ALIGNMENT.start}
          gridGutters={0}
          gridMargins={16}
        >
          <Cell
            span={[12, 6, 4]}
          >
            <Navigation
              items={[
                {
                  title: t('organizations:tipSourceConfig.EWA.section.label'),
                  itemId: 'ewa',
                },
                {
                  title: t('organizations:tipSourceConfig.TPO.section.label'),
                  itemId: 'tpo',
                },
              ]}
              overrides={{
                NavItem: {
                  style: ({ $active }) => {
                    if (!$active) {
                      return {
                        ':hover': {
                          backgroundColor: '#EBEBEB',
                        },
                      };
                    }
                    return {
                      borderLeftColor: colors.primary,
                    };
                  },
                },
              }}
              activeItemId={activeItemId}
              onChange={({ event, item }) => {
                event.preventDefault();
                item.itemId && setActiveItemId(item.itemId);
              }}
            />
          </Cell>
          <Cell
            span={[12, 6, 8]}
            overrides={{
              Cell: {
                style: {
                  background: '#EBEBEB',
                  padding: '16px',
                },
              },
            }}
          >
            <LabelLarge>{t('organizations:tipSourceConfig.tips.label')}</LabelLarge>
            <br />
            <Grid
              gridColumns={12}
              align={ALIGNMENT.start}
              gridMargins={0}
            >
              <Cell
                span={[12]}
                align={ALIGNMENT.start}
                overrides={{
                  Cell: {
                    style: {
                      paddingBottom: '16px',
                    },
                  },
                }}
              >
                <LabelMedium>{t('organizations:tipSourceConfig.ThirdPartyTips.label')}</LabelMedium>
                <Grid
                  gridColumns={12}
                  align={ALIGNMENT.start}
                  gridMargins={8}
                >
                  <AppCheckbox
                    name={`${activeItemId}3rdPartyTips`}
                    label={t('organizations:tipSourceConfig.totalTips.label')}
                    labelPlacement={LABEL_PLACEMENT.right}
                    checkboxType={STYLE_TYPE.default}
                    cellSpan={[12, 12, 6]}
                    context={LocationFormContext}
                    checkboxProps={{
                      onChange: (option) => {
                        const isChecked = option.currentTarget.checked;
                        const currentOption = option.currentTarget.name;
                        setValues({
                          ...values,
                          ewa3rdPartyTips: currentOption === 'ewa3rdPartyTips' ? isChecked : values.ewa3rdPartyTips,
                          ewaInclude3rdPartyTips: currentOption === 'ewa3rdPartyTips' ? isChecked : values.ewaInclude3rdPartyTips,
                          ewaSubtractIncluded3rdPartyTips: currentOption === 'ewa3rdPartyTips' ? false : values.ewaSubtractIncluded3rdPartyTips,
                          tpo3rdPartyTips: currentOption === 'tpo3rdPartyTips' ? isChecked : values.tpo3rdPartyTips,
                          tpoInclude3rdPartyTips: currentOption === 'tpo3rdPartyTips' ? isChecked : values.tpoInclude3rdPartyTips,
                          tpoSubtractIncluded3rdPartyTips: currentOption === 'tpo3rdPartyTips' ? false : values.tpoSubtractIncluded3rdPartyTips,
                        });
                      },
                    }}
                  />
                  <Cell
                    span={[12, 12, 6]}
                    align={ALIGNMENT.start}
                    overrides={{
                      Cell: {
                        style: {
                          marginTop: '8px',
                        },
                      },
                    }}
                  >
                    <Button
                      type="button"
                      kind={values[`${activeItemId}Include3rdPartyTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                      size={SIZE.compact}
                      disabled={!values[`${activeItemId}3rdPartyTips` as keyof typeof values]}
                      onClick={() => {
                        setFieldValue(`${activeItemId}Include3rdPartyTips`, true);
                        setFieldValue(`${activeItemId}SubtractIncluded3rdPartyTips`, false);
                      }}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}3rdPartyTips-add`,
                            name: `${activeItemId}Include3rdPartyTips`,
                          },
                          style: {
                            borderBottomStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderTopWidth: '2px',
                            borderLeftWidth: '2px',
                            borderRightWidth: '2px',
                            borderColor: !values[`${activeItemId}3rdPartyTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                          },
                        },
                      }}
                    >
                      {t('common:add')}
                    </Button>
                    <Button
                      type="button"
                      kind={values[`${activeItemId}SubtractIncluded3rdPartyTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                      size={SIZE.compact}
                      disabled={!values[`${activeItemId}3rdPartyTips` as keyof typeof values]}
                      onClick={() => {
                        setFieldValue(`${activeItemId}Include3rdPartyTips`, false);
                        setFieldValue(`${activeItemId}SubtractIncluded3rdPartyTips`, true);
                      }}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}3rdPartyTips-deduct`,
                            name: `${activeItemId}SubtractIncluded3rdPartyTips`,
                          },
                          style: {
                            borderBottomStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderTopWidth: '2px',
                            borderLeftWidth: '2px',
                            borderRightWidth: '2px',
                            borderColor: !values[`${activeItemId}3rdPartyTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                          },
                        },
                      }}
                    >
                      {t('common:deduct')}
                    </Button>
                  </Cell>
                </Grid>
              </Cell>
              <Cell span={[12]} align={ALIGNMENT.start}>
                <LabelMedium>{t('organizations:tipSourceConfig.TnA.label')}</LabelMedium>
                <Grid
                  gridColumns={12}
                  align={ALIGNMENT.start}
                  gridMargins={8}
                >
                  <AppCheckbox
                    name={`${activeItemId}ServiceCharges`}
                    label={t('organizations:tipSourceConfig.serviceCharges.label')}
                    labelPlacement={LABEL_PLACEMENT.right}
                    checkboxType={STYLE_TYPE.default}
                    cellSpan={[12, 12, 6]}
                    context={LocationFormContext}
                    checkboxProps={{
                      onChange: (option) => {
                        const isChecked = option.currentTarget.checked;
                        const currentOption = option.currentTarget.name;
                        setValues({
                          ...values,
                          ewaServiceCharges: currentOption === 'ewaServiceCharges' ? isChecked : values.ewaServiceCharges,
                          ewaIncludeServiceCharges: currentOption === 'ewaServiceCharges' ? isChecked : values.ewaIncludeServiceCharges,
                          ewaSubtractIncludedServiceCharges: currentOption === 'ewaServiceCharges' ? false : values.ewaSubtractIncludedServiceCharges,
                          tpoServiceCharges: currentOption === 'tpoServiceCharges' ? isChecked : values.tpoServiceCharges,
                          tpoIncludeServiceCharges: currentOption === 'tpoServiceCharges' ? isChecked : values.tpoIncludeServiceCharges,
                          tpoSubtractIncludedServiceCharges: currentOption === 'tpoServiceCharges' ? false : values.tpoSubtractIncludedServiceCharges,
                        });
                      },
                    }}
                  />
                  <Cell
                    span={[12, 12, 6]}
                    align={ALIGNMENT.start}
                    overrides={{
                      Cell: {
                        style: {
                          marginTop: '8px',
                        },
                      },
                    }}
                  >
                    <Button
                      type="button"
                      kind={values[`${activeItemId}IncludeServiceCharges` as keyof typeof values] ? KIND.primary : KIND.secondary}
                      size={SIZE.compact}
                      disabled={!values[`${activeItemId}ServiceCharges` as keyof typeof values]}
                      onClick={() => {
                        setFieldValue(`${activeItemId}IncludeServiceCharges`, true);
                        setFieldValue(`${activeItemId}SubtractIncludedServiceCharges`, false);
                      }}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}ServiceCharges-add`,
                            name: `${activeItemId}IncludeServiceCharges`,
                          },
                          style: {
                            borderBottomStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderTopWidth: '2px',
                            borderLeftWidth: '2px',
                            borderRightWidth: '2px',
                            borderColor: !values[`${activeItemId}ServiceCharges` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                          },
                        },
                      }}
                    >
                      {t('common:add')}
                    </Button>
                    <Button
                      type="button"
                      kind={values[`${activeItemId}SubtractIncludedServiceCharges` as keyof typeof values] ? KIND.primary : KIND.secondary}
                      size={SIZE.compact}
                      disabled={!values[`${activeItemId}ServiceCharges` as keyof typeof values]}
                      onClick={() => {
                        setFieldValue(`${activeItemId}IncludeServiceCharges`, false);
                        setFieldValue(`${activeItemId}SubtractIncludedServiceCharges`, true);
                      }}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}ServiceCharges-deduct`,
                            name: `${activeItemId}SubtractIncludedServiceCharges`,
                          },
                          style: {
                            borderBottomStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderTopWidth: '2px',
                            borderLeftWidth: '2px',
                            borderRightWidth: '2px',
                            borderColor: !values[`${activeItemId}ServiceCharges` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                          },
                        },
                      }}
                    >
                      {t('common:deduct')}
                    </Button>
                  </Cell>
                </Grid>
                <Grid
                  gridColumns={12}
                  align={ALIGNMENT.start}
                  gridMargins={8}
                >
                  <AppCheckbox
                    name={`${activeItemId}CashTips`}
                    label={t('organizations:tipSourceConfig.cashTips.label')}
                    labelPlacement={LABEL_PLACEMENT.right}
                    checkboxType={STYLE_TYPE.default}
                    cellSpan={[12, 12, 6]}
                    context={LocationFormContext}
                    checkboxProps={{
                      onChange: (option) => {
                        const isChecked = option.currentTarget.checked;
                        const currentOption = option.currentTarget.name;
                        setValues({
                          ...values,
                          ewaCashTips: currentOption === 'ewaCashTips' ? isChecked : values.ewaCashTips,
                          ewaIncludeCashTips: currentOption === 'ewaCashTips' ? isChecked : values.ewaIncludeCashTips,
                          ewaSubtractIncludedCashTips: currentOption === 'ewaCashTips' ? false : values.ewaSubtractIncludedCashTips,
                          tpoCashTips: currentOption === 'tpoCashTips' ? isChecked : values.tpoCashTips,
                          tpoIncludeCashTips: currentOption === 'tpoCashTips' ? isChecked : values.tpoIncludeCashTips,
                          tpoSubtractIncludedCashTips: currentOption === 'tpoCashTips' ? false : values.tpoSubtractIncludedCashTips,
                        });
                      },
                    }}
                  />
                  <Cell
                    span={[12, 12, 6]}
                    align={ALIGNMENT.start}
                    overrides={{
                      Cell: {
                        style: {
                          marginTop: '8px',
                        },
                      },
                    }}
                  >
                    <Button
                      type="button"
                      kind={values[`${activeItemId}IncludeCashTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                      size={SIZE.compact}
                      disabled={!values[`${activeItemId}CashTips` as keyof typeof values]}
                      onClick={() => {
                        setFieldValue(`${activeItemId}IncludeCashTips`, true);
                        setFieldValue(`${activeItemId}SubtractIncludedCashTips`, false);
                      }}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}CashTips-add`,
                            name: `${activeItemId}IncludeCashTips`,
                          },
                          style: {
                            borderBottomStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderTopWidth: '2px',
                            borderLeftWidth: '2px',
                            borderRightWidth: '2px',
                            borderColor: !values[`${activeItemId}CashTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                          },
                        },
                      }}
                    >
                      {t('common:add')}
                    </Button>
                    <Button
                      type="button"
                      kind={values[`${activeItemId}SubtractIncludedCashTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                      size={SIZE.compact}
                      disabled={!values[`${activeItemId}CashTips` as keyof typeof values]}
                      onClick={() => {
                        setFieldValue(`${activeItemId}IncludeCashTips`, false);
                        setFieldValue(`${activeItemId}SubtractIncludedCashTips`, true);
                      }}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}CashTips-deduct`,
                            name: `${activeItemId}SubtractIncludedCashTips`,
                          },
                          style: {
                            borderBottomStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderTopWidth: '2px',
                            borderLeftWidth: '2px',
                            borderRightWidth: '2px',
                            borderColor: !values[`${activeItemId}CashTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                          },
                        },
                      }}
                    >
                      {t('common:deduct')}
                    </Button>
                  </Cell>
                </Grid>
                <Grid
                  gridColumns={12}
                  align={ALIGNMENT.start}
                  gridMargins={8}
                >
                  <AppCheckbox
                    name={`${activeItemId}CCTips`}
                    label={t('organizations:tipSourceConfig.cardTips.label')}
                    labelPlacement={LABEL_PLACEMENT.right}
                    checkboxType={STYLE_TYPE.default}
                    cellSpan={[12, 12, 6]}
                    context={LocationFormContext}
                    checkboxProps={{
                      onChange: (option) => {
                        const isChecked = option.currentTarget.checked;
                        const currentOption = option.currentTarget.name;
                        setValues({
                          ...values,
                          ewaCCTips: currentOption === 'ewaCCTips' ? isChecked : values.ewaCCTips,
                          ewaIncludeCCTips: currentOption === 'ewaCCTips' ? isChecked : values.ewaIncludeCCTips,
                          ewaSubtractIncludedCCTips: currentOption === 'ewaCCTips' ? false : values.ewaSubtractIncludedCCTips,
                          tpoCCTips: currentOption === 'tpoCCTips' ? isChecked : values.tpoCCTips,
                          tpoIncludeCCTips: currentOption === 'tpoCCTips' ? isChecked : values.tpoIncludeCCTips,
                          tpoSubtractIncludedCCTips: currentOption === 'tpoCCTips' ? false : values.tpoSubtractIncludedCCTips,
                        });
                      },
                    }}
                  />
                  <Cell
                    span={[12, 12, 6]}
                    align={ALIGNMENT.start}
                    overrides={{
                      Cell: {
                        style: {
                          marginTop: '8px',
                        },
                      },
                    }}
                  >
                    <Button
                      type="button"
                      kind={values[`${activeItemId}IncludeCCTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                      size={SIZE.compact}
                      disabled={!values[`${activeItemId}CCTips` as keyof typeof values]}
                      onClick={() => {
                        setFieldValue(`${activeItemId}IncludeCCTips`, true);
                        setFieldValue(`${activeItemId}SubtractIncludedCCTips`, false);
                      }}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}CCTips-add`,
                            name: `${activeItemId}IncludeCCTips`,
                          },
                          style: {
                            borderBottomStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderTopWidth: '2px',
                            borderLeftWidth: '2px',
                            borderRightWidth: '2px',
                            borderColor: !values[`${activeItemId}CCTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                          },
                        },
                      }}
                    >
                      {t('common:add')}
                    </Button>
                    <Button
                      type="button"
                      kind={values[`${activeItemId}SubtractIncludedCCTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                      size={SIZE.compact}
                      disabled={!values[`${activeItemId}CCTips` as keyof typeof values]}
                      onClick={() => {
                        setFieldValue(`${activeItemId}IncludeCCTips`, false);
                        setFieldValue(`${activeItemId}SubtractIncludedCCTips`, true);
                      }}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}CCTips-deduct`,
                            name: `${activeItemId}SubtractIncludedCCTips`,
                          },
                          style: {
                            borderBottomStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderTopWidth: '2px',
                            borderLeftWidth: '2px',
                            borderRightWidth: '2px',
                            borderColor: !values[`${activeItemId}CCTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                          },
                        },
                      }}
                    >
                      {t('common:deduct')}
                    </Button>
                  </Cell>
                </Grid>
                <Grid
                  gridColumns={12}
                  align={ALIGNMENT.start}
                  gridMargins={8}
                >
                  <AppCheckbox
                    name={`${activeItemId}DeclaredTips`}
                    label={t('organizations:tipSourceConfig.declaredTips.label')}
                    labelPlacement={LABEL_PLACEMENT.right}
                    checkboxType={STYLE_TYPE.default}
                    cellSpan={[12, 12, 6]}
                    context={LocationFormContext}
                    checkboxProps={{
                      onChange: (option) => {
                        const isChecked = option.currentTarget.checked;
                        const currentOption = option.currentTarget.name;
                        setValues({
                          ...values,
                          ewaDeclaredTips: currentOption === 'ewaDeclaredTips' ? isChecked : values.ewaDeclaredTips,
                          ewaIncludeDeclaredTips: currentOption === 'ewaDeclaredTips' ? isChecked : values.ewaIncludeDeclaredTips,
                          ewaSubtractIncludedDeclaredTips: currentOption === 'ewaDeclaredTips' ? false : values.ewaSubtractIncludedDeclaredTips,
                          tpoDeclaredTips: currentOption === 'tpoDeclaredTips' ? isChecked : values.tpoDeclaredTips,
                          tpoIncludeDeclaredTips: currentOption === 'tpoDeclaredTips' ? isChecked : values.tpoIncludeDeclaredTips,
                          tpoSubtractIncludedDeclaredTips: currentOption === 'tpoDeclaredTips' ? false : values.tpoSubtractIncludedDeclaredTips,
                        });
                      },
                    }}
                  />
                  <Cell
                    span={[12, 12, 6]}
                    align={ALIGNMENT.start}
                    overrides={{
                      Cell: {
                        style: {
                          marginTop: '8px',
                        },
                      },
                    }}
                  >
                    <Button
                      type="button"
                      kind={values[`${activeItemId}IncludeDeclaredTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                      size={SIZE.compact}
                      disabled={!values[`${activeItemId}DeclaredTips` as keyof typeof values]}
                      onClick={() => {
                        setFieldValue(`${activeItemId}IncludeDeclaredTips`, true);
                        setFieldValue(`${activeItemId}SubtractIncludedDeclaredTips`, false);
                      }}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}DeclaredTips-add`,
                            name: `${activeItemId}IncludeDeclaredTips`,
                          },
                          style: {
                            borderBottomStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderTopWidth: '2px',
                            borderLeftWidth: '2px',
                            borderRightWidth: '2px',
                            borderColor: !values[`${activeItemId}DeclaredTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                          },
                        },
                      }}
                    >
                      {t('common:add')}
                    </Button>
                    <Button
                      type="button"
                      kind={values[`${activeItemId}SubtractIncludedDeclaredTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                      size={SIZE.compact}
                      disabled={!values[`${activeItemId}DeclaredTips` as keyof typeof values]}
                      onClick={() => {
                        setFieldValue(`${activeItemId}IncludeDeclaredTips`, false);
                        setFieldValue(`${activeItemId}SubtractIncludedDeclaredTips`, true);
                      }}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}DeclaredTips-deduct`,
                            name: `${activeItemId}SubtractIncludedDeclaredTips`,
                          },
                          style: {
                            borderBottomStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderTopWidth: '2px',
                            borderLeftWidth: '2px',
                            borderRightWidth: '2px',
                            borderColor: !values[`${activeItemId}DeclaredTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                          },
                        },
                      }}
                    >
                      {t('common:deduct')}
                    </Button>
                  </Cell>
                </Grid>
                <Grid
                  gridColumns={12}
                  align={ALIGNMENT.start}
                  gridMargins={8}
                >
                  <AppCheckbox
                    name={`${activeItemId}TipsPool`}
                    label={t('organizations:tipSourceConfig.TipsPool.label')}
                    labelPlacement={LABEL_PLACEMENT.right}
                    checkboxType={STYLE_TYPE.default}
                    cellSpan={[12, 12, 6]}
                    context={LocationFormContext}
                    checkboxProps={{
                      onChange: (option) => {
                        const isChecked = option.currentTarget.checked;
                        const currentOption = option.currentTarget.name;
                        setValues({
                          ...values,
                          ewaTipsPool: currentOption === 'ewaTipsPool' ? isChecked : values.ewaTipsPool,
                          ewaIncludeTipsPool: currentOption === 'ewaTipsPool' ? isChecked : values.ewaIncludeTipsPool,
                          ewaSubtractIncludedTipsPool: currentOption === 'ewaTipsPool' ? false : values.ewaSubtractIncludedTipsPool,
                          tpoTipsPool: currentOption === 'tpoTipsPool' ? isChecked : values.tpoTipsPool,
                          tpoIncludeTipsPool: currentOption === 'tpoTipsPool' ? isChecked : values.tpoIncludeTipsPool,
                          tpoSubtractIncludedTipsPool: currentOption === 'tpoTipsPool' ? false : values.tpoSubtractIncludedTipsPool,
                        });
                      },
                    }}
                  />
                  <Cell
                    span={[12, 12, 6]}
                    align={ALIGNMENT.start}
                    overrides={{
                      Cell: {
                        style: {
                          marginTop: '8px',
                        },
                      },
                    }}
                  >
                    <Button
                      type="button"
                      kind={values[`${activeItemId}IncludeTipsPool` as keyof typeof values] ? KIND.primary : KIND.secondary}
                      size={SIZE.compact}
                      disabled={!values[`${activeItemId}TipsPool` as keyof typeof values]}
                      onClick={() => {
                        setFieldValue(`${activeItemId}IncludeTipsPool`, true);
                        setFieldValue(`${activeItemId}SubtractIncludedTipsPool`, false);
                      }}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}TipsPool-add`,
                            name: `${activeItemId}IncludeTipsPool`,
                          },
                          style: {
                            borderBottomStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderTopWidth: '2px',
                            borderLeftWidth: '2px',
                            borderRightWidth: '2px',
                            borderColor: !values[`${activeItemId}TipsPool` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                          },
                        },
                      }}
                    >
                      {t('common:add')}
                    </Button>
                    <Button
                      type="button"
                      kind={values[`${activeItemId}SubtractIncludedTipsPool` as keyof typeof values] ? KIND.primary : KIND.secondary}
                      size={SIZE.compact}
                      disabled={!values[`${activeItemId}TipsPool` as keyof typeof values]}
                      onClick={() => {
                        setFieldValue(`${activeItemId}IncludeTipsPool`, false);
                        setFieldValue(`${activeItemId}SubtractIncludedTipsPool`, true);
                      }}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}TipsPool-deduct`,
                            name: `${activeItemId}SubtractIncludedTipsPool`,
                          },
                          style: {
                            borderBottomStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderTopWidth: '2px',
                            borderLeftWidth: '2px',
                            borderRightWidth: '2px',
                            borderColor: !values[`${activeItemId}TipsPool` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                          },
                        },
                      }}
                    >
                      {t('common:deduct')}
                    </Button>
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>

        <br />
        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <Cell
            span={12}
          >
            <Block
              display="flex"
              justifyContent="flex-end"
            >
              <ModalButton
                size={SIZE.compact}
                type="button"
                disabled={isSubmitting}
                kind={KIND.secondary}
                onClick={handleModalClose}
                overrides={{
                  BaseButton: {
                    props: {
                      id: 'Modal-footer-close',
                    },
                  },
                }}
              >
                {t('common:cancel')}
              </ModalButton>

              <ModalButton
                size={SIZE.compact}
                disabled={isSubmitting || !isFormChanged || !isValid}
                kind={KIND.primary}
                type="button"
                onClick={handleSubmitExtended}
                overrides={{
                  BaseButton: {
                    props: {
                      id: 'Modal-footer-action',
                    },
                  },
                }}
              >
                {t('common:save')}
              </ModalButton>
            </Block>
          </Cell>
        </Grid>
      </LocationFormContext.Provider>
    </form>
  );
};

export default memo(OrganizationFormLocationModalTipConfiguration);
