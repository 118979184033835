import { Button, KIND, SIZE } from 'baseui/button';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import HighLightSearch from 'components/HighLightSearch';
import moment from 'moment';
import {
  Key,
  memo,
  useEffect,
  useState,
} from 'react';
import { colors, emptyPlaceholder } from 'theme';
import { accordionDrawsListItemOverrides, listContainerStyles, outerContainerStyles } from 'screens/Employees/EmployeesHelpers';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import Access, { AccessCheckType, AccessUnit } from 'components/Access/Access';
import hasAccess from 'utils/hasAccess';
import priceFormatter from 'utils/priceFormatter';
import {
  Draw, EmployeeDrawsAvailableOperations, EmployeeIDType, RecipientDtoType, SubElementPaymentMethodEnum, SubElementTypeEnum,
} from 'types/EmployeeTypes';
import { useStyletron } from 'styletron-react';
import { Accordion, Panel } from 'baseui/accordion';
import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import { unScalePrice } from 'utils/priceScale';
import { Spinner, SIZE as SpinnerSize } from 'baseui/spinner';
import { useAppSelector } from 'store/hooks';
import { pendingResendMoneyFromMFASelector } from 'store/slices/transactions';
import { employeeDrawHistoryDeclineDrawPendingSelector, employeeDrawHistoryVoidDrawPendingSelector } from 'store/slices/employees';

interface ZenIItem {
  label: string,
  onClick: (draw: Draw, { close }: { close: () => void }) => void,
}
interface IItem {
  label: string,
  onClick: (draw: Draw, maxAmount: number, { close }: { close: () => void }) => void,
  isACF?: boolean,
  isLoading?: boolean,
  id?: string,
}

export type EmployeeDetailsDrawListItemPropsType = {
  search?: string
  draw: Draw
  handleAddZendeskBtnClick: (draw: Draw, { close }: { close: () => void }) => void
  handleInitiateACFBtnClick: (draw: Draw, maxAmount: number, { close }: { close: () => void }) => void
  handleInitiateBadDebtBtnClick: (draw: Draw, maxAmount: number, { close }: { close: () => void }) => void
  handleAvailableOperationClick: (draw: Draw, type: EmployeeDrawsAvailableOperations) => void
  handleDrawIDClick: (draw: Draw) => void
  amount: number
  status: string
  recipient: RecipientDtoType | undefined
  fees: number
  zendeskTicketRef: string
  payPeriod: string
  paymentMethod: SubElementPaymentMethodEnum | undefined
  handleOfferIDClick: (draw: Draw) => void
  payPeriodTotal: string
  employeeID: EmployeeIDType,
  clickedPaymentId?: string,
}

const EmployeeDetailsDrawListItem = ({
  search,
  draw,
  handleAddZendeskBtnClick,
  handleInitiateACFBtnClick,
  handleInitiateBadDebtBtnClick,
  handleAvailableOperationClick,
  handleDrawIDClick,
  amount,
  status,
  recipient,
  fees,
  zendeskTicketRef,
  payPeriod,
  paymentMethod,
  handleOfferIDClick,
  payPeriodTotal,
  employeeID,
  clickedPaymentId,
}: EmployeeDetailsDrawListItemPropsType) => {
  const hasDrawSubElements = draw?.subElements.length > 0;
  const isArrears = draw?.type === 'ARREARS';
  const isEwaManager = hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager]);
  const [css] = useStyletron();
  const [acfMaxAmount, setAcfMaxAmount] = useState<number>(0);
  const { t } = useTranslation(['employees', 'dateFormats']);
  const dateFormat = t('dateFormats:standard-with-time');
  const [badDebtMaxAmount, setBadDebtMaxAmount] = useState<number>(0);
  const isDeclineDrawPending = useAppSelector(employeeDrawHistoryDeclineDrawPendingSelector);
  const isVoidDrawPending = useAppSelector(employeeDrawHistoryVoidDrawPendingSelector);
  const isRetryDrawPending = useAppSelector(pendingResendMoneyFromMFASelector);
  const isPayrollPeriodLocked = draw?.payrollPeriod?.status === 'LOCKED';
  const loadingStateMap = {
    [EmployeeDrawsAvailableOperations.VOID]: isVoidDrawPending,
    [EmployeeDrawsAvailableOperations.RETRY]: isRetryDrawPending,
    [EmployeeDrawsAvailableOperations.DECLINE]: isDeclineDrawPending,
  };
  const getLoadingState = (type: EmployeeDrawsAvailableOperations) => loadingStateMap[type] ?? false;

  const listOfDropdownOptions: Omit<IItem, 'onClick'>[] & ZenIItem[] = [
    { label: t('employees:addZendeskRef'), onClick: handleAddZendeskBtnClick },
    ...(isEwaManager && draw?.availableOperations && draw.availableOperations.length > 0
      ? draw?.availableOperations.map((operation: EmployeeDrawsAvailableOperations) => ({
        label: t(`common:${operation.toLocaleLowerCase()}`),
        onClick: () => handleAvailableOperationClick(draw, operation),
        isLoading: getLoadingState(operation) && clickedPaymentId === `${draw?.id}`,
      })) || []
      : []
    ),
  ];
  const listOfDropdownOptionsForSubElement: IItem[] = [
    {
      label: t('employees:initiateACFBtn'), onClick: handleInitiateACFBtnClick, isACF: true, id: 'initiateACFBtn',
    },
    {
      label: t('employees:initiateBadDebtBtn'), onClick: handleInitiateBadDebtBtnClick, id: 'initiateBadDebtBtn', isACF: false,
    },
  ];

  const popoverListOfDropdownOptions = draw.type !== 'ARREARS'
    ? [...listOfDropdownOptions, ...listOfDropdownOptionsForSubElement] : listOfDropdownOptions;

  useEffect(() => {
    if (isArrears) {
      setAcfMaxAmount(draw.subElements.reduce((acc, element) => {
        let result = acc;
        const value = unScalePrice(element.amount.value, element.amount.scale);
        if (element.type === SubElementTypeEnum.ARREARS_BROUGHT_FORWARD) result += value;
        if (element.type === SubElementTypeEnum.ARREARS_CARRIED_FORWARD) result -= value;
        return result;
      }, 0));
      setBadDebtMaxAmount(draw.subElements.reduce((acc, element) => {
        let result = acc;
        const value = unScalePrice(element.amount.value, element.amount.scale);
        if (element.type === SubElementTypeEnum.ARREARS_BROUGHT_FORWARD) result += value;
        if (element.type === SubElementTypeEnum.ARREARS_CARRIED_FORWARD
          || (element.type === SubElementTypeEnum.BAD_DEBT && element.status !== 'VOIDED')) result -= value;
        return result;
      }, 0));
    } else {
      const maxAcf = draw.subElements.reduce((acc, element) => {
        let result = acc;
        const value = unScalePrice(element.amount.value, element.amount.scale);
        if (![SubElementTypeEnum.ARREARS_CARRIED_FORWARD, SubElementTypeEnum.BAD_DEBT].includes(element.type)) {
          result += value;
        } else if (element.type !== SubElementTypeEnum.BAD_DEBT) {
          result -= value;
        }
        return result;
      }, 0);
      const maxBadDebt = draw.subElements.reduce((acc, element) => {
        let result = acc;
        const value = unScalePrice(element.amount.value, element.amount.scale);
        if (![SubElementTypeEnum.ARREARS_CARRIED_FORWARD, SubElementTypeEnum.BAD_DEBT].includes(element.type)) {
          result += value;
        } else if (element.type === SubElementTypeEnum.ARREARS_CARRIED_FORWARD
          || (element.type === SubElementTypeEnum.BAD_DEBT && element.status !== 'VOIDED')) {
          result -= value;
        }
        return result;
      }, 0);
      setAcfMaxAmount(maxAcf < 0 ? 0 : maxAcf);
      setBadDebtMaxAmount(maxBadDebt < 0 ? 0 : maxBadDebt);
    }
  }, []);
  if (isArrears && !isEwaManager) return null;
  return (
    <>
      <div className={css(outerContainerStyles)}>
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
          gridMargins={30}
        >
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <HighLightSearch search={search}>
              {moment(draw?.drawRequestedDate).format(dateFormat)}
            </HighLightSearch>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <Button
              disabled={!hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager])}
              overrides={{
                Root: {
                  props: {
                    id: `EmployeeDetailsDrawSection-handle-draw-${draw?.id}`,
                  },
                  style: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    backgroundColor: 'transparent',
                  },
                },
              }}
              kind={KIND.tertiary}
              size="compact"
              onClick={() => handleDrawIDClick(draw)}
            >
              <HighLightSearch search={search}>
                {draw?.id}
              </HighLightSearch>
            </Button>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <Button
              overrides={{
                Root: {
                  props: {
                    id: `EmployeeDetailsDrawSection-handle-offer-${draw?.id}`,
                  },
                  style: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    backgroundColor: 'transparent',
                  },
                },
              }}
              kind={KIND.tertiary}
              size="compact"
              onClick={() => handleOfferIDClick(draw)}
              disabled={isArrears}
            >
              <HighLightSearch search={search}>
                {draw.offerId || (isArrears && t('employees:arrearsType'))}
              </HighLightSearch>
            </Button>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <HighLightSearch search={search}>
              {payPeriod}
            </HighLightSearch>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <HighLightSearch search={search}>
              {priceFormatter().format(amount)}
            </HighLightSearch>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <HighLightSearch search={search}>
              {priceFormatter().format(fees)}
            </HighLightSearch>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <HighLightSearch search={search}>
              {paymentMethod && t(`employees:draws.paymentMethods.${paymentMethod}`)}
            </HighLightSearch>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <span className="ellipsis block" title={`${recipient?.accountNickname || emptyPlaceholder} - ${recipient?.accountLastFour || emptyPlaceholder}`}>
              <HighLightSearch search={search}>
                {`${recipient?.accountNickname || emptyPlaceholder} - ${recipient?.accountLastFour || emptyPlaceholder}`}
              </HighLightSearch>
            </span>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <span className="ellipsis block" title={status === emptyPlaceholder && isArrears ? draw.subElements[0].status : status}>
              <HighLightSearch search={search}>
                {status === emptyPlaceholder && isArrears ? draw.subElements[0].status : status}
              </HighLightSearch>
            </span>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <HighLightSearch search={search}>
              {isArrears ? emptyPlaceholder : payPeriodTotal}
            </HighLightSearch>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <HighLightSearch search={search}>
              {zendeskTicketRef}
            </HighLightSearch>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <Access
              not
              oneOf={[
                AccessUnit.EWAClientManager,
              ]}
            >
              <StatefulPopover
                content={({ close }) => (
                  <StatefulMenu
                    items={popoverListOfDropdownOptions.map((item) => ({
                      ...item,
                      label: item.isLoading ? (
                        <Block
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          minHeight="20px"
                        >
                          <Spinner
                            style={{
                              borderRightColor: colors.primary,
                              borderTopColor: colors.primary,
                              borderLeftColor: colors.primary,
                              borderBottomColor: 'transparent',
                              width: 12,
                              height: 12,
                            }}
                            $size={SpinnerSize.small}
                          />
                        </Block>
                      ) : (
                        item.label
                      ),
                    }))}
                    onItemSelect={({ item }) => {
                      let maxAmount = acfMaxAmount;
                      if (!item.isACF) maxAmount = badDebtMaxAmount;
                      !item.isLoading && item.onClick(draw, maxAmount, { close });
                      close();
                    }}
                    getRequiredItemProps={(item, index) => ({
                      disabled: index !== 0
                      && (((item.id === 'initiateACFBtn' || item.id === 'initiateBadDebtBtn') && (['VOIDED'].includes(status) || !isPayrollPeriodLocked))
                        || ((item.id === 'initiateACFBtn' && acfMaxAmount === 0)
                        || (item.id === 'initiateBadDebtBtn' && badDebtMaxAmount === 0)
                        || ((item.id === 'initiateBadDebtBtn' || item.id === 'initiateACFBtn') && !isPayrollPeriodLocked))
                      ),
                    })}
                  />
                )}
                accessibilityType="tooltip"
                placement={PLACEMENT.right}
              >
                <Button
                  size={SIZE.compact}
                  kind={KIND.tertiary}
                  overrides={{
                    Root: {
                      props: {
                        id: `EmployeeDetailsDrawSection-employee-${employeeID}-menu`,
                      },
                      style: {
                        color: colors.primary,
                        backgroundColor: 'inherit',
                        ':hover': {
                          backgroundColor: 'inherit',
                        },
                      },
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                  />
                </Button>
              </StatefulPopover>
            </Access>

          </Cell>
        </Grid>
      </div>
      <Access
        not
        oneOf={[
          AccessUnit.EWAClientManager,
        ]}
      >
        <Accordion
          overrides={accordionDrawsListItemOverrides}
        >
          <Panel
            key={draw?.id as Key}
          >
            {hasDrawSubElements && (
              <Grid
                gridColumns={12}
                align={ALIGNMENT.center}
                gridMargins={4}
              >
                <Cell
                  align={ALIGNMENT.center}
                  span={3}
                >
                  <Block
                    marginLeft="48px"
                  >
                    <strong>{t('employees:drawSubElement.type')}</strong>
                  </Block>
                </Cell>

                <Cell
                  align={ALIGNMENT.center}
                  span={1}
                >
                  <strong>{t('employees:drawSubElement.value')}</strong>
                </Cell>

                <Cell
                  align={ALIGNMENT.center}
                  span={1}
                >
                  <strong>{t('employees:drawSubElement.id')}</strong>
                </Cell>

                <Cell
                  align={ALIGNMENT.center}
                  span={1}
                >
                  <strong>{t('employees:drawSubElement.batchId')}</strong>
                </Cell>

                <Cell
                  align={ALIGNMENT.center}
                  span={3}
                >
                  <strong>{t('employees:drawSubElement.status')}</strong>
                </Cell>
              </Grid>
            )}

            <div className={css(listContainerStyles)}>

              {[...draw.subElements]
                ?.sort((a, b) => a.id - b.id)
                ?.map((drawSubElement) => (
                  <div key={drawSubElement.id} className={css(outerContainerStyles)}>
                    <Grid
                      gridColumns={12}
                      align={ALIGNMENT.center}
                      gridMargins={0}
                    >
                      <Cell
                        align={ALIGNMENT.center}
                        span={3}
                      >
                        <Block
                          marginLeft="48px"
                        >
                          {drawSubElement.type.replace(/_/g, ' ').replace(/\S*/g, (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`)
                            || emptyPlaceholder}
                        </Block>
                      </Cell>

                      <Cell
                        align={ALIGNMENT.center}
                        span={1}
                      >
                        {priceFormatter().format(unScalePrice(drawSubElement.amount.value)) || emptyPlaceholder}
                      </Cell>

                      <Cell
                        align={ALIGNMENT.center}
                        span={1}
                      >
                        {drawSubElement.id || emptyPlaceholder}
                      </Cell>

                      <Cell
                        align={ALIGNMENT.center}
                        span={1}
                      >
                        {drawSubElement.batchId || emptyPlaceholder}
                      </Cell>

                      <Cell
                        align={ALIGNMENT.center}
                        span={3}
                      >
                        {drawSubElement.status || emptyPlaceholder}
                      </Cell>
                      {[SubElementTypeEnum.ARREARS_BROUGHT_FORWARD].includes(drawSubElement.type) && (
                        <StatefulPopover
                          content={({ close }) => (
                            <StatefulMenu
                              items={listOfDropdownOptionsForSubElement}
                              onItemSelect={(item) => {
                                let maxAmount = acfMaxAmount;
                                if (!item.item.isACF) maxAmount = badDebtMaxAmount;
                                item.item.onClick(draw, maxAmount, { close });
                              }}
                              getRequiredItemProps={(item, index) => ({
                                disabled: (index === 0 && acfMaxAmount === 0) || (index === 1 && badDebtMaxAmount === 0) || !isPayrollPeriodLocked,
                              })}
                            />
                          )}
                          accessibilityType="tooltip"
                          placement={PLACEMENT.right}
                        >
                          <Button
                            size={SIZE.compact}
                            kind={KIND.tertiary}
                            disabled={!drawSubElement.batchId}
                            overrides={{
                              Root: {
                                props: {
                                  id: `EmployeeDetailsDrawListItem-employee-${employeeID}-menu`,
                                },
                                style: {
                                  color: colors.primary,
                                  backgroundColor: 'inherit',
                                  ':hover': {
                                    backgroundColor: 'inherit',
                                  },
                                },
                              },
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEllipsisVertical}
                            />
                          </Button>
                        </StatefulPopover>
                      )}
                    </Grid>
                  </div>
                ))}

              {!hasDrawSubElements && (
                <Block
                  marginTop="24px"
                >
                  <Grid
                    gridColumns={12}
                    gridMargins={0}
                  >
                    <Cell span={12}>
                      <Card>
                        {t('employees: noDrawSubElements')}
                      </Card>
                    </Cell>
                  </Grid>
                </Block>
              )}
            </div>
          </Panel>
        </Accordion>
      </Access>
    </>
  );
};

export default memo(EmployeeDetailsDrawListItem);
