/* eslint-disable */
import { memo } from "react";
import { Link, useHistory } from "react-router-dom";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { useIsAuthenticated } from "@azure/msal-react";
import { Layer } from "baseui/layer";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { setPrevPage } from "store/slices/application";
import { loggedOrganizationSelector } from "store/slices/loggedOrganization";
import { colors } from "theme";
import { ReactComponent as HeaderLogo } from "assets/header-logo.svg";
import Access, { AccessCheckType, AccessUnit } from "components/Access/Access";
import { Button, KIND } from "baseui/button";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from "components/Menu";
import { userSelector } from "store/slices/user";
import {
  maintenanceSelector,
  organizationsSearchSelector,
} from "store/slices/organizations";
import RouteVisibility from "./RouteVisibility/RouteVisibility";
import { StyleObject } from "styletron-react";
import hasAccess from "../utils/hasAccess";

const navigationContainerStyle: any = {
  display: "inline-flex",
  boxSizing: "border-box",
  width: "100vw",
  height: "72px",
  background: colors.primaryGradient,
  position: "fixed",
  top: "0px",
  left: "0px",
  color: "#fff",
  alignItems: "center",
  alignContent: "center",
  justifyItems: "center",
  zIndex: 200,
};

const headerLogoContainerStyles = {
  position: "relative",
  width: "65px",
  maxWidth: "65px",
  marginTop: "4px",
} as StyleObject;

const organizationContainerStyles = {
  width: "calc(100vw - 130px)",
};

const organizationNameStyles = {
  minWidth: "100px",
  maxWidth: "50%",
  "text-align": "center",
  marginRight: "4px",
};

const dropdownBtnOverrides = {
  Root: {
    style: {
      backgroundColor: "transparent",
    },
  },
};
export type NavigationPropsType = {
  showNavigation?: boolean;
};

const MenuNavigation = ({ showNavigation }: NavigationPropsType) => {
  const [css] = useStyletron();
  const isAuthenticated = useIsAuthenticated();
  const user = useAppSelector(userSelector);
  const shouldGoIntoMaintenance = useAppSelector(maintenanceSelector);
  const { accessUnits } = user;
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const shouldSeeTreasuryMenu = accessUnits.some((unit) =>
    [
      AccessUnit.TreasuryManager,
      AccessUnit.FBOManager,
      AccessUnit.TreasuryReader,
      AccessUnit.FBOReader,
      AccessUnit.TipsReader,
      AccessUnit.EWAReport,
    ].includes(unit)
  );
  const hasPerformedOrganizationsSearch = useAppSelector(
    organizationsSearchSelector
  );
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleEditOrganizationClick = (organizationId: number) => {
    if (organizationId) {
      dispatch(setPrevPage(history.location.pathname));
      history.push(`/organizations/${organizationId}`);
    }
  };

  return (isAuthenticated || showNavigation) &&
    (!shouldGoIntoMaintenance || hasPerformedOrganizationsSearch) ? (
    <Layer index={200}>
      <div
        className={css(navigationContainerStyle)}
        data-testid="Navigation-container"
        id="Navigation-container"
      >
        <Menu />

        <div
          data-testid="Navigation-logged-organization-logo"
          id="Navigation-logged-organization-logo"
          className={css(headerLogoContainerStyles)}
        >
          <HeaderLogo />
        </div>

        {loggedOrganization?.name && (
          <div
            data-testid="Navigation-logged-organization-name"
            id="Navigation-logged-organization-name"
            className={css(organizationContainerStyles)}
          >
            <RouteVisibility
              hideFor={[
                {
                  exact: false,
                  path: "/organizations",
                },
                {
                  exact: false,
                  path: "/administrators",
                },
                {
                  exact: false,
                  path: "/treasury",
                },
                {
                  exact: false,
                  path: "/workers",
                },
                {
                  exact: false,
                  path: "/integrationData",
                },
              ]}
            >
              <Block
                width="100%"
                display="inline-flex"
                alignItems="center"
                alignContent="center"
                justifyItems="center"
                justifyContent="center"
              >
                <div className={css(organizationNameStyles)}>
                  {hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager]) ? (
                    <Link to="/" className="link">
                      <strong className="overflow-block ellipsis">
                        {loggedOrganization?.name}
                      </strong>
                    </Link>
                  ) : (
                    <strong className="overflow-block ellipsis">
                      {loggedOrganization?.name}
                    </strong>
                  )}
                </div>

                <Access oneOf={[AccessUnit.EWAManager]}>
                  <Button
                    kind={KIND.primary}
                    size="mini"
                    overrides={dropdownBtnOverrides}
                    onClick={() =>
                      handleEditOrganizationClick(
                        Number(loggedOrganization?.id)
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                </Access>
              </Block>
            </RouteVisibility>
          </div>
        )}
        {shouldSeeTreasuryMenu && !loggedOrganization?.id && (
          <div className={css(organizationContainerStyles)} />
        )}
      </div>
    </Layer>
  ) : null;
};

export default memo(MenuNavigation);
