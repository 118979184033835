import { memo, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { Block } from 'baseui/block';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { emptyPlaceholder } from 'theme';
import priceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchTippedEmployeeTPOPayments,
  tpoSearchPayrollPeriodSelector,
  tippedEmployeeInfoSelector,
  tippedEmployeesSelectedLocationSelector,
  resetTPOSearchByPayrollPeriodChanged,
} from 'store/slices/tpo';
import moment from 'moment';
import { Tag, VARIANT } from 'baseui/tag';
import { LabelSmall } from 'baseui/typography';
import {
  contentContainerStyles,
  details,
  listEmployeeItem,
  listItemInfoStyles,
  listItemName,
  listLabel,
  listWithBorder,
} from '../TipManagementHelper';
import TippedEmployeeTPOPaymentsTable from './TippedEmployeeTPOPaymentsTable';

const TippedEmployeeTPOPaymentsPerLocationPerPeriod = () => {
  const [css, theme] = useStyletron();
  const { t } = useTranslation(['tipsManagement', 'dateFormats']);
  const tippedEmployee = useAppSelector(tippedEmployeeInfoSelector);
  const selectedLocation = useAppSelector(tippedEmployeesSelectedLocationSelector);
  const selectedPayrollPeriod = useAppSelector(tpoSearchPayrollPeriodSelector);
  const dispatch = useAppDispatch();
  const dateFormat = t('dateFormats:standard');

  const OPEN = {
    tagColor: theme.colors.backgroundAccentLight,
    labelColor: '#1661ED',
  };

  const GONE = {
    tagColor: theme.colors.backgroundTertiary,
    labelColor: theme.colors.contentSecondary,
  };

  const FUTURE = {
    tagColor: theme.colors.backgroundWarningLight,
    labelColor: '#674D1B',
  };

  const PAY_PERIOD_STATUS_TO_TAG_CONFIGS: {
    [index: string]: { tagColor: string, labelColor: string },
  } = {
    OPEN,
    CLOSED: GONE,
    LOCKED: GONE,
    FUTURE,
  };

  useEffect(() => {
    dispatch(fetchTippedEmployeeTPOPayments({
      employeeID: tippedEmployee?.id?.toString(),
      locationID: selectedLocation[0].id,
      payrollPeriodID: selectedPayrollPeriod[0].id.toString(),
    }));
    dispatch(resetTPOSearchByPayrollPeriodChanged());
  }, []);

  return (
    <div className={css(contentContainerStyles)}>
      <CommonHeader title={t('tipsManagement:employeeTipOverview.title')} backTo="/tips" />
      <Block marginTop="24px" marginBottom="21px" marginRight="-24px">
        <Grid
          align={ALIGNMENT.center}
        >
          <Cell
            span={12}
            align={ALIGNMENT.center}
          >

            <div className={css(listWithBorder)}>
              <div className={css(listItemName)}>
                <span className="ellipsis">{`${tippedEmployee?.name} - ${selectedLocation[0].name}`}</span>
              </div>

              <div className={css(listItemInfoStyles)}>

                <span
                  id="employee-tip-payrollPeriod-id-label"
                  className={css(details)}
                >
                  <p className={css(listLabel)}>
                    {t('tipsManagement:employee.payPeriod.label')}
                    :
                  </p>
                  <p className={css(listEmployeeItem)}>
                    {`${moment(selectedPayrollPeriod[0].startDate).format(dateFormat)} - ${moment(selectedPayrollPeriod[0].endDate).format(dateFormat)}`
                    || emptyPlaceholder}
                  </p>
                </span>

                <span
                  id="employee-tip-status-id-label"
                  className={css(details)}
                >
                  <p className={css(listLabel)}>
                    {t('tipsManagement:employee.status.label')}
                    :
                  </p>
                  <Tag
                    closeable={false}
                    variant={VARIANT.solid}
                    overrides={{
                      Root: {
                        style: {
                          backgroundColor:
                          PAY_PERIOD_STATUS_TO_TAG_CONFIGS[selectedPayrollPeriod[0].status as keyof typeof PAY_PERIOD_STATUS_TO_TAG_CONFIGS].tagColor,
                          marginTop: '10px',
                          marginBottom: '14px',
                        },
                      },
                      Text: {
                        style: {
                          maxWidth: 'unset',
                        },
                      },
                    }}
                  >
                    <LabelSmall
                      color={PAY_PERIOD_STATUS_TO_TAG_CONFIGS[selectedPayrollPeriod[0].status as keyof typeof PAY_PERIOD_STATUS_TO_TAG_CONFIGS].labelColor}
                      width="fit-content"
                    >
                      {`${t(`tipsManagement:payPeriod.statuses.${selectedPayrollPeriod[0].status}`)}` || emptyPlaceholder}
                    </LabelSmall>
                  </Tag>
                </span>

                <span
                  id="employee-tip-totalTips-id-label"
                  className={css(details)}
                >
                  <p className={css(listLabel)}>
                    {t('tipsManagement:employee.totalTips.label')}
                    :
                  </p>
                  <p className={css(listEmployeeItem)}>
                    {tippedEmployee?.tipsEarnedToDate ? priceFormatter().format(unScalePrice(tippedEmployee?.tipsEarnedToDate.value))
                      : emptyPlaceholder}
                  </p>
                </span>

                <span
                  id="employee-tip-tipsPayable-id-label"
                  className={css(details)}
                >
                  <p className={css(listLabel)}>
                    {t('tipsManagement:employee.tipsPayable.label')}
                    :
                  </p>
                  <p className={css(listEmployeeItem)}>
                    {tippedEmployee?.tipsPayable ? priceFormatter().format(unScalePrice(tippedEmployee?.tipsPayable.value))
                      : emptyPlaceholder}
                  </p>
                </span>

                <span
                  id="employee-tip-paidTips-id-label"
                  className={css(details)}
                >
                  <p className={css(listLabel)}>
                    {t('tipsManagement:employee.paidTips.label')}
                    :
                  </p>
                  <p className={css(listEmployeeItem)}>
                    {tippedEmployee?.tipsPaid ? priceFormatter().format(unScalePrice(tippedEmployee?.tipsPaid.value))
                      : emptyPlaceholder}
                  </p>
                </span>

                <span
                  id="employee-tip-balance-id-label"
                  className={css(details)}
                >
                  <p className={css(listLabel)}>
                    {t('tipsManagement:employee.balance.label')}
                    :
                  </p>
                  <p className={css(listEmployeeItem)}>
                    {tippedEmployee?.balance ? priceFormatter().format(unScalePrice(tippedEmployee?.balance.value))
                      : emptyPlaceholder}
                  </p>
                </span>

              </div>
            </div>
          </Cell>
        </Grid>
      </Block>
      <TippedEmployeeTPOPaymentsTable />
    </div>
  );
};

export default memo(TippedEmployeeTPOPaymentsPerLocationPerPeriod);
