import {
  memo,
  useEffect,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Block } from 'baseui/block';
import {
  Grid,
  Cell,
  ALIGNMENT,
} from 'baseui/layout-grid';
import {
  Heading,
  HeadingLevel,
} from 'baseui/heading';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { Card } from 'baseui/card';
import { WorkerIDType } from 'types/WorkerTypes';
import { setPrevPage } from 'store/slices/application';
import {
  resetWorkers,
  workersAreSearchedSelector,
  workersFilterCriteriaSelector,
  workersFilterSelector,
  workersListSelector,
  workersPendingListSelector,
}
  from 'store/slices/workers';
import { userAccessUnitsSelector } from 'store/slices/user';
import { AccessUnit } from 'components/Access/Access';
import TreasuryWorkerAccountListItem from './TreasuryWorkerAccountListItem';
import WorkersSearch from './WorkersSearch';

const TreasuryWorkerAccounts = () => {
  const { t } = useTranslation('treasury');
  const dispatch = useAppDispatch();
  const history = useHistory();
  const pending = useAppSelector(workersPendingListSelector);
  const areSearched = useAppSelector(workersAreSearchedSelector);
  const workers = useAppSelector(workersListSelector);
  const search = useAppSelector(workersFilterSelector);
  const criteria = useAppSelector(workersFilterCriteriaSelector);
  const userRoles = useAppSelector(userAccessUnitsSelector);

  const handleAccountClick = (workerID: WorkerIDType) => {
    dispatch(setPrevPage(userRoles.includes(AccessUnit.EWAReport) ? '/treasury/section/1' : '/treasury/section/0'));
    history.push(`/treasury/transaction-history/${workerID}`);
  };

  useEffect(() => {
    if ((criteria[0].id === 'name' && search.firstName === '' && search.lastName === '')) {
      dispatch(resetWorkers());
    }
  }, [search, criteria]);

  return (
    <>
      <Grid
        gridColumns={12}
        align={ALIGNMENT.center}
      >
        <Cell span={12}>
          <HeadingLevel>
            <Heading styleLevel={6}>{t('workersHeading')}</Heading>
          </HeadingLevel>
        </Cell>
        <Cell span={12}>
          <WorkersSearch />
        </Cell>
      </Grid>

      <Block
        padding={pending ? '24px' : '0'}
        display="block"
        position="relative"
        minHeight={pending ? '200px' : '0'}
        backgroundColor="#f3f3f3"
      >
        <Grid
          align={ALIGNMENT.center}
        >
          <Cell
            span={12}
          >
            <Block
              paddingBottom="16px"
            >
              {useMemo(() => (
                areSearched && !pending && workers?.length > 0 && workers?.map((worker) => (
                  <TreasuryWorkerAccountListItem
                    key={worker?.id}
                    handleAccountClick={() => handleAccountClick(worker?.id)}
                    worker={worker}
                  />
                ))
              ), [workers])}
            </Block>

            {areSearched && !pending && workers?.length === 0 && (
              <Block
                marginTop="8px"
                marginBottom="8px"
              >
                <Card>
                  <p>{t('workerAccounts.notFound')}</p>
                </Card>
              </Block>
            )}
          </Cell>
        </Grid>
      </Block>
    </>
  );
};

export default memo(TreasuryWorkerAccounts);
