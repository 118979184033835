import {
  Key,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import { Accordion } from 'baseui/accordion';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  accordionAllocationListItemOverrides,
  allocationsListContainerStyles,
  tableHeaderStyles,
  tableStyles,
} from 'screens/Batches/BatchesHelpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  repaymentSelector,
} from 'store/slices/repayments';
import {
  allocatedBatchesSelector,
  batchesPendingAllocatedListSelector,
  pageNumberAllocatedEmploymentsList,
  totalSizeAllocatedEmploymentsList,
  allocatedBatchesPageNumberSelector,
  allocatedBatchesTotalSizeSelector,
  fetchAllocatedBatchEmployments,
  fetchAllocatedBatchAllocations,
  allocatedBatchesNumPagesSelector,
  numPagesAllocatedEmploymentsList,
} from 'store/slices/paymentAllocation';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { Pagination, SIZE } from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import Loader from 'components/Loader';
import { Block } from 'baseui/block';
import { unScalePrice } from 'utils/priceScale';
import {
  AllocationBatchType,
  PaymentAllocationTablePropsType,
} from 'types/RepaymentTypes';
import { BatchIDType } from 'types/BatchTypes';
import AllocatedPaymentsListItem from './AllocatedPaymentsListItem';

const AllocatedPaymentsTable = ({
  filter,
}: PaymentAllocationTablePropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['batches']);
  const dispatch = useAppDispatch();
  const allocatedBatches = useAppSelector(allocatedBatchesSelector);
  const pending = useAppSelector(batchesPendingAllocatedListSelector);
  const repayment = useAppSelector(repaymentSelector);
  const numPages = useAppSelector(numPagesAllocatedEmploymentsList);
  const pageNumber = useAppSelector(pageNumberAllocatedEmploymentsList);
  const totalSize = useAppSelector(totalSizeAllocatedEmploymentsList);
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const { id: organizationID } = loggedOrganization || {};
  const batchesPageNumber = useAppSelector(allocatedBatchesPageNumberSelector);
  const batchesNumPages = useAppSelector(allocatedBatchesNumPagesSelector);
  const batchesTotalSize = useAppSelector(allocatedBatchesTotalSizeSelector);

  const {
    id: repaymentID,
  } = repayment || {};

  const handlePageChange = ({ nextPage, batchID }: { nextPage: number, batchID: BatchIDType }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();

    dispatch(fetchAllocatedBatchEmployments({ batchID, repaymentId: String(repaymentID), pageNumber: page }));
  };
  const handleBatchesPageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), batchesTotalSize).toString();

    dispatch(fetchAllocatedBatchAllocations({ organizationID, pageNumber: page, repaymentId: String(repaymentID) }));
  };

  const handleAccordionChange = ({ expanded }: { expanded: Key[] }) => {
    if (expanded[0]) {
      dispatch(fetchAllocatedBatchEmployments({ batchID: expanded[0]?.toString(), repaymentId: String(repaymentID), pageNumber: '1' }));
    }
  };

  const hasSearchWordInBatches = (item: AllocationBatchType) => {
    const searchWord = filter?.search?.trim().toLowerCase();

    if (searchWord) {
      const {
        payGroup,
        payrollPeriod,
        reference,
        amount: itemAmount,
        amountDue: itemAmountDue,
      } = item || {};

      const normalizeString = (text: string | number) => text?.toString().trim().toLowerCase();
      const unscaledAmount = itemAmount ? unScalePrice(itemAmount?.value, itemAmount?.scale) : 0;
      const unscaledAmountDue = itemAmountDue ? unScalePrice(itemAmountDue?.value, itemAmountDue?.scale) : 0;

      return searchWord
        && (
          normalizeString(payGroup?.name)?.includes(searchWord)
          || normalizeString(payrollPeriod?.name)?.includes(searchWord)
          || normalizeString(reference)?.includes(searchWord)
          || normalizeString(unscaledAmount)?.includes(searchWord)
          || normalizeString(unscaledAmountDue)?.includes(searchWord)
        );
    }

    return true;
  };

  const filterAllocationBatches = (item: AllocationBatchType) => {
    const payGroupFilter = filter?.payGroupID
      ? item?.payGroup?.id?.toString() === filter?.payGroupID?.toString()
      : true;

    const payrollPeriodFilter = filter?.payrollPeriodID
      ? item?.payrollPeriod?.id?.toString() === filter?.payrollPeriodID?.toString()
      : true;

    return payGroupFilter && payrollPeriodFilter && hasSearchWordInBatches(item);
  };

  const filteredAllocationBatches = allocatedBatches?.filter(filterAllocationBatches);

  return (
    <div className={css(tableStyles)}>
      <div className={css(tableHeaderStyles)}>
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
          gridMargins={30}
        >
          <Cell
            align={ALIGNMENT.center}
            span={[2, 3]}
          >
            <Block display="flex" justifyContent="start">
              <strong>{t('batches:paymentAllocations.batchReference')}</strong>
            </Block>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={[2, 3]}
          >
            <strong>{t('batches:paymentAllocations.payGroup')}</strong>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <strong>{t('batches:paymentAllocations.payPeriod')}</strong>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <strong>{t('batches:paymentAllocations.amount')}</strong>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={[2, 1]}
          >
            <strong>{t('batches:paymentAllocations.amountDue')}</strong>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <strong>{t('batches:paymentAllocations.allocation')}</strong>
          </Cell>
        </Grid>
      </div>

      <div className={css(allocationsListContainerStyles)}>
        <Accordion
          onChange={handleAccordionChange}
          overrides={accordionAllocationListItemOverrides}
        >
          <Loader active={pending} />

          {filteredAllocationBatches
            ?.map((allocationBatch) => (
              <AllocatedPaymentsListItem
                pageNumber={pageNumber}
                numPages={numPages}
                handlePageChange={handlePageChange}
                key={allocationBatch?.id}
                allocationBatch={allocationBatch}
                search={filter?.search}
              />
            ))}
        </Accordion>
      </div>
      {allocatedBatches?.length > 0 && (
        <Block
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
          marginBottom="16px"
          marginTop="16px"
        >
          <Pagination
            size={SIZE.compact}
            numPages={batchesNumPages}
            currentPage={batchesPageNumber}
            overrides={paginationTransparentOverrides}
            onPageChange={handleBatchesPageChange}
          />
        </Block>
      )}
    </div>
  );
};

export default memo(AllocatedPaymentsTable);
