import {
  ChangeEvent,
  Key,
  ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useStyletron } from 'styletron-react';
import { Accordion, Panel } from 'baseui/accordion';
import { Checkbox } from 'baseui/checkbox';
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import PriceFormatter from 'utils/priceFormatter';
import {
  accordionAllocationListItemOverrides,
  checkboxOverrides,
  listContainerStyles,
  outerContainerStyles,
} from 'screens/Batches/BatchesHelpers';
import {
  repaymentSelector,
} from 'store/slices/repayments';
import {
  setSelectedEmployees,
  expandedBatchesSelector,
  selectedBatchEmploymentsSelector,
  setExpandedEmployees,
  selectedBatchesSelector,
  setSelectedBatches,
  batchEmploymentsSelector,
  batchEmploymentsPendingListSelector,
  fetchBatchEmploymentDrawAllocations,
  createEmploymentAllocations,
  expandedBatchEmploymentsSelector,
} from 'store/slices/paymentAllocation';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { unScalePrice } from 'utils/priceScale';
import Loader from 'components/Loader';
import { Card } from 'baseui/card';
import HighLightSearch from 'components/HighLightSearch';
import { Pagination, SIZE } from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import { EmploymentIDType } from 'types/EmployeeTypes';
import {
  AllocationBatchType,
  RepaymentIDType,
} from 'types/RepaymentTypes';
import { BatchIDType } from 'types/BatchTypes';
import { StatefulTooltip } from 'baseui/tooltip';
import PaymentAllocationEmploymentListItem from './PaymentAllocationEmploymentListItem';

export type PaymentAllocationListItemPropsType = {
  allocationBatch: AllocationBatchType
  search?: string
  disableSave?: boolean
  footer?: ReactNode
  handlePageChange: ({ nextPage, batchID }: { nextPage: number, batchID: BatchIDType }) => void,
  pageNumber: number,
  numPages: number,
}

const PaymentAllocationListItem = ({
  allocationBatch,
  search,
  disableSave,
  footer,
  handlePageChange,
  pageNumber,
  numPages,
  ...rest
}: PaymentAllocationListItemPropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['batches', 'dateFormats']);
  const dispatch = useAppDispatch();
  const expandedBatches = useAppSelector(expandedBatchesSelector);
  const expandedBatchEmployments = useAppSelector(expandedBatchEmploymentsSelector);
  const selectedBatches = useAppSelector(selectedBatchesSelector);
  const selectedBatchEmployments = useAppSelector(selectedBatchEmploymentsSelector);
  const employments = useAppSelector(batchEmploymentsSelector);
  const repayment = useAppSelector(repaymentSelector);
  const pending = useAppSelector(batchEmploymentsPendingListSelector);
  const {
    id,
    payGroup,
    payrollPeriod,
    amount,
    amountDue,
    reference,
    allocatedAmount,
  } = allocationBatch || {};
  const batchID = id?.toString();
  const isBatchExpanded = !!(expandedBatches?.find((item) => item?.toString() === batchID?.toString()));
  const isBatchSelected = !!selectedBatches?.includes(batchID);
  const allChecked = isBatchSelected || !!(isBatchExpanded && selectedBatchEmployments?.length > 0 && selectedBatchEmployments?.length >= employments?.length);
  const isIndeterminate = !!(isBatchExpanded && selectedBatchEmployments?.length > 0 && !allChecked);
  const hasEmployments = employments?.length > 0;
  const hasExpandedBatches = expandedBatches?.length > 0;
  const hasExpandedBatchEmployments = expandedBatchEmployments?.length > 0;
  const repaymentID: RepaymentIDType = repayment?.id?.toString();
  const isSaveDisabled = disableSave || !(allChecked || isIndeterminate);
  const dateFormat = t('dateFormats:standard');

  const handleAllocationCheckboxChanged = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation();

    const { checked } = e.currentTarget;

    dispatch(
      setSelectedBatches(
        checked
          ? [...selectedBatches, batchID]
          : selectedBatches?.filter((item) => item !== batchID),
      ),
    );

    dispatch(
      setSelectedEmployees(
        checked
          ? [...selectedBatchEmployments, ...(employments?.map((item) => ({ batchID, employeeID: item?.id })) || [])]
          : selectedBatchEmployments?.filter((item) => item.batchID !== batchID),
      ),
    );
  };

  const handleClickSave = () => {
    dispatch(createEmploymentAllocations({
      batchID,
      repaymentID,
      data: {
        allocations: selectedBatchEmployments?.map((item) => ({ id: Number(item.employmentID), amount: item.totalDrawAmount })),
      },
    }));
  };

  const handleAccordionChange = ({ expanded }: { expanded: Key[] }) => {
    if (expanded[0]) {
      dispatch(fetchBatchEmploymentDrawAllocations(
        {
          batchID,
          employmentID: expanded[0] as EmploymentIDType,
        },
      ));
    }

    dispatch(
      setExpandedEmployees(
        expanded[0]
          ? [{ batchID, employmentID: expanded[0] }]
          : [],
      ),
    );
  };

  return (
    <>
      <div className={css(outerContainerStyles)}>
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
          gridMargins={30}
        >
          <Cell
            align={ALIGNMENT.center}
            span={[2, 3]}
          >
            <HighLightSearch
              search={search}
            >
              <StatefulTooltip
                accessibilityType="tooltip"
                content={reference}
                placement="topLeft"
              >
                <span
                  className="ellipsis"
                  style={{
                    marginTop: '4px',
                    width: '100%',
                    display: 'inline-block',
                  }}
                >
                  {reference}
                </span>
              </StatefulTooltip>
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={[2, 3]}
          >
            <HighLightSearch
              search={search}
            >
              <StatefulTooltip
                accessibilityType="tooltip"
                content={payGroup?.name}
                placement="topLeft"
              >
                <span
                  className="ellipsis"
                  style={{
                    marginTop: '4px',
                    width: '100%',
                    display: 'inline-block',
                  }}
                >
                  {payGroup?.name}
                </span>
              </StatefulTooltip>
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <HighLightSearch search={search}>
              {`${moment(payrollPeriod?.startDate).format(dateFormat)} - ${moment(payrollPeriod?.endDate).format(dateFormat)}`}
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <HighLightSearch search={search}>
              {PriceFormatter().format(unScalePrice(amount?.value, amount?.scale))}
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={[2, 1]}
          >
            <HighLightSearch search={search}>
              {PriceFormatter().format(unScalePrice(amountDue?.value, amountDue?.scale))}
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <Checkbox
              disabled={hasExpandedBatches || amountDue?.value <= 0}
              checked={isBatchSelected || allChecked || isIndeterminate}
              isIndeterminate={isIndeterminate}
              name={batchID}
              overrides={{
                Root: {
                  ...checkboxOverrides.Root,
                  props: {
                    id: `payment-allocation-list-item-checkbox-${batchID}`,
                  },
                },
                Input: {
                  props: {
                    'aria-checked': `${isBatchSelected || allChecked || isIndeterminate}`,
                  },
                },
              }}
              onChange={handleAllocationCheckboxChanged}
            />
            <HighLightSearch search={search}>
              {PriceFormatter().format(unScalePrice(allocatedAmount?.value, allocatedAmount?.scale))}
            </HighLightSearch>
          </Cell>
        </Grid>
      </div>

      <Panel
        key={batchID as Key}
        {...rest}
      >
        {hasEmployments && (
          <Grid
            gridColumns={12}
            align={ALIGNMENT.center}
            gridMargins={4}
          >
            <Cell
              align={ALIGNMENT.center}
              span={4}
            >
              <strong>{t('batches:paymentAllocations.employee')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={2}
            >
              <strong>{t('batches:paymentAllocations.payrollNumber')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={3}
            >
              <strong>{t('batches:paymentAllocations.totalAmount')}</strong>
            </Cell>
          </Grid>
        )}

        <div className={css(listContainerStyles)}>
          <Accordion
            onChange={handleAccordionChange}
            overrides={accordionAllocationListItemOverrides}
          >
            <Loader active={pending} />

            {[...employments]
              ?.sort((a, b) => `${a.firstName} ${a.firstName}`.localeCompare(`${b.firstName} ${b.lastName}`))
              ?.map((employment) => (
                <PaymentAllocationEmploymentListItem
                  key={employment?.id}
                  repaymentID={repaymentID}
                  batchID={batchID}
                  employment={employment}
                  footer={footer}
                  disableSave={disableSave}
                />
              ))}
            {employments?.length > 0 && (
              <Block
                display="flex"
                width="100%"
                alignItems="center"
                justifyContent="center"
                justifyItems="center"
                marginBottom="16px"
                marginTop="16px"
              >
                <Pagination
                  size={SIZE.compact}
                  numPages={numPages}
                  currentPage={pageNumber}
                  overrides={paginationTransparentOverrides}
                  onPageChange={({ nextPage }: { nextPage: number }) => handlePageChange({ nextPage, batchID })}
                />
              </Block>
            )}

            {!pending && !hasEmployments && (
              <Block
                marginTop="24px"
              >
                <Grid
                  gridColumns={12}
                  gridMargins={0}
                >
                  <Cell span={12}>
                    <Card>
                      {t('repayments:notFoundEmployments')}
                    </Card>
                  </Cell>
                </Grid>
              </Block>
            )}
          </Accordion>
        </div>

        <Block
          marginTop="16px"
        >
          {!hasExpandedBatchEmployments && !pending && hasEmployments && (
            <Grid
              align={ALIGNMENT.center}
              gridColumns={12}
              gridMargins={16}
            >
              <Cell
                span={12}
              >
                {footer}
              </Cell>

              <Cell
                span={12}
                align={ALIGNMENT.center}
              >
                <Block
                  display="flex"
                  justifyContent="end"
                  marginRight="-12px"
                  marginTop="24px"
                >
                  <Button
                    disabled={isSaveDisabled}
                    type="button"
                    kind={KIND.primary}
                    onClick={handleClickSave}
                    overrides={{
                      Root: {
                        props: {
                          id: 'payment-allocation-list-item-save-button',
                        },
                      },
                    }}
                  >
                    {t('common:save')}
                  </Button>
                </Block>
              </Cell>
            </Grid>
          )}
        </Block>
      </Panel>
    </>
  );
};

export default PaymentAllocationListItem;
