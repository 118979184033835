import {
  memo,
  useEffect,
} from 'react';
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid';
import { Heading, HeadingLevel } from 'baseui/heading';
import { useTranslation } from 'react-i18next';
import { Block } from 'baseui/block';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  balanceSelector,
  dashBalanceSelector,
  fetchBalance,
  fetchDashBalance,
} from 'store/slices/masterAccount';
import { useHistory } from 'react-router-dom';
import TreasuryAccountsListItem from '../TreasuryAccountsListItem';

const TreasuryMasterAccount = () => {
  const { t } = useTranslation('treasury');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const balance = useAppSelector(balanceSelector);
  const dashBalance = useAppSelector(dashBalanceSelector);

  const handleAccountClick = () => history.push('/treasury/transaction-history/master-account');

  const handleDashAccountClick = () => history.push('/treasury/transaction-history/dash-account');
  const data = {
    accountName: t('masterAccountTitle'),
    balance: balance?.ledgerBalance,
  };

  const dashBalanceData = {
    accountName: t('dashAccountTitle'),
    balance: dashBalance?.ledgerBalance,
  };

  useEffect(() => {
    dispatch(fetchBalance());
    dispatch(fetchDashBalance());
  }, []);

  return (
    <>
      <Block marginTop="24px" />
      <Grid
        align={ALIGNMENT.center}
        gridColumns={12}
      >
        <Cell span={12}>
          <HeadingLevel>
            <Heading styleLevel={6}>{t('accountsHeading')}</Heading>
          </HeadingLevel>

          <TreasuryAccountsListItem
            handleAccountClick={handleAccountClick}
            data={data}
          />
          <TreasuryAccountsListItem
            handleAccountClick={handleDashAccountClick}
            data={dashBalanceData}
          />
          <hr />

        </Cell>
      </Grid>
    </>
  );
};

export default memo(TreasuryMasterAccount);
