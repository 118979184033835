import {
  OrganizationIDType,
} from 'types/OrganizationTypes';
import {
  SavePayGroupRequestType,
  PayGroupIDType,
  PayrollPeriodIDType,
  GeneratePayPeriodsRequestType,
  SavePayGroupPayPeriodsRequestType,
  PayrollPeriod,
  PatchPayGroupRequestType,
} from 'types/PayGroupTypes';
import request from './request';
import { organizationsBaseUrl } from './organizationsAPI';

export const fetchPayGroupByIdRequest = (
  token: string,
  organizationID: OrganizationIDType,
  payGroupID: PayGroupIDType,
) => request({
  token,
  url: `organizations/${organizationID}/pay-groups/${payGroupID}`,
});

export const editPayGroupRequest = (
  token: string,
  organizationID: OrganizationIDType,
  payGroupID: PayGroupIDType,
  data: SavePayGroupRequestType,
) => request({
  token,
  method: 'PUT',
  payload: data,
  url: `organizations/${organizationID}/pay-groups/${payGroupID}`,
});
// TODO: temp solution 1000
export const fetchPayrollPeriodsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  payGroupID: PayGroupIDType,
  statuses?: string,
  pageSize: string = '1000',
) => {
  const queryParams = statuses ? new URLSearchParams({
    pageSize,
    statuses,
  }).toString() : new URLSearchParams({
    pageSize,
  }).toString();

  return request({
    token,
    url: `${organizationsBaseUrl}/${organizationID}/pay-groups/${payGroupID}/payroll-periods?${queryParams}`,
  });
};

export const fetchPayGroupsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  pageSize: string = '1000',
) => request({
  token,
  url: `${organizationsBaseUrl}/${organizationID}/pay-groups?pageSize=${pageSize}`,
});

export const createPayGroupRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: SavePayGroupRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/pay-groups`,
});

export const savePayGroupPayPeriodsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  payGroupID: PayGroupIDType,
  data: SavePayGroupPayPeriodsRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/pay-groups/${payGroupID}/payroll-periods/bulk`,
});

export const generatePayPeriodsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  payGroupID: PayGroupIDType,
  data: GeneratePayPeriodsRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/pay-groups/${payGroupID}/payroll-periods/generate`,
});

export const closePayPeriodRequest = (
  token: string,
  organizationID: OrganizationIDType,
  payGroupID: PayGroupIDType,
  payrollPeriodID: PayrollPeriodIDType,
) => request({
  token,
  method: 'POST',
  url: `${organizationsBaseUrl}/${organizationID}/pay-groups/${payGroupID}/payroll-periods/${payrollPeriodID}/close`,
});

export const deletePayPeriodRequest = (
  token: string,
  organizationID: OrganizationIDType,
  payGroupID: PayGroupIDType,
  payrollPeriodID: PayrollPeriodIDType,
) => request({
  token,
  method: 'DELETE',
  url: `${organizationsBaseUrl}/${organizationID}/pay-groups/${payGroupID}/payroll-periods/${payrollPeriodID}`,
});

export const updatePayPeriodRequest = (
  token: string,
  organizationID: OrganizationIDType,
  payGroupID: PayGroupIDType,
  data: Partial<PayrollPeriod>,
) => request({
  token,
  method: 'PATCH',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/pay-groups/${payGroupID}/payroll-periods/${data.id}`,
});

export const patchPayGroupRequest = (
  token: string,
  organizationID: OrganizationIDType,
  payGroupID: PayGroupIDType,
  data: PatchPayGroupRequestType,
) => request({
  token,
  method: 'PATCH',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/pay-groups/${payGroupID}`,
});
