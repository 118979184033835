import {
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import { Table, SIZE } from 'baseui/table-semantic';
import { Block } from 'baseui/block';
import AccordionSection from 'components/AccordionSection/AccordionSection';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  employeeDetailsSelector,
  employeeDetailsPendingSelector,
  setPrimaryAssignmentForEmployee,
  fetchEmployee,
} from 'store/slices/employees';
import Loader from 'components/Loader';
import { Button, KIND } from 'baseui/button';
import { emptyPlaceholder } from 'theme';
import {
  AssignEmployeesToWorkerValuesType,
  OperationMode,
  EmployeeAssignment,
} from 'types/EmployeeTypes';
import { NotificationType, setNotification } from 'store/slices/notification';
import hasAccess from 'utils/hasAccess';
import { AccessCheckType, AccessUnit } from 'components/Access/Access';
import {
  containerStyles,
} from '../../EmployeesHelpers';

export const contentContainerStyles = {
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: '24px',
  paddingBottom: '24px',
  background: '#E5E5E5',
  zIndex: 50,
  minHeight: '100vh',
};

export type EmployeeDetailsAssignmentsSectionPropsType = {
  expanded?: boolean
}

const EmployeeDetailsAssignmentsSection = ({
  expanded,
}: EmployeeDetailsAssignmentsSectionPropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'employees']);
  const dispatch = useAppDispatch();
  const employeeDetails = useAppSelector(employeeDetailsSelector);
  const pending = useAppSelector(employeeDetailsPendingSelector);

  const assignments = employeeDetails?.assignments || [];
  const setPrimaryAssignment = (assignment: EmployeeAssignment) => {
    const selectedEmployeesFormatted: AssignEmployeesToWorkerValuesType = {
      workerId: employeeDetails?.workerId,
      operation: OperationMode.ASSIGNMENT_UPDATE,
      employments: [{
        id: employeeDetails?.id,
        assignments: assignments?.map((i) => ({ id: i.id, name: i.name, primaryLocation: i.id === assignment.id })),
      }],
    };
    dispatch(setPrimaryAssignmentForEmployee({
      organizationID: employeeDetails?.organization?.id,
      data: selectedEmployeesFormatted,
    })).then(() => {
      dispatch(fetchEmployee({
        organizationID: employeeDetails?.organization?.id,
        employeeID: String(employeeDetails?.id),
      })).then(() => {
        dispatch(setNotification({
          isOpen: true,
          type: NotificationType.SUCCESS,
          title: undefined,
          titleKey: 'successfullySaved.title',
          text: undefined,
          textKey: undefined,
          autoHideDuration: 5000,
        }));
      });
    });
  };

  return (
    <AccordionSection
      expanded={expanded}
      title={t('employees:assignments')}
      disabled={false}
      showStatus={false}
    >
      <div className={css(containerStyles)}>
        <Loader active={pending} />

        <Block
          paddingLeft="36px"
          paddingRight="36px"
        >
          <Table
            overrides={{
              TableBodyRow: {
                style: {
                  ':nth-child(even)': {
                    background: '#F5F5F5',
                  },
                },
              },
            }}
            emptyMessage={t('employees:assignments.noResults')}
            columns={[
              t('employees:table.assignmentName.header'),
              t('employees:table.locationName.header'),
              t('employees:table.primaryLocation.header'),
              '',
            ]}
            data={[...assignments]?.sort((a, b) => {
              if (b.name > a.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            }).map((assignment) => ([
              <Block>
                {assignment?.name}
              </Block>,
              <Block>
                {assignment?.location?.name}
              </Block>,
              <Block>
                {typeof assignment?.primaryLocation === 'boolean'
                  ? t(`common:${assignment?.primaryLocation
                    ? 'yes' : 'no'}`).toUpperCase()
                  : emptyPlaceholder}
              </Block>,
              <Block
                display="inline-flex"
              >
                <Button
                  disabled={assignments.filter((item) => item.primaryLocation === true).length > 1
                    || !hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager]) ? false : assignment?.primaryLocation}
                  overrides={{
                    Root: {
                      props: {
                        id: `EmployeeDetailsAssignmentSection-handle-assignment-${assignment?.id}`,
                      },
                      style: {
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      },
                    },
                  }}
                  kind={KIND.primary}
                  onClick={() => setPrimaryAssignment(assignment)}
                  size="compact"
                >
                  {t('employees:assignments.primary')}
                </Button>
              </Block>,
            ]))}
            size={SIZE.compact}
          />

        </Block>
      </div>
    </AccordionSection>
  );
};

export default memo(EmployeeDetailsAssignmentsSection);
