import { useState, useEffect } from 'react';

const removeKeys: any = (obj: any, keys: string[]) => {
  if (Array.isArray(obj)) return obj.map((item) => removeKeys(item, keys));

  if (!(obj instanceof Date) && typeof obj === 'object' && obj !== null) {
    return Object
      .keys(obj)
      .reduce((acc, key) => {
        if (!keys.includes(key)) {
          return {
            ...acc,
            [key]: removeKeys(obj[key], keys),
          };
        }
        return acc;
      }, {});
  }

  if (typeof obj === 'number') {
    return obj.toString();
  }

  return obj;
};

export const useIsFormChanged = (values: any) => {
  const excludeKeys = ['label'];
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [changedFields, setChangedFields] = useState<string[]>([]);
  const [defaultData, setDefaultData] = useState(JSON.stringify(removeKeys(values, excludeKeys)));

  useEffect(() => {
    setIsFormChanged(false);
    setChangedFields([]);
    setDefaultData('');

    return () => {
      setIsFormChanged(false);
      setChangedFields([]);
      setDefaultData('');
    };
  }, []);

  useEffect(() => {
    const currentData = JSON.stringify(removeKeys(values, excludeKeys));
    setIsFormChanged(defaultData !== currentData);
  }, [values]);

  useEffect(() => {
    if (isFormChanged && defaultData) {
      const newChangedFields: string[] = [];
      Object.keys(values).forEach((key) => {
        const defaultFieldValue = JSON.parse(defaultData)[key];
        const currentFieldValue = values[key];
        if (defaultFieldValue !== JSON.stringify(currentFieldValue)) {
          newChangedFields.push(key);
        }
      });

      setChangedFields(newChangedFields);
    } else {
      setChangedFields([]);
    }
  }, [isFormChanged]);

  const setDefaultValues = (valuesParam: any) => {
    const cleanedData = JSON.stringify(removeKeys(valuesParam, excludeKeys));
    setDefaultData(cleanedData);
    setIsFormChanged(false);
    setChangedFields([]);
  };

  return {
    isFormChanged,
    changedFields,
    setDefaultValues,
    defaultData,
  };
};

export default useIsFormChanged;
