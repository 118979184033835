import {
  SaveOrganizationRequestType,
  OrganizationIDType,
  SaveOrganizationConfigsRequestType,
  OrganizationSectionIDType,
  EditOrganizationSectionStatusesRequestType,
  CreateOrganizationSectionStatusesRequestType,
  OrganizationStatuses,
} from 'types/OrganizationTypes';
import request from './request';

export const organizationsBaseUrl = '/organizations';

export const fetchAllOrganizationsRequest = (
  token: string,
  status: OrganizationStatuses,
  pageNumber: string,
  pageSize: string,
  namePattern: string,
) => {
  const queryParams = new URLSearchParams({
    pageNumber,
    pageSize,
    status,
    namePattern,
  })?.toString();
  return request({
    token,
    url: `${organizationsBaseUrl}?${queryParams}`,
  });
};

export const fetchOrganizationByIdRequest = (
  token: string,
  organizationID: OrganizationIDType,
) => request({
  token,
  url: `organizations/${organizationID}`,
});
/**
 * @returns {values: []} but with potential to return pages as well
 */
export const fetchOrganizationConfigsRequest = (
  token: string,
  organizationID: OrganizationIDType,
) => request({
  token,
  url: `organizations/${organizationID}/configs`,
});

/**
 * Gets UI section statuses. No point in having pagination here.
 * @returns { values: [], pageNumber, pageSize, totalSize }
 */
export const fetchOrganizationSectionsStatusesRequest = (
  token: string,
  organizationID: OrganizationIDType,
) => request({
  token,
  url: `organizations/${organizationID}/ui-sections?pageSize=1000`,
});

export const createOrganizationRequest = (
  token: string,
  data: SaveOrganizationRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: organizationsBaseUrl,
});

export const createOrganizationSectionStatusesRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: CreateOrganizationSectionStatusesRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/ui-sections`,
});

export const editOrganizationRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: SaveOrganizationRequestType,
) => request({
  token,
  method: 'PUT',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}`,
});

export const editOrganizationConfigsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: SaveOrganizationConfigsRequestType,
) => request({
  token,
  method: 'PUT',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/configs`,
});

export const editOrganizationSectionStatusesRequest = (
  token: string,
  organizationID: OrganizationIDType,
  sectionID: OrganizationSectionIDType,
  data: EditOrganizationSectionStatusesRequestType,
) => request({
  token,
  method: 'PATCH',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/ui-sections/${sectionID}`,
});
