import { Tag, VARIANT, KIND } from 'baseui/tag';
import { ParagraphLarge, ParagraphSmall } from 'baseui/typography';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import {
  PayPeriodRequiredActionsEnum,
  PayPeriodStatusEnum,
  PayPeriodType,
} from 'types/PayrollPeriodTypes';
import { Block } from 'baseui/block';
import AppCheckbox from 'components/Form/AppCheckbox';
import { STYLE_TYPE } from 'baseui/checkbox';
import {
  isPayPeriodStatusFutureOrOpen,
  paragraphLowOpacityStyles,
  payPeriodItemContainer,
  payPeriodTagContainer,
} from '../../../DashboardHelper';
import { PayPeriodStatusFormContext } from '../PayPeriodItem';

interface Props {
  payPeriod: PayPeriodType;
}

const PayPeriodItemHeader = ({ payPeriod }: Props) => {
  const { t } = useTranslation(['dateFormats']);
  const datePickerFormat = t('dateFormats:standard');
  const isStatusFutureOrOpen = isPayPeriodStatusFutureOrOpen(
    payPeriod.ewaStatus as PayPeriodStatusEnum,
  );
  const [css] = useStyletron();

  const startDate = moment(payPeriod.startDate).format(datePickerFormat);
  const endDate = moment(payPeriod.endDate).format(datePickerFormat);

  const status = useMemo(() => {
    switch (payPeriod.ewaStatus) {
      case PayPeriodStatusEnum.OPEN:
        return t('dashboard:dashboard.statusOptions.open');
      case PayPeriodStatusEnum.LOCKED:
        return t('dashboard:dashboard.statusOptions.locked');
      case PayPeriodStatusEnum.CLOSED:
        return t('dashboard:dashboard.statusOptions.closed');
      case PayPeriodStatusEnum.FUTURE:
        return t('dashboard:dashboard.statusOptions.future');
      default:
        return '';
    }
  }, [payPeriod.ewaStatus]);

  const isActionRequired = useMemo(() => {
    const ewaActions = new Set(payPeriod.ewaRequiredActions);
    const tpoActions = new Set(payPeriod.tpoRequiredActions);

    return (
      ewaActions.has(PayPeriodRequiredActionsEnum.CREATE_BATCH)
      || tpoActions.has(PayPeriodRequiredActionsEnum.CREATE_BATCH)
      || ewaActions.has(PayPeriodRequiredActionsEnum.INCLUDE_LEDGERS_IN_BATCH)
      || tpoActions.has(PayPeriodRequiredActionsEnum.INCLUDE_LEDGERS_IN_BATCH)
      || ewaActions.has(PayPeriodRequiredActionsEnum.SETTLE_LEDGERS_IN_BATCH)
      || tpoActions.has(PayPeriodRequiredActionsEnum.SETTLE_LEDGERS_IN_BATCH)
    );
  }, [payPeriod.ewaRequiredActions, payPeriod.tpoRequiredActions]);

  return (
    <Block className={css(payPeriodItemContainer)}>
      <Block>
        <ParagraphLarge margin={0} style={{ fontWeight: '600' }}>
          {`${payPeriod.name} (${status})`}
        </ParagraphLarge>
        <ParagraphSmall
          margin="8px 0 0"
          className={css(
            isStatusFutureOrOpen ? {} : paragraphLowOpacityStyles,
          )}
        >
          {`${startDate} - ${endDate}`}
        </ParagraphSmall>
      </Block>
      <Block
        className={css(payPeriodTagContainer)}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => { e.stopPropagation(); }}
      >
        {isActionRequired && (
        <Tag kind={KIND.warning} variant={VARIANT.solid} closeable={false}>
          {t('dashboard:dashboard.payPeriodContent.actionRequired')}
        </Tag>

        )}
        <AppCheckbox
          name="sentToPayroll"
          label={t('dashboard:dashboard.payPeriodContent.sentToPayrollLabel')}
          cellSpan={[12, 6, 3]}
          context={PayPeriodStatusFormContext}
          checkboxType={STYLE_TYPE.default}
        />
      </Block>
    </Block>
  );
};

export default PayPeriodItemHeader;
