import {
  ReactNode,
} from 'react';
import { Layer } from 'baseui/layer';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Cell,
} from 'baseui/layout-grid';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE as MODALSIZE,
  ROLE,
} from 'baseui/modal';
import { KIND } from 'baseui/button';
import { borderRadius, colors } from 'theme';
import { Block } from 'baseui/block';

export type ConfirmModalPropsType = {
  title?: string
  cancelBtnText?: string
  actionBtnText?: string
  children?: ReactNode
  onClose?: () => Promise<void> | void
  onAction?: () => Promise<void> | void
  isActionDisabled?: boolean
  isCloseDisabled?: boolean
  minWidth?: string
  maxWidth?: string
  modalNameSpecified?: keyof typeof ModalNames
};

const ConfirmModal = ({
  title,
  cancelBtnText,
  actionBtnText,
  isCloseDisabled,
  isActionDisabled,
  children,
  onAction,
  onClose,
  minWidth,
  maxWidth,
  modalNameSpecified = 'CONFIRM_MODAL',
}: ConfirmModalPropsType) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common']);
  const modals = useAppSelector(modalsSelector);
  const modalName = ModalNames[modalNameSpecified];
  const isModalOpen = checkIsModalOpen(modals, modalName);

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalCloseBtnClick = () => {
    onClose && onClose();
    setIsModalOpen(false);
  };

  const handleModalActionBtnClick = () => {
    onAction && onAction();
    setIsModalOpen(false);
  };

  return (
    <Layer index={400}>
      <Modal
        animate
        closeable={false}
        onClose={handleModalCloseBtnClick}
        isOpen={isModalOpen}
        autoFocus={false}
        size={MODALSIZE.auto}
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              minWidth,
              maxWidth,
              overflow: 'auto',
              borderBottomLeftRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
              borderTopLeftRadius: borderRadius,
              borderTopRightRadius: borderRadius,
            },
          },
          Close: {
            style: {
              margin: '18px 28px',
              width: '32px',
              height: '32px',
              background: colors.primary,
              borderBottomLeftRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
              borderTopLeftRadius: borderRadius,
              borderTopRightRadius: borderRadius,
              color: '#fff',
            },
          },
        }}
      >
        <Block width={['75vw', '75vw', '30vw', '30vw']}>
          <ModalHeader>
            <Grid
              gridColumns={12}
              gridMargins={16}
            >
              <Cell
                span={12}
              >
                {title || t('common:confirm.title')}
              </Cell>
            </Grid>
          </ModalHeader>

          {children && (
            <ModalBody>
              <Grid
                gridColumns={12}
                gridMargins={36}
              >
                {children}
              </Grid>

            </ModalBody>
          )}

          <ModalFooter>
            <Grid
              gridColumns={12}
              gridMargins={16}
            >
              <Cell
                span={12}
              >
                <Block
                  marginBottom="16px"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <ModalButton
                    type="button"
                    disabled={isCloseDisabled}
                    kind={KIND.secondary}
                    onClick={handleModalCloseBtnClick}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'confirm-modal-cancel-button',
                          'data-testid': 'confirm-modal-cancel-button',
                        },
                      },
                    }}
                  >
                    {cancelBtnText || t('common:confirm.cancel')}
                  </ModalButton>

                  <ModalButton
                    disabled={isActionDisabled}
                    kind={KIND.primary}
                    type="button"
                    onClick={handleModalActionBtnClick}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'confirm-modal-ok-button',
                          'data-testid': 'confirm-modal-ok-button',
                        },
                      },
                    }}
                  >
                    {actionBtnText || t('common:confirm.ok')}
                  </ModalButton>
                </Block>
              </Cell>
            </Grid>
          </ModalFooter>
        </Block>
      </Modal>
    </Layer>
  );
};

export default ConfirmModal;
