import {
  PayPeriodDeductionStatusEnum,
  PayPeriodRequiredActionsEnum,
  PayPeriodStatusEnum,
  PayPeriodStatusFormType,
  PayPeriodType,
} from 'types/PayrollPeriodTypes';
import { Accordion, Panel } from 'baseui/accordion';
import { ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { useTranslation } from 'react-i18next';
import {
  createContext, useEffect, useMemo, useState,
} from 'react';
import moment from 'moment-timezone';
import {
  FormikState,
  FormikHelpers,
  FormikHandlers,
  useFormik,
} from 'formik';
import AppSelect from 'components/Form/AppSelect';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updatePayPeriodActions } from 'store/slices/dashboard';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { useStyletron } from 'styletron-react';
import { Button, KIND } from 'baseui/button';
import { useHistory } from 'react-router-dom';
import { Block } from 'baseui/block';
import { NotificationType, setNotification } from 'store/slices/notification';
import PayPeriodItemHeader from './PayPeriodItemHeader/PayPeriodItemHeader';
import {
  isPayPeriodStatusFutureOrOpen,
  listItemAccordionStyles,
  paragraphLowOpacityStyles,
} from '../../DashboardHelper';
import PayPeriodBanner from './PayPeriodBanner/PayPeriodBanner';

export const PayPeriodStatusFormContext = createContext(
  {} as FormikState<PayPeriodStatusFormType> &
    FormikHelpers<PayPeriodStatusFormType> &
    FormikHandlers,
);

interface Props {
  payPeriod: PayPeriodType;
}

const PayPeriodItem = ({ payPeriod }: Props) => {
  const { t } = useTranslation(['notifications', 'dateFormats', 'errors', 'dashboard', 'common']);
  const history = useHistory();
  const [css] = useStyletron();
  const organization = useAppSelector(loggedOrganizationSelector);
  const isStatusFutureOrOpen = isPayPeriodStatusFutureOrOpen(
    payPeriod.ewaStatus as PayPeriodStatusEnum,
  );
  const dispatch = useAppDispatch();
  const [deductionStatusState, setDeductionStatusState] = useState(payPeriod.deductionStatus);
  const [sentToPayrollState, setSentToPayrollState] = useState(payPeriod.sentToPayroll);

  const formik = useFormik({
    initialValues: {
      sentToPayroll: payPeriod.sentToPayroll,
      deductionStatus: payPeriod.deductionStatus
        ? [
          {
            value: payPeriod.deductionStatus,
            label: payPeriod.deductionStatus
              .toLowerCase()
              .replace(/_/g, ' ')
              .replace(/\b\w/g, (char) => char.toUpperCase()),
          },
        ]
        : [],
    },
    onSubmit: () => {},
  });

  const { values, setValues } = formik;
  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  const deductionStatusOptions = useMemo(
    () => Object.entries(PayPeriodDeductionStatusEnum).map(([key, value]) => ({
      value,
      label: key
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (char) => char.toUpperCase()),
    })),
    [],
  );

  const issueTextEwa = useMemo(() => (
    payPeriod.ewaRequiredActions.map((action) => {
      if (action === PayPeriodRequiredActionsEnum.CREATE_BATCH) {
        return `${t('dashboard:dashboard.payPeriodContent.issueLabel')} ${t(
          'dashboard:dashboard.payPeriodContent.issueMessages.createBatch',
        )}`;
      }

      if (action === PayPeriodRequiredActionsEnum.INCLUDE_LEDGERS_IN_BATCH) {
        return `${t('dashboard:dashboard.payPeriodContent.issueLabel')} ${t(
          'dashboard:dashboard.payPeriodContent.issueMessages.includeLedgersInBatch',
        )}`;
      }

      if (action === PayPeriodRequiredActionsEnum.SETTLE_LEDGERS_IN_BATCH) {
        return `${t('dashboard:dashboard.payPeriodContent.issueLabel')} ${t(
          'dashboard:dashboard.payPeriodContent.issueMessages.settleLedgersInBatch',
        )}`;
      }
      return '';
    })
  ), [payPeriod.ewaRequiredActions]);

  const issueTextTpo = useMemo(() => (
    payPeriod.tpoRequiredActions.map((action) => {
      if (action === PayPeriodRequiredActionsEnum.CREATE_BATCH) {
        return `${t('dashboard:dashboard.payPeriodContent.issueLabel')} ${t(
          'dashboard:dashboard.payPeriodContent.issueMessages.createBatch',
        )}`;
      }

      if (action === PayPeriodRequiredActionsEnum.INCLUDE_LEDGERS_IN_BATCH) {
        return `${t('dashboard:dashboard.payPeriodContent.issueLabel')} ${t(
          'dashboard:dashboard.payPeriodContent.issueMessages.includeLedgersInBatch',
        )}`;
      }

      if (action === PayPeriodRequiredActionsEnum.SETTLE_LEDGERS_IN_BATCH) {
        return `${t('dashboard:dashboard.payPeriodContent.issueLabel')} ${t(
          'dashboard:dashboard.payPeriodContent.issueMessages.settleLedgersInBatch',
        )}`;
      }
      return '';
    })
  ), [payPeriod.tpoRequiredActions]);

  const formatDate = (date: string) => {
    const detectedTimeZone = moment.tz.guess();
    return moment
      .tz(date, detectedTimeZone)
      .format(t('dateFormats:standard-with-time-and-timezone'));
  };

  const handleActionsUpdate = () => {
    dispatch(
      updatePayPeriodActions({
        organizationId: organization?.id,
        payPeriodId: payPeriod.id,
        payGroupId: payPeriod.payGroup.id,
        payload: {
          sentToPayroll: values.sentToPayroll,
          ...(values.deductionStatus[0]
            ? {
              deductionStatus: values.deductionStatus[0].value,
            }
            : {}),
        },
      }),
    ).unwrap()
      .then(() => {
        if (deductionStatusState !== values.deductionStatus[0]?.value
          && deductionStatusState === PayPeriodDeductionStatusEnum.PARTIALLY_RECOVERED
        ) {
          dispatch(setNotification({
            type: NotificationType.SUCCESS,
            isOpen: true,
            titleKey: t('notifications:successfullyPayPeriodAsFullyRecovered'),
            autoHideDuration: 3000,
            multipleLinesTitle: true,
          }));
        } else if (
          deductionStatusState !== values.deductionStatus[0]?.value
          && deductionStatusState === PayPeriodDeductionStatusEnum.FULLY_RECOVERED
        ) {
          dispatch(setNotification({
            type: NotificationType.SUCCESS,
            isOpen: true,
            titleKey: t('notifications:successfullyPayPeriodAsPartiallyRecovered'),
            autoHideDuration: 3000,
            multipleLinesTitle: true,
          }));
        } else if (
          sentToPayrollState !== values.sentToPayroll
          && values.sentToPayroll
        ) {
          dispatch(setNotification({
            type: NotificationType.SUCCESS,
            isOpen: true,
            titleKey: t('notifications:successfullyMarkedPayPeriodSentToPayroll'),
            autoHideDuration: 3000,
            multipleLinesTitle: true,
          }));
        } else if (
          sentToPayrollState !== values.sentToPayroll
          && !values.sentToPayroll
        ) {
          dispatch(setNotification({
            type: NotificationType.SUCCESS,
            isOpen: true,
            titleKey: t('notifications:successfullyUnmarkedPayPeriodSentToPayroll'),
            autoHideDuration: 3000,
            multipleLinesTitle: true,
          }));
        }

        setDeductionStatusState(values.deductionStatus[0]?.value);
        setSentToPayrollState(values.sentToPayroll);
      })
      .catch(() => {
        if (
          deductionStatusState !== values.deductionStatus[0]?.value
          && deductionStatusState === PayPeriodDeductionStatusEnum.PARTIALLY_RECOVERED
        ) {
          dispatch(setNotification({
            type: NotificationType.ERROR,
            isOpen: true,
            titleKey: t('errors:unableToMarkPayPeriodAsFullyRecovered'),
            autoHideDuration: 3000,
            multipleLinesTitle: true,
          }));
        } else if (
          deductionStatusState !== values.deductionStatus[0]?.value
          && deductionStatusState === PayPeriodDeductionStatusEnum.FULLY_RECOVERED
        ) {
          dispatch(setNotification({
            type: NotificationType.ERROR,
            isOpen: true,
            titleKey: t('errors:unableToMarkPayPeriodAsPartiallyRecovered'),
            autoHideDuration: 3000,
            multipleLinesTitle: true,
          }));
        } else if (
          sentToPayrollState !== values.sentToPayroll
          && values.sentToPayroll
        ) {
          dispatch(setNotification({
            type: NotificationType.ERROR,
            isOpen: true,
            titleKey: t('errors:unableToMarkPayPeriodSentToPayroll'),
            autoHideDuration: 3000,
            multipleLinesTitle: true,
          }));
        } else if (
          sentToPayrollState !== values.sentToPayroll
          && !values.sentToPayroll
        ) {
          dispatch(setNotification({
            type: NotificationType.ERROR,
            isOpen: true,
            titleKey: t('errors:unableToUnmarkPayPeriodSentToPayroll'),
            autoHideDuration: 3000,
            multipleLinesTitle: true,
          }));
        }

        setValues({
          ...values,
          sentToPayroll: sentToPayrollState,
          deductionStatus: deductionStatusState
            ? [
              {
                value: deductionStatusState,
                label: deductionStatusState
                  .toLowerCase()
                  .replace(/_/g, ' ')
                  .replace(/\b\w/g, (char) => char.toUpperCase()),
              },
            ]
            : [],
        });
      });
  };

  useEffect(() => {
    if (isFormChanged) {
      setDefaultValues(values);
    }
  }, [isFormChanged]);

  useEffect(() => {
    organization
      && isFormChanged
      && handleActionsUpdate();
  }, [values, isFormChanged]);

  const handleResolveClick = () => {
    history.push('/batches/section/0');
  };

  return (
    <PayPeriodStatusFormContext.Provider value={formik}>
      <Accordion accordion overrides={listItemAccordionStyles}>
        <Panel title={<PayPeriodItemHeader payPeriod={payPeriod} />}>
          <Grid align={ALIGNMENT.start} gridMargins={0}>
            <Cell span={[12, 6, 4]}>
              <ParagraphMedium margin="8px 0" style={{ fontWeight: '600' }}>
                {t('dashboard:dashboard.payPeriodContent.earnedWageAccessHeader')}
              </ParagraphMedium>
              <ParagraphSmall
                margin="4px 0"
                className={css(
                  isStatusFutureOrOpen ? {} : paragraphLowOpacityStyles,
                )}
              >
                {t('dashboard:dashboard.payPeriodContent.cutoffLabel')}
                {' '}
                {formatDate(payPeriod.ewaCutoffTime)}
              </ParagraphSmall>
              <ParagraphSmall
                margin="4px 0"
                className={css(
                  isStatusFutureOrOpen ? {} : paragraphLowOpacityStyles,
                )}
              >
                {t('dashboard:dashboard.payPeriodContent.payOutLabel')}
                {' '}
                {formatDate(payPeriod.officialCutoffTime)}
              </ParagraphSmall>
              <ParagraphSmall margin="16px 0 0" style={{ whiteSpace: 'normal' }}>
                {issueTextEwa.map((text) => (
                  <span key={text}>
                    {text}
                    <Button
                      kind={KIND.tertiary}
                      onClick={handleResolveClick}
                      overrides={{
                        Root: {
                          style: { padding: '8px' },
                        },
                      }}
                    >
                      {t('dashboard:dashboard.payPeriodContent.resolveLabel')}
                    </Button>
                    <br />
                  </span>
                ))}
              </ParagraphSmall>
            </Cell>
            <Cell span={[12, 6, 4]}>
              <ParagraphMedium margin="8px 0" style={{ fontWeight: '600' }}>
                {t('dashboard:dashboard.payPeriodContent.tipPayOutHeader')}
              </ParagraphMedium>
              <ParagraphSmall
                margin="4px 0"
                className={css(
                  isStatusFutureOrOpen ? {} : paragraphLowOpacityStyles,
                )}
              >
                {t('dashboard:dashboard.payPeriodContent.cutoffLabel')}
                {' '}
                {formatDate(payPeriod.tcoCutoffTime)}
              </ParagraphSmall>
              <ParagraphSmall
                margin="4px 0"
                className={css(
                  isStatusFutureOrOpen ? {} : paragraphLowOpacityStyles,
                )}
              >
                {t('dashboard:dashboard.payPeriodContent.payOutLabel')}
                {' '}
                {formatDate(payPeriod.officialCutoffTime)}
              </ParagraphSmall>
              <ParagraphSmall margin="9px 0 0" style={{ whiteSpace: 'normal' }}>
                {issueTextTpo.map((text) => (
                  <span key={text}>
                    {text}
                    <Button
                      kind={KIND.tertiary}
                      onClick={handleResolveClick}
                      overrides={{
                        Root: {
                          style: { padding: '8px' },
                        },
                      }}
                    >
                      {t('dashboard:dashboard.payPeriodContent.resolveLabel')}
                    </Button>
                    <br />
                  </span>
                ))}
              </ParagraphSmall>
            </Cell>
            <AppSelect
              name="deductionStatus"
              label={t(
                'dashboard:dashboard.payPeriodContent.deductionStatusLabel',
              )}
              options={deductionStatusOptions}
              cellSpan={[12, 4, 4]}
              context={PayPeriodStatusFormContext}
              placeholder={t('common:select')}
            >
              {values.deductionStatus[0]?.value
                === PayPeriodDeductionStatusEnum.PARTIALLY_RECOVERED && (
                <Block width="100%">
                  <PayPeriodBanner />
                </Block>
              )}
            </AppSelect>
          </Grid>
        </Panel>
      </Accordion>
    </PayPeriodStatusFormContext.Provider>
  );
};
export default PayPeriodItem;
