import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLocationsRequest } from 'api/locationsAPI';
import { fetchPayGroupsRequest } from 'api/payGroupsAPI';
import { RootState } from 'store';
import { FetchLocationsParamsType } from 'types/LocationTypes';

export const resetLinkEmploymentsFormEvent = createAction('events/resetLinkEmploymentsFormEvent');

export const saveOrganizationConfigsEvent = createAction('events/saveOrganizationConfigsEvent');

export const resetOrganizationEvent = createAction('events/resetOrganizationEvent');
export const resetOrganizationPaycardEvent = createAction('events/resetOrganizationPaycardEvent');

export const resetEmployeeDetailsWorkersDrawsAndOffers = createAction('events/resetEmployeeDetailsWorkersDrawsAndOffers');

export const resetNotification = createAction('events/resetNotifications');

export const fetchPayGroupsWithLocations = createAsyncThunk(
  'payGroupsWithLocations',
  async (params: FetchLocationsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID } = params || {};
    const storeState = getState() as RootState;

    try {
      const result = await Promise.all([
        fetchPayGroupsRequest(storeState.user.accessToken, organizationID),
        fetchLocationsRequest(storeState.user.accessToken, organizationID),
      ]);
      return [
        result[0]?.values,
        result[1]?.values,
      ];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
