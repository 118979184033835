import {
  createContext,
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'baseui';
import {
  ConfigType,
  TipSourceConfigurationValuesType,
} from 'types/OrganizationTypes';
import {
  useFormik,
  FormikHandlers,
  FormikHelpers,
  FormikState,
} from 'formik';
import Loader from 'components/Loader';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  defaultConfigSelector,
  editOrganizationTipSourceConfiguration,
  organizationConfigSelector,
  organizationSelector,
  organizationTipSourceConfigSuccessfullySavedSelector,
  organizationsPendingConfigsSelector,
} from 'store/slices/organizations';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { TipSourceConfigurationInitialValues } from 'initialValues/OrganizationInitialValues';
import {
  defaultConfigMapper,
  organizationConfigMapper,
  saveOrganizationTipSourceConfigurationMapper,
} from 'dataMappers/organizationsDataMappers';
import { Block } from 'baseui/block';
import {
  Button,
  KIND,
  SIZE,
} from 'baseui/button';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { useHistory } from 'react-router-dom';
import { Navigation } from 'baseui/side-navigation';
import {
  LabelLarge,
  LabelMedium,
} from 'baseui/typography';
import AppCheckbox from 'components/Form/AppCheckbox';
import {
  LABEL_PLACEMENT,
  STYLE_TYPE,
} from 'baseui/checkbox';
import { colors } from 'theme';
import { errorSelector } from 'store/slices/error';
import { StyleObject } from 'styletron-react';

const containerStyles = {
  position: 'relative',
} as StyleObject;

export const TipSourceConfigurationFormContext = createContext({} as FormikState<TipSourceConfigurationValuesType>
  & FormikHelpers<TipSourceConfigurationValuesType>
  & FormikHandlers);

const OrganizationFormTipSourceConfigurationSection = () => {
  const { t } = useTranslation(['organizations', 'common']);
  const [css] = useStyletron();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const organization = useAppSelector(organizationSelector);
  const defaultConfig = useAppSelector(defaultConfigSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const pending = useAppSelector(organizationsPendingConfigsSelector);
  const error = useAppSelector(errorSelector);
  const [activeItemId, setActiveItemId] = useState('ewa');
  const successfullySaved = useAppSelector(organizationTipSourceConfigSuccessfullySavedSelector);

  const initialValues = {
    ...TipSourceConfigurationInitialValues,
    ...(defaultConfig && defaultConfigMapper(defaultConfig)),
    ...(organizationConfig && organizationConfigMapper(organizationConfig)),
    ewa3rdPartyTips: organizationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_3RD_PARTY_TIPS)?.configValue === 'true'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.SUBTRACT_INCLUDED_3RD_PARTY_TIPS)?.configValue === 'true',
    ewaServiceCharges: organizationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_SERVICE_CHARGES)?.configValue === 'true'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.SUBTRACT_INCLUDED_SERVICE_CHARGES)?.configValue === 'true',
    ewaCashTips: organizationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_CASH_TIPS)?.configValue === 'true'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.SUBTRACT_INCLUDED_CASH_TIPS)?.configValue === 'true',
    ewaCCTips: organizationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_CC_TIPS)?.configValue === 'true'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.SUBTRACT_INCLUDED_CC_TIPS)?.configValue === 'true',
    ewaDeclaredTips: organizationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_DECLARED_TIPS)?.configValue === 'true'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.SUBTRACT_INCLUDED_DECLARED_TIPS)?.configValue === 'true',
    ewaTipsPool: organizationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_TIPS_POOL)?.configValue === 'true'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.SUBTRACT_INCLUDED_TIPS_POOL)?.configValue === 'true',
    tpo3rdPartyTips: organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_3RD_PARTY_TIPS)?.configValue === 'true'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_SUBTRACT_INCLUDED_3RD_PARTY_TIPS)?.configValue === 'true',
    tpoServiceCharges: organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_SERVICE_CHARGES)?.configValue === 'true'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_SUBTRACT_INCLUDED_SERVICE_CHARGES)?.configValue === 'true',
    tpoCashTips: organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_CASH_TIPS)?.configValue === 'true'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_SUBTRACT_INCLUDED_CASH_TIPS)?.configValue === 'true',
    tpoCCTips: organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_CC_TIPS)?.configValue === 'true'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_SUBTRACT_INCLUDED_CC_TIPS)?.configValue === 'true',
    tpoDeclaredTips: organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_DECLARED_TIPS)?.configValue === 'true'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_SUBTRACT_INCLUDED_DECLARED_TIPS)?.configValue === 'true',
    tpoTipsPool: organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_TIPS_POOL)?.configValue === 'true'
    || organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_SUBTRACT_INCLUDED_TIPS_POOL)?.configValue === 'true',
  };

  const handleClickSave = (
    values: TipSourceConfigurationValuesType,
  ) => {
    dispatch(editOrganizationTipSourceConfiguration({
      organizationID: organization?.id,
      configs: saveOrganizationTipSourceConfigurationMapper(values),
    }));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleClickSave,
  });

  const {
    values,
    handleSubmit,
    isSubmitting,
    setValues,
    setFieldValue,
    setSubmitting,
  } = formik;

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  const handleClickCancel = () => {
    history.push('/organizations');
  };

  useEffect(() => {
    if (organizationConfig) {
      setDefaultValues(initialValues);
      setValues(initialValues);
    }
  }, [organizationConfig]);

  useEffect(() => {
    if (successfullySaved) {
      setSubmitting(false);
    }
  }, [successfullySaved]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  return (
    <TipSourceConfigurationFormContext.Provider value={formik}>
      <div className={css(containerStyles)}>
        <Loader active={pending} />

        <form onSubmit={handleSubmit}>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
            gridGutters={0}
          >
            <Cell
              span={[12, 6, 2]}
            >
              <Navigation
                items={[
                  {
                    title: t('organizations:tipSourceConfig.EWA.section.label'),
                    itemId: 'ewa',
                  },
                  {
                    title: t('organizations:tipSourceConfig.TPO.section.label'),
                    itemId: 'tpo',
                  },
                ]}
                overrides={{
                  NavItem: {
                    style: ({ $active }) => {
                      if (!$active) {
                        return {
                          ':hover': {
                            backgroundColor: '#EBEBEB',
                          },
                        };
                      }
                      return {
                        borderLeftColor: colors.primary,
                      };
                    },
                  },
                }}
                activeItemId={activeItemId}
                onChange={({ event, item }) => {
                  event.preventDefault();
                  item.itemId && setActiveItemId(item.itemId);
                }}
              />
            </Cell>
            <Cell
              span={[12, 6, 10]}
              overrides={{
                Cell: {
                  style: {
                    background: '#EBEBEB',
                    padding: '16px',
                  },
                },
              }}
            >
              <LabelLarge marginLeft="16px">{t('organizations:tipSourceConfig.tips.label')}</LabelLarge>
              <br />
              <Grid
                gridColumns={12}
                align={ALIGNMENT.start}
                gridMargins={16}
              >
                <Cell
                  span={[12, 12, 4.5]}
                  align={ALIGNMENT.start}
                  overrides={{
                    Cell: {
                      style: {
                        paddingBottom: '16px',
                      },
                    },
                  }}
                >
                  <LabelMedium>{t('organizations:tipSourceConfig.ThirdPartyTips.label')}</LabelMedium>
                  <Grid
                    gridColumns={12}
                    align={ALIGNMENT.start}
                    gridMargins={16}
                  >
                    <AppCheckbox
                      name={`${activeItemId}3rdPartyTips`}
                      label={t('organizations:tipSourceConfig.totalTips.label')}
                      labelPlacement={LABEL_PLACEMENT.right}
                      checkboxType={STYLE_TYPE.default}
                      cellSpan={[12, 12, 5]}
                      context={TipSourceConfigurationFormContext}
                      checkboxProps={{
                        onChange: (option) => {
                          const isChecked = option.currentTarget.checked;
                          const currentOption = option.currentTarget.name;
                          setValues({
                            ...values,
                            ewa3rdPartyTips: currentOption === 'ewa3rdPartyTips' ? isChecked : values.ewa3rdPartyTips,
                            ewaInclude3rdPartyTips: currentOption === 'ewa3rdPartyTips' ? isChecked : values.ewaInclude3rdPartyTips,
                            ewaSubtractIncluded3rdPartyTips: currentOption === 'ewa3rdPartyTips' ? false : values.ewaSubtractIncluded3rdPartyTips,
                            tpo3rdPartyTips: currentOption === 'tpo3rdPartyTips' ? isChecked : values.tpo3rdPartyTips,
                            tpoInclude3rdPartyTips: currentOption === 'tpo3rdPartyTips' ? isChecked : values.tpoInclude3rdPartyTips,
                            tpoSubtractIncluded3rdPartyTips: currentOption === 'tpo3rdPartyTips' ? false : values.tpoSubtractIncluded3rdPartyTips,
                          });
                        },
                      }}
                    />
                    <Cell
                      span={[12, 12, 7]}
                      align={ALIGNMENT.start}
                      overrides={{
                        Cell: {
                          style: {
                            marginTop: '8px',
                          },
                        },
                      }}
                    >
                      <Button
                        type="button"
                        kind={values[`${activeItemId}Include3rdPartyTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                        size={SIZE.compact}
                        disabled={!values[`${activeItemId}3rdPartyTips` as keyof typeof values]}
                        onClick={() => {
                          setFieldValue(`${activeItemId}Include3rdPartyTips`, true);
                          setFieldValue(`${activeItemId}SubtractIncluded3rdPartyTips`, false);
                        }}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}3rdPartyTips-add`,
                              name: `${activeItemId}Include3rdPartyTips`,
                            },
                            style: {
                              borderBottomStyle: 'solid',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderRightStyle: 'solid',
                              borderBottomWidth: '2px',
                              borderTopWidth: '2px',
                              borderLeftWidth: '2px',
                              borderRightWidth: '2px',
                              borderColor: !values[`${activeItemId}3rdPartyTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                            },
                          },
                        }}
                      >
                        {t('common:add')}
                      </Button>
                      <Button
                        type="button"
                        kind={values[`${activeItemId}SubtractIncluded3rdPartyTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                        size={SIZE.compact}
                        disabled={!values[`${activeItemId}3rdPartyTips` as keyof typeof values]}
                        onClick={() => {
                          setFieldValue(`${activeItemId}Include3rdPartyTips`, false);
                          setFieldValue(`${activeItemId}SubtractIncluded3rdPartyTips`, true);
                        }}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}3rdPartyTips-deduct`,
                              name: `${activeItemId}SubtractIncluded3rdPartyTips`,
                            },
                            style: {
                              borderBottomStyle: 'solid',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderRightStyle: 'solid',
                              borderBottomWidth: '2px',
                              borderTopWidth: '2px',
                              borderLeftWidth: '2px',
                              borderRightWidth: '2px',
                              borderColor: !values[`${activeItemId}3rdPartyTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                            },
                          },
                        }}
                      >
                        {t('common:deduct')}
                      </Button>
                    </Cell>
                  </Grid>
                </Cell>
                <Cell span={[12, 12, 7]} align={ALIGNMENT.start}>
                  <LabelMedium>{t('organizations:tipSourceConfig.TnA.label')}</LabelMedium>
                  <Grid
                    gridColumns={12}
                    align={ALIGNMENT.start}
                    gridMargins={16}
                  >
                    <AppCheckbox
                      name={`${activeItemId}ServiceCharges`}
                      label={t('organizations:tipSourceConfig.serviceCharges.label')}
                      labelPlacement={LABEL_PLACEMENT.right}
                      checkboxType={STYLE_TYPE.default}
                      cellSpan={[12, 12, 4]}
                      context={TipSourceConfigurationFormContext}
                      checkboxProps={{
                        onChange: (option) => {
                          const isChecked = option.currentTarget.checked;
                          const currentOption = option.currentTarget.name;
                          setValues({
                            ...values,
                            ewaServiceCharges: currentOption === 'ewaServiceCharges' ? isChecked : values.ewaServiceCharges,
                            ewaIncludeServiceCharges: currentOption === 'ewaServiceCharges' ? isChecked : values.ewaIncludeServiceCharges,
                            ewaSubtractIncludedServiceCharges: currentOption === 'ewaServiceCharges' ? false : values.ewaSubtractIncludedServiceCharges,
                            tpoServiceCharges: currentOption === 'tpoServiceCharges' ? isChecked : values.tpoServiceCharges,
                            tpoIncludeServiceCharges: currentOption === 'tpoServiceCharges' ? isChecked : values.tpoIncludeServiceCharges,
                            tpoSubtractIncludedServiceCharges: currentOption === 'tpoServiceCharges' ? false : values.tpoSubtractIncludedServiceCharges,
                          });
                        },
                      }}
                    />
                    <Cell
                      span={[12, 12, 6]}
                      align={ALIGNMENT.start}
                      overrides={{
                        Cell: {
                          style: {
                            marginTop: '8px',
                          },
                        },
                      }}
                    >
                      <Button
                        type="button"
                        kind={values[`${activeItemId}IncludeServiceCharges` as keyof typeof values] ? KIND.primary : KIND.secondary}
                        size={SIZE.compact}
                        disabled={!values[`${activeItemId}ServiceCharges` as keyof typeof values]}
                        onClick={() => {
                          setFieldValue(`${activeItemId}IncludeServiceCharges`, true);
                          setFieldValue(`${activeItemId}SubtractIncludedServiceCharges`, false);
                        }}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}ServiceCharges-add`,
                              name: `${activeItemId}IncludeServiceCharges`,
                            },
                            style: {
                              borderBottomStyle: 'solid',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderRightStyle: 'solid',
                              borderBottomWidth: '2px',
                              borderTopWidth: '2px',
                              borderLeftWidth: '2px',
                              borderRightWidth: '2px',
                              borderColor: !values[`${activeItemId}ServiceCharges` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                            },
                          },
                        }}
                      >
                        {t('common:add')}
                      </Button>
                      <Button
                        type="button"
                        kind={values[`${activeItemId}SubtractIncludedServiceCharges` as keyof typeof values] ? KIND.primary : KIND.secondary}
                        size={SIZE.compact}
                        disabled={!values[`${activeItemId}ServiceCharges` as keyof typeof values]}
                        onClick={() => {
                          setFieldValue(`${activeItemId}IncludeServiceCharges`, false);
                          setFieldValue(`${activeItemId}SubtractIncludedServiceCharges`, true);
                        }}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}ServiceCharges-deduct`,
                              name: `${activeItemId}SubtractIncludedServiceCharges`,
                            },
                            style: {
                              borderBottomStyle: 'solid',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderRightStyle: 'solid',
                              borderBottomWidth: '2px',
                              borderTopWidth: '2px',
                              borderLeftWidth: '2px',
                              borderRightWidth: '2px',
                              borderColor: !values[`${activeItemId}ServiceCharges` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                            },
                          },
                        }}
                      >
                        {t('common:deduct')}
                      </Button>
                    </Cell>
                  </Grid>
                  <Grid
                    gridColumns={12}
                    align={ALIGNMENT.start}
                    gridMargins={16}
                  >
                    <AppCheckbox
                      name={`${activeItemId}CashTips`}
                      label={t('organizations:tipSourceConfig.cashTips.label')}
                      labelPlacement={LABEL_PLACEMENT.right}
                      checkboxType={STYLE_TYPE.default}
                      cellSpan={[12, 12, 4]}
                      context={TipSourceConfigurationFormContext}
                      checkboxProps={{
                        onChange: (option) => {
                          const isChecked = option.currentTarget.checked;
                          const currentOption = option.currentTarget.name;
                          setValues({
                            ...values,
                            ewaCashTips: currentOption === 'ewaCashTips' ? isChecked : values.ewaCashTips,
                            ewaIncludeCashTips: currentOption === 'ewaCashTips' ? isChecked : values.ewaIncludeCashTips,
                            ewaSubtractIncludedCashTips: currentOption === 'ewaCashTips' ? false : values.ewaSubtractIncludedCashTips,
                            tpoCashTips: currentOption === 'tpoCashTips' ? isChecked : values.tpoCashTips,
                            tpoIncludeCashTips: currentOption === 'tpoCashTips' ? isChecked : values.tpoIncludeCashTips,
                            tpoSubtractIncludedCashTips: currentOption === 'tpoCashTips' ? false : values.tpoSubtractIncludedCashTips,
                          });
                        },
                      }}
                    />
                    <Cell
                      span={[12, 12, 6]}
                      align={ALIGNMENT.start}
                      overrides={{
                        Cell: {
                          style: {
                            marginTop: '8px',
                          },
                        },
                      }}
                    >
                      <Button
                        type="button"
                        kind={values[`${activeItemId}IncludeCashTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                        size={SIZE.compact}
                        disabled={!values[`${activeItemId}CashTips` as keyof typeof values]}
                        onClick={() => {
                          setFieldValue(`${activeItemId}IncludeCashTips`, true);
                          setFieldValue(`${activeItemId}SubtractIncludedCashTips`, false);
                        }}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}CashTips-add`,
                              name: `${activeItemId}IncludeCashTips`,
                            },
                            style: {
                              borderBottomStyle: 'solid',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderRightStyle: 'solid',
                              borderBottomWidth: '2px',
                              borderTopWidth: '2px',
                              borderLeftWidth: '2px',
                              borderRightWidth: '2px',
                              borderColor: !values[`${activeItemId}CashTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                            },
                          },
                        }}
                      >
                        {t('common:add')}
                      </Button>
                      <Button
                        type="button"
                        kind={values[`${activeItemId}SubtractIncludedCashTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                        size={SIZE.compact}
                        disabled={!values[`${activeItemId}CashTips` as keyof typeof values]}
                        onClick={() => {
                          setFieldValue(`${activeItemId}IncludeCashTips`, false);
                          setFieldValue(`${activeItemId}SubtractIncludedCashTips`, true);
                        }}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}CashTips-deduct`,
                              name: `${activeItemId}SubtractIncludedCashTips`,
                            },
                            style: {
                              borderBottomStyle: 'solid',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderRightStyle: 'solid',
                              borderBottomWidth: '2px',
                              borderTopWidth: '2px',
                              borderLeftWidth: '2px',
                              borderRightWidth: '2px',
                              borderColor: !values[`${activeItemId}CashTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                            },
                          },
                        }}
                      >
                        {t('common:deduct')}
                      </Button>
                    </Cell>
                  </Grid>
                  <Grid
                    gridColumns={12}
                    align={ALIGNMENT.start}
                    gridMargins={16}
                  >
                    <AppCheckbox
                      name={`${activeItemId}CCTips`}
                      label={t('organizations:tipSourceConfig.cardTips.label')}
                      labelPlacement={LABEL_PLACEMENT.right}
                      checkboxType={STYLE_TYPE.default}
                      cellSpan={[12, 12, 4]}
                      context={TipSourceConfigurationFormContext}
                      checkboxProps={{
                        onChange: (option) => {
                          const isChecked = option.currentTarget.checked;
                          const currentOption = option.currentTarget.name;
                          setValues({
                            ...values,
                            ewaCCTips: currentOption === 'ewaCCTips' ? isChecked : values.ewaCCTips,
                            ewaIncludeCCTips: currentOption === 'ewaCCTips' ? isChecked : values.ewaIncludeCCTips,
                            ewaSubtractIncludedCCTips: currentOption === 'ewaCCTips' ? false : values.ewaSubtractIncludedCCTips,
                            tpoCCTips: currentOption === 'tpoCCTips' ? isChecked : values.tpoCCTips,
                            tpoIncludeCCTips: currentOption === 'tpoCCTips' ? isChecked : values.tpoIncludeCCTips,
                            tpoSubtractIncludedCCTips: currentOption === 'tpoCCTips' ? false : values.tpoSubtractIncludedCCTips,
                          });
                        },
                      }}
                    />
                    <Cell
                      span={[12, 12, 6]}
                      align={ALIGNMENT.start}
                      overrides={{
                        Cell: {
                          style: {
                            marginTop: '8px',
                          },
                        },
                      }}
                    >
                      <Button
                        type="button"
                        kind={values[`${activeItemId}IncludeCCTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                        size={SIZE.compact}
                        disabled={!values[`${activeItemId}CCTips` as keyof typeof values]}
                        onClick={() => {
                          setFieldValue(`${activeItemId}IncludeCCTips`, true);
                          setFieldValue(`${activeItemId}SubtractIncludedCCTips`, false);
                        }}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}CCTips-add`,
                              name: `${activeItemId}IncludeCCTips`,
                            },
                            style: {
                              borderBottomStyle: 'solid',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderRightStyle: 'solid',
                              borderBottomWidth: '2px',
                              borderTopWidth: '2px',
                              borderLeftWidth: '2px',
                              borderRightWidth: '2px',
                              borderColor: !values[`${activeItemId}CCTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                            },
                          },
                        }}
                      >
                        {t('common:add')}
                      </Button>
                      <Button
                        type="button"
                        kind={values[`${activeItemId}SubtractIncludedCCTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                        size={SIZE.compact}
                        disabled={!values[`${activeItemId}CCTips` as keyof typeof values]}
                        onClick={() => {
                          setFieldValue(`${activeItemId}IncludeCCTips`, false);
                          setFieldValue(`${activeItemId}SubtractIncludedCCTips`, true);
                        }}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}CCTips-deduct`,
                              name: `${activeItemId}SubtractIncludedCCTips`,
                            },
                            style: {
                              borderBottomStyle: 'solid',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderRightStyle: 'solid',
                              borderBottomWidth: '2px',
                              borderTopWidth: '2px',
                              borderLeftWidth: '2px',
                              borderRightWidth: '2px',
                              borderColor: !values[`${activeItemId}CCTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                            },
                          },
                        }}
                      >
                        {t('common:deduct')}
                      </Button>
                    </Cell>
                  </Grid>
                  <Grid
                    gridColumns={12}
                    align={ALIGNMENT.start}
                    gridMargins={16}
                  >
                    <AppCheckbox
                      name={`${activeItemId}DeclaredTips`}
                      label={t('organizations:tipSourceConfig.declaredTips.label')}
                      labelPlacement={LABEL_PLACEMENT.right}
                      checkboxType={STYLE_TYPE.default}
                      cellSpan={[12, 12, 4]}
                      context={TipSourceConfigurationFormContext}
                      checkboxProps={{
                        onChange: (option) => {
                          const isChecked = option.currentTarget.checked;
                          const currentOption = option.currentTarget.name;
                          setValues({
                            ...values,
                            ewaDeclaredTips: currentOption === 'ewaDeclaredTips' ? isChecked : values.ewaDeclaredTips,
                            ewaIncludeDeclaredTips: currentOption === 'ewaDeclaredTips' ? isChecked : values.ewaIncludeDeclaredTips,
                            ewaSubtractIncludedDeclaredTips: currentOption === 'ewaDeclaredTips' ? false : values.ewaSubtractIncludedDeclaredTips,
                            tpoDeclaredTips: currentOption === 'tpoDeclaredTips' ? isChecked : values.tpoDeclaredTips,
                            tpoIncludeDeclaredTips: currentOption === 'tpoDeclaredTips' ? isChecked : values.tpoIncludeDeclaredTips,
                            tpoSubtractIncludedDeclaredTips: currentOption === 'tpoDeclaredTips' ? false : values.tpoSubtractIncludedDeclaredTips,
                          });
                        },
                      }}
                    />
                    <Cell
                      span={[12, 12, 6]}
                      align={ALIGNMENT.start}
                      overrides={{
                        Cell: {
                          style: {
                            marginTop: '8px',
                          },
                        },
                      }}
                    >
                      <Button
                        type="button"
                        kind={values[`${activeItemId}IncludeDeclaredTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                        size={SIZE.compact}
                        disabled={!values[`${activeItemId}DeclaredTips` as keyof typeof values]}
                        onClick={() => {
                          setFieldValue(`${activeItemId}IncludeDeclaredTips`, true);
                          setFieldValue(`${activeItemId}SubtractIncludedDeclaredTips`, false);
                        }}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}DeclaredTips-add`,
                              name: `${activeItemId}IncludeDeclaredTips`,
                            },
                            style: {
                              borderBottomStyle: 'solid',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderRightStyle: 'solid',
                              borderBottomWidth: '2px',
                              borderTopWidth: '2px',
                              borderLeftWidth: '2px',
                              borderRightWidth: '2px',
                              borderColor: !values[`${activeItemId}DeclaredTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                            },
                          },
                        }}
                      >
                        {t('common:add')}
                      </Button>
                      <Button
                        type="button"
                        kind={values[`${activeItemId}SubtractIncludedDeclaredTips` as keyof typeof values] ? KIND.primary : KIND.secondary}
                        size={SIZE.compact}
                        disabled={!values[`${activeItemId}DeclaredTips` as keyof typeof values]}
                        onClick={() => {
                          setFieldValue(`${activeItemId}IncludeDeclaredTips`, false);
                          setFieldValue(`${activeItemId}SubtractIncludedDeclaredTips`, true);
                        }}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}DeclaredTips-deduct`,
                              name: `${activeItemId}SubtractIncludedDeclaredTips`,
                            },
                            style: {
                              borderBottomStyle: 'solid',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderRightStyle: 'solid',
                              borderBottomWidth: '2px',
                              borderTopWidth: '2px',
                              borderLeftWidth: '2px',
                              borderRightWidth: '2px',
                              borderColor: !values[`${activeItemId}DeclaredTips` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                            },
                          },
                        }}
                      >
                        {t('common:deduct')}
                      </Button>
                    </Cell>
                  </Grid>
                  <Grid
                    gridColumns={12}
                    align={ALIGNMENT.start}
                    gridMargins={16}
                  >
                    <AppCheckbox
                      name={`${activeItemId}TipsPool`}
                      label={t('organizations:tipSourceConfig.TipsPool.label')}
                      labelPlacement={LABEL_PLACEMENT.right}
                      checkboxType={STYLE_TYPE.default}
                      cellSpan={[12, 12, 4]}
                      context={TipSourceConfigurationFormContext}
                      checkboxProps={{
                        onChange: (option) => {
                          const isChecked = option.currentTarget.checked;
                          const currentOption = option.currentTarget.name;
                          setValues({
                            ...values,
                            ewaTipsPool: currentOption === 'ewaTipsPool' ? isChecked : values.ewaTipsPool,
                            ewaIncludeTipsPool: currentOption === 'ewaTipsPool' ? isChecked : values.ewaIncludeTipsPool,
                            ewaSubtractIncludedTipsPool: currentOption === 'ewaTipsPool' ? false : values.ewaSubtractIncludedTipsPool,
                            tpoTipsPool: currentOption === 'tpoTipsPool' ? isChecked : values.tpoTipsPool,
                            tpoIncludeTipsPool: currentOption === 'tpoTipsPool' ? isChecked : values.tpoIncludeTipsPool,
                            tpoSubtractIncludedTipsPool: currentOption === 'tpoTipsPool' ? false : values.tpoSubtractIncludedTipsPool,
                          });
                        },
                      }}
                    />
                    <Cell
                      span={[12, 12, 6]}
                      align={ALIGNMENT.start}
                      overrides={{
                        Cell: {
                          style: {
                            marginTop: '8px',
                          },
                        },
                      }}
                    >
                      <Button
                        type="button"
                        kind={values[`${activeItemId}IncludeTipsPool` as keyof typeof values] ? KIND.primary : KIND.secondary}
                        size={SIZE.compact}
                        disabled={!values[`${activeItemId}TipsPool` as keyof typeof values]}
                        onClick={() => {
                          setFieldValue(`${activeItemId}IncludeTipsPool`, true);
                          setFieldValue(`${activeItemId}SubtractIncludedTipsPool`, false);
                        }}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}TipsPool-add`,
                              name: `${activeItemId}IncludeTipsPool`,
                            },
                            style: {
                              borderBottomStyle: 'solid',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderRightStyle: 'solid',
                              borderBottomWidth: '2px',
                              borderTopWidth: '2px',
                              borderLeftWidth: '2px',
                              borderRightWidth: '2px',
                              borderColor: !values[`${activeItemId}TipsPool` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                            },
                          },
                        }}
                      >
                        {t('common:add')}
                      </Button>
                      <Button
                        type="button"
                        kind={values[`${activeItemId}SubtractIncludedTipsPool` as keyof typeof values] ? KIND.primary : KIND.secondary}
                        size={SIZE.compact}
                        disabled={!values[`${activeItemId}TipsPool` as keyof typeof values]}
                        onClick={() => {
                          setFieldValue(`${activeItemId}IncludeTipsPool`, false);
                          setFieldValue(`${activeItemId}SubtractIncludedTipsPool`, true);
                        }}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: `OrganizationFormTipSourceConfigurationSection-${activeItemId}TipsPool-deduct`,
                              name: `${activeItemId}SubtractIncludedTipsPool`,
                            },
                            style: {
                              borderBottomStyle: 'solid',
                              borderTopStyle: 'solid',
                              borderLeftStyle: 'solid',
                              borderRightStyle: 'solid',
                              borderBottomWidth: '2px',
                              borderTopWidth: '2px',
                              borderLeftWidth: '2px',
                              borderRightWidth: '2px',
                              borderColor: !values[`${activeItemId}TipsPool` as keyof typeof values] ? colors.secondaryHover : colors.primary,
                            },
                          },
                        }}
                      >
                        {t('common:deduct')}
                      </Button>
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Cell>

          </Grid>
          <hr />

          <Block marginTop="24px">
            <Grid
              align={ALIGNMENT.start}
              gridColumns={12}
            >
              <Cell
                span={12}
              >
                <Block
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Block
                    display="inline-flex"
                    marginRight="16px"
                  >
                    <Button
                      type="button"
                      kind={KIND.secondary}
                      onClick={handleClickCancel}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: 'OrganizationFormTipSourceConfigurationSection-cancel',
                          },
                        },
                      }}
                    >
                      {t('common:cancel')}
                    </Button>
                  </Block>

                  <Button
                    type="submit"
                    kind={KIND.primary}
                    disabled={isSubmitting || !isFormChanged}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'OrganizationFormTipSourceConfigurationSection-save',
                        },
                      },
                    }}
                  >
                    {t('common:save')}
                  </Button>
                </Block>
              </Cell>
            </Grid>
          </Block>
        </form>
      </div>
    </TipSourceConfigurationFormContext.Provider>

  );
};

export default memo(OrganizationFormTipSourceConfigurationSection);
