import { Block } from 'baseui/block';
import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  LabelMedium,
  ParagraphMedium,
} from 'baseui/typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { emptyPlaceholder } from 'theme';
import { LoadFundsValuesType } from 'types/PaycardManagementTypes';
import priceFormatter from 'utils/priceFormatter';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { useAppSelector } from 'store/hooks';
import Loader from 'components/Loader';
import { paycardsLoadFundsPendingSelector } from 'store/slices/paycards';

export type Props = {
  formValues: LoadFundsValuesType | undefined
}

const LoadFundsReview = ({ formValues }: Props) => {
  const { t } = useTranslation(['common', 'paycardManagement']);
  const organization = useAppSelector(loggedOrganizationSelector);
  const pending = useAppSelector(paycardsLoadFundsPendingSelector);
  const { name: organizationName } = organization || {};

  return (
    <Grid
      gridColumns={12}
      gridMargins={24}
      overrides={{
        Grid: {
          style: {
            padding: '24px',
          },
        },
      }}
    >
      <Loader active={pending} />
      <Cell span={[12]}>
        <Block>
          <LabelMedium>{t('paycardManagement:paycardManagement.paycards.manageFunds.loadFunds.loadedFunds')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{(formValues?.amount && priceFormatter().format(Number(formValues?.amount))) || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.paycards.manageFunds.paymentDescription.labelReview')}</LabelMedium>
          <ParagraphMedium marginTop="4px">
            {formValues?.paymentDescription
            || t(
              'paycardManagement:paycardManagement.paycards.manageFunds.paymentDescription.placeholderUnloadFundsEmpty',
              { orgName: `${organizationName || ''}` },
            )}
          </ParagraphMedium>
        </Block>
        <Block marginTop="24px">
          <LabelMedium>{t('paycardManagement:paycardManagement.paycards.manageFunds.internalNotes.labelReview')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{formValues?.internalNotes || emptyPlaceholder}</ParagraphMedium>
        </Block>
      </Cell>
    </Grid>
  );
};

export default memo(LoadFundsReview);
