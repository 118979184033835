import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  ChangeEvent,
  memo,
  useEffect,
  useState,
} from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  tippedEmployeesSelectedLocationSelector,
  fetchTippedEmployeesByBusinessDate,
  tippedEmployeesByBusinessDatePendingListSelector,
  tpoSearchByBusinessDateNameSelector,
  setTPOSearchNameInBusinessDate,
  tippedEmployeesByBusinessDateTotalSizeSelector,
  tpoSearchBusinessDateSelector,
  dailyFileUploadedSuccessfullySelector,
} from 'store/slices/tpo';
import { useStyletron } from 'baseui';
import Loader from 'components/Loader';
import CellFormControl from 'components/CellFormControl';
import {
  Input,
  SIZE,
} from 'baseui/input';
import { Search } from 'baseui/icon';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import {
  LOADER_STYLE,
  contentLocationContainerStyles,
  listContainerStyles,
} from './TipManagementHelper';
import TippedEmployeesPerBusinessDateTable from './TippedEmployeesPerBusinessDateTable';

let timer: any;

const TipManagementBusinessDateSection = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['tipsManagement', 'dateFormats', 'common']);
  const dispatch = useAppDispatch();
  const location = useAppSelector(tippedEmployeesSelectedLocationSelector);
  const loggedOrganizationID = useAppSelector(loggedOrganizationSelector)?.id;
  const selectedLocation = useAppSelector(tippedEmployeesSelectedLocationSelector);
  const pendingList = useAppSelector(tippedEmployeesByBusinessDatePendingListSelector);
  const search = useAppSelector(tpoSearchByBusinessDateNameSelector);
  const totalSize = useAppSelector(tippedEmployeesByBusinessDateTotalSizeSelector);
  const businessDate = useAppSelector(tpoSearchBusinessDateSelector);
  const [searchDebounce, setSearchDebounce] = useState('');
  const dailyFileUploadedSuccessfully = useAppSelector(dailyFileUploadedSuccessfullySelector);
  const dateFormatAPI = t('dateFormats:standard-reverse');

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    dispatch(setTPOSearchNameInBusinessDate(e.target.value));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();
    if (selectedLocation && selectedLocation.length > 0) {
      dispatch(fetchTippedEmployeesByBusinessDate({
        organizationID: loggedOrganizationID,
        locationID: selectedLocation?.[0]?.id?.toString(),
        businessDate: moment(businessDate).format(dateFormatAPI),
        namePattern: search,
        pageNumber: page,
      }));
    }
  };

  useEffect(() => {
    if (search === searchDebounce) {
      dispatch(fetchTippedEmployeesByBusinessDate({
        organizationID: loggedOrganizationID,
        locationID: selectedLocation?.[0]?.id?.toString(),
        businessDate: moment(businessDate).format(dateFormatAPI),
        namePattern: search,
        pageNumber: '1',
      }));
    }
  }, [
    searchDebounce,
    selectedLocation,
    businessDate,
  ]);

  useEffect(() => {
    if (dailyFileUploadedSuccessfully) {
      dispatch(fetchTippedEmployeesByBusinessDate({
        organizationID: loggedOrganizationID,
        locationID: selectedLocation?.[0]?.id?.toString(),
        businessDate: moment(businessDate).format(dateFormatAPI),
        namePattern: search,
        pageNumber: '1',
      }));
    }
  }, [
    dailyFileUploadedSuccessfully,
  ]);

  useEffect(() => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      setSearchDebounce(search);
    }, 1000);
  }, [search]);

  return (
    <Grid gridColumns={12}>
      <div className={css(contentLocationContainerStyles)}>
        <Grid gridColumns={12} gridMargins={20}>
          <CellFormControl
            cellSpan={[12, 4, 2]}
            label={t('common:searchButton')}
          >
            <Input
              disabled={!location || !location?.length || pendingList}
              clearable
              clearOnEscape
              size={SIZE.compact}
              startEnhancer={<Search />}
              type="text"
              name="search"
              onChange={handleChangeSearch}
              value={search}
              placeholder={t('tipsManagement:searchByName.placeholder')}
              overrides={{
                Input: {
                  props: {
                    id: 'Tip-Management-Day-view-Search-by-NamePattern',
                    'data-testid': 'Tip-Management-Day-view-Search-by-NamePattern',
                    autoComplete: 'off',
                  },
                },
              }}
            />
          </CellFormControl>
        </Grid>
        {pendingList && (
        <div className={css(listContainerStyles)}>
          <Loader active={pendingList} containerStyles={{ LOADER_STYLE }} />
        </div>
        )}
        {!pendingList && (
        <Cell span={12}>
          <TippedEmployeesPerBusinessDateTable handlePageChange={handlePageChange} />
        </Cell>
        )}
      </div>
    </Grid>
  );
};

export default memo(TipManagementBusinessDateSection);
