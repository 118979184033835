import {
  ProcessRepaymentRequestsDataType, RepaymentRequestStatus, SaveOrganizaitonSettingsRequestDataType, EditRepaymentRequestDataType,
} from 'types/RepaymentsBankDetails';
import {
  BankAccountValuesType,
  OrganizationIDType,
} from 'types/OrganizationTypes';
import request from './request';

export const fetchOrganizationRepaymentsAccountsRequest = (
  token: string,
  organizationID: OrganizationIDType,
) => request({
  token,
  url: `/organizations/${organizationID}/source-accounts`,
});

export const fetchOrganizationRepaymentRequestsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  page: string,
  startDate?: string,
  endDate?: string,
  status?: RepaymentRequestStatus,
  sort?: string,
) => {
  const queryParams = new URLSearchParams({
    page,
  });
  if (startDate) {
    queryParams.set('fromDate', startDate);
  }
  if (endDate) {
    queryParams.set('toDate', endDate);
  }
  if (status) {
    queryParams.set('status', status);
  }
  if (sort) {
    queryParams.set('sort', sort);
  }

  return request({
    token,
    method: 'GET',
    url: `organizations/${organizationID}/repayment-requests?${queryParams.toString()}`,
  });
};
export const destinationAccountsRequest = (
  token: string,
) => request({
  token,
  url: '/destination-accounts',
});

export const saveSourceAccountRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: BankAccountValuesType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `/organizations/${organizationID}/source-accounts`,
});

export const editSourceAccountRequest = (
  token: string,
  organizationID: OrganizationIDType,
  accountID: string,
  data: BankAccountValuesType,
) => request({
  token,
  method: 'PUT',
  payload: data,
  url: `/organizations/${organizationID}/source-accounts/${accountID}`,
});

export const deleteSourceAccountRequest = (
  token: string,
  organizationID: OrganizationIDType,
  accountID: string,
) => request({
  token,
  method: 'DELETE',
  url: `/organizations/${organizationID}/source-accounts/${accountID}`,
});

export const processRepaymentRequestsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: ProcessRepaymentRequestsDataType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `/organizations/${organizationID}/repayment-requests/process`,
});

export const editRepaymentRequest = (
  token: string,
  organizationID: OrganizationIDType,
  requestId: number,
  data: EditRepaymentRequestDataType,
) => request({
  token,
  method: 'PUT',
  payload: data,
  url: `/organizations/${organizationID}/repayment-requests/${requestId}`,
});

export const fetchOrganizationSettingsRequest = (
  token: string,
  organizationID: OrganizationIDType,
) => request({
  token,
  url: `/organizations/${organizationID}/settings`,
});

export const saveOrganizationSettingsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: SaveOrganizaitonSettingsRequestDataType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `/organizations/${organizationID}/settings`,
});
