import {
  createContext,
  memo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Block } from 'baseui/block';
import {
  Button,
  KIND,
} from 'baseui/button';
import {
  Grid,
  Cell,
  ALIGNMENT,
} from 'baseui/layout-grid';
import { useHistory } from 'react-router-dom';
import {
  useFormik,
  FormikHandlers,
  FormikHelpers,
  FormikState,
} from 'formik';
import { EWASettingsInitialValues } from 'initialValues/OrganizationInitialValues';
import { EWASettingsValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import {
  defaultConfigSelector,
  editOrganizationEWASettings,
  organizationConfigSelector,
  organizationSelector,
  organizationsPendingConfigsSelector,
} from 'store/slices/organizations';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  defaultConfigMapper,
  organizationConfigMapper,
  saveOrganizationEWASettingsMapper,
} from 'dataMappers/organizationsDataMappers';
import { EWASettingsValuesType } from 'types/OrganizationTypes';
import AppInput from 'components/Form/AppInput';
import AppTimePicker from 'components/Form/AppTimePicker';
import AppCheckbox from 'components/Form/AppCheckbox';
import { currencyCodeSelector } from 'store/slices/application';
import Loader from 'components/Loader';
import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import { notificationSelector, setNotification } from 'store/slices/notification';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { LabelMedium } from 'baseui/typography';
import { TETHER_PLACEMENT } from 'baseui/layer';
import AppTooltip from 'components/Form/AppTooltip';
import AppSelect from 'components/Form/AppSelect';
import AppTextarea from 'components/Form/AppTextarea';
import {
  controlOverrides,
  gridOverrides,
} from '../OrganizationFormHelpers';

export const panelStyles = { display: 'flex', 'justify-content': 'space-between' };

export const EWASettingsFormContext = createContext({} as FormikState<EWASettingsValuesType> & FormikHelpers<EWASettingsValuesType> & FormikHandlers);

const containerStyles = {
  position: 'relative',
} as StyleObject;

const OrganizationFormEWASection = () => {
  const { t } = useTranslation(['organizations', 'errors', 'common']);
  const [css] = useStyletron();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const defaultConfig = useAppSelector(defaultConfigSelector);
  const pending = useAppSelector(organizationsPendingConfigsSelector);
  const currencyCode = useAppSelector(currencyCodeSelector);
  const organization = useAppSelector(organizationSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const notificationToast = useAppSelector(notificationSelector);

  const proRataType = [
    {
      value: 'CALENDAR_DAYS',
      label: t('organizations:proRataType.calendarDays.option.label'),
    },
  ];

  const initialValues = {
    ...EWASettingsInitialValues,
    ...(defaultConfig && defaultConfigMapper(defaultConfig)),
    ...(organizationConfig && organizationConfigMapper(organizationConfig)),
  };

  const onSubmit = (
    values: EWASettingsValuesType,
  ) => dispatch(editOrganizationEWASettings({
    organizationID: organization?.id,
    configs: saveOrganizationEWASettingsMapper(values),
  }));

  const onSubmitExtended = (
    props: EWASettingsValuesType,
  ) => {
    setNotification({ isOpen: false });
    onSubmit(props);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmitExtended,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    isSubmitting,
    setValues,
    isValid,
    setSubmitting,
  } = formik;

  useEffect(() => {
    if (notificationToast?.[1]?.isOpen) {
      setSubmitting(false);
    }
  }, [notificationToast]);

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  const handleClickCancel = () => {
    history.push('/organizations');
  };

  useEffect(() => {
    if (organizationConfig) {
      setDefaultValues(initialValues);
      setValues(initialValues);
    }
  }, [organizationConfig]);

  return (
    <EWASettingsFormContext.Provider value={formik}>
      <div className={css(containerStyles)}>
        <Loader active={pending} />

        <form onSubmit={handleSubmit}>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <Cell
              span={12}
              align={ALIGNMENT.start}
            >
              <LabelMedium>{t('organizations:program.enabled')}</LabelMedium>
            </Cell>

            <AppCheckbox
              name="ewaEnabled"
              label={t('organizations:program.labelEnabled')}
              cellSpan={12}
              context={EWASettingsFormContext}
            />
          </Grid>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <Cell
              span={12}
              align={ALIGNMENT.start}
            >
              <LabelMedium>{t('organizations:offer')}</LabelMedium>
            </Cell>

            <AppInput
              showStar
              name="offerPercentPerEmployee"
              label={t('organizations:labelOfferPercentPerEmployee')}
              cellSpan={[12, 6, 3]}
              context={EWASettingsFormContext}
              inputProps={{
                min: 0,
                max: 100,
                endEnhancer: '%',
                autoComplete: 'off',
                type: 'number',
                disabled: !values.ewaEnabled,
              }}
            />

            <AppInput
              showStar
              name="maxOfferTotal"
              label={t('organizations:labelMaxOfferTotal')}
              cellSpan={[12, 6, 3]}
              context={EWASettingsFormContext}
              inputProps={{
                min: 0,
                max: 999999.99,
                type: 'number',
                step: 'any' as any,
                endEnhancer: currencyCode,
                autoComplete: 'off',
                disabled: !values.ewaEnabled,
              }}
            />

            <AppInput
              showStar
              name="maxShiftLength"
              label={t('organizations:labelMaxShiftLength')}
              cellSpan={[12, 6, 3]}
              context={EWASettingsFormContext}
              inputProps={{
                min: 0,
                type: 'number',
                autoComplete: 'off',
                disabled: !values.ewaEnabled,
              }}
            />

            <AppTimePicker
              showStar
              name="offerAvailableTime"
              label={t('organizations:labelOfferAvailableTime')}
              cellSpan={[12, 6, 3]}
              context={EWASettingsFormContext}
              timePickerProps={{ nullable: true, disabled: !values.ewaEnabled }}
            />
          </Grid>

          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <Cell
              span={[12, 6, 6]}
              align={ALIGNMENT.start}
            >
              <LabelMedium>{t('organizations:organization.employeeTypes.header')}</LabelMedium>
              <Grid
                gridColumns={12}
                gridGutters={0}
                gridMargins={24}
                overrides={gridOverrides}
              >
                <Cell
                  span={[6, 12, 5]}
                  overrides={{
                    Cell: {
                      style: {
                        paddingTop: '18px',
                      },
                    },
                  }}
                >
                  <LabelMedium>
                    {t('organizations:employeeType.hourlyPaid.label')}
                    <AppTooltip
                      title={t('organizations:employeeType.hourlyPaid.tooltip.title')}
                      content={t('organizations:employeeType.hourlyPaid.tooltip.content')}
                      placement={TETHER_PLACEMENT.right}
                    />

                  </LabelMedium>
                </Cell>
                <Cell skip={[1, 0, 2]} />
                <AppCheckbox
                  name="hourlyPaidEmployeesEnable"
                  label={t('common:enable')}
                  cellSpan={[4, 12, 4]}
                  context={EWASettingsFormContext}
                  disabled={!values.ewaEnabled}
                  formControlProps={{ overrides: controlOverrides }}
                />
              </Grid>

              <Grid
                gridColumns={12}
                gridGutters={0}
                gridMargins={24}
                overrides={gridOverrides}
              >
                <Cell
                  span={[6, 12, 5]}
                  overrides={{
                    Cell: {
                      style: {
                        paddingTop: '18px',
                      },
                    },
                  }}
                >
                  <LabelMedium>
                    {t('organizations:employeeType.salaried.label')}
                    <AppTooltip
                      title={t('organizations:employeeType.salaried.tooltip.title')}
                      content={t('organizations:employeeType.salaried.tooltip.content')}
                      placement={TETHER_PLACEMENT.right}
                    />

                  </LabelMedium>
                </Cell>
                <Cell skip={[1, 0, 2]} />
                <AppCheckbox
                  name="salariedEmployeesEnable"
                  label={t('common:enable')}
                  cellSpan={[4, 12, 4]}
                  context={EWASettingsFormContext}
                  disabled={!values.ewaEnabled}
                  formControlProps={{ overrides: controlOverrides }}
                />
              </Grid>

              <Grid
                gridColumns={12}
                gridGutters={0}
                gridMargins={24}
                overrides={gridOverrides}
              >
                <Cell
                  span={[6, 12, 5]}
                  overrides={{
                    Cell: {
                      style: {
                        paddingTop: '18px',
                      },
                    },
                  }}
                >
                  <LabelMedium>
                    {t('organizations:employeeType.regularlyPaid.label')}
                    <AppTooltip
                      title={t('organizations:employeeType.regularlyPaid.tooltip.title')}
                      content={t('organizations:employeeType.regularlyPaid.tooltip.content')}
                      placement={TETHER_PLACEMENT.right}
                    />

                  </LabelMedium>
                </Cell>
                <Cell skip={[1, 0, 2]} />
                <AppCheckbox
                  name="regularlyPaidEmployeesEnable"
                  label={t('common:enable')}
                  cellSpan={[4, 12, 4]}
                  context={EWASettingsFormContext}
                  disabled={!values.ewaEnabled}
                  formControlProps={{ overrides: controlOverrides }}
                />
              </Grid>
              {(values.salariedEmployeesEnable || values.regularlyPaidEmployeesEnable)
            && (
              <Grid
                gridColumns={12}
                gridGutters={0}
                gridMargins={24}
                overrides={gridOverrides}
              >
                <AppSelect
                  clearable
                  showStar
                  name="offerProRataMethod"
                  label={t('organizations:proRataType.label')}
                  options={proRataType}
                  cellSpan={[12, 6]}
                  context={EWASettingsFormContext}
                  selectProps={{
                    disabled: !values.ewaEnabled,
                    clearable: false,
                  }}
                />
              </Grid>
            )}
            </Cell>

            <Cell
              span={[12, 6, 6]}
              align={ALIGNMENT.start}
            >
              <LabelMedium>{t('organizations:organization.shiftSettings.header')}</LabelMedium>

              <Grid
                gridColumns={12}
                gridGutters={0}
                gridMargins={24}
                overrides={gridOverrides}
              >
                <Cell
                  span={[6, 12, 5]}
                  overrides={{
                    Cell: {
                      style: {
                        paddingTop: '18px',
                      },
                    },
                  }}
                >
                  <LabelMedium>{t('organizations:shiftSettings.tippedShifts.label')}</LabelMedium>
                </Cell>
                <Cell skip={[1, 0, 2]} />
                <AppCheckbox
                  name="excludeTippedShifts"
                  label={t('common:exclude')}
                  cellSpan={[4, 12, 4]}
                  context={EWASettingsFormContext}
                  disabled={!values.ewaEnabled}
                  formControlProps={{ overrides: controlOverrides }}
                />
              </Grid>

              <Grid
                gridColumns={12}
                gridGutters={0}
                gridMargins={24}
                overrides={gridOverrides}
              >
                <Cell
                  span={[6, 12, 5]}
                  overrides={{
                    Cell: {
                      style: {
                        paddingTop: '18px',
                      },
                    },
                  }}
                >
                  <LabelMedium className="ellipsis">{t('organizations:shiftSettings.autoClockedOutShifts.label')}</LabelMedium>
                </Cell>
                <Cell skip={[1, 0, 2]} />
                <AppCheckbox
                  name="excludeAutoClockedOutShifts"
                  label={t('common:exclude')}
                  cellSpan={[4, 12, 4]}
                  context={EWASettingsFormContext}
                  disabled={!values.ewaEnabled}
                  formControlProps={{ overrides: controlOverrides }}
                />
                {values.excludeAutoClockedOutShifts && (
                <AppTimePicker
                  showStar
                  name="autoClockOutTime"
                  label={t('organizations:labelAutoclockOutTime')}
                  cellSpan={[12, 8]}
                  context={EWASettingsFormContext}
                  timePickerProps={{ nullable: true, disabled: !values.ewaEnabled }}
                />
                )}
              </Grid>

              <Grid
                gridColumns={12}
                gridGutters={0}
                gridMargins={24}
                overrides={gridOverrides}
              >
                <Cell
                  span={[6, 12, 5]}
                  overrides={{
                    Cell: {
                      style: {
                        paddingTop: '18px',
                      },
                    },
                  }}
                >
                  <LabelMedium className="ellipsis">{t('organizations:shiftSettings.shiftsForSelectedJobCodes.label')}</LabelMedium>
                </Cell>
                <Cell skip={[1, 0, 2]} />
                <AppCheckbox
                  name="jobCodeExclusions"
                  label={t('common:exclude')}
                  cellSpan={[4, 12, 4]}
                  context={EWASettingsFormContext}
                  checkboxProps={{
                    disabled: true,
                    onChange: (option) => {
                      const isChecked = option.currentTarget.checked;
                      setValues({
                        ...values,
                        jobCodeExclusions: isChecked,
                        jobCodeExclusionsValue: isChecked ? values.jobCodeExclusionsValue : '',
                      });
                    },
                  }}
                  disabled={!values.ewaEnabled}
                  formControlProps={{ overrides: controlOverrides }}
                />
                {values?.jobCodeExclusions
                && (
                  <AppTextarea
                    showStar
                    name="jobCodeExclusionsValue"
                    label={t('organizations:jobCodeExclusions.label')}
                    cellSpan={[12, 12, 8]}
                    context={EWASettingsFormContext}
                    textareaProps={{
                      id: 'jobCodeExclusionsValue',
                      autoComplete: 'off',
                      disabled: !values.ewaEnabled,
                    }}
                  />
                )}
              </Grid>
            </Cell>
          </Grid>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <Cell
              span={12}
              align={ALIGNMENT.start}
            >
              <LabelMedium>{t('organizations:paySettings.header')}</LabelMedium>
            </Cell>

            <AppInput
              name="ewaMaxHourlyRateTimecard"
              label={t('organizations:ewaMaxHourlyRate.label')}
              cellSpan={[12, 6, 3]}
              context={EWASettingsFormContext}
              inputProps={{
                min: 0,
                max: 999999.99,
                type: 'number',
                step: 'any' as any,
                endEnhancer: currencyCode,
                autoComplete: 'off',
                disabled: !values.ewaEnabled,
              }}
            />

            <AppInput
              name="ewaMaxHourlyRateSalariedEmployees"
              label={t('organizations:ewaMaxHourlyRateSalariedEmployees.label')}
              cellSpan={[12, 6, 3]}
              context={EWASettingsFormContext}
              inputProps={{
                min: 0,
                max: 999999.99,
                type: 'number',
                step: 'any' as any,
                endEnhancer: currencyCode,
                autoComplete: 'off',
                disabled: !values.ewaEnabled,
              }}
            />
          </Grid>
          <br />

          <hr />

          <Block marginTop="24px">
            <Grid
              align={ALIGNMENT.start}
              gridColumns={12}
            >
              <Cell
                span={12}
              >
                <Block
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Block
                    display="inline-flex"
                    marginRight="16px"
                  >
                    <Button
                      type="button"
                      kind={KIND.secondary}
                      onClick={handleClickCancel}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: 'OrganizationFormEWASection-cancel',
                          },
                        },
                      }}
                    >
                      {t('common:cancel')}
                    </Button>
                  </Block>

                  <Button
                    type="submit"
                    kind={KIND.primary}
                    disabled={isSubmitting || !isFormChanged || !isValid}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'OrganizationFormEWASection-save',
                        },
                      },
                    }}
                  >
                    {t('common:save')}
                  </Button>
                </Block>
              </Cell>
            </Grid>
          </Block>
        </form>
      </div>
    </EWASettingsFormContext.Provider>
  );
};

export default memo(OrganizationFormEWASection);
