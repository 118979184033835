import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  SIZE,
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic';
import { ReactComponent as NotFound } from 'assets/not-found.svg';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  paycardsListFetchFailedSelector,
  paycardsListNumPagesSelector,
  paycardsListPageNumberSelector,
  paycardsListPendingSelector,
  paycardsListSelector,
  setManageFundsSelectedEmployee,
} from 'store/slices/paycards';
import moment from 'moment';
import { Button, KIND } from 'baseui/button';
import { Skeleton } from 'baseui/skeleton';
import { HeadingXSmall, ParagraphSmall } from 'baseui/typography';
import { PaycardItem, PaycardType } from 'types/PaycardManagementTypes';
import { Block } from 'baseui/block';
import { Pagination } from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import { imageContainerStyles } from 'screens/PaycardManagement/PaycardManagementHelper';
import { useStyletron } from 'styletron-react';
import hasAccess from 'utils/hasAccess';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import { useHistory } from 'react-router-dom';
import PaycardsAccountNumber from './PaycardsAccountNumber/PaycardsAccountNumber';

interface Props {
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
}

const PaycardManagementPaycardsTable = ({
  handlePageChange,
}: Props) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['paycardManagement']);
  const paycards = useAppSelector(paycardsListSelector);
  const pending = useAppSelector(paycardsListPendingSelector);
  const numPages = useAppSelector(paycardsListNumPagesSelector);
  const page = useAppSelector(paycardsListPageNumberSelector);
  const fetchFailed = useAppSelector(paycardsListFetchFailedSelector);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const parsePaycardItemCardType = (type: string) => {
    switch (type) {
      case PaycardType.PERSONALIZED:
        return t('paycardManagement.paycards.table.cardTypes.personalized');
      case PaycardType.NON_PERSONALIZED:
        return t('paycardManagement.paycards.table.cardTypes.nonPersonalized');
      default:
        return type;
    }
  };

  const handleManageFundsClick = (item: PaycardItem) => {
    dispatch(setManageFundsSelectedEmployee(item));
    history.push('/paycard/manage-funds/history');
  };

  return (
    <Grid
      gridColumns={12}
      gridMargins={12}
    >
      <Cell
        span={12}
      >
        <TableBuilder
          data={paycards}
          size={SIZE.compact}
          isLoading={pending}
          loadingMessage={(
            <Skeleton
              height="72px"
              animation
            />
          )}
          emptyMessage={(
            <div className={css(imageContainerStyles)}>
              {fetchFailed
                ? (<HeadingXSmall margin={0}>{t('paycardManagement.errorOnLoading')}</HeadingXSmall>)
                : (
                  <>
                    <NotFound />
                    <HeadingXSmall margin={0}>{t('paycardManagement.paycards.noResults')}</HeadingXSmall>
                  </>
                )}
            </div>
          )}
        >
          <TableBuilderColumn
            header={t('paycardManagement.paycards.table.registrationDate')}
          >
            {(item: PaycardItem) => (
              <ParagraphSmall>
                {moment(item.registrationDate).format('MM/DD/YY HH:mm')}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement.paycards.table.location')}
          >
            {(item: PaycardItem) => (
              <ParagraphSmall>
                {item.locationName}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement.paycards.table.employeeName')}
          >
            {(item: PaycardItem) => (
              <ParagraphSmall>
                {`${item.firstName} ${item.lastName}`}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement.paycards.table.payrollId')}
          >
            {(item: PaycardItem) => (
              <ParagraphSmall>
                {item.payrollNumber}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement.paycards.table.accountNumber')}
          >
            {(item: PaycardItem) => (
              <PaycardsAccountNumber paycard={item} />
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement.paycards.table.proxyNumber')}
          >
            {(item: PaycardItem) => (
              <ParagraphSmall>
                {item.proxyNumber}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement.paycards.table.cardType')}
          >
            {(item: PaycardItem) => (
              <ParagraphSmall>
                { parsePaycardItemCardType(item.type) }
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement.paycards.table.actions.header')}
          >
            {(item: PaycardItem) => (
              <Button
                kind={KIND.secondary}
                size={SIZE.compact}
                onClick={() => handleManageFundsClick(item)}
                disabled={!hasAccess(AccessCheckType.oneOf, [AccessUnit.PaycardClientManager])}
                overrides={{
                  Root: {
                    style: {
                      width: '140px',
                    },
                  },
                }}
              >
                {t('paycardManagement.paycards.table.actions.manageFunds')}
              </Button>
            )}
          </TableBuilderColumn>
        </TableBuilder>
      </Cell>

      {!pending && (
        <Cell span={12}>
          <Block
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            marginBottom="16px"
          >
            <Pagination
              size={SIZE.compact}
              numPages={Math.max(numPages, 1)}
              currentPage={page}
              overrides={paginationTransparentOverrides}
              onPageChange={handlePageChange}
            />
          </Block>
        </Cell>
      )}
    </Grid>
  );
};

export default memo(PaycardManagementPaycardsTable);
