import {
  createContext,
  memo,
  useEffect,
} from 'react';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, ALIGNMENT } from 'baseui/layout-grid';
import { Layer } from 'baseui/layer';
import { tpoRepaymentValidationSchema as validationSchema } from 'validation/repaymentsSchema';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import AppModal from 'components/AppModal/AppModal';
import AppInput from 'components/Form/AppInput';
import AppDatePicker from 'components/Form/AppDatePicker';
import {
  RepaymentType, TPORepaymentsValuesType,
} from 'types/RepaymentTypes';
import { tpoRepaymentsInitialValues } from 'initialValues/RepaymentsInitialValues';
import {
  repaymentsTPOAllocationPendingSelector,
  createTPORepaymentAllocation,
} from 'store/slices/repayments';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import Loader from 'components/Loader';
import { errorSelector } from 'store/slices/error';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import moment from 'moment';

export const TPORepaymentFormContext = createContext(
  {} as FormikState<TPORepaymentsValuesType> & FormikHelpers<TPORepaymentsValuesType> & FormikHandlers,
);

const TPORepaymentAllocationModal = ({
  repayment,
  handleModalClose,
}: {
  repayment: RepaymentType,
  handleModalClose: () => void,
}) => {
  const { t } = useTranslation(['repayments', 'common']);
  const error = useAppSelector(errorSelector);
  const dispatch = useAppDispatch();
  const organization = useAppSelector(loggedOrganizationSelector);
  const { id: organizationID } = organization || {};
  const modals = useAppSelector(modalsSelector);
  const pending = useAppSelector(repaymentsTPOAllocationPendingSelector);

  const modalName = ModalNames.TPO_REPAYMENT_ALLOCATION_MODAL;
  const isModalOpen = checkIsModalOpen(modals, modalName);

  const initialValues = {
    ...tpoRepaymentsInitialValues,
  };

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleClickSave = (
    values: TPORepaymentsValuesType,
  ) => {
    const data = {
      transactionId: values.referenceID,
      dateReceived: moment(values.dateReceived[0]).format('YYYY-MM-DD'),
    };

    dispatch(createTPORepaymentAllocation({
      organizationID,
      id: repayment.id.toString(),
      data,
    })).then(() => {
      setIsModalOpen(false);
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleClickSave,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    resetForm,
    validateForm,
    isSubmitting,
    setSubmitting,
    setValues,
    isValid,
  } = formik;

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  useEffect(() => {
    resetForm();
    setSubmitting(false);
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      setValues({
        ...values,
        ...initialValues,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  return (
    <Layer index={400}>
      <TPORepaymentFormContext.Provider value={formik}>
        <form>
          <AppModal
            modal={modalName}
            title={t('repayments:tpoAllocateRepayment.title')}
            cancelBtnText={t('common:cancel')}
            onClose={handleModalClose}
            actionBtnText={t('common:save')}
            onAction={handleSubmitExtended}
            isCloseDisabled={isSubmitting}
            isActionDisabled={isSubmitting || !isValid}
          >
            <Loader active={pending} />

            <Grid
              align={ALIGNMENT.start}
              gridMargins={16}
            >
              <AppInput
                showStar
                name="referenceID"
                label={t('repayments:referenceID.label')}
                cellSpan={12}
                context={TPORepaymentFormContext}
                inputProps={{
                  id: 'referenceID',
                  autoComplete: 'off',
                }}
              />
              <AppDatePicker
                showStar
                name="dateReceived"
                label={t('repayments:dateReceived.label')}
                cellSpan={12}
                context={TPORepaymentFormContext}
              />
            </Grid>
          </AppModal>
        </form>
      </TPORepaymentFormContext.Provider>
    </Layer>
  );
};

export default memo(TPORepaymentAllocationModal);
