import { ChangeEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'baseui';
import { Checkbox } from 'baseui/checkbox';
import { RadioGroup, Radio, ALIGN } from 'baseui/radio';
import { EmploymentsListPropsType } from 'types/EmployeeTypes';
import { colors } from 'theme';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import EmployeeStatusTag from 'components/EmployeeStatusTag';

const employmentsMainGrid = {
  display: 'grid',
  gridTemplateColumns: '10% 15% 55% 20%',
  color: '#000',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  fontSize: '14px',
  padding: '16px',
  alignItems: 'center',
  ':hover': {
    backgroundColor: 'rgb(246, 246, 246)',
  },
};

const selectedRowMainGrid = {
  gridColumn: '2 / span 2',
  paddingTop: 'inherit',
  paddingBottom: 'inherit',
  overflow: 'auto',
};

const EmploymentsList = ({
  employments,
  toggle,
  onAssignmentsChange,
}: EmploymentsListPropsType) => {
  const { t } = useTranslation(['employees', 'common', 'batches']);
  const [css] = useStyletron();

  const onToggle = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name } = event.currentTarget;

    const employeeToBeAdded = employments.find((e) => e.id === name);
    if (employeeToBeAdded) {
      toggle(event);
    }
  };

  const onSelectHeader = (title: string) => (
    <div
      className={css({
        borderBottom: `1px solid ${colors.primary}`,
        marginBottom: '15px',
        fontSize: '12px',
      })}
    >
      {title}
    </div>
  );

  return (
    <Grid
      gridColumns={12}
      gridMargins={16}
      align={ALIGNMENT.center}
      overrides={{
        Grid: {
          style: {
            maxHeight: '200px',
            overflowX: 'hidden',
          },
        },
      }}
    >
      <Cell
        align={ALIGNMENT.center}
        span={12}
      >
        {employments?.length > 0
          ? employments.filter((i) => i.status !== 'TERMINATED').map((row, index) => (
            <div
              className={css({
                ...employmentsMainGrid,
                marginTop: index === 0 ? '33px' : 0,
              })}
              key={row.id}
            >
              <div className={css({ cursor: 'pointer' })}>
                <Checkbox
                  name={row.id}
                  checked={row.selected}
                  onChange={onToggle}
                  overrides={{
                    Input: {
                      props: {
                        'aria-checked': `${row.selected}`,
                      },
                    },
                  }}
                />
              </div>
              <div className={css({ cursor: 'pointer' })}>
                <EmployeeStatusTag status={row?.status} />
              </div>

              <div className={css({ marginLeft: '8px' })} id={`Worker-Linking-list-worker-${row.firstName}-${row.lastName}-${row.id}`}>
                {`${row.firstName} ${row.lastName}${row.email ? `, ${row.email}` : ''}${row.phone ? `, ${row.phone}` : ''}`}
              </div>

              {row.selected && (
                <div
                  className={css(selectedRowMainGrid)}
                >
                  {onSelectHeader(t('employees:assignments'))}
                  {row?.assignments ? (
                    <RadioGroup
                      value={String(row?.assignments?.find((i) => i.primaryLocation)?.id)}
                      onChange={(e) => onAssignmentsChange(row.id, e.currentTarget.value)}
                      name="assignment"
                      align={ALIGN.vertical}
                    >
                      {row?.assignments?.map(
                        ({
                          id,
                          name,
                          primaryLocation,
                          location,
                        }) => (

                          <Radio
                            key={id}
                            value={String(id)}
                          >
                            {name}
                            {' ('}
                            <span
                              title={location?.name}
                              className="ellipsis"
                              style={{
                                maxWidth: '150px',
                                width: 'auto',
                                display: 'inline-block',
                                height: '19px',
                                lineHeight: 1,
                                verticalAlign: 'middle',
                              }}
                            >
                              {location?.name}
                            </span>
                            {') '}
                            {primaryLocation ? t('employees:primaryAssignment') : null}
                          </Radio>
                        ),
                      )}
                    </RadioGroup>
                  )
                    : null}
                </div>
              )}
            </div>
          ))
          : (
            <h3>{t('employees:noEmploymentsFound')}</h3>
          )}
      </Cell>
    </Grid>
  );
};

export default memo(EmploymentsList);
