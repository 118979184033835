import moment from 'moment';
import {
  ConfigType,
} from 'types/OrganizationTypes';
import {
  SaveLocationRequestType,
  LocationValuesType,
  LocationResponseType,
  LocationConfigResponseType,
  LocationConfigValuesType,
  SaveLocationConfigRequestType,
  LocationPaycardConfigType,
  SaveLocationPaycardConfigType,
} from 'types/LocationTypes';
import { SubElementEWATypeEnum } from 'types/EmployeeTypes';
import {
  scalePrice,
  unScalePrice,
} from 'utils/priceScale';

export const locationMapper = ({
  id,
  name,
  timezone,
  payGroup,
  externalLocationId,
  posId,
  businessDayStartTime,
  tipsProviderId,
  status,
  externalIntegrationId,
  storeNumber,
  alphaNumericStoreNumber,
  conceptId,
  bankAccount,
}: LocationResponseType): LocationValuesType => ({
  id,
  tipsProviderId: tipsProviderId || '',
  posId: posId || '',
  locationName: name,
  locationExternalID: externalLocationId,
  externalIntegrationID: externalIntegrationId,
  organizationTimezone: timezone,
  businessDayStartTime: moment(businessDayStartTime, 'HH:mm:ss').toDate(),
  tag: [{ value: 1 }],
  payGroup: [{ value: payGroup?.id?.toString(), label: payGroup?.name?.toString() }],
  status: [{ value: status }],
  offerPercentPerEmployee: 0,
  excludeTippedShifts: false,
  jobCodeExclusions: false,
  jobCodeExclusionsValue: null as unknown as string,
  ewaEnabled: true,
  tpoEnabled: false,
  storeNumber: storeNumber || '',
  alphaNumericStoreNumber: alphaNumericStoreNumber || '',
  conceptID: conceptId || '',
  employeeSchedulingSupported: true,
  offerProRataMethod: [{ value: 'CALENDAR_DAYS' }],
  hourlyPaidEmployeesEnable: true,
  salariedEmployeesEnable: false,
  regularlyPaidEmployeesEnable: false,
  ewa3rdPartyTips: false,
  ewaServiceCharges: false,
  ewaCashTips: false,
  ewaCCTips: false,
  ewaDeclaredTips: false,
  ewaTipsPool: false,
  ewaInclude3rdPartyTips: false,
  ewaIncludeServiceCharges: false,
  ewaIncludeCashTips: false,
  ewaIncludeCCTips: false,
  ewaIncludeDeclaredTips: false,
  ewaIncludeTipsPool: false,
  ewaSubtractIncluded3rdPartyTips: false,
  ewaSubtractIncludedServiceCharges: false,
  ewaSubtractIncludedCashTips: false,
  ewaSubtractIncludedCCTips: false,
  ewaSubtractIncludedDeclaredTips: false,
  ewaSubtractIncludedTipsPool: false,
  tpo3rdPartyTips: false,
  tpoServiceCharges: false,
  tpoCashTips: false,
  tpoCCTips: false,
  tpoDeclaredTips: false,
  tpoTipsPool: false,
  tpoIncludeCCTips: true,
  tpoIncludeServiceCharges: true,
  tpoInclude3rdPartyTips: true,
  tpoIncludeDeclaredTips: false,
  tpoIncludeCashTips: false,
  tpoIncludeTipsPool: false,
  tpoSubtractIncluded3rdPartyTips: false,
  tpoSubtractIncludedServiceCharges: false,
  tpoSubtractIncludedCashTips: false,
  tpoSubtractIncludedCCTips: false,
  tpoSubtractIncludedDeclaredTips: false,
  tpoSubtractIncludedTipsPool: false,
  tpoMaxEarningsPerBusinessDay: 0,
  tpoMaxEarningsPerPeriod: 0,
  bankAccount: bankAccount || {
    bankAccountId: null,
    bankAccountAlias: null,
  },
});

export type LocationFormConfig = {
  offerPercentPerEmployee?: number,
  excludeTippedShifts?: boolean,
  jobCodeExclusions?: boolean,
  jobCodeExclusionsValue: string | null,
  ewaEnabled?: boolean,
  tpoEnabled?: boolean,
  tpoIncludeCCTips?: boolean,
  tpoIncludeServiceCharges?: boolean,
  tpoInclude3rdPartyTips?: boolean,
  tpoIncludeDeclaredTips?: boolean,
  tpoIncludeCashTips?: boolean,
  tpoIncludeTipsPool?: boolean,
  tpoSubtractIncludedCCTips: boolean,
  tpoSubtractIncludedServiceCharges: boolean,
  tpoSubtractIncluded3rdPartyTips: boolean,
  tpoSubtractIncludedDeclaredTips: boolean,
  tpoSubtractIncludedCashTips?: boolean,
  tpoSubtractIncludedTipsPool?: boolean,
  employeeSchedulingSupported: boolean,
  offerProRataMethod: { value: string }[],
  hourlyPaidEmployeesEnable: boolean,
  salariedEmployeesEnable: boolean,
  regularlyPaidEmployeesEnable: boolean,
  ewaIncludeCCTips?: boolean
  ewaInclude3rdPartyTips?: boolean
  ewaIncludeServiceCharges?: boolean
  ewaIncludeDeclaredTips?: boolean
  ewaIncludeCashTips?: boolean
  ewaIncludeTipsPool?: boolean
  ewaSubtractIncludedCCTips?: boolean
  ewaSubtractIncluded3rdPartyTips?: boolean
  ewaSubtractIncludedServiceCharges?: boolean
  ewaSubtractIncludedDeclaredTips?: boolean
  ewaSubtractIncludedCashTips?: boolean
  ewaSubtractIncludedTipsPool?: boolean
  tpo3rdPartyTips: boolean,
  tpoServiceCharges: boolean,
  tpoCashTips: boolean,
  tpoCCTips: boolean,
  tpoDeclaredTips: boolean,
  tpoTipsPool: boolean,
  ewa3rdPartyTips: boolean,
  ewaServiceCharges: boolean,
  ewaCashTips: boolean,
  ewaCCTips: boolean,
  ewaDeclaredTips: boolean,
  ewaTipsPool: boolean,
  tpoMaxEarningsPerBusinessDay?: number,
  tpoMaxEarningsPerPeriod?: number,
}

export const locationConfigMapper = (
  data: LocationConfigResponseType,
) => {
  const result = data.reduce((acc, item) => {
    switch (item.configKeyName as ConfigType) {
      case ConfigType.EWA_ENABLED:
        acc.ewaEnabled = item.configValue === 'true';
        break;
      case ConfigType.INCLUDE_3RD_PARTY_TIPS:
        acc.ewaInclude3rdPartyTips = item.configValue === 'true';
        break;

      case ConfigType.INCLUDE_CC_TIPS:
        acc.ewaIncludeCCTips = item.configValue === 'true';
        break;

      case ConfigType.INCLUDE_SERVICE_CHARGES:
        acc.ewaIncludeServiceCharges = item.configValue === 'true';
        break;

      case ConfigType.INCLUDE_DECLARED_TIPS:
        acc.ewaIncludeDeclaredTips = item.configValue === 'true';
        break;

      case ConfigType.INCLUDE_CASH_TIPS:
        acc.ewaIncludeCashTips = item.configValue === 'true';
        break;

      case ConfigType.INCLUDE_TIPS_POOL:
        acc.ewaIncludeTipsPool = item.configValue === 'true';
        break;

      case ConfigType.SUBTRACT_INCLUDED_CC_TIPS:
        acc.ewaSubtractIncludedCCTips = item.configValue === 'true';
        break;

      case ConfigType.SUBTRACT_INCLUDED_3RD_PARTY_TIPS:
        acc.ewaSubtractIncluded3rdPartyTips = item.configValue === 'true';
        break;

      case ConfigType.SUBTRACT_INCLUDED_SERVICE_CHARGES:
        acc.ewaSubtractIncludedServiceCharges = item.configValue === 'true';
        break;

      case ConfigType.SUBTRACT_INCLUDED_DECLARED_TIPS:
        acc.ewaSubtractIncludedDeclaredTips = item.configValue === 'true';
        break;

      case ConfigType.SUBTRACT_INCLUDED_CASH_TIPS:
        acc.ewaSubtractIncludedCashTips = item.configValue === 'true';
        break;

      case ConfigType.SUBTRACT_INCLUDED_TIPS_POOL:
        acc.ewaSubtractIncludedTipsPool = item.configValue === 'true';
        break;

      case ConfigType.INCLUDE_TIPPED_SHIFTS:
        acc.excludeTippedShifts = !(item.configValue === 'true');
        break;

      case ConfigType.JOB_CODE_EXCLUSIONS:
        acc.jobCodeExclusions = !!item.configValue;
        acc.jobCodeExclusionsValue = item.configValue ? item.configValue.toString().replace(/,/g, ', ') as string : null;

        break;

      case ConfigType.TPO_ENABLED:
        acc.tpoEnabled = item.configValue === 'true';
        break;

      case ConfigType.TPO_INCLUDE_CC_TIPS:
        acc.tpoIncludeCCTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_INCLUDE_SERVICE_CHARGES:
        acc.tpoIncludeServiceCharges = item.configValue === 'true';
        break;

      case ConfigType.TPO_INCLUDE_3RD_PARTY_TIPS:
        acc.tpoInclude3rdPartyTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_INCLUDE_DECLARED_TIPS:
        acc.tpoIncludeDeclaredTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_INCLUDE_CASH_TIPS:
        acc.tpoIncludeCashTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_INCLUDE_TIPS_POOL:
        acc.tpoIncludeTipsPool = item.configValue === 'true';
        break;

      case ConfigType.TPO_SUBTRACT_INCLUDED_CC_TIPS:
        acc.tpoSubtractIncludedCCTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_SUBTRACT_INCLUDED_SERVICE_CHARGES:
        acc.tpoSubtractIncludedServiceCharges = item.configValue === 'true';
        break;

      case ConfigType.TPO_SUBTRACT_INCLUDED_3RD_PARTY_TIPS:
        acc.tpoSubtractIncluded3rdPartyTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_SUBTRACT_INCLUDED_DECLARED_TIPS:
        acc.tpoSubtractIncludedDeclaredTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_SUBTRACT_INCLUDED_CASH_TIPS:
        acc.tpoSubtractIncludedCashTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_SUBTRACT_INCLUDED_TIPS_POOL:
        acc.tpoSubtractIncludedTipsPool = item.configValue === 'true';
        break;

      case ConfigType.EMPLOYEE_SCHEDULING_SUPPORTED:
        acc.employeeSchedulingSupported = item.configValue === 'true';
        break;

      case ConfigType.SUPPORTED_EMPLOYMENT_EWA_TYPES:
        acc.hourlyPaidEmployeesEnable = item.configValue ? item.configValue.includes(SubElementEWATypeEnum.HOURLY_PAID) : false;
        acc.salariedEmployeesEnable = item.configValue ? item.configValue.includes(SubElementEWATypeEnum.SALARIED_NOT_CLOCKING) : false;
        acc.regularlyPaidEmployeesEnable = item.configValue ? item.configValue.includes(SubElementEWATypeEnum.REGULARLY_PAID_HOURLY) : false;
        break;

      case ConfigType.OFFER_PRO_RATA_METHOD:
        acc.offerProRataMethod = [{ value: item.configValue || '' }];
        break;

      case ConfigType.TPO_MAX_EARNINGS_PER_BUSINESS_DAY:
        acc.tpoMaxEarningsPerBusinessDay = unScalePrice(Number(item.configValue));
        break;

      case ConfigType.TPO_MAX_EARNINGS_PER_PERIOD:
        acc.tpoMaxEarningsPerPeriod = unScalePrice(Number(item.configValue));
        break;

      default:
        break;
    }

    return acc;
  }, {} as LocationFormConfig);

  return result;
};

export const saveLocationMapper = (
  values: LocationValuesType,
): SaveLocationRequestType => ({
  id: Number(values.id),
  externalLocationId: values.locationExternalID,
  posId: values.posId?.trim() || null,
  timezone: values.organizationTimezone,
  businessDayStartTime: moment(values.businessDayStartTime).format('HH:mm:ss'),
  payGroup: (values.payGroup && { id: Number(values.payGroup[0].value), name: values.payGroup[0].label?.toString() }),
  name: values.locationName,
  status: values?.status[0].value,
  externalIntegrationId: values?.externalIntegrationID || null,
  tipsProviderId: values?.tipsProviderId || null,
  storeNumber: values?.storeNumber || undefined,
  alphaNumericStoreNumber: values?.alphaNumericStoreNumber || undefined,
  conceptId: values?.conceptID || undefined,
  bankAccount: values?.bankAccount,
});

export const saveLocationConfigMapper = (
  values: LocationValuesType & LocationConfigValuesType,
): SaveLocationConfigRequestType => {
  let supportedEmploymentEWAType = '';

  if (values.hourlyPaidEmployeesEnable) {
    if (supportedEmploymentEWAType.length > 0) {
      supportedEmploymentEWAType += ',';
    }
    supportedEmploymentEWAType += SubElementEWATypeEnum.HOURLY_PAID;
  }
  if (values.salariedEmployeesEnable) {
    if (supportedEmploymentEWAType.length > 0) {
      supportedEmploymentEWAType += ',';
    }
    supportedEmploymentEWAType += SubElementEWATypeEnum.SALARIED_NOT_CLOCKING;
  }
  if (values.regularlyPaidEmployeesEnable) {
    if (supportedEmploymentEWAType.length > 0) {
      supportedEmploymentEWAType += ',';
    }
    supportedEmploymentEWAType += SubElementEWATypeEnum.REGULARLY_PAID_HOURLY;
  }
  return (
    [
      {
        configKeyName: ConfigType.EWA_ENABLED,
        configValue: values.ewaEnabled.toString(),
      },
      {
        configKeyName: ConfigType.INCLUDE_TIPPED_SHIFTS,
        configValue: (!values.excludeTippedShifts).toString(),
      },
      {
        configKeyName: ConfigType.JOB_CODE_EXCLUSIONS,
        configValue: values.jobCodeExclusions && values.jobCodeExclusionsValue ? values.jobCodeExclusionsValue as string : null,
      },
      {
        configKeyName: ConfigType.TPO_ENABLED,
        configValue: values.tpoEnabled.toString(),
      },
      {
        configKeyName: ConfigType.INCLUDE_CC_TIPS,
        configValue: values.ewaIncludeCCTips.toString(),
      },
      {
        configKeyName: ConfigType.INCLUDE_3RD_PARTY_TIPS,
        configValue: values.ewaInclude3rdPartyTips.toString(),
      },
      {
        configKeyName: ConfigType.INCLUDE_SERVICE_CHARGES,
        configValue: values.ewaIncludeServiceCharges.toString(),
      },
      {
        configKeyName: ConfigType.INCLUDE_DECLARED_TIPS,
        configValue: values.ewaIncludeDeclaredTips.toString(),
      },
      {
        configKeyName: ConfigType.INCLUDE_CASH_TIPS,
        configValue: values.ewaIncludeCashTips.toString(),
      },
      {
        configKeyName: ConfigType.INCLUDE_TIPS_POOL,
        configValue: values.ewaIncludeTipsPool.toString(),
      },
      {
        configKeyName: ConfigType.SUBTRACT_INCLUDED_CC_TIPS,
        configValue: values.ewaSubtractIncludedCCTips.toString(),
      },
      {
        configKeyName: ConfigType.SUBTRACT_INCLUDED_3RD_PARTY_TIPS,
        configValue: values.ewaSubtractIncluded3rdPartyTips.toString(),
      },
      {
        configKeyName: ConfigType.SUBTRACT_INCLUDED_SERVICE_CHARGES,
        configValue: values.ewaSubtractIncludedServiceCharges.toString(),
      },
      {
        configKeyName: ConfigType.SUBTRACT_INCLUDED_DECLARED_TIPS,
        configValue: values.ewaSubtractIncludedDeclaredTips.toString(),
      },
      {
        configKeyName: ConfigType.SUBTRACT_INCLUDED_CASH_TIPS,
        configValue: values.ewaSubtractIncludedCashTips.toString(),
      },
      {
        configKeyName: ConfigType.SUBTRACT_INCLUDED_TIPS_POOL,
        configValue: values.ewaSubtractIncludedTipsPool.toString(),
      },
      {
        configKeyName: ConfigType.TPO_INCLUDE_CC_TIPS,
        configValue: values.tpoIncludeCCTips.toString(),
      },
      {
        configKeyName: ConfigType.TPO_INCLUDE_SERVICE_CHARGES,
        configValue: values.tpoIncludeServiceCharges.toString(),
      },
      {
        configKeyName: ConfigType.TPO_INCLUDE_3RD_PARTY_TIPS,
        configValue: values.tpoInclude3rdPartyTips.toString(),
      },
      {
        configKeyName: ConfigType.TPO_INCLUDE_DECLARED_TIPS,
        configValue: values.tpoIncludeDeclaredTips.toString(),
      },
      {
        configKeyName: ConfigType.TPO_INCLUDE_CASH_TIPS,
        configValue: values.tpoIncludeCashTips.toString(),
      },
      {
        configKeyName: ConfigType.TPO_INCLUDE_TIPS_POOL,
        configValue: values.tpoIncludeTipsPool.toString(),
      },
      {
        configKeyName: ConfigType.TPO_SUBTRACT_INCLUDED_CC_TIPS,
        configValue: values.tpoSubtractIncludedCCTips.toString(),
      },
      {
        configKeyName: ConfigType.TPO_SUBTRACT_INCLUDED_SERVICE_CHARGES,
        configValue: values.tpoSubtractIncludedServiceCharges.toString(),
      },
      {
        configKeyName: ConfigType.TPO_SUBTRACT_INCLUDED_3RD_PARTY_TIPS,
        configValue: values.tpoSubtractIncluded3rdPartyTips.toString(),
      },
      {
        configKeyName: ConfigType.TPO_SUBTRACT_INCLUDED_DECLARED_TIPS,
        configValue: values.tpoSubtractIncludedDeclaredTips.toString(),
      },
      {
        configKeyName: ConfigType.TPO_SUBTRACT_INCLUDED_CASH_TIPS,
        configValue: values.tpoSubtractIncludedCashTips.toString(),
      },
      {
        configKeyName: ConfigType.TPO_SUBTRACT_INCLUDED_TIPS_POOL,
        configValue: values.tpoSubtractIncludedTipsPool.toString(),
      },
      {
        configKeyName: ConfigType.EMPLOYEE_SCHEDULING_SUPPORTED,
        configValue: values.employeeSchedulingSupported.toString(),
      },
      {
        configKeyName: ConfigType.SUPPORTED_EMPLOYMENT_EWA_TYPES,
        configValue: supportedEmploymentEWAType.length > 0 ? supportedEmploymentEWAType : null,
      },
      {
        configKeyName: ConfigType.OFFER_PRO_RATA_METHOD,
        configValue: values.offerProRataMethod[0].value.toString(),
      },
      {
        configKeyName: ConfigType.TPO_MAX_EARNINGS_PER_BUSINESS_DAY,
        configValue: Math.round(scalePrice(values.tpoMaxEarningsPerBusinessDay)).toString(),
      },
      {
        configKeyName: ConfigType.TPO_MAX_EARNINGS_PER_PERIOD,
        configValue: Math.round(scalePrice(values.tpoMaxEarningsPerPeriod)).toString(),
      },
    ]
  );
};

export const saveLocationPaycardConfigMapper = (
  values: LocationPaycardConfigType,
): SaveLocationPaycardConfigType => (
  {
    locationName: values.locationName,
    locationExternalId: Number(values.locationExternalId[0].value),
    nonPersonalizedCardsProgram: {
      programId: Number(values.programId[0].value),
      enabled: values.enabled,
      deliveryAddress: {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        state: values.state[0].value,
        postalCode: values.postalCode,
        country: values.country,
      },
    },
  }
);

export const locationPaycardConfigMapper = (
  data: SaveLocationPaycardConfigType,
): LocationPaycardConfigType => ({
  locationName: data.locationName,
  locationExternalId: [{ value: data.locationExternalId }],
  programId: [{ value: data.nonPersonalizedCardsProgram.programId }],
  enabled: data.nonPersonalizedCardsProgram.enabled,
  addressLine1: data.nonPersonalizedCardsProgram.deliveryAddress.addressLine1,
  addressLine2: data.nonPersonalizedCardsProgram.deliveryAddress.addressLine2 || '',
  city: data.nonPersonalizedCardsProgram.deliveryAddress.city,
  state: [{ value: data.nonPersonalizedCardsProgram.deliveryAddress.state }],
  postalCode: data.nonPersonalizedCardsProgram.deliveryAddress.postalCode,
  country: data.nonPersonalizedCardsProgram.deliveryAddress.country,
});
