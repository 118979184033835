import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Block } from 'baseui/block';
import { SIZE, Spinner } from 'baseui/spinner';
import { ParagraphSmall } from 'baseui/typography';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { fetchPaycardAccountDetails } from 'store/slices/paycards';
import { PaycardAccountDetails, PaycardItem } from 'types/PaycardManagementTypes';

const iconStyle = {
  cursor: 'pointer',
  marginLeft: '12px',
};

interface Props {
  paycard: PaycardItem;
}

const PaycardsAccountNumber = ({ paycard }: Props) => {
  const hiddenString = '****';
  const [showData, setShowData] = useState(false);
  const [accountDetails, setAccountDetails] = useState<PaycardAccountDetails>();
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showData) {
      setLoading(true);
      dispatch(fetchPaycardAccountDetails({
        organizationId: loggedOrganization?.id,
        proxyNumber: paycard.proxyNumber,
      })).then(({ payload }) => {
        if (payload.code) {
          setShowData(false);
          return;
        }

        setAccountDetails(payload);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [showData]);

  return (
    <Block key={paycard.proxyNumber}>
      {
        (showData && !loading)
          ? (
            <Block display="flex">
              <ParagraphSmall>
                {accountDetails?.accountNumber || ''}
                <br />
                {accountDetails?.routingNumber || ''}
              </ParagraphSmall>
              <ParagraphSmall>
                <FontAwesomeIcon
                  onClick={() => setShowData(false)}
                  icon={faEyeSlash}
                  style={iconStyle}
                />
              </ParagraphSmall>
            </Block>
          )
          : (
            <Block display="flex">
              {
                loading
                  ? <Spinner $size={SIZE.medium} />
                  : (
                    <ParagraphSmall>
                      {hiddenString}
                      <FontAwesomeIcon
                        onClick={() => setShowData(true)}
                        icon={faEye}
                        style={iconStyle}
                      />
                    </ParagraphSmall>
                  )
              }
            </Block>
          )
      }
    </Block>
  );
};

export default memo(PaycardsAccountNumber);
