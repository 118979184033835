import { Block } from 'baseui/block';
import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  Pagination,
  SIZE,
} from 'baseui/pagination';
import {
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic';
import { ParagraphSmall } from 'baseui/typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import { useAppSelector } from 'store/hooks';
import {
  tippedEmployeesByBusinessDateListSelector,
  tippedEmployeesByBusinessDateNumPagesSelector,
  tippedEmployeesByBusinessDatePageNumberSelector,
} from 'store/slices/tpo';
import { emptyPlaceholder } from 'theme';
import { TippedEmployeePerBusinessDate } from 'types/TPOTypes';
import priceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';

export type TippedEmployeesPerBusinessDateTablePropsType = {
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
}

const TippedEmployeesPerBusinessDateTable = ({
  handlePageChange,
}: TippedEmployeesPerBusinessDateTablePropsType) => {
  const { t } = useTranslation(['tipsManagement']);
  const pageNumber = useAppSelector(tippedEmployeesByBusinessDatePageNumberSelector);
  const numPages = useAppSelector(tippedEmployeesByBusinessDateNumPagesSelector);
  const tippedEmployees = useAppSelector(tippedEmployeesByBusinessDateListSelector);

  return (
    <Grid
      gridColumns={12}
      gridMargins={0}
    >
      <Cell
        span={12}
      >
        <TableBuilder
          data={tippedEmployees}
          size={SIZE.compact}
          emptyMessage={t('tipsManagement:location.noResults')}
        >
          <TableBuilderColumn
            header={t('tipsManagement:employeeName.header')}
          >
            {(employee: TippedEmployeePerBusinessDate) => (
              <ParagraphSmall>
                {employee.firstName}
                {' '}
                {employee.lastName}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('tipsManagement:payrollNumber.columnHeader.label')}
          >
            {(employee: TippedEmployeePerBusinessDate) => (
              <ParagraphSmall>
                {employee.payrollId || emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('tipsManagement:thirdPartyTips.columnHeader.label')}
          >
            {(employee: TippedEmployeePerBusinessDate) => (
              <ParagraphSmall>
                {employee.thirdPartyTips
                  ? priceFormatter().format(unScalePrice(employee.thirdPartyTips.value, employee.thirdPartyTips.scale))
                  : emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('tipsManagement:declaredTips.columnHeader.label')}
          >
            {(employee: TippedEmployeePerBusinessDate) => (
              <ParagraphSmall>
                {employee.declaredTips
                  ? priceFormatter().format(unScalePrice(employee.declaredTips.value, employee.declaredTips.scale))
                  : emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('tipsManagement:tipPoolTips.columnHeader.label')}
          >
            {(employee: TippedEmployeePerBusinessDate) => (
              <ParagraphSmall>
                {employee.tipPoolTips
                  ? priceFormatter().format(unScalePrice(employee.tipPoolTips.value, employee.tipPoolTips.scale))
                  : emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('tipsManagement:serviceChargeTips.columnHeader.label')}
          >
            {(employee: TippedEmployeePerBusinessDate) => (
              <ParagraphSmall>
                {employee.serviceChargeTips
                  ? priceFormatter().format(unScalePrice(employee.serviceChargeTips.value, employee.serviceChargeTips.scale))
                  : emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('tipsManagement:cashTips.columnHeader.label')}
          >
            {(employee: TippedEmployeePerBusinessDate) => (
              <ParagraphSmall>
                {employee.cashTips
                  ? priceFormatter().format(unScalePrice(employee.cashTips.value, employee.cashTips.scale))
                  : emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('tipsManagement:creditCardTips.columnHeader.label')}
          >
            {(employee: TippedEmployeePerBusinessDate) => (
              <ParagraphSmall>
                {employee.creditCardTips
                  ? priceFormatter().format(unScalePrice(employee.creditCardTips.value, employee.creditCardTips.scale))
                  : emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
        </TableBuilder>

      </Cell>
      {tippedEmployees && tippedEmployees?.length > 0 && (
      <Cell span={12}>
        <Block
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
          marginBottom="16px"
          marginTop="16px"
        >
          <Pagination
            size={SIZE.compact}
            numPages={numPages}
            currentPage={pageNumber}
            overrides={paginationTransparentOverrides}
            onPageChange={handlePageChange}
          />
        </Block>
      </Cell>
      )}
    </Grid>
  );
};

export default memo(TippedEmployeesPerBusinessDateTable);
