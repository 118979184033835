import {
  memo,
  useEffect,
  useState,
} from 'react';
import { useStyletron } from 'baseui';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { useTranslation } from 'react-i18next';
import
{
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import { BankFileTypes } from 'types/BankFilesTypes';
import SectionsTabulation from 'components/SectionsTabulation';
import { useAppSelector } from 'store/hooks';
import hasAccess from 'utils/hasAccess';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { userAccessUnitsSelector } from 'store/slices/user';
import { Grid } from 'baseui/layout-grid';
import Loader from 'components/Loader';
import DailyBankFilesSection from './DailyBankFilesSection/DailyBankFilesSection';
import TreasurySummarySection from './TreasurySummarySection/TreasurySummarySection';
import ACHDebitSection from './ACHDebitSection/ACHDebitSection';
import TreasuryWorkerAndMasterAccount from './TreasuryWorkerAndMasterAccount';
import { containerStylesTreasury } from './TreasuryHelper';

const Treasury = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['treasury']);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const userRoles = useAppSelector(userAccessUnitsSelector);

  const hasEWAReportRole = userRoles.includes(AccessUnit.EWAReport);

  const onlyEWAReportPermission = userRoles.includes(AccessUnit.EWAReport)
        && (!userRoles.some((unit) => [AccessUnit.TreasuryManager, AccessUnit.FBOManager, AccessUnit.TreasuryReader, AccessUnit.FBOReader].includes(unit)));
  const EWAReportAndTreasuryFBOPermission = userRoles.includes(AccessUnit.EWAReport)
        && (userRoles.some((unit) => [AccessUnit.TreasuryManager, AccessUnit.FBOManager, AccessUnit.TreasuryReader, AccessUnit.FBOReader].includes(unit)));
  const onlyTreasuryFBOPermission = !userRoles.includes(AccessUnit.EWAReport)
        && (userRoles.some((unit) => [AccessUnit.TreasuryManager, AccessUnit.FBOManager, AccessUnit.TreasuryReader, AccessUnit.FBOReader].includes(unit)));

  const subMenuHeadingsOnlyEWAReport = [
    t('treasury:treasurySummary'),
  ];

  const subMenuHeadingsNoEWAReportAndBankDetailsVisible = [
    t('treasury:foa'),
    t('treasury:dailyCardFiles'),
    t('treasury:dailyTransactionFiles'),
    t('treasury:dailyMFAFiles'),
    t('treasury:otherFiles'),
    t('treasury:achDebit.header'),
  ];

  const subMenuHeadingsAndBankDetailsVisible = [
    t('treasury:treasurySummary'),
    t('treasury:foa'),
    t('treasury:dailyCardFiles'),
    t('treasury:dailyTransactionFiles'),
    t('treasury:dailyMFAFiles'),
    t('treasury:otherFiles'),
    t('treasury:achDebit.header'),
  ];

  const buttonDisabledRule = !hasAccess(AccessCheckType.oneOf, [AccessUnit.TreasuryManager]);

  const { sectionIndex } = useParams<{sectionIndex: string}>();
  const selectedSection = Number(sectionIndex);

  const handleShownSection = (i: number) => {
    history.push(`/treasury/section/${i}`);
  };

  useEffect(() => {
    if (onlyEWAReportPermission && sectionIndex !== '0') {
      history.push('/login');
    } else if (EWAReportAndTreasuryFBOPermission && !userRoles.includes(AccessUnit.TreasuryManager)
        && (sectionIndex === '2' || sectionIndex === '3' || sectionIndex === '4' || sectionIndex === '5')) {
      history.push('/login');
    } else if (((onlyTreasuryFBOPermission && !userRoles.includes(AccessUnit.TreasuryManager))
    || (EWAReportAndTreasuryFBOPermission && !userRoles.includes(AccessUnit.TreasuryManager)))
    && (sectionIndex === '1' || sectionIndex === '2' || sectionIndex === '3' || sectionIndex === '4')) {
      history.push('/login');
    }
  }, [sectionIndex]);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={css(containerStylesTreasury)}>
      <Loader active={isLoading} />
      <CommonHeader title={(t('treasury:heading'))} />
      { onlyEWAReportPermission && (
      <>
        <Grid>
          <SectionsTabulation
            subMenuHeadings={subMenuHeadingsOnlyEWAReport}
            buttonDisabledRole={false}
            sectionName="treasury"
            activeSection={selectedSection}
            setShownSection={handleShownSection}
          />
        </Grid>
        {selectedSection === 0 && hasEWAReportRole && <TreasurySummarySection />}
      </>
      )}
      { EWAReportAndTreasuryFBOPermission && (
      <>
        <Grid>
          <SectionsTabulation
            subMenuHeadings={subMenuHeadingsAndBankDetailsVisible}
            buttonDisabledRole={buttonDisabledRule}
            sectionName="treasury"
            activeSection={selectedSection}
            setShownSection={handleShownSection}
          />
        </Grid>
        {selectedSection === 0 && <TreasurySummarySection />}
        {selectedSection === 1 && (
        <TreasuryWorkerAndMasterAccount />
        )}
        {selectedSection === 2 && <DailyBankFilesSection />}
        {selectedSection === 3 && <DailyBankFilesSection fileType={BankFileTypes.TRANSACTION} />}
        {selectedSection === 4 && <DailyBankFilesSection fileType={BankFileTypes.MFA} />}
        {selectedSection === 5 && <DailyBankFilesSection fileType={BankFileTypes.OTHER} />}
        {selectedSection === 6 && <ACHDebitSection />}
      </>
      )}
      { onlyTreasuryFBOPermission && (
      <>
        <Grid>
          <SectionsTabulation
            subMenuHeadings={subMenuHeadingsNoEWAReportAndBankDetailsVisible}
            buttonDisabledRole={buttonDisabledRule}
            sectionName="treasury"
            activeSection={selectedSection}
            setShownSection={handleShownSection}
          />
        </Grid>
        {selectedSection === 0 && (
        <TreasuryWorkerAndMasterAccount />
        )}
        {selectedSection === 1 && <DailyBankFilesSection />}
        {selectedSection === 2 && <DailyBankFilesSection fileType={BankFileTypes.TRANSACTION} />}
        {selectedSection === 3 && <DailyBankFilesSection fileType={BankFileTypes.MFA} />}
        {selectedSection === 4 && <DailyBankFilesSection fileType={BankFileTypes.OTHER} />}
        {selectedSection === 5 && <ACHDebitSection />}
      </>
      )}
    </div>
  );
};

export default memo(Treasury);
