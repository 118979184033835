import { ReactNode } from 'react';
import { StatefulTooltip, TRIGGER_TYPE } from 'baseui/tooltip';
import { useStyletron } from 'styletron-react';
import { TETHER_PLACEMENT } from 'baseui/layer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { borderRadius } from 'theme';

const titleStyles = {
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: '12px',
  lineHeight: '20px',
  marginBottom: '4px',
};

const contentStyles = {
  fontStyle: 'normal',
  fontHeight: 400,
  fontSize: '12px',
  lineHeight: '20px',
};

export type AppTooltipPropsType = {
  title?: ReactNode
  content?: ReactNode
  placement?: keyof typeof TETHER_PLACEMENT
  icon?: IconDefinition
  iconColor?: string
};

const AppTooltip = ({
  title,
  content,
  placement,
  icon = faInfoCircle,
  iconColor = '#757575',
}: AppTooltipPropsType) => {
  const [css] = useStyletron();

  return (
    <StatefulTooltip
      overrides={{
        Body: {
          style: {
            marginLeft: '24px',
            marginRight: '24px',
          },
        },
        Inner: {
          style: {
            maxWidth: '320px',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '16px',
            paddingBottom: '16px',
            backgroundColor: '#fff',
            border: '1px solid #111111',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            color: '#000',
            borderTopLeftRadius: borderRadius,
            borderTopRightRadius: borderRadius,
            borderBottomLeftRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
          },
        },
      }}
      accessibilityType="tooltip"
      triggerType={TRIGGER_TYPE.click}
      content={(
        <>
          {title && <div className={css(titleStyles)}>{title}</div>}
          {content && <div className={css(contentStyles)}>{content}</div>}
        </>
      )}
      placement={placement ? TETHER_PLACEMENT[placement] : TETHER_PLACEMENT.bottomRight}
      popoverMargin={4}
    >
      <span
        className="tooltip-icon"
        data-testid="tooltip-icon"
      >
        <FontAwesomeIcon
          className={css({
            cursor: 'pointer',
            marginLeft: '12px',
            marginRight: '12px',
            color: iconColor,
          })}
          icon={icon}
        />
      </span>
    </StatefulTooltip>
  );
};

export default AppTooltip;
