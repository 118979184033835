import { store } from 'store';

const state = store.getState();
const { application } = state || {};
const { priceScale } = application || {};

export const scalePrice = (
  amount: number,
  scale: number = priceScale,
): number => {
  const scaled = amount * (10 ** scale);
  return Math.round(scaled * 1e10) / 1e10;
};

export const unScalePrice = (
  amount: number,
  scale: number = priceScale,
  decimalPlaces: number = 2,
): number => {
  const unScaled = amount / (10 ** scale);
  return parseFloat(unScaled.toFixed(decimalPlaces));
};
