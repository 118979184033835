import { ChangeEvent, Key, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import { Panel } from 'baseui/accordion';
import { Checkbox } from 'baseui/checkbox';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { Card } from 'baseui/card';
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import PriceFormatter from 'utils/priceFormatter';
import {
  checkboxOverrides,
  listContainerStyles,
  outerContainerStyles,
} from 'screens/Batches/BatchesHelpers';
import {
  createDrawAllocations,
  employmentDrawsSelector,
  expandedBatchEmploymentsSelector,
  pendingEmploymentDrawsSelector,
  selectedBatchEmploymentsSelector,
  selectedEmploymentDrawsSelector,
  setSelectedEmployees,
} from 'store/slices/paymentAllocation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BatchIDType } from 'types/BatchTypes';
import {
  BatchEmploymentType,
  RepaymentIDType,
} from 'types/RepaymentTypes';
import { unScalePrice } from 'utils/priceScale';
import Loader from 'components/Loader';
import PaymentAllocationDrawListItem from './PaymentAllocationDrawListItem';

export type PaymentAllocationEmploymentListItemPropsType = {
  repaymentID: RepaymentIDType
  batchID: BatchIDType
  employment: BatchEmploymentType
  disableSave?: boolean
  footer?: ReactNode
}

const PaymentAllocationEmploymentListItem = ({
  repaymentID,
  batchID,
  employment,
  footer,
  disableSave,
  ...rest
}: PaymentAllocationEmploymentListItemPropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['batches']);
  const dispatch = useAppDispatch();
  const selectedBatchEmployments = useAppSelector(selectedBatchEmploymentsSelector);
  const expandedBatchEmployments = useAppSelector(expandedBatchEmploymentsSelector);
  const draws = useAppSelector(employmentDrawsSelector);
  const pending = useAppSelector(pendingEmploymentDrawsSelector);
  const {
    id,
    firstName,
    lastName,
    payrollId,
    totalDrawAmount,
  } = employment;
  const employmentID = id?.toString();
  const selectedEmployments = selectedBatchEmployments?.filter((item) => item.batchID === batchID);
  const selectedEmploymentDraws = useAppSelector(selectedEmploymentDrawsSelector);
  const expandedEmployments = expandedBatchEmployments?.filter((item) => item.batchID === batchID);
  const checked = !!selectedEmployments?.find((item) => item?.employmentID === employmentID);
  const checkboxDisabled = !!(expandedBatchEmployments?.length > 0);
  const hasDraws = draws?.length > 0;
  const hasSelectedDraws = selectedEmploymentDraws?.length > 0;

  const isEmploymentExpanded = !!(expandedEmployments?.find((item) => item?.batchID?.toString() === batchID?.toString()
    && item?.employmentID?.toString() === employmentID?.toString()));
  const allDrawsChecked = !!(isEmploymentExpanded && selectedEmploymentDraws?.length > 0 && selectedEmploymentDraws?.length === draws?.length);
  const isIndeterminate = !allDrawsChecked && isEmploymentExpanded && selectedEmploymentDraws?.length > 0;
  const isSaveDisabled = disableSave || !hasSelectedDraws;

  const handleEmployeeAllocationCheckboxChanged = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation();
    const { checked: checkedValue } = e.currentTarget;

    dispatch(
      setSelectedEmployees(
        checkedValue
          ? [...selectedBatchEmployments, { batchID, employmentID, totalDrawAmount }]
          : [...selectedBatchEmployments.filter((item) => (item.batchID !== batchID)
            || (item.batchID === batchID && item.employmentID !== employmentID))],
      ),
    );
  };

  const handleClickSave = () => {
    dispatch(createDrawAllocations({
      repaymentID,
      batchID,
      employmentID: id?.toString(),
      data: {
        allocations: selectedEmploymentDraws?.map((item) => ({ id: Number(item.drawID), amount: item.amount })),
      },
    }));
  };

  return (
    <>
      <div className={css(outerContainerStyles)}>
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
          gridMargins={4}
        >
          <Cell
            align={ALIGNMENT.center}
            span={4}
          >
            {`${firstName} ${lastName}`}
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            {payrollId}
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={3}
          >
            <span
              data-testid="PaymentAllocationEmploymentListItem"
            >
              {PriceFormatter().format(unScalePrice(totalDrawAmount?.value, totalDrawAmount?.scale))}
            </span>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <Checkbox
              disabled={checkboxDisabled || totalDrawAmount?.value <= 0}
              checked={checked || allDrawsChecked || isIndeterminate}
              isIndeterminate={isIndeterminate}
              name={employmentID}
              overrides={{
                Root: {
                  ...checkboxOverrides.Root,
                  props: {
                    id: `payment-allocation-employee-list-item-checkbox-${employmentID}`,
                  },
                },
                Input: {
                  props: {
                    'aria-checked': `${checked || allDrawsChecked || isIndeterminate}`,
                  },
                },
              }}
              onChange={handleEmployeeAllocationCheckboxChanged}
            />
            {PriceFormatter().format(unScalePrice(totalDrawAmount?.value, totalDrawAmount?.scale))}
          </Cell>
        </Grid>
      </div>

      <Block
        marginLeft="-20px"
        marginRight="-20px"
      >
        <Panel
          key={employmentID as Key}
          {...rest}
        >
          {!pending && hasDraws && (
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
              gridMargins={0}
            >
              <Cell
                span={4}
              >
                <strong>{t('batches:paymentAllocations.drawDate')}</strong>
              </Cell>

              <Cell
                span={2}
              >
                <strong>{t('batches:paymentAllocations.drawAmount')}</strong>
              </Cell>

              <Cell
                span={3}
              >
                <strong>{t('batches:paymentAllocations.paidAmount')}</strong>
              </Cell>
            </Grid>
          )}

          {pending && (
            <div className={css(listContainerStyles)}>
              <Loader active={pending} />
            </div>
          )}

          {!pending && hasDraws && draws?.map((draw) => (
            <PaymentAllocationDrawListItem
              key={draw?.createdDate}
              draw={draw}
            />
          ))}

          {!pending && !hasDraws && (
            <Grid
              gridColumns={12}
              gridMargins={16}
            >
              <Cell span={12}>
                <Card>
                  {t('repayments:notFoundEmploymentDraws')}
                </Card>
              </Cell>
            </Grid>
          )}

          <Block
            marginTop="40px"
          >
            {!pending && (
              <Grid
                align={ALIGNMENT.center}
                gridColumns={12}
                gridMargins={16}
              >
                <Cell
                  span={12}
                >
                  {footer}
                </Cell>

                <Cell
                  span={12}
                  align={ALIGNMENT.center}
                >
                  <Block
                    display="flex"
                    justifyContent="flex-end"
                    marginRight="-12px"
                    marginTop="24px"
                  >
                    <Button
                      disabled={isSaveDisabled}
                      type="button"
                      kind={KIND.primary}
                      onClick={handleClickSave}
                      overrides={{
                        Root: {
                          props: {
                            id: 'payment-allocation-employment-list-save-button',
                          },
                        },
                      }}
                    >
                      {t('common:save')}
                    </Button>
                  </Block>
                </Cell>
              </Grid>
            )}
          </Block>
        </Panel>
      </Block>
    </>
  );
};

export default PaymentAllocationEmploymentListItem;
