import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStyletron } from 'baseui';
import { Accordion, Panel } from 'baseui/accordion';
import { Card } from 'baseui/card';
import { LabelSmall } from 'baseui/typography';
import { Key, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  fetchUniversalCalendarOpenShifts,
  universalCalendarOpenShiftsListSelector,
  universalCalendarOpenShiftsPendingSelector,
  universalCalendarSearchLocationSelector,
  universalCalendarSearchOrganizationSelector,
  universalCalendarSelectedWorkerIDSelector,
} from 'store/slices/universalCalendar';
import { Block } from 'baseui/block';
import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import WorkerShiftsShiftListItem from './WorkerShiftsShiftListItem';

const WorkerShiftsAvailableShiftsItem = ({
  availableShiftsCount,
  date,
}: {
  availableShiftsCount: number,
    date: string
}) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'workers', 'errors']);
  const dispatch = useAppDispatch();
  const openShiftsList = useAppSelector(universalCalendarOpenShiftsListSelector);
  const selectedWorkerID = useAppSelector(universalCalendarSelectedWorkerIDSelector);
  const pending = useAppSelector(universalCalendarOpenShiftsPendingSelector);
  const selectedLocation = useAppSelector(universalCalendarSearchLocationSelector);
  const selectedOrganization = useAppSelector(universalCalendarSearchOrganizationSelector);

  const handleAccordionChange = ({ expanded }: { expanded: Key[] }) => {
    if (expanded[0]) {
      dispatch(fetchUniversalCalendarOpenShifts({
        workerID: selectedWorkerID,
        fromDate: date,
        organizationID: selectedOrganization?.[0]?.id || undefined,
        locationID: selectedLocation?.[0]?.id || undefined,
      }));
    }
  };

  const filterOpenShiftsList = openShiftsList.filter((shift: any) => shift.date === date);

  return (
    <Card>
      <Accordion
        onChange={handleAccordionChange}
        overrides={{
          Root: {
            props: {
              id: `AccordionSection-availableShifts-${date}`,
            },
          },
        }}
      >
        <Panel
          key={`${date}-panel` as Key}
          title={(
            <div>
              <FontAwesomeIcon
                icon={faCircleInfo}
                className={css({
                  marginRight: '8px',
                  color: '#174291',
                  paddingTop: '0px',
                })}
              />
              <LabelSmall display="inline-flex">
                {`${availableShiftsCount > 9 ? '9+' : availableShiftsCount} ${t('workers:shifts.shiftsAvailable.label')}`}
              </LabelSmall>
            </div>
          )}
          overrides={{
            PanelContainer: {
              style: {
                borderBottomWidth: '0px',
                padding: 0,
              },
            },
            Header: {
              style: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
                paddingLeft: 0,
              },
            },
            Content: {
              style: {
                paddingTop: '8px',
                paddingBottom: 0,
                paddingRight: 0,
                paddingLeft: 0,
              },
            },
          }}
        >
          {filterOpenShiftsList && filterOpenShiftsList.length > 0 && (
            filterOpenShiftsList.map((shift: any) => (
              <WorkerShiftsShiftListItem shiftsList={shift} isOpenShifts key={`${shift.id}-availableShifts-shift`} />
            ))
          )}
          {filterOpenShiftsList && filterOpenShiftsList.length === 0 && !pending && (
            <Block>
              <Grid gridMargins={0}>
                <Cell span={12}>
                  <Card>
                    <p>{t('errors:workerUniversalCalendar.openShifts.unableToLoad.error')}</p>
                  </Card>
                </Cell>
              </Grid>
            </Block>
          )}
        </Panel>
      </Accordion>
    </Card>
  );
};
export default memo(WorkerShiftsAvailableShiftsItem);
