import {
  createContext,
  memo,
  useEffect,
  useState,
} from 'react';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, ALIGNMENT, Cell } from 'baseui/layout-grid';
import { Layer } from 'baseui/layer';
import { Block } from 'baseui/block';
import { ParagraphMedium } from 'baseui/typography';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import { fetchManageFundsHistory, paycardsManageFundsSelectedEmployeeSelector, unloadFunds } from 'store/slices/paycards';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { NotificationType, setNotification } from 'store/slices/notification';
import AppModal from 'components/AppModal/AppModal';
import Loader from 'components/Loader';
import AppTextarea from 'components/Form/AppTextarea';
import { FundsHistoryItem, UnloadFundsValuesType } from 'types/PaycardManagementTypes';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import priceFormatter from 'utils/priceFormatter';
import { unloadFundsMapper } from 'dataMappers/paycardManagementMapper';
import {
  PaycardManagementUnloadFundsModalInitialValues as unloadFundsInitialValues,
} from 'initialValues/OrganizationInitialValues';

export const UnloadFundsModalContext = createContext(
  {} as FormikState<UnloadFundsValuesType> & FormikHelpers<UnloadFundsValuesType> & FormikHandlers,
);

interface Props {
  selectedRecord: FundsHistoryItem | undefined;
}

const UnloadFundsModal = ({ selectedRecord }: Props) => {
  const { t } = useTranslation(['paycardManagement', 'errors', 'common']);
  const dispatch = useAppDispatch();
  const modals = useAppSelector(modalsSelector);
  const organization = useAppSelector(loggedOrganizationSelector);
  const { id: organizationId, name: organizationName } = organization || {};
  const isModalOpen = checkIsModalOpen(modals, ModalNames.UNLOAD_FUNDS_MODAL);
  const selectedEmployee = useAppSelector(paycardsManageFundsSelectedEmployeeSelector);
  const [error, setError] = useState<boolean>(false);

  const initialValues = {
    ...unloadFundsInitialValues,
  };

  const onSubmit = (
    values: UnloadFundsValuesType,
  ) => {
    if (selectedRecord && selectedEmployee) {
      dispatch(unloadFunds({
        organizationId,
        proxyNumber: selectedEmployee.proxyNumber,
        data: unloadFundsMapper(
          {
            transactionId: selectedRecord.transactionId || '',
            description: values.paymentDescription,
            internalNotes: values.internalNotes,
          },
          t,
          organizationName,
        ),
      }))
        .unwrap()
        .then(() => {
          dispatch(setModal({
            name: ModalNames.UNLOAD_FUNDS_MODAL,
            isOpen: false,
          }));
          dispatch(fetchManageFundsHistory({
            organizationId,
            proxyNumber: selectedEmployee?.proxyNumber,
          }));
        })
        .catch(() => {
          dispatch(setNotification({
            type: NotificationType.ERROR,
            isOpen: true,
            titleKey: t('errors:unableToUnloadFunds'),
            autoHideDuration: 3000,
          }));
          setError(true);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const {
    values,
    handleSubmit,
    resetForm,
    validateForm,
    isSubmitting,
    setSubmitting,
    setValues,
  } = formik;

  const handleModalClose = () => {
    resetForm();
  };

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  useEffect(() => {
    if (isModalOpen) {
      setSubmitting(false);
      resetForm();
      setValues({
        ...values,
        ...initialValues,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (error) {
      setSubmitting(false);
      setError(false);
    }
  }, [error]);

  return (
    <Layer index={400}>
      {selectedRecord
        && (
        <UnloadFundsModalContext.Provider value={formik}>
          <form>
            <AppModal
              modal={ModalNames.UNLOAD_FUNDS_MODAL}
              title={t('paycardManagement:paycardManagement.paycards.manageFunds.unloadFundsModal.title')}
              cancelBtnText={t('common:cancel')}
              onClose={handleModalClose}
              actionBtnText={t('paycardManagement:paycardManagement.paycards.manageFunds.unloadFundsModal.unloadFunds')}
              onAction={handleSubmitExtended}
              isCloseDisabled={isSubmitting}
              isActionDisabled={isSubmitting}
              modalWidth={['360px', '360px', '500px', '500px']}
              minWidth="360px"
              maxWidth="100%"
            >
              <Loader active={isSubmitting} />
              <Grid
                align={ALIGNMENT.start}
                gridColumns={12}
                gridMargins={12}
                gridGaps={6}
              >
                <Cell span={12}>
                  <Block>
                    <ParagraphMedium whiteSpace="normal">
                      {t(
                        'paycardManagement:paycardManagement.paycards.manageFunds.unloadFundsModal.subtitle',
                        { amount: selectedRecord && priceFormatter().format(selectedRecord?.amount) },
                      )}
                    </ParagraphMedium>
                  </Block>
                </Cell>
                <AppTextarea
                  name="paymentDescription"
                  label={t('paycardManagement:paycardManagement.paycards.manageFunds.paymentDescription.label')}
                  cellSpan={[12]}
                  context={UnloadFundsModalContext}
                  textareaProps={{
                    id: 'paymentDescription-unloadFunds',
                    autoComplete: 'off',
                  }}
                  placeholder={
                    t(
                      'paycardManagement:paycardManagement.paycards.manageFunds.paymentDescription.placeholderDefault',
                      { orgName: `${organizationName || ''}` },
                    )
                  }
                />
                <AppTextarea
                  name="internalNotes"
                  label={t('paycardManagement:paycardManagement.paycards.manageFunds.internalNotes.label')}
                  cellSpan={[12]}
                  context={UnloadFundsModalContext}
                  textareaProps={{
                    id: 'internalNotes-unloadFunds',
                    autoComplete: 'off',
                  }}
                  placeholder={t('paycardManagement:paycardManagement.paycards.manageFunds.internalNotes.placeholder')}
                />
              </Grid>
            </AppModal>
          </form>
        </UnloadFundsModalContext.Provider>
        )}
    </Layer>
  );
};

export default memo(UnloadFundsModal);
