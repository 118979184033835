import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { Card } from 'baseui/card';
import { Search } from 'baseui/icon';
import { Input } from 'baseui/input';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import Loader from 'components/Loader';
import {
  ChangeEvent,
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  administratorsListSelector,
  administratorsNextTokenSelector,
  administratorsPendingListSelector,
  administratorsSearchFilterSelector,
  fetchAllAdministrators,
  resetAdministratorsList,
  searchAdministrators,
  setSearchFilter,
} from 'store/slices/administrators';
import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import {
  AdministratorType,
  AllAdministratorsFilterType,
} from 'types/AdministratorsTypes';
import {
  ModalNames,
  modalsSelector,
} from 'store/slices/modals';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import {
  prevPageSelector,
  setPrevPage,
} from 'store/slices/application';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import AdministratorListItem from './AdministratorListItem';
import EditAdministratorModal from './EditAdministratorModal';

const blockOverrides = {
  Block: {
    style: {
      background: '#F3F3F3',
    },
  },
};

const searchButton = {
  Root: {
    props: {
      id: 'Administrators-search-admin',
    },
    style: {
      width: '100%',
      minWidth: '168px',
    },
  },
};

const newAdminBtnOverrides = {
  Root: {
    props: {
      id: 'Administrators-create-admin',
    },
    style: {
      width: '200px',
    },
  },
};

const containerStyles = {
  width: '100%',
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 100,
} as StyleObject;

const Administrators = () => {
  const list = useAppSelector(administratorsListSelector);
  const pendingList = useAppSelector(administratorsPendingListSelector);
  const nextToken = useAppSelector(administratorsNextTokenSelector);
  const namePattern = useAppSelector(administratorsSearchFilterSelector);
  const prevPage = useAppSelector(prevPageSelector);
  const { t } = useTranslation(['common', 'administrators']);
  const [css] = useStyletron();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const modals = useAppSelector(modalsSelector);
  const [hasPerformedSearch, setHasPerformedSearch] = useState(false);

  const createAdmin = () => {
    history.push('/administrators/create');
  };

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    if (e.target.value === '' && namePattern && hasPerformedSearch) {
      dispatch(resetAdministratorsList());
      dispatch(fetchAllAdministrators({
        active: true,
      }));
      setHasPerformedSearch(false);
    }
    dispatch(setSearchFilter(e.target.value));
  };

  const handleSearch = () => {
    setHasPerformedSearch(true);
    let filter: AllAdministratorsFilterType = {
      active: true,
    };
    if (namePattern.trim().indexOf(' ') !== -1) {
      const [firstName, lastName] = namePattern.trim().split(' ');
      filter = { ...filter, firstName: firstName.trim(), lastName: lastName.trim() };
    } else {
      filter = { ...filter, namePattern: namePattern.trim() };
    }
    dispatch(searchAdministrators(filter));
  };

  const handleLoadMoreClick = () => {
    let filter: AllAdministratorsFilterType = {
      active: true,
      nextToken,
    };

    if (namePattern) {
      if (namePattern.indexOf(' ') !== -1) {
        const [firstName, lastName] = namePattern.trim().split(' ');
        filter = { ...filter, firstName, lastName };
      } else {
        filter = { ...filter, namePattern: namePattern.trim() };
      }
    }
    dispatch(fetchAllAdministrators(filter));
  };

  useEffect(() => {
    dispatch(resetAdministratorsList());
    if (!prevPage.startsWith('/administrators')) {
      dispatch(fetchAllAdministrators({
        active: true,
      }));
      dispatch(setSearchFilter(''));
    } else {
      handleSearch();
    }
    dispatch(setPrevPage(''));
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={css(containerStyles)}>

      <Loader active={pendingList && list?.length === 0} />

      <CommonHeader title={t('administrators:administrators')}>
        <Block
          alignItems="center"
          alignContent="end"
          justifyItems="end"
          display="inline-flex"
          justifyContent="flex-end"
          width="50%"
          minWidth="160px"
          height="72px"
        >
          <Button
            kind={KIND.primary}
            overrides={newAdminBtnOverrides}
            onClick={createAdmin}
          >
            {t('administrators:createNewAdmin')}
          </Button>
        </Block>
      </CommonHeader>

      <Block
        marginTop="24px"
      >
        <Grid
          gridGaps={[0, 10]}
          align={ALIGNMENT.center}
        >

          <Cell
            align={ALIGNMENT.start}
            span={[12, 6]}
          >
            <Block
              marginTop="15px"
              marginBottom="15px"
              display="flex"
              width="auto"
            >
              <Input
                clearable
                clearOnEscape
                startEnhancer={<Search />}
                type="text"
                name="namePattern"
                onChange={handleChangeSearch}
                value={namePattern}
                placeholder={t('administrators:searchByName')}
                overrides={{
                  Input: {
                    props: {
                      id: 'Administrators-search-input',
                      autoComplete: 'off',
                    },
                  },
                }}
              />
            </Block>
          </Cell>
          <Cell
            align={ALIGNMENT.end}
            span={[12, 6]}
          >
            <Block
              marginBottom="16px"
              display="flex"
              alignItems="flex-end"
              width="120px"
            >
              <Button
                kind={KIND.primary}
                overrides={searchButton}
                disabled={!namePattern || pendingList}
                onClick={handleSearch}
              >
                {t('administrators:searchButton')}
              </Button>
            </Block>
          </Cell>

        </Grid>
      </Block>

      <Block overrides={blockOverrides}>
        <Grid
          align={ALIGNMENT.center}
        >
          <Cell
            span={12}
          >
            {list?.length ? list.map((admin: AdministratorType) => (
              <AdministratorListItem key={admin.id} admin={admin} />
            )) : null}
            {nextToken
              ? (
                <Block margin="10px 0 20px" display="flex">
                  <Button
                    overrides={{
                      Root: {
                        style: {
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        },
                        props: {
                          id: 'administrators-more-workers-button',
                        },
                      },
                      LoadingSpinner: {
                        style: {
                          borderRightColor: 'white',
                          borderTopColor: 'white',
                          borderLeftColor: 'white',
                        },
                      },
                    }}
                    isLoading={pendingList}
                    disabled={!nextToken}
                    onClick={handleLoadMoreClick}
                  >
                    {t('administrators:loadMore')}
                  </Button>
                </Block>
              ) : null}
            {list?.length === 0 && (
              <Block
                marginTop="24px"
                marginBottom="24px"
              >
                <Card>
                  <p>{t('administrators:notFound')}</p>
                </Card>
              </Block>
            )}
          </Cell>
        </Grid>
      </Block>
      {checkIsModalOpen(modals, ModalNames.EDIT_ADMIN_MODAL) && <EditAdministratorModal />}
    </div>
  );
};

export default memo(Administrators);
