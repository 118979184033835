import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { contentContainerStyles } from 'screens/Organizations/OrganizationForm/OrganizationFormHelpers';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { HeadingXSmall } from 'baseui/typography';
import {
  Button,
  KIND,
} from 'baseui/button';
import CellFormControl from 'components/CellFormControl';
import {
  fetchManageFundsHistory,
  paycardsManageFundsSelectedEmployeeSelector,
} from 'store/slices/paycards';
import { DatePicker } from 'baseui/datepicker';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { OptionalDateOrDateArrayType } from 'types/CommonTypes';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import PaycardManagementPaycardsEmployeeManageFundsTable from './ManageFundsTable/PaycardManagementPaycardsEmployeeManageFundsTable';

const PaycardManagementPaycardsEmployeeManageFunds = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const selectedEmployee = useAppSelector(paycardsManageFundsSelectedEmployeeSelector);
  const [valueDate, setValueDate] = useState<OptionalDateOrDateArrayType>([]);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const dateFormat = t('dateFormats:date-picker.reverse');
  const displayFormat = t('dateFormats:standard');
  const datePickerFormat = t('dateFormats:date-picker.standard');
  const prevDate = useRef<OptionalDateOrDateArrayType>([]);

  const fetchHistory = (
    pageNum: number = 1,
  ) => {
    selectedEmployee && dispatch(fetchManageFundsHistory({
      organizationId: loggedOrganization?.id,
      proxyNumber: selectedEmployee?.proxyNumber,
      page: pageNum,
      fromDate: Array.isArray(valueDate) && valueDate?.[0] ? format(valueDate[0], dateFormat) : '',
      toDate: Array.isArray(valueDate) && valueDate?.[1] ? format(valueDate[1], dateFormat) : '',
    }));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    fetchHistory(nextPage);
  };

  const handleDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    setValueDate(Array.isArray(date) ? date : [date]);
  };

  const loadFunds = () => {
    history.push('/paycard/manage-funds/load');
  };

  useEffect(() => {
    if (JSON.stringify(prevDate.current) !== JSON.stringify(valueDate)) {
      fetchHistory();
      prevDate.current = valueDate;
    }
  }, [valueDate]);

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div className={css(contentContainerStyles)}>
      <CommonHeader
        title={`${t('paycardManagement:paycardManagement.paycards.manageFunds.header')} - ${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`}
        backTo="/paycard"
      />
      <Grid
        align={ALIGNMENT.center}
      >
        <Cell
          span={12}
          overrides={{
            Cell: {
              style: {
                background: '#FFFFFF',
                'list-style': 'none',
                marginTop: '24px',
              },
            },
          }}
        >
          <Grid
            align={ALIGNMENT.start}
            gridColumns={12}
            gridMargins={20}
          >
            <CellFormControl
              cellSpan={[12, 7, 4]}
              cellAlign={ALIGNMENT.center}
            >
              <HeadingXSmall margin={0}>{t('paycardManagement:paycardManagement.paycards.manageFunds.loadHistory')}</HeadingXSmall>
            </CellFormControl>
            <Cell skip={[0, 0, 4]} />
            <CellFormControl
              cellSpan={[12, 4, 3]}
              cellAlign={ALIGNMENT.center}
              formControlProps={{
                overrides: {
                  ControlContainer: {
                    style: {
                      '@media screen and (min-width: 600px)': {
                        display: 'inline-flex',
                        justifyItems: 'end',
                        justifyContent: 'flex-end',
                      },
                    },
                  },
                },
              }}
            >
              <Button
                kind={KIND.primary}
                onClick={loadFunds}
              >
                {t('paycardManagement:paycardManagement.paycards.manageFunds.loadFunds.label')}
              </Button>
            </CellFormControl>
          </Grid>
          <Grid
            align={ALIGNMENT.start}
            gridColumns={12}
            gridMargins={20}
          >
            <CellFormControl
              cellSpan={[12, 5, 2.5]}
              cellAlign={ALIGNMENT.center}
              label={t('paycardManagement:paycardManagement.paycards.manageFunds.dateRange')}
            >
              <DatePicker
                clearable
                value={valueDate}
                onChange={handleDateChange}
                formatString={datePickerFormat}
                placeholder={`${displayFormat} - ${displayFormat}`}
                range
                size="compact"
                overrides={{
                  InputWrapper: {
                    props: {
                      'data-testid': 'Paycards-manage-funds-history-date-picker',
                    },
                  },
                  Input: {
                    props: {
                      id: 'Paycards-manage-funds-history-date-picker',
                      'data-testid': 'Paycards-manage-funds-history-date-picker',
                    },
                  },
                }}
              />
            </CellFormControl>
          </Grid>
          <PaycardManagementPaycardsEmployeeManageFundsTable handlePageChange={handlePageChange} />
        </Cell>
      </Grid>
    </div>
  );
};

export default PaycardManagementPaycardsEmployeeManageFunds;
