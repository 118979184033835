import {
  DrawIDType,
  ZendeskTicketRequestType,
} from 'types/EmployeeTypes';
import request from './request';

export const fetchDefaultConfigRequest = (
  token: string,
) => request({
  token,
  url: '/organization-config-keys',
});

export const saveZendeskTicketRequest = (
  token: string,
  drawID: DrawIDType,
  data: ZendeskTicketRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `draws/${drawID}/zendesk-ticket`,
});
