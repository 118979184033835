import { useStyletron } from 'baseui';
import {
  memo, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeActionRequired,
  enrolmentReportDataSelector,
  enrolmentReportPendingSelector,
  fetchEnrolmentReport,
  fetchPayPeriodsWithActions,
  fetchUtilizationReport,
  payPeriodsLoadingSelector,
  resetEnrolmentReportData,
  resetUtilizationReportData,
  setStatus,
  setValueDate,
  utilizationReportDataSelector,
  utilizationReportPendingSelector,
} from 'store/slices/dashboard';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { Grid, ALIGNMENT, Cell } from 'baseui/layout-grid';
import { HeadingSmall } from 'baseui/typography';
import { Button, KIND } from 'baseui/button';
import { SIZE } from 'baseui/input';
import { StyleObject } from 'styletron-react';
import Loader from 'components/Loader';
import { PLACEMENT, Popover } from 'baseui/popover';
import { ChevronDown } from 'baseui/icon';
import DownloadFile from 'utils/downloadFile';
import { Block } from 'baseui/block';
import { colors } from 'theme';
import { containerStyles, popoverExportOverrides } from './DashboardHelper';
import PayPeriodStatusFilters from './PayPeriodStatusFilters/PayPeriodStatusFilters';
import PayPeriodsSection from './PayPeriodsSection/PayPeriodsSection';

const Dashboard = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'dashboard']);
  const organization = useAppSelector(loggedOrganizationSelector);
  const loading = useAppSelector(payPeriodsLoadingSelector);
  const dispatch = useAppDispatch();
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const utilizationReportData = useAppSelector(utilizationReportDataSelector);
  const utilizationReportPending = useAppSelector(utilizationReportPendingSelector);
  const enrolmentReportData = useAppSelector(enrolmentReportDataSelector);
  const enrolmentReportPending = useAppSelector(enrolmentReportPendingSelector);

  useEffect(() => {
    organization
      && dispatch(fetchPayPeriodsWithActions({
        organizationId: organization.id,
        pageNumber: 1,
        pageSize: 10,
      }));

    return () => {
      dispatch(setValueDate([]));
      dispatch(setStatus([]));
      dispatch(changeActionRequired([]));
    };
  }, []);

  useEffect(() => {
    if (utilizationReportData?.csv) {
      DownloadFile(
        `${utilizationReportData?.id}.csv`,
        `data:text/csv;charset=UTF-8,${encodeURIComponent(utilizationReportData?.csv)}`,
      );
      dispatch(resetUtilizationReportData());
    }
  }, [utilizationReportData?.id]);

  useEffect(() => {
    if (enrolmentReportData?.csv) {
      DownloadFile(
        `${enrolmentReportData?.id}.csv`,
        `data:text/csv;charset=UTF-8,${encodeURIComponent(enrolmentReportData?.csv)}`,
      );
      dispatch(resetEnrolmentReportData());
    }
  }, [enrolmentReportData?.id]);

  const exportUtilizationReport = () => {
    setIsPopoverOpen(false);
    organization && dispatch(fetchUtilizationReport({ organizationId: organization.id, organizationName: organization.name }));
  };

  const exportEnrolmentReport = () => {
    setIsPopoverOpen(false);
    organization && dispatch(fetchEnrolmentReport({ organizationId: organization.id, organizationName: organization.name }));
  };

  return (
    <div className={css(containerStyles as StyleObject)}>
      <Loader active={loading} />
      <CommonHeader title={t('dashboard:dashboard.pageHeader')}>
        <Block>
          <Button
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            kind={KIND.secondary}
            size={SIZE.compact}
            endEnhancer={<ChevronDown />}
          >
            {t('common:export')}
          </Button>
          <Popover
            isOpen={isPopoverOpen}
            onClickOutside={() => setIsPopoverOpen(false)}
            placement={PLACEMENT.bottomRight}
            content={() => (
              <Block className={css(popoverExportOverrides)}>
                <Button
                  onClick={exportUtilizationReport}
                  isLoading={utilizationReportPending}
                  overrides={{
                    Root: {
                      style: {
                        color: '#545454',
                      },
                      props: {
                        'data-testid': 'export-utilization-report-btn',
                        id: 'export-utilization-report-btn',
                      },
                    },
                    LoadingSpinner: {
                      style: {
                        borderRightColor: colors.primary,
                        borderTopColor: colors.primary,
                        borderLeftColor: colors.primary,
                      },
                    },
                  }}
                  kind={KIND.tertiary}
                  size={SIZE.compact}
                >
                  {t('dashboard:dashboard.exportUtilizationReport')}
                </Button>
                <Button
                  onClick={exportEnrolmentReport}
                  isLoading={enrolmentReportPending}
                  overrides={{
                    Root: {
                      style: {
                        color: '#545454',
                      },
                      props: {
                        'data-testid': 'export-enrolment-report-btn',
                        id: 'export-enrolment-report-btn',
                      },
                    },
                    LoadingSpinner: {
                      style: {
                        borderRightColor: colors.primary,
                        borderTopColor: colors.primary,
                        borderLeftColor: colors.primary,
                      },
                    },
                  }}
                  kind={KIND.tertiary}
                  size={SIZE.compact}
                >
                  {t('dashboard:dashboard.exportEnrolmentReport')}
                </Button>
              </Block>
            )}
          >
            <Block />
          </Popover>
        </Block>
      </CommonHeader>
      <Grid
        align={ALIGNMENT.end}
        gridColumns={12}
      >
        <Cell
          span={12}
          align={ALIGNMENT.start}
        >
          <HeadingSmall margin="24px 0">
            {t('dashboard:dashboard.payPeriodStatusHeader')}
          </HeadingSmall>
        </Cell>
      </Grid>
      {!loading && <PayPeriodStatusFilters />}
      {!loading && <PayPeriodsSection />}
    </div>
  );
};

export default memo(Dashboard);
