import { Value } from 'baseui/select';
import { LocationStatus } from 'types/LocationTypes';

export const locationConfigInitialValues = {
  excludeTippedShifts: false,
  offerPercentPerEmployee: 0,
  jobCodeExclusions: false,
  jobCodeExclusionsValue: null as string | null,
  ewaEnabled: true,
  tpoEnabled: false,
  ewa3rdPartyTips: false,
  ewaServiceCharges: false,
  ewaCashTips: false,
  ewaCCTips: false,
  ewaDeclaredTips: false,
  ewaTipsPool: false,
  ewaInclude3rdPartyTips: false,
  ewaIncludeServiceCharges: false,
  ewaIncludeCashTips: false,
  ewaIncludeCCTips: false,
  ewaIncludeDeclaredTips: false,
  ewaIncludeTipsPool: false,
  ewaSubtractIncluded3rdPartyTips: false,
  ewaSubtractIncludedServiceCharges: false,
  ewaSubtractIncludedCashTips: false,
  ewaSubtractIncludedCCTips: false,
  ewaSubtractIncludedDeclaredTips: false,
  ewaSubtractIncludedTipsPool: false,
  tpo3rdPartyTips: false,
  tpoServiceCharges: false,
  tpoCashTips: false,
  tpoCCTips: false,
  tpoDeclaredTips: false,
  tpoTipsPool: false,
  tpoIncludeCCTips: false,
  tpoIncludeServiceCharges: false,
  tpoInclude3rdPartyTips: false,
  tpoIncludeDeclaredTips: false,
  tpoIncludeCashTips: false,
  tpoIncludeTipsPool: false,
  tpoSubtractIncluded3rdPartyTips: false,
  tpoSubtractIncludedServiceCharges: false,
  tpoSubtractIncludedCashTips: false,
  tpoSubtractIncludedCCTips: false,
  tpoSubtractIncludedDeclaredTips: false,
  tpoSubtractIncludedTipsPool: false,
  tpoMaxEarningsPerBusinessDay: 0,
  tpoMaxEarningsPerPeriod: 0,
};

export const locationInitialValues = {
  id: null as number | null,
  locationName: '',
  locationExternalID: '',
  posId: '',
  organizationTimezone: '',
  tipsProviderId: '' as string | undefined,
  externalIntegrationID: '' as string | undefined,
  businessDayStartTime: new Date(new Date().setHours(9, 0, 0)),
  tag: [{ value: 1 }],
  payGroup: undefined as {
    value: string | undefined,
    label: string | undefined
  }[] | undefined,
  offerPercentPerEmployee: 0,
  excludeTippedShifts: false,
  status: [{
    value: LocationStatus.ACTIVE,
  }],
  jobCodeExclusions: false,
  jobCodeExclusionsValue: null as string | null,
  ewaEnabled: true,
  tpoEnabled: false,
  storeNumber: '' as string | undefined,
  conceptID: '' as string | undefined,
  alphaNumericStoreNumber: '' as string | undefined,
  employeeSchedulingSupported: true,
  offerProRataMethod: [{ value: 'CALENDAR_DAYS' }],
  hourlyPaidEmployeesEnable: true,
  salariedEmployeesEnable: false,
  regularlyPaidEmployeesEnable: false,
  ewa3rdPartyTips: false,
  ewaServiceCharges: false,
  ewaCashTips: false,
  ewaCCTips: false,
  ewaDeclaredTips: false,
  ewaTipsPool: false,
  ewaInclude3rdPartyTips: false,
  ewaIncludeServiceCharges: false,
  ewaIncludeCashTips: false,
  ewaIncludeCCTips: false,
  ewaIncludeDeclaredTips: false,
  ewaIncludeTipsPool: false,
  ewaSubtractIncluded3rdPartyTips: false,
  ewaSubtractIncludedServiceCharges: false,
  ewaSubtractIncludedCashTips: false,
  ewaSubtractIncludedCCTips: false,
  ewaSubtractIncludedDeclaredTips: false,
  ewaSubtractIncludedTipsPool: false,
  tpo3rdPartyTips: false,
  tpoServiceCharges: false,
  tpoCashTips: false,
  tpoCCTips: false,
  tpoDeclaredTips: false,
  tpoTipsPool: false,
  tpoIncludeCCTips: false,
  tpoIncludeServiceCharges: false,
  tpoInclude3rdPartyTips: false,
  tpoIncludeDeclaredTips: false,
  tpoIncludeCashTips: false,
  tpoIncludeTipsPool: false,
  tpoSubtractIncluded3rdPartyTips: false,
  tpoSubtractIncludedServiceCharges: false,
  tpoSubtractIncludedCashTips: false,
  tpoSubtractIncludedCCTips: false,
  tpoSubtractIncludedDeclaredTips: false,
  tpoSubtractIncludedTipsPool: false,
  tpoMaxEarningsPerBusinessDay: 0,
  tpoMaxEarningsPerPeriod: 0,
  bankAccount: {
    bankAccountId: null as string | null,
    bankAccountAlias: null as string | null,
  },
};

export const LocationPaycardConfigTypeInitialValues = {
  locationName: '',
  locationExternalId: [] as Value,
  programId: [] as Value,
  enabled: false,
  state: [] as Value,
  city: '',
  postalCode: '',
  addressLine1: '',
  addressLine2: '',
  country: 'US',
};
