/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useState,
  memo,
  ReactNode,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import {
  Drawer,
  DrawerOverrides,
} from 'baseui/drawer';
import {
  OnItemSelect,
  StatefulMenu,
} from 'baseui/menu';
import { Menu as MenuIcon } from 'baseui/icon';
import { Layer } from 'baseui/layer';
import { Avatar } from 'baseui/avatar';
import { Block } from 'baseui/block';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  logout,
  userAccessUnitsSelector,
  userSelector,
} from 'store/slices/user';
import { colors } from 'theme';
import hasAccess from 'utils/hasAccess';
import { setPrevPage } from 'store/slices/application';
import environment from '../environment';
import {
  AccessCheckType,
  AccessUnit,
} from './Access/Access';

export type MenuItem = {
  id?: string
  route?: string
  all?: AccessUnit[]
  oneOf?: AccessUnit[]
  label?: string | ReactNode
  disabled?: boolean
  hidden?: boolean
  handler?: () => any
}

const logoutStyles = {
  color: colors.primary,
};

const menuBtnOverrides = {
  Root: {
    style: {
      backgroundColor: 'transparent',
    },
    props: {
      id: 'menu-drawer-button',
      'data-test-id': 'Menu-drawer-button',
    },
  },
};

const drawerOverrides: DrawerOverrides = {
  Root: {
    style: {
      zIndex: 100,
    },
  },
  Close: {
    props: {
      id: 'Menu-close-button',
      'data-testid': 'Menu-close-button',
    },
  },
  DrawerContainer: {
    style: {
      marginTop: '72px',
      boxSizing: 'border-box',
      paddingLeft: '0px',
      paddingRight: '0px',
      maxWidth: '300px',
    },
  },
  DrawerBody: {
    style: {
      marginLeft: '0px',
      marginRight: '0px',
      paddingLeft: '0px',
      paddingRight: '0px',
      height: 'calc(100vh - 110px)',
    },
  },
};

const statefulMenuOverrides = {
  List: {
    style: {
      height: 'auto',
      width: '100%',
      boxShadow: 'none',
      marginTop: '0px',
      paddingTop: '0px',
      marginLeft: '0px',
      paddingLeft: '0px',
    },
  },
  ListItem: {
    style: {
      borderBottom: '1px solid #ccc',
      paddingTop: '18px',
      paddingBottom: '18px',
      paddingLeft: '18px',
      paddingRight: '18px',
      fontSize: '18px',
    },
  },
  Option: {
    props: {
      getItemLabel: (item: { label: string }) => item.label,
    },
  },
};

const avatarOverrides = {
  Root: {
    style: {
      backgroundColor: colors.primary,
    },
  },
};

const menuContainerStyle = {
  display: 'inline-flex',
  marginRight: '8px',
};

const drawerContainerStyle = {
  zIndex: 100,
};

export type MenuPropsType = {
  hideMenu?: boolean
}

const Menu = ({ hideMenu = false }: MenuPropsType) => {
  const history = useHistory();
  const initialState: { position: string, active: boolean } = {
    position: 'left',
    active: false,
  };

  const { t } = useTranslation();
  const [css] = useStyletron();
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const organization = useAppSelector(loggedOrganizationSelector);
  const user = useAppSelector(userSelector);

  const [drawer, setDrawer] = useState(initialState);

  const close = () => {
    setDrawer({ ...drawer, active: false });
  };

  const handleItemSelect: OnItemSelect = ({
    item,
  }) => {
    setDrawer({ ...drawer, active: false });

    if (item.handler) {
      item.handler();
    }

    if (item.route) {
      history.push(item.route);
    }
    dispatch(setPrevPage(''));
  };

  const handleLogoutButtonClick = () => {
    dispatch(logout(instance));
  };

  const organizationId = useAppSelector(loggedOrganizationSelector)?.id;
  const userRoles = useAppSelector(userAccessUnitsSelector);

  const hasEWAReportRole = userRoles.includes(AccessUnit.EWAReport);
  const hasOrganizationId = !!organizationId;

  const menuItems: MenuItem[] = [
    {
      id: 'menu-dashboard',
      route: '/dashboard',
      label: t('menu.dashboard'),
      oneOf: [AccessUnit.EWAManager],
      disabled: !organization?.id,
    },
    {
      id: 'menu-admin',
      route: '/administrators',
      label: t('menu.administrators'),
      oneOf: [AccessUnit.EWAManager, AccessUnit.EWAClientManager],
    },
    {
      id: 'menu-workers',
      route: '/workers',
      label: t('menu.workers'),
      oneOf: [AccessUnit.EWAManager],
    },
    {
      id: 'menu-organization',
      route: '/organizations',
      oneOf: [AccessUnit.EWAManager, AccessUnit.EWAClientManager],
      label: t('menu.customers'),
    },
    {
      id: 'menu-worker-linking',
      route: '/loggedOrganization/worker-linking',
      oneOf: [AccessUnit.EWAManager],
      label: t('menu.workerLinking'),
      disabled: !organization?.id,
    },
    {
      id: 'menu-employees',
      route: '/loggedOrganization/employees',
      oneOf: [AccessUnit.EWAManager, AccessUnit.EWAClientManager],
      label: t('menu.employees'),
      disabled: !organization?.id,
    },
    {
      id: 'menu-batch-management',
      route: '/batches/section/0',
      oneOf: [AccessUnit.EWAManager, AccessUnit.EWAClientManager],
      label: t('menu.batchManagement'),
      disabled: !organization?.id,
    },
    {
      id: 'menu-tips-management',
      route: '/tips',
      oneOf: [AccessUnit.EWAManager, AccessUnit.EWAClientManager, AccessUnit.EWAClientLocationManager],
      label: t('menu.tipsManagement'),
      disabled: !organization?.id,
    },
    {
      id: 'menu-paycard-management',
      route: '/paycard',
      oneOf: [AccessUnit.PaycardClientManager],
      label: t('menu.paycardManagement'),
      disabled: !organization?.id,
    },
    {
      id: 'menu-integrationData',
      route: '/integrationData',
      oneOf: [AccessUnit.TipsReader],
      label: t('menu.integrationData'),
    },
    {
      id: 'menu-treasury',
      route: '/treasury/section/0',
      oneOf: [AccessUnit.TreasuryManager, AccessUnit.TreasuryReader, AccessUnit.FBOManager, AccessUnit.FBOReader, AccessUnit.EWAReport],
      label: t('menu.treasury'),
    },
    // {
    //   route: '/settingsAudit',
    //   label: t('menu.settingsAudit'),
    //   disabled: true,
    // },
    {
      id: 'menu-log-out',
      handler: handleLogoutButtonClick,
      label: <span className={css(logoutStyles)}>{t('menu.logout')}</span>,
    },
  ];

  const filteredByAccess = menuItems?.filter((item) => {
    const checkOneOf = item?.oneOf ? hasAccess(AccessCheckType.oneOf, item.oneOf) : true;
    const hasAccessProvided = !!(item?.all || item?.oneOf);

    return !item?.hidden && (!hasAccessProvided || (hasAccessProvided && checkOneOf));
  });

  const handleMenuIconButtonClick = () => setDrawer({ ...drawer, active: true });

  const handleDrawerClose = () => close();

  return (
    <div className={css(menuContainerStyle)}>
      {!hideMenu && (
        <>
          <Button
            overrides={menuBtnOverrides}
            onClick={handleMenuIconButtonClick}
          >
            <MenuIcon size={32} />
          </Button>

          <div className={css(drawerContainerStyle)}>
            <Layer index={100}>
              <Drawer
                overrides={drawerOverrides}
                onClose={handleDrawerClose}
                isOpen={drawer.active}
                anchor={drawer.position}
              >

                <Block
                  marginTop="12px"
                  marginBottom="12px"
                  padding="16px 8px 16px 16px"
                >
                  <Avatar
                    name={user?.name}
                    overrides={avatarOverrides}
                    size="scale1000"
                  />

                  <Block
                    maxWidth="230px"
                    position="relative"
                    display="inline-flex"
                    marginLeft="12px"
                  >
                    <span className="ellipsis">
                      {user?.name}
                    </span>
                  </Block>
                </Block>

                <StatefulMenu
                  items={filteredByAccess}
                  getRequiredItemProps={(item) => ({
                    id: item.id,
                  })}
                  onItemSelect={handleItemSelect}
                  overrides={statefulMenuOverrides}
                />
                {Boolean(environment.buildNumber && !environment.buildNumber.includes('#')) && (
                  <Block
                    display="flex"
                    justifyContent="center"
                    padding="10px 0px 0px 18px"
                  >
                    Release Number:&nbsp;
                    {environment.buildNumber}
                  </Block>
                )}
              </Drawer>
            </Layer>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(Menu);
