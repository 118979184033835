import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loginRequest } from 'auth/authConfig';
import { AccessUnit } from 'components/Access/Access';
import parseJwt from 'utils/parseJwt';
import { RootState } from '..';
import { setError } from './error';

export interface User {
  name: string,
  email: string,
  isAuthenticated: boolean,
  accessToken: string,
  accessUnits: AccessUnit[],
  organizationUnits: string[]
}

export const initialState: User = {
  name: '',
  email: '',
  isAuthenticated: false,
  accessToken: '',
  accessUnits: [],
  organizationUnits: [],
};

export const login = createAsyncThunk(
  'user/login',
  (instance: IPublicClientApplication): Promise<any> => instance.loginRedirect(loginRequest),
);

export const logout = createAsyncThunk(
  'user/logout',
  async (instance: IPublicClientApplication, thunkAPI): Promise<any> => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    } catch (error: any) {
      thunkAPI.dispatch(setError({ message: error?.message || 'Logout Failed!' }));
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: User, { payload }): User => {
      const { accessToken } = payload || {};
      const data = accessToken && parseJwt(accessToken);
      const accessUnits: AccessUnit[] = data?.extension_FinservRoles?.split(',')?.map((item: string) => item?.trim()) || [];
      const organizationUnits = data?.extension_FinservOrganisation?.split(',')?.map((item: string) => item?.trim()) || [];

      return ({
        ...state, ...payload, accessUnits, organizationUnits,
      });
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;

// Selectors
export const userSelector = (state: RootState): User => state.user;
export const userAccessUnitsSelector = (state: RootState): AccessUnit[] => state.user.accessUnits;
export const userOrganizationUnitsSelector = (state: RootState): string[] => state.user.organizationUnits;
