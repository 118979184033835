import { StyleObject } from 'styletron-react';
import { colors } from 'theme';

export const contentContainerStyles = {
  padding: '0 0 8px 0',
  background: '#E5E5E5',
  marginTop: '24px',
  zIndex: 50,
  minHeight: '100vh',
};

export const notificationsOverrides = {
  Body: {
    style: {
      width: '100%',
      margin: 0,
    },
  },
};

export const hrStyles = {
  borderTopColor: colors.primary,
  borderBottomColor: colors.primary,
};

export const controlOverrides = {
  ControlContainer: {
    style: {
      '@media screen and (min-width: 1136px)': {
        display: 'inline-flex',
        justifyItems: 'end',
        justifyContent: 'flex-end',
      },
    },
  },
};

export const gridOverrides = {
  Grid: {
    style: {
      marginRight: '8px',
      marginLeft: '8px',
      marginTop: '8px',
      marginBottom: '8px',
      display: 'flex',
      borderRadius: '4px',
      border: '1px solid #eee',
      width: '100%',
    },
  },
};

export const containerStyles = {
  position: 'relative',
} as StyleObject;

export const labelMediumOverrides = {
  fontWeight: 500,
};

export const headingSmallOverrides = {
  margin: '24px 0 8px 0',
};

export const bannerStyles = {
  Root: {
    style: {
      margin: '0',
      cursor: 'normal',
      maxWidth: '100%',
      marginTop: '16px',
      marginBottom: '24px',
    },
  },
};

export const bannerListStyles = {
  paddingLeft: '24px',
  marginTop: '8px',
  width: 'calc(100% - 24px)',
  marginBottom: 0,
};

export const dailyScheduledPayOutTimeStyles = {
  borderColor: '#AFAFAF',
  borderWidth: '2px',
  borderRadius: '24px',
  borderStyle: 'solid',
  padding: '2px 8px',
  color: '#000000',
};

export const dailyScheduledPayOutTimeParagraphStyles = {
  margin: '0',
  lineHeight: '20px',
};
