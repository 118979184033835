import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer } from 'baseui/layer';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { ModalNames, setModal } from 'store/slices/modals';
import AppModal from 'components/AppModal/AppModal';
import { EmployeeDetailsDrawDetailsModalPropsType } from 'types/EmployeeTypes';
import { employeeEWADrawsSelector } from 'store/slices/employees';
import { Cell, Grid } from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import { useStyletron } from 'styletron-react';
import moment from 'moment';

const firstColumnWidth = '40%';
const secondColumnWidth = '60%';
const headerLabelStyles = {
  fontWeight: 600,
};

const EmployeeDetailsDrawDetailsModal = ({ drawID }: EmployeeDetailsDrawDetailsModalPropsType) => {
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const { t } = useTranslation(['organizations', 'errors', 'common', 'locations', 'payGroups', 'dateFormats']);
  const modalName = ModalNames.EMPLOYEE_DRAW_DETAILS_MODAL;
  const draws = useAppSelector(employeeEWADrawsSelector);
  const draw = draws?.find((item) => item?.id?.toString() === drawID?.toString());

  const dateFormat = t('dateFormats:standard-with-time');

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Layer index={400}>
      <AppModal
        minWidth="360px"
        maxWidth="100%"
        modal={modalName}
        title={t('employees:draws.details.title')}
        onClose={handleModalClose}
      >
        <Block
          marginTop="40px"
        >
          <Grid
            gridColumns={12}
            gridMargins={16}
            gridGaps={16}
          >
            <Cell span={12}>
              <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
                {t('employees:draws.details.ID.label')}
              </Block>
              <Block width={secondColumnWidth} display="inline-flex">
                {draw?.id}
              </Block>
            </Cell>

            <Cell span={12}>
              <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
                {t('employees:draws.details.workerID.label')}
              </Block>
              <Block width={secondColumnWidth} display="inline-flex">
                {draw?.workerId}
              </Block>
            </Cell>

            <Cell span={12}>
              <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
                {t('employees:draws.details.employmentID.label')}
              </Block>
              <Block width={secondColumnWidth} display="inline-flex">
                {draw?.employmentId}
              </Block>
            </Cell>

            <Cell span={12}>
              <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
                {t('employees:draws.details.offerID.label')}
              </Block>
              <Block width={secondColumnWidth} display="inline-flex">
                {draw?.offerId}
              </Block>
            </Cell>

            <Cell span={12}>
              <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
                {t('employees:draws.details.paymentMethod.label')}
              </Block>
              <Block width={secondColumnWidth} display="inline-flex">
                {draw?.paymentMethod && t(`employees:draws.paymentMethods.${draw?.paymentMethod}`)}
              </Block>
            </Cell>

            <Cell span={12}>
              <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
                {t('employees:draws.details.requestDate.label')}
              </Block>
              <Block width={secondColumnWidth} display="inline-flex">
                {moment(draw?.drawRequestedDate).format(dateFormat)}
              </Block>
            </Cell>
          </Grid>
        </Block>
      </AppModal>
    </Layer>
  );
};

export default memo(EmployeeDetailsDrawDetailsModal);
