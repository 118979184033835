import { Card } from 'baseui/card';
import { Grid, Cell } from 'baseui/layout-grid';
import { useTranslation } from 'react-i18next';
import { StyleObject, useStyletron } from 'styletron-react';
import { borderRadius } from 'theme';

const contentContainerStyles = {
  padding: '24px 24px 0px',
  background: '#E5E5E5',
  marginTop: '0px',
  zIndex: 50,
};

const contentPaymentAloocationStyles = {
  background: '#fff',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  'box-sizing': 'border-box',
  padding: '24px',
  maxWidth: '100%',
  position: 'relative',
} as StyleObject;

const FailedPaymentsSection = () => {
  const { t } = useTranslation(['batches', 'errors', 'common', 'dateFormats']);
  const [css] = useStyletron();

  return (
    <div className={css(contentContainerStyles)}>
      <Grid
        gridColumns={12}
        gridMargins={0}
      >
        <Cell span={12}>
          <div
            className={css(contentPaymentAloocationStyles)}
          >
            <Grid
              gridColumns={12}
              gridMargins={16}
            >
              <Cell span={12}>
                <Card>
                  {t('batches:failedPayments')}
                </Card>
              </Cell>
            </Grid>
          </div>
        </Cell>
      </Grid>
    </div>
  );
};

export default FailedPaymentsSection;
