import { useStyletron } from 'baseui';
import {
  KIND,
} from 'baseui/button';
import {
  Checkbox,
  LABEL_PLACEMENT,
  STYLE_TYPE,
} from 'baseui/checkbox';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  LabelMedium,
  LabelXSmall,
  ParagraphMedium,
  ParagraphSmall,
} from 'baseui/typography';
import AppModal from 'components/AppModal/AppModal';
import moment from 'moment';
import {
  ChangeEvent,
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  ModalNames,
  setModal,
} from 'store/slices/modals';
import {
  NotificationType,
  setNotification,
} from 'store/slices/notification';
import {
  fetchTnALocationSettings,
  setTnALocationSettings,
  tnaLocationSettingsSelector,
  tnaLocationSettingsPendingSelector,
  tnaLocationSettingsFetchFailedSelector,
} from 'store/slices/tna';
import {
  isDailyFileUploadingSelector,
  importTipsFile,
  tippedEmployeesSelectedLocationSelector,
  tpoSearchBusinessDateSelector,
} from 'store/slices/tpo';
import { colors } from 'theme';
import { FileUploader } from 'baseui/file-uploader';
import { toggleOverrides } from 'components/Form/AppCheckbox';
import Loader from 'components/Loader';
import CellFormControl from 'components/CellFormControl';
import {
  SIZE,
  Select,
  Value,
} from 'baseui/select';

const TipManagementUploadTipsFileModal = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'tipsManagement', 'dateFormats']);
  const theme = useStyletron()[1];
  const dispatch = useAppDispatch();
  const modalName = ModalNames.UPLOAD_TIPS_FILE_MODAL;
  const dayViewBusinessDate = useAppSelector(tpoSearchBusinessDateSelector);
  const [formData, setFormData] = useState<FormData>(new FormData());
  const [fileUploaded, setFileUploaded] = useState<File | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const tnaLocationSettings = useAppSelector(tnaLocationSettingsSelector);
  const pendingTnALocationSettings = useAppSelector(tnaLocationSettingsPendingSelector);
  const tnaLocationSettingsFetchFailed = useAppSelector(tnaLocationSettingsFetchFailedSelector);
  const [isSynchingEnabled, setIsSynchingEnabled] = useState<boolean>(true);
  const organization = useAppSelector(loggedOrganizationSelector);
  const { id: organizationID } = organization || {};
  const selectedLocation = useAppSelector(tippedEmployeesSelectedLocationSelector);
  const dateFormat = t('dateFormats:standard-reverse');
  const dateFormatStandard = t('dateFormats:standard');
  const dateFormatDayOfTheWeek = t('dateFormats:standard-day-of-the-week-and-year-slashes');
  const isDailyFileUploading = useAppSelector(isDailyFileUploadingSelector);
  const [fileFormat, setFileFormat] = useState<Value>();

  const formats = [
    { value: 'FuegoExportBasic', label: t('tipsManagement:uploadFile.format.tipManagementTemplate.label') },
    { value: '', label: t('tipsManagement:uploadFile.format.customTemplate.label') },
  ];

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleUploadFile = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFileUploaded(acceptedFiles[0]);
    } else {
      setErrorMessage('Upload failed... connection was lost');
    }
  };

  const handleCancelClick = () => {
    setFileUploaded(undefined);
  };

  const handleImportFile = () => {
    if (selectedLocation[0].tipsProviderId) {
      dispatch(importTipsFile({
        organizationID,
        locationID: selectedLocation[0].id,
        formData,
      })).then(() => {
        setIsModalOpen(false);
      });
    } else {
      setIsModalOpen(false);
      dispatch(setNotification({
        type: NotificationType.ERROR,
        isOpen: true,
        title: t('errors:error'),
        text: t('tipsManagement:noTipsProviderIdError'),
        autoHideDuration: 3000,
      }));
    }
  };

  const handleAutomaticSynchingChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;
    setIsSynchingEnabled(checked);
    dispatch(setTnALocationSettings({
      organizationID,
      locationID: selectedLocation[0].id,
      data: {
        externalLocationId: selectedLocation[0].tipsProviderId,
        businessDate: moment(dayViewBusinessDate?.toString()).format(dateFormat),
        lockBusinessDate: !checked,
      },
    })).unwrap()
      .catch(() => {
        dispatch(setNotification({
          type: NotificationType.ERROR,
          isOpen: true,
          title: t('errors:error'),
          text: t(
            'tipsManagement:setTnALocationSettings.failed',
            {
              businessDate: moment(dayViewBusinessDate?.toString()).format(dateFormat),
            },
          ),
          autoHideDuration: 3000,
        }));
      });
  };

  useEffect(() => {
    if (fileUploaded && dayViewBusinessDate) {
      formData.append('csvFile', fileUploaded);
      formData.append('businessDate', moment(dayViewBusinessDate?.toString()).format(dateFormat));
      formData.append('externalLocationId', selectedLocation[0].tipsProviderId);
      fileFormat?.[0].value === 'FuegoExportBasic' && formData.append('csvFormat', fileFormat[0].value);
      setFormData(formData);
    }
  }, [fileUploaded, dayViewBusinessDate]);

  useEffect(() => {
    dispatch(fetchTnALocationSettings({
      organizationID,
      locationID: selectedLocation[0].id,
      businessDate: moment(dayViewBusinessDate?.toString()).format(dateFormat),
    }));
  }, []);

  useEffect(() => {
    tnaLocationSettings && setIsSynchingEnabled(!tnaLocationSettings?.[0]?.lockBusinessDate);
  }, [tnaLocationSettings]);

  return (
    <AppModal
      confirm
      confirmTitle={t('tipsManagement:uploadTipFile.confirmModal.title')}
      confirmBody={t(
        'tipsManagement:uploadTipFile.confirmModal.body',
        {
          businessDate: moment(dayViewBusinessDate?.toString()).format(dateFormatDayOfTheWeek),
        },
      )}
      confirmActionBtnText={t('common:confirm')}
      modal={modalName}
      title={t('common:uploadFile')}
      cancelBtnText={t('common:cancel')}
      actionBtnText={t('common:save')}
      onClose={handleModalClose}
      isActionDisabled={!dayViewBusinessDate || !fileUploaded?.name || !fileFormat?.[0].label}
      onAction={handleImportFile}
      modalWidth={['70vw']}
      minWidth="400px"
      maxWidth="100%"
    >
      <Grid
        align={ALIGNMENT.start}
        gridMargins={16}
      >
        <Cell
          span={12}
          align={ALIGNMENT.start}
        >
          <LabelMedium display="inline-flex">
            {t('tipsManagement:businessDate.label')}
            : &nbsp;
          </LabelMedium>
          <ParagraphMedium display="inline-flex">
            {moment(dayViewBusinessDate).format(dateFormatStandard)}
          </ParagraphMedium>

        </Cell>
        <CellFormControl
          cellSpan={[12, 6, 3]}
          label={t('tipsManagement:uploadFile.format.label')}
          tooltipContent={t('tipsManagement:uploadFile.format.tooltip')}
          showStar
        >
          <Select
            size={SIZE.compact}
            placeholder={t('common:select')}
            type="select"
            clearable={false}
            options={formats}
            labelKey="label"
            valueKey="value"
            onChange={({ value }) => {
              setFileFormat(value);
            }}
            value={fileFormat}
            overrides={{
              ControlContainer: {
                props: {
                  id: 'TipManagementSection-upload-file-format-select',
                },
              },
            }}
          />
        </CellFormControl>
        <Cell span={12}>
          <FileUploader
            onCancel={handleCancelClick}
            errorMessage={fileUploaded ? `Uploaded File: ${fileUploaded.name}` : errorMessage}
            onDrop={handleUploadFile}
            progressMessage={fileUploaded ? `Uploading: ${fileUploaded.name}` : ''}
            onRetry={handleCancelClick}
            overrides={{
              ButtonComponent: {
                props: {
                  id: 'TipManagementSection-import-tips-file-btn',
                  kind: `${KIND.primary}`,
                },
              },
              ErrorMessage: {
                style: {
                  color: '#674D1B',
                },
              },
            }}
          />
        </Cell>
        <Cell span={12}>
          <br />
          <Checkbox
            checked={isSynchingEnabled}
            disabled={tnaLocationSettingsFetchFailed || pendingTnALocationSettings}
            labelPlacement={LABEL_PLACEMENT.right}
            name="checkbox-disable-automatic-synching"
            onChange={handleAutomaticSynchingChange}
            checkmarkType={STYLE_TYPE.toggle_round}
            overrides={{
              ...toggleOverrides({
                checked: isSynchingEnabled && !tnaLocationSettingsFetchFailed && !pendingTnALocationSettings,
              }),
              Input: {
                props: {
                  id: 'Checkbox-disable-automatic-synching-input',
                  'data-testid': 'Checkbox-disable-automatic-synching-input',
                  'aria-checked': `${isSynchingEnabled}`,
                },
              },
              ToggleTrack: {
                style: {
                  minWidth: '40px',
                },
              },
            }}
          >
            {isSynchingEnabled
              ? t('tipsManagement:tnaLocationSettings.synchingEnabled.label')
              : t('tipsManagement:tnaLocationSettings.synchingDisabled.label')}
          </Checkbox>
          <ParagraphSmall
            color={theme.colors.contentSecondary}
            className={css({
              whiteSpace: 'normal',
            })}
          >
            {t('tipsManagement:disableAutomaticSynching.description')}
          </ParagraphSmall>
          {tnaLocationSettingsFetchFailed && (
            <LabelXSmall display="flex" justifyContent="right" color={colors.primary}>
              {t('tipsManagement:fetchingSettingsFailedError')}
            </LabelXSmall>
          )}
        </Cell>
      </Grid>
      {isDailyFileUploading && (
        <Loader active={isDailyFileUploading} />
      )}
    </AppModal>
  );
};
export default memo(TipManagementUploadTipsFileModal);
