import {
  createContext,
  memo,
  useEffect,
} from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  Button,
  KIND,
} from 'baseui/button';
import {
  Grid,
  Cell,
  ALIGNMENT,
} from 'baseui/layout-grid';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { Block } from 'baseui/block';
import AccordionSection from 'components/AccordionSection/AccordionSection';
import { employeeProfileValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  changeWorkerPaycardOffer,
  editEmployeeDetailsProfile,
  employeeDetailsPendingSelector,
  fetchEmployee,
  fetchPaycardOffer,
  paycardOfferPendingSelector,
  paycardOfferSelector,
  showPaycardOfferSelector,
} from 'store/slices/employees';
import {
  loggedOrganizationSelector,
} from 'store/slices/loggedOrganization';
import {
  employeeDetailsProfileMapper,
  saveEmployeeDetailsProfileMapper,
} from 'dataMappers/employmentDataMapper';
import AppSelect from 'components/Form/AppSelect';
import Loader from 'components/Loader';
import { errorSelector } from 'store/slices/error';
import { resetEmployeeDetailsWorkersDrawsAndOffers } from 'store/events';
import {
  borderRadius,
  colors,
  emptyPlaceholder,
} from 'theme';
import { notificationSelector } from 'store/slices/notification';
import {
  EmploymentStatus,
  EmployeeResponseType,
  EmployeeDetailsProfileValuesType,
} from 'types/EmployeeTypes';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import hasAccess from 'utils/hasAccess';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { StatefulTooltip } from 'baseui/tooltip';
import {
  LabelMedium,
  LabelSmall,
  ParagraphMedium,
  ParagraphSmall,
} from 'baseui/typography';
import { employeeDetailsProfileInitialValues } from 'initialValues/EmployeeInitialValues';
import AppCheckbox from 'components/Form/AppCheckbox';
import {
  profileContainerStyles,
} from '../../EmployeesHelpers';

export const EmployeeProfileFormContext = createContext(
  {} as FormikState<EmployeeDetailsProfileValuesType> & FormikHelpers<EmployeeDetailsProfileValuesType> & FormikHandlers,
);

export type EmployeeDetailsProfileSectionPropsType = {
  selectedEmployee?: EmployeeResponseType
  expanded?: boolean
};

const EmployeeDetailsProfileSection = ({
  selectedEmployee,
  expanded = true,
}: EmployeeDetailsProfileSectionPropsType) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { employeeID } = useParams<{ organizationID: string, employeeID: string }>();
  const { t } = useTranslation(['errors', 'common', 'locations', 'payGroups', 'employees', 'workers']);
  const [css] = useStyletron();
  const theme = useStyletron()[1];
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const pending = useAppSelector(employeeDetailsPendingSelector);
  const notificationToast = useAppSelector(notificationSelector);
  const error = useAppSelector(errorSelector);
  const organizationID = loggedOrganization?.id;
  const paycardOffer = useAppSelector(paycardOfferSelector);
  const paycardOfferPending = useAppSelector(paycardOfferPendingSelector);
  const showPaycardOffer = useAppSelector(showPaycardOfferSelector);

  const initialValues = {
    ...employeeDetailsProfileInitialValues,
  };

  const onSubmit = (
    values: EmployeeDetailsProfileValuesType,
  ) => {
    (loggedOrganization?.id && employeeID && selectedEmployee) && dispatch(editEmployeeDetailsProfile({
      employeeID,
      organization: loggedOrganization,
      employeeDetailsData: saveEmployeeDetailsProfileMapper(values, selectedEmployee),
    }));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const enrollmentStatuses = [
    { value: EmploymentStatus.ACTIVE, label: t('employees:employments.statuses.active') },
    { value: EmploymentStatus.INACTIVE, label: t('employees:employments.statuses.inactive') },
    { value: EmploymentStatus.TERMINATED, label: t('employees:employments.statuses.terminated') },
    { value: EmploymentStatus.MERGED, label: t('employees:employments.statuses.merged') },
  ];

  const enrollmentStatusesBADData = [
    { value: EmploymentStatus.BAD_DATA, label: t('employees:employments.statuses.badData') },
  ];

  const handleClickCancel = () => {
    history.push('/loggedOrganization/employees');
  };

  const {
    values,
    setValues,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    isValid,
  } = formik;

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  useEffect(() => {
    setDefaultValues(initialValues);
    setValues(initialValues);
    dispatch(resetEmployeeDetailsWorkersDrawsAndOffers());
    dispatch(fetchEmployee({ organizationID, employeeID }));
  }, []);

  useEffect(() => {
    if (selectedEmployee?.id) {
      const combinedValues = {
        ...values,
        ...employeeDetailsProfileMapper(selectedEmployee),
      };
      setValues(combinedValues);
      setDefaultValues(combinedValues);
    }
    if (selectedEmployee?.workerId) {
      dispatch(fetchPaycardOffer({
        organizationID,
        workerID: selectedEmployee?.workerId,
      }));
    }
  }, [selectedEmployee]);

  useEffect(() => {
    if (notificationToast?.[1]?.isOpen) {
      setSubmitting(false);
      setDefaultValues(values);
      setValues(values);
    }
  }, [notificationToast]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
      setDefaultValues(values);
      setValues(values);
    }
  }, [error]);

  const handlePaycardToggleSwitch = () => {
    dispatch(changeWorkerPaycardOffer({
      organizationID,
      workerID: selectedEmployee?.workerId || '',
      allowed: !paycardOffer,
    }));
  };

  return (
    <EmployeeProfileFormContext.Provider value={formik}>
      <AccordionSection
        title={t('employees:profile')}
        disabled={false}
        showStatus={false}
        expanded={expanded}
      >
        <div className={css(profileContainerStyles)}>
          <Loader active={pending || paycardOfferPending} />

          <form onSubmit={handleSubmit}>
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <AppSelect
                showStar
                name="enrollmentStatus"
                cellSpan={[12, 6, 3]}
                label={t('employees:employeeStatus')}
                clearable={false}
                options={values?.enrollmentStatus?.[0]?.value === 'BAD_DATA' ? enrollmentStatusesBADData : enrollmentStatuses}
                context={EmployeeProfileFormContext}
                selectProps={{
                  onChange: (option) => { setFieldValue('enrollmentStatus', [option.option]); },
                  disabled: !hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.EWAClientManager])
                    || values?.enrollmentStatus?.[0]?.value === 'BAD_DATA',
                }}
              />
            </Grid>
            <br />
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <Cell
                span={12}
                align={ALIGNMENT.start}
              >
                <LabelMedium>{t('employees:employeeInfo.header')}</LabelMedium>
              </Cell>
            </Grid>

            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <Cell
                span={[12, 4, 2]}
              >
                <LabelSmall display="inline-flex" color={theme.colors.contentSecondary}>
                  {t('employees:payrollEmployeeId')}
                  : &nbsp;
                </LabelSmall>
                <ParagraphSmall display="inline-flex">
                  {values?.payrollEmployeeId || emptyPlaceholder}
                </ParagraphSmall>
              </Cell>

              <Cell
                span={[12, 4, 3]}
              >
                <LabelSmall display="inline-flex" color={theme.colors.contentSecondary}>
                  {t('employees:email')}
                  : &nbsp;
                </LabelSmall>
                <StatefulTooltip
                  accessibilityType="tooltip"
                  content={selectedEmployee?.email}
                >
                  <ParagraphSmall display="inline-flex">
                    {selectedEmployee?.email || emptyPlaceholder}
                  </ParagraphSmall>
                </StatefulTooltip>
              </Cell>

              <Cell
                span={[12, 4, 2]}
              >
                <LabelSmall display="inline-flex" color={theme.colors.contentSecondary}>
                  {t('employees:mobileNumber')}
                  : &nbsp;
                </LabelSmall>
                <ParagraphSmall display="inline-flex">
                  {selectedEmployee?.phone || emptyPlaceholder}
                </ParagraphSmall>
              </Cell>
            </Grid>

            {showPaycardOffer && selectedEmployee?.workerId && (
              <Grid
                gridColumns={12}
                align={ALIGNMENT.center}
              >
                <AppCheckbox
                  name={t('employees:fuegoPaycardOption.label')}
                  label={t('employees:fuegoPaycardOption.label')}
                  cellSpan={12}
                  context={EmployeeProfileFormContext}
                  checkboxProps={{
                    checked: paycardOffer,
                    onChange: handlePaycardToggleSwitch,
                    overrides: paycardOffer ? {
                      Toggle: {
                        style: {
                          backgroundColor: colors.primary,
                        },
                      },
                    } : {},
                  }}
                />

                <Cell span={[12, 12, 8.5]}>
                  <ParagraphMedium
                    overrides={{
                      Block: {
                        style: {
                          backgroundColor: 'rgb(238, 243, 254)',
                          padding: '1rem',
                          borderRadius,
                          'white-space': 'normal',
                        },
                      },

                    }}
                  >
                    {t('employees:fuegoPaycardOption.description')}
                  </ParagraphMedium>
                </Cell>
              </Grid>
            )}

            <br />
            <hr />
            <Block
              marginTop="24px"
            >
              <Grid
                gridColumns={12}
                align={ALIGNMENT.end}
              >
                <Cell
                  span={9}
                  align={ALIGNMENT.end}
                />
                <Cell
                  span={3}
                  align={ALIGNMENT.end}
                >
                  <Block
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Block
                      display="inline-flex"
                      marginRight="16px"
                    >
                      <Button
                        type="button"
                        kind={KIND.secondary}
                        onClick={handleClickCancel}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: 'EmployeeDetailsProfileSection-cancel',
                            },
                          },
                        }}
                      >
                        {t('common:cancel')}
                      </Button>
                    </Block>
                    <Button
                      type="submit"
                      kind={KIND.primary}
                      disabled={isSubmitting || !isFormChanged || !isValid}
                      overrides={{
                        Root: {
                          props: {
                            id: 'EmployeeDetailsProfileSection-save',
                          },
                        },
                      }}
                    >
                      {t('common:save')}
                    </Button>
                  </Block>
                </Cell>
              </Grid>
            </Block>
          </form>
        </div>
      </AccordionSection>
    </EmployeeProfileFormContext.Provider>
  );
};

export default memo(EmployeeDetailsProfileSection);
