/**
 * Removes underlines from text and replaces them with spaces
 *
 * @param text text to remove underlines from
 * @returns text with replaced by underlines
 */
const removeUnderlinesFromText = (text: string) => text.replace(/_/g, ' ');

// TODO: remove the default export when they are more than one exports in the file
export default removeUnderlinesFromText;
