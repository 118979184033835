import { useState, useEffect } from 'react';

export const useIdempotecyKey = () => {
  const [key, setKey] = useState('');

  useEffect(() => {
    setKey(crypto.randomUUID());

    return () => {
      setKey('');
    };
  }, []);
  return key;
};

export default useIdempotecyKey;
