import { EmploymentIDType } from 'types/EmployeeTypes';
import { LocationIDType } from 'types/LocationTypes';
import { OrganizationIDType } from 'types/OrganizationTypes';
import { PayrollPeriodIDType } from 'types/PayGroupTypes';
import request from './request';

export const fetchEmployeesTPOByPayPeriodRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
  payrollPeriodID: PayrollPeriodIDType,
  pageNumber: string,
  statuses: string,
  employmentStatuses: string,
  name: string,
) => {
  const queryParams = new URLSearchParams({
    pageNumber,
  });

  if (statuses && statuses.length > 0) {
    queryParams.set('statuses', statuses);
  }
  if (employmentStatuses && employmentStatuses.length > 0) {
    queryParams.set('employmentStatuses', employmentStatuses);
  }
  if (name) {
    queryParams.set('name', name);
  }

  return request({
    token,
    method: 'GET',
    url: `/organizations/${organizationID}/locations/${locationID}/payroll-periods/${payrollPeriodID}/tipped-employments?${queryParams.toString()}`,
  });
};

export const fetchEmployeesTPOByBusinessDateRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
  businessDate: string,
  pageNumber: string,
  firstName?: string,
  lastName?: string,
  payrollId?: string,
  statuses?: string,
  namePattern?: string,
) => {
  const queryParams = new URLSearchParams({
    businessDate,
    pageNumber,
  });

  if (namePattern) {
    queryParams.set('namePattern', namePattern);
  }
  if (firstName) {
    queryParams.set('firstName', firstName);
  }
  if (lastName) {
    queryParams.set('lastName', lastName);
  }
  if (payrollId) {
    queryParams.set('payrollId', payrollId);
  }
  if (statuses) {
    queryParams.set('statuses', statuses);
  }

  return request({
    token,
    method: 'GET',
    url: `/organizations/${organizationID}/locations/${locationID}/employee-tips?${queryParams.toString()}`,
  });
};

export const generateTPODailyFileRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
  businessDate: string,
  statuses?: string,
  onlyThirdParty?: boolean,
) => {
  const queryParams = new URLSearchParams({
    businessDate,
  });

  if (statuses) {
    queryParams.set('statuses', statuses);
  }

  if (onlyThirdParty) {
    queryParams.set('onlyThirdParty', onlyThirdParty.toString());
  }

  return request({
    token,
    method: 'GET',
    url: `/organizations/${organizationID}/locations/${locationID}/employee-tips-csv?${queryParams.toString()}`,
  });
};

export const initiateTPOPaymentsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
  payrollPeriodID: PayrollPeriodIDType,
  employmentIds?: string,
) => {
  const queryParams = new URLSearchParams({});

  if (employmentIds) {
    queryParams.set('employmentIds', employmentIds);
  }

  return request({
    token,
    method: 'POST',
    // eslint-disable-next-line max-len
    url: `/organizations/${organizationID}/locations/${locationID}/payroll-periods/${payrollPeriodID}/tco-payments-tasks?${queryParams.toString().toString().replace(/%2C/g, ',')}`,
  });
};

export const fetchEmployeeTPOPaymentsRequest = (
  token: string,
  employeeID: EmploymentIDType,
  locationID: LocationIDType,
  payrollPeriodID: PayrollPeriodIDType,
) => request({
  token,
  method: 'GET',
  url: `/employments/${employeeID}/locations/${locationID}/payroll-periods/${payrollPeriodID}/tco-payments`,
});

export const generateTPOPayrollFileRequest = (
  token: string,
  payrollPeriodID: PayrollPeriodIDType,
  locationID?: LocationIDType,
) => {
  if (locationID) {
    return request({
      token,
      method: 'GET',
      url: `/payroll-periods/${payrollPeriodID}/tips-snapshot-report?locationId=${locationID}`,
    });
  }
  return request({
    token,
    method: 'GET',
    url: `/payroll-periods/${payrollPeriodID}/tips-snapshot-report`,
  });
};
