import moment from 'moment';
import {
  OrganizationIDType,
} from 'types/OrganizationTypes';
import {
  SavePayGroupRequestType,
  PayGroupValuesType,
  PayGroupResponseType,
} from 'types/PayGroupTypes';

export const payGroupMapper = ({
  id,
  name,
  businessDayStartTime,
  periodStartDay,
  paydayOffset,
  payrollCutoffDayOffset,
  payFrequencyRule,
  ewaCutoffTime,
  tcoCutoffTime,
  payrollCutoffTime,
  deductionCode,
  payrollCutoffTimezone,
  batchDeliveryEmailAddresses,
  automaticPayrollPeriodGenerationEnabled,
  ewaCutoffDayOffset,
  tcoCutoffDayOffset,
  externalIntegrationCode,
  bankAccount,
}: PayGroupResponseType): PayGroupValuesType => ({
  id,
  deductionCode,
  payGroupClientID: '',
  payGroupName: name,
  businessDayStartTime: moment(businessDayStartTime, 'HH:mm:ss').toDate(),
  ewaCutoffTime: moment(ewaCutoffTime, 'HH:mm:ss').toDate(),
  tpoCutoffTime: moment(tcoCutoffTime, 'HH:mm:ss').toDate(),
  payrollCutoffTime: moment(payrollCutoffTime, 'HH:mm:ss').toDate(),
  organizationTimezone: payrollCutoffTimezone,
  payCycle: [{ value: payFrequencyRule.toString() }],
  payrollPeriodStartDay: [{ value: periodStartDay.toString() }],
  paydayOffset: [{ value: paydayOffset }],
  payrollCutoffDayOffset: [{ value: payrollCutoffDayOffset }],
  batchDeliveryEmailAddresses: batchDeliveryEmailAddresses?.join(', ') || '',
  automaticPayrollPeriodGenerationEnabled: !automaticPayrollPeriodGenerationEnabled,
  ewaCutoffDayOffset: [{ value: ewaCutoffDayOffset }],
  tpoCutoffDayOffset: [{ value: tcoCutoffDayOffset }],
  externalIntegrationCode,
  bankAccount: bankAccount || {
    bankAccountId: null,
    bankAccountAlias: null,
  },
});

export const savePayGroupMapper = (
  organizationID: OrganizationIDType,
  values: PayGroupValuesType,
): SavePayGroupRequestType => ({
  id: Number(values.id),
  organizationId: Number(organizationID),
  payFrequencyRule: values.payCycle[0].value,
  defaultForOrganization: false,
  name: values.payGroupName,
  deductionCode: values.deductionCode || null,
  businessDayStartTime: moment(values.businessDayStartTime).format('HH:mm:ss'),
  payrollCutoffDayOffset: values.payrollCutoffDayOffset[0].value,
  ewaCutoffTime: moment(values.ewaCutoffTime).format('HH:mm:ss'),
  tcoCutoffTime: moment(values.tpoCutoffTime).format('HH:mm:ss'),
  payrollCutoffTime: moment(values.payrollCutoffTime).format('HH:mm:ss'),
  payrollCutoffTimezone: values.organizationTimezone,
  periodStartDay: values.payrollPeriodStartDay[0].value.toUpperCase(),
  paydayOffset: values.paydayOffset[0].value,
  batchDeliveryEmailAddresses: values.batchDeliveryEmailAddresses ? values.batchDeliveryEmailAddresses.split(/,\s?/) : [],
  automaticPayrollPeriodGenerationEnabled: !values.automaticPayrollPeriodGenerationEnabled,
  ewaCutoffDayOffset: values.ewaCutoffDayOffset[0].value,
  tcoCutoffDayOffset: values.tpoCutoffDayOffset[0].value,
  externalIntegrationCode: values.externalIntegrationCode || null,
  bankAccount: values.bankAccount,
});
