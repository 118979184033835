import {
  useEffect,
  createContext,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, KIND } from 'baseui/button';
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { profileInitialValues } from 'initialValues/OrganizationInitialValues';
import { profileValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  createOrganization,
  defaultConfigSelector,
  editOrganization,
  organizationConfigSelector,
  organizationSelector,
  organizationsPendingSelector,
} from 'store/slices/organizations';
import { EWASettingsValuesType, ProfileValuesType } from 'types/OrganizationTypes';
import {
  saveOrganizationMapper,
  saveOrganizationConfigsMapper,
  organizationMapper,
  defaultConfigMapper,
  organizationConfigMapper,
  dayOffsetsMapper,
  daysOfWeek,
} from 'dataMappers/organizationsDataMappers';
import AppInput from 'components/Form/AppInput';
import AppTimePicker from 'components/Form/AppTimePicker';
import AppTimezonePicker from 'components/Form/AppTimezonePicker';
import AppSelect from 'components/Form/AppSelect';
import {
  resetOrganizationEvent,
  resetOrganizationPaycardEvent,
} from 'store/events';
import Loader from 'components/Loader';
import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import { Block } from 'baseui/block';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { FormControl } from 'baseui/form-control';
import { emptyPlaceholder } from 'theme';
import AppCheckbox from 'components/Form/AppCheckbox';
import messages from 'validation/messages';
import AppTextarea from 'components/Form/AppTextarea';

export const ProfileFormContext = createContext(
  {} as FormikState<ProfileValuesType & Partial<EWASettingsValuesType>> & FormikHelpers<ProfileValuesType> & FormikHandlers,
);

const containerStyles = {
  position: 'relative',
} as StyleObject;

const OrganizationFormProfileSection = () => {
  const history = useHistory();
  const { t } = useTranslation(['organizations', 'errors', 'common', 'locations']);
  const [css] = useStyletron();
  const dispatch = useAppDispatch();
  const organization = useAppSelector(organizationSelector);
  const pending = useAppSelector(organizationsPendingSelector);
  const defaultConfig = useAppSelector(defaultConfigSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const initialValues = {
    ...profileInitialValues,
    ...(defaultConfig && defaultConfigMapper(defaultConfig)),
    ...(organizationConfig && organizationConfigMapper(organizationConfig)),
  };

  const onSubmit = (
    values: ProfileValuesType,
  ) => dispatch(
    organization?.id
      ? editOrganization({
        organizationID: organization?.id,
        data: saveOrganizationMapper(values, organization?.id),
        configs: saveOrganizationConfigsMapper(values),
      })
      : createOrganization({
        data: saveOrganizationMapper(values),
        configs: saveOrganizationConfigsMapper(values),
      }),
  );

  const onSubmitExtended = (
    props: ProfileValuesType,
  ) => onSubmit(props);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmitExtended,
    isInitialValid: false,
  });

  const programStatuses = [
    { value: 'ACTIVE', label: t('organizations:statuses.active') },
    { value: 'INACTIVE', label: t('organizations:statuses.inactive') },
    { value: 'TERMINATED', label: t('organizations:statuses.terminated') },
    { value: 'PENDING', label: t('organizations:statuses.pending') },
  ];

  const offerCalculations = [
    { value: 'BY_EXTERNAL_EMPLOYEE_ID', label: t('organizations:offerModes.byExtId') },
    { value: 'BY_PAYROLL_ID', label: t('organizations:offerModes.byPayrollId') },
  ];

  const deductionsCsvFormats = [
    { value: 'DEFAULT', label: t('organizations:deductionFormats.default') },
    { value: 'PAYCOM', label: t('organizations:deductionFormats.paycom') },
    { value: 'PRISMHR', label: t('organizations:deductionFormats.prismHR') },
    { value: 'PAYCOR', label: t('organizations:deductionFormats.paycor') },
    { value: 'PAYLOCITY', label: t('organizations:deductionFormats.paylocity') },
  ];

  const deductionShortfallSystem = [
    { value: 'FUEGO', label: t('organizations:deductionShortfallSystem.fuego') },
    { value: 'PAYROLL', label: t('organizations:deductionShortfallSystem.payroll') },
  ];

  const employmentDataSources = [
    { value: 'HOTSCHEDULES', label: t('organizations:employmentDataSource.hotSchedules') },
    { value: 'FOURTH', label: t('organizations:employmentDataSource.fourth') },
    { value: 'POS_PLATFORM', label: t('organizations:employmentDataSource.posPlatform') },
    { value: 'ADMIN_API', label: t('employmentDataSource.adminAPI') },
  ];

  const tnaDataSources = [
    { value: 'HOTSCHEDULES', label: t('organizations:tnaDataSource.hotSchedules') },
    { value: 'POS_PLATFORM', label: t('organizations:tnaDataSource.posPlatform') },
  ];

  const tnaDataImports = [
    { value: 'HOTSCHEDULES', label: t('organizations:tnaDataImport.hotSchedules') },
    { value: 'POS_PLATFORM', label: t('organizations:tnaDataImport.posPlatform') },
  ];

  const dayOffsets = dayOffsetsMapper(t, 30);

  const handleClickCancel = () => {
    history.push('/organizations');
  };

  const {
    values,
    handleSubmit,
    isSubmitting,
    setValues,
    isValid,
    setErrors,
    errors,
  } = formik;

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  useEffect(() => {
    dispatch(resetOrganizationEvent());
    dispatch(resetOrganizationPaycardEvent());

    return () => {
      dispatch(resetOrganizationEvent());
      dispatch(resetOrganizationPaycardEvent());
    };
  }, []);

  useEffect(() => {
    if ((organization?.id && organization?.name) || organizationConfig) {
      const convertedValues = {
        ...values,
        ...(organization && organizationMapper(organization)),
        ...(organizationConfig && organizationConfigMapper(organizationConfig)),
      };

      setValues(convertedValues);
      setDefaultValues(convertedValues);
    }
  }, [organization, organizationConfig]);

  useEffect(() => {
    if (
      values.automaticBatchGeneration && values.batchDeliveryEmail && values.organizationBatchDeliveryEmailAddresses === ''
    ) {
      setErrors({
        ...errors,
        organizationBatchDeliveryEmailAddresses: messages?.required,
      });
    }
  }, [values, errors]);

  return (
    <ProfileFormContext.Provider value={formik}>
      <div className={css(containerStyles)}>
        <Loader active={pending} />

        <form onSubmit={handleSubmit}>
          <Grid
            align={ALIGNMENT.center}
            gridColumns={12}
            gridGutters={16}
          >
            <Cell
              span={[12, 3]}
              align={ALIGNMENT.start}
            >
              <FormControl
                label={t('organizations:organizationId')}
                overrides={{
                  Label: {
                    style: {
                      lineHeight: '20px',
                    },
                  },
                  ControlContainer: {
                    props: {
                      'data-testid': 'FormControl-organization-profile-id',
                      id: 'FormControl-organization-profile-id',
                    },
                  },
                }}
              >
                <Block
                  marginTop="16px"
                >
                  {organization?.id ? organization?.id : emptyPlaceholder}
                </Block>
              </FormControl>
            </Cell>
            <Cell
              span={[12, 3]}
              align={ALIGNMENT.start}
            >
              <FormControl
                label={t('organizations:organizationInvitationCode')}
                overrides={{
                  Label: {
                    style: {
                      lineHeight: '20px',
                    },
                  },
                  ControlContainer: {
                    props: {
                      'data-testid': 'FormControl-organization-profile-invitation-code',
                      id: 'FormControl-organization-profile-invitation-code',
                    },
                  },
                }}
              >
                <Block
                  marginTop="16px"
                >
                  {organization?.invitationCode ? organization?.invitationCode : emptyPlaceholder}
                </Block>
              </FormControl>
            </Cell>
            <AppSelect
              showStar
              name="programStatus"
              label={t('organizations:programStatus')}
              formControlProps={{
                htmlFor: 'programStatus',
              }}
              cellSpan={[6, 3]}
              context={ProfileFormContext}
              options={organization?.id ? programStatuses : programStatuses.filter((i) => i.value !== 'TERMINATED')}
            />
            <AppCheckbox
              name="hiddenForWorkers"
              label={t('organizations:disableRegistrations')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
              cellAlign={ALIGNMENT.center}
              tooltipTitle={t('organizations:disableRegistrations')}
              tooltipContent={t('organizations:disableRegistrationsTooltip')}
              marginTop="24px"
            />
          </Grid>

          <Grid
            align={ALIGNMENT.center}
            gridColumns={12}
            gridGutters={16}
          >
            <AppSelect
              showStar
              name="employmentDataSource"
              label={t('organizations:employmentDataSource')}
              formControlProps={{
                htmlFor: 'employmentDataSource',
              }}
              cellSpan={[6, 3]}
              context={ProfileFormContext}
              options={employmentDataSources}
            />
            <AppSelect
              showStar
              name="tnaDataSource"
              label={t('organizations:tnaDataSource')}
              formControlProps={{
                htmlFor: 'tnaDataSource',
              }}
              cellSpan={[6, 3]}
              context={ProfileFormContext}
              options={tnaDataSources}
            />
            <AppSelect
              showStar
              name="tnaDataImport"
              label={t('organizations:tnaDataImport')}
              formControlProps={{
                htmlFor: 'tnaDataImport',
              }}
              cellSpan={[6, 3]}
              context={ProfileFormContext}
              options={tnaDataImports}
            />
            <AppSelect
              showStar
              name="offerCalculationMode"
              label={t('organizations:offerCalculationMode')}
              formControlProps={{
                htmlFor: 'offerCalculationMode',
              }}
              cellSpan={[6, 3]}
              context={ProfileFormContext}
              options={offerCalculations}
            />
          </Grid>

          <Grid
            align={ALIGNMENT.start}
            gridColumns={12}
          >
            <Cell
              span={[12, 6, 3]}
              align={ALIGNMENT.center}
            >
              <h3>{t('organizations:profile.labelBatchManagement')}</h3>
            </Cell>
          </Grid>

          <Grid
            align={ALIGNMENT.center}
            gridColumns={12}
            gridGutters={16}
          >
            <AppSelect
              showStar
              name="deductionsCsvFormat"
              label={t('organizations:deductionsCsvFormat')}
              formControlProps={{
                htmlFor: 'deductionsCsvFormat',
              }}
              cellSpan={[6, 3, 3]}
              context={ProfileFormContext}
              options={deductionsCsvFormats}
            />
            <AppSelect
              showStar
              name="deductionShortfallSystem"
              label={t('organizations:deductionShortfallSystem')}
              formControlProps={{
                htmlFor: 'deductionShortfallSystem',
              }}
              cellSpan={[6, 3, 3]}
              context={ProfileFormContext}
              options={deductionShortfallSystem}
            />
            <Block>
              <AppCheckbox
                name="automaticBatchGeneration"
                label={t('organizations:labelAutomaticBatchGeneration')}
                cellSpan={[12, 9, 3]}
                context={ProfileFormContext}
                cellAlign={ALIGNMENT.center}
                cellProps={{
                  overrides: {
                    Cell: {
                      style: {
                        height: '110px',
                        display: 'grid !important',
                        '@media screen and (max-width: 768px)': {
                          height: 'unset',
                        },
                      },
                    },
                  },
                }}
              />
            </Block>
          </Grid>

          <Grid
            align={ALIGNMENT.center}
            gridColumns={12}
            gridGutters={16}
          >
            <AppCheckbox
              name="batchDeliveryManual"
              label={t('organizations:labelBatchDeliveryManual')}
              cellSpan={[12, 6, 3]}
              disabled
              context={ProfileFormContext}
              cellAlign={ALIGNMENT.center}
            />
            {values.automaticBatchGeneration && (
              <AppCheckbox
                name="batchDeliveryEmail"
                label={t('organizations:labelBatchDeliveryEmail')}
                cellSpan={[12, 6, 3]}
                context={ProfileFormContext}
                cellAlign={ALIGNMENT.center}
              />
            )}

            {values.batchDeliveryEmail && values.automaticBatchGeneration && (
              <AppTextarea
                showStar
                name="organizationBatchDeliveryEmailAddresses"
                textareaProps={{
                  id: 'batchDeliveryEmailAddresses',
                  autoComplete: 'off',
                }}
                placeholder="john.doe@example.org, doe.john@example.com"
                label={t('organizations:labelBatchDeliveryEmailAddresses')}
                cellSpan={[12, 12, 6]}
                context={ProfileFormContext}
                cellProps={{
                  overrides: {
                    Cell: {
                      style: {
                        '@media screen and (min-width: 768px)': {
                          height: '100px',
                          display: 'grid !important',
                        },
                      },
                    },
                  },
                }}
              />

            )}
          </Grid>
          <Grid
            align={ALIGNMENT.center}
            gridColumns={12}
            gridGutters={16}
          >
            <Block>
              <AppCheckbox
                name="estimatedPay"
                label={t('organizations:labelEstimatedPay')}
                cellSpan={[12, 9, 3]}
                context={ProfileFormContext}
                cellAlign={ALIGNMENT.center}
              />
            </Block>
          </Grid>

          <Grid
            align={ALIGNMENT.start}
            gridColumns={12}
          >
            <Cell
              span={[12, 6, 3]}
              align={ALIGNMENT.center}
              overrides={{
                Cell: {
                  style: {
                    '@media screen and (min-width: 768px)': {
                      marginTop: '50px',
                    },
                  },
                },
              }}
            >
              <h3>{t('common:details')}</h3>
            </Cell>
          </Grid>

          <Grid
            align={ALIGNMENT.start}
            gridGutters={16}
            gridColumns={12}
          >
            <AppInput
              showStar
              name="name"
              inputProps={{
                id: 'name',
                autoComplete: 'off',
              }}
              formControlProps={{
                htmlFor: 'name',
              }}
              label={t('organizations:labelName')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
            />

            <AppInput
              tooltipTitle={t('organizations:organizationID.tooltip.title')}
              tooltipContent={t('organizations:organizationID.tooltip.content')}
              name="externalId"
              inputProps={{
                id: 'externalId',
                autoComplete: 'off',
              }}
              formControlProps={{
                htmlFor: 'externalId',
              }}
              label={t('organizations:labelOrganizationID')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
            />

            <AppInput
              name="salesForceId"
              inputProps={{
                id: 'salesForceId',
                autoComplete: 'off',
              }}
              formControlProps={{
                htmlFor: 'salesForceId',
              }}
              label={t('organizations:labelOrganizationSalesforceID')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
            />

            <AppInput
              name="netSuiteId"
              inputProps={{
                id: 'netSuiteId',
                autoComplete: 'off',
              }}
              formControlProps={{
                htmlFor: 'netSuiteId',
              }}
              label={t('organizations:labelOrganizationNetSuiteID')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
            />
          </Grid>

          <Grid
            align={ALIGNMENT.start}
            gridGutters={16}
            gridColumns={12}
          >
            <AppInput
              name="externalIntegrationId"
              inputProps={{
                id: 'externalIntegrationId',
                autoComplete: 'off',
              }}
              formControlProps={{
                htmlFor: 'externalIntegrationId',
              }}
              label={t('organizations:labelOrganizationExternalIntegrationId')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
            />
            <AppInput
              name="externalPayrollSystemId"
              inputProps={{
                id: 'externalPayrollSystemId',
                autoComplete: 'off',
              }}
              formControlProps={{
                htmlFor: 'externalPayrollSystemId',
              }}
              label={t('organizations:labelOrganizationExternalPayrollSystemId')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
            />
            <AppInput
              tooltipTitle={t('organizations:deductionCode.tooltip.title')}
              tooltipContent={t('organizations:deductionCode.tooltip.content')}
              name="deductionCode"
              inputProps={{
                id: 'deductionCode',
                autoComplete: 'off',
              }}
              formControlProps={{
                htmlFor: 'deductionCode',
              }}
              label={t('organizations:labelOrganizationdeDuctionCode')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
            />
            <AppSelect
              showStar
              tooltipTitle={t('organizations:payrollPeriodStartDay.tooltip.title')}
              tooltipContent={t('organizations:payrollPeriodStartDay.tooltip.content')}
              name="payrollPeriodStartDay"
              label={t('organizations:payGroups.payrollPeriodStartDay.label')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
              options={daysOfWeek(t)}
              selectProps={{
                clearable: false,
              }}
            />

            <AppSelect
              showStar
              tooltipTitle={t('organizations:paydayOffset.tooltip.title')}
              tooltipContent={t('organizations:paydayOffset.tooltip.content')}
              name="paydayOffset"
              key="org-profile-pd-offset"
              label={t('organizations:payGroups.paydayOffset.label')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
              options={dayOffsets}
              selectProps={{
                clearable: false,
              }}
            />

            <AppTimePicker
              showStar
              tooltipTitle={t('organizations:payrollCutoffTime.tooltip.title')}
              tooltipContent={t('organizations:payrollCutoffTime.tooltip.content')}
              name="payrollCutoffTime"
              label={t('organizations:labelPayrollCutoffTime')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
            />

            <AppTimezonePicker
              showStar
              tooltipTitle={t('organizations:timezone.tooltip.title')}
              tooltipContent={t('organizations:timezone.tooltip.content')}
              name="organizationTimezone"
              label={t('common:labelTimezone')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
            />

            <AppTimePicker
              showStar
              tooltipTitle={t('organizations:businessDayStartTime.tooltip.title')}
              tooltipContent={t('organizations:businessDayStartTime.tooltip.content')}
              name="businessDayStartTime"
              label={t('organizations:profile.businessDayStartTime.label')}
              cellSpan={[12, 6, 3]}
              context={ProfileFormContext}
              maxDropdownHeight="280px"
            />
          </Grid>
          <hr />

          <Block marginTop="24px">
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <Cell
                span={9}
                align={ALIGNMENT.end}
              />

              <Cell
                span={3}
                align={ALIGNMENT.end}
              >
                <Block
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Block
                    display="inline-flex"
                    marginRight="16px"
                  >
                    <Button
                      type="button"
                      kind={KIND.secondary}
                      onClick={handleClickCancel}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: 'OrganizationFormProfileSection-cancel',
                          },
                        },
                      }}
                    >
                      {t('common:cancel')}
                    </Button>
                  </Block>

                  <Button
                    type="submit"
                    kind={KIND.primary}
                    disabled={isSubmitting || !isFormChanged || !isValid}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'OrganizationFormProfileSection-save',
                        },
                      },
                    }}
                  >
                    {t('common:save')}
                  </Button>
                </Block>
              </Cell>
            </Grid>
          </Block>
        </form>
      </div>
    </ProfileFormContext.Provider>
  );
};

export default memo(OrganizationFormProfileSection);
