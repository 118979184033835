import { ChangeEvent, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { Input, SIZE } from 'baseui/input';
import { Checkbox } from 'baseui/checkbox';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import PriceFormatter from 'utils/priceFormatter';
import { currencyCodeSelector } from 'store/slices/application';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  checkboxOverrides,
  outerContainerStyles,
} from 'screens/Batches/BatchesHelpers';
import {
  SelectedEmploymentDraw,
  selectedEmploymentDrawsSelector,
  setSelectedEmploymentDraws,
} from 'store/slices/paymentAllocation';
import { scalePrice, unScalePrice } from 'utils/priceScale';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DrawAllocationType } from 'types/RepaymentTypes';
import { InputCustomHTMLElement } from 'types/CommonTypes';

export type PaymentAllocationDrawListItemPropsType = {
  draw: DrawAllocationType
}

const PaymentAllocationDrawListItem = ({
  draw,
}: PaymentAllocationDrawListItemPropsType) => {
  const {
    id: drawID,
    allocatedAmount,
    totalAmount,
    amountDue,
    createdDate,
  } = draw || {};

  const [css] = useStyletron();
  const { t } = useTranslation(['dateFormats']);
  const dispatch = useAppDispatch();
  const currencyCode = useAppSelector(currencyCodeSelector);
  const selectedEmploymentDraws = useAppSelector(selectedEmploymentDrawsSelector);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isChecked = !!selectedEmploymentDraws?.find((item) => item?.drawID?.toString() === drawID?.toString());
  const dateFormat = t('dateFormats:standard-with-time');
  const currentDraw = selectedEmploymentDraws?.find((item) => item?.drawID?.toString() === drawID?.toString());
  const unscaledTotalAmount = unScalePrice(totalAmount?.value, totalAmount?.scale);
  const unscaledAllocatedAmount = unScalePrice(allocatedAmount?.value, allocatedAmount?.scale);
  const unscaledAmountDue = unScalePrice(amountDue?.value, amountDue?.scale);
  const unscaledCurrentDrawAmount = currentDraw && unScalePrice(currentDraw?.amount?.value, currentDraw?.amount?.scale);
  const allocateValue = unscaledCurrentDrawAmount !== undefined ? unscaledCurrentDrawAmount : unscaledAmountDue;

  const handleDrawAllocationCheckboxChanged = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation();
    const { checked } = e.currentTarget;

    dispatch(
      setSelectedEmploymentDraws(
        checked
          ? [...selectedEmploymentDraws, {
            drawID,
            amount: amountDue,
          }]
          : [...selectedEmploymentDraws.filter((item) => item?.drawID?.toString() !== drawID?.toString())],
      ),
    );
  };

  const updateDraw = (data: SelectedEmploymentDraw) => {
    dispatch(
      setSelectedEmploymentDraws(
        [
          ...selectedEmploymentDraws.filter((item) => item.drawID?.toString() !== drawID?.toString()),
          data,
        ],
      ),
    );
  };

  const handleChangeAllocatedValue = (
    e: ChangeEvent<InputCustomHTMLElement>,
  ) => {
    e.stopPropagation();

    const { value } = e.currentTarget;

    updateDraw({
      drawID,
      amount: {
        value: scalePrice(Number(value), amountDue?.scale),
        scale: amountDue?.scale,
        currencyCode: amountDue?.currencyCode,
      },
    });
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleBlurAllocatedValue = (
    e: ChangeEvent<InputCustomHTMLElement>,
  ) => {
    e.stopPropagation();

    allocateValue > unscaledTotalAmount && updateDraw({
      drawID,
      amount: amountDue,
    });

    setIsEditing(false);
  };

  return (
    <div className={css(outerContainerStyles)}>
      <Grid
        gridColumns={12}
        gridMargins={0}
      >
        <Cell
          align={ALIGNMENT.center}
          span={4}
        >
          {moment(createdDate).format(dateFormat)}
        </Cell>

        <Cell
          span={2}
          align={ALIGNMENT.center}
        >
          {PriceFormatter().format(unscaledTotalAmount)}
        </Cell>

        <Cell
          span={3}
          align={ALIGNMENT.center}
        >
          {PriceFormatter().format(unscaledAllocatedAmount)}
        </Cell>

        <Cell
          span={0.5}
          align={ALIGNMENT.center}
        >
          <Checkbox
            checked={isChecked}
            name={drawID.toString()}
            overrides={{
              Root: {
                ...checkboxOverrides.Root,
                props: {
                  id: 'payment-allocation-draw-checkbox',
                },
              },
              Input: {
                props: {
                  'aria-checked': `${isChecked}`,
                },
              },
            }}
            onChange={handleDrawAllocationCheckboxChanged}
          />
        </Cell>

        <Cell
          align={ALIGNMENT.center}
          span={2.5}
        >
          <Input
            size={SIZE.compact}
            error={allocateValue > unscaledTotalAmount}
            disabled={!isChecked}
            placeholder="00.00"
            type="number"
            autoComplete="off"
            min={0}
            id="payment-allocationo-draw-amount"
            max={unscaledAmountDue}
            endEnhancer={currencyCode}
            value={isEditing ? allocateValue : allocateValue.toFixed(2)}
            onChange={handleChangeAllocatedValue}
            onBlur={handleBlurAllocatedValue}
            onFocus={handleFocus}
          />
        </Cell>
      </Grid>
    </div>
  );
};

export default PaymentAllocationDrawListItem;
