import { Button, KIND } from 'baseui/button';
import {
  ChangeEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

export type AppFileUploadButtonPropsType = {
  handleFileSelected: (file: File) => void
  children: ReactNode
};

const AppFileUploadButton = ({
  handleFileSelected,
  children,
}: AppFileUploadButtonPropsType) => {
  const hiddenFileInput = useRef(null);
  const [fileUploaded, setFileUploaded] = useState<File | undefined>(undefined);
  const [randomKey, setRandomKey] = useState(Math.random().toString(36));

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event.target.files[0]) {
      setFileUploaded(event.target.files[0]);
    }
  };

  const handleClick = () => {
    // @ts-ignore-next-line
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    if (fileUploaded) {
      handleFileSelected(fileUploaded);
      setRandomKey(Math.random().toString(36));
    }
  }, [fileUploaded, hiddenFileInput]);

  return (
    <>
      <Button
        type="button"
        kind={KIND.tertiary}
        onClick={handleClick}
        overrides={{
          Root: {
            props: {
              id: 'OrganizationFormLocationsSection-import-btn',
            },
          },
        }}
      >
        {children}
      </Button>

      <input
        key={randomKey}
        type="file"
        id="upload-button"
        data-testid="upload-button"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default AppFileUploadButton;
