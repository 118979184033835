import * as Yup from 'yup';
import messages from './messages';

const profileValidationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email(messages?.email),
  firstName: Yup.string().required('First Name is required').matches(/^[a-zA-Z ]+$/, messages?.name),
  lastName: Yup.string().required('Last Name is required').matches(/^[a-zA-Z ]+$/, messages?.name),
  password: Yup.string()
    .required('Password is required')
    .matches(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\\s).{8,30}$/, messages?.password),
  repeatPassword: Yup.string()
    .required('Repeat Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  mobilePhone: Yup.string()
    .matches(/^\d{10}$/, messages?.mobilePhone),
});

// TODO: remove default export once we have more than one declaration to export
export default profileValidationSchema;
