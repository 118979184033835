import { memo, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { FormControl } from 'baseui/form-control';
import { Button, KIND } from 'baseui/button';
import {
  OnChangeParams,
  Select,
  SIZE,
  Value,
} from 'baseui/select';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  organizationsSelector,
  fetchAllOrganizations,
  organizationsPendingListSelector,
  maintenanceSelector,
} from 'store/slices/organizations';
import { setLoggedOrganization } from 'store/slices/loggedOrganization';
import { setPrevPage } from 'store/slices/application';
import Access, { AccessUnit } from 'components/Access/Access';
import { resetOrganizationEvent } from 'store/events';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { Block } from 'baseui/block';
import { logout } from 'store/slices/user';
import { colors } from 'theme';
import {
  chooseOrganizationContainerStyles,
  chooseOrganizationMainDivStyles,
  formOverride,
  newOrganizationButtonOverrides,
  organizationContinueButtonOverrides,
} from './ChooseOrganizationHelpers';

const logoContainerStyles = {
  width: '100%',
  'text-align': 'center',
  marginBottom: '38px',
};

const logoutButtonOverrides = {
  Root: {
    props: {
      id: 'ChooseOrganization-log-out',
    },
    style: {
      color: colors.primary,
      width: '100%',
    },
  },
};

const ChooseOrganization = () => {
  const [css] = useStyletron();
  const history = useHistory();
  const shouldGoIntoMaintenance = useAppSelector(maintenanceSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['organizations', 'errors', 'common', 'menu']);
  const { instance } = useMsal();
  const organizations = useAppSelector(organizationsSelector);
  const pending = useAppSelector(organizationsPendingListSelector);
  const [selectedOrganizationValue, setSelectedOrganizationValue] = useState<Value>([]);

  const handleOrganizationSelectChange = ({ value }: OnChangeParams) => {
    setSelectedOrganizationValue(value);
  };

  const handleContinueBtnClick = () => {
    dispatch(setLoggedOrganization({
      name: selectedOrganizationValue[0].name,
      id: selectedOrganizationValue[0].id,
    }));
    history.push('/loggedOrganization/employees');
  };

  const handleSetupNewCustomerBtnClick = () => {
    dispatch(resetOrganizationEvent());
    dispatch(setPrevPage('/choose-organization'));
    history.push('/organizations/create');
  };

  const handleLogoutButtonClick = () => {
    dispatch(logout(instance));
  };

  useEffect(() => {
    dispatch(fetchAllOrganizations({ pageSize: '1000' }));
    dispatch(setPrevPage(''));
  }, []);
  useEffect(() => {
    if (shouldGoIntoMaintenance) history.push('/maintenance');
  }, [shouldGoIntoMaintenance]);

  return (
    <div className={css(chooseOrganizationContainerStyles)}>
      <div className={css(chooseOrganizationMainDivStyles)}>
        <div className={css(logoContainerStyles)}>
          <Logo />
        </div>

        <Block marginBottom="36px">
          <FormControl
            label={t('organizations:chooseOrganization')}
            overrides={formOverride}
          >
            <Select
              size={SIZE.compact}
              isLoading={pending}
              disabled={pending}
              maxDropdownHeight="280px"
              type="select"
              overrides={{
                ValueContainer: {
                  props: {
                    'data-testid': 'Form-select-value-choose-organization',
                    id: 'Form-select-value-choose-organization',
                  },
                },
                ControlContainer: {
                  props: {
                    'data-testid': 'Form-select-control-choose-organization',
                    id: 'Form-select-control-choose-organization',
                  },
                },
                Input: {
                  props: {
                    id: 'Form-select-input-choose-organization',
                    name: 'Form-select-input-name-choose-organization',
                    autoComplete: 'off',
                  },
                },
              }}
              clearable={false}
              options={
                organizations
                  ?.map((org) => ({
                    name: org.name,
                    id: org.id,
                  }))
                  ?.sort((a, b) => a.name.localeCompare(b.name))
              }
              labelKey="name"
              valueKey="name"
              onChange={handleOrganizationSelectChange}
              value={selectedOrganizationValue as Value}
            />
          </FormControl>
        </Block>

        <Block marginBottom="36px">
          <Button
            size={SIZE.compact}
            disabled={!selectedOrganizationValue[0]?.id}
            onClick={handleContinueBtnClick}
            overrides={organizationContinueButtonOverrides}
          >
            {t('organizations:continue')}
          </Button>
        </Block>

        <hr />
        <Access oneOf={[AccessUnit.EWAManager]}>
          <Block
            marginBottom="36px"
            marginTop="36px"
          >
            <Button
              size={SIZE.compact}
              onClick={handleSetupNewCustomerBtnClick}
              overrides={newOrganizationButtonOverrides}
            >
              {t('organizations:setUpNewCustomer')}
            </Button>
          </Block>
        </Access>
        <Block
          display="flex"
          justifyContent="center"
          width="100%"
        >
          <Button
            size={SIZE.compact}
            type="button"
            onClick={handleLogoutButtonClick}
            kind={KIND.secondary}
            overrides={logoutButtonOverrides}
          >
            {t('common:menu.logout')}
          </Button>
        </Block>
      </div>
    </div>
  );
};

export default memo(ChooseOrganization);
