import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import { Card } from 'baseui/card';
import { Cell } from 'baseui/layout-grid';
import AppModal from 'components/AppModal/AppModal';
import AppStyledTable from 'components/AppStyledTable/AppStyledTable';
import Loader from 'components/Loader';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ModalNames, setModal } from 'store/slices/modals';
import {
  fetchWorkerAccountExternal,
  workerAccountExternalPendingListSelector,
  workerAccountExternalSelector,
} from 'store/slices/workerAccount';
import { emptyPlaceholder } from 'theme';
import { AccountType } from 'types/WorkerAccountTypes';
import { headerStyle, rowStyles } from 'screens/CommonHelpers';

const listItemNameAndStatuses = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  marginLeft: '4px',
  paddingTop: '4px',
  cursor: 'default',
  color: 'black',
  paddingBottom: '14px',
  paddingRight: '10px',
  '@media screen and (max-width: 768px)': {
    'flex-direction': 'column',
  },
};

export const blockOverrides = {
  Block: {
    style: {
      marginTop: '8px',
      marginBottom: '8px',
      lineHeight: '30px',
      fontWeight: 500,
      color: '#5F6368',
      fontSize: '12px',
    },
  },
};

const WorkerLinkedAccountsModal = ({
  workerId,
}: { workerId: string }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'workers', 'employees']);
  const modalName = ModalNames.WORKER_LINKED_ACCOUNTS_MODAL;
  const pending = useAppSelector(workerAccountExternalPendingListSelector);
  const [css] = useStyletron();
  const externalAccounts = useAppSelector(workerAccountExternalSelector);
  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(fetchWorkerAccountExternal({ workerID: workerId }));
  }, []);
  const externalCardAccounts = externalAccounts?.filter((item) => item?.type?.toString() === AccountType.EXTERNAL_CARD);
  const externalBankAccounts = externalAccounts?.filter((item) => item?.type?.toString() === AccountType.EXTERNAL_BANK_ACCOUNT);
  return (
    <AppModal
      modal={modalName}
      title={t('workers:linkedAccountsModalTitle')}
      cancelBtnText={t('common:cancel')}
      isActionDisabled={externalAccounts?.length === 0}
      onClose={handleModalClose}
    >
      <Loader active={pending} />
      <div className={css({
        maxHeight: '350px',
        overflowY: 'auto',
        overflowX: 'hidden',
        '@media screen and (max-width: 768px)': {
          overflowX: 'auto',
        },
      })}
      >
        {externalBankAccounts?.length > 0 && (
          <>
            <div className={css(listItemNameAndStatuses)}>
              <Button
                kind={KIND.tertiary}
                overrides={{
                  BaseButton: { style: { color: 'black', cursor: 'default' } },
                }}
              >
                {t('employees:linkedBankAccounts')}
              </Button>
            </div>
            <Cell span={12}>
              <AppStyledTable
                tableGridTemplateColumns="minmax(40%, max-content) minmax(20%, max-content) minmax(20%, max-content) minmax(20%, max-content)"
                headingCells={[
                  <span style={headerStyle}>{t('workers:accountNickname')}</span>,
                  <span style={headerStyle}>{t('workers:accountLastFour')}</span>,
                  <span style={headerStyle}>{t('workers:accountStatus')}</span>,
                  <span style={headerStyle}>{t('workers:accountVerificationStatus')}</span>,
                ]}
                rows={externalBankAccounts?.map(({
                  nickname, lastFour, status, verificationStatus,
                }) => ({
                  id: `${nickname}-${lastFour}-${status}`,
                  cells: [
                    <span style={rowStyles}>{nickname || emptyPlaceholder}</span>,
                    <span style={rowStyles}>{`****${lastFour}` || emptyPlaceholder}</span>,
                    <span style={rowStyles}>{status || emptyPlaceholder}</span>,
                    <span style={rowStyles}>{verificationStatus || emptyPlaceholder}</span>,
                  ],
                })) || []}
              />
            </Cell>
          </>
        )}

        {externalCardAccounts?.length > 0 && (
        <>
          <div className={css(listItemNameAndStatuses)}>
            <Button
              kind={KIND.tertiary}
              overrides={{
                BaseButton: { style: { color: 'black', cursor: 'default' } },
              }}
            >
              {t('employees:linkedCardAccounts')}
            </Button>
          </div>
          <Cell span={12}>
            <AppStyledTable
              tableGridTemplateColumns="minmax(40%, max-content) minmax(20%, max-content) minmax(20%, max-content) minmax(20%, max-content)"
              headingCells={[
                <span style={headerStyle}>{t('workers:accountNickname')}</span>,
                <span style={headerStyle}>{t('workers:accountLastFour')}</span>,
                <span style={headerStyle}>{t('workers:accountStatus')}</span>,
                <span style={headerStyle}>{t('workers:accountVerificationStatus')}</span>,
              ]}
              rows={externalCardAccounts?.map(({
                nickname, lastFour, status, verificationStatus,
              }) => ({
                id: `${nickname}-${lastFour}-${status}`,
                cells: [
                  <span style={rowStyles}>{nickname || emptyPlaceholder}</span>,
                  <span style={rowStyles}>{`****${lastFour}` || emptyPlaceholder}</span>,
                  <span style={rowStyles}>{status || emptyPlaceholder}</span>,
                  <span style={rowStyles}>{verificationStatus || emptyPlaceholder}</span>,
                ],
              })) || []}
            />
          </Cell>
        </>
        )}
        {externalAccounts?.length === 0 && (
          <Block
            marginTop="24px"
            marginBottom="24px"
          >
            <Card>
              <p>{t('workers:externalAccountsNotFound')}</p>
            </Card>
          </Block>
        )}
      </div>

    </AppModal>
  );
};

export default memo(WorkerLinkedAccountsModal);
