/* eslint-disable react/jsx-no-useless-fragment */
import {
  memo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Cell } from 'baseui/layout-grid';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import {
  organizationSelector,
} from 'store/slices/organizations';
import {
  fetchPayGroup,
  resetSelectedPayGroup,
  selectedPayGroupSelector,
  payGroupPendingSelector,
} from 'store/slices/payGroups';
import { OrganizationPreviewPayGroupModalPropsType } from 'types/PayGroupTypes';
import AppModal from 'components/AppModal/AppModal';
import Loader from 'components/Loader';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { Block } from 'baseui/block';
import { useStyletron } from 'styletron-react';
import { emptyPlaceholder } from 'theme';
import { FormControl } from 'baseui/form-control';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Button, KIND } from 'baseui/button';
import moment from 'moment';
import removeUnderlinesFromText from 'utils/locationHelpers';

const titleStyles = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  'white-space': 'nowrap',
  width: 'calc(100% - 40px)',
  display: 'inline-block',
  marginRight: '8px',
};

const OrganizationPreviewPayGroupModal = ({
  payGroupID,
}: OrganizationPreviewPayGroupModalPropsType) => {
  const { t } = useTranslation(['payGroups', 'common']);
  const [css] = useStyletron();
  const dispatch = useAppDispatch();
  const modals = useAppSelector(modalsSelector);
  const payGroup = useAppSelector(selectedPayGroupSelector);
  const modalName = ModalNames.PAY_GROUP_PREVIEW_MODAL;
  const isModalOpen = checkIsModalOpen(modals, modalName);
  const pending = useAppSelector(payGroupPendingSelector);
  const organization = useAppSelector(organizationSelector);
  const { id: organizationID } = organization || {};

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleClickEdit = () => {
    dispatch(setModal({
      name: ModalNames.PAY_GROUP_FORM_MODAL,
      isOpen: true,
    }));
    dispatch(setModal({
      name: ModalNames.PAY_GROUP_PREVIEW_MODAL,
      isOpen: false,
    }));
  };

  useEffect(() => {
    dispatch(resetSelectedPayGroup());
    return () => {
      dispatch(resetSelectedPayGroup());
    };
  }, []);

  useEffect(() => {
    dispatch(resetSelectedPayGroup());
  }, [isModalOpen]);

  useEffect(() => {
    isModalOpen && payGroupID && dispatch(fetchPayGroup({ organizationID, payGroupID }));
  }, [payGroupID, isModalOpen]);

  return (
    <AppModal
      modal={modalName}
      title={(
        <Block
          marginTop="-4px"
          display="flex"
          alignItems="center"
        >
          <div className={css(titleStyles)}>
            {t('payGroups:payGroup.details.title')}
          </div>
          <Block
            marginRight="16px"
            display="flex"
          >
            <Button
              onClick={handleClickEdit}
              size="compact"
              kind={KIND.tertiary}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Block>
        </Block>
      )}
      onClose={handleModalClose}
    >
      <Loader active={pending} />
      <Block
        marginTop="40px"
      >
        <Grid
          gridColumns={12}
          gridMargins={16}
          gridGaps={16}
        >
          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.name.label')}
            >
              <div className="ellipsis" title={payGroup?.name || emptyPlaceholder}>
                {payGroup?.name || emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.payFrequencyRule.label')}
            >
              <>
                {payGroup?.payFrequencyRule || emptyPlaceholder}
              </>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.periodStartDay.label')}
            >
              <>
                {(payGroup?.periodStartDay && t(`common:daysOfWeek.${payGroup?.periodStartDay?.toLocaleLowerCase()}`)) || emptyPlaceholder}
              </>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.businessDayStartTime.label')}
            >
              <>
                {payGroup?.businessDayStartTime ? moment(payGroup?.businessDayStartTime, 'hh:mm').format('hh:mm A') : emptyPlaceholder}
              </>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.ewaCutoffTime.label')}
            >
              <>
                {payGroup?.ewaCutoffTime ? moment(payGroup?.ewaCutoffTime, 'hh:mm').format('hh:mm A') : emptyPlaceholder}
              </>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.ewaCutoffDayOffset.label')}
            >
              <>
                {t(`common:${payGroup?.ewaCutoffDayOffset === 1 ? 'day' : 'days'}`, { day: payGroup?.ewaCutoffDayOffset || 0 })}
              </>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.tpoCutoffTime.label')}
            >
              <>
                {payGroup?.tcoCutoffTime ? moment(payGroup?.tcoCutoffTime, 'hh:mm').format('hh:mm A') : emptyPlaceholder}
              </>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.tpoCutoffDayOffset.label')}
            >
              <>
                {t(`common:${payGroup?.tcoCutoffDayOffset === 1 ? 'day' : 'days'}`, { day: payGroup?.tcoCutoffDayOffset || 0 })}
              </>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.payrollCutoffTime.label')}
            >
              <>
                {payGroup?.payrollCutoffTime ? moment(payGroup?.payrollCutoffTime, 'hh:mm').format('hh:mm A') : emptyPlaceholder}
              </>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.payrollCutoffTimezone.label')}
            >
              <>
                {removeUnderlinesFromText(payGroup?.payrollCutoffTimezone || emptyPlaceholder)}
              </>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.payrollCutoffDayOffset.label')}
            >
              <>
                {t(`common:${payGroup?.payrollCutoffDayOffset === 1 ? 'day' : 'days'}`, { day: payGroup?.payrollCutoffDayOffset || 0 })}
              </>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.paydayOffset.label')}
            >
              <>
                {t(`common:${payGroup?.paydayOffset === 1 ? 'day' : 'days'}`, { day: payGroup?.paydayOffset || 0 })}
              </>
            </FormControl>
          </Cell>
          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.externalIntegrationCode.label')}
            >
              <div className="ellipsis" title={payGroup?.externalIntegrationCode || emptyPlaceholder}>
                {payGroup?.externalIntegrationCode || emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>
          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.deductionCode.label')}
            >
              <div className="ellipsis" title={payGroup?.deductionCode || emptyPlaceholder}>
                {payGroup?.deductionCode || emptyPlaceholder}
              </div>
            </FormControl>
          </Cell>

          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.batchDeliveryEmailAddresses.label')}
            >
              <>
                {payGroup?.batchDeliveryEmailAddresses?.join(', ') || emptyPlaceholder}
              </>
            </FormControl>
          </Cell>
          <Cell span={[12, 6]}>
            <FormControl
              label={t('payGroups:payGroup.details.automaticPayrollPeriodGenerationEnabled.label')}
            >
              <>
                {(payGroup?.automaticPayrollPeriodGenerationEnabled ? 'no' : 'yes').toUpperCase()}
              </>
            </FormControl>
          </Cell>

        </Grid>
      </Block>
    </AppModal>
  );
};

export default memo(OrganizationPreviewPayGroupModal);
