import { useStyletron } from 'baseui';
import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { LabelMedium } from 'baseui/typography';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setWizardSections, wizardSectionSelector } from 'store/slices/sections';

export type WizardTabulationPropsType = {
  tabs: string[]
}

const WizardTabulation = ({ tabs }: WizardTabulationPropsType) => {
  const theme = useStyletron()[1];
  const activeSection = useAppSelector(wizardSectionSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setWizardSections(tabs));
  }, []);

  return (
    <>
      <Grid
        gridColumns={12}
        gridMargins={24}
      >
        {tabs.map((tab, index) => {
          const isActive = index === activeSection
            ? theme.colors.contentPrimary
            : theme.colors.contentStateDisabled;

          return (
            <Cell
              key={tab}
              span={[6, 4, 2]}
              overrides={{
                Cell: {
                  style: {
                    paddingTop: '20px',
                    paddingBottom: '12px',
                  },
                },
              }}
            >
              <LabelMedium color={isActive}>
                {`${index + 1}. ${tab}`}
              </LabelMedium>
            </Cell>
          );
        })}
      </Grid>
      <hr />
    </>
  );
};

export default WizardTabulation;
