import { useStyletron } from 'styletron-react';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import PriceFormatter from 'utils/priceFormatter';
import {
  listContainerStyles,
  outerContainerStyles,
} from 'screens/Batches/BatchesHelpers';
import {
  AllocatedTPOBatchEmploymentType,
} from 'types/RepaymentTypes';
import { unScalePrice } from 'utils/priceScale';
import { Block } from 'baseui/block';
import { Panel } from 'baseui/accordion';
import { useAppSelector } from 'store/hooks';
import {
  allocatedTPOBatchEmploymentPaymentAllocationsListSelector,
  allocatedTPOBatchEmploymentPaymentAllocationsPendingSelector,
} from 'store/slices/paymentAllocation';
import Loader from 'components/Loader';
import { Card } from 'baseui/card';
import { useTranslation } from 'react-i18next';
import { Key } from 'react';
import TPOAllocatedPaymentsPaymentListItem from './TPOAllocatedPaymentsPaymentListItem';

export type TPOAllocatedPaymentEmploymentListItemPropsType = {
  employment: AllocatedTPOBatchEmploymentType
}

const TPOAllocatedPaymentsEmploymentListItem = ({
  employment,
  ...rest
}: TPOAllocatedPaymentEmploymentListItemPropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['batches', 'common']);

  const {
    id,
    firstName,
    lastName,
    payrollId,
    allocatedAmount,
    totalAmount,
  } = employment;

  const payments = useAppSelector(allocatedTPOBatchEmploymentPaymentAllocationsListSelector);
  const pending = useAppSelector(allocatedTPOBatchEmploymentPaymentAllocationsPendingSelector);

  const employmentID = id?.toString();

  const hasPayments = payments?.length > 0;

  return (
    <>
      <div className={css(outerContainerStyles)}>
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
          gridMargins={4}
        >
          <Cell
            align={ALIGNMENT.center}
            span={4}
          >
            {`${firstName} ${lastName}`}
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            {payrollId}
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <span
              data-testid="TPOAllocatedPaymentsEmploymentListItem-totalAmount"
              id="TPOAllocatedPaymentsEmploymentListItem-totalAmount"
            >
              {PriceFormatter().format(unScalePrice(totalAmount?.value, totalAmount?.scale))}
            </span>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <span
              data-testid="TPOAllocatedPaymentsEmploymentListItem-allocatedAmount"
              id="TPOAllocatedPaymentsEmploymentListItem-allocatedAmount"
            >
              {PriceFormatter().format(unScalePrice(allocatedAmount?.value, allocatedAmount?.scale))}
            </span>
          </Cell>
        </Grid>
      </div>

      <Block
        marginLeft="-20px"
        marginRight="-20px"
      >
        <Panel
          key={employmentID as Key}
          {...rest}
        >
          {!pending && hasPayments && (
          <Grid
            gridColumns={12}
            align={ALIGNMENT.center}
            gridMargins={0}
          >
            <Cell
              span={3}
            >
              <strong>{t('batches:paymentAllocations.drawDate')}</strong>
            </Cell>
            <Cell
              span={3}
            >
              <strong>{t('common:location')}</strong>
            </Cell>

            <Cell
              span={2}
            >
              <strong>{t('batches:paymentAllocations.tpoPaymentAmount')}</strong>
            </Cell>

            <Cell
              span={2}
            >
              <strong>{t('batches:paymentAllocations.allocatedAmount')}</strong>
            </Cell>
          </Grid>
          )}

          {pending && (
          <div className={css(listContainerStyles)}>
            <Loader active={pending} />
          </div>
          )}

          {!pending && hasPayments && payments?.map((payment: any) => (
            <TPOAllocatedPaymentsPaymentListItem
              key={payment?.createdDate}
              payment={payment}
            />
          ))}

          {!pending && !hasPayments && (
          <Grid
            gridColumns={12}
            gridMargins={16}
          >
            <Cell span={12}>
              <Card>
                {t('repayments:notFoundEmploymentDraws')}
              </Card>
            </Cell>
          </Grid>
          )}
        </Panel>
      </Block>
    </>
  );
};

export default TPOAllocatedPaymentsEmploymentListItem;
