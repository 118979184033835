import { LabelSmall } from 'baseui/typography';
import { Tag, VARIANT } from 'baseui/tag';
import { useStyletron } from 'baseui';

const EmployeeStatusTag = ({ status = 'INACTIVE', styles = null }: {
  status?: string,
  styles?: { tagColor: string, labelColor: string } | null,
}) => {
  const theme = useStyletron()[1];
  const NEUTRAL = {
    tagColor: theme.colors.backgroundTertiary,
    labelColor: theme.colors.contentSecondary,
  };

  const POSITIVE = {
    tagColor: theme.colors.backgroundPositiveLight,
    labelColor: '#02773F',
  };

  const WARNING = {
    tagColor: theme.colors.backgroundWarningLight,
    labelColor: '#674D1B',
  };

  const EMPLOYEE_STATUS_TO_TAG_CONFIGS: {
    [index: string]: { tagColor: string, labelColor: string },
  } = {
    ACTIVE: POSITIVE,
    INACTIVE: NEUTRAL,
    TERMINATED: WARNING,
    MERGED: NEUTRAL,
    BAD_DATA: WARNING,
  };
  const statusStyles = styles || EMPLOYEE_STATUS_TO_TAG_CONFIGS[status as keyof typeof EMPLOYEE_STATUS_TO_TAG_CONFIGS];
  return (
    <Tag
      closeable={false}
      variant={VARIANT.solid}
      overrides={{
        Root: {
          style: {
            backgroundColor: statusStyles.tagColor,
          },
        },
        Text: {
          style: {
            maxWidth: 'unset',
          },
        },
      }}
    >
      <LabelSmall color={statusStyles.labelColor} width="fit-content">
        {status.replace(/_/g, ' ')}
      </LabelSmall>
    </Tag>
  );
};

export default EmployeeStatusTag;
