import {
  FC,
  Fragment,
  memo,
  ReactNode,
} from 'react';
import {
  StyledTable,
  StyledHeadCell,
  StyledBodyCell,
} from 'baseui/table-grid';
import { StyleObject } from 'styletron-react';

type StyledRowType = {
  cells: Array<ReactNode>,
  id: string,
};

type StyledFooterRowCellType = {
  cell: ReactNode,
  props?: any,
};

type StyledHeadingCellType = string | object;

type AppGridTablePropTypes = {
  tableStyle?: StyleObject,
  headingCells: Array<StyledHeadingCellType>,
  rows: Array<StyledRowType>,
  footer?: Array<StyledFooterRowCellType>,
  tableGridTemplateColumns: string
};

const StyledHead: FC<any> = ({ children, ...rest }: any) => (
  <StyledHeadCell
    $style={{
      borderLeftColor: 'unset',
      borderLeftWidth: '0',
      borderRightColor: 'unset',
      borderRightWidth: '0',
      borderTopColor: '#E2E2E2',
      borderBottomColor: '#E2E2E2',
      borderBottomStyle: 'solid',
      boxShadow: 'none',
      backgroundColor: '#EEEEEE',
      height: '36px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '14px',
      paddingBottom: '14px',
      ...rest,
    }}
  >
    {children}
  </StyledHeadCell>
);

const StyledFooter: FC<any> = ({ children, ...rest }: any) => (
  <StyledHeadCell
    $style={{
      borderLeftColor: 'unset',
      borderLeftWidth: '0',
      borderRightColor: 'unset',
      borderRightWidth: '0',
      borderTopColor: '#E2E2E2',
      borderBottomColor: '#E2E2E2',
      borderBottomStyle: 'solid',
      boxShadow: 'none',
      backgroundColor: '#EEEEEE',
      height: '36px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '14px',
      paddingBottom: '14px',
      ...rest,
    }}
  >
    {children}
  </StyledHeadCell>
);

const StyledCell: FC<any> = ({ children, ...rest }: any) => (
  <StyledBodyCell
    $style={{
      display: 'flex',
      alignItems: 'center',
      'text-overflow': 'ellipsis',
      'white-space': 'nowrap',
      overflow: 'hidden',
      height: '36px',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '14px',
      paddingBottom: '14px',
      backgroundColor: '#FFFFFF',
      borderBottomColor: '#E2E2E2',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
    }}
    {...rest}
  >
    {children}
  </StyledBodyCell>
);

const AppGridTable = ({
  tableStyle,
  headingCells,
  rows,
  footer,
  tableGridTemplateColumns,
}: AppGridTablePropTypes) => (
  <StyledTable $style={tableStyle} $gridTemplateColumns={tableGridTemplateColumns}>
    {headingCells.map((cell: StyledHeadingCellType) => (<StyledHead key={`sh-${Math.random()}`}>{cell}</StyledHead>))}
    {rows.map((row: StyledRowType) => (
      <Fragment key={row.id}>
        {row.cells.map((cell: ReactNode) => (
          <StyledCell key={`${row.id}-${Math.random()}`}>
            {cell}
          </StyledCell>
        ))}
      </Fragment>
    ))}
    {footer?.map((row: StyledFooterRowCellType) => (
      <StyledFooter key={`ft-cell-${Math.random()}`} {...row.props}>
        {row.cell}
      </StyledFooter>
    ))}
  </StyledTable>
);

export default memo(AppGridTable);
