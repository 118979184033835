import { ReactNode } from 'react';
import { matchPath, RouteProps, useLocation } from 'react-router-dom';

export type RouteVisibilityPropsType = {
  children?: ReactNode
  hideFor: RouteProps[]
};

const RouteVisibility = ({
  children,
  hideFor,
}: RouteVisibilityPropsType) => {
  const location = useLocation();
  const { pathname } = location;
  const hideChildren = hideFor?.find((item) => matchPath(pathname, item));

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return hideChildren ? null : <>{children}</>;
};

export default RouteVisibility;
