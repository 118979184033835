import { OrganizationIDType } from 'types/OrganizationTypes';
import {
  LoadFundsRequestParamsType,
  OrderNewCardsType,
  OrganizationPaycardSettingsParamsType,
  RegisterPaycardRequestParamsType,
  UnloadFundsRequestParamsType,
} from 'types/PaycardManagementTypes';
import { WorkerIDType } from 'types/WorkerTypes';
import {
  LocationIDType,
  SaveLocationPaycardConfigType,
} from 'types/LocationTypes';
import request from './request';

export const fetchOrganizationPaycardSettingsRequest = (
  token: string,
  organizationID: OrganizationIDType,
) => request({
  token,
  url: `organizations/${organizationID}/paycards/settings`,
});

export const setOrganizationPaycardSettingsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: OrganizationPaycardSettingsParamsType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organizations/${organizationID}/paycards/settings`,
});

export const fetchOrganizationsPaycardProviderRequest = (
  token: string,
  parentId?: number,
) => {
  const queryParams = new URLSearchParams({});
  if (parentId) {
    queryParams.set('parentId', parentId.toString());
  }

  return request({
    token,
    url: `paycard-provider/organizations?${queryParams.toString()}`,
  });
};

export const fetchOrganizationsPaycardProviderProgramsRequest = (
  token: string,
  providerOrganizationId: string,
) => request({
  token,
  url: `paycard-provider/organizations/${providerOrganizationId}/programs`,
});

export const fetchOrganizationsPaycardFundingAccountsRequest = (
  token: string,
  providerOrganizationId: string,
) => request({
  token,
  url: `paycard-provider/organizations/${providerOrganizationId}/accounts`,
});

export const fetchWorkerPaycardOfferRequest = (
  token: string,
  workerID: WorkerIDType,
  organizationID: OrganizationIDType,
) => request({
  token,
  url: `workers/${workerID}/accounts/paycard/offer?organizationId=${organizationID}`,
});

export const changeWorkerPaycardOfferRequest = (
  token: string,
  workerID: WorkerIDType,
  organizationID: OrganizationIDType,
  allowed: boolean,
) => request({
  token,
  url: `workers/${workerID}/accounts/paycard/offer`,
  method: 'POST',
  payload: {
    organizationId: organizationID,
    allowed,
  },
});

export const fetchOrganizationMasterAccountPaycardBalanceRequest = (
  token: string,
  organizationID: OrganizationIDType,
) => request({
  token,
  url: `organizations/${organizationID}/paycards/master-account/balance`,
});

export const fetchPaycardManagementInventoryRequest = (
  token: string,
  organizationId: OrganizationIDType,
  locationId?: LocationIDType,
  page?: number,
  pageSize?: number,
) => {
  const queryParams = new URLSearchParams({});
  if (locationId) {
    queryParams.set('locationId', locationId);
  }
  if (page) {
    queryParams.set('page', page.toString());
  }
  if (pageSize) {
    queryParams.set('pageSize', pageSize.toString());
  }
  return request({
    token,
    url: `organizations/${organizationId}/paycards/inventory?${queryParams.toString()}`,
  });
};

export const fetchOrganizationPaycardEligibleLocationsRequest = (
  token: string,
  organizationID: OrganizationIDType,
) => request({
  token,
  url: `organizations/${organizationID}/paycards/locations`,
});

export const fetchOrganizationPaycardsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  page: number,
  pageSize: number,
  filter: string,
) => {
  const queryParams = new URLSearchParams({});
  queryParams.set('page', page.toString());
  queryParams.set('pageSize', pageSize.toString());

  return request({
    token,
    url: `organizations/${organizationID}/paycards?${filter}&${queryParams.toString()}`,
  });
};

export const fetchOrganizationPaycardAccountDetailsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  proxyNumber: string,
) => request({
  token,
  url: `organizations/${organizationID}/paycards/${proxyNumber}/account`,
});

export const registerPaycardRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
  data: RegisterPaycardRequestParamsType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organizations/${organizationID}/locations/${locationID}/paycards/register`,
});

export const loadFundsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  proxyNumber: string,
  data: LoadFundsRequestParamsType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organizations/${organizationID}/paycards/${proxyNumber}/load`,
});

export const unloadFundsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  proxyNumber: string,
  data: UnloadFundsRequestParamsType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organizations/${organizationID}/paycards/${proxyNumber}/unload`,
});

export const fetchLocationPaycardConfigRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationId: LocationIDType,
) => (
  request({
    token,
    url: `organizations/${organizationID}/locations/${locationId}/paycards/settings`,
  })
);

export const saveLocationPaycardConfigRequest = (
  token: string,
  organizationID: OrganizationIDType,
  locationId: LocationIDType,
  data: SaveLocationPaycardConfigType,
) => (
  request({
    token,
    method: 'POST',
    payload: data,
    url: `organizations/${organizationID}/locations/${locationId}/paycards/settings`,
  })
);

export const fetchOrdersHistoryRequest = (
  token: string,
  organizationId: OrganizationIDType,
  locationId: LocationIDType,
  fromDate?: string,
  toDate?: string,
  page?: number,
  pageSize?: number,
) => {
  const queryParams = new URLSearchParams({});

  fromDate && queryParams.set('fromDate', fromDate);
  toDate && queryParams.set('toDate', toDate);
  page && queryParams.set('page', page.toString());
  pageSize && queryParams.set('pageSize', pageSize.toString());

  return request({
    token,
    url: `organizations/${organizationId}/locations/${locationId}/paycards/orders?${queryParams.toString()}`,
  });
};

export const orderNewCardsRequest = (
  token: string,
  organizationId: OrganizationIDType,
  locationId: LocationIDType,
  data: OrderNewCardsType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organizations/${organizationId}/locations/${locationId}/paycards/orders`,
});

export const fetchManageFundsHistoryRequest = (
  token: string,
  organizationId: OrganizationIDType,
  proxyNumber: string,
  fromDate?: string,
  toDate?: string,
  page?: number,
  pageSize?: number,
) => {
  const queryParams = new URLSearchParams({});

  fromDate && queryParams.set('fromDate', fromDate);
  toDate && queryParams.set('toDate', toDate);
  page && queryParams.set('page', page.toString());
  pageSize && queryParams.set('pageSize', pageSize.toString());

  return request({
    token,
    url: `organizations/${organizationId}/paycards/${proxyNumber}/transactions?${queryParams.toString()}`,
  });
};
