import { useStyletron } from 'baseui';
import { borderRadius } from 'theme';

const styles = {
  width: '100%',
  border: '1px solid #ccc',
  height: '100%',
  overflow: 'auto',
  background: '#ededed',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
};

const PrintJsObjectAsJson = (
  data: any,
) => {
  const [css] = useStyletron();

  return (
    <div className={css(styles)}>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

export default PrintJsObjectAsJson;
