import React from 'react';
import { BaseProvider } from 'baseui';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AppTheme } from 'theme';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import environment from './environment';
import { store } from './store';
import App from './App';
import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';
import { msalConfig } from './auth/authConfig';
import handleRedirectResponse from './utils/handleRedirectPromise';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const engine = new Styletron();
const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.handleRedirectPromise().then((res) => handleRedirectResponse(msalInstance, res)).catch(() => {
  // Handle error here
});
// eslint-disable-next-line react/jsx-no-constructed-context-values
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: environment.appInsightsInstrumentationKey,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();

root.render(
  <React.StrictMode>
    <StyletronProvider value={engine}>
      <BaseProvider zIndex={100} theme={AppTheme}>
        <Provider store={store}>
          <MsalProvider instance={msalInstance}>
            <AppInsightsContext.Provider value={reactPlugin}>
              <App />
            </AppInsightsContext.Provider>
          </MsalProvider>
        </Provider>
      </BaseProvider>
    </StyletronProvider>
  </React.StrictMode>,
);
