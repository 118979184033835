import { borderRadius } from 'theme';

export const list = {
  padding: '0px',
};

export const listItem = {
  background: '#FFFFFF',
  'list-style': 'none',
  padding: '8px',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
};

export const listItemNameAndStatuses = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  padding: '4px',
};

export const listItemInfoStyles = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '0px 4px 16px 20px',
};

export const details = {
  display: 'inline-block',
  marginRight: '8px',
  marginTop: '4px',
};

export const characterLimit = {
  marginLeft: '20px',
  lineHeight: '20px',
  color: '#545454',
};

export const multiLineComponents = {
  'white-space': 'pre-line',
  fontStyle: 'bold',
  cursor: 'default',
};

export const descriptionText = {
  'white-space': 'pre-line',
  borderLeftColor: 'unset',
  borderLeftWidth: '0',
  borderRightColor: 'unset',
  borderRightWidth: '0',
  boxShadow: 'none',
  backgroundColor: '#EEEEEE',
  display: 'flex',
  'flex-direction': 'row',
  alignItems: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '14px',
  paddingBottom: '14px',
  cursor: 'default',
};
