import {
  ChangeEvent,
  memo,
  useEffect,
} from 'react';
import { Block } from 'baseui/block';
import {
  Grid,
  Cell,
  ALIGNMENT,
} from 'baseui/layout-grid';
import {
  Button,
  KIND,
} from 'baseui/button';
import { Search } from 'baseui/icon';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  Input,
  SIZE,
} from 'baseui/input';
import {
  OnChangeParams,
  Select,
  Value,
} from 'baseui/select';
import CellFormControl from 'components/CellFormControl';
import {
  prevPageSelector,
  setPrevPage,
} from 'store/slices/application';
import {
  resetWorkers,
  searchWorkers,
  setFilter,
  setFilterCriteria,
  workersFilterCriteriaSelector,
  workersFilterSelector,
  workersPendingListSelector,
  setFilterFirstName,
  setFilterLastName,
  setFilterEmail,
  setFilterWorkerId,
  resetFilter,
} from 'store/slices/workers';
import { InputCustomHTMLElement } from 'types/CommonTypes';

const WorkersSearch = () => {
  const { t } = useTranslation('treasury');
  const dispatch = useAppDispatch();
  const pending = useAppSelector(workersPendingListSelector);
  const search = useAppSelector(workersFilterSelector);
  const criteria = useAppSelector(workersFilterCriteriaSelector);
  const prevPage = useAppSelector(prevPageSelector);

  const {
    firstName,
    lastName,
    email,
    workerId,
  } = search;

  const searchByOptions = [
    {
      name: 'Name',
      id: 'name',
    },
    {
      name: 'Email',
      id: 'email',
    },
    {
      name: 'Worker ID',
      id: 'workerId',
    },
  ];

  const handleChangeFirstName = (e: ChangeEvent<InputCustomHTMLElement>) => {
    dispatch(setFilterFirstName(e.target.value));
  };

  const handleChangeLastName = (e: ChangeEvent<InputCustomHTMLElement>) => {
    dispatch(setFilterLastName(e.target.value));
  };

  const handleChangeEmail = (e: ChangeEvent<InputCustomHTMLElement>) => {
    if (criteria[0].id === 'email' && !e.target.value) {
      dispatch(resetWorkers());
    }
    dispatch(setFilterEmail(e.target.value));
  };

  const handleChangeWorkerId = (e: ChangeEvent<InputCustomHTMLElement>) => {
    if (criteria[0].id === 'workerId' && !e.target.value) {
      dispatch(resetWorkers());
    }
    dispatch(setFilterWorkerId(e.target.value));
  };

  const handleSearch = () => {
    const filter: any = { pageSize: 999 };
    if (search.firstName !== '' || search.lastName !== '' || search.email !== '' || search.workerId !== '') {
      dispatch(searchWorkers({
        filter: {
          ...filter,
          firstName: firstName.trim() || '',
          lastName: lastName.trim() || '',
          email: email || '',
          workerId: workerId || '',
        },
      }));
    }
  };

  const handleSearchWorkerClick = () => {
    handleSearch();
    dispatch(setFilter({
      firstName, lastName, email, workerId,
    }));
  };

  const handleCriteriaChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(resetWorkers());
    dispatch(resetFilter());
    dispatch(setFilterCriteria(value));
  };

  useEffect(() => {
    if (search && (prevPage.startsWith('/treasury') || prevPage.startsWith('/workers') || prevPage.startsWith('/loggedOrganization/employees/'))) {
      handleSearch();
    } else {
      dispatch(resetWorkers());
      dispatch(resetFilter());
      dispatch(setFilterCriteria([{ id: 'name', name: '' }]));
    }
    dispatch(setPrevPage(''));
  }, []);

  return (
    <Grid
      gridColumns={12}
      gridMargins={0}
      align={ALIGNMENT.center}
    >
      <CellFormControl
        cellSpan={[12, 6, 3]}
        cellAlign={ALIGNMENT.center}
        label={`${t('common:searchBy')}`}
      >
        <Select
          size={SIZE.default}
          placeholder={t('common:select')}
          type="select"
          clearable={false}
          options={searchByOptions}
          labelKey="name"
          valueKey="id"
          onChange={handleCriteriaChange}
          value={criteria as Value}
          overrides={{
            ControlContainer: {
              props: {
                'data-testid': 'WorkerAccounts-search-type-select',
                id: 'WorkerAccounts-search-type-select',
              },
            },
          }}
        />
      </CellFormControl>
      { criteria[0].id === 'name' && (
        <>
          <CellFormControl
            cellSpan={[12, 6, 3]}
            cellAlign={ALIGNMENT.center}
            label={t('common:firstName.label')}
          >
            <Input
              clearOnEscape
              id="treasury-worker-search-first-name"
              clearable
              startEnhancer={<Search />}
              type="text"
              autoComplete="off"
              name="firstName"
              onChange={handleChangeFirstName}
              value={firstName}
              placeholder={t('common:searchPlaceholder')}
            />
          </CellFormControl>
          <CellFormControl
            cellSpan={[12, 6, 3]}
            cellAlign={ALIGNMENT.center}
            label={t('common:lastName.label')}
          >
            <Input
              clearOnEscape
              id="treasury-worker-search-last-name"
              clearable
              startEnhancer={<Search />}
              type="text"
              autoComplete="off"
              name="lastName"
              onChange={handleChangeLastName}
              value={lastName}
              placeholder={t('common:searchPlaceholder')}
            />
          </CellFormControl>
        </>
      )}
      { criteria[0].id === 'email' && (
      <CellFormControl
        cellSpan={[12, 6, 6]}
        cellAlign={ALIGNMENT.center}
      >
        <Block
          marginTop="28px"
        >
          <Input
            clearOnEscape
            id="treasury-worker-search-email"
            clearable
            startEnhancer={<Search />}
            type="text"
            autoComplete="off"
            name="email"
            onChange={handleChangeEmail}
            value={email}
            placeholder={t('common:searchPlaceholder')}
          />
        </Block>
      </CellFormControl>
      )}

      { criteria[0].id === 'workerId' && (
      <CellFormControl
        cellSpan={[12, 6, 6]}
        cellAlign={ALIGNMENT.center}
      >
        <Block
          marginTop="28px"
        >
          <Input
            clearOnEscape
            id="treasury-worker-search-workerId"
            clearable
            startEnhancer={<Search />}
            type="text"
            autoComplete="off"
            name="workerId"
            onChange={handleChangeWorkerId}
            value={workerId}
            placeholder={t('common:searchPlaceholder')}
          />
        </Block>
      </CellFormControl>
      )}

      <Cell
        span={3}
        align={ALIGNMENT.center}
      >
        <Block
          marginTop="24px"
          marginBottom="8px"
        >
          <Button
            disabled={(criteria[0].id === 'name' && (!firstName && !lastName))
            || (criteria[0].id === 'email' && !email)
            || (criteria[0].id === 'workerId' && !workerId)
            || pending}
            type="button"
            overrides={{
              Root: {
                props: { id: 'treasury-worker-button' },
              },
            }}
            kind={KIND.primary}
            onClick={handleSearchWorkerClick}
          >
            {t('common:searchButton')}
          </Button>
        </Block>
      </Cell>
    </Grid>

  );
};

export default memo(WorkersSearch);
