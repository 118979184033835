import {
  SetWorkerProfileAttributesTrustedRequest,
  WorkerIDType,
} from 'types/WorkerTypes';
import request from './request';

export const setWorkerProfileAttributesTrustedRequest = (
  token: string,
  workerID: WorkerIDType,
  data: SetWorkerProfileAttributesTrustedRequest,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `${workerID}/attributes/trusted`,
});

export const other = null;
