import * as Yup from 'yup';
import messages from './messages';

export const commonPercentValidationSchema = (min: number = 0.1, max: number = 100) => Yup
  .string()
  .matches(/^[0-9][0-9]?$|^100$/, messages.mustBePercent)
  .typeError(messages.mustBeANumber)
  .min(min, messages.positiveNumbersOnly)
  .max(max, messages.maxPercent)
  .required(messages?.numberRequired);

export const commonAmountValidationSchema = (min: number = 0.1, max: number = 999999.99, errorAmount: string = '999999.99') => Yup
  .string()
  .matches(/^(?!0*[,\\.]?0+?$)(([0-9]{1,6})(?:[,\\.]\d{1,2})?)$/, `${messages.mustBeAmount} and ${messages.maxAmount} ${errorAmount}`)
  .min(min)
  .max(max)
  .required(messages?.numberRequired);

export const commonPositiveAmountValidationSchema = (errorAmount: string = '999999.99') => Yup
  .string()
  .matches(/^([0-9]{1,6})(?:[,\\.]\d{1,2})?$/, `${messages.mustBeAmount} and ${messages.maxAmount} ${errorAmount}`);

export const commonAmountFormattedValidationSchema = (min: number = 0, max: number = 999999.99, errorAmount: string = '999999.99') => Yup
  .string()
  .matches(/^(0|(?!0*[,\\.]?0+?$)(([0-9]{1,6})(?:[\\.]\d{1,2})?))$/, `${messages.mustBeFormattedAmount} and ${messages.maxAmount} ${errorAmount}`)
  .min(min)
  .max(max)
  .required(messages?.numberRequired);
