import {
  ChangeEvent,
  useState,
  useEffect,
  memo,
} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'baseui/pagination';
import { Button, KIND } from 'baseui/button';
import { Card } from 'baseui/card';
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid';
import { Search } from 'baseui/icon';
import { Block } from 'baseui/block';
import { Input, SIZE } from 'baseui/input';
import { useStyletron } from 'baseui';
import {
  organizationsSelector,
  fetchAllOrganizations,
  organizationsPendingListSelector,
  organizationsPageNumberSelector,
  organizationsTotalSizeSelector,
  organizationsSearchSelector,
  organizationsTabSelector,
  setOrganizationsSearch,
  setOrganizationsTab,
  resetOrganizations,
  resetShouldGoIntoMaintenancePage,
  organizationsNumPagesSelector,
  resetOrganization,
} from 'store/slices/organizations';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import Loader from 'components/Loader';
import CellFormControl from 'components/CellFormControl';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import {
  containerStyles, blockOverrides, paginationTransparentOverrides,
  emptyTabButtonStyles, activeTabContainerStyles, activeTabButtonStyles, tabButtonStyles,
} from 'screens/CommonHelpers';
import { prevPageSelector, setPrevPage } from 'store/slices/application';
import { OrganizationStatuses } from 'types/OrganizationTypes';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import hasAccess from 'utils/hasAccess';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import OrganizationListItem from './OrganizationListItem';

let timer: any;

const Organizations = () => {
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const organizations = useAppSelector(organizationsSelector);
  const pendingList = useAppSelector(organizationsPendingListSelector);
  const numPages = useAppSelector(organizationsNumPagesSelector);
  const pageNumber = useAppSelector(organizationsPageNumberSelector);
  const totalSize = useAppSelector(organizationsTotalSizeSelector);
  const { t } = useTranslation(['organizations', 'common']);
  const search = useAppSelector(organizationsSearchSelector);
  const selectedTab = useAppSelector(organizationsTabSelector);
  const prevPage = useAppSelector(prevPageSelector);
  const [searchDebounce, setSearchDebounce] = useState('');
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [hasChangedTab, setHasChangedTab] = useState<boolean>(false);
  const tabs = [
    OrganizationStatuses.ACTIVE,
    OrganizationStatuses.INACTIVE,
    OrganizationStatuses.TERMINATED,
    OrganizationStatuses.PENDING,
  ];

  const tabsButtonStyles = (id: string) => ({
    BaseButton: {
      props: {
        id: `Organizations-tabs-${id}`,
      },
      style: {
        color: '#000',
        'font-size': '15px',
        'font-weight': '700',
      },
    },
  });

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    dispatch(resetShouldGoIntoMaintenancePage());
    dispatch(setOrganizationsSearch(e.target.value));
    setHasSearched(true);
  };

  const handleStatusTabClick = (tab: OrganizationStatuses) => {
    dispatch(setOrganizationsSearch(''));
    dispatch(setOrganizationsTab(tab));
    setHasChangedTab(true);
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();

    dispatch(fetchAllOrganizations({
      status: selectedTab,
      namePattern: search.trim(),
      pageNumber: page,
    }));
  };

  const renderTabs = () => tabs.map((tab) => (
    <div
      key={tab}
      className={tab === selectedTab
        ? css(activeTabButtonStyles)
        : css(tabButtonStyles)}
    >
      <Button
        kind={KIND.tertiary}
        overrides={tabsButtonStyles(tab)}
        onClick={() => handleStatusTabClick(tab)}
      >
        {t(`organizations:organizationStatuses.${tab}`)}
      </Button>
    </div>
  ));

  useEffect(() => {
    if (timer) clearTimeout(timer);

    if (search !== '') {
      timer = setTimeout(() => {
        timer = undefined;
        setSearchDebounce(search);
        setHasSearched(true);
      }, 1000);
    } else if (hasSearched) {
      setSearchDebounce('');
    }
  }, [search]);

  useEffect(() => {
    if (((search || search === '') && hasSearched) || hasChangedTab) {
      dispatch(fetchAllOrganizations({
        status: selectedTab,
        namePattern: search.trim(),
        pageNumber: '1',
      }));
    }
  }, [selectedTab, searchDebounce]);

  useEffect(() => {
    if (!prevPage.startsWith('/organizations')) {
      dispatch(resetOrganizations());
      dispatch(resetOrganization());
      dispatch(setOrganizationsSearch(''));
      dispatch(setOrganizationsTab(OrganizationStatuses.ACTIVE));
      dispatch(fetchAllOrganizations({
        status: OrganizationStatuses.ACTIVE,
        pageNumber: '1',
      }));
    }
    dispatch(setPrevPage(''));
  }, []);

  return (
    <div className={css(containerStyles)}>
      <CommonHeader title={t('organizations:organizations')}>
        {hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager]) && (
        <Block
          alignItems="center"
          display="inline-flex"
          justifyContent="flex-end"
          width="50%"
          minWidth="160px"
          height="72px"
        >
          <Link to="/organizations/create" className="link">
            <Button
              kind={KIND.primary}
              overrides={{
                BaseButton: {
                  props: {
                    id: 'OrganizationsHeader-add-organization',
                  },
                },
              }}
            >
              {t('organizations:newOrganization')}
            </Button>
          </Link>
        </Block>
        )}
      </CommonHeader>

      <Block>
        <Grid
          align={ALIGNMENT.center}
        >
          <Cell span={12} />
          <div className={css(activeTabContainerStyles)}>
            {renderTabs()}
            <div className={css(emptyTabButtonStyles)} />
          </div>
        </Grid>
      </Block>

      <Block
        position="relative"
        minHeight="460px"
      >
        <Loader active={pendingList} />

        <Block
          margin="12px"
        >
          <Grid
            align={ALIGNMENT.center}
          >
            <CellFormControl
              cellSpan={5}
            >
              <Block
                marginTop="16px"
              >
                <Input
                  clearable
                  clearOnEscape
                  startEnhancer={<Search />}
                  type="text"
                  name="search"
                  onChange={handleChangeSearch}
                  value={search}
                  placeholder={t('common:searchPlaceholder')}
                  overrides={{
                    Input: {
                      props: {
                        id: 'Organizations-search',
                        autoComplete: 'off',
                      },
                    },
                  }}
                />
              </Block>
            </CellFormControl>
          </Grid>
        </Block>

        <Block
          overrides={blockOverrides}
        >
          <Grid
            align={ALIGNMENT.center}
          >
            <Cell
              span={12}
            >
              {organizations?.length > 0
                ? (
                  <>
                    {[...organizations]
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      ?.map((organization) => <OrganizationListItem key={organization?.id} organization={organization} />)}
                    <Block
                      display="flex"
                      width="100%"
                      alignItems="center"
                      justifyContent="center"
                      justifyItems="center"
                      marginBottom="16px"
                    >
                      <Pagination
                        size={SIZE.compact}
                        numPages={numPages}
                        currentPage={pageNumber}
                        overrides={paginationTransparentOverrides}
                        onPageChange={handlePageChange}
                      />
                    </Block>
                  </>
                )
                : (
                  <Block
                    marginTop="8px"
                    marginBottom="8px"
                  >
                    <Grid gridMargins={0}>
                      <Cell span={12}>
                        <Card>
                          <p>{t('notFound')}</p>
                        </Card>
                      </Cell>
                    </Grid>
                  </Block>
                )}
            </Cell>
          </Grid>
        </Block>
      </Block>
    </div>
  );
};

export default memo(Organizations);
