import {
  useState,
  useEffect,
  ChangeEvent,
  memo,
} from 'react';
import moment from 'moment';
import { Checkbox } from 'baseui/checkbox';
import {
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic';
import { Button, KIND } from 'baseui/button';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'baseui';
import { Search } from 'baseui/icon';
import { useHistory, useParams } from 'react-router-dom';
import { Block } from 'baseui/block';
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid';
import { OnChangeParams, Select, Value } from 'baseui/select';
import { Input, SIZE } from 'baseui/input';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { DrawTransactionType } from 'types/BatchTypes';
import {
  fetchEmploymentPayrollPeriods,
  createDeductionBatches,
  employmentPayrollPeriodsSelector,
  resetEmployment,
  employmentPendingSelector,
  employmentPageNumberSelector,
  employmentTotalSizeSelector,
  employmentNumPagesSelector,
} from 'store/slices/employees';
import {
  fetchPayrollPeriods,
  payGroupPayrollPeriodsSelector,
  payGroupsSelector,
} from 'store/slices/payGroups';
import {
  loggedOrganizationSelector,
} from 'store/slices/loggedOrganization';
import CellFormControl from 'components/CellFormControl';
import PriceFormatter from 'utils/priceFormatter';
import {
  DeductionBatchesValuesType,
} from 'types/OrganizationTypes';
import {
  EmploymentPayroll,
} from 'types/EmployeeTypes';
import { unScalePrice } from 'utils/priceScale';
import Loader from 'components/Loader';
import { Card } from 'baseui/card';
import { Pagination } from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import {
  contentContainerStyles,
} from './BatchFormHelper';

const BatchForm = () => {
  const [css] = useStyletron();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['batches', 'errors', 'common', 'dateFormats']);
  const employmentPayrollPeriods = useAppSelector(employmentPayrollPeriodsSelector);
  const payGroups = useAppSelector(payGroupsSelector);
  const payGroupPayrollPeriods = useAppSelector(payGroupPayrollPeriodsSelector);
  const organization = useAppSelector(loggedOrganizationSelector);
  const pending = useAppSelector(employmentPendingSelector);
  const { batchID } = useParams<{ batchID: string }>();
  const [search, setSearch] = useState('');
  const [payGroup, setPayGroup] = useState<Value>();
  const [payrollPeriod, setPayrollPeriod] = useState<Value>();
  const [draws, setDraws] = useState<(EmploymentPayroll & DrawTransactionType)[]>([]);
  const [selectedDraws, setSelectedDraws] = useState<(EmploymentPayroll & DrawTransactionType)[]>([]);
  const numPages = useAppSelector(employmentNumPagesSelector);
  const pageNumber = useAppSelector(employmentPageNumberSelector);
  const totalSize = useAppSelector(employmentTotalSizeSelector);

  const filteredDraws = draws?.filter((item) => {
    if (search) {
      return (`${item?.firstName} ${item?.lastName}`)?.toLowerCase()?.includes(search?.toString()?.toLowerCase());
    }

    return true;
  });

  const { id: organizationID } = organization || {};
  const hasAny = Boolean(filteredDraws.length);
  const hasAll = hasAny && (filteredDraws.every((x) => x.selected) || filteredDraws.every((x) => selectedDraws.find((i) => i.id === x.id)));
  const hasSome = hasAny && filteredDraws.some((x) => x.selected);
  const totalAmount = selectedDraws.reduce((acc, item) => acc + unScalePrice(item?.drawsTotalAmount?.value, item?.drawsTotalAmount?.scale), 0);

  const dateFormat = t('dateFormats:standard');

  const toggleAll = () => {
    const filteredAndRemaped = filteredDraws?.map((row) => ({
      ...row,
      selected: !hasAll,
    }));
    setDraws(filteredAndRemaped);
    if (!hasAll) {
      setSelectedDraws(selectedDraws.concat(filteredAndRemaped.filter((draw) => !selectedDraws.find((row) => String(row.id) === String(draw.id)))));
    } else {
      setSelectedDraws(selectedDraws.filter((draw) => !filteredDraws.find((row) => String(row.id) === String(draw.id))));
    }
  };

  const toggle = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = event.currentTarget;

    setDraws(filteredDraws?.map((row) => ({
      ...row,
      selected: String(row.id) === name ? checked : row.selected,
    })));
    const targetedDraw = filteredDraws?.find((row) => String(row.id) === name);
    if (checked && targetedDraw) {
      setSelectedDraws(selectedDraws.concat([targetedDraw]));
    } else {
      setSelectedDraws(selectedDraws.filter(({ id }) => targetedDraw && targetedDraw.id !== id));
    }
  };

  const handleClickCancel = () => {
    history.push('/batches/section/0');
  };

  const handleClickSave = () => {
    const selectedDrawsFormatted: DeductionBatchesValuesType = { workerIds: selectedDraws.map((x) => String(x?.workerId)) };

    if (payrollPeriod) {
      dispatch(
        createDeductionBatches({
          organizationID,
          payrollPeriodID: Number(payrollPeriod[0]?.id),
          data: selectedDrawsFormatted,
        }),
      )
        .then(() => handleClickCancel());
    }
  };

  const handleClickSaveAll = () => {
    if (payrollPeriod) {
      dispatch(
        createDeductionBatches({
          organizationID,
          payrollPeriodID: Number(payrollPeriod[0]?.id),
          data: { workerIds: [] },
        }),
      )
        .then(() => handleClickCancel());
    }
  };
  const handleChangeSearch = (
    e: ChangeEvent<InputCustomHTMLElement>,
  ) => {
    setSearch(e.target.value);
  };

  const handlePayGroupChange = ({
    value,
  }: OnChangeParams) => {
    setPayrollPeriod(undefined);
    setPayGroup(value);
  };

  const handlePayrollPeriodChange = ({
    value,
  }: OnChangeParams) => {
    setPayrollPeriod(value);
  };

  useEffect(() => {
    dispatch(resetEmployment());

    return () => {
      dispatch(resetEmployment());
    };
  }, []);

  useEffect(() => {
    if (organizationID && payGroup && payGroup[0]?.id) {
      dispatch(fetchPayrollPeriods({ organizationID, payGroupID: payGroup[0]?.id?.toString() }));
    } else {
      setPayrollPeriod([]);
    }
  }, [organizationID, payGroup]);

  useEffect(() => {
    if (payrollPeriod && payrollPeriod?.length > 0) {
      setSelectedDraws([]);
      dispatch(fetchEmploymentPayrollPeriods({ payrollPeriodID: String(payrollPeriod[0]?.id) }));
    } else {
      setDraws([]);
    }
  }, [payrollPeriod]);

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    if (payrollPeriod && payrollPeriod?.length > 0) {
      const page = Math.min(Math.max(nextPage, 1), totalSize).toString();
      dispatch(fetchEmploymentPayrollPeriods({
        payrollPeriodID: String(payrollPeriod[0]?.id),
        pageNumber: page,
      }));
    }
  };
  useEffect(() => {
    setDraws(employmentPayrollPeriods?.map((item): EmploymentPayroll & DrawTransactionType => ({
      id: item?.id,
      firstName: item.firstName || '',
      lastName: item.lastName || '',
      email: item.email || '',
      organization: {
        id: item.organization?.id,
        name: item.organization?.name,
      },
      externalUserId: item.externalUserId,
      status: item.status,
      statusEffectiveDate: item.statusEffectiveDate,
      payrollId: item.payrollId,
      drawsTotalAmount: item?.drawsTotalAmount,
      workerId: item?.workerId,
      selected: false,
    })));
  }, [employmentPayrollPeriods]);

  return (
    <div className={css(contentContainerStyles)}>
      <CommonHeader title={t(batchID ? 'batches:editBatch' : 'batches:newBatch')} backTo="/batches/section/0">
        <Cell
          span={[3]}
          align={ALIGNMENT.center}
        >
          <Block
            alignItems="center"
            justifyContent="flex-end"
            display="flex"
            height="72px"
          >
            <Block
              display="inline-flex"
              marginRight="16px"
            >
              <Button
                type="button"
                kind={KIND.secondary}
                onClick={handleClickCancel}
                overrides={{
                  Root: {
                    props: {
                      id: 'batch-form-cancel-button',
                    },
                  },
                }}
              >
                {t('common:cancel')}
              </Button>
            </Block>

            <Button
              type="button"
              kind={KIND.primary}
              onClick={handleClickSave}
              disabled={!(selectedDraws && selectedDraws?.length > 0)}
              overrides={{
                Root: {
                  props: {
                    id: 'batch-form-save-button',
                  },
                },
              }}
            >
              {t('common:save')}
            </Button>
          </Block>
        </Cell>
      </CommonHeader>

      <div className={css(contentContainerStyles)}>
        <Loader active={pending} />

        <Grid
          gridColumns={12}
        >
          <CellFormControl
            showStar
            cellSpan={[12, 6, 4]}
            label={t('batches:payGroup.label')}
          >
            <Select
              size={SIZE.compact}
              id="pay-group-select"
              clearable
              placeholder={t('common:select')}
              type="select"
              options={payGroups
                ?.map(({ name, id }) => ({
                  name,
                  id,
                }))
                ?.sort((a, b) => a.name.localeCompare(b.name))}
              labelKey="name"
              valueKey="id"
              onChange={handlePayGroupChange}
              value={payGroup as Value}
              maxDropdownHeight="300px"
              overrides={{
                ControlContainer: {
                  props: {
                    id: 'batch-form-pay-group-select',
                  },
                },
              }}
            />
          </CellFormControl>

          <CellFormControl
            showStar
            cellSpan={[12, 6, 4]}
            label={t('batches:payPeriod.label')}
          >
            <Select
              size={SIZE.compact}
              id="pay-period-select"
              clearable
              disabled={!payGroup || payGroup?.length === 0}
              type="select"
              options={payGroupPayrollPeriods
                ?.filter(({ status }) => status === 'LOCKED' || status === 'OPEN')
                ?.map(({ startDate, endDate, id }) => ({
                  name: `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`,
                  id,
                }))}
              labelKey="name"
              valueKey="id"
              onChange={handlePayrollPeriodChange}
              value={payrollPeriod as Value}
              placeholder={t('common:select')}
              maxDropdownHeight="300px"
              overrides={{
                ControlContainer: {
                  props: {
                    id: 'batch-form-pay-period-select',
                  },
                },
              }}
            />
          </CellFormControl>
        </Grid>

        <Grid
          gridColumns={12}
        >
          <Cell
            span={12}
          >
            <hr />
          </Cell>
        </Grid>

        {draws?.length > 0 && (
          <Block marginTop="16px">
            <Grid
              gridGaps={16}
              gridColumns={12}
              align={ALIGNMENT.start}
            >
              <CellFormControl
                cellSpan={[12, 6, 4]}
                cellAlign={ALIGNMENT.start}
              >
                <Input
                  size={SIZE.compact}
                  startEnhancer={<Search />}
                  autoComplete="off"
                  type="text"
                  name="search"
                  onChange={handleChangeSearch}
                  value={search}
                  placeholder={t('batches:searchEmployees.placeholder')}
                  clearOnEscape
                  clearable
                  id="batch-form-search-employees"
                />
              </CellFormControl>
              <CellFormControl
                cellSpan={[12, 6, 4]}
                cellAlign={ALIGNMENT.start}
              >
                <Button
                  overrides={{
                    Root: {
                      style: {
                        paddingTop: '8px',
                        paddingBottom: '8px',
                      },
                      props: {
                        id: 'batches-create-new-batch-for-all',
                      },
                    },
                  }}
                  kind={KIND.primary}
                  onClick={handleClickSaveAll}
                >
                  {t('batches:createForAll')}
                </Button>
              </CellFormControl>
            </Grid>

            <Grid
              gridColumns={12}
              gridGaps={32}
              align={ALIGNMENT.start}
            >
              <Cell
                align={ALIGNMENT.center}
                span={[12, 4]}
              >
                <strong>
                  {`${t('batches:batchSelection')}:`}
                </strong>
              </Cell>

              <Cell
                align={ALIGNMENT.center}
                span={[12, 4]}
              >
                {`${t('batches:employees')}: `}
                <strong>{selectedDraws?.length || 0}</strong>
              </Cell>

              <Cell
                align={ALIGNMENT.center}
                span={[12, 4]}
              >
                {`${t('batches:totalAmount')}: `}
                <strong>{PriceFormatter().format(totalAmount)}</strong>
              </Cell>

            </Grid>

            <Grid
              gridColumns={12}
            >
              <Cell
                span={12}
              >
                <TableBuilder data={filteredDraws}>
                  <TableBuilderColumn
                    overrides={{
                      TableHeadCell: { style: { width: '1%' } },
                      TableBodyCell: { style: { width: '1%' } },
                    }}
                    header={(
                      <Checkbox
                        checked={hasAll}
                        isIndeterminate={!hasAll && hasSome}
                        onChange={toggleAll}
                        overrides={{
                          Root: {
                            props: {
                              id: 'batch-form-has-all-checkbox',
                            },
                          },
                          Input: {
                            props: {
                              'aria-checked': `${hasAll}`,
                            },
                          },
                        }}
                      />
                    )}
                  >
                    {(row: any) => (
                      <Checkbox
                        name={row.id}
                        checked={!!selectedDraws.find(({ id }) => id === row.id)}
                        onChange={toggle}
                        overrides={{
                          Root: {
                            props: {
                              id: `batch-form-${row.id}-checkbox`,
                            },
                          },
                          Input: {
                            props: {
                              'aria-checked': `${!!selectedDraws.find(({ id }) => id === row.id)}`,
                            },
                          },
                        }}
                      />
                    )}
                  </TableBuilderColumn>

                  <TableBuilderColumn header={t('batches:employee')}>
                    {(row: any) => `${row.firstName} ${row.lastName}`}
                  </TableBuilderColumn>

                  <TableBuilderColumn header={t('batches:payrollID')}>
                    {(row: any) => row.payrollId}
                  </TableBuilderColumn>

                  <TableBuilderColumn header={t('batches:amount')}>
                    {(row: any) => PriceFormatter().format(unScalePrice(row.drawsTotalAmount.value, row.drawsTotalAmount.scale))}
                  </TableBuilderColumn>
                </TableBuilder>
              </Cell>
            </Grid>
            <Block
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
              marginBottom="16px"
            >
              <Pagination
                size={SIZE.compact}
                numPages={numPages}
                currentPage={pageNumber}
                overrides={paginationTransparentOverrides}
                onPageChange={handlePageChange}
              />
            </Block>
          </Block>
        )}

        {filteredDraws?.length === 0 && payGroup ? (
          <Block
            marginTop="16px"
            marginBottom="24px"
          >
            <Grid
              gridColumns={12}
            >
              <Cell
                span={12}
              >
                <Card>
                  <p>{t('batches:employeesNotFound')}</p>
                </Card>
              </Cell>
            </Grid>
          </Block>
        ) : null}
      </div>
    </div>
  );
};

export default memo(BatchForm);
