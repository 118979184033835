import { borderRadius, colors } from 'theme';

export const chooseOrganizationContainerStyles: any = {
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  background: '#E5E5E5',
  position: 'fixed',
  top: '0px',
  left: '0px',
  color: '#fff',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const chooseOrganizationMainDivStyles: any = {
  backgroundColor: '#fff',
  maxWidth: '470px',
  width: '100%',
  minWidth: '28%',
  padding: '56px',
  boxSizing: 'border-box',
  marginTop: '32px',
  overflow: 'auto',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
};

export const chooseOrganizationHeadingStyles: any = {
  color: colors.primary,
  textAlign: 'center',
  fontSize: '52px',
};

export const formOverride: any = {
  Label: {
    style: {
      textAlign: 'center',
      paddingBottom: '15px',
    },
  },
};

export const organizationContinueButtonOverrides = {
  Root: {
    props: {
      id: 'ChooseOrganization-continue',
    },
    style: {
      width: '100%',
    },
  },
};

export const newOrganizationButtonOverrides = {
  Root: {
    props: {
      id: 'ChooseOrganization-set-up-new-organization',
    },
    style: {
      width: '100%',
    },
  },
};
