import { SubElementStatusType } from 'types/EmployeeTypes';
import { StyleObject } from 'styletron-react';

export const blockOverrides = {
  Block: {
    style: {
      marginTop: '8px',
      marginBottom: '8px',
      lineHeight: '30px',
      fontWeight: 500,
      color: '#5F6368',
      fontSize: '12px',
    },
  },
};
export const employmentStatuses = [
  { id: 'ACTIVE', name: 'ACTIVE' },
  { id: 'INACTIVE', name: 'INACTIVE' },
  { id: 'TERMINATED', name: 'TERMINATED' },
  { id: 'MERGED', name: 'MERGED' },
  { id: 'BAD_DATA', name: 'BAD DATA' },
];

export const TPOEmploymentStatuses = [
  { id: 'ACTIVE', name: 'ACTIVE' },
  { id: 'INACTIVE', name: 'INACTIVE' },
  { id: 'TERMINATED', name: 'TERMINATED' },
  { id: 'MERGED', name: 'MERGED' },
];

export const enrollmentStatuses = [
  { id: '*', name: 'ENROLLED' },
  { id: 'null', name: 'NOT ENROLLED' },
];

export const containerStyles = {
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 100,
  'box-sizing': 'border-box',
  overFlow: 'hidden',
  padding: '0',
} as StyleObject;

export const profileContainerStyles = {
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  zIndex: 100,
} as StyleObject;

export const offersLoaderContainerStyles = {
  marginTop: '-8px',
  width: '28px',
  height: '28px',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 100,
} as StyleObject;

export const offerBtnOverrides = (id: number) => ({
  Root: {
    style: {
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      backgroundColor: 'transparent',
    },
    props: {
      id: `EmployeeDetailsProfileSection-handle-offer-${id}`,
      'data-testid': 'EmployeeDetailsProfileSection-handle-offer',
    },
  },
});

export const formControlOverrides = {
  Label: {
    style: {
      lineHeight: '30px',
      fontWeight: 500,
      color: '#5F6368',
      fontSize: '12px',
    },
  },
};

export const showOffersBtnOverrides = {
  Root: {
    style: {
      marginTop: '18px',
    },
    props: {
      id: 'EmployeeDetailsProfileSection-show-offers-btn',
      'data-testid': 'EmployeeDetailsProfileSection-show-offers',
    },
  },
};

export const offerAmountStyles = {
  fontSize: '24px',
};

export const outerContainerStyles = {
  marginTop: '32px',
  overflow: 'hidden',
  position: 'relative',
} as StyleObject;

export const tableStyles = {
  border: '1px solid #ccc',
  overflow: 'auto',
};

export const tableHeaderStyles = {
  paddingTop: '8px',
  paddingBottom: '8px',
  borderBottom: '1px solid #ccc',
  minWidth: '960px',
  overflow: 'hidden',
  'box-sizing': 'border-box',
};

export const drawsListContainerStyles = {
  width: '100%',
  height: '100%',
  position: 'relative',
  minWidth: '960px',
  overflow: 'hidden',
  zIndex: 0,
} as StyleObject;

export const accordionDrawsListItemOverrides = {
  Content: {
    style: {
      backgroundColor: '#fff',
      position: 'relative',
      zIndex: 0,
    } as StyleObject,
  },
  Header: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  ToggleIcon: {
    style: {
      display: 'flex',
      width: '16px',
      height: '16px',
      marginLeft: 'auto',
      marginTop: '-70px',
      zIndex: 1000,
      position: 'relative',
      '@media (max-width: 1680px)': {
        marginTop: '-90px',
      },
    } as StyleObject,
  },
};

export const listContainerStyles = {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  minHeight: '120px',
} as StyleObject;

export const statusOptions = [
  {
    key: 1,
    value: SubElementStatusType.CREATED,
  },
  {
    id: 2,
    value: SubElementStatusType.ACCEPTED,
  },
  {
    id: 3,
    value: SubElementStatusType.COMPLETED,
  },
  {
    id: 4,
    value: SubElementStatusType.DISPUTED,
  },
  {
    id: 5,
    value: SubElementStatusType.VOIDED,
  },
  {
    id: 6,
    value: SubElementStatusType.DECLINED,
  },
  {
    id: 7,
    value: SubElementStatusType.REFUNDED,
  },
  {
    id: 8,
    value: SubElementStatusType.SETTLED,
  },
  {
    id: 9,
    value: SubElementStatusType.PARTIALLY_SETTLED,
  },
  {
    id: 10,
    value: SubElementStatusType.PROCESSED,
  },
];

export enum PositiveSubElementStatusTypesEnum {
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  PROCESSED = 'PROCESSED',
  SETTLED = 'SETTLED',
  PARTIALLY_SETTLED = 'PARTIALLY_SETTLED',
}

export const checkIfSubElementStatusTypeIsPositive = (status: string) => (
  Object.values(PositiveSubElementStatusTypesEnum).includes(status as PositiveSubElementStatusTypesEnum)
);

export const onDemandPayContractedPayRateStyles = {
  display: 'flex',
  width: '100%',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
} as StyleObject;
