import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWorkerRequest } from 'api/employmentsAPI';
import {
  fetchWorkerAccountBalanceRequest,
  fetchWorkerAccountExternalRequest,
} from 'bankingApi/workerAccountAPI';
import { BalanceType } from 'types/MasterAccountTypes';
import {
  ExternalAccountType,
  fetchWorkerAccountBalancePropsType,
  FetchWorkerAccountExternalParamsType,
} from 'types/WorkerAccountTypes';
import { Worker } from 'types/WorkerTypes';
import { RootState } from '..';

export const initialState = {
  pending: false,
  balance: {} as BalanceType,
  worker: {} as Worker,
  areFetched: false,
  externalAccounts: {
    areFetched: false,
    pendingList: false,
    list: [] as ExternalAccountType[],
  },
};

export const fetchWorkerAccountExternal = createAsyncThunk(
  'worker/externalAccounts',
  async (params: FetchWorkerAccountExternalParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken } } = getState() as RootState;
    const { workerID } = params;
    try {
      return await fetchWorkerAccountExternalRequest(accessToken, workerID);
    } catch (error: any) {
      return error?.response?.status !== 400 && rejectWithValue(error);
    }
  },
);

export const fetchWorkerAccountBalance = createAsyncThunk(
  'workerAccount/balance',
  async (params: fetchWorkerAccountBalancePropsType, { getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken } } = getState() as RootState;
    const { workerID } = params;

    try {
      return await fetchWorkerAccountBalanceRequest(accessToken, workerID);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchWorkerAccountWithBalance = createAsyncThunk(
  'workerAccount/worker',
  async (params: fetchWorkerAccountBalancePropsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken } } = getState() as RootState;
    const { workerID } = params;

    try {
      const result = await fetchWorkerRequest(accessToken, workerID);

      await dispatch(fetchWorkerAccountBalance({ workerID }));

      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const workerAccountSlice = createSlice({
  name: 'workerAccount',
  initialState,
  reducers: {
    resetWorkerAccount: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkerAccountExternal.pending, (state) => {
      state.externalAccounts.pendingList = true;
      state.externalAccounts.areFetched = false;
      state.externalAccounts.list = initialState.externalAccounts.list;
    });

    builder.addCase(fetchWorkerAccountExternal.fulfilled, (state, { payload }) => {
      state.externalAccounts.list = payload?.values || [];
      state.externalAccounts.pendingList = false;
      state.externalAccounts.areFetched = true;
    });

    builder.addCase(fetchWorkerAccountExternal.rejected, (state) => {
      state.externalAccounts.list = initialState.externalAccounts.list;
      state.externalAccounts.pendingList = false;
      state.externalAccounts.areFetched = false;
    });

    builder.addCase(fetchWorkerAccountBalance.pending, (state) => {
      state.balance = initialState.balance;
    });

    builder.addCase(fetchWorkerAccountBalance.fulfilled, (state, { payload }) => {
      state.balance = payload;
    });

    builder.addCase(fetchWorkerAccountBalance.rejected, (state) => {
      state.balance = initialState.balance;
    });

    builder.addCase(fetchWorkerAccountWithBalance.pending, (state) => {
      state.worker = initialState.worker;
      state.pending = true;
      state.areFetched = false;
    });

    builder.addCase(fetchWorkerAccountWithBalance.fulfilled, (state, { payload }) => {
      state.worker = payload;
      state.pending = false;
      state.areFetched = true;
    });

    builder.addCase(fetchWorkerAccountWithBalance.rejected, (state) => {
      state.worker = initialState.worker;
      state.pending = false;
      state.areFetched = true;
    });
  },
});

export const {
  resetWorkerAccount,
} = workerAccountSlice.actions;

export const workerAccountPendingSelector = (state: RootState): boolean => state.workerAccount.pending;
export const workerAccountAreFetchedSelector = (state: RootState): boolean => state.workerAccount.areFetched;
export const workerAccountBalanceSelector = (state: RootState): BalanceType => state.workerAccount.balance;
export const workerAccountWorkerSelector = (state: RootState): Worker => state.workerAccount.worker;

// External
export const workerAccountExternalSelector = (state: RootState): ExternalAccountType[] => state.workerAccount.externalAccounts.list;
export const workerAccountExternalPendingListSelector = (state: RootState): boolean => state.workerAccount.externalAccounts.pendingList;
export const workerAccountExternalAreFetchedSelector = (state: RootState): boolean => state.workerAccount.externalAccounts.areFetched;

export default workerAccountSlice.reducer;
