import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchAllBatchesRequest,
  fetchBatchRequest,
} from 'api/batchesAPI';
import {
  DrawTransactionType,
  FetchBatchParamsType,
  FetchAllBatchesParamsType,
  BatchItem,
} from 'types/BatchTypes';
import { SearchDropDown } from 'types/CommonTypes';
import { PayGroup } from './payGroups';
import { RootState } from '..';

export interface Batch {
  id?: string
  name?: string
  draws?: DrawTransactionType[]
  csv?: string
}

export const initialState = {
  pendingList: false,
  list: [] as BatchItem[],
  pageNumber: 1 as number,
  pageSize: 50 as number,
  totalSize: 0 as number,
  numPages: 1 as number,
  batch: null as Batch | null,
  payGroup: null as PayGroup | null,
  ewaBatchesSearch: {
    searchPattern: '',
    searchPayGroup: [] as SearchDropDown[],
    searchPayPeriod: [] as SearchDropDown[],
    searchBatchStatus: [] as any,
    searchBatchCreationTrigger: [] as any,
    searchDateCreated: '' as any,
    searchShowEmptyBatches: false,
  },
  tpoBatchesSearch: {
    searchPattern: '',
    searchPayGroup: [] as SearchDropDown[],
    searchPayPeriod: [] as SearchDropDown[],
    searchDateCreated: '' as any,
    searchBatchStatus: [] as any,
  },
};

export const fetchAllBatches = createAsyncThunk(
  'batches/fetchAll',
  async (params: FetchAllBatchesParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
      pageNumber,
      status,
      creationTrigger,
      payGroupId,
      payrollPeriodId,
      deductionCount,
      creationDate,
      types,
    } = params;
    const storeState = getState() as RootState;

    try {
      const result = await (
        fetchAllBatchesRequest(
          storeState.user.accessToken,
          organizationID,
          pageNumber,
          payGroupId,
          payrollPeriodId,
          status,
          creationTrigger,
          deductionCount,
          creationDate,
          types,
        )
      );
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchBatch = createAsyncThunk(
  'batches/fetchBatch',
  async (params: FetchBatchParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { batchID } = params;
    const storeState = getState() as RootState;

    try {
      return {
        id: batchID,
        csv: await fetchBatchRequest(storeState.user.accessToken, batchID),
      };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const batchesSlice = createSlice({
  name: 'batches',
  initialState,
  reducers: {
    resetBatches: (state) => {
      state.list = initialState.list;
      state.batch = initialState.batch;
      state.pendingList = initialState.pendingList;
      state.pageNumber = initialState.pageNumber;
      state.pageSize = initialState.pageSize;
      state.totalSize = initialState.totalSize;
      state.numPages = initialState.numPages;
    },
    setBatchesPage: (state, action) => {
      state.pageNumber = action.payload;
    },
    setEWASearchPattern: (state, action) => {
      state.ewaBatchesSearch.searchPattern = action.payload;
    },
    setEWASearchPayGroup: (state, action) => {
      state.ewaBatchesSearch.searchPayGroup = action.payload;
    },
    setEWASearchPayPeriod: (state, action) => {
      state.ewaBatchesSearch.searchPayPeriod = action.payload;
    },
    setEWASearchBatchStatus: (state, action) => {
      state.ewaBatchesSearch.searchBatchStatus = action.payload;
    },
    setEWASearchBatchCreationTrigger: (state, action) => {
      state.ewaBatchesSearch.searchBatchCreationTrigger = action.payload;
    },
    setEWASearchDateCreated: (state, action) => {
      state.ewaBatchesSearch.searchDateCreated = action.payload;
    },
    setEWASearchShowEmptyBatches: (state, action) => {
      state.ewaBatchesSearch.searchShowEmptyBatches = action.payload;
    },
    resetEWABatchSearch: (state) => {
      state.ewaBatchesSearch = initialState.ewaBatchesSearch;
    },
    setTPOBatchSearchPattern: (state, action) => {
      state.tpoBatchesSearch.searchPattern = action.payload;
    },
    setTPOBatchSearchPayGroup: (state, action) => {
      state.tpoBatchesSearch.searchPayGroup = action.payload;
    },
    setTPOBatchSearchPayPeriod: (state, action) => {
      state.tpoBatchesSearch.searchPayPeriod = action.payload;
    },
    setTPOBatchSearchDateCreated: (state, action) => {
      state.tpoBatchesSearch.searchDateCreated = action.payload;
    },
    setTPOSearchBatchStatus: (state, action) => {
      state.tpoBatchesSearch.searchBatchStatus = action.payload;
    },
    resetTPOBatchSearch: (state) => {
      state.tpoBatchesSearch = initialState.tpoBatchesSearch;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllBatches.pending, (state) => {
      state.list = initialState.list;
      state.pendingList = true;
    });

    builder.addCase(fetchAllBatches.fulfilled, (state, action) => {
      state.list = action.payload.values;
      state.pendingList = false;
      state.pageNumber = action.payload.pageNumber;
      state.pageSize = action.payload.pageSize;
      state.totalSize = action.payload.totalSize;
      state.numPages = (Math.ceil(action.payload.totalSize / initialState.pageSize));
    });

    builder.addCase(fetchAllBatches.rejected, (state) => {
      state.list = initialState.list;
      state.pendingList = false;
    });

    builder.addCase(fetchBatch.fulfilled, (state, action) => {
      state.batch = {
        id: action.payload.id,
        csv: action.payload.csv,
      };
    });

    builder.addCase(fetchBatch.rejected, (state) => {
      state.batch = initialState.batch;
    });
  },
});

export const {
  resetBatches,
  setBatchesPage,
  setEWASearchPattern,
  setEWASearchPayGroup,
  setEWASearchPayPeriod,
  setEWASearchBatchStatus,
  setEWASearchBatchCreationTrigger,
  setEWASearchDateCreated,
  setEWASearchShowEmptyBatches,
  resetEWABatchSearch,
  setTPOBatchSearchPattern,
  setTPOBatchSearchPayGroup,
  setTPOBatchSearchPayPeriod,
  setTPOBatchSearchDateCreated,
  setTPOSearchBatchStatus,
  resetTPOBatchSearch,
} = batchesSlice.actions;
export const batchesSelector = (state: RootState): BatchItem[] => state.batches.list;
export const batchSelector = (state: RootState): Batch | null | undefined => state.batches.batch;
export const batchesPendingListSelector = (state: RootState): boolean => state.batches.pendingList;
export const batchesPageNumberSelector = (state: RootState) => state.batches.pageNumber;
export const batchesPageSizeSelector = (state: RootState) => state.batches.pageSize;
export const batchesTotalSizeSelector = (state: RootState) => state.batches.totalSize;
export const batchesNumPagesSelector = (state: RootState) => state.batches.numPages;
export const batchesSearchPatternSelector = (state: RootState): string => state.batches.ewaBatchesSearch.searchPattern;
export const batchesSearchPayGroupSelector = (state: RootState) => state.batches.ewaBatchesSearch.searchPayGroup;
export const batchesSearchPayPeriodSelector = (state: RootState) => state.batches.ewaBatchesSearch.searchPayPeriod;
export const batchesSearchBatchStatusSelector = (state: RootState) => state.batches.ewaBatchesSearch.searchBatchStatus;
export const batchesSearchBatchCreationTriggerSelector = (state: RootState) => state.batches.ewaBatchesSearch.searchBatchCreationTrigger;
export const batchesSearchDateCreatedSelector = (state: RootState) => state.batches.ewaBatchesSearch.searchDateCreated;
export const batchesSearchShowEmptyBatchesSelector = (state: RootState): boolean => state.batches.ewaBatchesSearch.searchShowEmptyBatches;
export const batchesTPOSearchPatternSelector = (state: RootState): string => state.batches.tpoBatchesSearch.searchPattern;
export const batchesTPOSearchPayGroupSelector = (state: RootState) => state.batches.tpoBatchesSearch.searchPayGroup;
export const batchesTPOSearchPayPeriodSelector = (state: RootState) => state.batches.tpoBatchesSearch.searchPayPeriod;
export const batchesTPOSearchDateCreatedSelector = (state: RootState) => state.batches.tpoBatchesSearch.searchDateCreated;
export const batchesTPOSearchBatchStatusSelector = (state: RootState) => state.batches.tpoBatchesSearch.searchBatchStatus;

export default batchesSlice.reducer;
