import { memo } from 'react';
import { WorkerResultPropsType } from 'types/WorkerTypes';
import { Cell, Grid } from 'baseui/layout-grid';

const WorkerResult = (props: WorkerResultPropsType) => {
  const { worker } = props;
  const {
    firstName,
    lastName,
    email,
    phone,
  } = worker || {};

  return (
    <Grid
      gridColumns={12}
      gridMargins={16}
    >
      <Cell
        span={12}
      >
        {worker && (
          <h4>{`${firstName} ${lastName}, ${email}, ${phone}`}</h4>
        )}
      </Cell>
    </Grid>
  );
};

export default memo(WorkerResult);
