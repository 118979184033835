import { Button, KIND } from 'baseui/button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStyletron } from 'baseui';
import {
  Employee,
  EmployeeResponseType,
} from 'types/EmployeeTypes';
import { borderRadius, emptyPlaceholder } from 'theme';
import AppChip from 'components/AppChip/AppChip';
import EmployeeStatusTag from 'components/EmployeeStatusTag';
import { useAppDispatch } from 'store/hooks';
import { setPrevPage } from 'store/slices/application';

const listStyles = {
  padding: '0px',
};

const listItemStyles = {
  background: '#FFFFFF',
  'list-style': 'none',
  padding: '8px',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
};

const listItemNameAndStatuses = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '4px',
};

const listItemInfoStyles = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '0px 4px 16px 20px',
};

const detailsStyles = {
  display: 'inline-block',
  margin: '4px 16px 4px 0',
};

const enrolledStatusStyle = {
  background: '#E0F1E5',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  padding: '7px',
  marginRight: '10px',
  color: '#4A5456',
  'font-weight': '700',
  'font-size': '14px',
};

const notEnrolledStatusStyle = {
  background: '#fce280',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  padding: '7px',
  marginRight: '10px',
  color: '#4A5456',
  'font-weight': '700',
  'font-size': '14px',
};

const EmployeeListItem = ({
  employee,
}: { employee: Employee | EmployeeResponseType }) => {
  const { t } = useTranslation(['common', 'employees']);
  const [css] = useStyletron();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleEmployeeClick = (id: number) => {
    dispatch(setPrevPage('/loggedOrganization/employees'));
    history.push(`/loggedOrganization/employees/${id}`);
  };

  const payGroupsNames = (employee?.assignments && employee?.assignments[0]?.location?.payGroup?.name)
    ? employee?.assignments
      ?.map((item) => item?.location?.payGroup?.name)
      .filter((item, index, array) => array.indexOf(item) === index)
    : emptyPlaceholder;

  const locationsNames = employee?.assignments
    ? employee?.assignments
      ?.map((item) => item?.location?.name)
      .filter((item, index, array) => array.indexOf(item) === index)
    : emptyPlaceholder;

  return (
    <ul className={css(listStyles)}>
      <li className={css(listItemStyles)}>
        <div className={css(listItemNameAndStatuses)}>
          <Button
            kind={KIND.tertiary}
            onClick={() => handleEmployeeClick(Number(employee?.id))}
            overrides={{
              Root: {
                props: {
                  id: `EmployeesListItem-employee-${employee?.id}-title`,
                },
                style: {
                  maxWidth: '70%',
                },
              },
            }}
          >
            <span className="ellipsis">{`${employee?.firstName} ${employee?.lastName}`}</span>
          </Button>

          {employee?.status && (
            <EmployeeStatusTag status={employee.status} />
          )}
        </div>

        <div className={css(listItemInfoStyles)}>
          <div>
            <span className={css(employee?.workerId
              ? enrolledStatusStyle
              : notEnrolledStatusStyle)}
            >
              {t(
                `employees:${employee?.workerId
                  ? 'enrolled'
                  : 'notEnrolled'}`,
              )}
            </span>

            <span className={css(detailsStyles)}>
              {t('employees:payrollEmployeeId')}
              :
              <AppChip
                items={employee.payrollId || emptyPlaceholder}
              />
            </span>
            <span className={css(detailsStyles)}>
              {t('employees:payGroup')}
              :
              <AppChip
                items={payGroupsNames}
              />
            </span>
            <span className={css(detailsStyles)}>
              {t('employees:location')}
              :
              <AppChip
                items={locationsNames}
              />
            </span>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default EmployeeListItem;
