import { Value } from 'baseui/select';
import {
  EmployeeValuesType,
  ZendeskTicketValuesType,
} from 'types/EmployeeTypes';
import { PaymentOptionsType } from 'types/WorkerAccountTypes';

export const employeeZendeskInitialValues: ZendeskTicketValuesType = {
  ticketStatuses: [{ value: '' }],
  ticketNumber: 0,
};

export const employeeLinkToEmployerInitialValues = {
  workerID: '',
  organization: [{ value: '' }] as Value,
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  workerId: '',
};

export const generateDirectDepositInitialValues = {
  ddReportTime: 'ALL_TIME',
  fromDate: '' as any,
  toDate: [new Date()],
};

export const employeeInitialValues: EmployeeValuesType = {
  id: null,
  organization: {
    id: null,
    name: '',
  },
  enrollmentStatus: [{ value: '' }],
  payrollEmployeeId: '',
  linkedAccounts: [],
  locations: [],
  offerPercentPerEmployee: '',
  lowerEarningsThreshold: 0,
  tpoOfferPercentPerEmployee: '',
  employeeType: [{ value: 'HOURLY_PAID' }],
};

export const employeeDetailsProfileInitialValues = {
  id: null,
  organization: {
    id: null,
    name: '',
  },
  enrollmentStatus: [{ value: '' }],
  payrollEmployeeId: '',
  locations: [],
};

export const employeeDetailsOnDemandPayInitialValues = {
  offerPercentPerEmployee: '',
  lowerEarningsThreshold: 0,
  tpoOfferPercentPerEmployee: '',
  workerId: null,
  programs: [],
  enrollmentDate: '',
  employeeType: [{ value: 'HOURLY_PAID' }],
};

export const employeeDetailsOnDemandPayRatesInitialValues = {
  contractedHours: 0,
  YEARLY: 0,
  HOURLY: 0,
  PERIOD: 0,
};

export const resendMoneyMfaInitialValues = {
  paymentMethod: PaymentOptionsType,
  refundTransferFee: false,
  refundDrawFee: false,
  zendeskTicket: undefined,
  notes: '',
};
