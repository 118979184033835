import {
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'baseui';
import AccordionSection from 'components/AccordionSection/AccordionSection';
import {
  employeeDetailsPendingSelector,
  employeeDetailsSelector,
  employeeDirectDepositEnrollmentPendingSelector,
  employeeDirectDepositEnrollmentSelector,
  fetchDirectDepositEnrollmentByWorkerId,
} from 'store/slices/employees';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  LabelMedium,
  LabelSmall,
} from 'baseui/typography';
import {
  Tag,
  VARIANT,
} from 'baseui/tag';
import moment from 'moment';
import Loader from 'components/Loader';
import { emptyPlaceholder } from 'theme';
import { Input, SIZE } from 'baseui/input';
import { Block } from 'baseui/block';
import hasAccess from 'utils/hasAccess';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import { profileContainerStyles } from '../../EmployeesHelpers';

export const contentContainerStyles = {
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: '24px',
  paddingBottom: '24px',
  background: '#E5E5E5',
  zIndex: 50,
  minHeight: '100vh',
};

export type EmployeeDetailsDirectDepositSectionPropsType = {
    expanded?: boolean
  }

const EmployeeDetailsDirectDepositSection = ({
  expanded,
}: EmployeeDetailsDirectDepositSectionPropsType) => {
  const [css] = useStyletron();
  const theme = useStyletron()[1];
  const { t } = useTranslation(['common', 'employees']);
  const dispatch = useAppDispatch();

  const employeeDetails = useAppSelector(employeeDetailsSelector);
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);

  const enrollmentInfo = useAppSelector(employeeDirectDepositEnrollmentSelector);
  const pendingEnrollmentInfo = useAppSelector(employeeDirectDepositEnrollmentPendingSelector);
  const pendingEmployeeDetails = useAppSelector(employeeDetailsPendingSelector);
  const [isExpanded, setIsExpanded] = useState(false);

  const directDepositProgram = employeeDetails && employeeDetails.programs.find((i) => i.includes('DIRECT_DEPOSIT'));

  const handleOnExpand = () => {
    setIsExpanded(true);
    employeeDetails?.workerId && directDepositProgram === 'DIRECT_DEPOSIT_FUEGO'
      && dispatch(fetchDirectDepositEnrollmentByWorkerId({ organizationID: loggedOrganization?.id, workerID: employeeDetails?.workerId }))
        .then(() => setIsExpanded(false));
  };

  useEffect(() => {
    employeeDetails?.workerId && isExpanded && directDepositProgram === 'DIRECT_DEPOSIT_FUEGO'
    && dispatch(fetchDirectDepositEnrollmentByWorkerId({ organizationID: loggedOrganization?.id, workerID: employeeDetails?.workerId }));
  }, [employeeDetails?.workerId]);

  return (
    <AccordionSection
      expanded={expanded}
      title={t('employees:directDeposit.section.header')}
      showStatus={false}
      disabled={false}
      onExpand={handleOnExpand}
    >
      <div className={css(profileContainerStyles)}>
        <Loader active={pendingEnrollmentInfo || pendingEmployeeDetails} />

        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
        >
          <Cell
            span={12}
            align={ALIGNMENT.start}
          >
            <LabelMedium>{t('employees:service.header')}</LabelMedium>
          </Cell>
        </Grid>
        <br />

        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
        >
          <Cell
            span={[12, 6, 3]}
          >
            <LabelSmall display="inline-flex">
              {t('employees:onDemandPay.service.status.label')}
              : &nbsp;
            </LabelSmall>
            <Tag
              closeable={false}
              variant={VARIANT.solid}
              overrides={{
                Root: {
                  style: {
                    backgroundColor: directDepositProgram && directDepositProgram.length > 0
                      ? theme.colors.backgroundPositiveLight
                      : theme.colors.backgroundWarningLight,
                  },
                },
                Text: {
                  style: {
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              <LabelSmall
                color={directDepositProgram && directDepositProgram.length > 0 ? '#03582F' : '#674D1B'}
                width="fit-content"
              >
                {directDepositProgram && directDepositProgram.length > 0
                  ? t(`employees:employeeDetails.directDeposit.status.${directDepositProgram}`)
                  : t('employees:employeeDetails.directDeposit.status.NotEnrolled')}
              </LabelSmall>
            </Tag>
          </Cell>

          <Cell
            span={[12, 6, 3]}
          >
            <LabelSmall display="inline-flex">
              {t('employees:onDemandPay.service.type.label')}
              : &nbsp;
            </LabelSmall>
            <Tag
              closeable={false}
              variant={VARIANT.solid}
              overrides={{
                Root: {
                  style: {
                    backgroundColor: theme.colors.backgroundAccentLight,
                  },
                },
                Text: {
                  style: {
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              <LabelSmall color="#1661ED" width="fit-content">
                {directDepositProgram && directDepositProgram.length > 0
                  ? t(`employees:employeeDetails.directDeposit.program.${directDepositProgram}`)
                  : emptyPlaceholder}
              </LabelSmall>
            </Tag>
          </Cell>

          <Cell
            span={[12, 6, 4]}
          >
            <LabelSmall display="inline-flex">
              {t('employees:onDemandPay.service.enrollmentDate.label')}
              : &nbsp;
            </LabelSmall>
            <Tag
              closeable={false}
              variant={VARIANT.solid}
              overrides={{
                Root: {
                  style: {
                    backgroundColor: theme.colors.backgroundTertiary,
                  },
                },
                Text: {
                  style: {
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              <LabelSmall color={theme.colors.contentSecondary} width="fit-content">
                {enrollmentInfo?.enrollDate ? moment(enrollmentInfo?.enrollDate).format(t('dateFormats:standard')) : emptyPlaceholder}
              </LabelSmall>
            </Tag>
          </Cell>
        </Grid>
        <br />
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
        >
          <Cell
            span={12}
            align={ALIGNMENT.start}
          >
            <LabelMedium>{t('employees:directDeposit.section.header')}</LabelMedium>
          </Cell>
        </Grid>
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
        >
          <Cell
            span={[12, 8, 4]}
            align={ALIGNMENT.center}
            overrides={{
              Cell: {
                style: {
                  '@media screen and (max-width: 1500px)': {
                    width: '400px',
                  },
                },
              },
            }}
          >
            <Block
              alignItems="start"
              justifyContent="start"
              display="flex"
              marginTop="20px"
              overrides={{
                Block: {
                  style: {
                    whiteSpace: 'nowrap',
                  },
                },
              }}
            >
              <LabelSmall display="inline-flex" color={theme.colors.contentSecondary} marginTop="8px">
                {t('employees:fuegoDirectDeposit.accountNumber.label')}
                :
              </LabelSmall>

              <Input
                type="password"
                disabled
                size={SIZE.compact}
                value={enrollmentInfo?.accountNumber || emptyPlaceholder}
                overrides={{
                  Root: {
                    style: () => ({
                      border: 'none',
                      backgroundColor: 'white',

                    }),
                  },
                  InputContainer: {
                    style: {
                      backgroundColor: 'white',
                    },
                  },
                  MaskToggleButton: {
                    style: {
                      cursor: hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager]) ? 'not-allowed' : 'pointer',
                    },
                    props: {
                      disabled: hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager]),
                    },
                  },
                }}
              />
            </Block>
          </Cell>

          <Cell
            span={[12, 8, 3]}
            overrides={{
              Cell: {
                style: {
                  '@media screen and (max-width: 1500px)': {
                    width: '300px',
                  },
                },
              },
            }}

          >
            <Block
              alignItems="start"
              justifyContent="start"
              display="flex"
              marginTop="20px"
              overrides={{
                Block: {
                  style: {
                    whiteSpace: 'nowrap',
                  },
                },
              }}
            >
              <LabelSmall display="inline-flex" color={theme.colors.contentSecondary} marginTop="8px">
                {t('employees:fuegoDirectDeposit.routingNumber.label')}
                : &nbsp;
              </LabelSmall>
              <Input
                type="password"
                disabled
                size={SIZE.compact}
                value={enrollmentInfo?.routingNumber || emptyPlaceholder}
                overrides={{
                  Root: {
                    style: () => ({
                      border: 'none',
                      backgroundColor: 'white',

                    }),
                  },
                  InputContainer: {
                    style: {
                      backgroundColor: 'white',
                    },
                  },
                  MaskToggleButton: {
                    style: {
                      cursor: hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager]) ? 'not-allowed' : 'pointer',
                    },
                    props: {
                      disabled: hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager]),
                    },
                  },
                }}
              />
            </Block>
          </Cell>
        </Grid>
      </div>
    </AccordionSection>
  );
};

export default memo(EmployeeDetailsDirectDepositSection);
