import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  LabelMedium,
  LabelXSmall,
} from 'baseui/typography';
import AppModal from 'components/AppModal/AppModal';
import {
  createContext,
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ModalNames, setModal } from 'store/slices/modals';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { GenerateDirectDepositInitialValuesType } from 'types/EmployeeTypes';
import { generateDirectDepositInitialValues as initialValues } from 'initialValues/EmployeeInitialValues';
import validationSchema from 'validation/directDepositSchema';
import {
  directDepositReportDataPendingSelector,
  directDepositReportDataSelector,
  generateDirectDepositReportReport,
  resetDirectDepositReportData,
} from 'store/slices/employees';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import Loader from 'components/Loader';
import DownloadFile from 'utils/downloadFile';
import {
  ALIGN,
  Radio,
  RadioGroup,
} from 'baseui/radio';
import AppDatePicker from 'components/Form/AppDatePicker';
import moment from 'moment';
import { useStyletron } from 'baseui';

export const GenerateDirectDepositFormContext = createContext(
    {} as FormikState<GenerateDirectDepositInitialValuesType> & FormikHelpers<GenerateDirectDepositInitialValuesType> & FormikHandlers,
);

const GenerateDirectDepositModal = () => {
  const dispatch = useAppDispatch();
  const modalName = ModalNames.GENERATE_DIRECT_DEPOSIT_MODAL;
  const { t } = useTranslation(['employees', 'organizations', 'errors', 'common']);
  const organizationID = useAppSelector(loggedOrganizationSelector)?.id;
  const theme = useStyletron()[1];

  const directDepositReportFileData = useAppSelector(directDepositReportDataSelector);
  const directDepositReportFilePending = useAppSelector(directDepositReportDataPendingSelector);

  const [ddReportTime, setDDReportTime] = useState('ALL_TIME');

  const dateFormatRequest = t('dateFormats:standard-reverse');

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const onSubmit = (values: GenerateDirectDepositInitialValuesType) => {
    if (ddReportTime === 'ALL_TIME') {
      dispatch(generateDirectDepositReportReport({ organizationID }))
        .then(() => {
          setIsModalOpen(false);
        });
    } else if (ddReportTime === 'DATE_RANGE') {
      dispatch(generateDirectDepositReportReport({
        organizationID,
        fromDate: moment(values.fromDate[0]).format(dateFormatRequest),
        toDate: moment(values.toDate[0]).format(dateFormatRequest),
      }))
        .then(() => {
          setIsModalOpen(false);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    values,
    isSubmitting,
    handleSubmit,
    validateForm,
    isValid,
    errors,
    touched,
    setFieldValue,
  } = formik;

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  useEffect(() => {
    if (directDepositReportFileData?.csv) {
      DownloadFile(
        `${directDepositReportFileData?.id}.csv`,
        `data:text/csv;charset=UTF-8,${encodeURIComponent(directDepositReportFileData?.csv)}`,
      );
      dispatch(resetDirectDepositReportData());
    }
  }, [directDepositReportFileData?.id]);

  return (
    <AppModal
      modal={modalName}
      title={t('employees:directDepositReport.header')}
      cancelBtnText={t('common:cancel')}
      onClose={handleModalClose}
      actionBtnText={t('common:downloadReport')}
      onAction={handleSubmitExtended}
      isCloseDisabled={isSubmitting}
      isActionDisabled={isSubmitting || !isValid}
      modalWidth={['80vw', '90vw', '50vw', '40vw']}
      minWidth="550px"
      maxWidth="100%"
    >
      <Loader active={directDepositReportFilePending} />
      <GenerateDirectDepositFormContext.Provider value={formik}>
        <form>
          <Grid
            gridColumns={12}
            gridMargins={16}
            align={ALIGNMENT.start}
          >
            <Cell>
              <LabelMedium>
                {t('common:include')}
                :
              </LabelMedium>
            </Cell>

            <Cell
              span={12}
              align={ALIGNMENT.start}
            >
              <RadioGroup
                name="ddReportTime"
                value={ddReportTime}
                onChange={(e: any) => {
                  setDDReportTime(e.currentTarget.value);
                  setFieldValue('ddReportTime', [
                    {
                      value: e.currentTarget.value,
                    },
                  ]);
                }}
                align={ALIGN.vertical}
                error={!!(errors.ddReportTime && touched.ddReportTime && errors.ddReportTime)}
              >
                <Radio
                  value="ALL_TIME"
                  overrides={{
                    Description: {
                      style: {
                        maxWidth: '860px',
                      },
                    },
                  }}
                >
                  {t('employees:directDepositReport.allTimeEnrollments.label')}
                </Radio>
                <Radio
                  value="DATE_RANGE"
                  overrides={{
                    Description: {
                      style: {
                        maxWidth: '860px',
                      },
                    },
                  }}
                >
                  {t('employees:directDepositReport.dateRangeEnrollments.label')}
                </Radio>
              </RadioGroup>
            </Cell>

          </Grid>
          {ddReportTime === 'DATE_RANGE' && (
          <Grid
            gridColumns={12}
            gridMargins={16}
            align={ALIGNMENT.center}
          >
            <AppDatePicker
              showStar
              name="fromDate"
              label={t('common:fromDate.label')}
              cellSpan={[12, 6, 6]}
              context={GenerateDirectDepositFormContext}
              datePickerProps={{
                maxDate: new Date(),
              }}
            />
            <AppDatePicker
              showStar
              name="toDate"
              label={t('common:toDate.label')}
              cellSpan={[12, 6, 6]}
              context={GenerateDirectDepositFormContext}
              datePickerProps={{
                maxDate: new Date(),
                minDate: new Date(values.fromDate),
              }}
            />
            <Cell
              span={12}
              align={ALIGNMENT.start}
            >
              <LabelXSmall color={theme.colors.contentTertiary}>{t('employees:directDepositReport.disclaimer')}</LabelXSmall>
            </Cell>
          </Grid>
          )}
        </form>
      </GenerateDirectDepositFormContext.Provider>
    </AppModal>
  );
};

export default memo(GenerateDirectDepositModal);
