import {
  createContext,
  memo,
  useEffect,
} from 'react';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, ALIGNMENT } from 'baseui/layout-grid';
import { Layer } from 'baseui/layer';
import { repaymentValidationSchema as validationSchema } from 'validation/repaymentsSchema';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import AppModal from 'components/AppModal/AppModal';
import AppInput from 'components/Form/AppInput';
import AppDatePicker from 'components/Form/AppDatePicker';
import { RepaymentsValuesType } from 'types/RepaymentTypes';
import { repaymentsInitialValues } from 'initialValues/RepaymentsInitialValues';
import {
  createRepayment,
  successfullySavedSelector,
  fetchRepayments,
  repaymentsPendingSelector,
} from 'store/slices/repayments';
import { saveRepaymentsMapper } from 'dataMappers/repaymentsDataMappers';
import { currencyCodeSelector, priceScaleSelector } from 'store/slices/application';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import Loader from 'components/Loader';
import { errorSelector } from 'store/slices/error';
import checkIsModalOpen from 'utils/checkIsModalOpen';

export const RepaymentFormContext = createContext(
  {} as FormikState<RepaymentsValuesType> & FormikHelpers<RepaymentsValuesType> & FormikHandlers,
);

const RepaymentFormModal = () => {
  const { t } = useTranslation(['repayments', 'errors', 'common']);
  const error = useAppSelector(errorSelector);
  const dispatch = useAppDispatch();
  const organization = useAppSelector(loggedOrganizationSelector);
  const { id: organizationID } = organization || {};
  const modals = useAppSelector(modalsSelector);
  const successfullySaved = useAppSelector(successfullySavedSelector);
  const currencyCode = useAppSelector(currencyCodeSelector);
  const scale = useAppSelector(priceScaleSelector);
  const pending = useAppSelector(repaymentsPendingSelector);

  const modalName = ModalNames.REPAYMENT_FORM_MODAL;
  const isModalOpen = checkIsModalOpen(modals, modalName);

  const initialValues = {
    ...repaymentsInitialValues,
  };

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const onSubmit = (values: RepaymentsValuesType) => {
    dispatch(createRepayment({
      organizationID,
      data: saveRepaymentsMapper(currencyCode, scale, values),
    })).then(() => {
      setIsModalOpen(false);
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    resetForm,
    validateForm,
    isSubmitting,
    setSubmitting,
    setValues,
    isValid,
  } = formik;

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  useEffect(() => {
    resetForm();
    setSubmitting(false);
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      setValues({
        ...values,
        ...initialValues,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (successfullySaved) {
      resetForm();
      setSubmitting(false);
      dispatch(fetchRepayments({ organizationID, pageNumber: '1' }));
    }
  }, [successfullySaved]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  return (
    <Layer index={400}>
      <RepaymentFormContext.Provider value={formik}>
        <form>
          <AppModal
            modal={modalName}
            title={t('repayments:recordNewPayment')}
            cancelBtnText={t('common:cancel')}
            onClose={handleModalClose}
            actionBtnText={t('common:save')}
            onAction={handleSubmitExtended}
            isCloseDisabled={isSubmitting}
            isActionDisabled={isSubmitting || !isValid}
          >
            <Loader active={pending} />

            <Grid
              align={ALIGNMENT.start}
              gridMargins={16}
            >
              <AppInput
                showStar
                name="referenceID"
                label={t('repayments:referenceID.label')}
                cellSpan={12}
                context={RepaymentFormContext}
                inputProps={{
                  id: 'referenceID',
                  autoComplete: 'off',
                }}
              />

              <AppInput
                showStar
                name="paymentAmount"
                label={t('repayments:paymentAmount.label')}
                cellSpan={12}
                context={RepaymentFormContext}
                inputProps={{
                  min: 0,
                  max: 999999.99,
                  type: 'number',
                  step: 'any' as any,
                  endEnhancer: currencyCode,
                  autoComplete: 'off',
                }}
              />

              <AppDatePicker
                showStar
                name="dateReceived"
                label={t('repayments:dateReceived.label')}
                cellSpan={12}
                context={RepaymentFormContext}
              />
            </Grid>
          </AppModal>
        </form>
      </RepaymentFormContext.Provider>
    </Layer>
  );
};

export default memo(RepaymentFormModal);
