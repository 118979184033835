import {
  Key,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useStyletron } from 'styletron-react';
import { Accordion, Panel } from 'baseui/accordion';
import { Block } from 'baseui/block';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import PriceFormatter from 'utils/priceFormatter';
import {
  accordionAllocationListItemOverrides,
  listContainerStyles,
  outerContainerStyles,
} from 'screens/Batches/BatchesHelpers';
import {
  repaymentSelector,
} from 'store/slices/repayments';
import {
  fetchAllocatedBatchEmploymentDrawAllocations,
  batchAllocatedEmploymentsSelector,
  batchAllocatedEmploymentsPendingListSelector,
} from 'store/slices/paymentAllocation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { unScalePrice } from 'utils/priceScale';
import Loader from 'components/Loader';
import { Card } from 'baseui/card';
import HighLightSearch from 'components/HighLightSearch';
import { Pagination, SIZE } from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import { EmploymentIDType } from 'types/EmployeeTypes';
import {
  AllocationBatchType,
  RepaymentIDType,
} from 'types/RepaymentTypes';
import { BatchIDType } from 'types/BatchTypes';
import { StatefulTooltip } from 'baseui/tooltip';
import AllocatedPaymentsEmploymentListItem from './AllocatedPaymentsEmploymentListItem';

export type PaymentAllocationListItemPropsType = {
  allocationBatch: AllocationBatchType
  search?: string
  handlePageChange: ({ nextPage, batchID }: { nextPage: number, batchID: BatchIDType }) => void,
  pageNumber: number,
  numPages: number,
}

const AllocatedPaymentsListItem = ({
  allocationBatch,
  search,
  handlePageChange,
  pageNumber,
  numPages,
  ...rest
}: PaymentAllocationListItemPropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['batches', 'dateFormats']);
  const dispatch = useAppDispatch();
  const employments = useAppSelector(batchAllocatedEmploymentsSelector);
  const repayment = useAppSelector(repaymentSelector);
  const pending = useAppSelector(batchAllocatedEmploymentsPendingListSelector);
  const {
    id,
    payGroup,
    payrollPeriod,
    amount,
    amountDue,
    reference,
    allocatedAmount,
  } = allocationBatch || {};
  const batchID = id?.toString();
  const hasEmployments = employments?.length > 0;
  const repaymentID: RepaymentIDType = repayment?.id?.toString();
  const dateFormat = t('dateFormats:standard');

  const handleAccordionChange = ({ expanded }: { expanded: Key[] }) => {
    if (expanded[0]) {
      dispatch(fetchAllocatedBatchEmploymentDrawAllocations(
        {
          batchID,
          employmentID: expanded[0] as EmploymentIDType,
          repaymentID,
        },
      ));
    }
  };

  return (
    <>
      <div className={css(outerContainerStyles)}>
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
          gridMargins={30}
        >
          <Cell
            align={ALIGNMENT.center}
            span={[2, 3]}
          >
            <HighLightSearch
              search={search}
            >
              <StatefulTooltip
                accessibilityType="tooltip"
                content={reference}
                placement="topLeft"
              >
                <span
                  className="ellipsis"
                  style={{
                    marginTop: '4px',
                    width: '100%',
                    display: 'inline-block',
                  }}
                >
                  {reference}
                </span>
              </StatefulTooltip>
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={[2, 3]}
          >
            <HighLightSearch
              search={search}
            >
              <StatefulTooltip
                accessibilityType="tooltip"
                content={payGroup?.name}
                placement="topLeft"
              >
                <span
                  className="ellipsis"
                  style={{
                    marginTop: '4px',
                    width: '100%',
                    display: 'inline-block',
                  }}
                >
                  {payGroup?.name}
                </span>
              </StatefulTooltip>
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <HighLightSearch search={search}>
              {`${moment(payrollPeriod?.startDate).format(dateFormat)} - ${moment(payrollPeriod?.endDate).format(dateFormat)}`}
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <HighLightSearch search={search}>
              {PriceFormatter().format(unScalePrice(amount?.value, amount?.scale))}
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={[2, 1]}
          >
            <HighLightSearch search={search}>
              {PriceFormatter().format(unScalePrice(amountDue?.value, amountDue?.scale))}
            </HighLightSearch>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <HighLightSearch search={search}>
              {PriceFormatter().format(unScalePrice(allocatedAmount?.value, allocatedAmount?.scale))}
            </HighLightSearch>
          </Cell>
        </Grid>
      </div>

      <Panel
        key={batchID as Key}
        {...rest}
      >
        {hasEmployments && (
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
          gridMargins={4}
        >
          <Cell
            align={ALIGNMENT.center}
            span={4}
          >
            <strong>{t('batches:paymentAllocations.employee')}</strong>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <strong>{t('batches:paymentAllocations.payrollNumber')}</strong>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={3}
          >
            <strong>{t('batches:paymentAllocations.totalAmount')}</strong>
          </Cell>
        </Grid>
        )}

        <div className={css(listContainerStyles)}>
          <Accordion
            onChange={handleAccordionChange}
            overrides={accordionAllocationListItemOverrides}
          >
            <Loader active={pending} />

            {[...employments]
              ?.sort((a, b) => `${a.firstName} ${a.firstName}`.localeCompare(`${b.firstName} ${b.lastName}`))
              ?.map((employment) => (
                <AllocatedPaymentsEmploymentListItem
                  key={employment?.id}
                  repaymentID={repaymentID}
                  batchID={batchID}
                  employment={employment}
                />
              ))}
            {employments?.length > 0 && (
            <Block
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
              marginBottom="16px"
              marginTop="16px"
            >
              <Pagination
                size={SIZE.compact}
                numPages={numPages}
                currentPage={pageNumber}
                overrides={paginationTransparentOverrides}
                onPageChange={({ nextPage }: { nextPage: number }) => handlePageChange({ nextPage, batchID })}
              />
            </Block>
            )}

            {!pending && !hasEmployments && (
            <Block
              marginTop="24px"
            >
              <Grid
                gridColumns={12}
                gridMargins={0}
              >
                <Cell span={12}>
                  <Card>
                    {t('repayments:notFoundEmployments')}
                  </Card>
                </Cell>
              </Grid>
            </Block>
            )}
          </Accordion>
        </div>
      </Panel>
    </>
  );
};

export default AllocatedPaymentsListItem;
