import {
  memo,
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  Button,
  KIND,
  SIZE,
} from 'baseui/button';
import { Layer } from 'baseui/layer';
import { DatePicker } from 'baseui/datepicker';
import {
  useAppSelector,
  useAppDispatch,
} from 'store/hooks';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import {
  organizationSelector,
} from 'store/slices/organizations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLock,
  faPencil,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import {
  fetchPayrollPeriods,
  payGroupPayrollPeriodsSelector,
  generatePayPeriods,
  savePayGroupPayPeriods,
  resetPayGroupPayRollPeriods,
  payrollPeriodsGeneratedSelector,
  payGroupPendingPayrollPeriodsListSelector,
  hasInitialPeriodsSelector,
  closePayPeriod,
  deletePayPeriod,
  updatePayrollPeriod,
} from 'store/slices/payGroups';
import AppModal from 'components/AppModal/AppModal';
import CellFormControl from 'components/CellFormControl';
import {
  OrganizationManagePayPeriodsModalPropsType,
  PayrollPeriod,
} from 'types/PayGroupTypes';
import Loader from 'components/Loader';
import { datePickerOverrides } from 'components/Form/AppDatePicker';
import { Block } from 'baseui/block';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { OptionalDateOrDateArrayType } from 'types/CommonTypes';
import {
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic';
import { ParagraphSmall } from 'baseui/typography';

export type StyledCellPropsType = {
  children: any,
  id: number,
  isInputCell?: boolean,
};

const editButtonsOverrides = {
  style: {
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '3px',
    paddingBottom: '3px',
  },
};

const faEnabledIcon = {
  cursor: 'pointer',
  justifyContent: 'center',
  marginRight: '10px',
  color: '#bb092f',
};

const TABLE_PARENT_ID = 'payroll-periods-stateful-table';
const ONE_ROW_HEIGHT = 36;
const FREQ_RULE_TO_DAYS: { [k: string]: number } = {
  WEEKLY: 7,
  BI_WEEKLY: 14,
};

const DAY_OF_WEEK_FORMAT = 'dddd';
const OrganizationManagePayPeriodsModal = ({
  payGroup,
}: OrganizationManagePayPeriodsModalPropsType) => {
  if (!payGroup) return null;
  const payrollPeriods = useAppSelector(payGroupPayrollPeriodsSelector);
  const payrollPeriodsGenerated = useAppSelector(payrollPeriodsGeneratedSelector);
  const pendingPayrollPeriodsList = useAppSelector(payGroupPendingPayrollPeriodsListSelector);
  const organization = useAppSelector(organizationSelector);
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const modals = useAppSelector(modalsSelector);
  const hasInitialPeriods = useAppSelector(hasInitialPeriodsSelector);
  const { t } = useTranslation(['organizations', 'errors', 'common', 'locations', 'payGroups', 'dateFormats']);
  const { payFrequencyRule, periodStartDay, businessDayStartTime } = payGroup;
  const cycle = FREQ_RULE_TO_DAYS[payFrequencyRule];
  const minDate = (hasInitialPeriods && payrollPeriods && payrollPeriods?.length > 0)
    ? moment(payrollPeriods[0]?.endDate).toDate()
    : undefined;
  const [startDate, setStartDate] = useState(minDate && new Date(minDate));
  const [endDate, setEndDate] = useState<Date>();
  const [editPayrollPeriod, setEditPayrollPeriod] = useState<any>(null);
  const [initialEditPayrollPeriod, setInitialEditPayrollPeriod] = useState<any>(null);
  const [editPayrollPeriodHasChanged, setEditPayrollPeriodHasChanged] = useState<boolean>(false);
  const [hasPerformedDelete, setHasPerformedDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [closeId, setCloseId] = useState<number | null>(null);
  const modalName = ModalNames.PAY_PERIOD_MANAGE_MODAL;
  const isModalOpen = checkIsModalOpen(modals, modalName);
  const isConfirmModalOpen = !!modals?.find((item) => item.name === ModalNames.CONFIRM_MODAL)?.isOpen;
  const payGroupID = String(payGroup.id);
  const LENGTH_OF_FUTURE_PERIODS = payrollPeriods.filter((i) => i.status === 'FUTURE').length;
  const { id: organizationID } = organization || {};

  const dateFormat = t('dateFormats:standard-with-time');
  const datePickerDateFormat = t('dateFormats:date-picker.standard');
  const datePickerPlaceholder = t('dateFormats:date-picker.placeholder');

  const datePickerOverridesExtended = (id: string) => ({
    ...datePickerOverrides,
    Input: {
      ...datePickerOverrides.Input,
      props: {
        onChange: () => { },
        overrides: {
          ...datePickerOverrides.Input.props.overrides,
        },
        id,
      },
    },
  });

  const setIsConfirmModalOpen = (
    isOpen: boolean,
  ) => {
    isConfirmModalOpen !== isOpen && dispatch(setModal({
      name: ModalNames.CONFIRM_MODAL,
      isOpen,
    }));
  };

  const updatePayPeriod = () => {
    const latestCutoffTime = editPayrollPeriod.ewaCutoffTime > editPayrollPeriod.tcoCutoffTime
      ? editPayrollPeriod.ewaCutoffTime : editPayrollPeriod.tcoCutoffTime;
    dispatch(updatePayrollPeriod({
      organizationID: organization?.id,
      payGroupID,
      data: {
        id: editPayrollPeriod.id,
        officialCutoffTime: moment(latestCutoffTime).add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
        ewaCutoffTime: editPayrollPeriod.ewaCutoffTime,
        tcoCutoffTime: editPayrollPeriod.tcoCutoffTime,
      },
    }));
    setEditPayrollPeriod(null);
  };

  const closePeriod = (id: number) => {
    dispatch(closePayPeriod({
      organizationID: organization?.id,
      payGroupID,
      payrollPeriodID: String(id),
    }));
    setIsConfirmModalOpen(false);
  };

  const deletePeriod = (id: number) => {
    dispatch(deletePayPeriod({
      organizationID: organization?.id,
      payGroupID,
      payrollPeriodID: String(id),
    }));
    setIsConfirmModalOpen(false);
    setHasPerformedDelete(true);
  };

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmitExtended = () => {
    if (closeId && !deleteId && !editPayrollPeriod) closePeriod(closeId);
    if (deleteId && !closeId && !editPayrollPeriod) deletePeriod(deleteId);
    if (!closeId && !deleteId && editPayrollPeriod) updatePayPeriod();

    setCloseId(null);
    setDeleteId(null);
    setEditPayrollPeriod(null);
    setInitialEditPayrollPeriod(null);
    setIsConfirmModalOpen(false);
    const shouldSubmit = payrollPeriods && !closeId && !deleteId && !editPayrollPeriod;
    if (shouldSubmit) {
      dispatch(savePayGroupPayPeriods({
        organizationID,
        payGroupID,
        data: {
          periods: payrollPeriods,
        },
      })).then(() => {
        handleModalClose();
      });
    }
  };

  const handleEditDateChange = ({ date, key }: { date: OptionalDateOrDateArrayType, key: string }) => {
    if (date && !Array.isArray(date)) {
      const newDate = date as Date;
      const newPayrollPerod = { ...editPayrollPeriod, [key]: moment(newDate).format('YYYY-MM-DDTHH:mm:ss') };
      setEditPayrollPeriod(newPayrollPerod);
      setEditPayrollPeriodHasChanged(JSON.stringify(newPayrollPerod) !== JSON.stringify(initialEditPayrollPeriod));
    } else if (!date) {
      setEditPayrollPeriod({ ...editPayrollPeriod, [key]: undefined });
    }
  };
  const resetPositionForMobile = () => {
    setTimeout(() => {
      if (document.body.clientWidth <= 768) {
        const element = document.querySelector('[data-baseweb="popover"]') as HTMLElement;
        if (element) {
          element.style.position = 'fixed';
          element.style.top = '50%';
          element.style.left = '0px';
          element.style.transform = 'translateY(-50%)';
        }
      }
    }, 50);
  };

  const getEditableDateColumn = (key: string) => (
    <DatePicker
      size={SIZE.mini}
      clearable
      onOpen={resetPositionForMobile}
      placeholder={datePickerPlaceholder}
      formatString={datePickerDateFormat}
      mask={dateFormat}
      value={editPayrollPeriod && editPayrollPeriod[key] ? new Date(editPayrollPeriod[key]) : undefined}
      onChange={({ date }: { date: OptionalDateOrDateArrayType }) => handleEditDateChange({ date, key })}
      timeSelectStart
      overrides={datePickerOverridesExtended(`OrganizationManagePayPeriodsModal-edit-${key}`)}
    />
  );

  const payrollPeriodsData = payrollPeriods?.slice()
    ?.sort((a, b) => b.id - a.id);

  const handleGenerateBtnClick = () => {
    setEditPayrollPeriod(null);
    dispatch(generatePayPeriods({
      organizationID,
      payGroupID,
      data: {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      },
    }));
  };

  const handleStartDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    if (date && !Array.isArray(date)) {
      setStartDate(
        !minDate || moment(date) > moment(minDate)
          ? date as Date
          : minDate as Date,
      );
      setEndDate(moment(date).add(cycle * 2, 'days').toDate());
    } else if (!date) {
      setStartDate(undefined);
    }
  };

  const handleEndDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    if (date && !Array.isArray(date)) {
      setEndDate(
        !minDate || moment(date) > moment(minDate)
          ? date as Date
          : minDate as Date,
      );
    } else if (!date) {
      setEndDate(undefined);
    }
  };
  const modifyStyles = (elem: HTMLElement | null) => {
    if (!elem) return;
    const el = elem;
    el.style.height = '25px';
    el.style.fontSize = '12px';
  };

  useEffect(() => {
    dispatch(resetPayGroupPayRollPeriods());

    return () => {
      dispatch(resetPayGroupPayRollPeriods());
    };
  }, []);

  useEffect(() => {
    setStartDate(minDate);
    setEndDate(undefined);

    if (isModalOpen) {
      dispatch(fetchPayrollPeriods({
        organizationID,
        payGroupID,
      }));
    } else {
      dispatch(resetPayGroupPayRollPeriods());
    }
  }, [isModalOpen]);

  useEffect(() => {
    const startDateElement = document.getElementById('OrganizationManagePayPeriodsModal-start-date') as HTMLInputElement;
    if (startDateElement?.disabled) {
      startDateElement.style.webkitTextFillColor = 'grey';
    }
    const numberOfFuturePeriods = payrollPeriods.filter((i) => i.status === 'FUTURE');
    if (numberOfFuturePeriods.length) {
      const tableParentElement = document.getElementById(TABLE_PARENT_ID);
      const elementToScroll = tableParentElement?.childNodes?.[1]?.childNodes?.[1]?.childNodes?.[0] as HTMLElement;
      if (elementToScroll) elementToScroll.scroll(0, (numberOfFuturePeriods.length - 1) * ONE_ROW_HEIGHT);
    }
    if (hasPerformedDelete) {
      setHasPerformedDelete(false);
      setStartDate(payrollPeriods && payrollPeriods?.length > 0 ? moment(payrollPeriods[0]?.endDate).toDate() : minDate);
    }
  }, [payrollPeriods]);

  useEffect(() => {
    hasInitialPeriods && minDate && setStartDate(minDate);
  }, [hasInitialPeriods]);

  useEffect(() => {
    const startEl = document.getElementById('OrganizationManagePayPeriodsModal-edit-startDate');
    const endEl = document.getElementById('OrganizationManagePayPeriodsModal-edit-endDate');
    const cutOffEl = document.getElementById('OrganizationManagePayPeriodsModal-edit-ewaCutoffTime');
    const tcoOffEl = document.getElementById('OrganizationManagePayPeriodsModal-edit-tcoCutoffTime');
    modifyStyles(startEl);
    modifyStyles(endEl);
    modifyStyles(cutOffEl);
    modifyStyles(tcoOffEl);
  }, [editPayrollPeriod]);

  const generateIncludedDates = (): Date[] => {
    const result = [];
    const firstDay = moment().subtract(cycle, 'days');
    const startDay = periodStartDay.toLowerCase();
    if (firstDay.format(DAY_OF_WEEK_FORMAT).toLowerCase() === startDay
      && firstDay.utc().hours() < Number(businessDayStartTime.split(':')[0])) result.push(firstDay.toDate());
    for (let i = 1; i <= 365; i += 1) {
      const nextDay = firstDay.add(1, 'days');
      if (nextDay.format(DAY_OF_WEEK_FORMAT).toLowerCase() === startDay) result.push(nextDay.toDate());
    }
    return result;
  };

  const includedDates: Date[] = generateIncludedDates();
  return (
    <Layer index={400}>
      <AppModal
        confirm
        isActionDisabled={!payrollPeriodsGenerated}
        modal={modalName}
        title={`${payGroup.name} - ${t('payGroups:managePayPeriods')}`}
        cancelBtnText={t('common:cancel')}
        onClose={handleModalClose}
        actionBtnText={t('common:save')}
        onAction={handleSubmitExtended}
      >
        <Loader active={pendingPayrollPeriodsList} />

        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <CellFormControl
            cellAlign={ALIGNMENT.end}
            cellSpan={!hasInitialPeriods ? [12, 8] : [12, 4]}
            label={t('payGroups:payPeriod.startDate.label')}
          >
            <Block maxWidth="192px">
              <DatePicker
                size={SIZE.compact}
                placeholder={datePickerPlaceholder}
                formatString={datePickerDateFormat}
                mask={dateFormat}
                value={startDate}
                includeDates={includedDates}
                onChange={handleStartDateChange}
                minDate={minDate || moment().subtract(cycle, 'days').toDate()}
                overrides={datePickerOverridesExtended('OrganizationManagePayPeriodsModal-start-date')}
                disabled={hasInitialPeriods}
              />
            </Block>
          </CellFormControl>
          {hasInitialPeriods
            ? (
              <CellFormControl
                cellAlign={ALIGNMENT.end}
                cellSpan={[12, 4]}
                label={t('payGroups:payPeriod.endDate.label')}
              >
                <Block maxWidth="192px">
                  <DatePicker
                    size={SIZE.compact}
                    placeholder={datePickerPlaceholder}
                    formatString={datePickerDateFormat}
                    value={endDate}
                    includeDates={includedDates.filter((date: Date, index: number): Boolean => (cycle === 14 && index % 2 > 0) || cycle === 7)}
                    onChange={handleEndDateChange}
                    minDate={moment(moment().isBefore(startDate) ? startDate : moment()).add(cycle, 'days').toDate()}
                    overrides={datePickerOverridesExtended('OrganizationManagePayPeriodsModal-end-date')}
                  />
                </Block>
              </CellFormControl>
            ) : null}

          <CellFormControl
            cellAlign={ALIGNMENT.end}
            cellSpan={[12, 4]}
          >
            <Block
              display="flex"
              justifyContent="end"
            >
              <Button
                disabled={(!startDate || !endDate) || (startDate && endDate && startDate > endDate)}
                kind={KIND.primary}
                size={SIZE.compact}
                onClick={handleGenerateBtnClick}
                overrides={{
                  Root: {
                    props: {
                      id: 'OrganizationManagePayPeriodsModal-generate-preview',
                    },
                  },
                }}
              >
                {t('payGroups:payPeriod.generatePreview')}
              </Button>
            </Block>
          </CellFormControl>
        </Grid>

        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <Cell
            span={12}
          >
            <TableBuilder
              data={payrollPeriodsData}
              overrides={{
                TableHeadCell: {
                  style: {
                    backgroundColor: '#F6F6F6',
                    borderLeftColor: '#D8D8D8',
                    borderRightColor: '#D8D8D8',
                    borderTopColor: '#D8D8D8',
                    borderBottomColor: '#D8D8D8',
                    borderLeftStyle: 'solid',
                    borderRightStyle: 'solid',
                    borderTopStyle: 'solid',
                    borderBottomStyle: 'solid',
                    borderLeftWidth: '0.5px',
                    borderRightWidth: '0.5px',
                    borderTopWidth: '0.5px',
                    borderBottomWidth: '0.5px',
                  },
                },
                Table: {
                  style: {
                    borderLeftColor: '#D8D8D8',
                    borderRightColor: '#D8D8D8',
                    borderTopColor: '#D8D8D8',
                    borderBottomColor: '#D8D8D8',
                    borderLeftStyle: 'solid',
                    borderRightStyle: 'solid',
                    borderTopStyle: 'solid',
                    borderBottomStyle: 'solid',
                    borderLeftWidth: '0.5px',
                    borderRightWidth: '0.5px',
                    borderTopWidth: '0.5px',
                    borderBottomWidth: '0.5px',
                  },
                },
                TableBodyCell: {
                  style: {
                    borderLeftColor: '#D8D8D8',
                    borderRightColor: '#D8D8D8',
                    borderTopColor: '#D8D8D8',
                    borderBottomColor: '#D8D8D8',
                    borderLeftStyle: 'solid',
                    borderRightStyle: 'solid',
                    borderTopStyle: 'solid',
                    borderBottomStyle: 'solid',
                    borderLeftWidth: '0.5px',
                    borderRightWidth: '0.5px',
                    borderTopWidth: '0.5px',
                    borderBottomWidth: '0.5px',
                  },
                },
                TableBodyRow: {
                  style: {
                    ':nth-child(even)': {
                      background: '#F5F5F5',
                    },
                  },
                },
              }}
            >
              <TableBuilderColumn
                header={t('payGroups:payPeriod.startDate.title')}
              >
                {(payrollPeriod: PayrollPeriod) => (
                  <ParagraphSmall margin="0px">
                    {moment(payrollPeriod.startDate).format(dateFormat)}
                  </ParagraphSmall>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                header={t('payGroups:payPeriod.endDate.title')}
              >
                {(payrollPeriod: PayrollPeriod) => (
                  <ParagraphSmall margin="0px">
                    {moment(payrollPeriod.endDate).format(dateFormat)}
                  </ParagraphSmall>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                header={t('payGroups:payPeriod.cutOffDate.title')}
              >
                {(payrollPeriod: PayrollPeriod) => (
                  <ParagraphSmall margin="0px">
                    {payrollPeriod.id && editPayrollPeriod?.id === payrollPeriod.id
                      ? getEditableDateColumn('ewaCutoffTime')
                      : moment(payrollPeriod.ewaCutoffTime).format(dateFormat)}
                  </ParagraphSmall>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                header={t('payGroups:payPeriod.tpoCutoffTime.title')}
              >
                {(payrollPeriod: PayrollPeriod) => (
                  <ParagraphSmall margin="0px">
                    { payrollPeriod.id && editPayrollPeriod?.id === payrollPeriod.id
                      ? getEditableDateColumn('tcoCutoffTime')
                      : moment(payrollPeriod.tcoCutoffTime).format(dateFormat)}
                  </ParagraphSmall>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                header={t('payGroups:payPeriod.status.title')}
              >
                {(payrollPeriod: PayrollPeriod) => (
                  <ParagraphSmall margin="0px">
                    {payrollPeriod.status}
                  </ParagraphSmall>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                header={t('payGroups:payPeriod.action.title')}
              >
                {(payrollPeriod: any) => {
                  const {
                    status, id, index,
                  } = payrollPeriod;
                  if (status === 'LOCKED') {
                    if (!editPayrollPeriod) {
                      return (
                        <div
                          className={css({
                            margin: 'auto 0',
                          })}
                        >
                          <FontAwesomeIcon
                            className={css({ cursor: 'pointer', justifyContent: 'center', color: '#bb092f' })}
                            icon={faLock}
                            onClick={() => { setCloseId(id); setIsConfirmModalOpen(true); }}
                          />
                        </div>
                      );
                    }
                  }
                  if (status === 'FUTURE' || status === 'OPEN') {
                    if (id && editPayrollPeriod?.id === id) {
                      return (
                        <div
                          className={css({
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          })}
                        >
                          <Button
                            kind={KIND.secondary}
                            size={SIZE.mini}
                            overrides={{
                              Root: {
                                style: { ...editButtonsOverrides.style, marginRight: '5px', marginLeft: '0px' },
                                props: { id: `pay-periods-edit-cancel-button-${id}` },
                              },
                            }}
                            onClick={() => { setEditPayrollPeriod(null); setInitialEditPayrollPeriod(null); }}
                          >
                            {t('common:cancel')}
                          </Button>
                          <Button
                            kind={KIND.primary}
                            size={SIZE.mini}
                            overrides={{
                              Root: {
                                ...editButtonsOverrides,
                                props: { id: `pay-periods-edit-save-button-${id}` },
                              },
                            }}
                            disabled={!editPayrollPeriod.startDate
                      || !editPayrollPeriod.endDate
                      || !editPayrollPeriod.ewaCutoffTime
                      || !editPayrollPeriod.tcoCutoffTime
                      || !editPayrollPeriodHasChanged}
                            onClick={() => setIsConfirmModalOpen(true)}
                          >
                            {t('common:save')}
                          </Button>
                        </div>
                      );
                    }
                    if (!editPayrollPeriod && id) {
                      return (
                        <div
                          className={css({
                            margin: 'auto 0',
                          })}
                        >
                          {LENGTH_OF_FUTURE_PERIODS > 1 && index === 0
                            ? (
                              <FontAwesomeIcon
                                className={css(faEnabledIcon)}
                                icon={faTrashCan}
                                onClick={() => { setDeleteId(id); setIsConfirmModalOpen(true); }}
                              />
                            ) : null}
                          <FontAwesomeIcon
                            className={css({ cursor: 'pointer', justifyContent: 'center', color: '#bb092f' })}
                            icon={faPencil}
                            onClick={() => { setEditPayrollPeriod(payrollPeriod); setInitialEditPayrollPeriod(payrollPeriod); }}
                          />
                        </div>
                      );
                    }
                  }
                  return null;
                }}
              </TableBuilderColumn>
            </TableBuilder>
          </Cell>
        </Grid>
      </AppModal>
    </Layer>
  );
};

export default memo(OrganizationManagePayPeriodsModal);
