import { OrganizationIDType } from 'types/OrganizationTypes';
import request from './request';

export const fetchTreasurySummaryRequest = (
  token: string,
  organizationID?: OrganizationIDType,
) => request({
  token,
  method: 'GET',
  url: organizationID
    ? `/organizations/treasury-summary?organizationId=${organizationID}`
    : '/organizations/treasury-summary',
});

export const fetchTreasurySummaryReportRequest = (
  token: string,
  organizationID?: OrganizationIDType,
) => request({
  token,
  method: 'GET',
  url: organizationID
    ? `/organizations/treasury-summary-report?organizationId=${organizationID}`
    : '/organizations/treasury-summary-report',
});

export const fetchOutstandingBatchesReportRequest = (
  token: string,
  organizationID: OrganizationIDType,
  types?: string,
  statuses?: string,
) => {
  const queryParams = new URLSearchParams();

  if (organizationID) {
    queryParams.set('organizationId', organizationID);
  }
  if (types) {
    queryParams.set('types', types);
  }
  if (statuses) {
    queryParams.set('statuses', statuses);
  }

  return request({
    token,
    method: 'GET',
    url: queryParams ? `/outstanding-batches-report?${queryParams.toString()}` : '/outstanding-batches-report',
  });
};

export const fetchOutstandingRepaymentsReportRequest = (
  token: string,
  organizationID: OrganizationIDType,
  autogenerated?: string,
  type?: string,
  status?: string,
) => {
  const queryParams = new URLSearchParams();

  if (organizationID) {
    queryParams.set('organizationId', organizationID);
  }
  if (autogenerated) {
    queryParams.set('autogenerated', autogenerated);
  }
  if (type) {
    queryParams.set('type', type);
  }
  if (status) {
    queryParams.set('status', status);
  }

  return request({
    token,
    method: 'GET',
    url: queryParams ? `/outstanding-repayments-report?${queryParams.toString()}` : '/outstanding-repayments-report',
  });
};

export const fetchFinancialSummaryReportRequest = (
  token: string,
  year?: number,
) => {
  const queryParams = new URLSearchParams();

  if (year) {
    queryParams.set('year', year.toString());
  }

  return request({
    token,
    method: 'GET',
    url: queryParams ? `/financial-summary-report?${queryParams.toString()}` : '/financial-summary-report',
  });
};
