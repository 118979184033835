import * as Yup from 'yup';
import messages from './messages';

export const DirectDepositReportValidationSchema = Yup.object().shape({
  fromDate: Yup
    .date()
    .typeError(messages?.required)
    .when('ddReportTime', (ddReportTime, schema) => {
      if (ddReportTime[0].value === 'DATE_RANGE') {
        return schema
          .required(messages?.required);
      }
      return schema;
    }),
  toDate: Yup
    .date()
    .typeError(messages?.required)
    .when('ddReportTime', (ddReportTime, schema) => {
      if (ddReportTime[0].value === 'DATE_RANGE') {
        return schema
          .required(messages?.required);
      }
      return schema;
    }),
});

export default DirectDepositReportValidationSchema;
