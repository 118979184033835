export enum BankFileTypes {
  CARD = 'CARD',
  TRANSACTION = 'TRANSACTION',
  MFA = 'MFA',
  OTHER = 'OTHER',
}

export type BankFilesFilterType = {
  fileType: BankFileTypes,
  year?: string,
}

export type BankFileType = {
  url?: string,
  name?: string,
  businessDate?: string,
  dateSubmitted?: string,
}

export type DailyBankFilesPropTypes = {
  fileType?: BankFileTypes,
}
