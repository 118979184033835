import { MoneyDtoType } from './CommonTypes';
import { OrganizationIDType } from './OrganizationTypes';

export type FetchRepaymentsSourceAccountsParamsType = {
  organizationID: OrganizationIDType,
}

export type FetchRepaymentsRequestsType = {
  organizationID: OrganizationIDType,
  startDate?: string
  endDate?: string
  sort?: string
  status?: RepaymentRequestStatus
}

export type SourceAccountType = {
  accountId: number,
  routingNumber: string,
  accountNumber: string,
  legalName: string,
  nickname: string,
  repaymentStatement: string,
  destinationAccount: DestinationAccountType,
}

export type DestinationAccountType = {
  id: number,
  displayName: string
}

export enum RepaymentRequestStatus {
  UNKNOWN = 'UNKNOWN',
  NEW = 'NEW',
  SEND_FOR_APPROVAL = 'SEND_FOR_APPROVAL',
  ACCEPTED_FOR_PROCESSING = 'ACCEPTED_FOR_PROCESSING',
  REJECTED_BY_BANK = 'REJECTED_BY_BANK',
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
  INVALID = 'INVALID'
}

export type RepaymentRequestType = {
  id: number,
  amount: MoneyDtoType,
  repaymentId: number,
  repaymentName?: string,
  status?: RepaymentRequestStatus,
  requestedAt?: string,
  sourceAccountLastFour?: string,
  sourceAccountNickname?: string,
  transactionId?: string,
  selected?: boolean | undefined
}

export type DeleteSourceAccountType = {
  accountID: string,
  organizationID: OrganizationIDType,
}

export type ProcessRepaymentRequestsDataType = {
  repaymentRequests: number[]
}

export type ProcessRepaymentRequestParamsType = {
  organizationID: OrganizationIDType,
  data: ProcessRepaymentRequestsDataType
}

export type EditRepaymentRequestDataType = {
  amount: {
    value: number,
    scale: number,
    currencyCode: string
  },
  sourceAccountId: number
}

export type EditRepaymentRequestParamsType = {
  organizationID: OrganizationIDType,
  requestId: number,
  data: EditRepaymentRequestDataType
}

export const TAG_REPAYMENTS_STATUS_KINDS: {
  [index: string]: string,
} = {
  UNKNOWN: 'warning',
  NEW: 'accent',
  SEND_FOR_APPROVAL: 'warning',
  ACCEPTED_FOR_PROCESSING: 'warning',
  REJECTED_BY_BANK: 'negative',
  PENDING: 'warning',
  PROCESSED: 'positive',
  INVALID: 'negative',
};
export type OrganizationSettingsType = {
  id: number,
  destinationAccountId: number,
  destinationAccountDisplayName: string
}

export type SaveOrganizaitonSettingsRequestDataType = {
  organizationName: string,
  destinationAccountId: number
}

export type FetchOrganizationSettingsParamsType = {
  organizationID: OrganizationIDType
}

export type SaveOrganizationSettingsParamsType = {
  organizationID: OrganizationIDType
  data: SaveOrganizaitonSettingsRequestDataType
}
