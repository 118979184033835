import {
  useState,
  memo,
  useMemo,
  useEffect,
} from 'react';
import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Cell,
  ALIGNMENT,
} from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import {
  workersFilterSelector,
  workersListSelector,
  workersPendingListSelector,
  workersNextTokenSelector,
  fetchWorkers,
  resetWorkers,
  workersFilterCriteriaSelector,
  workersAreSearchedSelector,
} from 'store/slices/workers';
import {
  useAppSelector,
  useAppDispatch,
} from 'store/hooks';
import Loader from 'components/Loader';
import WorkerListItem from 'screens/Workers//WorkersListItem';
import { Button } from 'baseui/button';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import WorkersSearch from 'screens/Treasury/TreasuryWorkerAccounts/WorkersSearch';
import WorkerEmploymentsModal from './WorkerEmploymentsModal';
import WorkerLinkedAccountsModal from './WorkerLinkedAccountsModal';
import WorkerShiftsModal from './Shifts/WorkerShiftsModal';

const blockOverrides = {
  Block: {
    style: {
      background: '#F3F3F3',
    },
  },
};

const containerStyles = {
  width: '100%',
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 100,
} as StyleObject;

const Workers = () => {
  const dispatch = useAppDispatch();
  const pendingList = useAppSelector(workersPendingListSelector);
  const workers = useAppSelector(workersListSelector);
  const modals = useAppSelector(modalsSelector);
  const nextToken = useAppSelector(workersNextTokenSelector);
  const areSearched = useAppSelector(workersAreSearchedSelector);
  const pending = useAppSelector(workersPendingListSelector);
  const search = useAppSelector(workersFilterSelector);
  const criteria = useAppSelector(workersFilterCriteriaSelector);
  const { t } = useTranslation(['common', 'workers']);
  const [css] = useStyletron();
  const [selectedWorkerId, setSelectedWorkerId] = useState<string>('');

  const handleLoadMoreClick = () => {
    dispatch(fetchWorkers({
      filter: { nextToken },
    }));
  };

  const setIsEmploymentsModalOpen = (
    isOpen: boolean,
    workerId: string,
  ) => {
    setSelectedWorkerId(workerId);
    dispatch(setModal({
      name: ModalNames.WORKER_EMPLOYMENTS_MODAL,
      isOpen,
    }));
  };

  const setIsLinkedAccountsModalOpen = (
    isOpen: boolean,
    workerId: string,
  ) => {
    setSelectedWorkerId(workerId);
    dispatch(setModal({
      name: ModalNames.WORKER_LINKED_ACCOUNTS_MODAL,
      isOpen,
    }));
  };

  const setIsShiftsModalOpen = (
    isOpen: boolean,
    workerId: string,
  ) => {
    setSelectedWorkerId(workerId);
    dispatch(setModal({
      name: ModalNames.WORKER_UNIVERSAL_CALENDAR_SHIFTS_MODAL,
      isOpen,
    }));
  };

  useEffect(() => {
    if ((criteria[0].id === 'name' && search.firstName === '' && search.lastName === '')) {
      dispatch(resetWorkers());
    }
  }, [search, criteria]);

  return (
    <div className={css(containerStyles)}>
      <CommonHeader title={t('workers:workers')} />
      <Block
        marginTop="24px"
        marginBottom="16px"
      >
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
        >
          <Cell span={12}>
            <WorkersSearch />
          </Cell>
        </Grid>
      </Block>

      {useMemo(() => (
        <Block
          overrides={blockOverrides}
          padding={pendingList ? '24px' : '0'}
          display="block"
          position="relative"
          minHeight={pendingList ? '400px' : '0'}
        >
          <Loader active={pendingList && workers?.length === 0} />

          <Grid
            align={ALIGNMENT.center}
          >
            <Cell
              span={12}
            >
              {workers?.map((worker) => (
                <WorkerListItem
                  key={`${worker.username}-${worker.id}`}
                  worker={worker}
                  setIsLinkedAccountsModalOpen={setIsLinkedAccountsModalOpen}
                  setIsEmploymentsModalOpen={setIsEmploymentsModalOpen}
                  setIsShiftsModalOpen={setIsShiftsModalOpen}
                />
              ))}
              {nextToken
                ? (
                  <Block margin="10px 0 20px" display="flex">
                    <Button
                      overrides={{
                        Root: {
                          style: {
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          },
                          props: {
                            id: 'workers-more-workers-button',
                          },
                        },
                        LoadingSpinner: {
                          style: {
                            borderRightColor: 'white',
                            borderTopColor: 'white',
                            borderLeftColor: 'white',
                          },
                        },
                      }}
                      isLoading={pendingList}
                      disabled={!nextToken}
                      onClick={handleLoadMoreClick}
                    >
                      {t('workers:loadMore')}
                    </Button>
                  </Block>
                ) : null}
              {areSearched && !pending && workers?.length === 0 && (
                <Block
                  marginTop="24px"
                  marginBottom="24px"
                >
                  <Card>
                    <p>{t('workers:notFound')}</p>
                  </Card>
                </Block>
              )}
            </Cell>
          </Grid>
        </Block>
      ), [workers, pendingList])}
      {checkIsModalOpen(modals, ModalNames.WORKER_EMPLOYMENTS_MODAL) && <WorkerEmploymentsModal workerId={selectedWorkerId} />}
      {checkIsModalOpen(modals, ModalNames.WORKER_LINKED_ACCOUNTS_MODAL) && <WorkerLinkedAccountsModal workerId={selectedWorkerId} />}
      {checkIsModalOpen(modals, ModalNames.WORKER_UNIVERSAL_CALENDAR_SHIFTS_MODAL) && <WorkerShiftsModal workerId={selectedWorkerId} />}
    </div>
  );
};

export default memo(Workers);
