import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchPayGroupsRequest,
  createPayGroupRequest,
  fetchPayGroupByIdRequest,
  editPayGroupRequest,
  fetchPayrollPeriodsRequest,
  generatePayPeriodsRequest,
  savePayGroupPayPeriodsRequest,
  closePayPeriodRequest,
  deletePayPeriodRequest,
  updatePayPeriodRequest,
  patchPayGroupRequest,
} from 'api/payGroupsAPI';
import { assignLocationsToPayGroupRequest } from 'api/locationsAPI';
import { FetchLocationsParamsType } from 'types/LocationTypes';
import {
  CreatePayGroupParamsType,
  PayGroupResponseType,
  EditPayGroupParamsType,
  FetchByPayGroupParamsType,
  AssignLocationsParamsType,
  GeneratePayPeriodsParamsType,
  SavePayGroupPayPeriodsParamsType,
  ClosePayPeriodParamsType,
  PayrollPeriod,
  UpdatePayGroupPropsType,
} from 'types/PayGroupTypes';
import {
  resetOrganizationEvent,
  resetNotification,
  fetchPayGroupsWithLocations,
} from 'store/events';
import {
  fetchEmployeesWithConfigsLocationsAndGroups,
} from 'store/slices/employees';
import { OrganizationIDType } from 'types/OrganizationTypes';
import { RootState } from '..';
import { fetchLocations } from './locations';

export interface PayGroup {
  id: number
  name: string
  payFrequencyRule: string
  defaultForOrganization: boolean
  automaticPayrollPeriodGenerationEnabled: boolean
  locations?: number[]
  bankAccount?: {
    bankAccountId: string
    bankAccountAlias: string
  }
  organizationId: OrganizationIDType,
  deductionCode: string,
  businessDayStartTime: string,
  payrollCutoffDayOffset: number,
  ewaCutoffTime: string,
  tcoCutoffTime: string,
  payrollCutoffTime: string,
  payrollCutoffTimezone: string,
  periodStartDay: string,
  paydayOffset: number,
  batchDeliveryEmailAddresses?: string[] | undefined,
  ewaCutoffDayOffset: number,
  tcoCutoffDayOffset: number,
  externalIntegrationCode: string
}

export type PayGroupIDType = string | null | undefined;

export const initialState = {
  list: [] as PayGroup[],
  isListFetched: false,
  payGroupSaved: false,
  payrollPeriodsSaved: false,
  payrollPeriodsGenerated: false,
  payrollPeriodsFetched: false,
  hasInitialPeriods: false,
  selectedPayGroup: null as PayGroupResponseType | null,
  payrollPeriods: [] as PayrollPeriod[],
  assignedLocations: false,
  pending: false,
  pendingList: false,
  pendingAssignLocations: false,
  pendingPayrollPeriodsList: false,
  pendingPatchPayGroup: false,
};

export type UpdatePayPeriodParamsType = {
  organizationID: OrganizationIDType,
  payGroupID: PayGroupIDType,
  data: Partial<PayrollPeriod>
}

export const fetchPayGroup = createAsyncThunk(
  'payGroups/ID',
  async (params: FetchByPayGroupParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, payGroupID } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchPayGroupByIdRequest(storeState.user.accessToken, organizationID, payGroupID);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPayGroupWithLocations = createAsyncThunk(
  'payGroupWithLocations',
  async (params: FetchByPayGroupParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { organizationID, payGroupID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchPayGroupByIdRequest(storeState.user.accessToken, organizationID, payGroupID);

      await dispatch(fetchLocations({ organizationID }));

      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPayGroups = createAsyncThunk(
  'payGroups',
  async (params: FetchLocationsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, pageSize = '1000' } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchPayGroupsRequest(storeState.user.accessToken, organizationID, pageSize);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPayrollPeriods = createAsyncThunk(
  'payGroups/payrollPeriods',
  async (params: FetchByPayGroupParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, payGroupID, statuses } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchPayrollPeriodsRequest(storeState.user.accessToken, organizationID, payGroupID, statuses);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editPayGroup = createAsyncThunk(
  'payGroups/ID/edit',
  async (params: EditPayGroupParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, payGroupID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await editPayGroupRequest(storeState.user.accessToken, organizationID, payGroupID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const generatePayPeriods = createAsyncThunk(
  'payGroups/ID/generatePayrollPeriods',
  async (params: GeneratePayPeriodsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, payGroupID, data } = params;
    const storeState = getState() as RootState;

    try {
      const result = await generatePayPeriodsRequest(storeState.user.accessToken, organizationID, payGroupID, data);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const assignLocationsToPayGroup = createAsyncThunk(
  'payGroups/ID/assignLocations',
  async (params: AssignLocationsParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { organizationID, payGroupID, data } = params;
    const storeState = getState() as RootState;
    try {
      const result = await assignLocationsToPayGroupRequest(storeState.user.accessToken, organizationID, payGroupID, data);

      await dispatch(fetchLocations({ organizationID }));

      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const savePayGroupPayPeriods = createAsyncThunk(
  'payGroups/ID/savePayGroupPayrollPeriods',
  async (params: SavePayGroupPayPeriodsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, payGroupID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await savePayGroupPayPeriodsRequest(storeState.user.accessToken, organizationID, payGroupID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createPayGroup = createAsyncThunk(
  'payGroups/create',
  async (params: CreatePayGroupParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await createPayGroupRequest(storeState.user.accessToken, organizationID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const closePayPeriod = createAsyncThunk(
  'payPeriod/close',
  async (params: ClosePayPeriodParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { organizationID, payGroupID, payrollPeriodID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await closePayPeriodRequest(storeState.user.accessToken, organizationID, payGroupID, payrollPeriodID);

      dispatch(fetchPayrollPeriods({ organizationID, payGroupID }));

      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deletePayPeriod = createAsyncThunk(
  'payPeriod/delete',
  async (params: ClosePayPeriodParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { organizationID, payGroupID, payrollPeriodID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await deletePayPeriodRequest(storeState.user.accessToken, organizationID, payGroupID, payrollPeriodID);

      dispatch(fetchPayrollPeriods({ organizationID, payGroupID }));

      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updatePayrollPeriod = createAsyncThunk(
  'payPeriod/update',
  async (params: UpdatePayPeriodParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { organizationID, payGroupID, data } = params;
    const storeState = getState() as RootState;

    try {
      const result = await updatePayPeriodRequest(storeState.user.accessToken, organizationID, payGroupID, data);
      dispatch(fetchPayrollPeriods({ organizationID, payGroupID }));

      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const patchPayGroup = createAsyncThunk(
  'payGroup/patch',
  async (params: UpdatePayGroupPropsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
      payGroupID,
      data,
    } = params;
    const storeState = getState() as RootState;

    try {
      return await patchPayGroupRequest(storeState.user.accessToken, organizationID, payGroupID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const payGroupsSlice = createSlice({
  name: 'payGroups',
  initialState,
  reducers: {
    resetPayGroups: () => initialState,
    resetPayGroupPayRollPeriods: (state) => {
      state.payrollPeriods = initialState.payrollPeriods;
      state.payrollPeriodsGenerated = initialState.payrollPeriodsGenerated;
      state.payrollPeriodsSaved = initialState.payrollPeriodsSaved;
      state.payrollPeriodsFetched = initialState.payrollPeriodsFetched;
      state.hasInitialPeriods = initialState.hasInitialPeriods;
    },
    resetSelectedPayGroup: (state) => {
      state.payGroupSaved = initialState.payGroupSaved;
      state.selectedPayGroup = initialState.selectedPayGroup;
      state.assignedLocations = initialState.assignedLocations;
    },
    setPayGroupCreated: (state, action) => {
      state.payGroupSaved = action.payload;
    },
    setPayGroupPayPeriodSaved: (state, action) => {
      state.payrollPeriodsSaved = action.payload;
    },
    setPayGroupAssignLocations: (state, action) => {
      state.assignedLocations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetNotification, (state) => {
      state.payGroupSaved = initialState.payGroupSaved;
    });

    builder.addCase(fetchPayGroups.pending, (state) => {
      state.pendingList = true;
      state.list = initialState.list;
    });

    builder.addCase(fetchPayGroups.fulfilled, (state, action) => {
      state.list = action.payload;
      state.pendingList = false;
      state.isListFetched = true;
    });

    builder.addCase(fetchPayGroups.rejected, (state) => {
      state.pendingList = false;
    });

    builder.addCase(fetchPayGroupsWithLocations.pending, (state) => {
      state.list = initialState.list;
      state.pendingList = true;
    });

    builder.addCase(fetchPayGroupsWithLocations.fulfilled, (state, action) => {
      const [payGroups] = action.payload;
      state.list = payGroups;
      state.pendingList = false;
      state.isListFetched = true;
    });

    builder.addCase(fetchPayGroupsWithLocations.rejected, (state) => {
      state.pendingList = false;
    });

    builder.addCase(createPayGroup.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(createPayGroup.fulfilled, (state) => {
      state.payGroupSaved = true;
      state.pending = false;
    });

    builder.addCase(createPayGroup.rejected, (state) => {
      state.payGroupSaved = false;
      state.pending = false;
    });

    builder.addCase(fetchPayGroup.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(fetchPayGroup.fulfilled, (state, action) => {
      state.selectedPayGroup = action.payload;
      state.pending = false;
    });

    builder.addCase(fetchPayGroup.rejected, (state) => {
      state.pending = false;
    });

    builder.addCase(fetchPayrollPeriods.pending, (state) => {
      state.pendingPayrollPeriodsList = true;
    });

    builder.addCase(fetchPayrollPeriods.fulfilled, (state, action) => {
      state.pendingPayrollPeriodsList = false;
      state.payrollPeriodsFetched = true;
      state.payrollPeriods = action.payload;

      if (action.payload?.length > 0) {
        state.hasInitialPeriods = true;
      }
    });

    builder.addCase(fetchPayrollPeriods.rejected, (state) => {
      state.pendingPayrollPeriodsList = false;
    });

    builder.addCase(editPayGroup.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(editPayGroup.fulfilled, (state) => {
      state.payGroupSaved = true;
      state.pending = false;
    });

    builder.addCase(editPayGroup.rejected, (state) => {
      state.payGroupSaved = false;
      state.pending = false;
    });

    builder.addCase(generatePayPeriods.pending, (state) => {
      state.pendingPayrollPeriodsList = true;
    });

    builder.addCase(generatePayPeriods.fulfilled, (state, action) => {
      state.pendingPayrollPeriodsList = false;
      state.payrollPeriodsGenerated = true;
      state.payrollPeriods = action.payload;
    });

    builder.addCase(generatePayPeriods.rejected, (state) => {
      state.pendingPayrollPeriodsList = false;
    });

    builder.addCase(savePayGroupPayPeriods.pending, (state) => {
      state.pendingPayrollPeriodsList = true;
    });

    builder.addCase(savePayGroupPayPeriods.fulfilled, (state) => {
      state.payrollPeriodsSaved = true;
    });

    builder.addCase(savePayGroupPayPeriods.rejected, (state) => {
      state.pendingPayrollPeriodsList = false;
    });

    builder.addCase(assignLocationsToPayGroup.pending, (state) => {
      state.assignedLocations = false;
      state.pendingAssignLocations = true;
    });

    builder.addCase(assignLocationsToPayGroup.fulfilled, (state) => {
      state.assignedLocations = true;
      state.pendingAssignLocations = false;
    });

    builder.addCase(assignLocationsToPayGroup.rejected, (state) => {
      state.assignedLocations = false;
      state.pendingAssignLocations = false;
    });

    builder.addCase(fetchPayGroupWithLocations.pending, (state) => {
      state.selectedPayGroup = initialState.selectedPayGroup;
      state.pendingAssignLocations = true;
    });

    builder.addCase(fetchPayGroupWithLocations.fulfilled, (state, action) => {
      state.selectedPayGroup = action.payload;
      state.pendingAssignLocations = false;
    });

    builder.addCase(fetchPayGroupWithLocations.rejected, (state) => {
      state.pendingAssignLocations = false;
    });

    builder.addCase(fetchEmployeesWithConfigsLocationsAndGroups.fulfilled, (state, action) => {
      state.list = action?.payload[3];
      state.isListFetched = true;
    });

    builder.addCase(patchPayGroup.pending, (state) => {
      state.pendingPatchPayGroup = true;
    });

    builder.addCase(patchPayGroup.fulfilled, (state, action) => {
      state.pendingPatchPayGroup = false;
      const { id, locations, bankAccount } = action.payload;
      const index = state.list.findIndex((item) => item.id === id);

      state.list[index].locations = locations;
      state.list[index].bankAccount = bankAccount;
    });

    builder.addCase(patchPayGroup.rejected, (state) => {
      state.pendingPatchPayGroup = false;
    });

    builder.addCase(resetOrganizationEvent, () => initialState);
  },
});

export const {
  resetPayGroups,
  resetSelectedPayGroup,
  resetPayGroupPayRollPeriods,
  setPayGroupCreated,
  setPayGroupPayPeriodSaved,
  setPayGroupAssignLocations,
} = payGroupsSlice.actions;

export const payGroupsSelector = (state: RootState): PayGroup[] => state.payGroups.list;
export const payGroupCreatedSelector = (state: RootState): boolean => state.payGroups.payGroupSaved;
export const payGroupPendingSelector = (state: RootState): boolean => state.payGroups.pending;
export const payGroupPendingListSelector = (state: RootState): boolean => state.payGroups.pendingList;
export const payGroupPendingAssignLocationsSelector = (state: RootState): boolean => state.payGroups.pendingAssignLocations;
export const payGroupPendingPayrollPeriodsListSelector = (state: RootState): boolean => state.payGroups.pendingPayrollPeriodsList;
export const payGroupAssignedLocationsSelector = (state: RootState): boolean => state.payGroups.assignedLocations;
export const payGroupsIsListFetchedSelector = (state: RootState): boolean => state.payGroups.isListFetched;
export const selectedPayGroupSelector = (state: RootState): PayGroupResponseType | null => state.payGroups.selectedPayGroup;
export const payrollPeriodsSavedSelector = (state: RootState): boolean => state.payGroups.payrollPeriodsSaved;
export const payrollPeriodsFetchedSelector = (state: RootState): boolean => state.payGroups.payrollPeriodsFetched;
export const hasInitialPeriodsSelector = (state: RootState): boolean => state.payGroups.hasInitialPeriods;
export const payrollPeriodsGeneratedSelector = (state: RootState): boolean => state.payGroups.payrollPeriodsGenerated;
export const payGroupPayrollPeriodsSelector = (state: RootState):
  PayrollPeriod[] => state.payGroups.payrollPeriods;
export const payGroupPendingPatchSelector = (state: RootState): boolean => state.payGroups.pendingPatchPayGroup;

export default payGroupsSlice.reducer;
