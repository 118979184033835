import { StyleObject } from 'styletron-react';
import { colors } from 'theme';

export const contentContainerStyles = {
  padding: '0',
  background: '#E5E5E5',
  zIndex: 50,
  minHeight: 'calc(100vh - 72px)',
  width: '100%',
  boxSizing: 'border-box' as any,
  marginTop: '24px',
};

export const batchHeaderContainerStyle = {
  position: 'relative',
  display: 'inline-block',
  width: '100%',
  zIndex: 100,
  boxShadow: '0px 0px 4px #ccc',
  background: colors.header,
  marginBottom: '24px',
} as StyleObject;
