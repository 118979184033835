import { useStyletron } from 'baseui';
import AppModal from 'components/AppModal/AppModal';
import Loader from 'components/Loader';
import { memo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLoggedOrganization } from 'store/slices/loggedOrganization';
import { ModalNames, setModal } from 'store/slices/modals';
import { fetchWorkerEmployments, workersPendingEmploymentsSelector, workersWorkerEmploymentsSelector } from 'store/slices/workers';
import { OrganizationIDType } from 'types/OrganizationTypes';
import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import WorkerEmploymentsListItem from './WorkerEmploymentsListItem';

const WorkerEmploymentsModal = ({
  workerId,
}: { workerId: string }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'workers']);
  const modalName = ModalNames.WORKER_EMPLOYMENTS_MODAL;
  const history = useHistory();
  const pending = useAppSelector(workersPendingEmploymentsSelector);
  const employments = useAppSelector(workersWorkerEmploymentsSelector);
  const [css] = useStyletron();
  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const visitEmployment = (
    organization: { name: string, id: OrganizationIDType } | undefined,
    employeeId: string,
  ) => {
    dispatch(setLoggedOrganization(organization));
    history.push(`/loggedOrganization/employees/${employeeId}`);
    handleModalClose();
  };

  useEffect(() => {
    dispatch(fetchWorkerEmployments({ workerID: workerId }));
  }, []);

  return (
    <AppModal
      modal={modalName}
      title={t('workers:employmentsModalTitle')}
      cancelBtnText={t('common:cancel')}
      onClose={handleModalClose}
    >
      <Loader active={pending} />
      <div className={css({
        maxHeight: '350px',
        overflowY: 'auto',
        overflowX: 'hidden',
      })}
      >
        {employments?.map((employment) => (
          <WorkerEmploymentsListItem employment={employment} visitEmployment={visitEmployment} />
        ))}
        {employments?.length === 0 && (
          <Block
            marginTop="24px"
            marginBottom="24px"
          >
            <Card>
              <p>{t('workers:employmentsNotFound')}</p>
            </Card>
          </Block>
        )}
      </div>
    </AppModal>
  );
};

export default memo(WorkerEmploymentsModal);
