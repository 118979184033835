import {
  memo,
} from 'react';
import {
  useAppSelector,
  useAppDispatch,
} from 'store/hooks';
import {
  Grid,
} from 'baseui/layout-grid';
import CellFormControl from 'components/CellFormControl';
import {
  SIZE,
  Select,
  Value,
  OnChangeParams,
} from 'baseui/select';
import { useTranslation } from 'react-i18next';
import {
  setOrganizationsPaycardRegisterCardLocation,
  paycardOrganizationRegisterCardSelectedLocationSelector,
  paycardOrganizationEligibleLocationsListSelector,
  paycardOrganizationEligibleLocationsPendingSelector,
} from 'store/slices/paycards';
import { OrganizationPaycardEligibleLocation } from 'types/PaycardManagementTypes';

const PaycardManagementRegisterCardSelectLocation = () => {
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const locations = useAppSelector(paycardOrganizationEligibleLocationsListSelector);
  const pendingLocations = useAppSelector(paycardOrganizationEligibleLocationsPendingSelector);
  const dispatch = useAppDispatch();
  const location = useAppSelector(paycardOrganizationRegisterCardSelectedLocationSelector);

  const eligibleLocations = locations?.filter((loc: OrganizationPaycardEligibleLocation) => loc.nonPersonalizedCardsProgramEnabled);

  const handleLocationChange = ({ value }: OnChangeParams): void => {
    dispatch(setOrganizationsPaycardRegisterCardLocation(value));
  };

  return (
    <Grid gridColumns={12} gridMargins={24}>
      <CellFormControl
        cellSpan={[12, 4, 2.5]}
        label={t('common:location')}
      >
        <Select
          isLoading={pendingLocations}
          disabled={pendingLocations}
          size={SIZE.compact}
          id="paycard-management-register-card-location-select"
          clearable={false}
          placeholder={t('common:select')}
          type="select"
          options={eligibleLocations?.map(({
            name,
            id,
          }) => ({
            name,
            id,
          }))}
          labelKey="name"
          valueKey="id"
          onChange={handleLocationChange}
          value={location as Value}
          maxDropdownHeight="300px"
          overrides={{
            ControlContainer: {
              props: {
                id: 'paycard-management-register-card-location-select',
                'data-testid': 'paycard-management-register-card-location-selector',
              },
            },
          }}
        />
      </CellFormControl>
    </Grid>
  );
};

export default memo(PaycardManagementRegisterCardSelectLocation);
