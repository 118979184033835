import {
  FC,
  Fragment,
  memo,
  ReactNode,
} from 'react';
import {
  StyledTable,
  StyledHeadCell,
  StyledBodyCell,
} from 'baseui/table-grid';
import { StyleObject } from 'styletron-react';

type StyledRowType = {
  cells: Array<ReactNode>,
  id: string,
};

type StyledHeadingCellType = string | object;

type AppStyleTablePropTypes = {
  tableStyle?: StyleObject,
  headingCells: Array<StyledHeadingCellType>,
  rows: Array<StyledRowType>,
    tableGridTemplateColumns: string
};

const StyledHead: FC<any> = ({ children }: any) => (
  <StyledHeadCell
    $style={{
      borderLeftColor: 'unset',
      borderLeftWidth: '0',
      borderRightColor: 'unset',
      borderRightWidth: '0',
      borderBottomColor: 'rgba(0, 0, 0, 0.12)',
      borderBottomWidth: '1px',
      boxShadow: 'none',
    }}
  >
    {children}
  </StyledHeadCell>
);

const StyledCell: FC<any> = ({ children, ...rest }: any) => (
  <StyledBodyCell
    $style={{
      display: 'flex',
      alignItems: 'center',
      'text-overflow': 'ellipsis',
      'white-space': 'nowrap',
      overflow: 'hidden',
    }}
    {...rest}
  >
    {children}
  </StyledBodyCell>
);

const AppStyledTable = ({
  tableStyle,
  headingCells,
  rows,
  tableGridTemplateColumns,
}: AppStyleTablePropTypes) => (
  <StyledTable $style={tableStyle} $gridTemplateColumns={tableGridTemplateColumns}>
    {headingCells.map((cell: StyledHeadingCellType) => (<StyledHead key={`sh-${Math.random()}`}>{cell}</StyledHead>))}
    {rows.map((row: StyledRowType, index: number) => {
      const striped = index % 2 === 0;
      return (
        <Fragment key={row.id}>
          {row.cells.map((cell: ReactNode) => (
            <StyledCell key={`${row.id}-${Math.random()}`} $striped={striped}>
              {cell}
            </StyledCell>
          ))}
        </Fragment>
      );
    })}
  </StyledTable>
);

export default memo(AppStyledTable);
