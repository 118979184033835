import {
  createContext,
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Layer } from 'baseui/layer';
import {
  useAppSelector,
  useAppDispatch,
} from 'store/hooks';
import {
  ModalNames,
  setModal,
} from 'store/slices/modals';
import AppModal from 'components/AppModal/AppModal';
import {
  administratorSelector,
  resetAdministrator,
  setAdministrator,
  updateAdminRoles,
} from 'store/slices/administrators';
import {
  FormikHandlers,
  FormikState,
  useFormik,
} from 'formik';
import {
  AdministratorRolesEnum,
  EditAdministratorFormType,
} from 'types/AdministratorsTypes';
import {
  fetchAllOrganizations,
  organizationsPendingListSelector,
  organizationsSelector,
} from 'store/slices/organizations';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import AppCheckbox from 'components/Form/AppCheckbox';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { updateAdminRolesMapper } from 'dataMappers/administratorDataMapper';
import Loader from 'components/Loader';
import {
  fetchLocations,
  locationsSelector,
} from 'store/slices/locations';
import {
  Select,
  SIZE,
  Value,
} from 'baseui/select';
import AppFormControl from 'components/Form/AppFormControl';
import { LabelMedium } from 'baseui/typography';
import { Block } from 'baseui/block';
import { LABEL_PLACEMENT } from 'baseui/checkbox';
import hasAccess from 'utils/hasAccess';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import { userAccessUnitsSelector } from 'store/slices/user';
import {
  checkForAdminRules,
  roleValueToRoleProp,
} from './AdministratorsHelpers';

export const ProfileFormContext = createContext(
  {} as FormikState<Partial<EditAdministratorFormType>> & FormikHandlers,
);

const EditAdministratorModal = () => {
  const dispatch = useAppDispatch();
  const admin = useAppSelector(administratorSelector);
  const organizations = useAppSelector(organizationsSelector);
  const locations = useAppSelector(locationsSelector);
  const [organizationIDChanged, setOrganizationIDChanged] = useState<boolean>(false);
  const [profile, setProfile] = useState<Value>();
  const [profileChanged, setProfileChanged] = useState<boolean>(false);
  const pending = useAppSelector(organizationsPendingListSelector);
  const editorRoles = useAppSelector(userAccessUnitsSelector);
  const initialRoles = Object.entries(roleValueToRoleProp).map(
    ([key, value]) => ({ [value]: admin?.roles?.indexOf(key) !== -1 }),
  );
  const initialValues = {
    ...Object.assign({}, ...initialRoles),
    organizationID: admin?.organizations ? admin?.organizations?.map((org) => ({ label: org.name || '', value: org.id || '' }))
      : null,
    locationID: admin?.locations ? admin?.locations?.map((loc) => ({ label: loc.name || '', value: loc.id || '' }))
      : null,
  };
  const { t } = useTranslation(['administrators', 'common']);

  const organizationOptions = organizations?.map((org) => ({ label: org.name, value: org.id }))
    ?.sort((a, b) => a.label.localeCompare(b.label));

  const locationsOptions = locations?.map((loc) => ({ label: loc.name, value: loc.id }))
    ?.sort((a, b) => a.label.localeCompare(b.label));

  const adminProfileEditorEWAManager = [
    { value: 'ewaManager', label: t('administrators:adminRole.fuegoAdmin') },
    { value: 'ewaClientManager', label: t('administrators:adminRole.customerAdmin') },
    { value: 'ewaClientLocationManager', label: t('administrators:adminRole.customerLocationManager') },
  ];
  const adminProfileEditorEWAClientManager = [
    { value: 'ewaClientManager', label: t('administrators:adminRole.customerAdmin') },
    { value: 'ewaClientLocationManager', label: t('administrators:adminRole.customerLocationManager') },
  ];

  const modalName = ModalNames.EDIT_ADMIN_MODAL;
  const {
    firstName,
    lastName,
    id,
    roles,
  } = admin;
  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };
  const handleModalClose = () => {
    dispatch(resetAdministrator());
    setIsModalOpen(false);
  };

  const handleClickSave = (
    values: EditAdministratorFormType,
  ) => {
    const data = updateAdminRolesMapper(values);
    dispatch(updateAdminRoles({ data, id: String(id) }))
      .then(() => {
        handleModalClose();
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleClickSave,
  });

  const {
    values,
    handleSubmit,
    isSubmitting,
    isValid,
    setValues,
    setFieldValue,
    errors,
    touched,
    handleBlur,
  } = formik;

  const { hasRules } = checkForAdminRules(values);

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  useEffect(() => {
    dispatch(setAdministrator(admin));
    setDefaultValues(initialValues);
    values.ewaManager && setProfile(
      [
        {
          value: 'ewaManager',
          label: t('administrators:adminRole.fuegoAdmin'),
        },
      ],
    );
    values.ewaClientManager && setProfile(
      [
        {
          value: 'ewaClientManager',
          label: t('administrators:adminRole.customerAdmin'),
        },
      ],
    );
    values.ewaClientLocationManager && setProfile(
      [
        {
          value: 'ewaClientLocationManager',
          label: t('administrators:adminRole.customerLocationManager'),
        },
      ],
    );
    if (organizations && organizations.length < 51) {
      dispatch(fetchAllOrganizations({ pageSize: '1000' }));
    }
    return () => {
      dispatch(resetAdministrator());
    };
  }, []);

  useEffect(() => {
    if (organizationIDChanged
      && (values.organizationID && values.organizationID.length > 0 && values.organizationID?.[0].value)) {
      setValues({
        ...values,
        locationID: [],
      });
    }
    if (values.ewaClientLocationManager && !!values.organizationID && values.organizationID[0].value !== '') {
      dispatch(fetchLocations({
        organizationID: values.organizationID && values.organizationID[0].value,
      }));
    }
  }, [values.organizationID,
    organizationIDChanged]);

  useEffect(() => {
    if (profile && profile.length > 0 && profileChanged) {
      setValues({
        ...values,
        ewaManager: profile?.[0].value === 'ewaManager',
        ewaClientManager: profile?.[0].value === 'ewaClientManager',
        ewaClientLocationManager: profile?.[0].value === 'ewaClientLocationManager',
        treasuryManager: false,
        treasuryReader: false,
        fboManager: false,
        fboReader: false,
        tipsReader: false,
        ewaReport: false,
        tipsClientManager: false,
        fddClientManager: false,
        paycardClientManager: false,
        organizationID: profile?.[0].value === 'ewaClientManager' ? [] : [{ label: '', value: '' }],
        locationID: [],
      });
    }
    setProfileChanged(false);
  }, [profileChanged,
    profile]);

  useEffect(() => {
    if (!organizations && !pending) {
      dispatch(fetchAllOrganizations({ pageSize: '1000' }));
    }
  }, [organizations,
    pending]);

  return (
    <Layer index={400}>
      <ProfileFormContext.Provider value={formik}>
        <AppModal
          modalWidth={['90vw', '80vw', '80vw', '70vw']}
          minWidth="360px"
          maxWidth="100%"
          modal={modalName}
          title={`${firstName} ${lastName} - ${t('administrators:permissions.header')}`}
          onClose={handleModalClose}
          onAction={handleSubmit}
          isActionDisabled={isSubmitting || !isFormChanged || !isValid || !hasRules}
          actionBtnText={t('administrators:updateButton')}
        >
          <Loader active={pending || isSubmitting} />

          <Grid
            align={ALIGNMENT.center}
            gridColumns={12}
            gridMargins={17}
          >
            <Cell
              span={[12, 8, 4]}
              align={ALIGNMENT.start}
            >
              <AppFormControl
                showStar
                label={t('administrators:adminRole.label')}
              >
                <Select
                  size={SIZE.compact}
                  clearable={false}
                  id="adminProfile"
                  overrides={{
                    ControlContainer: { props: { 'data-testid': 'select-edit-adminProfile', id: 'select-edit-adminProfile' } },
                    Input: { props: { 'data-testid': 'select-edit-adminProfile-input', id: 'select-edit-adminProfile' } },
                  }}
                  placeholder={t('common:select')}
                  type="select"
                  options={editorRoles.includes(AccessUnit.EWAClientManager) ? adminProfileEditorEWAClientManager : adminProfileEditorEWAManager}
                  labelKey="label"
                  valueKey="value"
                  onChange={({ value }) => {
                    setProfile(value);
                    setProfileChanged(true);
                  }}
                  value={profile as Value}
                  maxDropdownHeight="150px"
                />
              </AppFormControl>
            </Cell>
            {profile && profile.length > 0 && profile[0].value !== 'ewaManager' && (
            <Cell
              span={[12, 8, 4]}
              align={ALIGNMENT.start}
            >
              <AppFormControl
                showStar
                label={t('administrators:chooseOrganization')}
                error={!!(errors.organizationID && touched.organizationID && errors.organizationID)}
              >
                <Select
                  size={SIZE.compact}
                  id="organizationID"
                  type="select"
                  clearable={false}
                  multi={values.ewaClientManager}
                  searchable
                  options={organizationOptions}
                  labelKey="label"
                  valueKey="value"
                  maxDropdownHeight="150px"
                  onBlur={handleBlur}
                  placeholder={t('common:select')}
                  value={values.organizationID}
                  onChange={(option) => {
                    setFieldValue('organizationID', values.ewaClientManager ? option.value : [option.option]);
                    setOrganizationIDChanged(true);
                  }}
                  error={!!(errors.organizationID && touched.organizationID && errors.organizationID)}
                  overrides={{
                    ValueContainer: {
                      props: {
                        'data-testid': 'Form-select-edit-admin-loc-manager-organizationID',
                      },
                    },
                    ControlContainer: {
                      props: {
                        id: 'Form-select-edit-admin-loc-manager-organizationID',
                      },
                    },
                    Input: {
                      props: {
                        id: 'Form-select-input-edit-admin-loc-manager-organizationID',
                        name: 'Form-select-input-name-edit-admin-loc-manager-organizationID',
                      },
                    },
                  }}
                />
              </AppFormControl>
            </Cell>

            )}
            {profile && profile.length > 0 && profile[0].value === 'ewaClientLocationManager' && (

            <Cell
              span={[12, 8, 4]}
              align={ALIGNMENT.start}
            >
              <AppFormControl
                showStar
                label={t('administrators:chooseLocation')}
              >
                <Select
                  size={SIZE.compact}
                  id="locationID"
                  type="select"
                  searchable
                  clearable={false}
                  multi
                  options={locationsOptions}
                  labelKey="label"
                  valueKey="value"
                  maxDropdownHeight="150px"
                  onBlur={handleBlur}
                  placeholder={t('common:select')}
                  value={values.locationID}
                  onChange={(option) => { setFieldValue('locationID', option.value); }}
                  error={!!(errors.locationID && touched.locationID && errors.locationID)}
                  disabled={(values.organizationID && values.organizationID.length > 0
                    && (values.organizationID?.[0].value === '' || values.organizationID?.[0].value === undefined))}
                  overrides={{
                    ValueContainer: {
                      props: {
                        'data-testid': 'Form-select-edit-admin-locationID',
                      },
                    },
                    ControlContainer: {
                      props: {
                        id: 'Form-select-edit-admin-locationID',
                      },
                    },
                    Input: {
                      props: {
                        id: 'Form-select-edit-admin-input-locationID',
                        name: 'Form-select-edit-admin-input-name-locationID',
                      },
                    },
                  }}
                />
              </AppFormControl>
            </Cell>
            )}

          </Grid>
          <br />

          <Block
            overrides={{
              Block: {
                style: {
                  '@media screen and (max-width: 768px)': {
                    marginLeft: '0px',
                  },
                  marginLeft: '-20px',
                },
              },
            }}
          >
            <Grid
              gridColumns={12}
              gridMargins={17}
            >
              {profile && profile.length > 0 && profile[0].value === 'ewaManager'
              && (hasAccess(
                AccessCheckType.oneOf,
                [AccessUnit.TreasuryManager,
                  AccessUnit.FBOManager,
                  AccessUnit.TreasuryReader,
                  AccessUnit.FBOReader,
                  AccessUnit.EWAReport],
              )
              || (hasAccess(AccessCheckType.oneOf, [AccessUnit.TreasuryReader]) && roles?.includes(AdministratorRolesEnum.TREASURY_READER))) && (
              <Cell
                span={[12, 8, 4]}
                align={ALIGNMENT.start}
              >
                <Cell
                  span={12}
                  align={ALIGNMENT.start}
                >
                  <LabelMedium>{t('administrators:permissions.treasuryManagement.header')}</LabelMedium>
                </Cell>
                {(hasAccess(AccessCheckType.oneOf, [AccessUnit.TreasuryManager])
                || (hasAccess(AccessCheckType.oneOf, [AccessUnit.TreasuryReader]) && roles?.includes(AdministratorRolesEnum.TREASURY_READER))) && (
                <AppCheckbox
                  name="treasuryReader"
                  label={
                    t('administrators:treasuryReader.label')
                  }
                  labelPlacement={LABEL_PLACEMENT.left}
                  cellSpan={12}
                  context={ProfileFormContext}
                />
                )}
                {hasAccess(AccessCheckType.oneOf, [AccessUnit.TreasuryManager, AccessUnit.FBOManager]) && (
                <AppCheckbox
                  name="fboManager"
                  label={
                    t('administrators:fboManager.label')
                  }
                  labelPlacement={LABEL_PLACEMENT.left}
                  cellSpan={12}
                  context={ProfileFormContext}
                  checkboxProps={{
                    onChange: (option) => {
                      const isChecked = option.currentTarget.checked;
                      setValues({
                        ...values,
                        fboManager: isChecked,
                        treasuryManager: isChecked ? values.treasuryManager : false,
                      });
                    },
                  }}
                />
                )}
                { values.fboManager && hasAccess(AccessCheckType.oneOf, [AccessUnit.TreasuryManager]) && (
                <AppCheckbox
                  name="treasuryManager"
                  label={t('administrators:treasuryManager.label')}
                  labelPlacement={LABEL_PLACEMENT.left}
                  cellSpan={12}
                  context={ProfileFormContext}
                />
                )}
                <AppCheckbox
                  name="ewaReport"
                  label={t('administrators:ewaReport.label')}
                  labelPlacement={LABEL_PLACEMENT.left}
                  cellSpan={12}
                  context={ProfileFormContext}
                />
              </Cell>
              )}

              <Cell
                span={[12, 8, 4]}
                overrides={{
                  Cell: {
                    style: {
                      'align-self': 'flex-start',
                    },
                  },
                }}
              >
                {profile && profile.length > 0 && (
                <Cell
                  span={12}
                  align={ALIGNMENT.start}
                >
                  <LabelMedium>{t('administrators:permissions.tipsManagement.header')}</LabelMedium>
                </Cell>
                )}

                {profile && profile.length > 0 && profile[0].value !== 'ewaClientLocationManager' && (
                <AppCheckbox
                  name="tipsReader"
                  label={
                    t('administrators:tipsReader.label')
                  }
                  labelPlacement={LABEL_PLACEMENT.left}
                  cellSpan={12}
                  context={ProfileFormContext}
                />
                )}
                {profile && profile.length > 0 && profile[0].value !== 'ewaManager' && (
                <AppCheckbox
                  name="tipsClientManager"
                  label={
                      t('administrators:tipsClientManager.label')
                    }
                  labelPlacement={LABEL_PLACEMENT.left}
                  cellSpan={12}
                  context={ProfileFormContext}
                />
                )}

              </Cell>

              {profile && profile.length > 0 && profile[0].value === 'ewaClientManager' && (
              <Cell
                span={[12, 8, 4]}
              >
                <Cell
                  span={12}
                  align={ALIGNMENT.start}
                >
                  <LabelMedium>{t('administrators:permissions.fuegoDirectDeposit.header')}</LabelMedium>
                </Cell>
                <AppCheckbox
                  name="fddClientManager"
                  label={
                      t('administrators:fddClientManager.label')
                    }
                  labelPlacement={LABEL_PLACEMENT.left}
                  cellSpan={12}
                  context={ProfileFormContext}
                />
              </Cell>
              )}

              {profile
              && profile.length > 0
              && profile[0].value === 'ewaClientManager'
              && editorRoles.includes(AccessUnit.PaycardClientManager)
              && (
              <Cell
                span={[12, 8, 4]}
              >
                <Cell
                  span={12}
                  align={ALIGNMENT.start}
                >
                  <LabelMedium>{t('administrators:permissions.paycardManagement.header')}</LabelMedium>
                </Cell>
                <AppCheckbox
                  name="paycardClientManager"
                  label={
                      t('administrators:paycardClientManager.label')
                    }
                  labelPlacement={LABEL_PLACEMENT.left}
                  cellSpan={12}
                  context={ProfileFormContext}
                />
              </Cell>
              )}
            </Grid>
          </Block>
        </AppModal>
      </ProfileFormContext.Provider>
    </Layer>
  );
};

export default memo(EditAdministratorModal);
