import {
  memo,
} from 'react';
import { Button, KIND } from 'baseui/button';
import {
  nameButton,
} from 'screens/CommonHelpers';
import { Worker } from 'types/WorkerTypes';
import { Block } from 'baseui/block';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import AppChip from 'components/AppChip/AppChip';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'baseui';

export type TreasuryWorkerAccountsListItemPropsType = {
  handleAccountClick: () => any
  worker: Worker
};

const chipStyles = {
  'box-sizing': 'border-box',
};

const TreasuryWorkerAccountListItem = ({
  handleAccountClick,
  worker,
}: TreasuryWorkerAccountsListItemPropsType) => {
  const {
    id,
    firstName,
    lastName,
    email,
    mobilePhone,
  } = worker || {};

  const { t } = useTranslation(['employees', 'treasury']);
  const [css] = useStyletron();

  return (
    <Block
      width="100%"
      display="inline-block"
      backgroundColor="#fff"
      marginTop="16px"
      paddingBottom="16px"
    >
      <Grid
        align={ALIGNMENT.start}
        gridColumns={12}
        gridMargins={0}
      >
        <Cell
          span={12}
        >
          <Button
            kind={KIND.tertiary}
            overrides={{
              Root: {
                ...nameButton.Root,
                props: {
                  id: 'treasury-account-list-button',
                },
              },
            }}
            onClick={handleAccountClick}
          >
            <Block
              className="ellipsis"
              display="inline-flex"
              paddingLeft="18px"
            >
              {`${firstName} ${lastName}`}
            </Block>
          </Button>
        </Cell>

        <Cell
          span={12}
        >
          <Block
            className={css(chipStyles)}
            display="inline"
            paddingLeft="12px"
            width="100%"
          >
            <AppChip
              items={(
                <>
                  <strong>
                    {t('treasury:workerAccount.workers.workerID')}
                  </strong>
                  :
                  &nbsp;
                  {id}
                </>
              )}
            />
          </Block>

          <Block
            className="ellipsis"
            display="inline"
            paddingLeft="18px"
            margin="2px"
          >
            <AppChip items={(
              <>
                <strong>
                  {t('treasury:workerAccount.workers.email')}
                </strong>
                :
                &nbsp;
                {email}
              </>
            )}
            />
          </Block>

          <Block
            className="ellipsis"
            display="inline"
            paddingLeft="18px"
            margin="2px"
          >
            <AppChip items={(
              <>
                <strong>
                  {t('employees:mobileNumber')}
                </strong>
                :
                &nbsp;
                {mobilePhone}
              </>
            )}
            />
          </Block>
        </Cell>
      </Grid>
    </Block>
  );
};

export default memo(TreasuryWorkerAccountListItem);
