import { OrganizationIDType } from 'types/OrganizationTypes';
import { WorkerIDType } from 'types/WorkerTypes';
import request from './request';

export const generateDirectDepositReportRequest = (
  token: string,
  organizationID: OrganizationIDType,
  fromDate?: string,
  toDate?: string,
) => {
  const queryParams = new URLSearchParams({
  });
  if (fromDate) {
    queryParams.set('fromDate', fromDate);
  }
  if (toDate) {
    queryParams.set('toDate', toDate);
  }

  return request({
    token,
    method: 'GET',
    url: `organization/${organizationID}/enrollments?${queryParams}`,
  });
};

export const fetchEnrollmentByWorkerIdRequest = (
  token: string,
  organizationID: OrganizationIDType,
  workerID: WorkerIDType,
) => request({
  token,
  method: 'GET',
  url: `organization/${organizationID}/enrollments/${workerID}`,
});
