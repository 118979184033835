import {
  createContext,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { ALIGNMENT, Cell } from 'baseui/layout-grid';
import { Layer } from 'baseui/layer';
import { LabelSmall, ParagraphSmall } from 'baseui/typography';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import AppModal from 'components/AppModal/AppModal';
import Loader from 'components/Loader';
import AppTextarea from 'components/Form/AppTextarea';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { resendMoneyMfaInitialValues } from 'initialValues/EmployeeInitialValues';
import { PaymentOptionsType, RefundFees, ResendMoneyMfaValuesType } from 'types/WorkerAccountTypes';
import { Draw } from 'types/EmployeeTypes';
import {
  fetchPaymentOptions, paymentOptionsPendingSelector, paymentOptionsRejectedSelector, paymentOptionsSameDayBankTransferSelector, paymentOptionsSelector,
  resendMoneyFromMFA,
} from 'store/slices/transactions';
import { emptyPlaceholder } from 'theme';
import { STYLE_TYPE } from 'baseui/checkbox';
import AppInput from 'components/Form/AppInput';
import AppCheckbox from 'components/Form/AppCheckbox';
import { resendMoneyMfaSchema as validationSchema } from 'validation/employmentsSchema';
import AppSelect from 'components/Form/AppSelect';
import { paymentOptionToSubElementMapper, paymentOptionsDataMapper } from '../../../../dataMappers/employmentDataMapper';

export const ResendMoneyMFAModalContext = createContext(
  {} as FormikState<ResendMoneyMfaValuesType> & FormikHelpers<ResendMoneyMfaValuesType> & FormikHandlers,
);

interface Props {
  selectedDraw: Draw | undefined;
  fetchDraws: (filter: any) => void;
  isTPO?: boolean;
}

const ResendMoneyMFAModal = ({ selectedDraw, fetchDraws, isTPO }: Props) => {
  const { t } = useTranslation(['employees', 'errors', 'common']);
  const dispatch = useAppDispatch();
  const modals = useAppSelector(modalsSelector);
  const isModalOpen = checkIsModalOpen(modals, ModalNames.RESEND_MONEY_MFA_MODAL);
  const [refundFees, setRefundFees] = useState<RefundFees[]>([]);
  const paymentOptions = useAppSelector(paymentOptionsSelector);
  const pending = useAppSelector(paymentOptionsPendingSelector);
  const rejected = useAppSelector(paymentOptionsRejectedSelector);
  const sameDayBankTransfer = useAppSelector(paymentOptionsSameDayBankTransferSelector);

  const initialValues: ResendMoneyMfaValuesType = {
    ...resendMoneyMfaInitialValues,
    paymentMethod: [{ value: '' }],
  };

  const options = useMemo(() => paymentOptionsDataMapper(paymentOptions), [paymentOptions]);

  const onSubmit = (
    values: ResendMoneyMfaValuesType,
  ) => {
    if (paymentOptions?.length > 0 && selectedDraw) {
      const accountId = values.paymentMethod[0].value;
      const selectedPaymentMethod = paymentOptions.find((option) => option.id === accountId);
      const paymentMethod = selectedPaymentMethod && paymentOptionToSubElementMapper(selectedPaymentMethod.type as PaymentOptionsType, sameDayBankTransfer);

      paymentMethod && dispatch(resendMoneyFromMFA(({
        workerID: selectedDraw?.workerId,
        data: {
          ledgerId: `${selectedDraw?.id}`,
          paymentMethod,
          accountId,
          refundFees,
          zendeskTicket: values.zendeskTicket ? `${values.zendeskTicket}` : '',
          notes: values.notes,
        },
      }))).then(() => {
        dispatch(setModal({
          name: ModalNames.RESEND_MONEY_MFA_MODAL,
          isOpen: false,
        }));
        if (!isTPO) {
          fetchDraws({ pageNumber: '1', type: 'EWA' });
        } else {
          fetchDraws({
            pageNumber: '1',
            type: 'TCO',
            includeOfferDetails: 'true',
          });
        }
      });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    resetForm,
    validateForm,
    isSubmitting,
    setSubmitting,
    setValues,
    isValid,
    setErrors,
    errors,
  } = formik;

  const handleModalClose = () => {
    resetForm();
  };

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  useEffect(() => {
    if (isModalOpen && selectedDraw) {
      setSubmitting(false);
      setValues({
        ...initialValues,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    selectedDraw && dispatch(fetchPaymentOptions({ workerID: selectedDraw.workerId }));
  }, [selectedDraw]);

  useEffect(() => {
    if (errors) {
      setErrors({
        ...errors,
      });
    }
  }, [values, errors]);

  const handleCheckboxChange = (feeType: RefundFees, isChecked: boolean) => {
    setRefundFees((prevFees) => {
      if (isChecked) {
        return [...prevFees, feeType];
      }
      return prevFees.filter((fee) => fee !== feeType);
    });
  };

  return (
    <Layer index={400}>
      <ResendMoneyMFAModalContext.Provider value={formik}>
        <form>
          <AppModal
            modalWidth={['90vw', '90vw', '50vw', '35vw']}
            modal={ModalNames.RESEND_MONEY_MFA_MODAL}
            title={t('employees:resendMoneyMfaModal.title')}
            cancelBtnText={t('common:cancel')}
            onClose={handleModalClose}
            actionBtnText={t('common:retry')}
            onAction={handleSubmitExtended}
            isCloseDisabled={isSubmitting}
            isActionDisabled={isSubmitting || rejected || !isValid}
            minWidth="35vw"
          >
            <Loader active={pending || isSubmitting} />
            <Cell
              span={[12, 12, 12]}
              align={ALIGNMENT.start}
            >
              <LabelSmall display="inline-flex">
                {t('employees:resendMoneyMfaModal.ledgerId')}
                : &nbsp;
              </LabelSmall>
              <ParagraphSmall display="inline-flex">
                {selectedDraw?.id || emptyPlaceholder}
              </ParagraphSmall>
            </Cell>
            <AppSelect
              showStar
              name="paymentMethod"
              label={t('employees:resendMoneyMfaModal.paymentMethod')}
              options={options}
              cellSpan={[12, 12, 12]}
              selectLoading={pending}
              context={ResendMoneyMFAModalContext}
              selectDisabled={pending}
              noResultsText={rejected ? t('errors:errorOnLoadingData') : t('employees:resendMoneyMfaModal.noPaymentOptions')}
            />

            <AppCheckbox
              name="refundTransferFee"
              label={t('employees:resendMoneyMfaModal.refundTransferFee')}
              checkboxType={STYLE_TYPE.default}
              cellSpan={[12]}
              context={ResendMoneyMFAModalContext}
              labelProps={{
                fontSize: '14px',
              }}
              checkboxProps={{
                onChange: (option) => {
                  const isChecked = option.currentTarget.checked;
                  setValues({
                    ...values,
                    refundTransferFee: isChecked,
                  });
                  handleCheckboxChange(RefundFees.TRANSFER_FEE, isChecked);
                },
              }}
            />
            { !isTPO && (
              <AppCheckbox
                name="refundDrawFee"
                label={t('employees:resendMoneyMfaModal.refundDrawFee')}
                checkboxType={STYLE_TYPE.default}
                cellSpan={[12]}
                context={ResendMoneyMFAModalContext}
                labelProps={{
                  fontSize: '14px',
                }}
                checkboxProps={{
                  onChange: (option) => {
                    const isChecked = option.currentTarget.checked;
                    setValues({
                      ...values,
                      refundDrawFee: isChecked,
                    });
                    handleCheckboxChange(RefundFees.DRAW_FEE, isChecked);
                  },
                }}
              />
            )}
            <AppInput
              name="zendeskTicket"
              label={t('employees:resendMoneyMfaModal.zendeskTicket')}
              cellSpan={[12]}
              context={ResendMoneyMFAModalContext}
              inputProps={{
                autoComplete: 'off',
              }}
              type="number"
            />
            <AppTextarea
              name="notes"
              label={t('employees:resendMoneyMfaModal.notes')}
              cellSpan={[12]}
              context={ResendMoneyMFAModalContext}
              textareaProps={{
                id: 'notes-resendMoneyMfa',
                autoComplete: 'off',
              }}
            />
          </AppModal>
        </form>
      </ResendMoneyMFAModalContext.Provider>
    </Layer>
  );
};

export default memo(ResendMoneyMFAModal);
