import {
  memo,
  useEffect,
  useState,
} from 'react';
import {
  OnChangeParams,
  Select,
  SIZE,
  Value,
} from 'baseui/select';
import {
  Grid,
  Cell,
  ALIGNMENT,
} from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  fetchTipsFiles,
  filesPendingSelector,
  resetTipsFiles,
  tipsFilesSelector,
} from 'store/slices/tna';
import { TipsFileType } from 'types/TnaTypes';
import {
  fetchAllOrganizations,
  organizationsSelector,
} from 'store/slices/organizations';
import {
  loggedOrganizationSelector,
} from 'store/slices/loggedOrganization';
import {
  containerStyles,
  headerStyle,
} from 'screens/CommonHelpers';
import moment from 'moment';
import Loader from 'components/Loader';
import CellFormControl from 'components/CellFormControl';
import AppStyledTable from 'components/AppStyledTable/AppStyledTable';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import SectionsTabulation from 'components/SectionsTabulation';
import hasAccess from 'utils/hasAccess';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import {
  fetchLocations,
  locationPendingListSelector,
  locationsSelector,
} from 'store/slices/locations';
import { emptyPlaceholder } from 'theme';
import {
  NotificationType,
  setNotification,
} from 'store/slices/notification';
import { StyleObject } from 'styletron-react';

const rowStyles = {
  ...headerStyle,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whitSpace: 'nowrap',
};
const blockOverrides = {
  Block: {
    style: {
      background: '#F3F3F3',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
  },
};

export const listContainerStyles = {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  minHeight: '120px',
} as StyleObject;

const LOADER_STYLE = {
  background: '#F3F3F3',
};

const IntegrationData = () => {
  const { t } = useTranslation(['integrationData', 'dateFormats']);
  const [css] = useStyletron();
  const dispatch = useAppDispatch();
  const pending = useAppSelector(filesPendingSelector);
  const pendingLocations = useAppSelector(locationPendingListSelector);
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const organizations = useAppSelector(organizationsSelector);
  const files = useAppSelector(tipsFilesSelector);
  const organizationId = `${loggedOrganization?.id.toString()}`;
  const locations = useAppSelector(locationsSelector);
  const [selectedOrganizationValue, setSelectedOrganizationValue] = useState<any>(loggedOrganization ? [loggedOrganization] : []);
  const [location, setLocation] = useState<Value>([]);
  const standardDateFormatWithTime = t('dateFormats:standard-with-time');

  const subMenuHeadings = [
    t('integrationData:gsFiles'),
  ];

  const buttonDisabledRule = !hasAccess(AccessCheckType.oneOf, [AccessUnit.TipsReader]);

  const handleOrganizationSelectChange = ({ value }: OnChangeParams) => {
    setSelectedOrganizationValue(value);
    dispatch(resetTipsFiles());
    setLocation([]);
    if (value && value[0]) {
      dispatch(fetchLocations({
        organizationID: value[0].id?.toString(),
      }));
    }
  };

  const handleLocationChange = ({ value }: OnChangeParams): void => {
    setLocation(value);
    if (value && value[0].tipsProviderId) {
      dispatch(fetchTipsFiles({
        organizationId: selectedOrganizationValue[0].id,
        externalLocationID: value[0].tipsProviderId,
      }));
    } else {
      dispatch(setNotification({
        type: NotificationType.ERROR,
        isOpen: true,
        title: t('errors:error'),
        text: t('tipsManagement:noTipsProviderIdError'),
        autoHideDuration: 3000,
      }));
    }
  };

  useEffect(() => {
    dispatch(resetTipsFiles());
    if (loggedOrganization) {
      dispatch(fetchLocations({
        organizationID: organizationId,
      }));
    }
    if (organizations && organizations.length < 51) {
      dispatch(fetchAllOrganizations({ pageSize: '1000' }));
    }
  }, []);

  return (
    <div className={css(containerStyles)}>
      <CommonHeader title={(t('integrationData:integrationData.header'))} />
      <Grid>
        <SectionsTabulation subMenuHeadings={subMenuHeadings} buttonDisabledRole={buttonDisabledRule} sectionName="integrationData" />
      </Grid>
      <Block
        overrides={blockOverrides}
      >
        <Grid align={ALIGNMENT.center} gridGaps={20}>
          <Cell span={12}>
            <b>
              {t('common:searchBy')}
            </b>
          </Cell>

          <CellFormControl
            cellSpan={[12, 6, 4]}
            label={t('integrationData:gsFiles.orgFilterLabel')}
          >
            <Select
              size={SIZE.compact}
              disabled={pending}
              maxDropdownHeight="280px"
              type="select"
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'integrationData-filter-organization-control-container',
                  },
                },
                Input: {
                  props: {
                    id: 'GratuitySolutions-select-organization',
                    name: 'notASearchOrganizationInput',
                  },
                },
              }}
              clearable={false}
              options={
                organizations
                  ?.map((org) => ({
                    name: org.name,
                    id: org.id,
                  }))
                  ?.sort((a, b) => a.name.localeCompare(b.name))
              }
              labelKey="name"
              valueKey="name"
              onChange={handleOrganizationSelectChange}
              value={selectedOrganizationValue as Value}
            />
          </CellFormControl>
          <CellFormControl
            cellSpan={[12, 6, 4]}
            label={t('common:location')}
          >
            <Select
              size={SIZE.compact}
              isLoading={pendingLocations}
              disabled={!selectedOrganizationValue?.[0]?.id || pendingLocations || pending}
              maxDropdownHeight="280px"
              type="select"
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'integrationData-filter-location-control-container',
                  },
                },
                Input: {
                  props: {
                    id: 'GratuitySolutions-select-location',
                    name: 'notASearchLocationInput',
                  },
                },
              }}
              clearable={false}
              options={locations?.map(({
                name, id, payGroup, timezone, externalLocationId, tipsProviderId,
              }) => ({
                name, id, payGroup, timezone, externalLocationId, tipsProviderId,
              }))}
              labelKey="name"
              valueKey="name"
              onChange={handleLocationChange}
              value={location as Value}
            />
          </CellFormControl>
          {pending && (
          <div className={css(listContainerStyles)}>
            <Loader active={pending} containerStyles={LOADER_STYLE} />
          </div>
          )}
          <Cell span={12}>
            {!pending && files?.length && selectedOrganizationValue.length > 0 && location.length > 0 && location[0].tipsProviderId ? (
              <AppStyledTable
                tableGridTemplateColumns="minmax(480px, 60%) minmax(160px, 20%) minmax(160px, 20%)"
                headingCells={[
                  <span style={headerStyle}>{t('integrationData:gsFiles.nameHeader')}</span>,
                  <span style={headerStyle}>{t('integrationData:gsFiles.locIdHeader')}</span>,
                  <span style={headerStyle}>{t('integrationData:gsFiles.dateSubmittedHeader')}</span>,
                ]}
                rows={files?.map((file: TipsFileType, index: number) => ({
                  id: `${file.name}-${index}`,
                  cells: [
                    <span style={rowStyles}><a href={file.url}>{file.name}</a></span>,
                    <span style={rowStyles}>{location[0].tipsProviderId || emptyPlaceholder}</span>,
                    <span style={rowStyles}>{moment(file.dateSubmitted).format(standardDateFormatWithTime)}</span>,
                  ],
                })) || []}
              />
            ) : (
              <Block />
            )}
            { !pending && files.length === 0 && selectedOrganizationValue.length > 0 && location.length > 0 && location[0].tipsProviderId && (
            <Block
              marginTop="8px"
              marginBottom="8px"
            >
              <Grid gridMargins={0}>
                <Cell span={12}>
                  <Card>
                    <p>{t('integrationData:gsFiles.noFilesFound')}</p>
                  </Card>
                </Cell>
              </Grid>
            </Block>
            )}
          </Cell>
        </Grid>
      </Block>
    </div>
  );
};

export default memo(IntegrationData);
