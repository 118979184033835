import { memo, useEffect } from 'react';
import { useStyletron } from 'styletron-react';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  employeeDetailsSelector, resetEmployeeDetails, resetPayRates,
} from 'store/slices/employees';
import {
  fetchPayGroups,
} from 'store/slices/payGroups';
import { Block } from 'baseui/block';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import {
  ModalNames,
  modalsSelector,
} from 'store/slices/modals';
import { resetWorkerAccount } from 'store/slices/workerAccount';
import { fetchOrganizationConfigs } from 'store/slices/organizations';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { prevPageSelector } from 'store/slices/application';
import Access,
{ AccessUnit } from 'components/Access/Access';
import WorkerShiftsModal from 'screens/Workers/Shifts/WorkerShiftsModal';
import useExposeSpecificFeatureFlag from 'hooks/useExposeSpecificFeatureFlag';
import EmployeeDetailsProfileSection from './EmployeeDetailsProfileSection/EmployeeDetailsProfileSection';
import EmployeeDetailsDrawSection from './EmployeeDetailsDrawSection/EmployeeDetailsDrawSection';
import EmployeeDetailsOfferDetailsModal from './EmployeeDetailsDrawSection/EmployeeDetailsOfferDetailsModal';
import EmployeeDetailsAssignmentsSection from './EmployeeDetailsAssignmentsSection/EmployeeDetailsAssignmentsSection';
import EmployeeDetailsTPOSection from './EmployeeDetailsTPOSection/EmployeeDetailsTPOSection';
import EmployeeDetailsDirectDepositSection from './EmployeeDetailsDirectDepositSection/EmployeeDetailsDirectDepositSection';
import EmployeeDetailsOnDemandPaySection from './EmployeeDetailsOnDemandPaySection/EmployeeDetailsOnDemandPaySection';
import EmployeeDetailsTPOOfferModal from './EmployeeDetailsOnDemandPaySection/TPOOfferModal/EmployeeDetailsTPOOfferModal';
import EmployeeDetailsFuegoProfile from './EmployeeDetailsFuegoProfile/EmployeeDetailsFuegoProfile';
import EmployeeDetailsOnDemandPaySectionNoEditRates from './EmployeeDetailsOnDemandPaySection/EmployeeDetailsOnDemandPaySectionNoEditRates';

export const contentContainerStyles = {
  padding: '24px 0 16px 0',
  background: '#E5E5E5',
  zIndex: 50,
  minHeight: '100vh',
};

const EmployeeDetails = () => {
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const selectedEmployee = useAppSelector(employeeDetailsSelector);
  const modals = useAppSelector(modalsSelector);
  const organizationID = loggedOrganization?.id;
  const prevPage = useAppSelector(prevPageSelector);

  const isEditRatesAvailable = useExposeSpecificFeatureFlag('ratesEditable');

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(resetWorkerAccount());
    dispatch(resetPayRates());
    dispatch(resetEmployeeDetails());

    return () => {
      dispatch(resetWorkerAccount());
      dispatch(resetEmployeeDetails());
    };
  }, []);

  useEffect(() => {
    if (loggedOrganization) {
      dispatch(fetchPayGroups({ organizationID }));
      dispatch(fetchOrganizationConfigs({ organizationID }));
    }
  }, [loggedOrganization]);

  return (
    <>
      <CommonHeader
        backTo={`${prevPage}`}
        title={`${selectedEmployee?.firstName || ''} ${selectedEmployee?.lastName || ''}`}
      />

      <div className={css(contentContainerStyles)}>
        <Block
          marginTop="24px"
        >
          <EmployeeDetailsProfileSection selectedEmployee={selectedEmployee} />
        </Block>

        <Access oneOf={[AccessUnit.EWAManager]}>
          <Block
            marginTop="24px"
          >
            <EmployeeDetailsFuegoProfile selectedEmployee={selectedEmployee} />
          </Block>
        </Access>

        <Block
          marginTop="24px"
        >
          <EmployeeDetailsAssignmentsSection />
        </Block>

        <Block
          marginTop="24px"
        >
          {isEditRatesAvailable
            ? (<EmployeeDetailsOnDemandPaySection selectedEmployee={selectedEmployee} />)
            : (<EmployeeDetailsOnDemandPaySectionNoEditRates selectedEmployee={selectedEmployee} />)}
        </Block>

        <Block
          marginTop="24px"
        >
          <EmployeeDetailsDrawSection selectedEmployee={selectedEmployee} />
        </Block>

        <Block
          marginTop="24px"
        >
          <EmployeeDetailsTPOSection selectedEmployee={selectedEmployee} />
        </Block>

        <Access oneOf={[AccessUnit.EWAManager, AccessUnit.EWAClientManager]}>
          <Block
            marginTop="24px"
          >
            <EmployeeDetailsDirectDepositSection />
          </Block>
        </Access>

      </div>

      {checkIsModalOpen(modals, ModalNames.EMPLOYEE_OFFER_DETAILS_MODAL) && <EmployeeDetailsOfferDetailsModal />}
      {checkIsModalOpen(modals, ModalNames.EMPLOYEE_TPO_OFFER_DETAILS_MODAL) && <EmployeeDetailsTPOOfferModal />}
      {selectedEmployee?.workerId
        && checkIsModalOpen(modals, ModalNames.WORKER_UNIVERSAL_CALENDAR_SHIFTS_MODAL) && <WorkerShiftsModal workerId={selectedEmployee?.workerId} />}
    </>
  );
};

export default memo(EmployeeDetails);
