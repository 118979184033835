import environment from '../environment';
import { constants } from '../constants';

const b2cPolicies = {
  names: {
    signUpSignIn: environment.namesSignUpSignIn,
  },
  authorities: {
    signUpSignIn: {
      authority: environment.authoritiesSignUpSignInAuthority,
    },
  },
  authorityDomain: environment.authorityDomain,
};

export const msalConfig = {
  auth: {
    clientId: environment.authClientId, // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: environment.authRedirectUrl,
    navigateToRequestUrl: false,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['openid', environment.adminAPIWriteScope],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: constants.graphEndpoint,
};
