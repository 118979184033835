import { ReactNode, useContext } from 'react';
// import { Input, InputProps, SIZE } from 'baseui/input';
import { Textarea, TextareaProps, SIZE } from 'baseui/textarea';
import {
  Cell,
  ALIGNMENT,
  Responsive,
  CellProps,
  Alignment,
} from 'baseui/layout-grid';
import {
  FormControlProps,
} from 'baseui/form-control';
import {
  FormikHandlers, FormikHelpers, FormikState,
} from 'formik';
import AppFormControl from './AppFormControl';

export type AppTextareaPropsType = {
  name: string
  context: any
  label?: any
  cellSpan?: Responsive<number> | undefined
  cellAlign?: Alignment | undefined
  cellProps?: CellProps
  textareaProps?: TextareaProps
  formControlProps?: Pick<FormControlProps, Exclude<keyof FormControlProps, 'children'>>
  placeholder?: string
  showStar?: boolean
  tooltipTitle?: ReactNode,
  tooltipContent?: ReactNode,
  disabled?: boolean
}

const AppTextarea = ({
  label,
  name,
  context,
  cellSpan = 3,
  cellAlign = ALIGNMENT.start,
  cellProps,
  textareaProps,
  formControlProps,
  placeholder,
  showStar,
  tooltipTitle,
  tooltipContent,
  disabled = false,
}: AppTextareaPropsType) => {
  const formik = useContext<FormikState<any> & FormikHelpers<any> & FormikHandlers>(context);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
  } = formik;

  return (
    <Cell
      span={cellSpan}
      align={cellAlign}
      {...cellProps}
    >
      <AppFormControl
        tooltipTitle={tooltipTitle}
        tooltipContent={tooltipContent}
        showStar={showStar}
        label={label}
        error={!!(errors[name] && touched[name]) && errors[name]}
        {...formControlProps}
      >
        <Textarea
          size={SIZE.compact}
          type="text"
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name]}
          error={!!(errors[name] && touched[name] && errors[name])}
          placeholder={placeholder}
          disabled={disabled}
          overrides={{
            Input: {
              props: {
                id: `Form-textarea-input-${name}`,
                'data-testid': `Form-textarea-input-${name}`,
              },
            },
          }}
          {...textareaProps}
        />
      </AppFormControl>
    </Cell>
  );
};

export default AppTextarea;
