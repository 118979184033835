import { memo, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid, Cell, ALIGNMENT,
} from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'baseui/button';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'theme';
import { HeadingSmall } from 'baseui/typography';
import { useAppDispatch } from 'store/hooks';
import { setPrevPage } from 'store/slices/application';
import { StyleObject } from 'styletron-react';

export const headerContainerStyle = {
  position: 'relative',
  display: 'inline-block',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  'box-sizing': 'border-box',
  boxShadow: '0px 0px 4px #ccc',
  background: colors.header,
} as StyleObject;

const backButtonOverrides = {
  Root: {
    style: {
      marginRight: '16px',
    },
  },
};

export type CommonHeaderPropTypes = {
  title: string | ReactNode,
  backTo?: string,
  children?: ReactNode,
}

const CommonHeader = ({
  title,
  backTo,
  children = null,
}: CommonHeaderPropTypes) => {
  const [css] = useStyletron();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleBackTo = () => {
    if (backTo) {
      dispatch(setPrevPage(history.location.pathname));
      history.push(String(backTo));
    }
  };

  const truncTitle = title ? (title.toString().replace(/\s+/g, '-').toLowerCase()) : '';

  return (
    <div className={css(headerContainerStyle)}>
      <Grid
        align={ALIGNMENT.start}
      >
        <Cell
          span={12}
          align={ALIGNMENT.start}
          overrides={{
            Cell: {
              props: {
                id: `${truncTitle}-header-cell`,
                'data-testid': `${truncTitle}-header-cell`,
              },
            },
          }}
        >
          {title && (
            <Block
              alignItems="center"
              alignContent="flex-start"
              justifyContent="space-between"
              justifyItems="flex-start"
              display="inline-flex"
              width="100%"
              minWidth="160px"
              height="72px"
            >
              {backTo && (
                <Block
                  alignItems="center"
                  justifyContent="flex-start"
                  width="100%"
                  minWidth="50%"
                  display="inline-flex"
                  height="72px"
                >
                  <Button
                    overrides={{
                      Root: {
                        ...backButtonOverrides.Root,
                        props: {
                          id: `${truncTitle}-back-to-button`,
                        },
                      },
                    }}
                    onClick={handleBackTo}
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                    />
                  </Button>
                  <HeadingSmall overrides={{ Block: { style: { maxWidth: '100%' } } }}>{title}</HeadingSmall>
                </Block>
              )}
              {!backTo && (<HeadingSmall>{title}</HeadingSmall>)}
              {children}
            </Block>
          )}
        </Cell>
      </Grid>
    </div>
  );
};

export default memo(CommonHeader);
