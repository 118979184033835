import {
  ChangeEvent,
  FC,
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import {
  Button,
  KIND,
} from 'baseui/button';
import {
  Grid,
  Cell,
  ALIGNMENT,
} from 'baseui/layout-grid';
import {
  Tag,
  KIND as TAG_KIND,
  VARIANT,
} from 'baseui/tag';
import {
  SIZE,
} from 'baseui/table-semantic';
import { Pagination } from 'baseui/pagination';
import {
  Block,
  BlockProps,
} from 'baseui/block';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';
import {
  PLACEMENT,
  StatefulPopover,
} from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  payGroupsSelector,
  payGroupPendingListSelector,
  patchPayGroup,
  payGroupPendingPatchSelector,
  PayGroup,
} from 'store/slices/payGroups';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import {
  PayGroupIDType,
  PayGroupResponseType,
} from 'types/PayGroupTypes';
import { SourceAccountType } from 'types/RepaymentsBankDetails';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import Loader from 'components/Loader';
import {
  colors,
  pageSize,
} from 'theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCirclePlus,
  faEllipsisVertical,
  faArchive,
} from '@fortawesome/free-solid-svg-icons';
import { StatefulTooltip } from 'baseui/tooltip';
import {
  paginationTransparentOverrides,
  tooltipOverrides,
} from 'screens/CommonHelpers';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import AppStyledTable from 'components/AppStyledTable/AppStyledTable';
import { ParagraphSmall } from 'baseui/typography';
import { OnChangeParams, Select } from 'baseui/select';
import {
  repaymentsOrganizationSourceAccountsListSelector,
  repaymentsOrganizationSourceAccountsPendingSelector,
} from 'store/slices/repaymentsBankDetails';
import { useParams } from 'react-router-dom';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import OrganizationFormPayGroupModal from './OrganizationFormPayGroupModal';
import OrganizationFormAssignLocationsModal from './OrganizationFormAssignLocationsModal';
import OrganizationManagePayPeriodsModal from './OrganizationManagePayPeriodsModal';
import OrganizationPreviewPayGroupModal from './OrganizationPreviewPayGroupModal';

interface IItem {
  label: string
  modalName: string
  disabled?: boolean
  id?: string
}

const containerStyles = {
  width: '100%',
  minHeight: '240px',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 10,
} as StyleObject;

const OrganizationFormPayGroupsSection = () => {
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const { t } = useTranslation(['organizations', 'errors', 'common', 'locations', 'payGroups']);
  const organizationPayGroups = useAppSelector(payGroupsSelector);
  const pendingList = useAppSelector(payGroupPendingListSelector);
  const modals = useAppSelector(modalsSelector);
  const [filteredPayGroups, setFilteredPayGroups] = useState(organizationPayGroups);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [payGroupID, setPayGroupID] = useState<PayGroupIDType>(null);
  const [selectedPaygroup, setSelectedPaygroup] = useState<PayGroupResponseType>();
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [numPages, setNumPages] = useState(1);
  const sourceAccounts = useAppSelector(repaymentsOrganizationSourceAccountsListSelector);
  const [isBankAccountChanged, setIsBankAccountChanged] = useState<boolean>(false);
  const { organizationID } = useParams<{ organizationID: string }>();
  const pending = useAppSelector(repaymentsOrganizationSourceAccountsPendingSelector);
  const pendingPatch = useAppSelector(payGroupPendingPatchSelector);
  const [bankAccountChangedData, setBankAccountChangedData] = useState<OnChangeParams>();
  const isConfirmModalOpen = !!modals?.find((item) => item.name === ModalNames.CONFIRM_MODAL_PAYGROUP_EDIT_BANK_ACCOUNT)?.isOpen;

  const disabledBankAccountSelect = (payGroup: PayGroup) => (
    payGroup.automaticPayrollPeriodGenerationEnabled
      ? ((payGroupID === payGroup.id.toString() && pendingPatch && isBankAccountChanged) || pending)
      : true
  );

  const bankAccountOptions = sourceAccounts?.map((account: SourceAccountType) => ({
    value: account.accountId,
    label: account.nickname || account.accountNumber,
  }));

  const listOfDropdownOptions: IItem[] = [
    {
      label: t('organizations:editPayGroup.label'),
      modalName: ModalNames.PAY_GROUP_FORM_MODAL,
    },
    {
      label: t('organizations:duplicatePayGroup.label'),
      modalName: '',
      disabled: true,
    },
    {
      label: t('organizations:assignLocations.label'),
      modalName: ModalNames.ASSIGN_LOCATIONS_MODAL,
    },
    {
      label: t('organizations:managePayPeriods.label'),
      modalName: ModalNames.PAY_PERIOD_MANAGE_MODAL,
    },
  ];

  const totalSize = filteredPayGroups?.length || 0;

  const setIsConfirmModalOpen = (
    isOpen: boolean,
  ) => {
    isConfirmModalOpen !== isOpen && dispatch(setModal({
      name: ModalNames.CONFIRM_MODAL_PAYGROUP_EDIT_BANK_ACCOUNT,
      isOpen,
    }));
  };

  const updatePayGroups = () => {
    setFilteredPayGroups(
      organizationPayGroups
        ?.filter((payGroup) => payGroup?.name?.toString().toLowerCase().includes(search?.toLowerCase())),
    );
  };

  const handleBankAccountChange = () => {
    setIsBankAccountChanged(true);

    dispatch(patchPayGroup({
      organizationID,
      payGroupID,
      data: {
        bankAccount: {
          bankAccountId: bankAccountChangedData?.option?.value || null,
          bankAccountAlias: bankAccountChangedData?.option?.label && bankAccountChangedData?.option?.value !== null
            ? bankAccountChangedData?.option?.label?.toString()
            : null,
        },
      },
    }))
      .then(() => {
        setIsBankAccountChanged(false);
        setBankAccountChangedData(undefined);
      })
      .catch(() => {
        setIsBankAccountChanged(false);
        setBankAccountChangedData(undefined);
      });
  };

  const handleBankAccountChangeClose = () => {
    setIsConfirmModalOpen(false);
    setIsBankAccountChanged(false);
    setBankAccountChangedData(undefined);
  };

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    setSearch(e.target.value);
  };

  const handleAddPayGroupBtnClick = () => {
    setPayGroupID(null);
    dispatch(setModal({
      name: ModalNames.PAY_GROUP_FORM_MODAL,
      isOpen: true,
    }));
  };

  const handleMenuItemClick = (
    id: number,
    payGroup: PayGroupResponseType,
    modalName: string,
    { close }: { close: () => void },
  ) => {
    setPayGroupID(id.toString());
    setSelectedPaygroup(payGroup);
    setOpenPopover(false);
    dispatch(setModal({
      name: modalName,
      isOpen: true,
    }));
    close();
  };

  const handlePayGroupNameClick = (id: PayGroupIDType) => {
    setPayGroupID(id);
    dispatch(setModal({
      name: ModalNames.PAY_GROUP_PREVIEW_MODAL,
      isOpen: true,
    }));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize);
    setCurrentPage(page);
  };

  const ListItem: FC<BlockProps> = ({ children, ...rest }) => (
    <Block
      display="inline-flex"
      alignItems="center"
      minHeight="36px"
      {...rest}
    >
      {children}
    </Block>
  );

  const rows = filteredPayGroups?.length > 0 ? (filteredPayGroups
    ?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .map((payGroup: PayGroup) => ({
      id: payGroup.id.toString(),
      cells: [
        <ListItem
          overrides={{
            Block: {
              style: {
                opacity: !payGroup.automaticPayrollPeriodGenerationEnabled ? '0.5' : '1',
              },
            },
          }}
        >
          <StatefulTooltip
            accessibilityType="tooltip"
            content={payGroup.name}
            overrides={tooltipOverrides(!payGroup.name?.trim().includes(' '))}
          >
            <Button
              onClick={() => handlePayGroupNameClick(payGroup.id.toString())}
              kind={KIND.tertiary}
              overrides={{
                Root: {
                  props: {
                    id: `OrganizationFormPayGroupsSection-pay-group-${payGroup.id}-name`,
                    'data-testid': 'pay-group-section-name-button',
                  },
                  style: {
                    maxWidth: '1024px',
                    color: colors.primary,
                    position: 'relative',
                    backgroundColor: 'inherit',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    textAlign: 'left',
                    ':hover': {
                      backgroundColor: 'inherit',
                    },
                  },
                },
              }}
            >
              <span>
                {payGroup.name}
              </span>
              {!payGroup.automaticPayrollPeriodGenerationEnabled && (
              <Tag
                overrides={{
                  Root: {
                    style: {
                      position: 'absolute',
                      top: '-6px',
                      right: '-20px',
                    },
                  },
                }}
                closeable={false}
                kind={TAG_KIND.warning}
                variant={VARIANT.solid}
              >
                <FontAwesomeIcon icon={faArchive} size="xs" />
              </Tag>
              )}
            </Button>
          </StatefulTooltip>
        </ListItem>,
        <ListItem
          overrides={{
            Block: {
              style: {
                opacity: !payGroup.automaticPayrollPeriodGenerationEnabled ? '0.5' : '1',
              },
            },
          }}
        >
          {payGroup.payFrequencyRule}
        </ListItem>,
        <ListItem>
          <Select
            size={SIZE.compact}
            type="select"
            clearable={!!payGroup.bankAccount?.bankAccountAlias}
            options={bankAccountOptions}
            isLoading={(payGroupID === payGroup.id.toString() && pendingPatch && isBankAccountChanged) || pending}
            disabled={disabledBankAccountSelect(payGroup)}
            onChange={(params) => {
              setIsConfirmModalOpen(true);
              setBankAccountChangedData(params);
              setPayGroupID(payGroup.id.toString());
            }}
            labelKey="label"
            valueKey="value"
            value={[{ value: payGroup.bankAccount?.bankAccountId, label: payGroup.bankAccount?.bankAccountAlias }]}
            overrides={{
              ControlContainer: {
                props: {
                  'data-testid': `OrganizationFormPayGroupsSection-bankAccount-select-${payGroup.id}`,
                  id: `OrganizationFormPayGroupsSection-bankAccount-select-${payGroup.id}`,
                },
                style: {
                  width: '280px',
                },
              },
            }}
          />
        </ListItem>,

        <ListItem
          display="flex"
          justifyContent="end"
          overrides={{
            Block: {
              style: {
                opacity: !payGroup.automaticPayrollPeriodGenerationEnabled ? '0.5' : '1',
              },
            },
          }}
        >
          <StatefulPopover
            initialState={{ isOpen: openPopover }}
            content={({ close }) => (
              <StatefulMenu
                onItemSelect={({ item }) => handleMenuItemClick(payGroup.id, payGroup as any, item.modalName, { close })}
                items={listOfDropdownOptions}
              />
            )}
            accessibilityType="tooltip"
            placement={PLACEMENT.bottomLeft}
          >
            <Button
              kind={KIND.tertiary}
              overrides={{
                Root: {
                  props: {
                    id: `OrganizationFormPayGroupsSection-pay-group-${payGroup.id}-tooltip-menu`,
                    'data-testid': `OrganizationFormPayGroupsSection-pay-group-${payGroup.id}-tooltip-menu`,
                  },
                  style: {
                    color: colors.primary,
                    backgroundColor: 'inherit',
                    ':hover': {
                      backgroundColor: 'inherit',
                    },
                  },
                },
              }}
            >
              <FontAwesomeIcon
                icon={faEllipsisVertical}
              />
            </Button>
          </StatefulPopover>
        </ListItem>,
      ],
    }
    ))) : ([{
    id: 'no-paygroups-available-message',
    cells: [
      <ParagraphSmall
        overrides={{
          Block: {
            style: {
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            },
          },
        }}
      >
        {t('payGroups:noResults')}
      </ParagraphSmall>, '', '', ''],
  }]);

  useEffect(() => {
    updatePayGroups();
    search && setCurrentPage(1);
  }, [organizationPayGroups, search]);

  useEffect(() => {
    setNumPages(Math.ceil(totalSize / pageSize));
  }, [totalSize]);

  return (
    <div className={css(containerStyles)}>
      <Block
        marginBottom="16px"
      >
        <Loader active={pendingList} />

        <Grid
          gridColumns={12}
          gridGutters={0}
          align={ALIGNMENT.center}
          gridMargins={36}
          gridGaps={8}
        >
          <Cell
            span={[12, 6]}
            align={ALIGNMENT.start}
          >
            <Block
              alignItems="start"
              justifyContent="flex-start"
              display="flex"
              width="auto"
            >
              <Input
                startEnhancer={<Search />}
                type="text"
                name="search"
                value={search}
                onChange={handleChangeSearch}
                clearOnEscape
                placeholder={t('common:searchPlaceholder')}
                overrides={{
                  Input: {
                    props: {
                      id: 'OrganizationFormPayGroupsSection-search',
                      autoComplete: 'off',
                    },
                  },
                }}
              />
            </Block>
          </Cell>

          <Cell
            span={[12, 6]}
          >
            <Block
              alignItems="end"
              justifyContent="flex-end"
              display="flex"
              width="100%"
              minWidth="160px"
              height="100%"
            >
              <Button
                type="button"
                kind={KIND.tertiary}
                onClick={handleAddPayGroupBtnClick}
                overrides={{
                  BaseButton: {
                    props: {
                      id: 'OrganizationFormPayGroupsSection-add-pay-group-btn',
                      'data-testid': 'OrganizationFormPayGroupsSection-add-pay-group-btn',
                    },
                  },
                }}
              >
                <Block
                  marginRight="8px"
                >
                  <FontAwesomeIcon icon={faCirclePlus} />
                </Block>
                {t('payGroups:addPayGroup')}
              </Button>
            </Block>
          </Cell>
        </Grid>
      </Block>

      <Block
        paddingLeft="36px"
        paddingRight="36px"
      >
        <AppStyledTable
          tableGridTemplateColumns="minmax(54%, max-content)
          minmax(10%, max-content)
          minmax(30%, max-content)
          minmax(6%, max-content)"
          tableStyle={{
            gridTemplateColumns: 'minmax(54%, max-content) minmax(10%, max-content) minmax(30%, max-content) minmax(6%, max-content)',
            '@media (max-width: 1200px)': {
              gridTemplateColumns: '500px 150px 330px 60px',
            },
            '@media (max-width: 780px)': {
              gridTemplateColumns: '500px 150px 330px 80px',
            },
          }}
          headingCells={[
            <Block paddingLeft="16px">
              {t('payGroups:payGroupsName')}
            </Block>,
            t('payGroups:payGroupsCycle'),
            t('payGroups:bankAccount'),
            '',
          ]}
          rows={rows}
        />
        <ConfirmModal
          onAction={() => handleBankAccountChange()}
          title={bankAccountChangedData?.type === 'clear'
            ? t('payGroups:bankAccount.remove.confirmation.title')
            : t('payGroups:bankAccount.change.confirmation.title')}
          actionBtnText={t('common:confirm.ok')}
          onClose={handleBankAccountChangeClose}
          modalNameSpecified="CONFIRM_MODAL_PAYGROUP_EDIT_BANK_ACCOUNT"
        />
      </Block>

      {filteredPayGroups?.length > 0 && (
        <Block
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
          marginBottom="16px"
        >
          <Pagination
            size={SIZE.compact}
            numPages={numPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            overrides={paginationTransparentOverrides}
          />
        </Block>
      )}

      {checkIsModalOpen(modals, ModalNames.PAY_GROUP_FORM_MODAL) && <OrganizationFormPayGroupModal payGroupID={payGroupID} />}
      {checkIsModalOpen(modals, ModalNames.ASSIGN_LOCATIONS_MODAL) && <OrganizationFormAssignLocationsModal payGroupID={payGroupID} />}
      {checkIsModalOpen(modals, ModalNames.PAY_PERIOD_MANAGE_MODAL) && <OrganizationManagePayPeriodsModal payGroup={selectedPaygroup} />}
      {checkIsModalOpen(modals, ModalNames.PAY_GROUP_PREVIEW_MODAL) && <OrganizationPreviewPayGroupModal payGroupID={payGroupID} />}
    </div>
  );
};

export default memo(OrganizationFormPayGroupsSection);
