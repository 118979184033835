export const repaymentRequestsList = [
  {
    id: 178,
    amount: {
      value: 8800,
      scale: 2,
      currencyCode: 'USD',
    },
    repaymentId: 244,
    repaymentName: 'test 29 org repayment 244 one',
    status: 'SEND_FOR_APPROVAL',
    requestedAt: '2024-04-26T12:06:47.4466667Z',
    sourceAccountLastFour: '1852',
    sourceAccountNickname: 'new Restaurantv 5',
    transactionId: '024ac45863e64d808f81464b2498a752',
  },
  {
    id: 177,
    amount: {
      value: 7500,
      scale: 2,
      currencyCode: 'USD',
    },
    repaymentId: 243,
    repaymentName: 'test 29 org repayment 243 one',
    status: 'SEND_FOR_APPROVAL',
    requestedAt: '2024-04-26T11:21:03.5966667Z',
    sourceAccountLastFour: '5121',
    sourceAccountNickname: "One more'",
    transactionId: '65e5d7a85d3f41ffb5e93bc9aaebfb89',
  },
  {
    id: 176,
    amount: {
      value: 7500,
      scale: 2,
      currencyCode: 'USD',
    },
    repaymentId: 243,
    repaymentName: 'test 29 org repayment 245 one',
    status: 'NEW',
    requestedAt: '2024-04-26T11:21:03.5966667Z',
    sourceAccountLastFour: '4589',
    sourceAccountNickname: "Test'",
    transactionId: '65e5d7a78d3f41ffb5e93bc9aaebfb89',
  },
];

export const sourceAccountsList = [
  {
    accountId: 11,
    routingNumber: '191817164',
    accountNumber: '5582215121',
    legalName: 'One more',
    nickname: "One more'",
    repaymentStatement: "Description on customer's debit transaction",
    destinationAccount: {
      id: 3,
      displayName: 'Destination ****7474',
    },
  },
  {
    accountId: 13,
    routingNumber: '123333156',
    accountNumber: '1557895335677',
    legalName: 'Happy FOOD OOD',
    nickname: 'Happy Atlanta',
    repaymentStatement: "Description on customer's debit transaction - Happy",
    destinationAccount: {
      id: 3,
      displayName: 'Destination ****7474',
    },
  },
  {
    accountId: 14,
    routingNumber: '728726789',
    accountNumber: '48924792900193',
    legalName: 'Happy FOOD OOD',
    nickname: 'Happy Georgia',
    repaymentStatement: "Description on customer's debit transaction - Happy 1",
    destinationAccount: {
      id: 3,
      displayName: 'Destination ****7474',
    },
  },

];
