import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import { Panel } from 'baseui/accordion';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { Card } from 'baseui/card';
import { Block } from 'baseui/block';
import PriceFormatter from 'utils/priceFormatter';
import {
  listContainerStyles,
  outerContainerStyles,
} from 'screens/Batches/BatchesHelpers';
import {
  allocatedEmploymentDrawsSelector,
  pendingAllocatedEmploymentDrawsSelector,
} from 'store/slices/paymentAllocation';
import { useAppSelector } from 'store/hooks';
import { BatchIDType } from 'types/BatchTypes';
import {
  AllocatedBatchEmploymentType,
  RepaymentIDType,
} from 'types/RepaymentTypes';
import { unScalePrice } from 'utils/priceScale';
import Loader from 'components/Loader';
import AllocatedPaymentsDrawListItem from './AllocatedPaymentsDrawListItem';

export type PaymentAllocationEmploymentListItemPropsType = {
  repaymentID: RepaymentIDType
  batchID: BatchIDType
  employment: AllocatedBatchEmploymentType
}

const AllocatedPaymentsEmploymentListItem = ({
  repaymentID,
  batchID,
  employment,
  ...rest
}: PaymentAllocationEmploymentListItemPropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['batches']);

  const draws = useAppSelector(allocatedEmploymentDrawsSelector);
  const pending = useAppSelector(pendingAllocatedEmploymentDrawsSelector);
  const {
    id,
    firstName,
    lastName,
    payrollId,
    allocatedAmount,
  } = employment;
  const employmentID = id?.toString();

  const hasDraws = draws?.length > 0;

  return (
    <>
      <div className={css(outerContainerStyles)}>
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
          gridMargins={4}
        >
          <Cell
            align={ALIGNMENT.center}
            span={4}
          >
            {`${firstName} ${lastName}`}
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            {payrollId}
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={3}
          >
            <span
              data-testid="PaymentAllocationEmploymentListItem"
            >
              {PriceFormatter().format(unScalePrice(allocatedAmount?.value, allocatedAmount?.scale))}
            </span>
          </Cell>

        </Grid>
      </div>

      <Block
        marginLeft="-20px"
        marginRight="-20px"
      >
        <Panel
          key={employmentID as Key}
          {...rest}
        >
          {!pending && hasDraws && (
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
              gridMargins={0}
            >
              <Cell
                span={4}
              >
                <strong>{t('batches:paymentAllocations.drawDate')}</strong>
              </Cell>

              <Cell
                span={2}
              >
                <strong>{t('batches:paymentAllocations.drawAmount')}</strong>
              </Cell>

              <Cell
                span={3}
              >
                <strong>{t('batches:paymentAllocations.paidAmount')}</strong>
              </Cell>
            </Grid>
          )}

          {pending && (
            <div className={css(listContainerStyles)}>
              <Loader active={pending} />
            </div>
          )}

          {!pending && hasDraws && draws?.map((draw) => (
            <AllocatedPaymentsDrawListItem
              key={draw?.createdDate}
              draw={draw}
            />
          ))}

          {!pending && !hasDraws && (
            <Grid
              gridColumns={12}
              gridMargins={16}
            >
              <Cell span={12}>
                <Card>
                  {t('repayments:notFoundEmploymentDraws')}
                </Card>
              </Cell>
            </Grid>
          )}
        </Panel>
      </Block>
    </>
  );
};

export default AllocatedPaymentsEmploymentListItem;
