import {
  ChangeEvent,
  createContext,
  memo,
  useEffect,
  useState,
} from 'react';
import {
  useAppDispatch, useAppSelector,
} from 'store/hooks';
import {
  Grid,
} from 'baseui/layout-grid';
import { useTranslation } from 'react-i18next';
import {
  Banner,
  HIERARCHY,
  KIND,
} from 'baseui/banner';
import { ParagraphSmall } from 'baseui/typography';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { employeeDetailsInitialValues } from 'initialValues/PaycardsInitialValues';
import { EmployeeDetailsValuesType } from 'types/PaycardManagementTypes';
import AppInput from 'components/Form/AppInput';
import AppSelect from 'components/Form/AppSelect';
import { states } from 'types/CommonTypes';
import { employeeDetailsValidationSchema as validationSchema } from 'validation/paycardManagementSchema';
import {
  paycardOrganizationRegisterCardEmployeeDetailsSelector,
  paycardOrganizationRegisterCardSelectedEmployeeSelector,
  setIsAddEmployeeFormValid,
} from 'store/slices/paycards';
import AppDatePicker from 'components/Form/AppDatePicker';
import formatValue from 'utils/formatValueWithRegex';

export const EmployeeDetailsFormContext = createContext(
  {} as FormikState<EmployeeDetailsValuesType> & FormikHelpers<EmployeeDetailsValuesType> & FormikHandlers,
);

export type PaycardManagementRegisterCardAddEmployeeDetailsPropsType = {
  formValues: EmployeeDetailsValuesType | {},
  setFormValues: (values: EmployeeDetailsValuesType | {}) => void;
}

const PaycardManagementRegisterCardAddEmployeeDetails = (
  {
    formValues,
    setFormValues,
  }: PaycardManagementRegisterCardAddEmployeeDetailsPropsType,
) => {
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const dispatch = useAppDispatch();
  const stateOptions = Object.values(states).map((state) => ({
    label: state,
    value: state,
  }));
  const selectedEmployee = useAppSelector(paycardOrganizationRegisterCardSelectedEmployeeSelector);
  const details = useAppSelector(paycardOrganizationRegisterCardEmployeeDetailsSelector);
  const [ssnInputText, setSSNInputText] = useState(details?.ssn || '');

  const datePickerDateFormat = t('dateFormats:date-picker.standard');
  const datePickerPlaceholder = t('dateFormats:date-picker.placeholder');
  const mobile = selectedEmployee?.phone?.replace(/[+()-]/g, '');

  const initialValues = {
    ...employeeDetailsInitialValues,
    ...formValues,
    firstName: selectedEmployee?.firstName || '',
    lastName: selectedEmployee?.lastName || '',
    payrollId: selectedEmployee?.payrollId || '',
    mobileNumber: mobile ? `${mobile?.slice(0, 3)}-${mobile?.slice(3, 6)}-${mobile?.slice(6, 10)}` : '',
    ...details,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema,
  });

  const {
    values,
    isValid,
    setFieldValue,
    validateForm,
  } = formik;

  const handleSSNChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const formattedSSN = formatValue(e.target.value, /(\d{3})(\d{2})(\d{1,4})/, '$1-$2-$3');
    setSSNInputText(formattedSSN);
    setFieldValue('ssn', formattedSSN);
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const formattedPhone = formatValue(e.target.value, /(\d{3})(\d{3})(\d{1,4})/, '$1-$2-$3');
    setFieldValue('mobileNumber', formattedPhone);
  };

  useEffect(() => {
    dispatch(setIsAddEmployeeFormValid(isValid));
  }, [isValid]);

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  useEffect(() => {
    validateForm(initialValues);
  }, []);

  return (
    <form>
      <EmployeeDetailsFormContext.Provider value={formik}>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <Banner
            kind={KIND.info}
            hierarchy={HIERARCHY.low}
          >
            <ParagraphSmall
              margin={0}
              overrides={{
                Block: {
                  style: {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                  },
                },
              }}
            >
              {t('paycardManagement:paycardManagement.registerCard.addEmployeeDetails.banner')}
            </ParagraphSmall>
          </Banner>
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <AppInput
            name="payrollId"
            inputProps={{
              id: 'payrollId',
              autoComplete: 'off',
            }}
            formControlProps={{ htmlFor: 'payrollId' }}
            label={t('paycardManagement:paycardManagement.registerCard.addEmployeeDetails.payrollId')}
            cellSpan={[12, 6, 2.5]}
            context={EmployeeDetailsFormContext}
          />
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <AppInput
            name="firstName"
            inputProps={{
              id: 'firstName',
              autoComplete: 'off',
            }}
            label={t('paycardManagement:paycardManagement.registerCard.addEmployeeDetails.firstName')}
            cellSpan={[12, 6, 2.5]}
            context={EmployeeDetailsFormContext}
            formControlProps={{
              htmlFor: 'firstName',
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          />
          <AppInput
            name="lastName"
            inputProps={{
              id: 'lastName',
              autoComplete: 'off',
            }}
            label={t('paycardManagement:paycardManagement.registerCard.addEmployeeDetails.lastName')}
            cellSpan={[12, 6, 2.5]}
            context={EmployeeDetailsFormContext}
            formControlProps={{
              htmlFor: 'lastName',
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          />
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <AppDatePicker
            name="birthDate"
            label={t('paycardManagement:paycardManagement.registerCard.addEmployeeDetails.birthDate')}
            cellSpan={[12, 6, 2.5]}
            context={EmployeeDetailsFormContext}
            placeholder={datePickerPlaceholder}
            datePickerProps={{
              formatString: datePickerDateFormat,
              minDate: new Date('1900-01-01'),
              maxDate: new Date(),
              clearable: false,
            }}
            formControlProps={{
              htmlFor: 'birthDate',
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          />
          <AppInput
            name="ssn"
            inputProps={{
              id: 'ssn',
              autoComplete: 'off',
              type: 'password',
              value: ssnInputText,
              onChange: handleSSNChange,
            }}
            label={t('paycardManagement:paycardManagement.registerCard.addEmployeeDetails.ssn')}
            cellSpan={[12, 6, 2.5]}
            context={EmployeeDetailsFormContext}
            formControlProps={{
              htmlFor: 'ssn',
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          />
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <AppInput
            name="mobileNumber"
            inputProps={{
              id: 'mobileNumber',
              autoComplete: 'off',
              onChange: handlePhoneChange,
            }}
            formControlProps={{ htmlFor: 'mobileNumber' }}
            label={t('paycardManagement:paycardManagement.registerCard.addEmployeeDetails.mobileNumber')}
            cellSpan={[12, 6, 2.5]}
            context={EmployeeDetailsFormContext}
          />
        </Grid>
        <br />
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <AppInput
            name="address"
            inputProps={{
              id: 'address',
              autoComplete: 'off',
            }}
            label={t('paycardManagement:paycardManagement.registerCard.addEmployeeDetails.address')}
            cellSpan={[12, 6, 2.5]}
            context={EmployeeDetailsFormContext}
            formControlProps={{
              htmlFor: 'address',
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          />
          <AppInput
            name="unit"
            inputProps={{
              id: 'unit',
              autoComplete: 'off',
            }}
            label={t('paycardManagement:paycardManagement.registerCard.addEmployeeDetails.unit')}
            cellSpan={[12, 6, 2.5]}
            context={EmployeeDetailsFormContext}
            formControlProps={{
              htmlFor: 'unit',
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          />
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <AppInput
            name="city"
            inputProps={{
              id: 'city',
              autoComplete: 'off',
            }}
            label={t('paycardManagement:paycardManagement.registerCard.addEmployeeDetails.city')}
            cellSpan={[12, 6, 2.5]}
            context={EmployeeDetailsFormContext}
            formControlProps={{
              htmlFor: 'city',
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          />
          <AppSelect
            name="state"
            label={t('paycardManagement:paycardManagement.registerCard.addEmployeeDetails.state')}
            options={stateOptions}
            cellSpan={[12, 2.5]}
            context={EmployeeDetailsFormContext}
            selectProps={{
              clearable: false,
            }}
            formControlProps={{
              htmlFor: 'state',
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          />
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <AppInput
            name="zip"
            inputProps={{
              id: 'zip',
              autoComplete: 'off',
            }}
            formControlProps={{ htmlFor: 'zip' }}
            label={t('paycardManagement:paycardManagement.registerCard.addEmployeeDetails.zip')}
            cellSpan={[12, 6, 2.5]}
            context={EmployeeDetailsFormContext}
          />
        </Grid>
      </EmployeeDetailsFormContext.Provider>
    </form>
  );
};

export default memo(PaycardManagementRegisterCardAddEmployeeDetails);
