import { ReactNode, useContext } from 'react';
import {
  Checkbox,
  CheckboxProps,
  LABEL_PLACEMENT,
  STYLE_TYPE,
} from 'baseui/checkbox';
import {
  Cell,
  ALIGNMENT,
  Responsive,
  CellProps,
  Alignment,
} from 'baseui/layout-grid';
import {
  FormControlProps,
} from 'baseui/form-control';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
} from 'formik';
import { colors } from 'theme';
import { LabelSmall, ParagraphMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { Button, KIND, SIZE } from 'baseui/button';
import AppFormControl from './AppFormControl';
import AppTooltip from './AppTooltip';

export type AppCheckboxPropsType = {
  name: string
  context: any
  disabled?: boolean
  label?: ReactNode
  cellSpan?: Responsive<number> | undefined
  cellAlign?: Alignment | undefined
  cellProps?: CellProps
  checkboxProps?: CheckboxProps
  formControlProps?: Pick<FormControlProps, Exclude<keyof FormControlProps, 'children'>>
  tooltipTitle?: ReactNode,
  tooltipContent?: ReactNode,
  labelPlacement?: any,
  checkboxType?: any,
  labelDescription?: string,
  paragraphProps?: any,
  isLongLabel?: boolean,
  marginTop?: string,
  labelProps?: any,
}

export const toggleOverrides = ({
  checked,
}: {
  checked: boolean
}) => checked && ({
  Toggle: {
    style: {
      backgroundColor: colors.primary,
    },
  },
});

const AppCheckbox = ({
  label,
  name,
  context,
  disabled = false,
  cellSpan = 3,
  cellAlign = ALIGNMENT.start,
  cellProps,
  checkboxProps,
  formControlProps,
  tooltipTitle,
  tooltipContent,
  labelPlacement = LABEL_PLACEMENT.right,
  checkboxType = STYLE_TYPE.toggle_round,
  labelDescription,
  paragraphProps,
  isLongLabel,
  marginTop,
  labelProps,
}: AppCheckboxPropsType) => {
  const theme = useStyletron()[1];
  const formik = useContext<FormikState<any> & FormikHelpers<any> & FormikHandlers>(context);

  const {
    errors,
    values,
    touched,
    handleChange,
  } = formik;

  return (
    <Cell
      span={cellSpan}
      align={cellAlign}
      {...cellProps}
    >
      <AppFormControl
        error={errors[name]}
        {...formControlProps}
      >
        <Checkbox
          checked={values[name]}
          labelPlacement={labelPlacement}
          name={name}
          onChange={handleChange}
          error={!!(errors[name] && touched[name] && errors[name])}
          disabled={disabled}
          checkmarkType={checkboxType}
          overrides={{
            ...toggleOverrides({
              checked: values[name],
            }),
            Input: {
              props: {
                id: `Form-checkbox-${name}`,
                'data-testid': `Form-checkbox-${name}`,
                'aria-checked': `${values[name]}`,
              },
            },
            ToggleTrack: {
              style: {
                minWidth: '40px',
              },
            },
            Label: {
              style: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: isLongLabel ? 'normal' : 'nowrap',
                fontWeight: labelPlacement === LABEL_PLACEMENT.left ? '400' : '500',
                order: labelPlacement === LABEL_PLACEMENT.left ? -1 : 0,
                marginRight: labelPlacement === LABEL_PLACEMENT.left ? '8px' : '0px',
                display: labelDescription ? 'block' : 'flex',
              },
            },
            Root: {
              style: {
                marginTop: marginTop || 0,
                width: labelPlacement === LABEL_PLACEMENT.left ? 'auto' : 'fit-content',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
              },
            },
          }}
          {...checkboxProps}
        >
          <LabelSmall style={{
            lineHeight: '24px',
            fontSize: '16px',
            ...labelProps,
          }}
          >
            {label}
          </LabelSmall>
          {labelDescription && (
          <ParagraphMedium
            overrides={{
              Block: {
                style: {
                  ...paragraphProps,
                  color: theme.colors.contentTertiary,
                  margin: '0px',
                  '@media screen and (max-width: 520px)': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                  },
                },
              },
            }}
          >
            {labelDescription}
          </ParagraphMedium>
          )}
          {(tooltipTitle || tooltipContent) && (
          <Button
            size={SIZE.compact}
            kind={KIND.tertiary}
            type="button"
            overrides={{
              Root: {
                style: {
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              },
            }}
          >
            <AppTooltip title={tooltipTitle} content={tooltipContent} />
          </Button>
          )}
        </Checkbox>
      </AppFormControl>
    </Cell>
  );
};

export default AppCheckbox;
