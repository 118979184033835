import { SIZE, Spinner } from 'baseui/spinner';
import {
  StyleObject,
  useStyletron,
  withStyle,
} from 'styletron-react';
import { colors } from 'theme';

const ExtraLargeSpinner = withStyle(Spinner, {
  width: '60px',
  height: '60px',
  borderLeftWidth: '16px',
  borderRightWidth: '16px',
  borderTopWidth: '16px',
  borderBottomWidth: '16px',
  borderTopColor: colors.primary,
});

const defaultContainerStyles = {
  top: '0px',
  left: '0px',
  position: 'absolute',
  display: 'flex',
  width: '100%',
  height: '100%',
  background: '#fff',
  zIndex: 9000,
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box' as any,
} as StyleObject;

export type LoaderPropsType = {
  active: boolean
  containerStyles?: StyleObject
  loaderProps?: any
};

const Loader = (
  props: LoaderPropsType,
) => {
  const { active, containerStyles = {}, loaderProps } = props;
  const [css] = useStyletron();

  return active
    ? (
      <div
        id="Loader-container"
        data-testid="Loader-container"
        className={css({ ...defaultContainerStyles, ...containerStyles })}
      >
        <ExtraLargeSpinner {...loaderProps} $size={SIZE.large} />
      </div>
    )
    : null;
};

export default Loader;
