import { memo } from 'react';
import { useStyletron } from 'styletron-react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TPOPayment } from 'types/TPOTypes';
import { useTranslation } from 'react-i18next';
import { ModalNames, setModal } from 'store/slices/modals';
import AppModal from 'components/AppModal/AppModal';
import { Block } from 'baseui/block';
import { Cell, Grid } from 'baseui/layout-grid';
import moment from 'moment';
import { tippedEmployeeInfoSelector } from 'store/slices/tpo';
import { emptyPlaceholder } from 'theme';

export type EmployeeTipsPaymentDetailsModalPropsType = {
    payment?: TPOPayment
  }

const firstColumnWidth = '40%';
const secondColumnWidth = '60%';
const headerLabelStyles = {
  fontWeight: 600,
};

const TippedEmployeeTPOPaymentDetailsModal = ({ payment }: EmployeeTipsPaymentDetailsModalPropsType) => {
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const { t } = useTranslation(['tipsManagement', 'dateFormats']);
  const tippedEmployee = useAppSelector(tippedEmployeeInfoSelector);
  const modalName = ModalNames.EMPLOYEE_TPO_PAYMENT_DETAILS_MODAL;

  const dateFormat = t('dateFormats:standard-with-time');

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <AppModal
      modalWidth={['90vw', '90vw', '50vw', '30vw']}
      minWidth="360px"
      maxWidth="100%"
      modal={modalName}
      title={t('tipsManagement:employee.tip.paymentDetails.title')}
      onClose={handleModalClose}
    >
      <Block
        marginTop="40px"
      >
        <Grid
          gridColumns={12}
          gridMargins={16}
          gridGaps={16}
        >
          <Cell span={12}>
            <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
              {t('tipsManagement:payment.details.ID.label')}
            </Block>
            <Block width={secondColumnWidth} display="inline-flex">
              {payment?.id}
            </Block>
          </Cell>

          {/* <Cell span={12}>
            <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
              {t('tipsManagement:payment.details.workerID.label')}
            </Block>
            <Block width={secondColumnWidth} display="inline-flex">
              {(tippedEmployee.workerId) || emptyPlaceholder}
            </Block>
          </Cell> */}

          <Cell span={12}>
            <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
              {t('tipsManagement:payment.details.payrollID.label')}
            </Block>
            <Block width={secondColumnWidth} display="inline-flex">
              {(tippedEmployee.payrollId) || emptyPlaceholder}
            </Block>
          </Cell>

          <Cell span={12}>
            <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
              {t('tipsManagement:payment.details.offerID.label')}
            </Block>
            <Block width={secondColumnWidth} display="inline-flex">
              {(payment?.tcoOfferId) || emptyPlaceholder}
            </Block>
          </Cell>

          <Cell span={12}>
            <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
              {t('tipsManagement:payment.details.paymentMethod.label')}
            </Block>
            <Block width={secondColumnWidth} display="inline-flex">
              {(payment?.paymentMethod && t(`tipsManagement:payment.paymentMethods.${payment?.paymentMethod}`)) || emptyPlaceholder}
            </Block>
          </Cell>

          <Cell span={12}>
            <Block className={css(headerLabelStyles)} width={firstColumnWidth} display="inline-flex">
              {t('tipsManagement:payment.details.requestDate.label')}
            </Block>
            <Block width={secondColumnWidth} display="inline-flex">
              {(moment(payment?.requestedDate).format(dateFormat)) || emptyPlaceholder}
            </Block>
          </Cell>
        </Grid>
      </Block>
    </AppModal>
  );
};

export default memo(TippedEmployeeTPOPaymentDetailsModal);
