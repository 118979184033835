import {
  faBriefcase,
  faClock,
  faLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'baseui/card';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  LabelSmall,
  ParagraphSmall,
} from 'baseui/typography';
import moment from 'moment';
import { Key, memo } from 'react';
import { useStyletron } from 'baseui';
import { Tag, VARIANT } from 'baseui/tag';
import { unScalePrice } from 'utils/priceScale';
import { useTranslation } from 'react-i18next';
import { MoneyDtoType } from 'types/CommonTypes';

export type WorkerShiftsShiftListItemPropsType = {
  shiftsList: {
    id: number,
    organizationName: string,
    locationName: string,
    locationTimeZone: string,
    jobName: string,
    start: string,
    end: string,
    source: string,
    earningAmount: MoneyDtoType
  },
  isOpenShifts: boolean
}

const WorkerShiftsShiftListItem = ({ shiftsList, isOpenShifts }:
  WorkerShiftsShiftListItemPropsType) => {
  const [css] = useStyletron();
  const theme = useStyletron()[1];
  const { t } = useTranslation(['common', 'workers']);
  const dateNoPeriodFormat = t('dateFormats:standard-time-no-day-period');
  const dateWithPeriodFormat = t('dateFormats:standard-time');

  const iconsShiftsModalStyle = {
    marginRight: '8px',
    color: isOpenShifts ? '#AFAFAF' : '#174291',
  };

  return (
    <Card
      overrides={{
        Root: {
          style: {
            marginBottom: '8px',
          },
        },
      }}
      key={shiftsList.id as Key}
    >
      <Grid
        gridColumns={12}
        gridMargins={0}
      >
        <Cell
          span={[12, 6, 3.5]}
        >
          <FontAwesomeIcon
            icon={faLocationDot}
            className={css(iconsShiftsModalStyle)}
          />
          <LabelSmall display="inline-flex">
            {t('workers:shifts.store.label')}
            :&nbsp;
          </LabelSmall>
          <ParagraphSmall display="inline-flex" margin={0} color={theme.colors.contentSecondary}>{shiftsList.locationName}</ParagraphSmall>
        </Cell>
        <Cell span={[12, 6, 2]}>
          <FontAwesomeIcon
            icon={faBriefcase}
            className={css(iconsShiftsModalStyle)}
          />
          <LabelSmall display="inline-flex">
            {t('workers:shifts.job.label')}
            :&nbsp;
          </LabelSmall>
          <ParagraphSmall display="inline-flex" margin={0} color={theme.colors.contentSecondary}>{shiftsList.jobName}</ParagraphSmall>
        </Cell>
        <Cell span={[12, 6, 3.5]}>
          <FontAwesomeIcon
            icon={faClock}
            className={css(iconsShiftsModalStyle)}
          />
          <LabelSmall display="inline-flex">
            {t('workers:shifts.time.label')}
            :&nbsp;
          </LabelSmall>
          <ParagraphSmall display="inline-flex" margin={0} color={theme.colors.contentSecondary}>
            {`${moment(shiftsList.start).format(dateNoPeriodFormat)} - ${moment(shiftsList.end).format(dateWithPeriodFormat)} 
            ${shiftsList.locationTimeZone}`}
          </ParagraphSmall>
        </Cell>
        {shiftsList.earningAmount && (
        <Cell
          span={[12, 8, 3]}
          align={ALIGNMENT.start}
        >
          <Tag
            closeable={false}
            variant={VARIANT.solid}
            overrides={{
              Root: {
                style: {
                  backgroundColor: theme.colors.backgroundTertiary,
                  display: 'inline-flex',
                  marginTop: '0',
                  height: 'auto',
                },
              },
              Text: {
                style: {
                  maxWidth: 'unset',
                  whiteSpace: 'normal', // Allow text to wrap onto the next line
                  wordWrap: 'break-word',
                },
              },
            }}
          >
            <LabelSmall
              color="#674D1B"
              width="fit-content"
            >
              {`${t('workers:shifts.estimatedEarnings.label')}: 
              $${unScalePrice(shiftsList.earningAmount?.value, shiftsList.earningAmount?.scale)} 
              + ${t('workers:shifts.estimatedEarnings.tips.label')}`}
            </LabelSmall>
          </Tag>
        </Cell>
        )}
      </Grid>
    </Card>
  );
};

export default memo(WorkerShiftsShiftListItem);
