import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { DatePicker } from 'baseui/datepicker';
import { Input, SIZE } from 'baseui/input';
import { ALIGNMENT, Grid } from 'baseui/layout-grid';
import AppModal from 'components/AppModal/AppModal';
import CellFormControl from 'components/CellFormControl';
import { datePickerOverrides } from 'components/Form/AppDatePicker';
import useIsFormChanged from 'hooks/useIsFormChanged';
import moment from 'moment';
import {
  ChangeEvent, memo, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { currencyCodeSelector } from 'store/slices/application';
import { ModalNames } from 'store/slices/modals';
import {
  InputCustomHTMLElement,
  MoneyDtoType,
} from 'types/CommonTypes';
import {
  RepaymentType,
  RepaymentIDType,
  RepaymentUpdateType,
} from 'types/RepaymentTypes';
import { unScalePrice, scalePrice } from 'utils/priceScale';

const RepaymentEditModal = ({
  repayment,
  handleModalClose,
  saveRepayment,
}: {
  repayment: RepaymentType,
  saveRepayment: (id: RepaymentIDType, data: RepaymentUpdateType) => void,
  handleModalClose: () => void,
}) => {
  const { t } = useTranslation(['common', 'batches', 'dateFormats']);
  const currencyCode = useAppSelector(currencyCodeSelector);
  const modalName = ModalNames.REPAYMENT_EDIT_MODAL;
  const [css] = useStyletron();
  const [transactionId, setTransactionId] = useState<string>(repayment.transactionId);
  const [amount, setAmount] = useState<MoneyDtoType>(repayment.amount);
  const [dateReceived, setDateReceived] = useState<Array<Date | undefined | null>>([new Date(repayment.dateReceived)]);
  const [amountError, setAmountError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const initialDate = moment(dateReceived[0]).format('YYYY-MM-DD');

  const initialValues = { transactionId, amount, initialDate };

  const { isFormChanged, setDefaultValues } = useIsFormChanged(initialValues);

  const datePickerDateFormat = t('dateFormats:date-picker.standard');
  const datePickerPlaceholder = t('dateFormats:date-picker.placeholder');

  const handleChangeTransactionId = (
    e: ChangeEvent<InputCustomHTMLElement>,
  ) => {
    setTransactionId(e.target.value);
  };

  const handleChangeAmount = (
    e: ChangeEvent<InputCustomHTMLElement>,
  ) => {
    const newScaledValue = scalePrice(Number(e.target.value), amount.scale);
    setAmount({ ...amount, value: newScaledValue || 100 });
    if (newScaledValue < repayment.allocatedAmount.value) {
      setAmountError(t('batches:amountError'));
    } else {
      setAmountError(null);
    }
  };
  const handleSaveRepayment = () => {
    setLoading(true);
    const data = {
      transactionId,
      amount,
      dateReceived: moment(dateReceived[0]).format('YYYY-MM-DD'),
    };
    saveRepayment(String(repayment?.id), data);
  };

  useEffect(() => {
    setDefaultValues(initialValues);
  }, []);

  return (
    <AppModal
      modal={modalName}
      title={t('batches:repaymentsEditModal')}
      cancelBtnText={t('common:cancel')}
      actionBtnText={t('common:save')}
      onClose={handleModalClose}
      isActionDisabled={loading || !!amountError || !isFormChanged}
      isActionLoading={loading}
      onAction={handleSaveRepayment}
    >
      <div className={css({
        maxHeight: '350px',
        overflowY: 'auto',
        overflowX: 'hidden',
      })}
      >
        <Grid
          gridGaps={16}
          gridMargins={16}
          gridColumns={12}
          align={ALIGNMENT.start}
        >
          <CellFormControl
            cellSpan={[12]}
            cellAlign={ALIGNMENT.start}
            formControlProps={{
              label: t('repayments:referenceID.label'),
            }}
          >
            <Input
              size={SIZE.compact}
              autoComplete="off"
              type="text"
              name="transactionId"
              onChange={handleChangeTransactionId}
              value={transactionId}
              placeholder={t('repayments:referenceID.label')}
              required
              id="edit-repayment-form-transactionId-input"
            />
          </CellFormControl>
          <CellFormControl
            cellSpan={[12]}
            cellAlign={ALIGNMENT.start}
            formControlProps={{
              label: t('repayments:paymentAmount.label'),
            }}
          >
            <Block>
              <Input
                size={SIZE.compact}
                autoComplete="off"
                type="number"
                min={1}
                endEnhancer={currencyCode}
                name="amount"
                onChange={handleChangeAmount}
                value={unScalePrice(amount.value, amount.scale)}
                error={!!amountError}
                placeholder={t('repayments:paymentAmount.label')}
                required
                id="edit-repayment-form-amount-input"
              />
              {amountError
                ? (
                  <div className={css({
                    color: 'red',
                    fontSize: '12px',
                    marginTop: '10px',
                  })}
                  >
                    {amountError}
                  </div>
                ) : null}
            </Block>
          </CellFormControl>
          <CellFormControl
            cellSpan={[12]}
            cellAlign={ALIGNMENT.start}
            formControlProps={{
              label: t('repayments:dateReceived.label'),
            }}
          >
            <DatePicker
              size={SIZE.compact}
              placeholder={datePickerPlaceholder}
              formatString={datePickerDateFormat}
              value={dateReceived}
              onChange={({ date }) => setDateReceived(Array.isArray(date) ? date : [date])}
              required
              overrides={{
                ...datePickerOverrides,
                InputWrapper: {
                  props: {
                    'data-testid': 'Form-date-picker-edit-repayment',
                  },
                },
                Input: {
                  ...datePickerOverrides.Input,
                  props: {
                    ...datePickerOverrides.Input.props,
                    id: 'Form-date-picker-edit-repayment',
                    'data-testid': 'Form-date-picker-edit-repayment',
                  },
                },
              }}
            />
          </CellFormControl>
        </Grid>
      </div>
    </AppModal>
  );
};

export default memo(RepaymentEditModal);
