import { useEffect } from 'react';
import { StyleObject, useStyletron } from 'styletron-react';
import { Notification } from 'baseui/notification';
import {
  notificationSelector,
  removeNotification,
} from 'store/slices/notification';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { resetNotification } from 'store/events';

const containerStyles = {
  display: 'inline-block',
  position: 'fixed',
  top: '16px',
  right: '0px',
  left: '0px',
  margin: 'auto',
  width: 'auto',
  minWidth: '30%',
  maxWidth: '360px',
  zIndex: 10000,
} as StyleObject;

const ToastNotification = () => {
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const { t } = useTranslation(['notifications']);
  const appNotifications = useAppSelector(notificationSelector);

  const colors = {
    INFO: { background: '#95b4ed', color: '#0f2c60' },
    WARNING: { background: '#efdcb7', color: '#674d1b' },
    SUCCESS: { background: '#679f85', color: '#fff' },
    ERROR: { background: '#c57272', color: '#fff' },
  };

  const handleClose = () => {
    dispatch(resetNotification());
  };

  useEffect(() => {
    appNotifications.forEach((notification, index) => {
      const duration = notification.autoHideDuration || 0;
      if (duration > 0 && notification.isOpen) {
        setTimeout(() => dispatch(removeNotification(index)), duration);
      }
    });
  }, [appNotifications, dispatch]);

  if (!appNotifications.length) return null;

  return (
    <div>
      {appNotifications.map((notification, index) => {
        const {
          isOpen,
          type = 'INFO',
          titleKey,
          text,
          textKey,
          title,
          multipleLinesTitle,
        } = notification;
        if (!isOpen) return null;

        return (
          <div
            className={css({
              ...containerStyles,
              top: `${index > 1 ? 20 + index * 50 : 0}px`,
            })}
          >
            <Notification
              overrides={{
                Body: {
                  style: {
                    minWidth: '100%',
                    backgroundColor: colors[type]?.background,
                    color: colors[type]?.color,
                    boxSizing: 'border-box',
                    boxShadow: '0px 0px 16px #666',
                  },
                },
                CloseIcon: {
                  style: {
                    width: '24px',
                    height: '24px',
                  },
                },
                InnerContainer: {
                  style: {
                    width: '100%',
                    overflow: 'auto',
                    maxWidth: '100%',
                    'white-space': 'normal',
                  },
                },
              }}
              closeable
              onClose={handleClose}
            >
              {(title || titleKey) && (
                <h3
                  style={multipleLinesTitle ? {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                  } : {}}
                >
                  {title || (titleKey && t(`notifications:${titleKey}`))}
                </h3>
              )}
              {(text || textKey) && (
                <p className={css({ 'white-space': 'pre-wrap' })}>
                  {text || (textKey && t(`notifications:${textKey}`))}
                </p>
              )}
            </Notification>
          </div>
        );
      })}
    </div>
  );
};

export default ToastNotification;
