import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import AppChip from 'components/AppChip/AppChip';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import { borderRadius } from 'theme';
import { WorkerEmployments, WorkerEmploymentStatusTypes } from 'types/WorkerTypes';

const listStyles = {
  padding: '0px',
};

const listItemStyles = {
  background: '#FFFFFF',
  'list-style': 'none',
  padding: '8px',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
};

const listItemNameAndStatuses = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '4px',
};

const listItemInfoStyles = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '0px 4px 16px 20px',
};

const detailsStyles = {
  display: 'inline-block',
  marginRight: '16px',
  marginTop: '4px',
};

const WorkerLinkingListItem = ({
  worker,
  handleLinkToEmployerClick,
}: {
  worker: WorkerEmployments,
  handleLinkToEmployerClick: (worker: WorkerEmployments) => void,
}) => {
  const { t } = useTranslation(['common', 'employees']);
  const [css] = useStyletron();

  return (
    <ul className={css(listStyles)}>
      <li className={css(listItemStyles)}>
        <div className={css(listItemNameAndStatuses)}>
          <Button
            kind={KIND.tertiary}
            overrides={{
              Root: {
                props: {
                  id: `WorkerLinkingListItem-employee-${worker?.id}-title`,
                },
                style: {
                  cursor: 'default',
                  color: '#000',
                  maxWidth: '80%',
                },
              },
            }}
          >
            <span className="ellipsis">{`${worker?.firstName} ${worker?.lastName}`}</span>
          </Button>
          {worker.status === WorkerEmploymentStatusTypes.PENDING
                    && (
                      <Block padding="14px 16px">
                        <FontAwesomeIcon
                          title={t('employees:linkEmployee')}
                          size="lg"
                          className={css({ cursor: 'pointer', color: 'rgb(187, 0, 47)' })}
                          icon={faLink}
                          onClick={() => handleLinkToEmployerClick(worker)}
                        />
                      </Block>
                    )}

        </div>

        <div className={css(listItemInfoStyles)}>
          <div>

            <span className={css(detailsStyles)}>
              {t('employees:mobileNumber')}
              :
              <AppChip
                items={worker.phone}
              />
            </span>
            <span className={css(detailsStyles)}>
              {t('employees:email')}
              :
              <AppChip
                items={worker.email}
              />
            </span>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default WorkerLinkingListItem;
